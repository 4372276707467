import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Box, Button, IconButton, Modal, Tooltip, alpha } from '@mui/material'
import { ReactComponent as ExcelIcon } from "../../../../../src/images/excel-icon.svg"
import UniversalInputField, { InputDataType } from '@Base/framework/components/Form/UniversalInputField'
import { Close, Replay, SaveOutlined, WarningAmber } from '@mui/icons-material'
import DetailedSCurveChartV2 from '../charts/DetailedSCurveChartV2'
import CustomTooltip, { getTooltip } from '@Base/components/shared/CustomTooltip'
import { makeStyles, styled } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'
import Localization from '@Base/components/Localization'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { actionCreators as actionDownloads } from '@Base/store/DownloadStore'
import { useDispatch } from 'react-redux'
import { roundDown, roundUp, toUnixDate } from '@Base/Utilities'
import exportingInit from 'highcharts/modules/exporting';
import HC_OfflineExport from 'highcharts/modules/offline-exporting';
import KeyratePanel from './KeyratePanel'
// import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import DownloaderAlert from '../../downloader/DownloaderAlert'
import { actionCreators as actionAlert } from '@Base/store/AlertStore'
import { useNewDispatch, useNewSelector } from '@Base/index'
import { clearValue, setConventionData } from '@Base/newStore/conventionSlice'
import { axisGridLineColor, axisLabelsColor, axisLineColor, axisTitleColor, plotBorderColor } from '@Base/components/constants/charts'

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: "DINPro-Bold",
    fontWeight: "700",
    letterSpacing: '1.5px',
    fontSize: '0.8rem !important',
    '& *': {
        fontSize: '0.8rem !important',
    },
    // color: style.colorPrimary3,
    padding: '0 0.5rem 0 9px',
    marginRight: '0.5rem',
    backgroundColor: 'rgba(183, 217, 139, 0.23)',
    borderColor: 'rgb(61,149,109)',
    color: 'rgb(61,149,109)',
    '&:hover': {
        borderColor: 'rgb(61,149,109) !important',
        backgroundColor: 'rgba(183, 217, 139, 0.4) !important',
        // color: `rgb(0,176,80) !important`,
    }
}))

// console.log('exportingInit ', exportingInit)

const useStyles = makeStyles({
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80
    },
    '& .highcharts-tooltip>span': {
        backgroundColor: '#fff',
        border: '1px solid #172F8F',
        zIndex: '9999!important',
    },
    yAxisName: {
        rotate: '-90deg',
        fontSize: '12px',
        color: axisTitleColor,
        zIndex: 999,
        position: 'absolute',
        left: '-20px',
        bottom: 'Calc(50% + 8px)',
        userSelect: 'none',
    },
    switchContainer: {
        fontFamily: "DINPro-Bold",
        fontWeight: "700",
        letterSpacing: '1.5px',
        fontSize: '0.8rem !important',
        border: `solid 1px ${style.colorPrimary3_40}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '22px',
        borderRadius: '4px',
        marginRight: '0.5rem',
        padding: '0 0.5rem',
        cursor: 'pointer'
    },

    switchRoot: {
        marginRight: '3px',
        marginBottom: '2px',
        zoom: '0.96',
        // height: '15px !important',
        // '& .MuiButtonBase-root': {
        //     height: '20px !important'
        // },
        '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
            color: 'rgb(245,245,245)',
            border: `solid 1px rgb(189,189,189)`,

        },
        '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
            color: 'rgb(245,245,245)',
            border: `solid 1px rgb(189,189,189)`,
        },
        '& .MuiSwitch-switchBase.Mui-focusVisible': {
            color: `${alpha(style.colorPrimary3, 1)}`,
            // padding:'5px',
            // paddingRight:0,
            // paddingBottom:0,
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            backgroundColor: 'rgb(255,255,255)',
            border: `solid 1px rgb(189,189,189)`,
            // opacity:'0.5 !important'
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            color: 'rgb(245,245,245)',
            border: `solid 1px rgb(189,189,189)`,
        },
    },
    chartsContainer: {
        position: 'relative',
        flexGrow: 1,
    },
    chart1Container: {
        position: 'absolute',
        left: 0, top: 0, right: 0, bottom: 'calc( 50% + 19px )',
    },
    chart2Container: {
        position: 'absolute',
        left: 0, top: 'calc( 50% - 19px )', right: 0, bottom: 0,
    },
    switchLabel: {
        margin: 0,
        textTransform: 'uppercase',
        fontSize: '0.8rem !important',
        letterSpacing: '1.5px !important',
        lineHeight: '2rem',
        // marginTop: '3px',
        fontWeight: 'bold',
    },
    disabled: {
        color: `${style.colorPrimary3_40} !important`
    },
    // headerCheckbox: {
    //     margin: 0,
    //     fontSize: '0.8rem !important',
    //     letterSpacing: '1.5px !important',
    //     lineHeight: '2rem',
    // },
    fullPanelAlert: {
        position: 'absolute',
        left: 0, top: 0, right: 0, bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: style.colorPrimary3_40,
        textAlign: 'center',
        userSelect: 'none',
    },
    wideTooltip: {
        maxWidth: '500px !important',
    },
    textIcon: {
        fontSize: '1.2rem !important',
        textTransform: 'initial',
        padding: '0 !important',
        minWidth: '25px !important',
        letterSpacing: 'initial',
        color: style.colorPrimary3,
        filter: 'grayscale(1)',
        fontFamily: 'auto',
        fontWeight: 100,
    },
    coverLayout: {
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        zIndex: '+9990',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    modal: {
        display: 'flex',
        padding: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    rootModal: {
        position: 'relative',
        width: '1450px',
        height: '830px',
        marginRight: '4px',
        outline: 'none',
        '& $rootPaper': {
            backgroundColor: '#FFF',
        }
    },
    root: {
        // marginRight: '0.2rem',
        // marginLeft: '0.2rem',
        // maxHeight: '400px',
        // position: 'relative',
        position: 'absolute',
        // left: '0.2rem', top: 0, bottom: 0, right: '0.2rem',
        left: 0, top: 0, bottom: 0, right: 0,
        // maxHeight:'400px',
        '& $panelHeader *': {
            fontSize: '1rem'
        }
    },
    rootPaper: {
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'hidden',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 0',
        justifyContent: 'space-between',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
        marginLeft: '0.5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    mr05: {
        marginRight: '0.5rem',
    },
    panelHeaderButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // '&>*:not(:first-child)': {
        //     marginLeft: '0.5rem',
        // }
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80
    },
    panelData: {
        margin: '0.8rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        wlexWrap: 'nopwrap',
    },
    tooltip: {
        '& *': { fontSize: '0.8rem !important' },
        // '&>span': {
        //     outline: 'solid',
        //     border: 'solid 1px #000',
        // }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }
})

const CPRChartPanel = () => {
    const cls = useStyles()
    const [showDetailedSCurveChart, setShowDetailedSCurveChart] = useState(false)
    const [showDebt, setShowDebt] = useState(true)
    // const { selectedBond = null, sCurveGraph = null, loading = false,
    //     cprGraph = null, calculationParameters = null, poolStatistics = null, useModelKeyrate = false, modelKeyrate = null, ...otherStore } = useSelector((state) => state.convention)

    const { selectedBond = null, sCurveGraph = null, loading = false,
        cprGraph = null, calculationParameters = null, poolStatistics = null,
        pricingParameters = null,
        useModelKeyrate = false, modelKeyrate = null, ...otherStore } = useNewSelector((state) => state.newConvention)

    // console.log(' useModelKeyrate=', useModelKeyrate, 'modelKeyrate=', modelKeyrate)
    const [readyForExport, setReadyForExport] = useState(false)
    const [showKeyratePanel, setShowKeyratePanel] = useState(false)
    const chart1Ref = useRef()
    const chart2Ref = useRef()
    const ref = useRef(null)
    const dispatch = useDispatch()
    const newDispatch = useNewDispatch()
    exportingInit(Highcharts)
    HC_OfflineExport(Highcharts)

    const series = [
        {
            name: 'rateArea',
            id: 'arearange',
            type: 'arearange',
            step: 'left',
            animation: false,
            zIndex: 1,
            data: null,
            yAxis: 0,
            xAxis: 0,
            lineWidth: 0,
            // lineColor: style.colorPrimary1_40,
            fillColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                    [0, 'rgba(255, 255, 255, 0)'], // Start color (transparent)
                    [1, 'rgba(255, 255, 255, 0)'] // End color (transparent)
                ]
            },
            fillOpacity: 0.3,
            showInLegend: false,
            stacking: null,
            visible: false,
            marker: {
                enabled: false,
            },
            states: {
                hover: {
                    enabled: false
                },
                inactive: {
                    enabled: false
                },
                normal: {
                    enabled: false
                },
                select: {
                    enabled: false
                }
            }
        },
        {
            name: t('convention2.keyRateChartName'),
            id: 'keyRate',
            type: 'line',
            step: 'left',
            stickyTracking: false,
            animation: false,
            zIndex: 1,
            data: null,
            yAxis: 0,
            xAxis: 0,
            lineWidth: 1,
            color: style.colorPrimary2,
            dashStyle: 'Solid',
            showInLegend: false,
            visible: false,
            marker: {
                symbol: 'circle', // Set the marker symbol to 'circle'
                radius: 2,
                enabled: false,
                states: {
                    hover: {
                        enabled: true,
                    }
                },
            },
            states: {
                inactive: {
                    enabled: false
                },
                hover: {
                    enabled: false, // Отключает выделение серии при наведении мыши
                }
            }
        },
        {
            name: t('convention2.refinancingRateChartName'),
            id: 'refinancingRate',
            type: 'line',
            step: 'left',
            stickyTracking: false,
            animation: false,
            // pointPlacement: 'on',
            data: null,
            yAxis: 0,
            xAxis: 0,
            lineWidth: 1,
            color: style.colorPrimary2,
            dashStyle: 'Dash', // Set the line style to long dashed
            visible: false,
            marker: {
                symbol: 'circle', // Set the marker symbol to 'circle'
                radius: 2,
                enabled: false,
                // states: {
                //     hover: {
                //         enabled: false,
                //     }
                // },
            },
            // enableMouseTracking: false,
            states: {
                inactive: {
                    enabled: false
                },
                hover: {
                    enabled: false, // Отключает выделение серии при наведении мыши
                }
            }
        },
        {
            name: t('convention2.cprChartName'),
            id: 'cpr',
            type: 'line',
            step: 'left',
            stickyTracking: false,
            animation: false,
            zIndex: 1,
            data: null,
            yAxis: 0,
            xAxis: 0,
            lineWidth: 1.5,
            color: style.colorPrimary1,
            dashStyle: 'Solid',
            showInLegend: false,
            visible: false,
            marker: {
                symbol: 'circle', // Set the marker symbol to 'circle'
                radius: 2,
                enabled: false,
                states: {
                    hover: {
                        enabled: true,
                    }
                },
            },
            states: {
                inactive: {
                    enabled: false
                },
                hover: {
                    enabled: false, // Отключает выделение серии при наведении мыши
                }
            }
        },
        {
            name: t('convention2.wacChartName'),
            id: 'wac',
            type: 'line',
            // step: 'left',
            stickyTracking: false,
            animation: false,
            zIndex: 1,
            data: null,
            yAxis: 0,
            xAxis: 0,
            // lineWidth: 1.5,
            color: style.colorPrimary3_40,
            dashStyle: 'ShortDash',
            showInLegend: false,
            visible: false,
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: true,
                    }
                },
            },
            states: {
                inactive: {
                    enabled: false
                },
                hover: {
                    enabled: false, // Отключает выделение серии при наведении мыши
                }
            }
        },
    ]
    // console.log('cprGraph', cprGraph)
    // console.log('useModelKeyrate!!!!!!!!!!!!!!!!!!!!!!!!!', useModelKeyrate)
    // useLayoutEffect(() => {


    //     return () => {

    //     };
    // }, [useModelKeyrate])

    useLayoutEffect(() => {
        return () => {
            const newSeries1 = structuredClone(series)
            const newSeries2 = structuredClone(series)
            newSeries1?.forEach(serie => serie.data = [])
            newSeries2?.forEach(serie => serie.data = [])
            setTopChartOptions({
                ...topChartOptions,
                series: [
                    { ...newSeries1[0], visible: true },
                    { ...newSeries1[1], visible: true },
                    { ...newSeries1[2], visible: true },
                    { ...newSeries1[3], visible: true, color: '#0000', states: { hover: { enabled: false }, } },
                    { ...newSeries1[4], visible: true, }
                ]
            })

            setBottomChartOptions({
                ...bottomChartOptions,
                series: [
                    { ...newSeries2[0], visible: false, showInLegend: false, },
                    { ...newSeries2[1], visible: false, showInLegend: true, },
                    { ...newSeries2[2], visible: false, showInLegend: true, },
                    { ...newSeries2[3], visible: true, showInLegend: true, },
                    { ...newSeries2[4], visible: false, showInLegend: false, }]
            })
        }
    }, [selectedBond?.isin])

    // console.log('topChartOptions', topChartOptions)


    useLayoutEffect(() => {
        // console.log('cprGraph', cprGraph)
        if (cprGraph == null || loading)
            return

        const arrSize = cprGraph?.date?.length
        const dates = cprGraph?.date?.map(date => toUnixDate(new Date(date)))
        const minX = Math.min.apply(null, dates)
        const maxX = Math.max.apply(null, dates)

        const dT = new Date(maxX).getFullYear() - new Date(minX).getFullYear()


        // console.log('start', minX, maxX, dT)
        const tickPositions = cprGraph?.date?.map((x, index) => {
            let step = 1
            if (dT >= 30) step = 3
            else
                if (dT >= 15) step = 2
            // console.log('step', step)
            const date = new Date(x)
            const y = date.getFullYear()
            const m = date?.getMonth()
            const d = date?.getDate()
            if ((y % step == 0 && m == 0 && d == 1) || index == arrSize - 1) {
                //newtopChartOptions.xAxis.plotLines.push({ ...lineStyle, value: index })
                return toUnixDate(date)
            }

            return null
        })//?.filter(x => x != null)
        // const newSeries = [...series]
        // newSeries?.forEach(serie => serie.data = [])
        const newSeries1 = structuredClone(series)
        const newSeries2 = structuredClone(series)
        newSeries1?.forEach(serie => serie.data = [])
        newSeries2?.forEach(serie => serie.data = [])

        // console.log('cprGraph', cprGraph)
        newSeries1[0].data = cprGraph?.keyRate.map((keyRate, index) => {
            return ({
                x: toUnixDate(new Date(cprGraph?.date[index])), low: keyRate, high: cprGraph?.refinancingRate[index]//, lowTitle, highTitle,
                // color: dy > 0 ? '#b7d98b1a' : '#FF00000a'
            })
        })
        const fillColor = pricingParameters?.cpr !== null ? 'rgba(187, 188, 189, 0.1)' : cprGraph?.keyRate[0] < cprGraph?.refinancingRate[0] ? 'rgba(183, 217, 139, 0.23)' : 'rgba(255, 0, 0, 0.1)'
        // newSeries1[0].fillColor.stops = [
        //     [0, fillColor], // Start color (transparent)
        //     [1, fillColor] // End color (transparent)
        // ]

        newSeries2[0].data = cprGraph?.keyRate.map((keyRate, index) => {
            return ({
                x: toUnixDate(new Date(cprGraph?.date[index])), low: keyRate, high: cprGraph?.refinancingRate[index]//, lowTitle, highTitle,
                // color: dy > 0 ? '#b7d98b1a' : '#FF00000a'
            })
        })
        newSeries2[0].fillColor.stops = [
            [0, fillColor], // Start color (transparent)
            [1, fillColor] // End color (transparent)
        ]
        // if (useModelKeyrate && modelKeyrate != null) {
        //     newSeries[1].data = []
        //     modelKeyrate?.forEach(mk => {
        //         // console.log('mk', mk)
        //         const startDate = new Date(mk.start)?.getTime()
        //         const endDate = new Date(mk.end)?.getTime()

        //         for (let x = startDate; x <= endDate;x = new Date(x)?.setMonth(new Date(x)?.getMonth() + 1)) {
        //             // console.log('x', x)
        //             newSeries[1].data.push([toUnixDate(new Date(x)), mk.value])
        //         }
        //     })
        //     // console.log('newSeries[1].data', newSeries[1].data)
        // }
        // else

        // console.log('maxX, minX', maxX, minX)
        newSeries1[1].data = cprGraph?.keyRate?.map((y, index) => { return [toUnixDate(new Date(cprGraph?.date[index])), y] })
        newSeries1[2].data = cprGraph?.refinancingRate?.map((y, index) => { return [toUnixDate(new Date(cprGraph?.date[index])), y] })
        newSeries1[3].data = cprGraph?.modelCPR?.map((y, index) => { return [toUnixDate(new Date(cprGraph?.date[index])), y] })
        newSeries1[4].data = cprGraph?.wac?.map((y, index) => { return [toUnixDate(new Date(cprGraph?.date[index])), y] })

        newSeries2[1].data = cprGraph?.keyRate?.map((y, index) => { return [toUnixDate(new Date(cprGraph?.date[index])), y] })
        newSeries2[2].data = cprGraph?.refinancingRate?.map((y, index) => { return [toUnixDate(new Date(cprGraph?.date[index])), y] })
        newSeries2[3].data = cprGraph?.modelCPR?.map((y, index) => { return [toUnixDate(new Date(cprGraph?.date[index])), y] })
        newSeries2[4].data = cprGraph?.wac?.map((y, index) => { return [toUnixDate(new Date(cprGraph?.date[index])), y] })
        // console.log('newSeries[3].data', newSeries[3].data)
        // console.log('cprGraph', cprGraph)
        // console.log('newSeries', newSeries)
        const plotLines =
        {
            color: style.colorPrimary3_40,
            dashStyle: 'ShortDash',
            width: 2,
            value: null,
            zIndex: 3,
            label: {
                rotation: 0,
                align: 'right',
                // textAlign: 'right',
                x: -5,
                y: -8,
                style: {
                    color: style.colorPrimary3,
                    fontWeight: 'bolder',
                    fontSize: '0.9rem'
                },
                text: null,
            }
        }
        // console.log('poolStatistics', poolStatistics, poolStatistics?.wac?.total)
        const wacPlotLinesLabel = [
            // {
            //     ...plotLines, value: poolStatistics?.wac?.total,
            //     zIndex: 0,
            //     label: null
            // },
            {
                ...plotLines, value: cprGraph?.wac[cprGraph?.wac?.length - 1],
                color: '#0000', zIndex: 3,
                label: {
                    ...plotLines.label,
                    text: `${t('convention2.WAC')}`
                }
            }
        ]

        const cprPlotLines = [
            (pricingParameters?.cpr === null) &&
            {
                ...plotLines, value: calculationParameters?.modelCPR,
                zIndex: 0,
                label: null
            },
            {
                ...plotLines, value: calculationParameters?.modelCPR,
                color: '#0000', zIndex: 3,
                label: {
                    ...plotLines.label,
                    y: ((cprGraph?.modelCPR?.length != null && calculationParameters?.modelCPR <= cprGraph?.modelCPR[cprGraph?.modelCPR?.length - 1]) ? 20 : -8),
                    text: `${pricingParameters?.cpr !== null ? t('convention2.CPRValue') : t('convention2.modelCPRValue')} ${Localization.FormatNumber(calculationParameters?.modelCPR, 1, 1)}%`
                }
            }
        ]

        // const categories = cprGraph?.date?.map(x => toUnixDate(new Date(x)))
        const chart1Min = Math.min.apply(null, [...cprGraph?.keyRate, ...cprGraph?.refinancingRate, ...cprGraph?.wac]?.filter(x => x != null))
        const chart1Max = Math.max.apply(null, [...cprGraph?.keyRate, ...cprGraph?.refinancingRate, ...cprGraph?.wac]?.filter(x => x != null))

        let chart2Min = Math.min.apply(null, [...cprGraph?.modelCPR]?.filter(x => x != null))
        if (chart2Min <= 0) chart2Min = 0;
        const chart2Max = Math.max.apply(null, [...cprGraph?.modelCPR]?.filter(x => x != null))
        // console.log('calculationParameters', calculationParameters.wac)
        // console.log('chart1Min, chart1Max', chart1Min, chart1Max)
        // console.log('newSeries?.forEach(serie => serie.data = [])', newSeries)
        // console.log('tickPositions', tickPositions)
        // console.log('topChartOptions?.xAxis', topChartOptions?.xAxis)
        // const chart2newSeries = [...newSeries]
        setBottomChartOptions({
            ...bottomChartOptions,
            xAxis: { ...bottomChartOptions?.xAxis, tickPositions: tickPositions, min: minX, max: maxX },
            yAxis: {
                ...bottomChartOptions?.yAxis,
                plotLines: cprPlotLines,
                min: chart2Min, max: chart2Max
                , ...Boolean(chart2Min <= 0) && {
                    plotBands: [
                        {
                            from: Number.NEGATIVE_INFINITY,
                            to: 0,
                            color: alpha(style.colorPrimary3, 0.02),
                        }
                    ],
                }
            },
            series: [
                { ...newSeries1[0], visible: false, showInLegend: false,},
                { ...newSeries1[1], visible: false, showInLegend: true, },
                { ...newSeries1[2], visible: false, showInLegend: true, },
                { ...newSeries1[3], visible: true, showInLegend: true, },
                { ...newSeries1[4], visible: false, showInLegend: false, }]

        })
        
        setTopChartOptions({
            ...topChartOptions,
            xAxis: { ...topChartOptions?.xAxis, tickPositions: tickPositions, min: minX, max: maxX },
            yAxis: {
                ...topChartOptions?.yAxis,
                plotLines: wacPlotLinesLabel,
                min: chart1Min, max: chart1Max
            },
            series: [
                { ...newSeries2[0], visible: true },
                { ...newSeries2[1], visible: true, ...Boolean(pricingParameters?.cpr !== null) && { color: style.colorPrimary3_60 } },
                { ...newSeries2[2], visible: true, ...Boolean(pricingParameters?.cpr !== null) && { color: style.colorPrimary3_60 }  },
                { ...newSeries2[3], visible: true, color: '#0000', states: { hover: { enabled: false }, } },
                { ...newSeries2[4], visible: true, showInLegend: false, }
            ]
        })

        // console.log('newSeries', newSeries)


    }, [cprGraph, calculationParameters, modelKeyrate])

    // console.log('cprGraph?.date?.length', cprGraph?.date?.length)
    const [topChartOptions, setTopChartOptions] = useState({
        exporting: {
            enabled: false,
            scale: 1,
            chartOptions: {
                chart: {
                    width: 1200,
                    height: 300,
                    marginLeft: 40, // Keep all charts left aligned
                    // marginRight: 50,
                    padding: 0,
                    plotBorderWidth: 0,
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                yAxis: {
                    title: {
                        enabled: true
                        //     style: {
                        //       fontSize: '8px' // Размер шрифта значений оси X при экспорте
                    }
                },
            },
        },
        credits: {
            enabled: false
        },
        chart: {
            id: 'cpr1',
            // unbindReflow: () => this,
            animation: false,
            marginLeft: 34,
            // marginTop: 0,
            marginBottom: 6,
            // marginRight: 34,
            // padding: 0,
            plotBorderColor: plotBorderColor,
            plotBorderWidth: 1,
            contextMenu: {
                enabled: false
            },
            events: {
                // load: function () {
                //     this.redraw()
                // }
                //     render: (e) => {
                //         if (e.target?.callback)
                //             e.target?.callback(e.target)
                //     },
            }
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            shape: "square",
            shared: true,
            trackMouse: true,
            animation: false,
            hideDelay: 0,
            useHTML: true,
            borderColor: style.colorPrimary3_60,
            zIndex: 2,
            style: {
                fontSize: '12px',
            },
            positioner: function (labelWidth, labelHeight, point) {
                // console.log('this.chart', this.chart)
                return {
                    x: this.chart.chartWidth - this.label.width - this.chart.marginRight - 7,
                    y: this.chart.plotTop + 5
                };
            },
            formatter: function () {
                // console.log('this.points', this.points) cvfd cv
                if (this?.points == null || !this?.points?.length) return

                const getPointValue = (point) => {
                    // console.log('point?.series?.userOptions?.id', point?.series?.userOptions?.id)
                    switch (point?.series?.userOptions?.id) {
                        case 'wac':
                            return ({ name: `${t('convention2.wacWithUnits')}`, value: Localization.FormatNumber(point.y, 2, 2), sortOrder: 1 })
                        case 'refinancingRate':
                            return ({ name: `${t('convention2.refinancingRate')}`, value: Localization.FormatNumber(point.y, 2, 2), sortOrder: 2 })
                        case 'keyRate':
                            return ({ name: `${t('convention2.keyRate')} `, value: Localization.FormatNumber(point.y, 2, 2), sortOrder: 3 })
                        case 'cpr':
                            return ({ name: `${t('convention2.cpr')}`, value: Localization.FormatNumber(point.y, 1, 1), sortOrder: 5 })
                        default:
                            return null //({ name: 'SERIES', value: point?.series?.userOptions?.id })
                    }

                }
                const date = new Date(this.x)
                const y = date.getFullYear()
                const m = date?.getMonth() + 1
                var points = [{ name: t('convention2.Month'), value: `${`${m}`.padStart(2, '0')}-${y}` },]
                this.points.forEach(point => {
                    const val = getPointValue(point)
                    if (val) points.push(val)
                })


                return points.sort((a, b) => a?.sortOrder < b?.sortOrder ? -1 : a?.sortOrder > b?.sortOrder ? 1 : 0)
                    // .filter((item, index) => {
                    //     if (index == 0 || (index != 0 && points[index - 1]?.sortOrder != item?.sortOrder))
                    //         return item
                    // })
                    .reduce(function (s, point) {
                        return s + `<div style='display:flex;flex-direction:row;justify-content:space-between;'>
                              <div>${point.name}</div>
                              <div style='margin-left:1rem;min-width:2rem;text-align:right;font-weight:bold;'>${point.value}</div>
                              </div>`
                        // return s + `<div style='display:flex;flex-direction:row;justify-content:space-between;'>
                        //       <div>${point.name}</div>
                        //       <div style='margin-left:1rem;min-width:2rem;text-align:right;font-weight:bold;'>${point.value}</div>
                        //       </div><br/>`
                    }, '')
            },
        },
        title: {
            text: '',
            //style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
        },
        xAxis: {
            type: 'datetime',
            visible: true,
            crosshair: {
                color: alpha(style.colorPrimary3, 1)
            },
            gridLineWidth: 1,
            gridLineColor: axisGridLineColor,
            lineWidth: 1,
            lineColor: axisLineColor,
            title: {
                enabled: false,
            },
            tickWidth: 0,
            labels: {
                enabled: false,
                rotation: 0,
                overflow: true,
                // allowOverlap: true,
                formatter: function () {
                    // console.log('this.value', this.value)
                    if (this.value == null)
                        return ''
                    const date = new Date(this.value)
                    // console.log('date', date)
                    const y = date.getFullYear()
                    const m = date?.getMonth()
                    const d = date?.getDate()
                    if (m == 0 && d == 1) {
                        // console.log('date?.getDate()', this.value, date, y, m, d)
                        return y
                    }

                    return null
                },
            },
            tickPositioner: function () {
                // console.log('min, max----------------------------')
                // const mi = roundDown(min - .2, 1)
                // const step = roundUp((ma - mi) / 4, 1)
                // // console.log('step', mi, ma, step)
                // const center = roundDown((ma + mi) / 2, 1)
                // // console.log('center', center)
                // const b = center - 2 * step
                // const t = center + 2 * step
                // let pos = []
                // for (let i = b; i <= t; i = i + step) {
                //     pos.push(i)
                // }
                return []
            },
            // startOnTick: true,
            minPadding: 0,
            maxPadding: 0,
        },
        yAxis: {
            // visible: false,
            lineWidth: 1,
            lineColor: axisLineColor,
            gridLineColor: axisGridLineColor,
            // gridLineWidth: 0,
            title: {
                enabled: false,
                text: t('convention2.percentInYearFull'),
                offset: 21,
                // y: 60
                style: {
                    fontSize: '12px',
                    color: axisTitleColor
                },
            },
            labels: {
                x: -4, // Adjust the margin near the axis values
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none',
                    color: axisLabelsColor,
                    fontSize: '11px',
                },
            },
            tickPositioner: (min, max) => {
                const ma = roundUp(max + .2, 1)
                const mi = roundDown(min - .2, 1)
                const step = roundUp((ma - mi) / 4, 1)
                // console.log('step', mi, ma, step)
                const center = roundDown((ma + mi) / 2, 1)
                // console.log('center', center)
                const b = center - 2 * step
                const t = center + 2 * step
                let pos = []
                for (let i = b; i <= t; i = i + step) {
                    pos.push(i)
                }
                return pos
            },
        },
        plotOptions: {
            series: {
                connectNulls: false,
                events: {
                    legendItemClick: function () {
                        return false; // Prevent the default legendItemClick behavior
                    }
                }
            }
        },
        series: []
    })
    const [bottomChartOptions, setBottomChartOptions] = useState({
        exporting: {
            enabled: false,
            scale: 1,
            chartOptions: {
                chart: {
                    width: 1200,
                    height: 365,
                    marginLeft: 40, // Keep all charts left aligned
                    // marginRight: 50,
                    padding: 0,
                    plotBorderWidth: 0,
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                yAxis: {
                    title: {
                        enabled: true
                        //     style: {
                        //       fontSize: '8px' // Размер шрифта значений оси X при экспорте
                    }
                },
            },
        },
        credits: {
            enabled: false
        },
        chart: {
            id: 'cpr2',
            // unbindReflow: () => this,
            animation: false,
            marginLeft: 34,
            marginTop: 6,
            // marginRight: 34,
            // padding: 0,
            plotBorderColor: plotBorderColor,
            plotBorderWidth: 1,
            contextMenu: {
                enabled: false
            },
            events: {
                render: function (e) {
                    if (e.target?.callback)
                        e.target?.callback(e.target)
                    this.options.tooltip.style = { zIndex: 200 }

                },
                load: function () {
                    this.options.tooltip.style = { zIndex: 200 }
                    // this.redraw()
                },
            }

        },
        legend: {
            useHTML: true,
            margin: 0,
            y: 15,
            itemHoverStyle: {
                textDecoration: 'none',
            },
            itemHiddenStyle: {
                color: style.colorPrimary1,
                textDecoration: 'none',
            },
            itemStyle: {
                fontSize: '12px',
                fontWeight: 'bold',
                fill: style.colorPrimary1,
                color: style.colorPrimary1,
                paddingLeft: 0,
                paddingRight: 0,
                cursor: 'default',
                textDecoration: 'none'
                // color: style.colorPrimary3// Задать цвет легенды
            },
            itemEvents: {
                mouseOver: function () {
                    return false; // Отключить подсветку при наведении на легенду
                }
            }
        },
        tooltip: {
            enabled: false,
            // shared: true,           
        },
        title: {
            text: '',
            //style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
        },
        xAxis: {
            type: 'datetime',
            visible: true,
            crosshair: {
                color: alpha(style.colorPrimary3, 1)
            },
            min: -Infinity,
            max: +Infinity,
            tickLength: 0,
            // tickColor: alpha(style.colorPrimary3, 0.2),
            // tickWidth: 0,
            gridLineWidth: 1,
            gridLineColor: axisGridLineColor,
            lineWidth: 1,
            lineColor: axisLineColor,
            title: {
                enabled: false
            },
            labels: {
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none',
                    color: axisLabelsColor,
                    fontSize: '11px',
                },
                y: 15,
                rotation: 0,
                overflow: true,
                allowOverlap: true,
                formatter: function () {
                    // console.log('this.value', this.value)
                    if (this.value == null)
                        return ''
                    const date = new Date(this.value)
                    // console.log('date', date)
                    const y = date.getFullYear()
                    const m = date?.getMonth()
                    const d = date?.getDate()
                    if (m == 0 && d == 1) {
                        // console.log('date?.getDate()', this.value, date, y, m, d)
                        return y
                    }

                    return null
                }
            },

            // startOnTick: true,
            minPadding: 0,
            maxPadding: 0,
        },
        yAxis: {
            // visible: false,
            lineWidth: 1,
            lineColor: axisLineColor,
            gridLineColor: axisGridLineColor,
            // gridLineWidth: 0,
            title: {
                enabled: false,
                text: t('convention2.percentInYearFull'),
                offset: 21,
                // y: -60,
            },
            labels: {
                x: -4, // Adjust the margin near the axis values
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none',
                    color: axisLabelsColor,
                    fontSize: '11px',
                },
            },
            // reversedStacks: false,
            tickPositioner: (min, max) => {
                const ma = roundUp(max + .2, 1)
                const mi = roundDown(min - .2, 1)
                const step = roundUp((ma - mi) / 4, 1)
                // console.log('step', mi, ma, step)
                const center = roundDown((ma + mi) / 2, 1)
                // console.log('center', center)
                const b = center - 2 * step
                const t = center + 2 * step
                let pos = []
                for (let i = b; i <= t; i = i + step) {
                    pos.push(i)
                }
                return pos
            },
            plotLines: null
        },
        plotOptions: {
            series: {
                events: {
                    legendItemClick: function () {
                        return false; // Prevent the default legendItemClick behavior
                    }
                }
            }
        },
        series: []
    })

    // console.log('bottomChartOptions.series[3]', bottomChartOptions?.series[3]?.data)
    // console.log('cprGraph', cprGraph)
    // console.log('bottomChartOptions', chart2Ref?.current?.chart)

    useLayoutEffect(() => {
        Highcharts.Point.prototype.highlight = function (event) {
            if (this?.series) {
                this.onMouseOver()
                // console.log('event', this, event)
                // var point = this.hoverPoint;
                // if (point) {
                //     console.log('Hovering over point:', point.x, point.y);
                // }
            }
        }

        Highcharts.getSVG = function (charts, options, callback) {
            let top = 0,
                width = 0;

            const svgArr = [],
                addSVG = function (svgres) {
                    // Grab width/height from exported chart
                    const svgWidth = +svgres.match(
                        /^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/
                    )[1],
                        svgHeight = +svgres.match(
                            /^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/
                        )[1];

                    // Offset the position of this chart in the final SVG
                    let svg = svgres.replace(
                        '<svg',
                        `<g transform="translate(0,${top})" `
                    );
                    svg = svg.replace('</svg>', '</g>');
                    top += svgHeight;
                    width = Math.max(width, svgWidth);
                    svgArr.push(svg);
                },
                exportChart = function (i) {
                    if (i === charts.length) {
                        return callback(
                            `<svg version="1.1" width="${width}" height="${top}"
                                    viewBox="0 0 ${width} ${top}"
                                    xmlns="http://www.w3.org/2000/svg">
                                ${svgArr.join('')}
                            </svg>`
                        );
                    }
                    charts[i].getSVGForLocalExport(options, {}, function () {
                        // console.log('Failed to get SVG');
                    }, function (svg) {
                        addSVG(svg);
                        // Export next only when this SVG is received
                        return exportChart(i + 1);
                    });
                };
            exportChart(0);
        };

        /**
         * Create a global exportCharts method that takes an array of charts as an
         * argument, and exporting options as the second argument
         */
        Highcharts.exportCharts = function (charts, options) {
            options = Highcharts.merge(Highcharts.getOptions().exporting, options);

            // Get SVG asynchronously and then download the resulting SVG
            Highcharts.getSVG(charts, options, function (svg) {
                Highcharts.downloadSVGLocal(svg, options, function () {
                    console.log('Failed to export on client side');
                });
            });
        };

        // Set global default options for all charts
        Highcharts.setOptions({
            exporting: {
                // Ensure the export happens on the client side or not at all
                fallbackToExportServer: false
            }
        });

    }, [])


    useLayoutEffect(() => {
        Highcharts.charts.forEach(chart => {
            if (chart && (chart?.userOptions?.chart?.id == /*name /*/'cpr1' || chart?.userOptions?.chart?.id == 'cpr2')) {
                chart.container.addEventListener('mousemove', (e) => syncCrosshair(e))
                if (chart?.userOptions?.chart?.id == 'cpr1' || chart?.userOptions?.chart?.id == 'cpr2')
                    chart.pointer.reset = function (e, c) {
                        return false
                    }
                // chart.redraw()
            }
        })

        return ((e) => {
            Highcharts.charts.forEach(chart => {
                if (chart && (chart?.userOptions?.chart?.id == /*name /*/'cpr1' || chart?.userOptions?.chart?.id == 'cpr2')) {
                    chart.container.removeEventListener('mousemove', (e) => syncCrosshair(e))
                }
            })
        })

    }, [topChartOptions, bottomChartOptions])

    const syncCrosshair = (e, name) => {
        for (let i = 0; i < Highcharts.charts.length; i++) {

            const chart = Highcharts.charts[i];

            if (chart?.userOptions?.chart?.id != 'cpr1' && chart?.userOptions?.chart?.id != 'cpr2')
                continue
            if (chart?.pointer == null)
                continue;

            const event = chart.pointer.normalize(e);
            const series = chart.series.find(s => s.visible == true) //(s.userOptions.id == 'cpr') || (s.userOptions.id == 'refinancingRate'))
            const point = series.searchPoint(event, true);

            if (point/* && point?.series?.data[point?.index].y != null*/) {
                point.highlight(event);
            }
            // else {
            //     console.log('point', point, 'event', event)
            //     // Highcharts.Pointer.prototype.reset.call(chart2Ref.current.chart);
            // }
        }
    }

    const showDetailedSCurveChartHandle = () => {
        setShowDetailedSCurveChart(true)
    }

    const hideDetailedSCurveChart = () => {
        setShowDetailedSCurveChart(false)
    }



    const exportSCurveToExcel = () => {
        // actionDownloads.addDownload(`api/Convention/ExportSCurvesToExcel?date=${calculationParameters?.calculationSCurvesReportDate}`)(dispatch)
        actionAlert.addAlert({ component: <DownloaderAlert />, key: `downloadAlert_${Date.now()}`, id: `downloadAlert_${Date.now()}`, link: `api/Convention/ExportSCurvesToExcel?date=${calculationParameters?.calculationSCurvesReportDate}`, color: style.colorPrimary1 })(dispatch)
    }

    const onSaveAsImageButtonClick = useCallback((event) => {
        if (chart1Ref?.current && chart2Ref?.current) {
            const chart1 = chart1Ref?.current?.chart
            const chart2 = chart2Ref?.current?.chart

            Highcharts.exportCharts([chart1, chart2], { scale: 1, filename: `${t('convention2.modelCPRChartTitle')}${pricingParameters?.bondID}` })
        }
    }, [chart1Ref?.current, chart2Ref?.current])

    const outHandler = () => {
        for (var i = 0; i < Highcharts.charts.length; i++) {
            var chart = Highcharts.charts[i];
            if (chart && (chart?.userOptions?.chart?.id == /*name /*/'cpr1' || chart?.userOptions?.chart?.id == 'cpr2')) {

                if (chart == null ||
                    (chart != chart1Ref?.current?.chart &&
                        chart != chart2Ref?.current?.chart))
                    continue;

                if (chart.hoverSeries != null) {
                    Highcharts.Pointer.prototype.reset.call(chart.pointer);
                }
            }
        }
    }

    const clearModelKeyrate = () => {
        // console.log('---------------------------- установка useModelKeyrate FALSE -----------')
        // actionConvention.setData({ useModelKeyrate: false })(dispatch)
        // newDispatch(setConventionData({ useModelKeyrate: false }))
        // actionConvention.clearValue('modelKeyrate')(dispatch)
        newDispatch(setConventionData({ modelKeyrate: null, useModelKeyrate: false }))
    }


    // console.log('useModelKeyrate---panel----', useModelKeyrate) 

    return <>
        <Modal
            className={cls.modal}
            open={showKeyratePanel} onClose={() => setShowKeyratePanel(false)}>
            <Box className={cls.rootModal}>
                <KeyratePanel
                    hidePanel={() => setShowKeyratePanel(false)}
                />
            </Box>
        </Modal>
        <Modal
            className={cls.modal}
            open={showDetailedSCurveChart} onClose={() => hideDetailedSCurveChart()}>
            <Box className={cls.rootModal}>
                <Box className={cls.rootPaper}>
                    <Box className={cls.panelHeader}>
                        <Box className={cls.panelHeaderTitle}>
                            {`${t('convention2.sCurvesChartName')} на ${Localization.FormatDateStringLocale(calculationParameters?.calculationSCurvesReportDate)}`}
                        </Box>
                        <Box className={cls.panelHeaderButtons}>

                            <span className={cls.switchContainer} onClick={() => setShowDebt(!showDebt)}>
                                <Box className={[cls.switchLabel, !showDebt && cls.disabled].join(' ')}>
                                    {t('convention2.debtVal')}
                                </Box>
                                <UniversalInputField
                                    classes={{ root: cls.switchRoot }}
                                    dataType={InputDataType.BOOLEAN}
                                    value={!showDebt}
                                    handleValueChange={(name, value) => { } /*setShowDebt(!value)*/}
                                />
                                <Box className={[cls.switchLabel, showDebt && cls.disabled,/* cls.mr05*/].join(' ')}>
                                    {t('convention2.mortgageCover')}
                                </Box>
                            </span>
                            <Box>
                                <StyledButton
                                    variant="outlined"
                                    size="small"
                                    // disabled={chartData?.steps == null}
                                    onClick={() => window.open('/files/Конвенция ИЦБ ДОМ.РФ.pdf#sCurvesEstimation', '_blank')}
                                >
                                    {t("convention2.SCurveGrade")}
                                </StyledButton>
                            </Box>
                            {(new Date(calculationParameters?.calculationSCurvesReportDate)?.getFullYear() >= 2024) &&
                                <Box /* className={cls.mr05}*/>
                                    <Tooltip
                                        title={t('convention2.exportSCurveToExcelTooltip')}
                                        enterDelay={0} leaveDelay={0}
                                        TransitionComponent={null}
                                        TransitionProps={{ timeout: 0 }}
                                    >
                                        <StyledButton
                                            variant="outlined"
                                            size="small"
                                            // disabled={chartData?.steps == null}
                                            onClick={exportSCurveToExcel}
                                        >
                                            {t("convention2.exportSCurveToExcel")}
                                        </StyledButton>
                                    </Tooltip>

                                    {/* <Tooltip
                                    title={t('convention2.exportSCurveToExcel')}
                                    disableInteractive
                                    disableFocusListener
                                    classes={{ tooltip: cls.wideTooltip }}
                                >
                                    <IconButton
                                        disabled={false}
                                        size='small'
                                        onClick={exportSCurveToExcel}
                                    >
                                        <ExcelIcon className={cls.icon} />
                                    </IconButton>
                                </Tooltip> */}
                                </Box>
                            }
                            {/* кнопка Save */}
                            {<Tooltip
                                disableFocusListener
                                disableInteractive
                                title={t('convention2.SaveAsImage')}
                                enterDelay={0} leaveDelay={0}
                                TransitionComponent={null}
                                TransitionProps={{ timeout: 0 }}
                            >
                                <IconButton size="small"
                                    disabled={!readyForExport}
                                    onClick={() => ref.current.exportChart()}
                                >
                                    <SaveOutlined className={cls.icon} />
                                </IconButton>
                            </Tooltip>
                            }
                            <IconButton size="small" onClick={hideDetailedSCurveChart}>
                                <Close className={cls.icon} />
                            </IconButton>
                        </Box>
                    </Box>
                    <DetailedSCurveChartV2 ref={ref} showDebt={showDebt} setReadyForExport={setReadyForExport} />
                </Box>
            </Box>
        </Modal >
        <Box className={cls.root}>
            <Box className={cls.rootPaper}>
                <Box className={cls.panelHeader}>
                    {/* {console.log('modelKeyrate', modelKeyrate)} */}

                    {Boolean(!calculationParameters?.calculationSCurvesReportDate) ?
                        // {Boolean(!sCurveGraph?.hasOwnProperty('sCurvesEmpiricalCPR')) ?
                        <Box className={cls.panelHeaderTitle}>
                            {t('convention2.cprChartPanelTitile')}
                        </Box>
                        :
                        <>
                            <Box className={cls.panelHeaderTitle}>
                                {pricingParameters?.cpr === null ?
                                    <CustomTooltip
                                        // hideIcon
                                        classes={{ popper: cls.wideTooltip }}
                                        sx={{ '& .MuiButtonBase-root': { marginLeft: '4px', }, '& svg': { color: style.colorPrimary3_40, width: '20px', height: '20px', } }}
                                        right
                                        content={getTooltip({ tooltip: 'convention2.cprChartPanelTitileTooltip', source: { ...calculationParameters, reportDate: poolStatistics.reportDate, isin: selectedBond?.isin } })}
                                    // enterDelay={0} leaveDelay={0}
                                    >
                                        {`${t('convention2.cprChartPanelTitile')}`}
                                    </CustomTooltip>
                                    : <>
                                        {`${t('convention2.cprChartPanelTitile')}`}
                                        <Tooltip
                                            classes={{ popper: cls.wideTooltip }}
                                            hideIcon
                                            title={t('convention2.cprChartPanelTitileTooltip1')}
                                        >
                                            <WarningAmber htmlColor='rgb(255,128,0)' className={cls.icon} sx={{ marginLeft: '4px', cursor: 'pointer' }} />
                                        </Tooltip>
                                    </>
                                }
                            </Box>
                            <Box>
                                {useModelKeyrate &&
                                    <Tooltip
                                        disableFocusListener
                                        // disableInteractive
                                        title={t('convention2.modelKeyrateReplayTooltip')}
                                        classes={{ tooltip: cls.wideTooltip }}
                                        enterDelay={0} leaveDelay={0}
                                        TransitionComponent={null}
                                        TransitionProps={{ timeout: 0 }}
                                    >
                                        <IconButton
                                            size="small"
                                            // disabled={modelKeyrate == null}
                                            onClick={() => {
                                                clearModelKeyrate()
                                            }}
                                            className={cls.mr05}
                                        >
                                            <Replay
                                                className={cls.icon} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip
                                    title={t('convention2.setupKeyrateTooltip')}
                                    enterDelay={0} leaveDelay={0}
                                    TransitionComponent={null}
                                    TransitionProps={{ timeout: 0 }}
                                >
                                    <StyledButton
                                        variant="outlined"
                                        size="small"
                                        // disabled={chartData?.steps == null}
                                        onClick={() => { setShowKeyratePanel(true) }}
                                    >

                                        {t("convention2.setupKeyrate")}
                                    </StyledButton>
                                </Tooltip>

                                <Tooltip
                                    title={t('convention2.zoomSCurveChart')}
                                    enterDelay={0} leaveDelay={0}
                                    TransitionComponent={null}
                                    TransitionProps={{ timeout: 0 }}
                                >
                                    <StyledButton
                                        variant="outlined"
                                        size="small"
                                        // disabled={chartData?.steps == null}
                                        onClick={showDetailedSCurveChartHandle}
                                    >
                                        {t("convention2.sCurvesGraphics")}
                                    </StyledButton>
                                </Tooltip>

                                <Tooltip
                                    disableFocusListener
                                    disableInteractive
                                    title={t('SaveAsImage')}
                                    enterDelay={0} leaveDelay={0}
                                    TransitionComponent={null}
                                    TransitionProps={{ timeout: 0 }}
                                >
                                    <IconButton size="small" disabled={topChartOptions == null} onClick={onSaveAsImageButtonClick}>
                                        <SaveOutlined className={cls.icon} />
                                    </IconButton>
                                </Tooltip>

                            </Box>
                        </>
                    }
                </Box>
                {loading && <Box className={cls.coverLayout} />}
                {Boolean(!calculationParameters?.calculationSCurvesReportDate) ?
                    <Box className={cls.fullPanelAlert}>
                        {selectedBond?.isin == 'RU000A100DQ4' ?
                            <div dangerouslySetInnerHTML={{ __html: t('Convention2_sCurveChartDQ4Alert') }} />
                            :
                            <div dangerouslySetInnerHTML={{ __html: t('Convention2_sCurveChartNotDQ4Alert') }} />
                        }
                    </Box>
                    :
                    <Box className={cls.chartsContainer}
                        id="chartsContainer"
                            /*onMouseMove={(e) => syncCrosshair(e, null)}*/ onMouseLeave={outHandler}
                    >
                        <Box className={cls.chart1Container}/* onMouseMove={(e) => syncCrosshair(e, 'cpr2')}*/>
                            <HighchartsReact
                                ref={chart1Ref}
                                highcharts={Highcharts}
                                containerProps={{ style: { height: "100%", width: "100%" } }}
                                options={topChartOptions ?? {}}
                                callback={(chart) => {
                                }}
                            />
                        </Box>
                        <Box className={cls.chart2Container}/* onMouseMove={(e) => syncCrosshair(e, 'cpr1')}*/>
                            <HighchartsReact
                                ref={chart2Ref}
                                highcharts={Highcharts}
                                containerProps={{ style: { height: "100%", width: "100%" } }}
                                options={bottomChartOptions ?? {}}
                                callback={(chart) => {
                                    if (chart?.legend) {
                                        var legend = chart.legend
                                        legend.allItems.forEach(function (item) {
                                            // console.log('item.legendItem', item.legendItem)
                                            item.legendItem.line.element.style.stroke = item?.userOptions?.color
                                            // item.legendItem.label.element.style.textDecoration = ''
                                        })
                                    }
                                    // chart.redraw()
                                }
                                }
                            />
                        </Box>
                        <Box className={cls.yAxisName}>{t('convention2.percentInYearFull')}</Box>
                    </Box>
                }
            </Box>
        </Box >
    </>
}



export default CPRChartPanel