import React, { useState, useRef, useEffect } from 'react'
import { Button, ButtonBase, ClickAwayListener, createStyles, Fade, IconButton, Popper, Theme } from '@mui/material';
import style from '@Styles/domrf-theme.module.scss';
import { HelpOutline } from '@mui/icons-material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Globals from '../../Globals';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';
import Localization from '../Localization';


// const useStyles=createStyles()=>{
const useStyles = makeStyles({
    tooltip: {
        userSelect: 'none',
        cursor: 'help',
        position: 'relative',
        borderBottom: 'dashed 1px #0000',
        width: 'min-content',
        whiteSpace: 'nowrap',
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '-1px', left: 0, right: 0,
            borderBottom: 'dashed 1px grey',
            transition: 'opacity 0.2s ease',
            opacity: 0,
        },
        '&:hover': {
            '&:after': {
                opacity: 1
            }
        },
    },
    active: {
        opacity: 1,
        borderBottom: 'dashed 1px grey',
        // marginBottom: '-1px',
        // borderSpacing: '-1px',
    },
    /* Styles applied to the root element. */
    root: {
        cursor: 'pointer',
        display: 'inline-flex',
        justifyContent: 'flex-start',
        flexDirection: 'inherit',
    },
    /* Styles applied to the icon component. */
    icon: {
        fontSize: 18,
        //marginLeft: 15,
        userSelect: 'none',
        color: style.colorPrimary3,
        opacity: 0.8
    },
    'MuiPopperRoot': {

    },
    button: {
        color: 'white'
    },
    buttonContainer: {
        textAlign: 'end',
        marginTop: '4px'
    },
    popper: {
        zIndex: +999999,
        backgroundColor: '#616161eb',
        padding: '7px !important',
        margin: '4px !important',
        maxWidth: '300px',
        borderRadius: '4px',
        color: '#FFF',
        lineHeight: `calc( ${style.fontSizePrimary} + 2px)`,
        fontSize: style.fontSizePrimary,
        fontFamily: style.fontFamilyBase
    }

    //children: {
    //cursor: 'pointer'
    //}
})

interface IProps {
    className?: object | string
    id?: string
    children?: any
    classes?: any
    hideIcon?: boolean
    IconComponent?: any
    content: any
    sx?: any
    tReady?: any
    link?: string
    linkName?: string
    onLinkClick?: () => void
    right?: boolean
    placement?: any
    popperSx?: any
    hideUnderline?: boolean
}

const getTooltip = (row) => {
    try {
        if (row?.tooltip == null)
            return

        let tooltip = t(row.tooltip)
        // const reg = new RegExp('\S', 'g')

        // const entriest = Array.from(tooltip.matchAll(/(\{(\S+)\})/g)) // $1 - {name} $2 = name
        // entriest.forEach(entrie => {
        //     console.log(entrie[1])
        // })
        const replaceParam = (item, p1, p2) => {
            const format = p2.split(':')
            // console.log('format', format)
            const types = format[0].split('.')
            // console.log('types', types)
            const data = row?.source ?
                format?.length > 1 ? row?.source[format[1]] : row?.source[p2]
                :
                ''
            let url = ''
            switch (types[0]) {
                case 'http':
                case 'https':
                    // console.log('data', data, format, types[0])
                    return `<a href=${types[0]}://${format[1]} target='_blank'>${types[1]?.replaceAll('_', ' ')}</a>`
                case 'httpInt':
                case 'httpsInt':
                    url = `${window.location.protocol}//${window.location.host}${format[1]}`
                    return `<a href="${url}" target='_blank'>${types[1]?.replaceAll('_', ' ')}</a>`
                case 'date':
                    // example {date:wacDate}
                    return Localization.FormatDateStringLocale(data)

                case 'datetime':
                    // example {datetime:wacDate}
                    return Localization.FormatDateTimeStringLocale(data)

                case 'f':
                    // example {f.2:wac}
                    return Localization.FormatNumber(data, types[1], types[1])

                case 'd':
                    // example {f.2:wac}
                    return Localization.FormatNumber(data, 0, 0)

                default:
                    return data
            }
        }

        tooltip = tooltip.replaceAll(/(\{([^\}]+)\})/g, replaceParam)
        // console.log('tooltip------------------------------', tooltip)
        return tooltip

    } catch (error) {
        console.error(error)
    }
}

function CustomTooltip(props: IProps) {
    const {
        id,
        children,
        // cls,
        IconComponent = props.IconComponent ? props.IconComponent : HelpOutline,
        hideIcon = false,
        content,
        sx,
        tReady,
        link,
        linkName,
        onLinkClick,
        right,
        placement,
        className,
        classes,
        popperSx,
        hideUnderline = false,

        ...otherProps
    } = props



    const cls = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false)
    const refEl = useRef()

    useEffect(() => {
        if (open) {
            document.body.addEventListener('wheel', handleTooltipClose, false);
            // document.body.addEventListener('touchstart', handleTooltipClose, false);
        }
        return () => {
            document.body.removeEventListener('wheel', handleTooltipClose, false);
            // document.body.removeEventListener('touchstart', handleTooltipClose, false);
        }
    }, [open])

    const handleTooltipOpen = () => {
        if (open)
            return

        setOpen(prev => !prev)
        if (refEl.current && !hideUnderline) refEl.current?.classList.add(cls.active);
    }
    const handleTooltipClose = () => {
        setOpen(false)
        if (refEl.current && !hideUnderline) refEl.current?.classList.remove(cls.active);
    }

    const handleClick = () => {
        let reg = new RegExp("^(http|https)://", "i")
        const externalLink = reg.test(link)
        if (externalLink)
            window.open(link, "_blank")
        else
            window.open(Globals.WikiServer + '/' + link, "_blank");
    }

    return <>
        {open &&
            <ClickAwayListener disableReactTree onClickAway={handleTooltipClose}  >
                <Popper
                    key={id ? id : new Date().getTime().toString()}
                    anchorEl={refEl.current}
                    open={open}
                    transition
                    disablePortal={false}
                    sx={{ zIndex: '+100000 !important', ...popperSx }}
                    // className={classes?.popper}
                    placement={placement}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={250}>
                            <Box className={[cls.popper, classes?.popper].join(' ')}>
                                <div dangerouslySetInnerHTML={{ __html: content }}></div>
                                {
                                    (link != null || onLinkClick != null) && (
                                        <div className={cls?.buttonContainer}>
                                            <Button variant="contained" color="secondary" onClick={onLinkClick || handleClick}>{linkName || t('Article')}</Button>
                                        </div>
                                    )
                                }
                            </Box>
                        </Fade>
                    )}
                </Popper>
            </ClickAwayListener>
        }

        {(content != null) ? ((!hideIcon) ?
            <Box sx={{
                display: 'flex',
                ...sx,
                ...(right ? { flexDirection: 'row-reverse' } : { flexDirection: 'row' })
            }}
                {...otherProps}
            >
                <ButtonBase
                    className={cls.root}
                    component="span"
                    disableRipple
                    onClick={handleTooltipOpen}
                    sx={{ verticalAlign: 'sub', ...(!right ? { mr: '10px', float: 'right' } : { ml: '10px' }) }}
                    ref={refEl}
                >
                    <IconComponent
                        className={cls?.icon}
                    />

                    {/* <HelpOutline
                        className={cls?.icon}
                    />} */}
                </ButtonBase>
                {children}
            </Box>
            :
            <Box component="span"
                sx={sx}
                className={[cls.tooltip, className, classes?.tooltip].join(' ')}
                {...otherProps}
                onClick={handleTooltipOpen}
                ref={refEl}
            >
                {children}
            </Box>
        ) : (
            <React.Fragment>
                {children}
            </React.Fragment>)
        }
    </>;
}

export default CustomTooltip

export { CustomTooltip, getTooltip }