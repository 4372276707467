import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles } from '@mui/styles'
import React, { Fragment, cloneElement } from 'react'
import NotificationAlert from './NotificationAlert'

const useStyles = makeStyles(theme => createStyles({
    rootLayout: {
        pointerEvents: 'none',
        position: 'absolute', left: 0, right: 0, top: '70px', bottom: 0,
        zIndex: '9999',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
    }
}))
const Notifications = () => {
    const { alerts = [] } = useSelector(state => state.alerts)
    const cls = useStyles()

    // console.log('alerts', alerts)
    return (
        <Box className={cls.rootLayout}>
            {alerts.map(({ message: { component = null, ...alert }, ...params }, index) => {
                // console.log('component', component)
                // console.log('alert', alert)
                let Comp = <></>
                if (component != null) {
                    Comp = React.cloneElement(
                        component, { ...params, ...alert }
                    )
                    return Comp
                } else {
                    // console.log('alert', alert)
                    return <NotificationAlert  {...params} {...alert}  /> // <Fragment key={`notification_${index}_${Date.now()}`}></Fragment>
                }
            })}
        </Box >
    )
}

export default Notifications