/* eslint-disable */
import React, { useState, useEffect, useContext, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
//import PropTypes from 'prop-types';
//import Promise from 'promise-polyfill';
/* eslint-enable */
//import { polyfill } from 'react-lifecycles-compat';
import { FormContext, FormContextType } from './ValidatorForm';
import { debounce } from 'throttle-debounce';

const useFormValidation = (
    disabled,
    name,
    value,
    errorMessages,
    validators
) => {

    const [updateCounter, setUpdateCounter] = useState(0);

    const context: FormContextType = useContext<FormContextType>(FormContext);

    const localData = useRef({
        instantValidate: true,
        debounceTime: 200,
        isValid: true,
        localValidators: validators,
        localErrorMessages: errorMessages,
        globalErrorMessage: null,
        value: value,
        invalidRuleIndex: null,
        deferredState: null
    });

    // console.log('name, isValid', name, localData?.current?.isValid)
    const handle = useRef(null);

    useEffect(() => {

        if (context?.form) {

            //console.log("=============attaching " + name);
            context.form.attachToForm(handle);
            localData.current.instantValidate = context.form.instantValidate;
            localData.current.debounceTime = context.form.debounceTime;
        }

        return () => {
            detachFromForm();
        };

    }, []);

    useEffect(() => {

        //console.log(`----settings changed to ${errorMessages}, ${validators}`);

        /*        
        var bIsUpdateNotRequired = checkUpdateState(undefined, validators, errorMessages);
        */
        localData.current.localValidators = validators;
        localData.current.localErrorMessages = errorMessages;

        /*
        //do not validate if no value set
        if (!bIsUpdateNotRequired && localData.current.instantValidate) {
            validateDebounced(false);
        }
        */

    }, [errorMessages, validators]);

    useEffect(() => {

        if (localData.current.value === value)
            return;

        localData.current.value = value;

        if (localData.current.instantValidate) {
            validateDebounced.current(false);
        }

        // console.log(`---${name}----value changed to ${value}`);

    }, [value]);

    const detachFromForm = () => {

        validateDebounced.current?.cancel();

        //console.log("=============detaching " + name);
        if (context?.form) {

            //console.log("=============form exists");
            context.form.detachFromForm(handle);
        }

        localData.current = {
            instantValidate: true,
            debounceTime: null,
            isValid: true,
            localValidators: undefined,
            localErrorMessages: undefined,
            value: undefined,
            invalidRuleIndex: null,
            globalErrorMessage: null,
            deferredState: null
        };
    }

    const getErrorMessage = () => {

        if (localData.current.isValid === false) {
            if (localData.current.globalErrorMessage != null)
                return localData.current.globalErrorMessage;

            // console.log(localData.current)
            if (localData.current.invalidRuleIndex != null) {
                return localData.current.localErrorMessages[localData.current.invalidRuleIndex];
            }

            return '';
        }

        return null;
    }

    const checkDefferedState = () => {
        if (localData.current.deferredState != null)
            return;

        localData.current.deferredState = {
            isValid: null,
            invalidRuleIndex: null,
            globalErrorMessage: null
        }
    }

    const validate = (dryRun = false) => {

        if (context?.form == null)
            return Promise.resolve({ name: '', isValid: true });

        if (localData.current == null)
            return Promise.resolve({ name: '', isValid: false });

        //disabled fields always valid
        if (disabled || localData.current.localValidators == null || localData.current.localValidators.length == 0) {

            var stateChanged = localData.current.isValid !== true;

            if (stateChanged === true) {
                checkDefferedState();
                localData.current.deferredState.isValid = true;
                localData.current.deferredState.invalidRuleIndex = null;
            }

            //localData.current.isValid = true;

            //console.log(`====set ${name}, to isValid: ${localData.current.isValid}`);
            //localData.current.globalErrorMessage = null;
            //localData.current.invalidRuleIndex = null;

            if (!dryRun) {

                //if (localData.current.instantValidate) {

                context.form.onChildStateChanged(name, localData.current.value, localData.current.deferredState?.isValid ?? localData.current.isValid, stateChanged);

                //if (stateChanged && localData.current.globalErrorMessage == null) {
                //
                //    //console.log('===ValidStateUpdate name: ' + name + ', value: ' + localData.current.value + ' ,stateChanged: ' + stateChanged + ' , isValid: ' + localData.current.isValid + ' valid: true ' + ', error message: ' + getErrorMessage());
                //
                //    console.log(`====RERENDER on validate 1 name: ${name}, value: ${localData.current.value}, isValid: ${localData.current.isValid}, isStateChanged: ${stateChanged}`);
                //    setUpdateCounter(prev => prev + 1);
                //}

                //console.log('--1--localIsValid: ' + true + ' is valid: ' + true);

            }

            return Promise.resolve({ name: name, isValid: localData.current.deferredState?.isValid ?? localData.current.isValid });
        }

        const validations = Promise.all(localData.current.localValidators?.map(validator => context.form.getValidator(validator, localData.current.value)));

        let valid = true;
        let prevRuleIndex = localData.current.invalidRuleIndex;
        let nextRuleIndex = null;
        //localData.current.invalidRuleIndex = null; //rule index
        //localData.current.globalErrorMessage = null; //global message

        return validations.then((results) => {
            for (let key = 0; key < results.length; key++) {
                if (!results[key]) {
                    valid = false;
                    nextRuleIndex = key;
                    break;
                }
            };

            var stateChanged = localData.current.isValid !== valid || prevRuleIndex !== nextRuleIndex; // || (valid && !localData.current.isGlobalValid);// || localData.current.isFirstRun;

            if (stateChanged === true) {
                checkDefferedState();
                localData.current.deferredState.isValid = valid;
                localData.current.deferredState.invalidRuleIndex = nextRuleIndex;
            }

            //localData.current.isValid = valid;
            //console.log(`====set ${name}, to isValid: ${localData.current.deferredState?.isValid}`);
            //localData.current.isFirstRun = false; //remove the flag

            if (!dryRun) {

                //console.log('--2--localIsValid: ' + localData.current.isValid + ' is valid: ' + valid);

                //if (localData.current.isValid !== true || valid !== true) {

                //console.log('===ValidStateUpdate name: ' + name + ', value: ' + localData.current.value + ' , isValid: ' + localData.current.isValid + ' valid: ' + valid + 'stateChanged: ' + stateChanged + ', error message: ' + getErrorMessage());

                //if (localData.current.instantValidate) {

                context.form.onChildStateChanged(/*handle,*/ name, localData.current.value, valid, stateChanged);

                //if (stateChanged && localData.current.globalErrorMessage == null) { //we don't have global rules - we can validate manually. Otherwise, global validation will be removed at form level
                //
                //    console.log(`====RERENDER on validate 2, name: ${name}, value: ${localData.current.value}, isValid: ${localData.current.isValid}, isStateChanged: ${stateChanged}`);
                //    setUpdateCounter(prev => prev + 1);
                //}
                //}
                //}
            }

            //console.log('validate field: ' + name + ' is valid: ' + valid);

            return { name: name, isValid: localData.current.deferredState?.isValid ?? localData.current.isValid };
        });
    }

    //debounce here is just a setTimeout using debounce with memo causes problems with a stale closures at dependent functions, like a onFieldChanged()
    const validateDebounced = useRef(debounce(localData.current.debounceTime, validate));

    const getState = () => {
        return { name: name, value: localData.current.value, isValid: localData.current.deferredState?.isValid ?? localData.current.isValid };
    }

    const setState = (valid, message) => {

        //var needToUpdate = (localData.current.isValid != valid || localData.current.globalErrorMessage != message);

        //console.log(`====SET STATE update field: ${name} message: ${message} isValid: ${localData.current.isValid}`)
        checkDefferedState();
        localData.current.deferredState.isValid = valid;
        localData.current.deferredState.globalErrorMessage = message;
        localData.current.deferredState.invalidRuleIndex = null;

        //if (needToUpdate) {
        //    console.log(`====RERENDER on request field: ${name} isValid: ${valid} needToUpdate: ${needToUpdate} message: ${message}`)
        //    setUpdateCounter(prev => prev + 1);
        //}
    }

    const updateState = () => {

        if (localData.current.deferredState == null)
            return;

        var isChanged = localData.current.isValid != localData.current.deferredState.isValid ||
        localData.current.invalidRuleIndex != localData.current.deferredState.invalidRuleIndex ||
        localData.current.globalErrorMessage != localData.current.deferredState.globalErrorMessage;

        localData.current.isValid = localData.current.deferredState.isValid;
        localData.current.invalidRuleIndex = localData.current.deferredState.invalidRuleIndex;
        localData.current.globalErrorMessage = localData.current.deferredState.globalErrorMessage;

        localData.current.deferredState = null;

        if (isChanged) {
            //console.log(`===RERENDER FIELD ${name} isValid=${localData.current.isValid}`);
            setUpdateCounter(prev => prev + 1);
        }
    }

    useImperativeHandle(handle, () => ({
        validate: (dryRun) => validate(dryRun),
        cancelValidation: () => validateDebounced.current?.cancel(),
        getState: () => getState(),
        setState: (isValid, message) => setState(isValid, message), //sets global state at field
        updateState: () => updateState(),
        onFormUnmounted: () => detachFromForm()
    }));

    //console.log(`return field: ${name} isValid: ${localData.current.isValid} message: ${getErrorMessage()}`)

    return { isValid: localData.current.isValid, /*&& localData.current.isGlobalValid,*/ errorMessage: getErrorMessage() };
}

export default useFormValidation;