import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux"
import Globals from "@Base/Globals"
import { makeStyles } from '@mui/styles'
import { useTranslation } from "react-i18next"
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_exporting_data from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official'

import style from '@Styles/domrf-theme.module.scss'
import Localization from '@Components/Localization'


import { actionCreators, reducer } from '@Store/SecuritizationStore';
import { alpha } from '@mui/system'
import { roundDown, roundUp, setHighchartsLocalization } from '../../../../Utilities'
import { Box, debounce, IconButton, Slider, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import SpinnerBars from '../../../../components/shared/SpinnerBars'
import CustomTooltip from '../../../../components/shared/CustomTooltip'
import { format, isValid, parseISO } from 'date-fns'
import { i18nLocales } from '../../../../components/Localization'
import { ru, enUS } from 'date-fns/locale'
import { SaveOutlined } from '@mui/icons-material'

const useStyles = makeStyles({
    saveButton: {
        position: 'absolute',
        top: '5px',
        right: '60px',
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.2rem',
        color: style.colorPrimary3_40
    },
    markActive: {
        boxShadow: `0px 0px 0px 8px ${alpha(style.colorPrimary2, 0.5)} !important`
    },
    mark: {
        backgroundColor: 'transparent',
    },
    sliderRoot: {
        width: '240px',
        minWidth: '240px',
        maxWidth: '240px',
        paddingTop: 0,
        paddingBottom: 0,
        zIndex: +1,

    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },

    controlsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        //alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: '60px',
        top: '7px',
    },

    controlBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'max-content',
        flexWrap: 'nowrap',
        // width: '30%'
    },
    spinnerContainer: {
        position: 'relative',
        width: '100%',
        height: '100%'
    }
});


const CurrentRateTab = ({ poolId }) => {

    const classes = useStyles()
    const chart = useRef()
    const xFormat = useRef(null)
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const { chartsData, deal = null, selectedNode = null, ...otherStore } = useSelector((state: any) => state.securitization)
    const { nodeWithChangesLoad = false, CurrentRateLoad = false } = useSelector((state: any) => state.loader);
    const [chartData, setChartData] = useState(null)
    const [chartLoading, setChartLoading] = useState(false);
    const [step, setStep] = useState(null)
    const [steps, setSteps] = useState([0.1, 0.5, 1])
    const [lods, setLods] = useState([])
    const [lod, setLod] = useState(null);
    const [binCount, setBinCount] = useState(0)
    const [currentWACBinIndex, SetCurrentWACBinIndex] = useState(0)

    //console.log("nodeWithChangesLoad: " + nodeWithChangesLoad + " CurrentRate: " + CurrentRateLoad)
    // console.log('chartsData', chartsData)

    const onSaveChartAsImageButtonClick = (event) => {
        if (chart.current)
            chart.current.chart.exportChart({ width: 3840, height: 1920 });
    }

    const chartTitle = `${deal?.deal?.name}, ${t("CurrentMortgageRateTab")}, пул от ${selectedNode?.node?.pools?.length ? Localization.FormatDateStringLocale(selectedNode.node.pools[0]?.poolDate) : ''}`

    const [chartOptions, setChartOptions] = useState({
        poolChartOptions: {
            customData: () => { return { step: step } },
        },
        exporting: {
            enabled: false,
            filename: chartTitle + '.jpg',
            sourceWidth: 1280,
            sourceHeight: 520,
            chartOptions: {
                title: {
                    text: chartTitle,
                    // align: 'center',
                    style: { display: "block", "color": "#2F444E", "fontSize": "20px", "fontWeight": "bold", 'float': 'left' },
                },
                chart: {
                    // style: { "fontSize": "5px" },
                    marginBottom: 65,
                    // events: {
                    // load: function () {
                    //     var renderer = this.renderer;
                    //     console.log('this', this, renderer)
                    //         renderer.path(['M', 30, 385, 'L', 570, 385, 'Z']).attr({
                    //             stroke: 'black',
                    //             'stroke-width': 1
                    //         }).add();

                    //         renderer.text('Some text...', 30, 400).add();
                    // }
                    // }
                },
                xAxis: {
                    labels: { style: { "color": "#2F444E", "fontSize": "18px", } },
                    title: { style: { "color": "#2F444E", "fontSize": "20px", } }
                },
                yAxis: [{
                    labels: { style: { "color": "#2F444E", "fontSize": "18px", } },
                    title: { style: { "color": "#2F444E", "fontSize": "20px", } }
                },
                {
                    labels: { style: { "color": "#2F444E", "fontSize": "18px", } },
                    title: { style: { "color": "#2F444E", "fontSize": "20px", } }
                }],
                legend: {
                    verticalAlign: 'bottom',
                    align: 'center',
                    y: 20,
                    x: 0,
                    itemDistance: 40,
                    itemStyle: {
                        fontSize: '18px',
                        overflow: 'hidden',
                    },
                },
            }
        },
        chart: {
            animation: false,
            marginTop: 40,
            marginLeft: 60,
            marginRight: 65,
        },
        plotOptions: {
            series: {
                pointPadding: 0,
                groupPadding: 0.1,

                dataLabels: {
                    enabled: false,
                },
                states: {
                    inactive: {
                        enabled: false,
                    },
                    hover: {
                        halo: {
                            size: 0
                        }
                    }
                },
            },
        },

        title: {
            text: t('CurrentCreditRate'),
            style: { display: "none", "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold", 'float': 'left' },
            align: 'left',
            verticalAlign: 'top',
            x: 50,
            // y: 8,
        },

        xAxis: {
            crosshair: {
                color: alpha(style.colorPrimary2, 0.1)
            },
            categories: null,
            labels: {
                enabled: true,
                // formatter: null,
                rotation: 0,
                allowOverlap: false,
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none'
                }
            },

            title: {
                enabled: false
            },

            gridLineWidth: 0,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: style.colorPrimary1,
            tickmarkPlacement: 'between',

            plotLines: [
                {
                    color: style.colorPrimary2,
                    dashStyle: 'dash',
                    width: 2,
                    value: -0.2,
                    zIndex: 2,
                    label: {
                        rotation: 0,
                        textAlign: 'right',
                        x: -5,
                        y: 15,
                        style: {
                            color: style.colorPrimary1,
                            fontWeight: 'bolder',
                            fontSize: '0.9rem'
                        },
                    }
                }]
        },

        yAxis: [{
            labels: {
                enabled: true,
            },
            stackLabels: {
                enabled: false
            },
            title: {
                text: t('securitization.CurrentDebtShare'),
                // margin: 4,
                style: { color: style.colorPrimary3_40 }
            },
            // tickPositions: null,
            tickPositioner: function (min, max) {
                const rounder = 5
                const rMax = roundUp(max, rounder)
                const rMin = roundDown(min, rounder)

                const count = 5
                const step = roundUp((rMax - rMin) / count, rounder)
                var positions = Array.from({ length: count + 1 }, (x, i) => i * step)
                // var positions = [0, 10, 20, 30, 40, 50];
                return positions;
            },
            gridLineWidth: 1,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: style.colorPrimary1,
            gridLineColor: style.colorPrimary3_5,
        }, {
            labels: {
                enabled: true,
            },
            stackLabels: {
                enabled: true
            },
            title: {
                text: t('securitization.Years'),
                style: { color: style.colorPrimary3_40 }
            },
            opposite: true,
            // tickPositions: null,
            tickPositioner: function (min, max) {
                // console.log('min, max', min, max)
                const rounder = 5
                const rMax = roundUp(max, rounder)
                const rMin = roundDown(min, rounder)

                const count = 5
                const step = roundUp((rMax - rMin) / count, 1)
                // console.log('rMax - rMin', rMax, max, rMin, min)
                var positions = Array.from({ length: count + 1 }, (x, i) => rMin + i * step)
                // var positions = [0, 10, 20, 30, 40, 50];
                return positions;
            },
            gridLineWidth: 1,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: style.colorPrimary1,
            gridLineColor: style.colorPrimary3_5,
            plotLines: [
                {
                    color: style.colorPrimary2,
                    dashStyle: 'dash',
                    width: 2,
                    value: -0.2,
                    zIndex: 2,
                    label: {
                        rotation: 0,
                    }
                }],
        }],

        legend: {
            floating: true,
            enabled: true,
            // layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -75,
            // y: 15,
            itemMarginTop: -4,
            itemMarginBottom: 0
        },

        tooltip: {
            shape: "square",
            positioner: function (labelWidth, labelHeight, point) {
                return {
                    x: this.chart.plotLeft + 10,
                    y: this.chart.plotTop + 10
                };
            },
            headerFormat: '<table><tr>' +
                '<td>' + t('CurrentMortgageRate') + ':</td>' +
                '<td style="text-align:end"><b>{point.key:.1f}</b></td>' +
                '</tr>',
            pointFormat: '<tr><td style="padding:0">{series.name}: </td>' +
                '<td style="padding-left:0.5rem;text-align:end"><b>{point.y: .1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
            hideDelay: 20
        },

        credits: {
            enabled: false
        },

        series: [{
            id: 'c1',
            type: 'column',
            name: t('securitization.CurrentDebt'),
            color: alpha(style.colorPrimary3, 0.1),
            borderColor: alpha(style.colorPrimary3, 0.5),
            yAxis: 0,
            data: null,
            animation: false,
            showInLegend: true,
            column: {
                zones: {
                    fillColor: style.colorPrimary2_20
                }
            },
            visible: true,
            stacking: true,
            dataLabels: {
                enabled: false
            }
        },
        {
            id: 'c2',
            type: 'column',
            name: t('securitization.CurrentDebtWG'),
            color: 'rgba(183, 217, 139, 0.23)',//alpha(style.colorPrimary2, 0.2),
            borderColor: alpha(style.colorPrimary3, 0.5),
            yAxis: 0,
            data: null,
            animation: false,
            showInLegend: true,
            visible: true,
            stacking: true,
            dataLabels: {
                enabled: false
            }
        }, {
            type: 'line',
            name: t('securitization.WAMyears'),
            color: style.colorPrimary1,
            yAxis: 1,
            data: null,
            marker: {
                fillColor: '#FFFFFF',
                lineWidth: 2,
                lineColor: null
            },
            animation: false,
            visible: true,
        }]
    })

    HC_exporting(Highcharts)
    HC_exporting_data(Highcharts)
    setHighchartsLocalization(Highcharts, t)

    useLayoutEffect(() => {
        setLod(0)
        setStep(steps[1])
        setChartData(null)
        if (window) {
            window.addEventListener('resize', debounce(onResize, 300))
            onResize();
        }

        return () => {
            window.removeEventListener('resize', debounce(onResize, 300))
        }
    }, [])

    useLayoutEffect(() => {
        // if (step != null) {
        //     setBinCount(step == 1 ? 3 : step == 0.5 ? 6 : 30)

        //console.log("-----START CHART LOAD");
        actionCreators.getPoolData(poolId, 'CurrentRate', step)(dispatch)
        //setChartLoading(true)
        // }
    }, [step, poolId])

    useLayoutEffect(() => {

        if (chartsData?.data != null && chartsData?.data[0]?.x1 != null) {
            const binCountVal = step == 1 ? 4
                : step == 0.5 ? 7
                    : 30
            setBinCount(binCountVal)

            let arr = chartsData?.data[0]?.x1
            const cnt = arr?.length - 1
            var deltaWACArr = arr?.map(x => Math.abs(parseFloat(x?.replace(/[<>=≥\+]/g, '')?.replace(/[,]/g, '.')) - chartsData?.currentWAC))
            var minDeltaWAC = Math.min.apply(null, deltaWACArr)
            var currentWACBin = deltaWACArr?.findIndex(x => x == minDeltaWAC)

            SetCurrentWACBinIndex(currentWACBin)
            if (cnt != null) {
                let sizeLeft = currentWACBin - binCountVal > 0 ? currentWACBin - binCountVal : 0
                let sizeRight = cnt - currentWACBin - binCountVal > 0 ? cnt - currentWACBin - binCountVal : 0
                let lodsSize = sizeRight > sizeLeft ? sizeRight : sizeLeft
                // console.log('lodsSize', lodsSize)
                if (lodsSize > 1) {
                    //    console.log('update lods', lodsSize)
                    let lodsArr = Array.from(Array(lodsSize + 1).keys()).map(x => { return { value: x } })
                    setLods(lodsArr)
                } else {
                    setLods([])
                }
            }

        }
        else {
            SetCurrentWACBinIndex(null)
            setLods([])
        }

        setLod(0)
        setChartData(chartsData)
    }, [chartsData]);

    useLayoutEffect(() => {
        if (lods?.length && lod != 0)
            setLod(0) //lods.length - 1)        
    }, [lods])


    useEffect(() => {
        if (chart.current != null)
            chart.current.chart.reflow();
    }, [chartOptions])

    useLayoutEffect(() => {

        setChartLoading(nodeWithChangesLoad || CurrentRateLoad);

    }, [nodeWithChangesLoad, CurrentRateLoad])

    useLayoutEffect(() => {
        updateChart()
    }, [chartData])

    useLayoutEffect(() => {
        updateChart()
    }, [lod])

    const updateChart = () => {

        if (chartData?.data == null || chartData?.data[0]?.x1 == null || chartData?.chartName?.toLowerCase() != "currentrate" || lod < 0 || chartsData.poolID != poolId)
            return;

        const lineStyle = {
            color: style.colorPrimary3_5,
            dashStyle: 'solid',
            width: 1,
            zIndex: 2,
            label: {
                rotation: 0
            }
        }
        //make chart
        var opts = { ...chartOptions };

        var data = { ...chartData.data[0], charts: [...chartData.data[0].charts] }

        let sliceLeftIndex = currentWACBinIndex - (binCount + parseInt(lods[lod]?.value)) //позиция среза слева
        // console.log('sliceLeftIndex', currentWACBinIndex, sliceLeftIndex)
        // let minX = parseFloat(data.x1[0]?.replace(/[=<>\+]/g, '')?.replace(/[,]/g, '.'))
        let minX = parseFloat(data?.x1[0]) //?.replace(/[=<>\+]/g, '')?.replace(/[,]/g, '.'))
        if (sliceLeftIndex < 0) sliceLeftIndex = 0

        // console.log('minX', minX)
        // data.x1 = data.x1.map(x => `${x}+`)

        if (sliceLeftIndex >= 1) {

            let y1ArrLeft = data?.charts[1]?.slice(0, sliceLeftIndex)
            const sliceDebtLeft = y1ArrLeft?.some(x => x) ? y1ArrLeft?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y1ArrLeftWG = data?.charts[2]?.slice(0, sliceLeftIndex)
            const sliceDebtLeftWG = y1ArrLeftWG?.some(x => x) ? y1ArrLeftWG?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y2ArrLeft = data?.charts[3]?.slice(0, sliceLeftIndex)
            let sliceYearsLeft = y2ArrLeft?.map((x, index) => {
                if (x == null || (y1ArrLeft[index] == null && y1ArrLeftWG[index] == null)) return null
                return x * (y1ArrLeft[index] ?? 0 + y1ArrLeftWG[index] ?? 0)
            }).reduce((x, s) => (x ?? 0) + s, 0) / (sliceDebtLeft + sliceDebtLeftWG)

            data.charts[1] = [sliceDebtLeft != null ? sliceDebtLeft : null, ...data?.charts[1]?.slice(sliceLeftIndex)]
            data.charts[2] = [sliceDebtLeftWG != null ? sliceDebtLeftWG : null, ...data?.charts[2]?.slice(sliceLeftIndex)]
            data.charts[3] = [sliceYearsLeft != null ? sliceYearsLeft : null, ...data?.charts[3]?.slice(sliceLeftIndex)]

            // console.log('data.charts[3]', data.charts[3])
            data.x1 = data?.x1?.slice(sliceLeftIndex ? sliceLeftIndex - 1 : 0)
            minX = minX + step * (sliceLeftIndex - (sliceDebtLeft != null && sliceDebtLeftWG != null ? 1 : 0)) - step

            data.x1[0] = `<${data?.x1[1]?.replace(/[\+]/g, '')}`
        }

        // console.log('data.chart1', data.chart1)
        let sliceRightIndex = -sliceLeftIndex + currentWACBinIndex + (binCount + parseInt(lods[lod]?.value)) + 1   //позиция среза справа

        if (sliceRightIndex < data.charts[1]?.length) {

            let y1ArrRight = data.charts[1]?.slice(sliceRightIndex - 1)
            const sliceDebtRight = y1ArrRight?.some(x => x) ? y1ArrRight?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y1ArrRightWG = data.charts[2]?.slice(sliceRightIndex - 1)
            const sliceDebtRightWG = y1ArrRightWG?.some(x => x) ? y1ArrRightWG?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y2ArrRight = data.charts[3]?.slice(sliceRightIndex - 1)
            let sliceYearsRight = y2ArrRight?.map((x, index) => {
                if (x == null || (y1ArrRight[index] == null && y1ArrRightWG[index] == null)) return null
                return x * (y1ArrRight[index] ?? 0 + y1ArrRightWG[index] ?? 0)
            }).reduce((x, s) => (x ?? 0) + s, 0) / (sliceDebtRight + sliceDebtRightWG)

            data.charts[1] = [...data?.charts[1]?.slice(0, sliceRightIndex - 1), sliceDebtRight != null ? sliceDebtRight : null]
            data.charts[2] = [...data?.charts[2]?.slice(0, sliceRightIndex - 1), sliceDebtRightWG != null ? sliceDebtRightWG : null]
            data.charts[3] = [...data?.charts[3]?.slice(0, sliceRightIndex - 1), sliceYearsRight != null ? sliceYearsRight : null]

            data.x1 = data?.x1?.slice(0, sliceRightIndex)

            const lastBin = data.x1?.length - 1
            data.x1[lastBin] = `≥${data?.x1[lastBin]?.replace(/[\+]/g, '')}`
        }

        opts.xAxis.plotLines = [opts.xAxis.plotLines[0]]

        // opts.xAxis.categories = data.x1?.map((x, index) => {
        //     if (x == '' || x == null) return ""
        //     let value = parseFloat(`${x}`?.replace(/[<>=≥\+]/g, '')?.replace(/[,]/g, '.'))
        //     if (value % 1 === 0 && index != 0) {
        //         opts.xAxis.plotLines.push({ ...lineStyle, value: index - 0.5 })
        //     }

        //     return x
        // })

        opts.xAxis.categories = data.x1?.map((x, index) => {
            // console.log('x', x)
            if (x == '' || x == null)
                return ""

            const tmplReg = new RegExp('[>=≥]', 'g')

            let value = parseFloat(`${x}`?.replace(/[<>=≥\+]/g, '')?.replace(/[,]/g, '.'))

            const testVal = tmplReg.test(`${x}`)
            // console.log('value', value, value % 1, testVal)
            if (value % 1 === 0/* || (testVal && (value % 1 !== 0))*/ && index != 0)
                opts.xAxis.plotLines.push({ ...lineStyle, value: index - 0.5 })

            return x
        })

        opts.xAxis.labels.formatter = function () {

            // console.log('this.value', this.value)
            let textVal = `${this.value}`
            let value = parseFloat(textVal?.replace(/[<>=≥\+]/g, '')?.replace(/[,]/g, '.'))


            if (this.pos == 0) return textVal

            switch (step) {
                case 0.1:
                    textVal = (this.pos < 2 || this.pos > opts.xAxis?.categories?.length - 5) ? '' :
                        data.x1?.length < 50 ?
                            (value % 0.5 === 0 ? textVal : '') :
                            (value % 1 === 0 ? textVal : '')
                    break
                case 0.5:
                    // console.log('step', step, data.x1, value, value % 2)
                    textVal = /*(this.pos < 2 || this.pos > opts.xAxis?.categories?.length - 4) ? '' :*/
                        data.x1?.length < 20 ?
                            (value % 0.5 === 0 ? textVal : '') :
                            data.x1?.length < 35 ?
                                (value % 1 === 0 ? textVal : '') :
                                (value % 2 === 0 ?
                                    // (this.pos > opts.xAxis?.categories?.length - 2) ? '':
                                    textVal
                                    : '')
                    break
            }

            return textVal
        }

        var opts = { ...chartOptions };

        // var selectedValue = chartData.data[0]
        // opts.xAxis.categories = data.x1//selectedValue.x1

        // let y2format10 = false, y2format100 = false
        // selectedValue.y2.some(y => {
        //     if (y * 10 % 1) {
        //         //сотые
        //         y2format100 = true
        //         return true
        //     }

        //     if (!y2format10 && y % 1) {
        //         //десятые
        //         y2format10 = true
        //     }
        // }
        // )
        // opts.yAxis[1].labels.format = y2format100 ? '{value:.2f}' : (y2format10 ? '{value:.1f}' : '{value:.0f}')

        // let y1format10 = false, y1format100 = false
        // selectedValue.y1.some(y => {
        //     if (y * 10 % 1) {
        //         //сотые
        //         y1format100 = true
        //         return true
        //     }

        //     if (!y1format10 && y % 1) {
        //         //десятые
        //         y1format10 = true
        //     }
        // }
        // )

        // opts.yAxis[0].labels.format = y1format100 ? '{value:.2f}' : (y1format10 ? '{value:.1f}' : '{value:.0f}')

        // opts.yAxis[0].tickPositions = data.y1
        // opts.yAxis[1].tickPositions = data.y2

        // opts.series[0].data = data.charts[1]
        // opts.series[1].data = data.charts[2].map((item, index) => [data.x1[index], item])
        // opts.series[1].visible = (step == steps[0] ? false : true)

        opts.series[0].data = data.charts[1]//?.map(val => parseFloat(val ?? 0))
        opts.series[0].showInLegend = data.charts[1]?.some(x => x)
        opts.series[1].data = data.charts[2]//.map(val => parseFloat(val ?? 0))
        opts.series[1].showInLegend = data.charts[2]?.some(x => x)

        opts.series[2].data = data.charts[3].map((item, index) => [index, item])

        opts.yAxis[1].visible = (step == steps[0] ? false : true)
        opts.series[2].visible = (step == steps[0] ? false : true)
        opts.series[2].showInLegend = (step == steps[0] ? false : true)

        // console.log(opts.series[1])


        opts.xAxis.plotLines[0].value = (chartData?.currentWAC - minX) / step - 0.5
        opts.xAxis.plotLines[0].label.text = `${t('WAC')} ${Localization.FormatNumber(chartData.currentWAC, 1, 2)}`
        //console.log('chartData.currentWAC', chartData.currentWAC)


        // opts.xAxis.plotLines[0].value = chartData.pti / step - 0.5
        // console.log('chartData.CurrentLTV', chartData.CurrentLTV, step)
        // opts.xAxis.plotLines[0].label.text = `${t('CurrentPTI_short')}: ${Localization.FormatNumber(chartData.pti, 1, 1)}`


        //console.log("---ALL DONE");
        setChartOptions(opts)
        // setChartLoading(false)
    }

    const onResize = () => {
        if (chart.current != null) {
            chart.current.chart.reflow();
            // console.log('resize')
        }
    }

    const handleStep = (event: React.MouseEvent<HTMLElement>, newValue: number | null) => {
        if (newValue != null)
            setStep(newValue)
    }

    const handleLODTrack = (event: Event, value: number | Array<number>, activeThumb: number) => {
        // console.log('value', value)
        setLod(value);
    }
    // console.log('lods+++', lods)
    return (
        <Stack sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <Stack sx={{ flex: 1, overflow: 'hidden' }}>
                {chartLoading ? <SpinnerBars withBackground /> : <></>}
                {(chartLoading || !chartData || chartData?.chartName.toLowerCase() != "currentrate" || chartOptions?.series == null || chartOptions.series?.length == 0) ?
                    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', color: style.colorPrimary3_60 }}>
                        {/* {(chartLoading == false && ((chartData == null && chartOptions?.series?.data == null) || chartData?.chartName.toLowerCase() == "currentrate")) && <Box className={classes.info}>{t("DataLoadingFail")}</Box>} */}
                    </Box> :
                    <>

                        <HighchartsReact
                            ref={chart}
                            highcharts={Highcharts}
                            options={chartOptions}
                            containerProps={{ style: { height: "100%", width: "100%" } }}
                            callback={() => {
                                setTimeout(() => {
                                    onResize()
                                }, 1)
                            }
                            }
                        />
                        <Tooltip title={t("securitization.SaveAsImage")} disableInteractive>
                            <IconButton size="small" className={classes.saveButton} disabled={false} onClick={onSaveChartAsImageButtonClick}>
                                <SaveOutlined />
                            </IconButton>
                        </Tooltip>
                        <Stack className={classes.controlsContainer} sx={{ mb: 1 }}>
                            <Box className={classes.controlBox}>
                                {/* <CustomTooltip id={`chart_step_${poolId}`} hideIcon sx={{ mr: 1 }} content={'?????'}>
                                    {t('Step')}
                                </CustomTooltip> */}
                                <ToggleButtonGroup
                                    value={step}
                                    exclusive
                                    onChange={handleStep}
                                    aria-label="step"
                                >
                                    {
                                        steps?.map(x => {
                                            return (
                                                <ToggleButton
                                                    size='small'
                                                    sx={{ pt: 0, pb: 0 }}
                                                    key={`step_${x}`} value={x}>
                                                    {Localization.FormatNumber(x, 1, 1)}
                                                </ToggleButton>
                                            )
                                        })
                                    }
                                </ToggleButtonGroup>
                            </Box>
                            {lods?.length > 1 &&
                                <Box className={classes.controlBox} sx={{ ml: 2 }}>
                                    {/* <CustomTooltip id={`chart_detailing_${poolId}`} hideIcon sx={{ mr: 2 }} content={t('LOD_Info')}>
                            {t('LOD')}
                        </CustomTooltip> */}
                                    {/* {console.log('lod*', lod)} */}
                                    <Slider
                                        aria-label={`LOD_steps_${poolId}`}
                                        size='small'
                                        value={lod}
                                        track={false}
                                        defaultValue={lods.length}
                                        marks={lods}
                                        max={lods[lods.length - 1].value}
                                        min={lods[0].value}
                                        // track="inverted"
                                        //step={1}
                                        //valueLabelDisplay="auto"
                                        onChange={handleLODTrack}
                                        //onChangeCommitted={handleLODChange}
                                        classes={{
                                            root: classes.sliderRoot,
                                            mark: classes.mark,
                                            active: classes.markActive,
                                        }}
                                    />
                                </Box>
                            }
                        </Stack>
                    </>
                }
            </Stack>

        </Stack >
    )
}

export default CurrentRateTab;