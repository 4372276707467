import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Box, IconButton, InputAdornment, TextField, Theme, alpha } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'
import { t } from 'i18next'
import Localization from '@Base/components/Localization'
import { CheckBox, CheckBoxOutlineBlank, CheckBoxOutlined, CheckOutlined, Clear, ClearOutlined, EditOutlined, IndeterminateCheckBoxOutlined, NotificationImportantOutlined, Sort } from '@mui/icons-material'
import useDebounce from '@Base/components/hook/useDebounce'
import useLocalStorage from '@Base/components/hook/useLocalStorage'
import { actionCreators as actionBonds } from '@Base/store/BondsStore'
import { DataGridPro, GridCallbackDetails, GridColDef, GridColumnHeaderParams, GridRowParams, MuiEvent, gridExpandedSortedRowIdsSelector, gridVisibleColumnDefinitionsSelector, useGridApiContext, useGridApiRef } from '@mui/x-data-grid-pro'
import { BondStatus, CouponType } from '@Base/components/Definitions'
import ConfirmationForm from '@Base/components/shared/ConfirmationForm'
import { copyTextToClipboard } from '@Base/Utilities'
import { DataGridPremium } from '@mui/x-data-grid-premium'


const useStyles = makeStyles((theme: Theme) => createStyles({
    bondIconContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    bondIcon: {

    },
    couponType: {
        color: style.colorPrimary3_60,
        marginLeft: '4px',
        fontSize: '18px',
    },
    tabContainerNoData: {
        // position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1rem',
        fontSize: '1.4rem',
        flexGrow: 1,
    },
    labelColor: {
        color: style.colorPrimary3_120,
        userSelect: 'none',
    },
    columnHeadersInner: {
        // '& *:first-child': {
        //     border: 'none'
        // },
        '& *:last-child': {
            // border: 'none'
        }
    },
    footerContainer: {
        '& .MuiTablePagination-toolbar': {
            alignItems: 'baseline',
            minHeight: '1rem !important',
        }
    },
    dataBlock: {
        // width: 'min-content',
        border: 'none',
        position: 'relative',
        '& .MuiDataGrid-scrollbar': {
            '&::-webkit-scrollbar': {
                backgroundColor: '#FFF',
                width: '4px',
            },
            // '&::-webkit-scrollbar:disabled':{
            //     opacity:0,
            // },

            '&::-webkit-scrollbar-track': {
                boxShadow: 'unset',
                borderRadius: '3px',
                backgroundColor: '#00000000',
                transition: 'all 0.3s ease'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#0000',
                borderRadius: '3px',
                height: '4px'
            },
        },
        '&:hover .MuiDataGrid-scrollbar': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
                borderRadius: '3px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
                borderRadius: '3px',
            }
        },
    },
    cellData: {
        // justifyContent: 'start !important',
        outline: 'none !important',
        fontSize: '1rem',
        paddingLeft: '2px !important',
        // marginLeft: '-4px !important',
        // border:'solid 1px #000 !important'
    },
    rootContainer: {
        border: 0,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        position: 'absolute', left: 0, right: '4px', top: 0, bottom: 0,
    },
    container: {
        // borderWidth: '0px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#dfdfe1', //style.colorPrimary3_40,
        borderTopRightRadius: '0.5rem',
        borderTopLeftRadius: '0.5rem',
        overflow: 'hidden',
        position: 'absolute', left: 0, right: 0, top: '10px', bottom: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        height: '2.2rem',
        maxHeight: '2.2rem',
        padding: '0 0.5rem',
        marginBottom: '10px',
        // backgroundColor: alpha(style.colorPrimary2, 0.1),
        // borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        fontSize: '1.6rem',
        // lineHeight: '2rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap'
    },
    body: {
        flexGrow: 1,
        position: 'relative',
    },
    dataContainer: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
    },
    tableRow: {
        cursor: 'pointer',
        marginLeft: '4px',
        '& .MuiDataGrid-cell': {
            //borderColor: `${alpha(style.colorPrimary3, 0.15)} !important`,
            border: 'none',
        }
    },
    virtualScrollablePanel: {
        '& .Mui-selected': {
            // backgroundColor: `${style.colorPrimary2_40} !important`,
            backgroundColor: `#e4f1d5 !important`,
        },
        // position: 'absolute', left: '4px', right: 0, top: 0, bottom: 0,
        // overflowY: 'auto',
        padding: 0,
        '&::-webkit-scrollbar': {

            width: '4px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    scrollablePanel: {
        position: 'absolute', left: '4px', right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {

            width: '4px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    bondItem: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        '&:hover': {
            backgroundColor: style.colorPrimary3_20,
            cursor: 'pointer'
        },
        '&>*': {
            // minWidth: '180px',
            // maxWidth: '180px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '&>*:not(:first-child)': {
            paddingLeft: '0.5rem',
        },
    },
    bondItemColumn: {
        flexGrow: 1,
        // border: `solid 1px ${style.colorPrimary1_20}`,
    },
    sortBlock: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    bondHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        position: 'sticky',
        top: 0,
        backgroundColor: '#FFF',
    },
    columnHeader: {
        outline: 'none !important',
    },
    columnHeaderTitleContainer: {
        justifyContent: 'center',
    },
    bondHeaderItem: {
        cursor: 'pointer',
        fontSize: '15px',
        color: `${style.colorPrimary1_40}`,
        // borderBottom: `solid 1px ${style.colorPrimary1_20}`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexGrow: 1,
        whiteSpace: 'nowrap',
        // paddingLeft: '0.5rem',
        // paddingRight: '1rem',
        position: 'relative',
        '&:not(:first-child)::before': {
            content: '""',
            position: 'absolute', left: 0, top: '5px', bottom: '5px', right: 0,
            borderLeft: `solid 1px ${style.colorPrimary1_20}`,
        },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    sortIcon: {
        width: '15px',
        marginLeft: '5px',
        color: `${style.colorPrimary1_20}`,
        // opacity: 0,
    },
    currentSortIcon: {
        color: `${style.colorPrimary1} !important`,
        // opacity: 1,
    },
    ASCIcon: {
        transform: 'rotate(180deg)'
    },
    inputRoot: {
        marginLeft: '0.4rem',
        width: '180px !important',
        '& input': {
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '4px',
            paddingRight: '26px',
            fontSize: '14px',
        }
    },
    searchBlock: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '14px',
        alignItems: 'baseline'
    },
    clearButton: {
        width: '12px',
        height: '12px',
    },
    typeIcon: {
        color: style.colorPrimary3_60
    },
    redeemedBond: {
        color: style.colorPrimary3_60,
    }
}))

// const SelectRow = () => {
//     const apiRef = useGridApiContext()
//     const { selectedBond, ...otherStore } = useSelector((state: any) => state.bonds)
// console.log('apiRef', apiRef)
//     // const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector)
//     // const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
//     if (selectedBond?.id) apiRef.current.selectRow(selectedBond?.id, true, true)
//     return <></>
// }
const BondsList = () => {
    const apiRef = useGridApiRef()
    const PAGE_SIZE = 100
    const classes = useStyles()
    const dispatch = useDispatch()
    const [rowSelectionModel, setRowSelectionModel] = useState([0])

    const getDateValue = (value, row) => {
        return Localization.FormatDateStringLocale(value)
    }

    const GetBondType = (param) => {
        // console.log('param', param)
        let Icon = <></>
        switch (param?.row?.bondStatus) {
            case BondStatus.STATUS_NOT_ISSUED:
                Icon = <EditOutlined className={classes.typeIcon} /> //ручка
                break
            case BondStatus.STATUS_PLACEMENT:
                Icon = <NotificationImportantOutlined className={classes.typeIcon} /> // 
                break
            case BondStatus.STATUS_ISSUED:
                Icon = <CheckOutlined className={classes.typeIcon} /> // галочка
                break
            case BondStatus.STATUS_REDEEMED:
                Icon = <ClearOutlined className={classes.typeIcon} /> //круст
                break
            default:
                Icon = <></>
        }

        // console.log('param', param)
        let couponTypeText = ''
        switch (param?.row?.couponType) {
            case CouponType.TYPE_FIXED:
                couponTypeText = 'ф'
                break
            case CouponType.TYPE_FLOAT:
                couponTypeText = 'п'
                break
            case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                couponTypeText = 'кс+'
                break
        }

        return <Box className={classes.bondIconContainer}>
            <Box className={classes.bondIcon}>
                {Icon}
            </Box>
            <Box className={classes.couponType}>
                {couponTypeText}
            </Box>
        </Box>
    }

    const renderHeader = (params: GridColumnHeaderParams) => {
        const columnIndex = sortOrder.findIndex(col => params.colDef.field == col?.field)
        if (columnIndex == -1)
            return

        return (<>
            <Box className={classes.bondHeaderItem}
                onClick={() => sortHandler(params.colDef.field)}
            >
                {t(params.colDef.headerName)}
                <Box>
                    <Sort className={
                        [classes.sortIcon,
                        params.colDef.field == sortBy?.field ? classes.currentSortIcon : '',
                        sortOrder[columnIndex]?.sortASC == true ? classes.ASCIcon : ''
                        ].join(' ')} />
                </Box>
            </Box>
        </>
        )
    }
    const cellDefaultParams = {
        sortable: false,
        // filterable: false,
        disableReorder: true,
        hideable: false,
        resizable: false,
        pinable: false,
        // cellClassName: classes.cellData,
    }

    const RenderCell = (params) => {
        return <>
            <Box className={[
                params.row?.bondStatus == BondStatus.STATUS_REDEEMED ? classes.redeemedBond : ''
            ].join(' ')
            }>
                {params?.formattedValue}
            </Box>
        </>
    }

    const columns: GridColDef[] = [
        { field: 'couponType', headerName: ' ', renderHeader: renderHeader, renderCell: GetBondType, width: 70, ...cellDefaultParams },
        { field: 'isin', align: 'left', headerName: 'IsinField', renderCell: RenderCell, renderHeader: renderHeader, width: 130, ...cellDefaultParams },
        { field: 'name', align: 'left', headerName: 'NameField', renderCell: RenderCell, renderHeader: renderHeader, width: 155, ...cellDefaultParams },
        { field: 'issueDate', align: 'center', headerName: 'NameIssueDate', renderCell: RenderCell, renderHeader: renderHeader, width: 125, valueGetter: getDateValue, ...cellDefaultParams }
    ]
    const [sortOrder, setSortOrder] = useState([
        { field: 'couponType', sortASC: true, },
        { field: 'isin', sortASC: true, },
        { field: 'name', sortASC: true, },
        { field: 'issueDate', sortASC: false, }
    ])
    const [sortBy, setSortBy] = useState({ ...sortOrder[3] })
    const [dialogCallback, setDialogCallback] = useState(null)

    const { items: bondsList = [], count: bondsCount, selectedBond = null, ...otherStore } = useSelector((state: any) => state.bonds)
    const [searchInput, setSearchInput] = useLocalStorage('', 'bonds2/list/filter')
    const searchVal = useDebounce(searchInput, 300)
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: PAGE_SIZE,
    })

    useEffect(() => {
        //   console.log('rowSelectionModel', rowSelectionModel)
        setRowSelectionModel([selectedBond?.id ?? 0]) //выделет строку с выбранным бондом
    }, [selectedBond])

    // useEffect(() => {
    //     console.log('bondsList', bondsList)
    // }, [bondsList])


    useEffect(() => {
        // actionCalculator.findBondsQuery('')(dispatch)
        actionBonds.getBonds({
            Skip: paginationModel?.page * paginationModel?.pageSize,
            Take: paginationModel?.pageSize,
            Filters: searchVal ? searchVal.split(' ').map(substr => {
                return ({
                    Operator: "or",
                    Values: columns
                        // ?.filter(x => x.useFilter == true)
                        ?.map(column => {
                            return {
                                Name: column.field,
                                Type: "contains",
                                FirstValue: substr,
                            }
                        })
                })
            }
            ) : null,
            Sorters: sortBy?.field != null ? [
                {
                    Name: sortBy?.field,
                    SortOrder: 1,
                    IsAscending: sortBy?.sortASC
                }
            ] : null,

        })(dispatch)

    }, [searchVal, sortBy, paginationModel])

    const [rowCountState, setRowCountState] = useState(0)

    // useEffect(() => {
    //     if (apiRef?.current != null && selectedBond?.id) {


    //         // const rowIndex = apiRef.current.getRowElement(selectedBond?.id)
    // console.log('rowIndex', rowIndex)
    //         // if(rowIndex) rowIndex.scrollIntoView({ behavior: "smooth" });

    //         // const colIndex = 0
    //         // apiRef.current.scrollToIndexes({ rowIndex, colIndex });
    //         // const id = gridExpandedSortedRowIdsSelector(apiRef)[rowIndex];
    //         // const column = gridVisibleColumnDefinitionsSelector(apiRef)[colIndex];
    //         // apiRef.current.setCellFocus(selectedBond?.id, column.field);

    //         // apiRef.current.setCellFocus(selectedBond?.id, 'isin');
    //     }
    // }, [selectedBond, searchVal, sortBy, paginationModel])


    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            bondsCount !== undefined
                ? bondsCount
                : prevRowCountState
        )
    }, [bondsCount, setRowCountState]);

    const sortHandler = (field) => {

        try {
            const columnIndex = sortOrder.findIndex(col => col.field == field)
            if (columnIndex == -1)
                return

            if (sortBy?.field == sortOrder[columnIndex]?.field)
                sortOrder[columnIndex].sortASC = !sortOrder[columnIndex].sortASC

            setSortOrder([...sortOrder])
            setSortBy({ ...sortOrder[columnIndex] })

        } catch (error) {
            console.error(error.message)
        }
    }

    const showConfirmationDialog = (callback = () => { }) => {
        setDialogCallback(() => callback)
    }

    const rowClick = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        // console.log('params', params)
        // console.log('event', event)
        // console.log('details', details)
        if (params.row.id == selectedBond?.id)
            return

        if (Boolean(selectedBond?.isEdit) == false) {
            actionBonds.getBond(params.row.id)(dispatch);
            actionBonds.getBondRemovePossibility(params.row.id)(dispatch);
        }
        else
            showConfirmationDialog(() => {
                actionBonds.getBond(params.row.id)(dispatch);
                actionBonds.getBondRemovePossibility(params.row.id)(dispatch);
            })
    }

    const NoRowOverlay = () => { // нет данных для отображения
        const classes = useStyles()
        return <Box className={classes.tabContainerNoData}>
            <Box className={classes.labelColor}>
                {/* {t("NoResultToShow")} */}
                {/* {console.log('bondsList', bondsList)} */}
            </Box>
        </Box>
    }

    const NoResultsOverlay = () => { // ничего не найдено
        const classes = useStyles()
        return <Box className={classes.tabContainerNoData}>
            <Box className={classes.labelColor}>
                {/* {t("NoResultToShow")} */}
                {/* {console.log('bondsList*', bondsList)} */}
            </Box>
        </Box>
    }

    const LoadingOverlay = () => { // загрузка
        const classes = useStyles()
        return <Box className={classes.tabContainerNoData}>
            <Box className={classes.labelColor}>
                {t("Loading")}
            </Box>
        </Box>
    }


    return (
        <>
            <ConfirmationForm
                header={t("ChangesNotSaved")}
                content={t("AreYouShureToQuitWithoutSave")}
                onAccept={() => {
                    dialogCallback()
                    setDialogCallback(null)
                }}
                onDecline={() => setDialogCallback(null)}
                acceptButton={t("Yes")}
                declineButton={t("No")}
                isOpen={dialogCallback != null}
            />
            <Box className={classes.rootContainer}>
                <Box className={classes.container}>
                    <Box className={classes.header}>
                        <Box>{
                            // t("Bonds")
                        }
                        </Box>
                        <Box className={classes.searchBlock}>
                            {t("filter")}
                            <TextField
                                variant='outlined'
                                size='small'
                                className={classes.inputRoot}
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                inputProps={{ autoComplete: "off" }}
                                InputProps={{
                                    endAdornment: Boolean(searchInput != '') ? (
                                        // <InputAdornment position="end">
                                        <IconButton
                                            className={classes.clearButton}
                                            onClick={() => setSearchInput('')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="small"
                                        >
                                            <Clear />
                                        </IconButton>
                                        // </InputAdornment>

                                    ) : <></>
                                }}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.body}>
                        <Box className={classes.dataContainer}>

                            <DataGridPremium
                                sx={{

                                }}
                                apiRef={apiRef}
                                classes={{
                                    root: classes.dataBlock,
                                    // virtualScroller: classes.virtualScrollablePanel,
                                    columnHeaderTitleContainer: classes.columnHeaderTitleContainer,
                                    columnHeader: classes.columnHeader,
                                    row: classes.tableRow,
                                    footerContainer: classes.footerContainer,
                                    cell: classes.cellData,
                                    // cellContent: classes.cellData,
                                    columnHeadersInner: classes.columnHeadersInner,
                                    // pinnedColumnHeaders: classes.pinnedColumnHeaders,
                                    // pinnedColumns: classes.pinnedColumns,
                                }}

                                slots={{
                                    noRowsOverlay: NoRowOverlay,
                                    noResultsOverlay: NoResultsOverlay,
                                    loadingOverlay: LoadingOverlay,
                                }}

                                rows={bondsList}
                                onRowClick={rowClick}
                                columns={columns}
                                // initialState={{
                                //     // pinnedColumns: {
                                //     //     left: ['name'],
                                //     //     right: ['unit']
                                //     // },
                                //     // pagination: {
                                //     //     paginationModel: {
                                //     //         pageSize: PAGE_SIZE,
                                //     //     },
                                //     // },
                                // }}
                                disableRowSelectionOnClick={true}
                                disableMultipleRowSelection={true}
                                hideFooterSelectedRowCount={true}

                                rowSelectionModel={rowSelectionModel}

                                disableColumnSelector
                                disableColumnFilter
                                disableColumnMenu

                                showCellVerticalBorder={false}
                                showColumnVerticalBorder={true}
                                density='compact'

                                rowHeight={40}
                                scrollbarSize={4}
                                pagination={true}
                                rowCount={rowCountState}
                                pageSizeOptions={[PAGE_SIZE]}
                                paginationModel={paginationModel}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                hideFooterPagination={false}
                                hideFooter={rowCountState < 100}

                            // unstable_cellSelection
                            // onClipboardCopy={(copiedString) => console.log(copiedString)}
                            // unstable_ignoreValueFormatterDuringExport

                            />
                            {/* <Box className={classes.bondHeader}>
                                {
                                    columns?.map((column, index) => <Box className={classes.bondHeaderItem}
                                        onClick={() => sortHandler(index)}
                                        sx={{
                                            maxWidth: column?.width ? `${column.width}px !important` : 'initial',
                                            width: column?.width ? `${column.width}px !important` : 'initial'
                                        }}>
                                        {t(column?.name)}
                                        <Box>
                                            <Sort className={
                                                [classes.sortIcon,
                                                column.field == sortBy?.field ? classes.currentSortIcon : '',
                                                column?.sortASC == true ? classes.ASCIcon : ''
                                                ].join(' ')} />
                                        </Box>
                                    </Box>)
                                }
                            </Box> 
                             {bondsList?.items
                                // ?.filter(bond => {
                                //     try {
                                //         // const reg = new RegExp(filter, "i")
                                //         return columns.some(column => RegExp(searchVal, "i").test(bond[column.field]))
                                //     } catch (error) {

                                //     }
                                // })
                                // ?.sort((a, b) => {
                                //     const sortField = sortBy?.field
                                //     const sortASC = sortBy?.sortASC
                                //     return a[sortField] < b[sortField] ? (sortASC ? -1 : 1)
                                //         : a[sortField] > b[sortField] ? (sortASC ? 1 : -1) : 0
                                // })
                                ?.map(row => <Box className={classes.bondItem}>
                                    {columns?.map(column => <Box className={classes.bondItemColumn}
                                        sx={{
                                            maxWidth: column?.width ? `${column.width}px !important` : 'initial',
                                            width: column?.width ? `${column.width}px !important` : 'initial',
                                            textAlign: column?.align ? column?.align : 'initial',
                                        }}>
                                        {getCellData(row, column)}
                                    </Box>)}
                                </Box>)} */}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default BondsList