import React, { useCallback, useEffect, useLayoutEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import styled from '@emotion/styled'
import style from '@Styles/domrf-theme.module.scss'
import { createStyles, makeStyles } from '@mui/styles'
import { Box, Button, IconButton, Slider, Tab, Tabs, ToggleButton, ToggleButtonGroup, Tooltip, alpha, debounce } from '@mui/material'
// import { ReactComponent as ExcelIcon } from "../../../../../src/images/excel-icon.svg"
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { actionCreators as actionAlert } from '@Base/store/AlertStore'
import { useDispatch } from 'react-redux'
import { SaveOutlined } from '@mui/icons-material'
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_exporting_data from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official'
import { roundDown, roundUp, setHighchartsLocalization } from '@Base/Utilities'
import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { actionCreators as actionDownloads } from '@Base/store/DownloadStore'

import Localization from '@Base/components/Localization'
import SpinnerBars from '@Base/components/shared/SpinnerBars'
import CustomTooltip, { getTooltip } from '@Base/components/shared/CustomTooltip'
import { clearValue, getPoolLLDForChartV2 } from '@Base/newStore/conventionSlice'
import { useNewDispatch, useNewSelector } from '@Base/index'
import { axisGridLineColor, axisLabelsColor, axisLineColor, axisTitleColor } from '@Base/components/constants/charts'

const useStyles = makeStyles((theme) => createStyles({
    smallToggleButton: {
        maxHeight: '22px',
        fontSize: '13px',
    },
    fullPanelAlert: {
        position: 'absolute',
        left: 0, top: 0, right: 0, bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: style.colorPrimary3_40,
        textAlign: 'center',
        userSelect: 'none',
        textTransform: 'uppercase',
    },
    headersButtonOutlined: {
        letterSpacing: '1.5px',
        fontSize: '0.8rem !important',
        // color: style.colorPrimary3,
        padding: '0 0.5rem',
        marginRight: '0.5rem',
        backgroundColor: 'rgba(183, 217, 139, 0.23)',
        borderColor: 'rgb(61,149,109)',
        color: 'rgb(61,149,109)',
        '&:hover': {
            borderColor: `${style.colorPrimary2} !important`,
            backgroundColor: 'rgba(183, 217, 139, 0.23) !important',
            color: `rgb(0,176,80) !important`,
        }
        // not(.Mui-disabled)
        // lineHeight: '2rem',
    },
    textIcon: {
        fontSize: '0.8rem !important',
        textTransform: 'initial',
        padding: '0 0.5rem !important',
        minWidth: '40px !important',
        letterSpacing: 'initial',
        color: style.colorPrimary3_80,
        filter: 'grayscale(1)',
        fontFamily: 'DINPro, Helvetica',
        fontWeight: 100,
    },
    wideTooltip: {
        maxWidth: '400px !important'
    },
    coverLayout: {
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        zIndex: '+100',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',

    },
    root: {
        // marginRight: '0.2rem',
        // marginLeft: '0.2rem',
        // marginTop: '0.5rem',
        // height: '421px',
        // position: 'relative',
        position: 'absolute',
        left: '0.2rem', top: '0.5rem', bottom: 0, right: '0.2rem',
        // maxHeight:'375px',
        '& $panelHeader *': {
            fontSize: '1rem'
        }
    },
    rootPaper: {
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'visible',
        boxSizing: 'border-box',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 0',
        justifyContent: 'space-between',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        marginLeft: '0.5rem',
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80,
    },
    panelData: {
        margin: '0.8rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        wlexWrap: 'nopwrap',
    },
    tooltip: {
        '& *': { fontSize: '0.8rem !important' },
        // '&>span': {
        //     outline: 'solid',
        //     border: 'solid 1px #000',
        // }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    poolTabContaner: {
        margin: 0,
        backgroundColor: 'transparent',
        minHeight: 'unset',
        position: 'relative',
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        },
    },
    tabRoot: {
        backgroundColor: '#FFF !important',
        padding: '0 0.5rem !important',
        minWidth: 'fit-content !important',
        color: `${alpha(style.colorPrimary3_60, 0.5)} !important`,
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        minHeight: '2rem',
        height: '2rem',
        fontSize: '1rem',
        whiteSpace: 'nowrap',
        color: style.colorPrimary3_40,
        position: 'relative',
        overflow: 'visible',
        transition: 'all 0.2s ease-in-out',
        // borderRight: `solid 1px ${style.colorPrimary3_40}`,
        '&::before': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: '3px',
            bottom: '-1px',
            width: '1px',
            backgroundColor: `${style.colorPrimary3_40}`,
        },
    },
    selectedTab: {
        '&::after': {
            backgroundColor: '#0002',
        },
        color: `${style.colorPrimary3_60} !important`,
        backgroundColor: '#FFF !important',
        borderBottom: 'solid 1px #FFF !important',
    },
    chartContainer: {
        position: 'relative',
        flexGrow: 1
    },
    chartContainerFlex: {
        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    centerData: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: style.colorPrimary3_40
    },
    saveButton: {
        position: 'absolute',
        top: '5px',
        right: '60px',
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.2rem',
        color: style.colorPrimary3_40
    },
    markActive: {
        boxShadow: `0px 0px 0px 4px ${alpha(style.colorPrimary2, 0.5)} !important`
    },
    mark: {
        backgroundColor: 'transparent',
    },
    sliderRoot: {
        color: style.colorPrimary3,
        width: '240px',
        minWidth: '240px',
        maxWidth: '240px',
        paddingTop: 0,
        paddingBottom: 0,
        // zIndex: +1,

    },
    // container: {
    //     position: 'relative',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     height: '100%'
    // },

    controlsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        //alignItems: 'center',
        justifyContent: 'center',
        // position: 'absolute',
        // left: '60px',
        // top: '7px',
        '&>*:not(:first-child)': {
            marginLeft: '2rem',
        }
    },

    controlBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'max-content',
        flexWrap: 'nowrap',
        // width: '30%'
    },

    spinnerContainer: {
        position: 'relative',
        width: '100%',
        height: '100%'
    }
}))

const CurrentRateChartV2 = forwardRef(({ setReadyForExport }, ref) => {


    HC_exporting(Highcharts)
    HC_exporting_data(Highcharts)
    setHighchartsLocalization(Highcharts, t)

    const cls = useStyles()
    // const dispatch = useDispatch()
    const newDispatch = useNewDispatch()
    const chart = useRef()
    // const { deal = null, selectedNode = null, ...otherStore } = useSelector((state: any) => state.securitization)

    // const {
    //     // loading = false,
    //     pricingParameters = null,
    //     // error = null,
    //     // calculationParameters = null,
    //     poolStatistics = null,
    // } = useSelector((state: any) => state.convention)

    const {
        pricingParameters = null,
        poolStatistics = null,
    } = useNewSelector((state: any) => state.newConvention)


    const { chartsData = {},
        loadingChartData = false, ...otherNewStore } = useNewSelector(state => state.newConvention)

    // console.log('otherNewStore', otherNewStore)

    const [chartData, setChartData] = useState(null)
    const [chartLoading, setChartLoading] = useState(false);
    const [step, setStep] = useState(null)
    const [steps, setSteps] = useState([0.1, 0.5, 1])
    const [lods, setLods] = useState([])
    const [lod, setLod] = useState(null);
    // const [binCount, setBinCount] = useState(0)
    // const [currentWACBinIndex, SetCurrentWACBinIndex] = useState(0)

    const [leftSlice, setLeftSlice] = useState(0)
    const [rightSlice, setRightSlice] = useState(0)

    const g = () => chartData?.steps

    useImperativeHandle(ref, () => {
        // console.log('renew ImpHandle', chartData?.steps)
        return {
            exportChart: () => {
                if (chart.current) {
                    console.log('exportV2')
                    chart.current?.chart?.exportChart({ width: 2048, height: 1365 });
                }
            }
        }
    })

    const chartTitle = `${t("convention2.chartTitle")} ${pricingParameters?.bondID} ${Localization.FormatDateStringLocale(poolStatistics?.reportDate)}.png`

    const [chartOptions, setChartOptions] = useState({
        // poolChartOptions: {
        //     customData: () => { return { step: step } },
        // },
        exporting: {
            enabled: false,
            filename: chartTitle,
            chartOptions: {
                chart: {
                    marginBottom: 55,
                },
                legend: {
                    align: 'center',
                    itemDistance: 40,
                    itemStyle: {
                        fontSize: '8px',
                        overflow: 'hidden',
                    },
                },
            }
        },
        chart: {
            animation: false,
            marginLeft: 34,
            marginRight: 34,
            marginBottom: 50,
        },
        plotOptions: {
            series: {
                stickyTracking: true,
                pointPadding: 0,
                groupPadding: 0.1,
                dataLabels: {
                    enabled: false,
                },
                states: {
                    inactive: {
                        enabled: false,
                    },
                    hover: {
                        enabled: false,
                        halo: {
                            size: 0
                        }
                    }
                },
                events: {
                    legendItemClick: function () {
                        return false; // Prevent the default legendItemClick behavior
                    }
                }
            },
            column: {
                borderRadius: 0,
            }
        },

        title: {
            text: t('CurrentCreditRate'),
            style: { display: "none", "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold", 'float': 'left' },
            align: 'left',
            verticalAlign: 'top',
            x: 50,
            y: 8,
        },

        xAxis: {
            crosshair: {
                color: alpha(style.colorPrimary2, 0.1)
            },
            categories: null,
            labels: {
                enabled: true,
                rotation: 0,
                allowOverlap: true,
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none',
                    color: axisLabelsColor,
                    fontSize: '11px',
                },
                y: 15,
            },
            title: {
                text: t('convention2.percentsPerYear'),
                offset: 15,
                enabled: true,
                style: {
                    color: axisTitleColor
                }
            },

            gridLineWidth: 0,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: axisLineColor,
            tickmarkPlacement: 'between',

            plotLines: [
                {
                    color: style.colorPrimary3_60,
                    dashStyle: 'Dash',
                    width: 1.3,
                    value: -0.2,
                    zIndex: 2,
                    label: {
                        rotation: 0,
                        textAlign: 'left',
                        x: 5,
                        y: 15,
                        style: {
                            color: style.colorPrimary3,
                            fontWeight: 'bolder',
                            fontSize: '0.9rem'
                        },
                    }
                }
            ]
        },

        yAxis: [{
            visible: true,
            labels: {
                enabled: true,
                x: -4,
                style: {
                    fontSize: '11px',
                    color: axisLabelsColor,
                }
            },
            stackLabels: {
                enabled: false
            },
            title: {
                text: t('convention2.CurrentDebtShare'),
                offset: 21,
                style: {
                    fontSize: '12px',
                    color: axisTitleColor
                }
            },
            tickPositioner: function (min, max) {
                const rounder = 5
                const rMax = roundUp(max, rounder)
                const rMin = roundDown(min, rounder)
                const count = 5
                const step = roundUp((rMax - rMin) / count, rounder)
                var positions = Array.from({ length: count + 1 }, (x, i) => i * step)
                return positions;
            },
            gridLineColor: axisGridLineColor,
            gridLineWidth: 1,
            gridLineDashStyle: 'solid',
            lineWidth: 1,
            lineColor: axisLineColor,
            gridZIndex: 1,
        }, {
            visible: true,
            labels: {
                enabled: true,
                x: 4,
                style: {
                    fontSize: '11px',
                    color: axisLabelsColor,
                }
            },
            stackLabels: {
                enabled: true
            },
            title: {
                text: t('convention2.Years'),
                offset: 21,
                style: {
                    fontSize: '12px',
                    color: axisTitleColor,
                }
            },
            opposite: true,
            tickPositioner: function (min, max) {
                const rounder = 5
                const rMax = roundUp(max, rounder)
                const rMin = roundDown(min, rounder)

                const count = 5
                const step = roundUp((rMax - rMin) / count, 1)
                var positions = Array.from({ length: count + 1 }, (x, i) => rMin + i * step)
                return positions;
            },
            gridLineWidth: 0,
            lineWidth: 1,
            lineColor: axisLineColor,
            gridZIndex: 1,
        }],

        legend: {
            useHTML: true,
            margin: 0,
            y: 15,
            itemMarginTop: 0, // отступ сверху для каждого элемента легенды
            itemMarginBottom: 0, // отступ снизу для каждого элемента легенды
            itemStyle: {
                cursor: 'default',
                fontSize: '12px',
                fontWeight: 'bold',
                color: style.colorPrimary1,
                fill: style.colorPrimary1,
            },
            // itemHoverStyle: {
            //     // color: style.colorPrimary3
            // },
        },
        tooltip: {
            shape: "square",
            animation: false,
            hideDelay: 0,
            useHTML: true,
            shared: true,
            borderColor: style.colorPrimary3_60,
            style: {
                fontSize: '12px',
            },
            positioner: function (labelWidth, labelHeight, point) {
                return {
                    x: this.chart.plotLeft + 5,
                    y: this.chart.plotTop + 5
                };
            },
            // headerFormat: '<table><tr>' +
            //     '<td>' + t('convention2.CurrentRate') + ':</td>' +
            //     '<td style="text-align:end"><b>{point.key:.1f}</b></td>' +
            //     '</tr>',
            // pointFormat: '<tr><td style="padding:0">{series.name}: </td>' +
            //     '<td style="padding-left:0.5rem;text-align:end"><b>{point.y: .1f}</b></td></tr>',
            // footerFormat: '</table>',
            formatter: function () {
                // console.log('this', this.points)
                if (this?.points == null || !this?.points?.length) return
                const getPointValue = (point) => {
                    // console.log('point', point.series)
                    switch (point?.series?.userOptions?.id) {
                        // "rateWithGovTooltip":"Надбавка по гос. пр., % год.",
                        // "rateWithoutGovTooltip":"Ставка по станд. кр.,% год.",
                        // "oodWithGovTooltip":"Гос. программы, % от ОД",
                        // "oodWithoutGovTooltip":"Станд. кредиты, % от ОД",
                        // "maturityYearsTooltip":"Срок до погашения, лет",

                        case 'oodWithGov': return ([{ name: 'convention2.rateWithGovTooltip', value: this.x }, { name: 'convention2.oodWithGovTooltip', value: Localization.FormatNumber(point.y, 1, 1) }])
                        case 'oodWithoutGov': return ([{ name: 'convention2.rateWithoutGovTooltip', value: this.x }, { name: 'convention2.oodWithoutGovTooltip', value: Localization.FormatNumber(point.y, 1, 1) }])
                        case 'maturity': return ([{ name: 'convention2.maturityYearsTooltip', value: Localization.FormatNumber(point.y, 1, 1) }])
                    }

                    return null
                }

                var points = []
                this.points.forEach(point => {
                    const val = getPointValue(point)
                    // console.log('val', val)
                    if (val) points = [...points, ...val]
                })

                return points.reduce(function (s, point) {
                    return s + `<div style='display:flex;flex-direction:row;justify-content:space-between;'>
                            <div style='min-width:10rem;'>${t(point.name)}</div>
                            <div style='margin-left:0.5rem;min-width:2rem;text-align:right;font-weight:bold;'>${Localization.FormatNumber(point.value, point?.fraction ?? 0, point?.fraction ?? 0)}</div>
                            </div>`
                }, '')

                // return '<table style="min-width:200px;max-width:600px">' +
                //     points.reduce(function (s, point) {
                //         return s + '<tr><td style="padding:0">' + t(point.name) + ': </td>' +
                //             '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(point.value, point?.fraction ?? 0, point?.fraction ?? 0) + '</b></td></tr>'
                //     }, '') +
                //     '</table>';
            },
        },

        credits: {
            enabled: false
        },

        series: [
            {
                id: 'oodWithGov',
                type: 'column',
                name: t('convention2.rateWithGov'),
                color: 'rgba(183, 217, 139, 0.35)', //'rgba(183, 217, 139, 0.23)',
                borderColor: alpha(style.colorPrimary3, 0.5),
                xAxis: 0,
                yAxis: 0,
                data: null,
                animation: false,
                showInLegend: true,
                visible: true,
                stacking: true,
                dataLabels: {
                    enabled: false
                }
            },
            {
                id: 'oodWithoutGov',
                type: 'column',
                name: t('convention2.rateWithoutGov'),
                color: alpha(style.colorPrimary3, 0.15), //alpha(style.colorPrimary3, 0.1),
                borderColor: alpha(style.colorPrimary3, 0.5),
                xAxis: 0,
                yAxis: 0,
                data: null,
                animation: false,
                showInLegend: true,
                column: {
                    zones: {
                        fillColor: style.colorPrimary2_20
                    }
                },
                visible: true,
                stacking: true,
                dataLabels: {
                    enabled: false
                }
            },
            {
                type: 'line',
                id: 'maturity',
                name: t('convention2.Maturity'),
                color: style.colorPrimary3_60,
                lineWidth: 1.5,
                xAxis: 0,
                yAxis: 1,
                data: null,
                showInLegend: true,
                states: {
                    hover: {
                        enabled: false
                    }
                },
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    lineColor: style.colorPrimary3_80,
                    lineWidth: 1,
                    radius: 4,
                    fillColor: '#FFFFFF',
                },
                animation: false,
                visible: true,
            }]
    })

    useLayoutEffect(() => {
        setLod(0)
        setStep(steps[1])
        setChartData(null)

        if (window) {
            window.addEventListener('resize', debounce(onResize, 300))
            onResize();
        }

        return () => {
            window.removeEventListener('resize', debounce(onResize, 300))
        }
    }, [])

    useEffect(() => {
        newDispatch(clearValue('chartsData'))
        // actionConvention.clearValue('chartsData')(dispatch)

    }, [pricingParameters?.bondID])

    // console.log('pricingParameters', pricingParameters)
    useLayoutEffect(() => {
        if (step != null) {
            if (pricingParameters?.bondID && poolStatistics?.reportDate) {
                newDispatch(getPoolLLDForChartV2({ bondID: pricingParameters.bondID, poolReportDate: poolStatistics.reportDate, step: step }))
            } else {
                newDispatch(clearValue('chartsData'))
                // actionConvention.clearValue('chartsData')(dispatch)
            }
        }
    }, [pricingParameters?.bondID, poolStatistics?.reportDate, step])

    useLayoutEffect(() => {

        setReadyForExport(!chartLoading)

    }, [chartLoading])

    useLayoutEffect(() => {

        if (chartsData?.currentRateChart?.steps != null) {
            let arr = [...chartsData?.currentRateChart?.oodWithoutGov ?? []]
            let lSlice = 0
            if (!chartsData?.currentRateChart?.oodWithGov?.some(x => x != null)) {
                arr.reduce((accumulator, currentValue, currentIndex) => {
                    if (accumulator + currentValue > 5) { lSlice = currentIndex - 1; return; }
                    return accumulator + currentValue
                }, 0)
            }
            else {
                // console.log('chartsData?.currentRateChart?.oodWithGov', chartsData?.currentRateChart?.steps)
                const pos = chartsData?.currentRateChart?.steps?.findIndex(val => val == '-0.5')
                lSlice = pos > 0 ? pos : 0
            }
            let rSlice = arr?.length ?? 0

            if (chartsData?.currentRateChart?.oodWithoutGov?.some(x => x != null)) {
                arr.toReversed().reduce((accumulator, currentValue, currentIndex) => {
                    if (accumulator + currentValue > 5) { rSlice = arr.length - currentIndex; return; }
                    return accumulator + currentValue
                }, 0)
            }
            setLeftSlice(lSlice)
            setRightSlice(rSlice)
            const binLength = rSlice - lSlice
            // console.log('rSlice , lSlice', rSlice, lSlice)
            const cnt = arr?.length - 1
            if (binLength > 0) {
                let sizeLeft = lSlice
                let sizeRight = cnt - rSlice
                let lodsSize = sizeRight > sizeLeft ? sizeRight : sizeLeft

                if (lodsSize > 1) {
                    let lodsArr = Array.from(Array(lodsSize + 1).keys()).map(x => { return { value: x } })
                    setLods(lodsArr)
                } else {
                    setLods([])
                }
            }
        }

        setChartData(chartsData?.currentRateChart);

    }, [chartsData]);

    useEffect(() => {
        if (chart.current != null)
            chart.current.chart.reflow();
    }, [chartOptions])

    useEffect(() => {
        setChartLoading(loadingChartData);
    }, [loadingChartData])

    useLayoutEffect(() => {
        updateChart()
    }, [lod, chartData])

    const updateChart = () => {

        if (chartData?.steps == null || lod < 0)
            return

        const lineStyle = {
            color: axisGridLineColor,
            dashStyle: 'solid',
            width: 1,
            zIndex: 2,
            label: {
                rotation: 0
            }
        }

        var opts = { ...chartOptions };
        var data = { ...chartData }
        // console.log('data', data)
        let sliceLeftIndex = leftSlice - parseInt(lods[lod]?.value)
        let minX = parseFloat(data?.steps[0])
        if (sliceLeftIndex < 0) sliceLeftIndex = 0

        data.steps = data.steps.map(x => `${x}+`) //добавляем плюсы в бины

        if (sliceLeftIndex > 0) {

            let y1ArrLeft = data?.oodWithoutGov?.slice(0, sliceLeftIndex)
            const sliceDebtLeft = y1ArrLeft?.some(x => x) ? y1ArrLeft?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y1ArrLeftWG = data?.oodWithGov?.slice(0, sliceLeftIndex)
            const sliceDebtLeftWG = y1ArrLeftWG?.some(x => x) ? y1ArrLeftWG?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y2ArrLeft = data?.wala?.slice(0, sliceLeftIndex)
            let sliceYearsLeft = y2ArrLeft?.map((x, index) => {
                if (x == null || (y1ArrLeft[index] == null && y1ArrLeftWG[index] == null)) return null
                return x * (y1ArrLeft[index] ?? 0 + y1ArrLeftWG[index] ?? 0)
            }).reduce((x, s) => (x ?? 0) + s, 0) / (sliceDebtLeft + sliceDebtLeftWG)

            data.oodWithoutGov = [sliceDebtLeft != null ? sliceDebtLeft : null, ...data?.oodWithoutGov?.slice(sliceLeftIndex)]
            data.oodWithGov = [sliceDebtLeftWG != null ? sliceDebtLeftWG : null, ...data?.oodWithGov?.slice(sliceLeftIndex)]
            data.wala = [sliceYearsLeft != null ? sliceYearsLeft : null, ...data?.wala?.slice(sliceLeftIndex)]

            data.steps = data?.steps?.slice(sliceLeftIndex ? sliceLeftIndex - 1 : 0)
            minX = minX + step * (sliceLeftIndex - (sliceDebtLeft != null && sliceDebtLeftWG != null ? 1 : 0)) - step

            data.steps[0] = `<${data?.steps[1]?.replace(/[\+]/g, '')}`
        }

        let sliceRightIndex = -sliceLeftIndex + rightSlice + parseInt(lods[lod]?.value) + 2// -sliceLeftIndex + currentWACBinIndex + (binCount + parseInt(lods[lod]?.value)) + 1   //позиция среза справа
        if (sliceRightIndex < chartData.oodWithoutGov?.length - sliceLeftIndex) {

            let y1ArrRight = data.oodWithoutGov?.slice(sliceRightIndex - 1)
            const sliceDebtRight = y1ArrRight?.some(x => x) ? y1ArrRight?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y1ArrRightWG = data.oodWithGov?.slice(sliceRightIndex - 1)
            const sliceDebtRightWG = y1ArrRightWG?.some(x => x) ? y1ArrRightWG?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y2ArrRight = data.wala?.slice(sliceRightIndex - 1)
            let sliceYearsRight = y2ArrRight?.map((x, index) => {
                if (x == null || (y1ArrRight[index] == null && y1ArrRightWG[index] == null)) return null
                return x * (y1ArrRight[index] ?? 0 + y1ArrRightWG[index] ?? 0)
            }).reduce((x, s) => (x ?? 0) + s, 0) / (sliceDebtRight + sliceDebtRightWG)

            data.oodWithoutGov = [...data?.oodWithoutGov?.slice(0, sliceRightIndex - 1), sliceDebtRight != null ? sliceDebtRight : null]
            data.oodWithGov = [...data?.oodWithGov?.slice(0, sliceRightIndex - 1), sliceDebtRightWG != null ? sliceDebtRightWG : null]
            data.wala = [...data?.wala?.slice(0, sliceRightIndex - 1), sliceYearsRight != null ? sliceYearsRight : null]

            data.steps = data?.steps?.slice(0, sliceRightIndex)

            const lastBin = data.steps?.length - 1
            data.steps[lastBin] = `≥${data?.steps[lastBin]?.replace(/[\+]/g, '')}`
        }

        opts.xAxis.plotLines = [opts.xAxis.plotLines[0]]

        opts.xAxis.categories = data.steps?.map((x, index) => {
            if (x == '' || x == null)
                return ""

            let value = parseFloat(`${x}`?.replace(/[<>=≥\+]/g, ''))

            if (value % 1 === 0 && index != 0)
                opts.xAxis.plotLines.push({ ...lineStyle, value: index - 0.5 })

            return x
        })

        opts.xAxis.labels.formatter = function () {

            let textVal = `${this.value}`
            let value = parseFloat(textVal?.replace(/[<>=≥\+]/g, ''))

            if (!(this.pos == 0 || this.pos == opts.xAxis?.categories?.length - 1)) {

                const leftPadding = opts.xAxis?.categories?.length > 50 ? 4 : 2
                const rightPadding = opts.xAxis?.categories?.length > 50 ? 6 : 4
                switch (step) {
                    case 0.1:
                        textVal = (this.pos < leftPadding || this.pos > opts.xAxis?.categories?.length - rightPadding) ? '' :
                            data.steps?.length < 50 ?
                                (value % 0.5 === 0 ? textVal : '') :
                                (value % 1 === 0 ? textVal : '')
                        break

                    case 0.5:
                        textVal =
                            data.steps?.length < 20 ?
                                (value % 0.5 === 0 ? textVal : '') :
                                data.steps?.length < 35 ?
                                    (value % 1 === 0 ? textVal : '') :
                                    (value % 2 === 0 ? textVal : '')
                        break
                }
            }

            return textVal?.replace(/([\+])/g, `<span style='color:${alpha(style.colorPrimary3, 0.6)};'>$1</span>`)
        }

        opts.series[1].data = data.oodWithoutGov
        opts.series[1].showInLegend = data.oodWithoutGov?.some(x => x)
        opts.series[0].data = data.oodWithGov
        opts.series[0].showInLegend = data.oodWithGov?.some(x => x)

        opts.series[2].data = data?.wala?.map((item, index) => [index, item])

        opts.yAxis[1].visible = (step == steps[0] ? false : true)
        opts.series[2].visible = (step == steps[0] ? false : true)
        opts.series[2].showInLegend = (step == steps[0] ? false : true)

        const oodWithoutGov = Math.round(data.oodWithoutGov?.reduce((accumulator, currentValue) => accumulator + currentValue, 0,))
        let WACval = 0
        if (chartData?.wac != null) {
            WACval = (chartData?.wac - minX) / step - 0.5
            opts.xAxis.plotLines[0].value = WACval

            opts.xAxis.plotLines[0].label = {
                ...opts.xAxis.plotLines[0].label,
                ...Math.abs(WACval - opts.xAxis?.categories?.length) < 5 ? { textAlign: 'right', x: -5 } : { textAlign: 'left', x: 5 }
            }
            opts.xAxis.plotLines[0].label.text = `${t('convention2.WAC')} ${Localization.FormatNumber(chartData.wac, 1, 2)}${oodWithoutGov != 100 ? `<br>${oodWithoutGov}${t('convention2.percentsInPool')}` : ''}`
        }
        else
            opts.xAxis.plotLines[0].value = null

        if (chartData?.govWAC != null) {
            const oodWithGov = 100 - oodWithoutGov ?? 0 // Math.floor(data.oodWithGov?.reduce((accumulator, currentValue) => accumulator + currentValue, 0,))
            const govWACval = (chartData?.govWAC - minX) / step - 0.5
            // console.log('govWACval,', govWACval,)
            opts.xAxis.plotLines.push({
                ...opts.xAxis.plotLines[0],
                color: style.colorPrimary2_60,
                dashStyle: 'dash',
                value: govWACval,
                label: {
                    ...opts.xAxis.plotLines[0]?.label,
                    textAlign: (govWACval - WACval > 3) || (govWACval < 4) ? 'left' : 'right',
                    x: (govWACval - WACval > 3) || (govWACval < 4) ? 5 : -5,
                    style: {
                        ...opts.xAxis.plotLines[0]?.label.style,
                        color: style.colorPrimary2_60,
                    },
                    text: `${t('convention2.govWAC')}${Localization.FormatNumber(chartData.govWAC, 1, 2)}${oodWithGov != 100 ? `<br>${oodWithGov}${t('convention2.percentsInPool')}` : ''}`
                }
            })
        }
        opts.exporting.filename = chartTitle

        setChartOptions(opts)
    }

    const onResize = () => {
        if (chart.current != null) {
            chart.current?.chart.reflow();
        }
    }

    const handleStep = (event: React.MouseEvent<HTMLElement>, newValue: number | null) => {
        if (newValue != null)
            setStep(newValue)
    }

    const handleLODTrack = (event: Event, value: number | Array<number>, activeThumb: number) => {
        setLod(value);
    }

    return (
        <Box className={cls.chartContainerFlex}>
            {chartLoading ? <SpinnerBars withBackground label={t('convention2.loadingPoolsData')} /> : <></>}
            {(!chartData?.steps || !chartsData || chartOptions?.series == null || chartOptions.series?.length == 0) ?
                <Box className={cls.fullPanelAlert}>
                    {chartData?.steps == null && !loadingChartData ?
                        <div dangerouslySetInnerHTML={{ __html: t('convention2.currenRateChartIsEmpty') }} />
                        : ''}
                </Box> :
                <>
                    <HighchartsReact
                        ref={chart}
                        highcharts={Highcharts}
                        options={chartOptions}
                        containerProps={{ style: { /*height: "100%", */width: "100%", flexGrow: 1 } }}
                        callback={() => {
                            setTimeout(() => {
                                onResize()
                            }, 1)
                        }
                        }
                    />

                    <Box className={cls.controlsContainer} sx={{ mb: 1 }}>
                        <Box className={cls.controlBox}>
                            <ToggleButtonGroup
                                value={step}
                                exclusive
                                onChange={handleStep}
                                aria-label="step"
                            >
                                {steps?.map(x => <ToggleButton
                                    classes={{ root: cls.smallToggleButton }}
                                    size='small'
                                    sx={{ pt: 0, pb: 0 }}
                                    key={`step_${x}`} value={x}>
                                    {Localization.FormatNumber(x, 1, 1)}
                                </ToggleButton>
                                )}
                            </ToggleButtonGroup>
                        </Box>
                        {lods?.length > 1 &&
                            <Box className={cls.controlBox}>
                                <Slider
                                    aria-label={`LOD_steps`}
                                    size='small'
                                    value={lod}
                                    track={false}
                                    defaultValue={lods.length}
                                    marks={lods}
                                    max={lods[lods.length - 1].value}
                                    min={lods[0].value}
                                    onChange={handleLODTrack}
                                    classes={{
                                        root: cls.sliderRoot,
                                        mark: cls.mark,
                                        active: cls.markActive,
                                    }}
                                />
                            </Box>
                        }
                        {/* кнопка Save */}
                        {/* <Box className={cls.controlBox}>
                            <Tooltip
                                disableFocusListener
                                disableInteractive
                                title={t('convention2.SaveAsImage')}>
                                <IconButton size="small"
                                // disabled={chartData?.steps == null}
                                // onClick={onSaveChartAsImageButtonClick}
                                >
                                    <SaveOutlined className={cls.icon} />
                                </IconButton>
                            </Tooltip>
                        </Box> */}
                    </Box>
                </>
            }
        </Box >
    )
})

export default CurrentRateChartV2