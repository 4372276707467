import React, { useState, useEffect, useCallback } from 'react';

import { debounce } from 'throttle-debounce';
import Globals from "@Base/Globals";
import { Paper, Stack, alpha, Box, Container, Tab, Tabs, Theme, Button, Typography, InputAdornment, Tooltip, TextField } from '@mui/material'

import Dialog from '@mui/material/Dialog';
// import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';

import { actionCreators as calculatorStore } from "@Store/CalculatorStore";

import SearchPanelInput from '@Components/shared/SearchPanelInput';
import { MessageType, EntityType } from '../Definitions';

import Localization from '@Components/Localization';
import { createStyles, makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'

import style from '@Styles/domrf-theme.module.scss';

import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridActionsCellItem,
    GridColumnGroupingModel,
    GridComparatorFn,
    GridValueFormatterParams,
    GridRenderCellParams,
    GridPaginationModel,
    GridCallbackDetails,
    GridSortModel,
    GridEventListener,
} from '@mui/x-data-grid-premium';

import { ruRU, enUS } from '@mui/x-data-grid/locales';

interface IProps {
    show: false,
    onClose: any;
    classes: any;
    entityId: number;
    entityType: EntityType;
}


const useStyles = makeStyles((theme: Theme) => createStyles({

    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        flex: '1 1 auto',
        height: 0,
        width: '100%',
        minWidth: '100%'
    },
    searchField: {
        marginLeft: 'auto'
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    progress: {
        width: '100%'
    },
    warningIcon: {
        color: style.colorWarning
    },
    statusButton: {
        padding: '0'
    },

    details: {
        flex: '1 1 auto',
        paddingTop: '1rem'
    },
    gridRoot: {
        flex: '1 1 auto'
    }

}));

const MessagesListDialog = ({ show, onClose, entityId, entityType }: IProps) => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();

    //const [startPageIndex, setStartPageIndex] = useState(0); //CHECKME!!! add to user settings
    //const [pageSize, setPageSize] = useState(50); //CHECKME!!! add to user settings
    const [searchValue, setSearchValue] = useState('');
    const [selected, setSelected] = useState('');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50
    });

    const [locale, setLocale] = useState(ruRU);

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'name',
            sort: 'asc',
        },
    ]);

    const {
        messages
    } = useSelector((state: any) => state.calculator)

    const {
        messagesLoad
    } = useSelector((state: any) => state.loader)

    useEffect(() => {
        searchDebounced(searchValue);
    }, [searchValue, paginationModel, entityId, entityType]);

    const onWindowClose = (event) => {
        if (onClose != null)
            onClose(event);
    }

    const columns: GridColDef[] = [

        { field: 'id', headerName: t('ID'), description: t('messages.id') },
        {
            field: 'type', headerName: t('Type'), description: t('messages.type'), width: 120,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return '';
                }
                return params.value; //CHECKME!!!
            }
        },
        { field: 'position', headerName: t('Position'), description: t('messages.position'), width: 600 },
        { field: 'description', headerName: t('Description'), description: t('messages.description'), width: 600 },
    ];

    const updateDataSource = (searchValue) => {

        if (entityId == null || entityType == null)
            return;

        var filterItem = null;
        if (searchValue != '') {

            if (paginationModel.page != 0) {
                setPaginationModel({ ...paginationModel, page: 0 });
                return;
            }

            filterItem = [{
                Operator: "or",
                Values: [
                    {
                        Name: "name",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "description",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    }
                ]
            }];
        }

        var sortItem = sortModel.map((item, index) => {
            return {
                Name: item.field,
                IsAscending: item.sort == 'asc',
                SortOrder: index
            };
        });

        var filter = {
            Filters: filterItem,
            Sorters: sortItem,
            ItemIndex: paginationModel.page * paginationModel.pageSize,
            PageSize: paginationModel.pageSize,
            Params: { id: entityId, type: entityType }
        };

        calculatorStore.getProcessingMessages(filter)(dispatch);
    }

    const onPaginationModelChange = (model: GridPaginationModel, details: GridCallbackDetails) => {

        setPaginationModel(model);
    }

    const searchDebounced = useCallback(debounce(500, updateDataSource), [paginationModel, sortModel, entityId, entityType]);

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        setSelected(params.row?.description);
    };

    return (

        <Dialog fullScreen onClose={onWindowClose} aria-labelledby="bonds-select-dialog-title" open={show}>
            <MuiDialogContent className={classes.root}>

                <Container className={classes.container}>

                    <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingX: '1rem', paddingY: '0.5rem' }}>
                        <SearchPanelInput className={classes.searchField} value={searchValue} onValueChange={setSearchValue} placeHolder={t('Search')} />
                    </Stack>
                    {/*console.log("portfoliosLoad => " + portfoliosLoad)*/}
                    <DataGridPremium
                        sx={{
                            ["& .bandHeaderCell .MuiDataGrid-columnHeaderTitleContainer"]: {
                                justifyContent: 'center'
                            }
                        }}
                        classes={{ root: classes.gridRoot }}
                        //experimentalFeatures={{ columnGrouping: true }}
                        disableMultipleRowSelection={true}
                        disableColumnMenu={true}
                        loading={messagesLoad}
                        columnHeaderHeight={30}
                        rowHeight={25}
                        rows={messages?.items || []}
                        columns={columns}
                        //columnGroupingModel={columnGrouping}
                        localeText={locale.components.MuiDataGrid.defaultProps.localeText}

                        paginationMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={onPaginationModelChange}
                        rowCount={messages?.count}
                        pagination
                        pageSizeOptions={[30, 50, 100]}

                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        onRowClick={handleRowClick}
                    />

                    <Box className={classes.details}>
                        <TextField
                            fullWidth
                            id="details-id"
                            multiline
                            disabled
                            rows="4"
                            value={selected}
                            variant="outlined"
                        />
                    </Box>

                </Container >
            </MuiDialogContent>
            <MuiDialogActions>
                <Button variant="contained" color="secondary" onClick={onWindowClose}>
                    {t('Close')}
                </Button>
            </MuiDialogActions>
        </Dialog>
    )
}

export default MessagesListDialog;