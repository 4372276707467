import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Box, Tooltip } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import useMobileDetect from '@Base/components/hook/useMobileDetect'
import { isValid as isValidDateFns } from 'date-fns'
import CustomTooltip from '@Base/components/shared/CustomTooltip'

export const withCustomizationInput = (WrappedComponent, ExtProps) => (props) => { //React.forwardRef((props, ref) => {

    const {
        header = null, unit = null, width = null, classes = null,
        needConfirm, autoConfirmTimeout = null, helper = null, helperShowDelay = 3000,
        changesHighlight = null, selectOnFocus = false,
        onChange, value, type, disableWheel = false, inputProps,
        tooltip = null, disableCloseOnSelect, onFocus: onFocusOriginal = () => { }, ...otherProps } = { ...ExtProps, ...props }
    // console.log('inputProps', inputProps)
    const [newVal, setNewVal] = useState(value)
    const [showHelper, setShowHelper] = useState(false)
    const [selected, setSelected] = useState(false)
    const [inEditMode, setInEditMode] = useState(false)
    const oldVal = useRef(value)
    const device = useMobileDetect()
    const refEl = useRef()
    var helperTimeout
    var confirmTimeout
    const headerRef = useRef()

    const useStyles = makeStyles(() =>
        createStyles({
            changed: {
                backgroundColor: changesHighlight ? changesHighlight : 'transparent',
                '& input': {
                    backgroundColor:/* changesHighlight ? changesHighlight :*/ 'transparent'
                }
            },
            optionParamItem: {
                margin: 0,
                padding: '0.2rem 0.5rem',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'start',
                alignItems: 'center',
                overflow: 'hidden',
            },
            optionParamItemInput: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'baseline',
                '-webkit-align-items': 'baseline',
                // '& input': { fontSize: '1.3rem', padding: 0, },
            },
            optionParamItemUnit: {
                // color: style.colorPrimary3_40,
                // fontSize: '0.9rem !important',
                alignSelf: 'baseline',
                textWrap: 'nowrap',
                lineHeight: '1.3rem',
                whiteSpace: 'nowrap',
            },
        }),
    );
    const cls = useStyles()

    const originalEvent = () => {
        // console.log('originalEvent')
        if (changesDetector(newVal, oldVal.current) && onChange) {

            if (helperTimeout) window.clearTimeout(helperTimeout)
            if (confirmTimeout) window.clearTimeout(confirmTimeout)

            const result = onChange(
                {
                    modifiedOnChange: true,
                    currentTarget: {
                        name: props.name,
                        value: newVal,
                    },
                    target: {
                        name: props.name,
                        value: newVal,
                    },
                })

            if (result === false)
                setNewVal(oldVal.current)
            else {
                setSelected(false)
                setInEditMode(false)
            }
        } //else console.log('no changes')

        if (helper != null)
            setShowHelper((prev) => false)
    }

    const inputDownEvents = (e) => {
        // console.log(e)
        if (e.ctrlKey || e.shiftKey) {
            // switch (e.code) {
            //   case 'Insert':
            //   case 'KeyC':
            //   case 'KeyV':
            //     break;
            // }
        }
        else
            switch (e.code) {
                case 'ArrowUp':
                case 'ArrowDown':
                    e.preventDefault()
                    break;

                case 'Enter':
                case 'NumpadEnter':
                case 'Escape':
                case 'Tab':
                case 'Del':
                case 'Backspace':
                case 'ArrowLeft':
                case 'ArrowRight':
                    break

                default:
                    // console.log(e.code, !e.ctrlKey && !e.shiftKey)
                    // if (e.key.match(e.key, inputProps.patern, new RegExp(inputProps.patern)))
                    if (inputProps?.pattern != null) {
                        let reg = new RegExp(inputProps.pattern)
                        if (e.key.match(reg)[0] == '')
                            e.preventDefault()
                    }
                    break
            }
    }

    const inputEvents = (e) => {
        // console.log('e.code', e.key)
        switch (e.code) {
            case 'Enter':
            case 'NumpadEnter':
                // if (changesDetector(newVal, oldVal.current)) {
                //   originalEvent();
                //   /*if (device?.isMobile) e.target.blur()*/
                // }
                e.target.blur()
                // console.log('enter')
                break

            case 'Escape':
                if (changesDetector(newVal, oldVal.current)) {
                    setNewVal(oldVal.current)
                    setTimeout(() => {
                        e.target.blur()
                    }, 200);
                }
                else
                    e.target.blur();
                // console.log('Esc')

                window.clearTimeout(helperTimeout)
                window.clearTimeout(confirmTimeout)
                break

            default:

                break
        }
        // console.log('oldVal.current', oldVal.current, newVal, value, parseFloat(oldVal.current) !== parseFloat(newVal) )
    }

    useLayoutEffect(() => {
        // console.log('store old Val', value)
        oldVal.current = value
        setNewVal(value)
    }, [value])


    useEffect(() => {
        // if (newVal !== value) {
        if (changesDetector(newVal, value)) {
            helperTimeout = window.setTimeout(() => {
                setShowHelper((prev) => true)
            }, helperShowDelay);
        }

        if (autoConfirmTimeout !== null) {
            confirmTimeout = window.setTimeout(() => {
                originalEvent();
            }, autoConfirmTimeout)
        }

        return () => {
            window.clearTimeout(helperTimeout)
            window.clearTimeout(confirmTimeout)
            setShowHelper((prev) => false)
        }
    }, [newVal])


    const fakedOnChange = (e) => {
        // console.log('e', e.target.value)
        let val = '';

        if (e?.target?.value != null)
            val = e?.target?.value?.toString();
        else
            val = e?.toString();

        // console.log('val', val)
        if (inputProps?.pattern != null) {
            // val = val.replace('.', i18nLocales.options.interpolation.nestingOptionsSeparator)
            let reg = new RegExp(inputProps.pattern, 'ig')
            const validSimbolArr = `${val}`.match(reg)
            if (validSimbolArr?.length) {
                let nonValidSimbolsReg = new RegExp(`[^${validSimbolArr.join('')}]*`, 'ig')
                val = `${val}`.replace(nonValidSimbolsReg, '')
                // val = val.replace(i18nLocales.options.interpolation.nestingOptionsSeparator, '.')
            }
            // e.preventDefault()
        }

        if (val != null) {
            if (type === "number") val = val.replace(',', '.') //??? will need to be removed!
        }
        //  console.log('val*', val)

        setNewVal(val)
    }


    const isNumber = (val) => {
        return !!val && isFinite(val)
    }

    const isDate = (val) => {
        const date = Date.parse(val)
        return isValidDateFns(date)
    }

    const getType = (value) => {

        if (isNumber(value?.toString()))
            return 'number';

        if (isDate(value?.toString()))
            return 'date';

        if (value === null)
            return 'nullObject';

        if (value === undefined)
            return 'undefined';

        if (typeof (value) === 'boolean')
            return 'boolean'

        return 'string';
    }

    const changesDetector = (curVal = null, prevVal = null) => {
        if (props?.disabled)
            return false
        // console.log('new=', curVal, '; old=', prevVal)
        // console.log('parseFloat(prevVal), parseFloat(curVal)', parseFloat(prevVal), parseFloat(curVal))
        if (getType(curVal) == 'number')
            return (parseFloat(prevVal) !== parseFloat(curVal) && !isNaN(parseFloat(curVal)))

        if (getType(curVal) == 'date')
            return (Date.parse(curVal?.toString()) !== Date.parse(prevVal?.toString()))

        return curVal?.toString() !== prevVal?.toString()
    }

    const wheelListener = (e) => e.preventDefault()

    // withCustomizationInput.propTypes = {}
    // console.log('props.inputProps', props.inputProps)
    // console.log('refEl', refEl)
    let Child = <WrappedComponent {...otherProps}
        inputProps={inputProps}
        ref={refEl}
        // onClickCapture={(e) => { //autoSelect
        //     // console.log('props?.InputProps?.readOnly', props?.InputProps?.readOnly)
        //     if (props?.InputProps?.readOnly)
        //         return

        //     if (e.target?.setSelectionRange && selectOnFocus && e?.target?.select && !selected && !inEditMode) {
        //         let type = e?.target?.type
        //         e.target.type = 'text';
        //         e.target.focus();
        //         e.target?.setSelectionRange(0, e.target.value.length);
        //         e.target.type = type;
        //         setSelected(true)
        //     } else {
        //         if (e.target?.setSelectionRange && !inEditMode) {
        //             let el = document?.activeElement
        //             let type = e?.target?.type
        //             e.target.type = 'text';
        //             setInEditMode(true)
        //             e.target?.setSelectionRange(e.target?.value?.length, e.target?.value?.length);
        //             e.target.type = type;
        //         }
        //     }
        // }}

        onFocus={(e) => {
            if (props?.InputProps?.readOnly)
                return

            if (disableWheel) {
                e.target.removeEventListener("wheel", wheelListener, { passive: false })
                e.target.addEventListener("wheel", wheelListener, { passive: false })
            }

            onFocusOriginal(e)
        }}
    // ref={ref} 
    />

    if (needConfirm) {
        // console.log('inputProps', inputProps)
        Child = React.cloneElement(
            Child,
            {
                onChange: fakedOnChange,
                onKeyDown: inputDownEvents,
                onKeyUp: inputEvents,
                onBlur: originalEvent,
                value: newVal,
                // ref: ref
            }
        )
    }

    // if (changesHighlight !== null) {
    //     Child =
    //         <span className={changesDetector(newVal, oldVal.current) ? cls.changed : ''}>
    //             {Child}
    //         </span>
    // }

    // if (helper !== null) {
    //     // console.log('helper', Child)
    //     Child =
    //         <Tooltip title={helper} open={showHelper}
    //             placement="bottom"
    //             arrow
    //             // PopperProps={{ sx: { '& > div': { mt: '8px !important' } } }}
    //             disableInteractive
    //         >
    //             {/* <span> */}
    //             {Child}
    //             {/* </span> */}
    //         </Tooltip>
    // }
    // console.log('width', width)

    const focusAtInput = (e) => {
        // console.log('e.currentTarget', e.currentTarget)
        // console.log('e.target', e.target)
        // console.log('headerRef.current', headerRef.current)
        if (refEl?.current && !headerRef.current?.contains(e.target))
            refEl.current.focus()
    }

    return <Box className={
        [
            cls.optionParamItem,
            (changesHighlight !== null && changesDetector(newVal, oldVal.current)) ? cls.changed : '',
            classes?.FieldRoot
        ].join(' ')
    }
        onClick={focusAtInput}
    >
        {Boolean(header != null)
            && <Box /*onClick={(e) => { e.stopPropagation() }}*/
                ref={headerRef}
            >
                {tooltip != null ?
                    <CustomTooltip
                        content={tooltip}
                        hideIcon
                    >
                        <Box className={[classes?.FieldTitle, cls.optionParamItemName].join(' ')}>{header}</Box>
                    </CustomTooltip>
                    : header}
            </Box>}
        <Box className={[cls.optionParamItemInput, classes?.FieldInputRoot].join(' ')}
            style={{ width: width != null ? `${width}rem` : 'initial', overflow: 'hidden' }}
            onClick={focusAtInput}
        >
            {Child}
            {Boolean(unit != null) && <Box className={[cls.optionParamItemUnit, classes?.FieldUnit].join(' ')}>{unit}</Box>}
        </Box>
    </Box >
}
//)
