import React from 'react'
import '@Styles/components/Footer.scss'
import { Box, Container } from "@mui/material";
import { useTranslation } from 'react-i18next';
import style from '@Styles/domrf-theme.module.scss';


function Footer() {
    const { t } = useTranslation();
    return <Box sx={{ height: '0.5rem !important' }} ></Box>
    // ManageAccounts
    // Sos
    return (
        <Container maxWidth='xxl' sx={{ textAlign: 'right', whiteSpace: 'nowrap', '& *': { userSelect: 'none', color: `${style.colorPrimary3_60} !important` } }}>
            <span>
                <a className='pe-3' href='mailto:calculator.service@domrf.ru'>{t('Techsupport')}</a>
            </span>
            <span>
                {`© ДОМ.РФ, ${new Date().getFullYear()}`}
            </span>
        </Container>
    )
}

export default Footer