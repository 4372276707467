import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import style from '@Styles/domrf-theme.module.scss'
import { Autocomplete, Box, Paper, TextField, Theme, alpha, IconButton, Tooltip, Grid, Button, Dialog, Modal, debounce, createFilterOptions, MenuItem, Menu, Popper, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { createStyles, makeStyles, styled } from '@mui/styles'
import UniversalInputField, { InputDataType } from '@Base/framework/components/Form/UniversalInputField'
import { useDispatch } from 'react-redux'
import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { BondStatus, CalculationTypeV2, CouponType } from '@Base/components/Definitions'
import Localization from '@Base/components/Localization'
import { NumericValidator } from '@Base/framework/components/Form'
import { GitHub, Slideshow, InfoOutlined, OfflineShare, Replay, Update, Menu as MenuIcon, ContentPaste, GppMaybe, GppMaybeOutlined, NewReleases, ErrorOutline, Share } from '@mui/icons-material'
import { ReactComponent as ApiIcon } from "../../../../../src/images/api-icon.svg"
import CustomCircularProgress from '@Base/components/shared/CustomCircularProgress'
import { useSearchParams } from 'react-router-dom'
import SpinnerBars from '@Base/components/shared/SpinnerBars'
import { ReactComponent as ExcelIcon } from "../../../../../src/images/excel-icon.svg"
import { b64EncodeUnicode, copyTextToClipboard, roundDown } from '@Utilities';
import { withCustomizationInput } from '../hocs/withCustomizationInput'
import { actionCreators as actionAlert } from '@Base/store/AlertStore'
import CustomTooltip, { getTooltip } from '@Base/components/shared/CustomTooltip'
import useMobileDetect from '@Base/components/hook/useMobileDetect'
// import { actionCreators as actionDownloads } from '@Base/store/DownloadStore'
import { SignalRHubUrl, convention2ShowError, convention2governProgramsFractionLowerBorder } from '@Base/PlatformConfig'
import Convention2InfoWin from '../components/Convention2InfoWin'
import DownloaderAlert from '../../downloader/DownloaderAlert'
import useSignalR from '@Base/components/hook/useSignalR'
import { useNewDispatch, useNewSelector } from '@Base/index'
import { setConventionData, getDataForCalculation, getZCYCDateTime, clearValue, findBondsQuery, runCalculation, conventionItems, setModelKeyrate } from '@Base/newStore/conventionSlice'
import Presentation from '../components/Presentation'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect'


const Conv2Button = styled(Button)(({ theme }) => ({
    fontFamily: "DINPro-Bold",
    fontWeight: "700",
    letterSpacing: '1.5px',
    fontSize: '0.8rem !important',
    '& *': {
        fontSize: '0.8rem !important',
    },
    // color: style.colorPrimary3,
    padding: '0 25px 3px 25px',
    margin: '3px 0',
    backgroundColor: '#eef6e4',
    borderColor: 'rgb(61,149,109)',
    color: 'rgb(61,149,109)',
    dislay: 'flex',
    flexDirection: 'column',
    '& .comment': {
        fontSize: '0.6rem !important',
        fontFamily: 'DINPro !important',
        lineHeight: '0.8rem !important'
    },
    '&:hover': {
        borderColor: `${style.colorPrimary2} !important`,
        backgroundColor: '#eef6e4 !important',
        color: `rgb(0,176,80) !important`,
    }
}))

const useStyles = makeStyles((theme: Theme) => createStyles({
    newVersionBlock: {
        boxSizing: 'border-box',
        width: '100%',
        padding: '0 1rem 0.2rem 0',
        textAlign: 'right',
    },
    newVersion: {
        cursor: 'pointer',
        display: 'inline-flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: style.colorPrimary1,//'rgb(0,176,80)',
        fontSize: '0.9rem !important',
    },
    menuText: {
        // width: '130px',
    },
    submenuText: {
        paddingLeft: '1rem',
    },
    menuIcon: {

    },
    progressBase: {
        display: 'none'
    },
    progress: {
        animationDuration: '550ms',
    },
    progressValueRoot: {
        // background: '#FFF'
    },
    bgCover: {
        zIndex: '+1',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.5)',
    },
    spinnerBase: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    hidden: { display: 'none' },
    grayColor: { color: style.colorPrimary3_60 },
    redeemedBond: {
        color: style.colorPrimary3_40,
        '& $optionISIN, & optionLabel, & $optionName, & $optionStatus, & $optionCouponType': { color: style.colorPrimary3_40, }
    },
    limitationLiability: {
        marginTop: '5px',
        '& *': {
            width: '100%',
            textAlign: 'center',
            fontSize: '0.8rem !important',
            color: style.colorPrimary3_40
        }
    },
    nextCouponString: {
        whiteSpace: 'nowrap',
    },
    grayText: {
        color: style.colorPrimary3_60,
    },
    wideTooltip: {
        maxWidth: '500px !important'
    },
    customTooltip: {
        userSelect: 'auto'
    },
    couponTypeTooltip: {
        fontSize: '12.6px !important',
        color: style.colorPrimary3_60,
        '& *': {
            fontSize: '12.6px !important',
            color: style.colorPrimary3_60,
        }
    },
    couponTypeTooltipPopper: {
        maxWidth: '500px',
    },
    autoselectPopper: {
        marginRight: '-1px',

    },
    option: {
        '& *[data-focus="true"]': {
            border: 'solid 1px #000 !important',
            backgroundColor: "#363636",
        },
    },
    test: {
        border: 'solid 10px #000',
    },
    tooltip: {
        maxWidth: '600px',
        marginTop: '0.5rem !important',
    },
    paramsRoot: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        // overflow: 'hidden',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        // marginLeft: '6px',
        padding: '0 11px 0 Calc(11px + 5px)',
    },
    paramsRoot1: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        // marginTop: '1rem',
        // marginLeft: '6px',
    },
    block: {
        marginBottom: '0.5rem',
        '&>$resultRow:nth-of-type(even)': {
            backgroundColor: '#f7f7f7',//alpha(style.colorPrimary3, 0.05)
        },
    },
    blockTitle: {
        color: style.colorPrimary3_60,
        borderBottom: `solid 1px ${style.colorPrimary3_60}`,
        textTransform: 'uppercase',
    },
    resultRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '2rem',
        overflow: 'hidden',
    },
    slimLine: {
        minHeight: '18px',
    },
    lessFont: {
        '& *': {
            fontSize: '0.9rem !important',
        }
    },
    resultSubRows: {
        '&>$resultRow': {
            backgroundColor: '#FFF !important'
        }
    },
    resultRowName: {
        whiteSpace: 'nowrap',
        paddingLeft: '0.5rem',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        // alignSelf: 'baseline',
    },
    resultRowSubname: {
        fontSize: '0.8rem !important',
        color: style.colorPrimary3_60,
        marginTop: '-0.2rem',
        lineHeight: '1.2rem',
        // marginBottom: '0.3rem',
    },
    resultRowDataBlock: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center', //'flex-end',
        alignItems: 'baseline',
    },
    resultRowData: {
        // flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        alignItems: 'baseline',
        '& $exResultRowValue:not(:first-child)': {
            position: 'relative',
            '&:before': {
                position: 'absolute',
                left: '8px', top: '-2rem', bottom: '-2rem', width: '1px',
                content: '""',
                // backgroundColor: `${style.colorPrimary3_60}`,
                borderLeft: `solid 1px ${style.colorPrimary3_60}`,
                paddingLeft: '1rem',
            }
        },
    },
    resultRowValue: {
        minWidth: '4rem',
        textAlign: 'right',
        position: 'relative',
    },
    exResultRowValue: {
        minWidth: '4.7rem',
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
    },
    resultRowSubvalue: {
        fontSize: '0.8rem !important',
        color: style.colorPrimary3_60,
        marginTop: '-0.2rem',
        lineHeight: '1.2rem',
        // marginBottom: '0.3rem',
    },
    resultRowUnit: {
        width: '3.3rem',
        color: style.colorPrimary3_60,
        whiteSpace: 'nowrap',
        paddingLeft: '0.3rem',
        fontSize: '0.9rem !important',
        alignSelf: 'flex-start',//'baseline',
        // height: '1rem',
        lineHeight: '1rem',
        margin: '3px 0 2px 0'
    },
    resultRowUnitFit: {
        width: 'fit-content !important',
        paddingRight: '10px',
        position: 'relative',
        '&:after': {
            position: 'absolute',
            right: 0, top: '-2rem', bottom: '-2rem', width: '1px',
            content: '""',
            // backgroundColor: `${style.colorPrimary3_60}`,
            borderLeft: `solid 1px ${style.colorPrimary3_60}`,
            // paddingLeft: '1rem',
        },
    },
    optionParamItem: {
        margin: 0,
        // backgroundColor: '#00000009',
        padding: '0.2rem 1rem',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'start',
        alignItems: 'center',
    },
    optionParamItemInput: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'baseline',
        height: '30px',
        '-webkit-align-items': 'baseline',
        '& input': { fontSize: '1.3rem', padding: 0, textAlign: 'right', marginRight: '5px' },
        // '&:not(&optionParamItemUnit)': {
        //     // flexGrow: 1,
        //     // width: '4.4rem',
        // },
    },
    optionParamItemUnit: {
        // maxWidth: '2rem !important',
        alignSelf: 'baseline',
        color: style.colorPrimary3_40,
        fontSize: '0.9rem !important',
        whiteSpace: 'nowrap',
        textWrap: 'nowrap',
        userSelect: 'none',
        // lineHeight: '1.3rem',
        height: '30px',
    },
    optionParams: {
        width: '100%',
        margin: 0,
        // marginTop: '0.5rem',
        // marginRight: '4px',
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
    },
    singleParam: {
        width: 'fit-content',
        minWidth: '48.5%'
    },
    optionParamsGrid: {
        '&>*:not(:first-child)': {
            borderLeft: `solid 1px ${style.colorPrimary3_40}`,
            margin: 0,
        },
    },
    optionExParams: {
        position: 'relative',
    },
    optionExParamsBox: {
        position: 'relative',
        // marginTop: '0.5rem',
        // marginRight: '4px',
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        '&>*': {
            minWidth: '8rem',
        },
        '&>*:not(:first-child)': {
            borderLeft: `solid 1px ${style.colorPrimary3_40}`
        },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        width: 'min-content',
        alignSelf: 'center',
    },
    optionParamItemName: {
        color: `${style.colorPrimary3_60} !important`,
        // fontSize: '0.9rem !important',
        whiteSpace: 'nowrap',
        userSelect: 'none'
    },
    labelStyle: {
        color: `${style.colorPrimary3_40} !important`,
        userSelect: 'none',
    },
    // mobileRoot: {
    //     // marginRight: '0.5rem',
    //     position: 'relative',
    //     marginLeft: '0.2rem',
    //     marginRight: '0',
    //     // position: 'absolute',
    //     // left: 0, top: 0, bottom: '2px', right: 0,
    //     '& *': {
    //         fontSize: '1rem'
    //     }
    // },
    root: {
        // marginRight: '0.5rem',
        // position: 'relative',
        // minWidth: '390px',
        // [theme.breakpoints.down(1400)]: {
        //     maxHeight: 'initial',
        // },
        // [theme.breakpoints.up(1400)]: {
        //     height: '100%'
        //     // maxHeight: '770px',
        // },
        // border: 'solid 1px',

        // left: '0.2rem', top: 0, bottom: '2px', right: '0.2rem',
        // maxHeight: '809px',
        // overflow: 'hidden',
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        '& *': {
            fontSize: '1rem'
        },
    },
    rootPaper: {
        maxHeight: 'Calc( 100% - 2px )',
        position: 'relative',
        // overflow: 'hidden',
        // position: 'absolute',
        // left: 0, top: 0, bottom: 0, right: 0,
        // paddingBottom: '1px',
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    '@media (max-height: 825px)': {
        //     //code here
        root: {
            position: 'relative',
            // marginLeft: '0.2rem',
            // marginRight: '0.2rem',
            paddingRight: '0',
            // left: 0, top: 0, bottom: 0, right: 0,
        },
        // rootPaper: {
        //     position: 'relative'
        // }
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'hidden',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 .5rem',
        justifyContent: 'space-between',
        // lineHeight: '2rem',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    panelHeaderIcons: {
        marginRight: '0.1rem',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    panelHeaderIcon: {
        width: '23px',
        height: '23px',
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80
    },
    unitButton: {
        // padding: 0,
        // marginBottom: '2px',
        margin: '1px 0 2px 0',
        padding: 0
    },
    unitIcon: {
        padding: 0,
        width: '18px',
        height: '18px',
        // color: 'rgb(0, 0, 0, 0.54)'
    },
    smallIconButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: '15px',
        height: '15px',
    },
    smallIcon: {
        width: '15px',
        height: '15px',
        color: style.colorPrimary3_80
    },
    panelData: {
        // margin: '0 1rem 0.5rem 1rem',
        margin: '0 0 0 0',
        paddingTop: '0.5rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    rowInputParams: {
        margin: '0 1rem',
        paddingBottom: '12px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&>*:not(:first-child)': {
            marginLeft: '0.5rem'
        }
        // minHeight: '2rem',
    },
    colInputParams: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    row: {
        margin: '0 1rem',
        paddingBottom: '12px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        // minHeight: '2rem',
    },
    bondInputRow: {
        paddingBottom: '0px',
    },
    bondInfoRow: {
        margin: '0 1rem',
        paddingBottom: '12px',
        // justifyContent: 'left'
    },
    requiredParametersRowMessage: {
        color: style.colorPrimary3,
        fontSize: '12.6px',
        margin: '-6px 0 0 0',
        paddingBottom: '4px',
        '& *': {
            color: style.colorPrimary3,
            fontSize: '12.6px',
        }
    },

    extandesRow: {
        marginLeft: '1rem',
    },
    rowName: {
        flexGrow: 1,
    },
    fw: {
        width: '100% !important',
    },
    rowData: {
        width: '200px',
        textAlign: 'right',
    },
    alertBox: {
        display: 'flex',
        placeContent: 'center',
        color: 'red',
        margin: '0.5rem 1rem',
    },
    optionBox: {
        display: 'flex !important',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between  !important',
        alignContent: 'center  !important',
        alignItems: 'center  !important',
        minWidth: '250px !important',
        padding: '5px 0',
        cursor: 'pointer !important',
        // '&:hover': {
        //     backgroundColor: alpha(style.colorPrimary3, 0.1)
        // }
    },
    optionMobile: {
        '& $optionLabel': {
            fontSize: '0.9rem !important',
            // width: 'fit-content',
        },
        '& $optionISIN': {
            fontSize: '0.9rem !important',
            // width: 'fit-content',
        },
    },
    optionName: {
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        margin: '0 !important',
        padding: '0 !important',
        // justifyContent: 'space-between',
        // width: '220px',
    },
    optionLabel: {
        textAlign: 'left',
        marginRight: '0.5rem',
        // width: '100px',
        width: 'fit-content',
        // border: 'solid 1px #000'
    },
    optionISIN: {
        // width: '120px',
        width: 'fit-content',
        color: style.colorPrimary3_40,
        textAlign: 'left',
        // border: 'solid 1px #000'
    },
    optionStatusBox: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
        marginLeft: '0.5rem'
    },
    optionStatus: {
        fontSize: '11px',
        whiteSpace: 'nowrap',
    },
    optionCouponType: {
        fontSize: '12px',
        whiteSpace: 'nowrap',
        color: 'rgb(0,176,80)', //style.colorPrimary2_80,
        fontSmooth: 'never'
    },
    divider: {
        position: 'relative',
        // marginTop:'0.5rem',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: '-2rem', right: '-2rem', top: 0, height: '1px',
            borderTop: `solid 1px ${alpha(style.colorPrimary3_40, 1)}`,
        },
    },
    virtualScrollablePanel: {
        padding: '0.5rem 11px 0 Calc(11px + 5px)',
        flexGrow: 1,
        overflowY: 'scroll',
        overflowX: 'none',
        // marginTop: '0.5rem',
        '& *': { userSelect: 'none', },
        '& .Mui-selected': {
            // backgroundColor: `#e4f1d5 !important`,
            backgroundColor: `#e4f1d500 !important`,
        },
        // position: 'absolute', left: '4px', right: 0, top: 0, bottom: 0,
        // overflowY: 'auto',
        // padding: 0,
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '6px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            // '&::-webkit-scrollbar-track': {
            //     backgroundColor: '#00000008',
            // },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px',
        },
    },
    virtualVisibleScrollablePanel: {
        padding: '0.5rem 11px 0 Calc(11px + 5px)',
        flexGrow: 1,
        overflowY: 'scroll',
        overflowX: 'none',
        // marginTop: '0.5rem',
        '& *': { userSelect: 'none', },
        '& .Mui-selected': {
            // backgroundColor: `#e4f1d5 !important`,
            backgroundColor: `#e4f1d500 !important`,
        },
        // position: 'absolute', left: '4px', right: 0, top: 0, bottom: 0,
        // overflowY: 'auto',
        // padding: 0,
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '6px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // // },
        // '&:hover': {
        //     '&::-webkit-scrollbar-track:disabled': {
        //         backgroundColor: '#0000',
        //     },
        //     '&::-webkit-scrollbar-thumb:disabled': {
        //         background: '#0000',
        //     },
        //     // '&::-webkit-scrollbar-track': {
        //     //     backgroundColor: '#00000008',
        //     // },
        //     '&::-webkit-scrollbar-thumb': {
        //         background: '#00000018',
        //     }
        // },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#00000018',
            borderRadius: '3px',
            height: '4px',
        },
    },
    scrollableList: {
        padding: '0 !important',
    },
}))

const InputPanel = ({ demo = false }) => {
    const [menuEl, setMenuEl] = useState(null);
    const open = Boolean(menuEl);
    const handleMenuClick = (event) => {
        setMenuEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuEl(null);
    };
    const { isMobile, isLandscape } = useMobileDetect()
    const cls = useStyles()
    const dispatch = useDispatch()
    const newDispatch = useNewDispatch()
    const [calculationPercents, setCalculationPercents] = useState(null)
    const [showConventionPresentation, setShowConventionPresentation] = useState(false)
    useLayoutEffect(() => {
        if (demo)
            setShowConventionPresentation(true)
    }, [demo])

    const {
        selectedBond = null,
        items: storeBondList = null,
        bondListLoading = false,
        pricingParameters = null, loading = false, pricingResult = null, error: apiError = null,
        calculationParameters = null,
        poolStatistics = null,
        useModelKeyrate = false, modelKeyrate = null, keyRateInteractiveGraph = null,
        bondParameters = null, poolInfo = null,
        zcycDate = null,
        ...otherNewStore } = useNewSelector( /*conventionItems */state => state.newConvention)
    // console.log('pricingParameters', pricingParameters)
    const [runCalc, setRunCalc] = useState(false)

    const convention2CalculationNotification = useCallback((message: any, ...otherProps: any[]) => {
        // console.log('convention2CalculationNotification', message?.percents)
        if (message?.percents !== null && loading) {
            if (runCalc) {
                // console.log('reset runCalc var to false')
                setRunCalc(false) // disable Fake Percentage
            }
            setCalculationPercents(message.percents)
            // console.log('update calculationPercents from HUB message')
        }
    }, [loading])

    const [calculationSpeed, setCalculationSpeed] = useState(1000)

    useLayoutEffect(() => {
        if (runCalc && (calculationPercents ?? 0) < 100)
            setTimeout(() => {
                // console.log('update calculationPercents FAKED, prevstate =', calculationPercents)
                setCalculationPercents(x => (x ?? 0) + 1)
            }, calculationSpeed)
    }, [runCalc, calculationPercents])


    const callbackRef = useRef({ convention2CalculationNotification: convention2CalculationNotification })

    useEffect(() => {
        callbackRef.current = {
            convention2CalculationNotification: convention2CalculationNotification
        }
    }, [loading])

    const signalR = null
    // const signalR = useSignalR(SignalRHubUrl, {
    //     convention2CalculationNotification: (message) => callbackRef.current?.convention2CalculationNotification(message)
    // })

    const [urlStruct, setUrlStruct] = useState(null)
    const [selectedBondId, setSelectedBondId] = useState(null)
    const [isBuildFromURL, setIsBuildFromURL] = useState(false)
    const inputRefsArr = useRef([])
    const inputRef = useRef(null)
    const [inputPricingParameters, setInputPricingParameters] = useState(null)
    const [selectedBondType, setSelectedBondType] = useState(null)
    const [bondList, setBondList] = useState([])
    const [calcType, setCalcType] = useState(null)
    const [calcValue, setCalcValue] = useState(null)
    const [calcTypeItems, setCalcTypeItems] = useState(null)
    let [searchParams, setSearchParams] = useSearchParams()
    const [paramsWasChangedManualy, setParamsWasChangedManualy] = useState(false)
    const [showConvention2InfoWin, setShowConvention2InfoWin] = useState(false)
    const availableOptions = {
        zSpread: { title: t('convention2.zSpread'), unit: 'Convention2_bp', tooltip: "Convention2Tooltip_zSpreadParam", width: 4.8, decimalScale: 0, limits: { min: -300, max: 500 } },
        gSpread: { title: t('convention2.gSpread'), unit: 'Convention2_bp', tooltip: "Convention2Tooltip_gSpreadParam", width: 4.8, decimalScale: 0, limits: { min: -300, max: 500 } },
        requiredKeyRatePremium: { title: t('convention2.requiredKeyRatePremium'), unit: 'Convention2_bp', tooltip: "Convention2Tooltip_requiredKeyRatePremiumParam", width: 4.8, decimalScale: 0, limits: { min: -300, max: 500 } },
        fixedKeyRatePremium: { title: t('convention2.fixedKeyRatePremium'), unit: 'Convention2_bp', tooltip: "Convention2Tooltip_fixedKeyRatePremium", width: 4.8, decimalScale: 0, limits: { min: 0, max: 300 } },
        fixedCouponRate: { title: t('convention2.fixedCouponRate'), unit: 'convention2.percentPerYear', tooltip: "Convention2Tooltip_fixedCouponRate", width: 5.1, decimalScale: 2, limits: { min: 0, max: 20 } },
        cleanPrice: { title: t('convention2.cleanPrice'), unit: 'Convention2_percent', tooltip: "Convention2Tooltip_cleanPriceParam", width: 5.1, decimalScale: 2, limits: { min: 0, max: 150 }, allowNegative: false, },
        dirtyPrice: { title: t('convention2.dirtyPrice'), unit: 'Convention2_percent', tooltip: "Convention2Tooltip_dirtyPriceParam", width: 5.1, decimalScale: 2, limits: { min: 0, max: 150 }, allowNegative: false, },
        couponRate: { title: t('convention2.couponRate'), disabled: false, unit: 'Convention2_percent', tooltip: "Convention2Tooltip_couponRateParam", width: 5.1, decimalScale: 2, limits: { min: 0, max: 20 }, allowNegative: false, },
    }
    const [requiredOptions, setRequiredOptions] = useState([])
    const [viewHeight, setViewHeight] = useState(0)

    const addToRefs = useCallback((el, index) => {
        if (!el || inputRefsArr.current.includes(el)) return
        inputRefsArr.current.splice(index, 0, el)
    }, [])

    const focusNextRef = useCallback((el = null) => {
        // console.log('el', el)
        // console.log('inputRefsArr.current', inputRefsArr.current)
        if (!inputRefsArr.current.length) return

        if (el?.index != null && inputRef.current != null && el?.index < inputRefsArr.current?.length - 1)
            inputRef.current = inputRefsArr.current[el.index + 1]
        else {
            inputRef.current = inputRefsArr.current[0]
        }

        inputRef.current?.focus()

    }, [requiredOptions])

    const CustomNumericValidator = useMemo(() => withCustomizationInput(NumericValidator,
        {
            needConfirm: true,
            helper: Localization.Translate('PressEnterToLaunchCalculation'),
            helperShowDelay: 3000,
            //autoConfirmTimeout: 1000,
            // helper: t('PressEnterToSubmit'),
            // helperShowDelay: 1000,
            changesHighlight: alpha(style.colorPrimary2, 0.2),
            disableWheel: true,
            selectOnFocus: true,
            disableCloseOnSelect: false,
            hideValidationIcon: true,
            placeholder: ' ',
            // autoComplete: "off",
            // inputProps: {
            //     autoComplete: "off",
            //     //     // disableUnderline: true,
            // },
            classes: { FieldRoot: cls.optionParamItem, FieldInputRoot: cls.optionParamItemInput, FieldUnit: cls.optionParamItemUnit, FieldTitle: cls.optionParamItemName }
        })
        , [calcValue, calcType])

    useLayoutEffect(() => { //resize Listener
        setIsBuildFromURL(false)
        newDispatch(clearValue('pricingResult'))
        newDispatch(clearValue('calculationParameters'))
        newDispatch(clearValue('poolStatistics'))
        newDispatch(clearValue('pricingParameters'))
        newDispatch(clearValue('mbsCashflowGraph'))
        newDispatch(clearValue('sCurveGraph'))
        newDispatch(clearValue('Error'))
        newDispatch(clearValue('moexSecurities'))
        newDispatch(clearValue('modelKeyrate'))        
        newDispatch(clearValue('useModelKeyrate'))    

        setViewHeight(window?.innerHeight)
        if (window) {
            window.addEventListener('resize', debounce(onResize, 300))
            onResize();
        }

        if (bondType?.length)
            setSelectedBondType(bondType[0])  //по умолчанию отороажение тикера

        newDispatch(findBondsQuery({ query: null, showError: convention2ShowError }))

        return () => {
            window.removeEventListener('resize', debounce(onResize, 300))
            setIsBuildFromURL(false)
            newDispatch(clearValue('pricingResult'))
            newDispatch(clearValue('calculationParameters'))
            newDispatch(clearValue('poolStatistics'))
            newDispatch(clearValue('pricingParameters'))
            newDispatch(clearValue('mbsCashflowGraph'))
            newDispatch(clearValue('sCurveGraph'))
            newDispatch(clearValue('Error'))
            newDispatch(clearValue('moexSecurities'))
            newDispatch(clearValue('modelKeyrate'))        
            newDispatch(clearValue('useModelKeyrate'))    
        }
    }, [])

    const onResize = () => {
        setViewHeight(window?.innerHeight)
    }

    useLayoutEffect(() => { // получены pricingParameters
        if (pricingParameters == null) {
            setInputPricingParameters(null)
            return
        }

        setCalculationPercents(null)
        setRunCalc(false)

        const filtredParams = {}
        Object.keys(pricingParameters).forEach(key => {
            switch (key) {
                // case 'isin':
                case 'bondID':
                case 'dirtyPrice':
                case 'cleanPrice':
                case 'zSpread':
                case 'gSpread':
                case 'requiredKeyRatePremium':
                case 'fixedKeyRatePremium':
                case 'fixedCouponRate':
                case 'couponRate':
                case 'cpr':
                case 'cdr':
                    filtredParams[key] = pricingParameters[key]
                    break
                default:
                    break
            }
        })

        setInputPricingParameters(filtredParams) //установка объекта инпутов со значениями
    }, [pricingParameters])

    // useLayoutEffect(() => {
    //     newDispatch(clearValue('selectedBond'))
    //     setRequiredOptions([])
    // }, [selectedBondId])

    useLayoutEffect(() => { // смена выбора бонда
        // if (pricingResult || paramsWasChangedManualy) {
        //     setSearchParams({}) // очищаем УРЛ
        // }

        // newDispatch(clearValue('pricingResult'))
        // newDispatch(clearValue('poolInfo'))
        // newDispatch(clearValue('poolStatistics'))
        // newDispatch(setConventionData({ useModelKeyrate: false }))
        // newDispatch(clearValue('modelKeyrate'))
        // newDispatch(clearValue('cprGraph'))
        // newDispatch(clearValue('error'))
        // newDispatch(clearValue('pricingParameters'))
        // newDispatch(clearValue('calculationParameters'))
        // newDispatch(setConventionData({ showHistoryFlows: false }))
        // newDispatch(clearValue('mbsCashflowTable'))
        // newDispatch(clearValue('mbsCashflowGraph'))
        // newDispatch(clearValue('zcycGraph'))
        // newDispatch(clearValue('sCurveGraph'))
        // newDispatch(clearValue('Error'))
        // newDispatch(clearValue('moexSecurities'))

        // setRequiredOptions([])

        if (selectedBond?.isin || selectedBond?.regNumber) {
            const bondID = selectedBond?.isin || selectedBond?.regNumber
            newDispatch(getDataForCalculation({ bondID: bondID, showError: convention2ShowError }))
            inputRefsArr.current = []
        }
    }, [selectedBond])

    useLayoutEffect(() => {
        if (!Object.keys(urlStruct ?? {}).length > 1 || !poolInfo?.bondID || !requiredOptions || !selectedBond) return

        let bondParam = null
        bondList.forEach(bond => {
            if (bond?.isin == poolInfo.bondID || bond?.regNumber == poolInfo.bondID)
                bondParam = bond
        })
        // console.log('urlStruct', urlStruct)
        selectRequiredParams(bondParam, urlStruct)

        const forecastTime = (poolInfo?.debt ?? 0) / 1000000000 * 0.42
        const delay = (forecastTime > 10 ? forecastTime : 10) / 100 * 1000
        // console.log('delay', delay)
        setCalculationSpeed(delay)

        if (!loading && !pricingParameters && !isBuildFromURL)
            setTimeout(() => {
                focusNextRef()
            }, 0)
    }, [poolInfo])
    // console.log('pricingParameters', pricingParameters)

    // useLayoutEffect(() => {
    //     console.log('useModelKeyrate', useModelKeyrate)
    //     if (keyRateInteractiveGraph?.model != null && useModelKeyrate == true) {
    //         // console.log('keyRateInteractiveGraph', keyRateInteractiveGraph)
    //         //    newDispatch(setConventionData({ useModelKeyrate: true, modelKeyrate: keyRateInteractiveGraph?.model }))
    //     }
    // }, [keyRateInteractiveGraph])

    useLayoutEffect(() => {
        if (urlStruct == null)
            return
        // console.log('isBuildFromURL', isBuildFromURL, urlStruct)

        if (!isBuildFromURL)
            setSearchParams(urlStruct ?? {})

        // console.log('checkForCalculationConditions()', checkForCalculationConditions())
        // console.log('useModelKeyrate', useModelKeyrate)
        // console.log('modelKeyrate', modelKeyrate)
        if (checkForCalculationConditions()) {
            if (signalR?.connection?.connectionId) {
                urlStruct['connectionId'] = signalR?.connection?.connectionId
            }

            setCalculationPercents(null)
            setRunCalc(true)
            const calcStruct = structuredClone(urlStruct)

            if (calcStruct?.kr) {
                // console.log('calcStruct?.kr', calcStruct?.kr)
                calcStruct.keyRateForecast = calcStruct?.kr.map(el => { return ({ date: el[0], rate: el[1] }) })
                delete (calcStruct?.kr)
            }
            // console.log('runCalc', calcStruct)
            newDispatch(runCalculation({ params: calcStruct, showError: convention2ShowError }))
            setIsBuildFromURL(false)
        }
    }, [urlStruct])

    useLayoutEffect(() => {
        // console.log('useModelKeyrate!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', useModelKeyrate)
        if (requiredOptions?.length && poolInfo != null) {
            const oldUrlStruct = structuredClone(urlStruct)
            requiredOptions.forEach(block => {
                const options = block?.options
                Object.keys(options).forEach(key => {
                    if (options[key]?.value != null && options[key]?.value != '' && options[key]?.value != '-')
                        oldUrlStruct[key] = options[key]?.value
                    else
                        if (oldUrlStruct.hasOwnProperty(key))
                            delete (oldUrlStruct[key])
                })
            })

            const newUrlStruct = {
                ...oldUrlStruct,
                ...selectedBond && { bondID: selectedBond?.isin || selectedBond?.regNumber },
                // ...getOptionsData(),
            }
            if (!useModelKeyrate && !isBuildFromURL)
                delete (newUrlStruct?.kr)

            if (useModelKeyrate && modelKeyrate?.length) {
                newUrlStruct.kr = modelKeyrate?.map(el => { return [el.start, el.value] })
                // data = { ...data, ...enhancedParams, ...enhancedParams?.kr && { enhancedParams.kr } }
            }

            // console.log('setUrlStruct(newUrlStruct)', newUrlStruct)
            setUrlStruct(newUrlStruct)
        }
    }, [selectedBond, poolInfo, requiredOptions, modelKeyrate/*, useModelKeyrate*/])

    const parseBool = (params) => {
        return !(
            params === false ||
            params === "false" ||
            params === "0" ||
            params === "" ||
            params === undefined
        )
    }

    const urlParamRemove = (name) => {
        if (urlStruct.hasOwnProperty(name)) {
            const newUrlStruct = structuredClone(urlStruct)
            delete newUrlStruct[name]
            setUrlStruct(newUrlStruct)
        }
    }

    const urlToVar = () => {
        let query = {}

        searchParams?.forEach((val, key) => {
            switch (key) {
                // case 'bondID':
                //     // console.log('setSelectedBondId(val)', val)
                //     setSelectedBondId(val)
                //     // bondList.map(bond => {
                //     //     if (val != null && (bond?.isin == val || bond?.regNumber == val))
                //     //         bondParam = bond
                //     // })
                //     break
                // case 'cleanPrice':
                // case 'dirtyPrice':
                // case 'zSpread':
                // case 'gSpread':
                // case 'requiredKeyRatePremium':
                // case 'fixedKeyRatePremium':
                // case 'fixedCouponRate':
                // case 'couponRate':
                //     query[key] = val
                //     break
                case 'kr':
                    const r = `${val}`.split(',')
                    if (query[key] == null)
                        query[key] = [r]
                    else
                        query[key].push(r)
                    break
                case 'usePricingDateDataOnly':
                    query[key] = parseBool(val)
                    break
                default:
                    query[key] = val

            }
        })
        return query
    }

    const parseUrl = () => {
        // console.log('parseURL')
        try {
            const query = urlToVar()
            // console.log('query-----------------------------------------------', query.kr)
            if (query.bondID)
                setSelectedBondId(query.bondID)
            if (query?.kr){
                const res = query.kr?.map(el=>({start:el[0],value:el[1]}))
                // [
                //     {
                //         "start": "2024-09-19T00:00:00",
                //         "end": "2025-01-01T00:00:00",
                //         "value": 20
                //     },
                // ]
                // const res = barsSeries?.data?.map((el, index) => {
                //     return ({ ...keyRateInteractiveGraph?.model[index], value: el?.y })
                // }) 
        
                // // actionConvention.setModelKeyrate(res)(dispatch)
                newDispatch(setModelKeyrate(res))
                newDispatch(setConventionData({ useModelKeyrate: true }))
            }
            setIsBuildFromURL(true)
            setUrlStruct(query)

        } catch (error) {
            console.error(error.message)
        }
    }

    useLayoutEffect(() => {

        if (urlStruct != null) return

        parseUrl()
    }, [searchParams /*, signalR?.connection?.connectionId*/])

    useEffect(() => {//заполняем bondList
        try {
            if (storeBondList != null && selectedBondType?.field != null) {
                setBondList(storeBondList
                    ?.filter(bond => bond.bondStatus != BondStatus.STATUS_NOT_ISSUED
                        && !(bond.bondStatus == BondStatus.STATUS_REDEEMED) //исключаем погашенные
                    )
                    ?.map(bond => {
                        const value = {
                            label: bond[selectedBondType?.field],
                            ...bond
                        }
                        return value
                    }))
            }
        } catch (error) {
            console.error(error.message)
        }
    }, [storeBondList, selectedBondType])

    const bondType = [
        { id: 3, name: t('TickerMBS'), source: '', field: 'ticker', },
        { id: 1, name: t('ISINMBS'), source: '', field: 'isin', },
        { id: 2, name: t('RegistrationNumberMBS'), source: '', field: 'regNumber', },
        // {key: 4, value: t('BloombergTicker')},
        { id: 5, name: t('NameMBS'), source: '', field: 'name', },
    ]

    const getBondStatus = (bond) => {
        // console.log('bond', bond.bondStatus)
        let title = ''
        let date = bond?.issueDate
        switch (bond.bondStatus) {
            case BondStatus.STATUS_NOT_ISSUED:
                title = "Convention2_BondNotIssued"
                break
            case BondStatus.STATUS_PLACEMENT:
                title = "Convention2_BondPlacement"
                break
            case BondStatus.STATUS_ISSUED:
                title = "Convention2_BondIssued"
                break
            case BondStatus.STATUS_REDEEMED:
                title = "Convention2_BondRedeemed"
                date = bond?.actualRedemptionDate
                break
        }

        return `${t(title)} ${Localization.FormatDateStringLocale(date)}`
    }

    const getBondCoupon = (couponType) => {
        switch (couponType) {
            case CouponType.TYPE_FIXED:
                return t("Convention2_CouponTypeFixed")

            case CouponType.TYPE_FLOAT:
                return t("Convention2_CouponTypeFloating")

            case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                return t("Convention2_CouponTypeStrKeyrateAndPremium")
        }
        return ''
    }

    useEffect(() => {
        // console.log('selectedBondId, bondList', selectedBondId, bondList)
        if (selectedBondId == null || bondList == null) {
            return
        }

        if (!isBuildFromURL) {
            // console.log('clear urlStruct')
            setUrlStruct({})
        }

        bondList.map(bond => {
            if (selectedBondId != null && (bond?.isin == selectedBondId || bond?.regNumber == selectedBondId))
                newDispatch(setConventionData({ selectedBond: bond }))
        })

    }, [selectedBondId, bondList])

    const handleSelectBond = (bond) => {
        // console.log('selectBond', bond)
        setIsBuildFromURL(false)
        selectRequiredParams(bond)
        newDispatch(clearValue('pricingResult'))
        newDispatch(clearValue('poolInfo'))
        newDispatch(clearValue('poolStatistics'))
        // newDispatch(setConventionData({ useModelKeyrate: false }))
        newDispatch(clearValue('modelKeyrate'))
        newDispatch(clearValue('cprGraph'))
        newDispatch(clearValue('error'))
        newDispatch(clearValue('pricingParameters'))
        newDispatch(clearValue('calculationParameters'))
        newDispatch(setConventionData({ showHistoryFlows: false }))
        newDispatch(clearValue('mbsCashflowTable'))
        newDispatch(clearValue('mbsCashflowGraph'))
        newDispatch(clearValue('zcycGraph'))
        newDispatch(clearValue('sCurveGraph'))
        newDispatch(clearValue('Error'))
        newDispatch(clearValue('moexSecurities'))

        // console.log('isBuildFromURL', isBuildFromURL)
        setSelectedBondId(bond?.isin || bond?.regNumber)
    }

    const selectRequiredParams = (bond, params = null) => {
        // console.log('bond, params', bond, params)
        let newRequiredOptions = []

        if (!bond || !poolInfo) {
            setUrlStruct({})
            setRequiredOptions(newRequiredOptions)
            return
        }

        // console.log('bond', bond)
        switch (bond?.couponType) {
            case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                if (bond?.bondStatus == BondStatus.STATUS_PLACEMENT) {
                    newRequiredOptions.push({
                        options: {
                            fixedKeyRatePremium: {
                                ...availableOptions.fixedKeyRatePremium, default: true,
                                ... (selectedBond?.lockPlacementCoupon == true) && { disabled: true, value: (bond?.couponPercentage ?? 0) * 100 }
                            },
                        }
                    })
                }
                else {
                    newRequiredOptions.push({
                        options: {
                            requiredKeyRatePremium: { ...availableOptions.requiredKeyRatePremium, default: true },
                            cleanPrice: { ...availableOptions.cleanPrice, tooltip: "Convention2Tooltip_cleanPriceParamKaP" },
                        }
                    })
                }
                break
            case CouponType.TYPE_FIXED:
                if (bond?.bondStatus == BondStatus.STATUS_PLACEMENT) {
                    newRequiredOptions.push({
                        options: {
                            fixedCouponRate: {
                                ...availableOptions.fixedCouponRate, default: true,
                                ... (selectedBond?.lockPlacementCoupon == true) && { disabled: true, value: (bond?.couponPercentage ?? 0) }
                            },
                        }
                    })
                }
                else {
                    newRequiredOptions.push({
                        // message: 'some message',
                        options: {
                            zSpread: { ...availableOptions.zSpread, default: true },
                            gSpread: { ...availableOptions.gSpread },
                            ...(bond.bondStatus == BondStatus.STATUS_PLACEMENT) ? {
                                couponRate: { ...availableOptions.couponRate, /*disabled: selectedBond?.lockPlacementCoupon == true*/ }
                            } :
                                { cleanPrice: { ...availableOptions.cleanPrice, tooltip: "Convention2Tooltip_cleanPriceParam" } }
                        }
                    })
                }
                break
            case CouponType.TYPE_FLOAT:
                if (poolInfo?.governProgramsFraction >= 100 - convention2governProgramsFractionLowerBorder) // 0.95
                    newRequiredOptions.push({
                        options: {
                            requiredKeyRatePremium: { ...availableOptions.requiredKeyRatePremium, default: true },
                            cleanPrice: {
                                ...availableOptions.cleanPrice, tooltip: "Convention2Tooltip_cleanPriceParamKaP",
                                ...(bond?.bondStatus == BondStatus.STATUS_PLACEMENT) && { title: t('convention2.placementPrice'), tooltip: 'convention2.placementPriceParamTooltip', }
                            },
                        }
                    })

                else if (poolInfo?.governProgramsFraction <= convention2governProgramsFractionLowerBorder)
                    newRequiredOptions.push({
                        options: {
                            zSpread: { ...availableOptions.zSpread, default: true },
                            gSpread: { ...availableOptions.gSpread },
                            cleanPrice: {
                                ...availableOptions.cleanPrice, tooltip: "Convention2Tooltip_cleanPriceParam",
                                ...(bond?.bondStatus == BondStatus.STATUS_PLACEMENT) && { title: t('convention2.placementPrice'), tooltip: 'convention2.placementPriceParamTooltip', }
                            }
                        }
                    })

                else {
                    newRequiredOptions.push({
                        message: t('convention2.forLoasWOGov'),
                        options: {
                            zSpread: { ...availableOptions.zSpread, default: true },
                        }
                    })


                    newRequiredOptions.push({
                        message: t('convention2.forLoasWGov'),
                        options: {
                            requiredKeyRatePremium: { ...availableOptions.requiredKeyRatePremium, default: true },
                        }
                    })
                }
                break
        }

        // console.log('newRequiredOptions', newRequiredOptions)
        if (params != null) {
            // console.log('params', params)
            newRequiredOptions.forEach(row => {
                Object.keys(row?.options).forEach(key => {
                    if (params[key] != null)
                        row.options[key].value = params[key]
                })
            })
        }
        // console.log('newRequiredOptions', newRequiredOptions)
        setRequiredOptions(newRequiredOptions)
    }

    const getDate = (row) => {
        try {
            if (row?.source == null)
                return

            return Localization.FormatDateStringLocale(row?.source[row?.field])

        } catch (error) {
            console.error(error.message)
        }
    }

    const getCoverageType = (row) => {
        try {
            if (row?.source == null)
                return

            switch (row?.source[row?.field]) {
                case 1:
                    return t('convention2.standard')
                case 2:
                    return t('convention2.subsidized')
                case 3:
                    return t('convention2.mixed')
            }

        } catch (error) {
            console.error(error.message)
        }
    }

    const getCouponType = (row) => {
        try {
            if (row?.source == null)
                return

            switch (row?.source[row?.field]) {
                case 1:
                    return t('Convention2_fix')
                case 2:
                    return t('Convention2_float')
                case 3:
                    return t('Convention2_kp')
            }

        } catch (error) {
            console.error(error.message)
        }
    }

    const getCouponPeriodValue = (row) => {
        try {
            if (row?.source == null || row?.source[row?.field] == 0)
                return

            return 12 / row?.source[row?.field]

        } catch (error) {
            console.error(error.message)
        }
    }

    const valueGetter = (row) => {
        try {
            if (row?.source == null) //inputPricingParameters
                return

            let val = null

            val = searchParams.get(row?.field)
            val = (val !== null && loading) ? val :
                row.source[row?.field] != null ? row.source[row?.field] : null

            switch (row?.dataType) {
                // case 'usePricingDateDataOnly':
                case InputDataType.BOOLEAN:
                    val = parseBool(val)
                    break

                case InputDataType.DATE:
                case InputDataType.DATE_TIME:
                case InputDataType.DATE_T:
                case InputDataType.DATE_TIME_UTC:
                    val = new Date(val)
                    break

                case InputDataType.FLOAT:
                    if (row.fractionLength != null && val != null)
                        val = Localization.FormatNumberString(val, row.fractionLength, row.fractionLength)
                    break;
            }
            return val

        } catch (error) {
            console.error(error.message)
        }
    }

    useEffect(() => {
        const zcycDateTimeParam = urlStruct?.zcycDateTime
        if (zcycDate != null && new Date(zcycDate?.requertedDate).getTime() != new Date(zcycDate?.resultDate).getTime()) {
            //если дата не поменялась то для возврата прежней даты в контроле иммутируем pricingParameters 
            newDispatch(setConventionData({ pricingParameters: { ...pricingParameters, zcycDateTime: Localization.FormatDateTimeISO(new Date(zcycDate?.resultDate)) }, }))
        }
        if (zcycDate != null && new Date(zcycDateTimeParam).getTime() != new Date(zcycDate?.resultDate).getTime()) {


            const query = structuredClone(urlStruct ?? {})

            query['zcycDateTime'] = zcycDate?.resultDate
            setUrlStruct(query)
        }
    }, [zcycDate])

    const handleValueChange = (name, value, type = null) => {
        // console.log('value', value)
        if (value === null || value === '' || (value === '-' && (type == InputDataType.FLOAT || type == InputDataType.INT))) {
            return false
        }

        if (name == 'zcycDateTime') {
            let valT
            switch (type) {
                case InputDataType.DATE_T:
                case InputDataType.DATE_TIME:
                    if (name == 'zcycDateTime') value?.setHours(23, 59, 59)
                    valT = value !== '' ? Localization.FormatDateTimeISO(value).replace(' ', 'T') : null
                    newDispatch(getZCYCDateTime({ date: valT }))
                    break
                case InputDataType.TIME:
                    valT = value !== '' ? Localization.FormatDateTimeISO(value).replace(' ', 'T') : null
                    newDispatch(getZCYCDateTime({ date: valT }))
                    break
            }
            return false
        }

        // const query = {}
        // searchParams?.forEach((val, key) => {
        //     if (key == 'usePricingDateDataOnly')
        //         query[key] = parseBool(val)
        //     else
        //         switch (key) {
        //             case 'kr':
        //                 if (query[key] == null)
        //                     query[key] = [val]
        //                 else
        //                     query[key].push(val)
        //                 break

        //             default:
        //                 query[key] = val

        //         }
        // })
        let val
        switch (type) {
            case InputDataType.DATE_T:
            case InputDataType.DATE_TIME:
                if (name == 'zcycDateTime') value?.setHours(23, 59, 59)
                val = value !== '' ? Localization.FormatDateTimeISO(value).replace(' ', 'T') : null
                break

            case InputDataType.TIME:
                val = value !== '' ? Localization.FormatDateTimeISO(value).replace(' ', 'T') : null
                break

            default:
                val = value !== '' ? value : null
                break
        }
        setUrlStruct({ ...urlStruct, [name]: val })

        setParamsWasChangedManualy(true)
    }

    const checkForCalculationConditions = useCallback(() => {
        // console.log('requiredOptions', requiredOptions)
        if (requiredOptions?.length && requiredOptions.every(row => Object.keys(row?.options)?.some(key => {
            if (row?.options[key].value != null && row?.options[key].value != '' && row?.options[key].value != '-')
                return true
            // if (urlStruct.has(key)/* && (!paramsWasChangedManualy || modelKeyrate)*/) {
            //     return true
            // }
        }))) {
            return true
        }

        return false
    }, [requiredOptions])

    const handleSetCalValue = (e) => {
        if (e?.index !== null) {
            const newRequiderOptions = [...requiredOptions]
            Object.keys(newRequiderOptions[e.index].options).forEach(option_key => {
                if (option_key == e.target.name) newRequiderOptions[e.index].options[option_key].value = e.target.value
                else {
                    delete (newRequiderOptions[e.index].options[option_key].value)
                }
            })
            setRequiredOptions(newRequiderOptions)
            // console.log('newRequiderOptions----------------------->', newRequiderOptions)
        }

        if (!checkForCalculationConditions() && (e.target.value || e.target.value === 0)) {
            focusNextRef(e)
        }
    }

    const isBondPlacementAndFixed = (bond) => {
        return Boolean(bond?.bondStatus == BondStatus.STATUS_PLACEMENT && bond?.couponType != CouponType.TYPE_FLOAT)
    }
    // console.log('pricingParameters', pricingParameters)
    // console.log('calculationParameters', calculationParameters)
    // console.log('{ cpr: pricingParameters?.cpr != null ? pricingParameters.cpr : calculationParameters?.modelCPR }', { cpr: pricingParameters?.cpr != null ? pricingParameters.cpr : calculationParameters?.modelCPR })
    const calcParamsRows = [
        { name: 'CPR', hide: false, source: { cpr: urlStruct?.cpr ?? (pricingParameters?.cpr ?? (calculationParameters?.modelCPR)) }, tooltip: 'Convention2Tooltip_cpr', field: 'cpr', settersource: '', alias: 'Convention2_CPR', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', width: 5.6, decimalScale: 1, limits: { min: 0, max: 80 }, allowNegative: false, resetIcon: true, resetTooltip: 'convention2.tooltipResetCPR', },
        { name: 'CDR', hide: false, source: {cdr: urlStruct?.cdr?? pricingParameters?.cdr }, tooltip: 'Convention2Tooltip_cdr', field: 'cdr', settersource: '', alias: 'Convention2_CDR', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', width: 5.6, decimalScale: 1, limits: { min: 0, max: 20 }, allowNegative: false, resetIcon: true, resetTooltip: 'Convention2_tooltipResetCDR', },
        { name: 'PricingDate', hide: true, source: pricingParameters, field: 'pricingDate', ...(selectedBond?.bondStatus == BondStatus.STATUS_PLACEMENT) && { disabled: true } },
        { name: 'UsePricingDateDataOnly', hide: true, source: pricingParameters, field: 'usePricingDateDataOnly' },
        { name: 'ZCYCDateTime', hide: true, source: pricingParameters, field: 'zcycDateTime' },
    ]

    // console.log('pricingParameters', pricingParameters)
    const getMaxDate = () => {
        const tenDaysAfterNow = Date.now() + 10 * 60 * 60 * 24 * 1000
        const maxDate = Math.min(tenDaysAfterNow, new Date(selectedBond?.actualRedemptionDate ?? tenDaysAfterNow)?.getTime(), new Date(selectedBond?.maturityDate ?? tenDaysAfterNow)?.getTime())
        return maxDate
    }


    const ZCYCDateTimeSetter = (row) => {
        try {
            return (
                <IconButton
                    size="small"
                    className={cls.unitButton}
                    onClick={() => handleValueChange('zcycDateTime', Date.now(), row?.dataType)}
                >
                    <Tooltip
                        disableInteractive
                        title={t('convention2.zcycyDateRimeReset')}
                    >
                        <Update className={cls.unitIcon} />
                    </Tooltip>
                </IconButton>
            )

        } catch (error) {

        }
    }

    const getPoolStatistic = (data) => {
        switch (data?.poolType) {
            case 1:
                return ([
                    { name: 'WAC', source: { ...data?.wac, poolReportDate: poolStatistics?.reportDate }, field: 'total', datefield: 'reportDate', alias: 'convention2.WAC', tooltip: 'convention2.tooltip_wac', dataType: InputDataType.FLOAT, unit: 'convention2.percentPerYear', fractionLength: 2, readOnly: true },
                    { name: 'WALA', source: data?.wala, field: 'total', datefield: 'reportDate', alias: 'convention2.WALA', tooltip: 'convention2.tooltip_wala', dataType: InputDataType.FLOAT, unit: 'convention2.years', fractionLength: 1, readOnly: true },
                    { name: 'WAM', source: data?.wam, field: 'total', datefield: 'reportDate', alias: 'convention2.WAM', tooltip: 'convention2.tooltip_wam', dataType: InputDataType.FLOAT, unit: 'convention2.years', fractionLength: 1, readOnly: true },
                ])

            case 2:
                return ([
                    {
                        subrows: [
                            // { name: 'WAC', source: {...data?.wac, poolReportDate: poolStatistics?.reportDate}, field: 'float', grayData: true, grayName: true, datefield: 'reportDate', alias: 'convention2.WAC', tooltip: 'convention2.tooltip_wac1', dataType: InputDataType.FLOAT, unit: 'convention2.percentPerYear', fractionLength: 2, readOnly: true },
                            // { name: 'keyRateDeduction', slimLine: true, source: data?.keyRateDeduction, allowEmpty: true, field: 'float', grayData: true, grayName: true, datefield: 'reportDate', alias: 'convention2.keyRateDeduction', tooltip: 'convention2.tooltip_keyRateDeduction', dataType: InputDataType.FLOAT, unit: 'convention2.percentPoints', fractionLength: 2, readOnly: true },
                            { name: 'keyRatePremium', source: { ...data?.keyRatePremium, poolReportDate: poolStatistics?.reportDate }, allowEmpty: true, field: 'float', datefield: 'reportDate', alias: 'convention2.keyRatePremium', tooltip: 'convention2.tooltip_keyRatePremium2', dataType: InputDataType.FLOAT, unit: 'convention2.percentPoints', fractionLength: 2, readOnly: true },
                        ]
                    },
                    { name: 'WALA', source: data?.wala, field: 'float', datefield: 'reportDate', alias: 'convention2.WALA', tooltip: 'convention2.tooltip_wala', dataType: InputDataType.FLOAT, unit: 'convention2.years', fractionLength: 1, readOnly: true },
                    { name: 'WAM', source: data?.wam, field: 'float', datefield: 'reportDate', alias: 'convention2.WAM', tooltip: 'convention2.tooltip_wam', dataType: InputDataType.FLOAT, unit: 'convention2.years', fractionLength: 1, readOnly: true },
                ])

            case 3: return ([
                { name: 'title', lessFont: true, slimLine: true, source: { fixed: t('convention2.standardProgs'), float: t('convention2.govProgs') }, field: ['fixed', 'float'], alias: 'convention2.coveragePart', tooltip: 'convention2.tooltip_coveragePart', unit: [null, ''], grayName: true, grayData: [true, true], readOnly: true },
                { name: 'poolFraction', source: { ...data?.poolFraction, poolReportDate: poolStatistics?.reportDate }, field: ['fixed', 'float'], datefield: 'reportDate', alias: 'convention2.poolFraction', tooltip: 'convention2.tooltip_poolFraction', dataType: InputDataType.FLOAT, unit: [null, 'convention2.percentsSign'], fractionLength: 1, readOnly: true },
                {
                    subrows: [
                        { name: 'WAC', source: { ...data?.wac, poolReportDate: poolStatistics?.reportDate }, allowEmpty: true, field: ['fixed', null], grayData: [false, true], datefield: 'reportDate', alias: 'convention2.WAC', tooltip: 'convention2.tooltip_wac3', dataType: InputDataType.FLOAT, unit: [null, 'convention2.percentPerYear'], fractionLength: 2, readOnly: true },
                        // { name: 'keyRateDeduction', slimLine: true, source: data?.keyRateDeduction, allowEmpty: true, field: ['fixed', 'float'], grayData: [true, true], grayName: true, datefield: 'reportDate', alias: 'convention2.keyRateDeduction', tooltip: 'convention2.tooltip_keyRateDeduction', dataType: InputDataType.FLOAT, unit: [null, 'convention2.percentPoints'], fractionLength: 2, readOnly: true },
                        { name: 'keyRatePremium', source: { ...data?.keyRatePremium, poolReportDate: poolStatistics?.reportDate }, allowEmpty: true, field: ['fixed', 'float'], datefield: 'reportDate', alias: 'convention2.keyRatePremium', tooltip: 'convention2.tooltip_keyRatePremium3', dataType: InputDataType.FLOAT, unit: [null, 'convention2.percentPoints'], fractionLength: 2, readOnly: true },
                    ]
                },
                { name: 'WALA', source: { ...data?.wala, poolReportDate: poolStatistics?.reportDate }, field: ['fixed', 'float'], datefield: 'reportDate', alias: 'convention2.WALA', tooltip: 'convention2.tooltip_wala', dataType: InputDataType.FLOAT, unit: [null, 'convention2.years'], fractionLength: 1, readOnly: true },
                { name: 'WAM', source: { ...data?.wam, poolReportDate: poolStatistics?.reportDate }, field: ['fixed', 'float'], datefield: 'reportDate', alias: 'convention2.WAM', tooltip: 'convention2.tooltip_wam', dataType: InputDataType.FLOAT, unit: [null, 'convention2.years'], fractionLength: 1, readOnly: true },
            ])

            default:
                break;
        }
        return ([])
    }

    const getCoupoTypeTooltip = (couponType) => {
        switch (couponType) {
            case CouponType.TYPE_FIXED:
                return 'convention2.fixedCouponTypeTooltip'
            case CouponType.TYPE_FLOAT:
                return 'convention2.floatCouponTypeTooltip'
            case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                return 'convention2.KPCouponTypeTooltip'
        }
        return null
    }

    const paramRows = [
        {
            name: 'params', alias: 'Convention2_CalculateParams', rows: [
                {
                    name: 'PricingDate', source: pricingParameters, field: 'pricingDate', alias: 'Convention2_PricingDate', dataType: InputDataType.DATE_T,
                    disabled: isBondPlacementAndFixed(selectedBond),
                    tooltip: 'Convention2Tooltip_pricingDate',
                    minDate: new Date(bondParameters?.issueDate ? bondParameters?.issueDate : '2014-01-07').getTime(),
                    maxDate: getMaxDate()
                },
                { name: 'ZCYCDate', source: pricingParameters, field: 'zcycDateTime', alias: 'Convention2_ZCYCDate', tooltip: 'Convention2Tooltip_zcycDate', dataType: InputDataType.DATE_T, minDate: new Date('2014-01-07').getTime(), maxDate: Date.now() },
                { name: 'ZCYCTime', source: pricingParameters, field: 'zcycDateTime', alias: 'Convention2_ZCYCTime', tooltip: 'Convention2Tooltip_zcycTime', unitComponent: ZCYCDateTimeSetter, dataType: InputDataType.TIME },
                (selectedBond?.isin != 'RU000A100DQ4') ?
                    (pricingParameters?.pricingDate != null && new Date(pricingParameters?.pricingDate).getTime() < new Date().setHours(0, 0, 0, 0)) ?
                        { name: 'UsePricingDateDataOnly', source: pricingParameters, field: 'usePricingDateDataOnly', alias: 'Convention2_UsePricingDateDataOnly', tooltip: 'Convention2Tooltip_usePricingDateDataOnly', dataType: InputDataType.BOOLEAN, unit: '' }
                        : null
                    : null,
            ]
        },
        {
            name: 'bondParams', alias: 'Convention2_BondParams', rows: [
                { name: 'CurrentBondPrincipal', source: calculationParameters, field: 'currentBondPrincipal', alias: 'Convention2_CurrentBondPrincipal', tooltip: 'Convention2Tooltip_currentBondPrincipal', dataType: InputDataType.FLOAT, unit: 'Convention2_rubSign', fractionLength: 2, readOnly: true },
                { name: 'couponType', source: bondParameters, field: 'couponType', valueGetter: getCouponType, alias: 'Convention2_CouponType', tooltip: getCoupoTypeTooltip(bondParameters?.couponType), dataType: InputDataType.STRING, readOnly: true },
                { name: 'couponPeriod', source: bondParameters, field: 'couponPeriod', valueGetter: getCouponPeriodValue, alias: 'Convention2_CouponPeriod', tooltip: 'Convention2Tooltip_couponPeriod', dataType: InputDataType.INT, readOnly: true },
                bondParameters?.couponType == 1 ?
                    { name: 'fixedCouponRate', source: bondParameters, field: 'fixedCouponRate', alias: 'Convention2_FixedCouponRate', tooltip: 'Convention2Tooltip_fixedCouponRate', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 2, readOnly: true }
                    : bondParameters?.couponType == 3 ?
                        { name: 'fixedKeyRatePremium', source: bondParameters, field: 'fixedKeyRatePremium', alias: 'Convention2_FixedKeyRatePremium', tooltip: 'Convention2Tooltip_fixedKeyRatePremium', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 2, readOnly: true }
                        : null,
                { name: 'cleanUpPercentage', source: bondParameters, field: 'cleanUpPercentage', alias: 'Convention2_CleanUpPercentage', tooltip: 'Convention2Tooltip_cleanUpPercentage', dataType: InputDataType.INT, unit: 'Convention2_percent', fractionLength: 2, readOnly: true },
                { name: 'legalRedemptionDate', source: bondParameters, field: 'legalRedemptionDate', valueGetter: getDate, alias: 'Convention2_LegalRedemptionDate', tooltip: 'Convention2Tooltip_legalRedemptionDate', dataType: InputDataType.DATE, fractionLength: 2, readOnly: true },
            ]
        },
        {
            name: 'mortgageCoverage', alias: `${t('convention2.mortgageCoverageAt')} ${Localization.FormatDateStringLocale(poolStatistics?.reportDate)}`, rows: [
                { name: 'coverageType', source: poolStatistics, field: 'poolType', valueGetter: getCoverageType, alias: 'convention2.coverageType', tooltip: 'convention2.tooltip_coverageType', unit: null, readOnly: true },

                ...getPoolStatistic(poolStatistics) ?? [],
                // { name: 'WAC', source: calculationParameters, field: 'wac', datefield: 'poolReportDate', alias: 'Convention2_WAC', tooltip: 'Convention2Tooltip_wac', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 2, readOnly: true },
                // { name: 'SCurveCPR', source: calculationParameters, field: 'modelCPR', datefield: 'calculationSCurvesReportDate', alias: 'Convention2_SCurveCPR', tooltip: 'Convention2Tooltip_sCurveCPR', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 1, readOnly: true },
                {
                    name: 'RefinancingRate',
                    source: { ...poolStatistics }, //need for tooltip variables
                    field: 'currentRefinancingRate', datefield: 'currentRefinancingRateDate', alias: 'convention2.currentRefinancingRate',
                    tooltip: 'convention2.currentRefinancingRateTooltip',
                    dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 1, readOnly: true
                },
                {
                    name: 'IncentiveToRefinance',
                    source: { ...poolStatistics, keyRateModelDate: calculationParameters?.keyRateModelDate },
                    field: 'currentIncentive', datefield: 'macroDate', alias: 'convention2.currentIncentive',
                    tooltip: 'convention2.currentIncentiveTooltip',
                    dataType: InputDataType.FLOAT, unit: 'Convention2_PercentagePoints', fractionLength: 1, readOnly: true
                },
                {
                    name: 'HistoricalCPR',
                    source: { ...poolStatistics, issueDate: selectedBond?.issueDate }, //need for tooltip variables
                    field: 'historicalCPR', datefield: 'historicalCPRDate', subalias: 'Convention2_AvgFromIssueDate', alias: 'Convention2_HistoricalCPR',
                    tooltip: poolStatistics?.historicalCPR != null ? 'Convention2Tooltip_historicalCPR' : 'Convention2Tooltip_historicalCPR_null'
                    , dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 1, readOnly: true
                },
                {
                    name: 'SixMonthsCPR',
                    source: { ...poolStatistics, issueDate: selectedBond?.issueDate }, //need for tooltip variables
                    field: 'sixMonthsCPR', datefield: 'historicalCPRDate', subalias: 'Convention2_AvgSixMonths', alias: 'Convention2_HistoricalCPR',
                    tooltip: poolStatistics?.historicalCPR != null ? 'Convention2Tooltip_sixMonthsCPR' : 'Convention2Tooltip_sixMonthsCPR_null',
                    dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 1, readOnly: true
                },
                {
                    name: 'HistoricalCDR',
                    source: { ...poolStatistics, issueDate: selectedBond?.issueDate }, //need for tooltip variables
                    field: 'historicalCDR', datefield: 'historicalCDRDate', subalias: 'Convention2_AvgFromIssueDate', alias: 'Convention2_HistoricalCDR',
                    tooltip: poolStatistics?.historicalCPR != null ? 'Convention2Tooltip_historicalCDR' : 'Convention2Tooltip_historicalCDR_null',
                    dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 1, readOnly: true
                },
            ]
        },
    ]

    const resultRows = [
        {
            name: 'bondParams', alias: 'Convention2_BondParams',
            rows: [
                { name: 'AccruedCouponInterest', source: pricingResult, field: ['accruedCouponInterest', 'accruedCouponInterestRub'], alias: 'Convention2_AccruedCouponInterest', tooltip: "Convention2Tooltip_accruedCouponInterest", unit: ['Convention2_percent', 'Convention2_rubSign'], dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                { name: 'DirtyPrice', source: pricingResult, field: ['dirtyPrice', 'dirtyPriceRub'], alias: 'Convention2_dirtyPrice', tooltip: "Convention2Tooltip_dirtyPrice", unit: ['Convention2_percent', 'Convention2_rubSign'], dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                { name: 'CleanPrice', source: pricingResult, field: ['cleanPrice', 'cleanPriceRub'], alias: 'Convention2_cleanPrice', tooltip: "Convention2Tooltip_cleanPrice", unit: ['Convention2_percent', 'Convention2_rubSign'], dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                // ...(selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM) ?
                //     [{ name: 'NextCouponKeyRatePlusPremiumValuePercents', source: calculationParameters, field: 'nextCouponKeyRatePlusPremiumValuePercents', valueGetter: getNextCoupon, alias: 'convention2.NextCoupon', tooltip: 'convention2.NextCouponTooltip', unit: 'Convention2_percentPerYear', dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true }]
                //     : []
                ,
                ...(selectedBond?.couponType == CouponType.TYPE_FLOAT && poolInfo?.governProgramsFraction >= 100 - convention2governProgramsFractionLowerBorder) ?
                    [
                        { name: 'modelKeyRatePremium', source: pricingResult, field: 'modelKeyRatePremium', alias: 'convention2.modelKeyRatePremium', tooltip: "convention2.modelKeyRatePremiumTooltip", unit: 'Convention2_bp', dataType: InputDataType.FLOAT, fractionLength: 0, readOnly: true },
                    ] : []
                ,
                ...([CouponType.TYPE_FIXED].includes(selectedBond?.couponType) && selectedBond?.bondStatus == BondStatus.STATUS_PLACEMENT) ?
                    [
                        { name: 'f_zSpread', source: pricingResult, field: 'zSpread', alias: 'Convention2_zSpread', tooltip: "Convention2Tooltip_f_zSpreadParam", unit: 'Convention2_bp', dataType: InputDataType.INT, readOnly: true },
                        { name: 'f_gSpread', source: pricingResult, field: 'gSpread', alias: 'Convention2_gSpread', tooltip: "Convention2Tooltip_f_gSpreadParam", unit: 'Convention2_bp', dataType: InputDataType.INT, readOnly: true },

                    ] : []
                ,
                ...([CouponType.TYPE_FIXED/*, CouponType.TYPE_FLOAT*/].includes(selectedBond?.couponType)
                    || (selectedBond?.couponType == CouponType.TYPE_FLOAT && poolInfo?.governProgramsFraction <= convention2governProgramsFractionLowerBorder)) ?
                    [
                        { name: 'YTM', source: pricingResult, field: 'ytm', alias: 'Convention2_YTM', tooltip: "Convention2Tooltip_ytm", unit: 'Convention2_percentPerYear', dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                        { name: 'DurationMacaulay', source: pricingResult, field: 'durationMacaulay', alias: 'Convention2_DurationMacaulay', tooltip: "Convention2Tooltip_durationMacaulay", unit: 'convention2.ofYear', dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                        { name: 'DurationModified', source: pricingResult, field: 'durationModified', alias: 'Convention2_DurationModified', tooltip: "Convention2Tooltip_durationModified", unit: 'Convention2_PercentagePoints', dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                        // ...Boolean(isBondPlacementAndFixed(selectedBond)) ? [
                        //     { name: 'f_zSpread', source: pricingResult, field: 'zSpread', alias: 'Convention2_zSpread', tooltip: "Convention2Tooltip_f_zSpreadParam", unit: 'Convention2_bp', dataType: InputDataType.INT, readOnly: true },
                        //     { name: 'f_gSpread', source: pricingResult, field: 'gSpread', alias: 'Convention2_gSpread', tooltip: "Convention2Tooltip_f_gSpreadParam", unit: 'Convention2_bp', dataType: InputDataType.INT, readOnly: true },
                        // ]
                        //     : [],
                        // { name: '', source:'', field: '', alias: 'Convention2_', unit: '', fractionLength: 2 },        
                    ] : []
            ]
        },
    ]

    const handleCopyLink = () => {
        if (pricingParameters == null)
            return

        const calcStruct = { ...urlStruct }
        // console.log('calcStruct', calcStruct)

        calcParamsRows?.forEach(row => {
            if (!row?.disabled) {
                if (row.field == 'usePricingDateDataOnly' && (new Date(pricingParameters?.pricingDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)))
                    calcStruct[row.field] = 'true'
                else
                    calcStruct[row.field] = pricingParameters[row.field]
            }
        })
        // console.log('modelKeyrate', modelKeyrate)
        if (calcStruct?.kr)
            delete (calcStruct.kr)

        if (isNaN(parseFloat(calcStruct?.cpr)))
            delete (calcStruct.cpr)

        const params = new URLSearchParams({ ...calcStruct })
        // console.log('modelKeyrate', modelKeyrate)
        if (modelKeyrate) {
            urlStruct?.kr?.forEach(el => {
                params?.append('kr', el)
            })
        }

        const url = window.location.protocol + '//' + window.location.hostname + (window.location.port ? `:${window.location.port}` : ``) + window.location.pathname + '?' + params?.toString()
        copyTextToClipboard(url)
        actionAlert.addAlert({ text: 'Ссылка на расчет сформирована и скопирована', color: style.colorPrimary1, timeout: 4000 })(dispatch)
    }

    const checkLimits = (val = 0, min = null, max = null) => {

        let minCondition = true
        let maxCondition = true
        if (max != null)
            maxCondition = (val <= max)

        if (min != null)
            minCondition = (val >= min)

        return minCondition && maxCondition
    }

    function chEndAdornment(endAdornment) {
        const children = React.Children.map(endAdornment.props.children, ch => { if (ch) return React.cloneElement(ch, { title: '' }) }) //remove title for each endAdornment
        return React.cloneElement(endAdornment, {}, children);
    }

    const checkResetCondition = (row) => {
        try {
            if (row.resetIcon == null || loading)
                return

            switch (row?.field) {
                case 'cpr':
                    return Boolean(/*row?.source[row?.field] != */ pricingParameters?.cpr != null)

                case 'cdr':
                    return Boolean(row?.source[row?.field] != calculationParameters?.conventionalCDR)

                default:
                    return false
            }
        } catch (error) {

        }
    }

    const resetHandler = (row) => {
        try {
            if (row.resetIcon == null || loading)
                return

            switch (row?.field) {
                case 'cpr':
                case 'cdr':
                    urlParamRemove(row?.field)

                    break
            }
        } catch (error) {

        }
    }

    const showBlockRow = (row) => {
        if (row?.subrows) {
            return <Box key={`rowBlock_${row.name}`} className={cls.resultSubRows}>
                {row.subrows?.map(subRow => showBlockRow(subRow))}
            </Box>
        }

        return (
            <Box key={`result_${row.name}`} className={[cls.resultRow, row?.slimLine && cls.slimLine, row?.lessFont && cls.lessFont].join(' ')}>
                <Box className={[cls.resultRowName, row?.grayName && cls.grayColor].join(' ')}>
                    {Boolean(row?.tooltip) ?
                        <CustomTooltip
                            content={getTooltip(row)}
                            hideIcon={true}
                        >
                            {t(row?.alias)}
                        </CustomTooltip>
                        :
                        t(row?.alias)
                    }
                    {Boolean(row?.subalias) && <Box className={cls.resultRowSubname}>{t(row?.subalias)}</Box>}
                </Box>
                <Box className={cls.resultRowData}>
                    {
                        [...Array.isArray(row?.field) ? row?.field : [row?.field]]?.map((field, index) => {

                            let val = (row?.valueGetter ? row.valueGetter(row) : valueGetter({ ...row, field: [...Array.isArray(row?.field) ? row?.field : [row?.field]][index] }))
                            if (val === null && row?.allowEmpty == null) val = '–'
                            const unit = [...Array.isArray(row?.unit) ? row.unit : [row.unit]][index]
                            const unitIsArray = Array.isArray(row?.unit)
                            // console.log('val', val, row, field)
                            return (<React.Fragment key={`result_${field}`}>
                                <Box className={[cls.exResultRowValue, [...Array.isArray(row?.grayData) ? row?.grayData : [row?.grayData]][index] && cls.grayColor].join(' ')}>
                                    {Boolean(row?.readOnly) ?
                                        val
                                        : <UniversalInputField
                                            needConfirm
                                            allowKeyboardControl={![InputDataType.DATE_T, InputDataType.TIME].includes(row?.dataType)}
                                            dataType={row?.dataType ? row?.dataType : InputDataType.STRING}
                                            name={row.field}
                                            disabled={row?.disabled === true}
                                            readOnly={Boolean(row?.readOnly)}
                                            title={''}
                                            width={row?.width != null ? row.width : 'initial'}
                                            handleValueChange={(name, val) => { handleValueChange(name, val, row?.dataType) }}
                                            value={val}
                                            {...row.validators}
                                            sx={{
                                                '& .MuiInput-root': { cursor: 'pointer !important', },
                                                '& input': { paddingTop: 0, paddingBottom: 0, paddingRight: '0 !important', cursor: 'pointer', userSelect: 'none !important', },
                                                '& .MuiInput-root:before': { border: 'none!important' },
                                                '& .MuiInput-root:after': { border: 'none!important' },
                                                '& .MuiInput-underline:before': { border: 'none!important' },
                                                '& .MuiInputAdornment-root': { marginLeft: 0, marginTop: '3px' },
                                            }
                                            }
                                            hideValidationIcon={true}
                                            minDate={
                                                (row.dataType == InputDataType.DATE ||
                                                    row.dataType == InputDataType.DATE_TIME ||
                                                    row.dataType == InputDataType.DATE_T) ?
                                                    row?.minDate : null
                                            }
                                            maxDate={
                                                (row.dataType == InputDataType.DATE ||
                                                    row.dataType == InputDataType.DATE_TIME ||
                                                    row.dataType == InputDataType.DATE_T) ?
                                                    row?.maxDate : null
                                            }
                                            inputProps={{
                                                autoComplete: "off",
                                                style: { textAlign: 'right', paddingRight: '5px' },
                                                ...row?.decimalScale ? { decimalScale: row.decimalScale } : null,
                                            }}
                                            closeOnSelect={false}

                                        />}
                                    {(Boolean(row?.datefield) && Boolean(val))
                                        && <Box className={cls.resultRowSubvalue}>{Localization.FormatDateStringLocale(row?.source[row.datefield])}</Box>}

                                </Box>
                                <Box className={[cls.resultRowUnit, (unitIsArray && unit == null) && cls.hidden].join(' ')}>
                                    {row?.unitComponent != null ?
                                        row?.unitComponent(row) :
                                        t(unit)
                                    }
                                </Box>
                            </React.Fragment>)
                        })
                    }
                </Box>
            </Box>
        )
    }

    const showBondDetail = (bond = null) => {
        let message = null
        if (poolInfo != null && bond != null) {
            switch (selectedBond?.couponType) {
                case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                    message = <CustomTooltip
                        content={t('convention2.KP_couponTypeTooltip')}
                        hideIcon={true}
                        classes={{ tooltip: cls.couponTypeTooltip, popper: cls.couponTypeTooltipPopper }}
                    >
                        {t('convention2.KP_couponType')}
                    </CustomTooltip>
                    break

                case CouponType.TYPE_FIXED:
                    message = <CustomTooltip
                        content={t('convention2.fixed_couponTypeTooltip')}
                        hideIcon={true}
                        classes={{ tooltip: cls.couponTypeTooltip, popper: cls.couponTypeTooltipPopper }}
                    >
                        {t('convention2.fixed_couponType')}
                    </CustomTooltip>
                    break

                case CouponType.TYPE_FLOAT:
                    if (poolInfo?.governProgramsFraction >= 100 - convention2governProgramsFractionLowerBorder)
                        message = <CustomTooltip
                            content={t('convention2.onlyGovFractionTooltip')}
                            hideIcon={true}
                            classes={{ tooltip: cls.couponTypeTooltip, popper: cls.couponTypeTooltipPopper }}
                        >
                            {`${t('convention2.float_couponType')} (${t('convention2.onlyGovFraction')})`}
                        </CustomTooltip>
                    else if (poolInfo?.governProgramsFraction <= convention2governProgramsFractionLowerBorder)
                        message = <CustomTooltip
                            content={t('convention2.withoutGovFractionTooltip')}
                            hideIcon={true}
                            classes={{ tooltip: cls.couponTypeTooltip, popper: cls.couponTypeTooltipPopper }}
                        >
                            {`${t('convention2.float_couponType')} (${t('convention2.withoutGovFraction')})`}
                        </CustomTooltip>
                    else
                        message = <CustomTooltip
                            content={t('convention2.couponGovFractionTooltip')}
                            hideIcon={true}
                            classes={{ tooltip: cls.couponTypeTooltip, popper: cls.couponTypeTooltipPopper }}
                        >
                            {`${t('convention2.float_couponType')} (${t('convention2.couponGovFraction')}`}
                            <b>&nbsp;{Math.round(poolInfo?.governProgramsFraction)}{`%`}</b>{`)`}
                        </CustomTooltip>
                    break
            }
        }

        return <Box className={[cls.bondInfoRow].join(' ')}>{message}</Box>

    }

    return (<>
        {/* <Convention2InfoWin
            show={showConvention2InfoWin}
            setShow={setShowConvention2InfoWin}
        /> */}
        {/* <Menu
            id="basic-menu"
            anchorEl={menuEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuItem onClick={() => { window?.open('https://xn--80atbdbsooh2gqb.xn--d1aqf.xn--p1ai/files/%D0%9A%D0%BE%D0%BD%D0%B2%D0%B5%D0%BD%D1%86%D0%B8%D1%8F%20%D0%98%D0%A6%D0%91%20%D0%94%D0%9E%D0%9C.%D0%A0%D0%A4%202.0.pdf', '_blank') }}>
                <ListItemIcon className={cls.menuIcon}><InfoOutlined fontSize="small" /></ListItemIcon>
                <ListItemText className={cls.menuText}>{t('convention2.conv2Button')}</ListItemText>
                {/ * <Typography variant="body2" color="text.secondary">⌘V</Typography> * /}
            </MenuItem>
            <MenuItem onClick={() => window?.open('/files/Конвенция ИЦБ ДОМ.РФ.pdf#limitationOfLiability', '_blank')}>
                <ListItemIcon className={cls.menuIcon}><GppMaybeOutlined fontSize="small" /></ListItemIcon>
                <ListItemText className={cls.menuText}>{t('convention2.limitation')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setShowConventionPresentation(true)}>
                <ListItemIcon className={cls.menuIcon}><Slideshow fontSize="small" /></ListItemIcon>
                <ListItemText className={cls.menuText}>{t('convention2.presentation')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => null}>
                <ListItemIcon className={cls.menuIcon}><ApiIcon fontSize="small" /></ListItemIcon>
                <ListItemText className={cls.menuText}>{t('convention2.API')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => window?.open('/files/Конвенция ИЦБ ДОМ.РФ.pdf#getDataForCalculation', '_blank')}>
                <ListItemIcon className={cls.menuIcon}></ListItemIcon>
                <ListItemText className={cls.menuText}>{t('convention2.api_method1')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => window?.open('/files/Конвенция ИЦБ ДОМ.РФ.pdf#calculate', '_blank')}>
                <ListItemIcon className={cls.menuIcon}></ListItemIcon>
                <ListItemText className={cls.menuText}>{t('convention2.api_method2')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => window?.open('/files/Конвенция ИЦБ ДОМ.РФ.pdf#examplesAPI', '_blank')}>
                <ListItemIcon className={cls.menuIcon}></ListItemIcon>
                <ListItemText className={cls.menuText}>{t('convention2.api_method3')}</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => window.open(`https://github.com/pdovbnya/convention`, '_blank')}>
                <ListItemIcon className={cls.menuIcon}><GitHub fontSize="small" /></ListItemIcon>
                <ListItemText className={cls.menuText}>{t('Convention2_GitHub')}</ListItemText>
            </MenuItem>

        </Menu > */}
        {showConventionPresentation && <Presentation
            show={showConventionPresentation}
            setShow={setShowConventionPresentation}
        />
        }
        <Box className={[cls.root].join(' ')}>
            <Box className={cls.rootPaper}>
                {loading && <CustomCircularProgress
                    color={style.colorPrimary2} к
                    size={48}
                    thickness={6}
                    classes={{ ValueRoot: cls.progressValueRoot, ProgressBase: cls.progressBase, Progress: cls.progress }}
                    value={calculationPercents} //{signalR?.connection?.connectionId && (calculationPercents !== null) ? calculationPercents : null}
                    showCover={true}
                />
                }
                <Box className={cls.panelHeader}>
                    <Box className={cls.panelHeaderTitle}>{t("Convention2_InputPanelTitle")}</Box>
                    <Box className={cls.panelHeaderIcons}>
                        <Tooltip
                            disableFocusListener
                            title={<>
                                <Conv2Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => { window?.open('/files/Конвенция ИЦБ ДОМ.РФ.pdf', '_blank') }}
                                >
                                    {t('convention2.conv2Button')}
                                    <Box className="comment">{t('convention2.methodicsFile')}</Box>
                                </Conv2Button>
                                <Box className={cls.limitationLiability}>
                                    <div dangerouslySetInnerHTML={{ __html: getTooltip({ tooltip: 'convention2.limitationLiability' }) }} />
                                </Box>
                            </>}
                            classes={{
                                tooltip: cls.tooltip
                            }}
                        >
                            <IconButton size='small'>
                                <InfoOutlined className={cls.panelHeaderIcon} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={t('convention2.manual')}
                            disableInteractive
                            classes={{ tooltip: cls.tooltip }}
                        >
                            <IconButton
                                size='small'
                                onClick={() => setShowConventionPresentation(true)}
                            >
                                <Slideshow className={cls.panelHeaderIcon} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={t('Convention2_GitHub')}
                            disableInteractive
                            classes={{ tooltip: cls.tooltip }}
                        >
                            <IconButton
                                size='small'
                                onClick={() => window.open(`https://github.com/pdovbnya/convention`, '_blank')}
                            >
                                <GitHub className={cls.panelHeaderIcon} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={<div dangerouslySetInnerHTML={{ __html: getTooltip({ tooltip: 'Convention2_API' }) }} />}
                            classes={{ tooltip: cls.tooltip }}
                            disableInteractive
                            disableFocusListener
                        >
                            <IconButton
                                size='small'
                                onClick={() => window.open('/files/Конвенция ИЦБ ДОМ.РФ.pdf#calculate', '_blank')}
                            >
                                <ApiIcon className={cls.panelHeaderIcon} />
                            </IconButton>
                        </Tooltip>
                        {/* <IconButton
                            size='small'
                            onClick={handleMenuClick}
                        >
                            <MenuIcon className={cls.panelHeaderIcon} />
                        </IconButton> */}

                        <Tooltip
                            title={t('Convention2_copyFullLink')}
                            disableInteractive
                            disableFocusListener
                            classes={{ tooltip: cls.tooltip }}
                        >
                            <IconButton
                                disabled={!Boolean(pricingResult)}
                                size='small'
                                onClick={handleCopyLink}
                            >
                                <Share className={cls.panelHeaderIcon} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Box className={cls.panelData}>
                    <Box className={[cls.row, cls.bondInputRow].join(' ')}>
                        <Box className={[cls.rowData, cls.fw].join(' ')}>
                            <Autocomplete
                                disablePortal={false}
                                id="bonds-list"
                                options={bondList}
                                disabled={loading || bondListLoading}
                                classes={{ option: cls.option, paper: cls.autoselectPopper, listbox: [cls.virtualScrollablePanel, cls.scrollableList].join(' ') }}
                                componentsProps={{ popper: { sx: { minWidth: 'fit-content' } } }}
                                noOptionsText={t("Convention2_noOptions")}
                                filterOptions={(options, state) => createFilterOptions({ trim: true })(options, state)}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            // label={selectedBond ? '' : t("Convention2_InputISINLabel")}
                                            label={t("Convention2_InputISINLabel")}
                                            InputLabelProps={{
                                                className: cls.labelStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "off",
                                                style: {/* textAlign: 'right'*/ },
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: bondListLoading ? <CustomCircularProgress color={style.colorPrimary3_40} size={20} /> : null,
                                                endAdornment: <>{chEndAdornment(params.InputProps.endAdornment)}</>
                                            }}
                                        />
                                    )
                                }}
                                getOptionLabel={(bond) => {
                                    if (bond.bondStatus == BondStatus.STATUS_PLACEMENT)
                                        return `${bond?.regNumber}`.trim()
                                    else {
                                        return (`${bond?.label}` + (bond?.isin != null ? ` / ${bond?.isin}` : ``)).trim()
                                    }
                                }}
                                value={selectedBond}
                                onChange={(e, bond, reason, details) => {
                                    // setParamsWasChangedManualy(true)
                                    handleSelectBond(bond)
                                }}
                                renderOption={(props, bond, { selected }) => {
                                    // console.log('props', props)
                                    return (
                                        <Box {...props} key={props?.key} className={[cls.optionBox, props.className, (bond.bondStatus == BondStatus.STATUS_REDEEMED) && cls.redeemedBond].join(' ')}>
                                            <Box className={[cls.optionName, isMobile ? cls.optionMobile : ''].join(' ')}>
                                                {
                                                    (bond.bondStatus == BondStatus.STATUS_PLACEMENT) ?
                                                        <Box className={cls.optionLabel}>{bond?.regNumber}</Box>
                                                        : <>
                                                            {bond?.label != null && <Box className={cls.optionLabel}>{bond.label}</Box>}
                                                            {bond?.isin != null && <Box className={cls.optionISIN}>{bond.isin}</Box>}
                                                        </>
                                                }
                                            </Box>
                                            <Box className={cls.optionStatusBox}>
                                                <Box className={cls.optionStatus}>
                                                    {getBondStatus(bond)}
                                                </Box>
                                                <Box className={cls.optionCouponType}>
                                                    {getBondCoupon(bond?.couponType)}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                }
                                }
                            />
                        </Box>
                    </Box>
                    {showBondDetail(selectedBond)}
                    {Boolean(poolInfo != null && Boolean(selectedBond) && requiredOptions?.length) &&
                        <Box className={cls.rowInputParams}>
                            {requiredOptions?.map((row, row_index) =>
                                <Box className={cls.colInputParams} key={`inputRow_${row_index}`}>
                                    {row?.message && <Box className={[cls.row, cls.requiredParametersRowMessage].join(' ')}><div dangerouslySetInnerHTML={{ __html: row.message }} /></Box>}
                                    <Box className={[cls.optionParams/*, Object.keys(row.options)?.length == 1 ? cls.singleParam : ''*/].join(' ')}>
                                        <Grid container className={cls.optionParamsGrid}>{
                                            Object.keys(row.options).map((key, index) => {
                                                const itemCount = Object.keys(row?.options)?.length ?? 1
                                                return <Grid item key={`option_${key}`} xs={12 / itemCount}>
                                                    {/* return <Box key={`option_${key}`} xs={12 / itemCount}> */}
                                                    <CustomNumericValidator
                                                        name={key}
                                                        disabled={row.options[key]?.disabled}
                                                        header={row.options[key]?.title}
                                                        tooltip={getTooltip(row.options[key])}
                                                        unit={row.options[key].unit ? t(row.options[key].unit) : null}
                                                        width={row.options[key]?.width != null ? row.options[key].width : 'initial'}
                                                        value={
                                                            row.options[key]?.value != null ? row.options[key].value : (pricingResult != null ? pricingResult[key] : '')
                                                        }
                                                        onChange={(e) => handleSetCalValue({ ...e, index: row_index })}
                                                        onFocus={(e) => { if (inputRef.current != e) inputRef.current = e }}
                                                        inputProps={{
                                                            ref: (el) => row.options[key]?.default ? addToRefs(el, row_index) : null,
                                                            autoComplete: "off",
                                                            style: { textAlign: 'right', paddingRight: '5px' },
                                                            isAllowed: (values) => {
                                                                const { floatValue } = values;
                                                                return checkLimits(floatValue, row.options[key]?.limits?.min, row.options[key]?.limits?.max)
                                                            },
                                                            thousandSeparator: false,
                                                            // fixedDecimalScale: true,
                                                            ...row.options[key]?.decimalScale != null ? { decimalScale: row.options[key].decimalScale } : null,
                                                            ...row.options[key]?.allowNegative != null ? { allowNegative: row.options[key].allowNegative } : null,
                                                        }}
                                                        sx={{
                                                            '& .MuiInput-root:before': { border: 'none!important' },
                                                            // '& .MuiInput-root:after': { border: 'none!important' },
                                                        }}
                                                    />
                                                </Grid>
                                                {/* </Box> */ }
                                            })
                                        }
                                        </Grid>
                                    </Box>
                                </Box>)
                            }
                        </Box>
                    }

                    {Boolean(inputPricingParameters) &&
                        calcParamsRows?.filter(row => !row?.hide)?.some(row => ((row?.valueGetter ? row.valueGetter(row) : valueGetter(row)) != null)) &&
                        <Box className={cls.row}>
                            <Grid container className={cls.optionExParamsBox}>
                                {calcParamsRows
                                    ?.filter(row => !row?.hide && ((row?.valueGetter ? row.valueGetter(row) : valueGetter(row)) != null))
                                    ?.map(row => {
                                        // console.log('row?.valueGetter ? row.valueGetter(row)', row?.valueGetter ? row.valueGetter(row) : '-')
                                        // console.log('valueGetter(row)', valueGetter(row))
                                        if (row?.name == null)
                                            return (<hr />)
                                        return <Grid item key={`option_${row.name}`} xs={12 / calcParamsRows?.filter(row => !row?.hide).length} className={cls.optionExParams} >
                                            <CustomNumericValidator
                                                name={row.field}
                                                disabled={row?.disabled}
                                                tooltip={getTooltip(row)}
                                                header={t(row.alias)}
                                                unit={row.unit ? t(row.unit) : null}
                                                width={row?.width != null ? row.width : 'initial'}
                                                onChange={(e) => {
                                                    return handleValueChange(e.target.name, e.target.value) //need RETURN for right behaviour with empty value FALSE - emulate Escape behaviour
                                                }}
                                                value={row?.valueGetter ? row.valueGetter(row) : valueGetter(row)}
                                                {...row.validators}
                                                sx={{
                                                    '& .MuiInput-root:before': { border: 'none!important' },
                                                    // '& .MuiInput-root:after': { border: 'none!important' },
                                                }}
                                                inputProps={{
                                                    // autoComplete: "off",
                                                    style: { textAlign: 'right', paddingRight: '5px', ...row?.disabled && { pointerEvents: 'none', userSelect: 'none' } },
                                                    isAllowed: (values) => {
                                                        const { floatValue } = values;
                                                        return checkLimits(floatValue, row?.limits?.min, row?.limits?.max)
                                                    },
                                                    thousandSeparator: false,
                                                    // fixedDecimalScale: true,
                                                    ...row?.decimalScale ? { decimalScale: row.decimalScale } : null,
                                                    // ...row?.allowNegative != null ? { allowNegative: row.allowNegative } : null,
                                                    allowNegative: false
                                                }}
                                            />
                                            {Boolean(row?.resetIcon != null) && checkResetCondition(row) && <>
                                                <Tooltip
                                                    disableInteractive
                                                    title={row?.resetTooltip ? t(row.resetTooltip) : null}
                                                    classes={{ tooltip: cls.wideTooltip }}
                                                >
                                                    <IconButton className={cls.smallIconButton} onClick={() => resetHandler(row)}>
                                                        <Replay className={cls.smallIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                            }
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Box>
                    }

                    {apiError ? <Box className={cls.alertBox}>{apiError}</Box>
                        : Boolean(pricingResult != null) &&
                        <><Box className={cls.paramsRoot1}>
                            <Box className={cls.divider} />
                            {/* <Box className={cls.virtualVisibleScrollablePanel}>*/}
                            <Box className={cls.virtualScrollablePanel}>
                                {resultRows
                                    ?.map(block => {
                                        return <Box key={`block_${block?.name}`} className={cls.block}>
                                            {block?.rows
                                                ?.filter(row => row != null)
                                                ?.map(row => {
                                                    return (
                                                        <Box key={`result_${row.name}`} className={cls.resultRow}>
                                                            <Box className={cls.resultRowName}>
                                                                {Boolean(row?.tooltip) ?
                                                                    <CustomTooltip
                                                                        content={getTooltip(row)}
                                                                        hideIcon={true}
                                                                        className={cls.customTooltip}
                                                                    >
                                                                        {t(row?.alias)}
                                                                    </CustomTooltip>
                                                                    :
                                                                    t(row?.alias)
                                                                }
                                                            </Box>
                                                            <Box className={cls.resultRowDataBlock}>
                                                                {[...Array.isArray(row?.field) ? row?.field : [row?.field]]?.map((field, index) => {
                                                                    return (<Box className={cls.resultRowData} key={`result_${field}`}>
                                                                        <Box className={cls.resultRowValue}>
                                                                            {row?.valueGetter ? row.valueGetter({ ...row, field }) : valueGetter({ ...row, field })}
                                                                        </Box>
                                                                        <Box className={[cls.resultRowUnit, (Array.isArray(row?.field) && index == 0) ? cls.resultRowUnitFit : ''].join(' ')}>{t([...Array.isArray(row?.unit) ? row.unit : [row.unit]][index])}</Box>
                                                                    </Box>)
                                                                })}
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    })
                                }
                                {paramRows
                                    ?.filter(row => row?.rows?.some(item => {
                                        try {
                                            return item?.source[item?.field] != null
                                        }
                                        catch (error) { }
                                    }))
                                    ?.map(block => {
                                        return <Box key={`block_${block?.name}`} className={cls.block}>
                                            {Boolean(block?.alias !== null) && <Box className={[cls.blockTitle].join(' ')}>{t(block.alias)}</Box>}
                                            {block?.rows
                                                ?.filter(row => row != null)
                                                ?.map(row => showBlockRow(row))
                                            }
                                        </Box>
                                    })
                                }
                            </Box>
                        </Box></>
                    }
                    {pricingResult == null &&
                        <Box className={cls.newVersionBlock}>
                            <Tooltip
                                title={<div dangerouslySetInnerHTML={{ __html: getTooltip({ tooltip: 'convention2.newReleaseInfo' }) }} />}
                            >
                                <Box className={cls.newVersion}>
                                    <ErrorOutline fontSize="small" />
                                    {t("convention2.newRelease")}
                                </Box>
                            </Tooltip>
                        </Box>
                    }
                </Box>
            </Box >
        </Box >
    </>
    )
}

export default InputPanel