import CalculatorService from "@Services/CalculatorService";
import CommonService from "@Services/CommonService";
import Localization from '@Components/Localization';
import { Actions as LoaderActions } from "@Store/LoaderStore";

const Actions = {

    Init: "CALCULATIOR_INIT",

    RequestStart: "CALCULATOR_REQUEST_START",
    RequestSuccess: "CALCULATOR_REQUEST_SUCCESS",
    RequestFailure: "CALCULATOR_REQUEST_FAILURE",

    ZCYCDataLoaded: "CALCULATOR_ZCYCDATA_LOADED",
    CalculationLoaded: "CALCULATOR_CALCULATION_LOADED",
    ZCYCDataUpdated: "CALCULATOR_ZCYCDATA_UPDATED",
    BondStatisticsLoaded: "CALCULATOR_STATISTICS_LOADED"
}


export const actionCreators = {

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init });
    },

    getOriginators: (filter) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        const loader = "originatorsLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        var result = await CalculatorService.getOriginators(filter);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            dispatch({ type: LoaderActions.HideLoader, payload: loader });

            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { originators: result.value } });
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },
    createOriginator: (originator) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.createOriginator(originator);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { newOriginator: result.value } });
    },
    updateOriginator: (update) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.updateOriginator(update);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { updatedOriginator: result.value } });
    },
    removeOriginator: (id) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.removeOriginator(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    },

    getCalculations: (filter) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        const loader = "calculationsLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        var result = await CalculatorService.getCalculations(filter);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            dispatch({ type: LoaderActions.HideLoader, payload: loader });

            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { calculations: { items: result.value.items, count: result.value.count } } });
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },

    removeCalculation: (id) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.removeCalculation(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    },

    findBondsQuery: (value, existedId = null, index = null, pageSize = null) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.getBondsQuery(value, existedId, index, pageSize);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: result.value });
    },

    findProductsQuery: (value, existedId = null, index = null, pageSize = null) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.getProductsQuery(value, existedId, index, pageSize);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: result.value });
    },

    getCalculationResults: (id) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.getCalculationResults(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: result.value });
    },

    getComplexZCYCC: (date) => async (dispatch) => {

        //dispatch({ type: Actions.RequestStart });

        var result = await CommonService.getComplexZCYCC(Localization.FormatDateStringISO(date));
        if (result.hasErrors) {
            //dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.ZCYCDataLoaded, payload: result.value });
    },

    getZCYCUpdateData: (date, curveData) => async (dispatch) => {

        //dispatch({ type: Actions.RequestStart });

        var result = await CommonService.getZCYCUpdateData(date, curveData);
        if (result.hasErrors) {
            //dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.ZCYCDataUpdated, payload: { ...result.value, forward_curve_modified_chart_data: curveData.map((item) => { return { x: item.year, y: item.value } }) } });
    },

    getCalculation: (id) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.getCalculation(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        var ZCYCresult = await CommonService.getComplexZCYCC(result.value.parameters.zcycDate, result.value.parameters.id);
        if (ZCYCresult.hasErrors) {
            ZCYCresult = null;
        }

        var bondResult = await CalculatorService.getBondInformation(result.value.bondId, result.value.parameters.evaluationDate);
        if (bondResult.hasErrors) {
            bondResult = null;
        }

        dispatch({ type: Actions.CalculationLoaded, payload: { calculation: result.value, zcyc: ZCYCresult?.value, bondStats: bondResult?.value } });
    },

    getBondInformation: (bondId, evaluationDate) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.getBondInformation(bondId, evaluationDate);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { bondStats: result.value } });
    },

    getCurveData: (id, actualDate) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.getCurveData(id, actualDate);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        var stressedForward = result.value.stressedForward.map((item) => { return { x: item.year, y: item.value } });

        dispatch({ type: Actions.ZCYCDataUpdated, payload: { zcycCurve: result.value.zcycStressed, forward_curve_modified_chart_data: stressedForward } });
    },

    saveZCYCValues: (data) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.saveZCYCValues(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    },

    getProcessingMessages: (filter) => async (dispatch) => {

        const loader = "messagesLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });
        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.getProcessingMessages(filter);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { showErrorsDialog: true, messages: { items: result.value.items, count: result.value.count } } });
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },

    loadBondStatistics: (id) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.getBondStatistics(id, Localization.FormatDateISO(new Date()));
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.BondStatisticsLoaded, payload: result.value });
    },

    loadLayouts: (id) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.loadUserLayouts(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { loadedLayouts: result.value } });
    },

    saveLayout: (data) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.saveUserLayout(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { newLayout: result.value } });
    },

    removeLayout: (id) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.removeUserLayout(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    },

    getPortfolios: (filter) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        const loader = "portfoliosLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        var result = await CalculatorService.getPortfolios(filter);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            dispatch({ type: LoaderActions.HideLoader, payload: loader });

            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { portfolios: result.value } });
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },
    createPortfolio: (portfolio) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.createPortfolio(portfolio);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { newPortfolio: result.value } });
    },
    updatePortfolio: (update) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.updatePortfolio(update);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { updatedPortfolio: result.value } });
    },
    removePortfolio: (id) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.removePortfolio(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    },

    getZCYCCurves: (request) => async (dispatch, getState) => {

        const loader = "zcycCurvesLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        dispatch({ type: Actions.RequestStart })

        var filter = {
            ItemIndex: request?.Skip,
            PageSize: request?.Take,
            Filters: request?.Filters,
            Sorters: request?.Sorters,
            Params: null,

        }

        var result = await CalculatorService.getZCYCCurves(filter)
        // console.log('result', result)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        const items = result.value.items

        dispatch({ type: Actions.RequestSuccess, payload: { zcycCurves: { count: result.value.count, items: items } } });
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },

    recalculateCalculationById: (id) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.recalculateCalculationById(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    },

    

    /*
    loadBondCPRCDR: (id) => async (dispatch) => {

        dispatch({ type: Actions.StartCPRCDRLoad });

        var cprcrdResult = await CalculatorService.getBondCPRCDR(id);
        if (cprcrdResult.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.CPRCDRLoaded, payload: cprcrdResult.value });
    }
    */
};

const initialState = {
    operationLoading: false,
    isLoaded: false
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState;

    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.RequestStart:
            return { ...currentState, operationLoading: true };

        case Actions.RequestSuccess:
            return { ...currentState, operationLoading: false, isLoaded: true, ...action.payload };

        case Actions.RequestFailure:
            return { ...currentState, operationLoading: false, isLoaded: true };

        case Actions.ZCYCDataLoaded:
            {
                if (action.payload == null)
                    return;

                //edit data
                var forward_curve_edit_data = action.payload.forwardCurveValues;

                var forward_curve_base_chart_data = [];
                var zcy_curve_base_chart_data = [];
                var forward_curve_modified_chart_data = [];
                var zcy_curve_modified_chart_data = [];

                action.payload.standardForwardCurve.forEach(function (item, i, arr) {
                    forward_curve_base_chart_data.push([item.year, item.value]);
                });

                action.payload.standardZCYCCurve.forEach(function (item, i, arr) {
                    zcy_curve_base_chart_data.push([item.year, item.value]);
                });

                //to be stressed
                forward_curve_edit_data.forEach(function (item, i, arr) {
                    forward_curve_modified_chart_data.push({ x: item.year, y: item.value });
                });


                action.payload.zcycCurve.forEach(function (item, i, arr) {
                    zcy_curve_modified_chart_data.push([item.year, item.value]);
                });

                return {
                    ...currentState,

                    parameters: {
                        zcycDate: action.payload.zcycDate
                    },

                    forward_curve_base_chart_data: forward_curve_base_chart_data,
                    zcy_curve_base_chart_data: zcy_curve_base_chart_data,
                    forward_curve_modified_chart_data: forward_curve_modified_chart_data,
                    zcy_curve_modified_chart_data: zcy_curve_modified_chart_data
                };
            }
        case Actions.CalculationLoaded: {

            var forward_curve_base_chart_data = [];
            var zcy_curve_base_chart_data = [];
            var forward_curve_modified_chart_data = [];
            var zcy_curve_modified_chart_data = [];

            if (action.payload.zcyc != null) {

                action.payload.zcyc.standardForwardCurve.forEach(function (item, i, arr) {
                    forward_curve_base_chart_data.push([item.year, item.value]);
                });

                action.payload.zcyc.standardZCYCCurve.forEach(function (item, i, arr) {
                    zcy_curve_base_chart_data.push([item.year, item.value]);
                });

                action.payload.zcyc.forwardCurveValues.forEach(function (item, i, arr) {
                    forward_curve_modified_chart_data.push({ x: item.year, y: item.value });
                });

                action.payload.zcyc.zcycCurve.forEach(function (item, i, arr) {
                    zcy_curve_modified_chart_data.push([item.year, item.value]);
                });
            }

            return {
                ...currentState,
                ...action.payload.calculation,
                bondStats: action.payload.bondStats,

                operationLoading: false,
                isLoaded: true,

                forward_curve_base_chart_data: forward_curve_base_chart_data,
                zcy_curve_base_chart_data: zcy_curve_base_chart_data,
                forward_curve_modified_chart_data: forward_curve_modified_chart_data,
                zcy_curve_modified_chart_data: zcy_curve_modified_chart_data
            };
        }
        case Actions.ZCYCDataUpdated: {

            if (action.payload == null)
                return;

            var zcy_curve_modified_chart_data = [];

            action.payload.zcycCurve.forEach(function (item, i, arr) {
                zcy_curve_modified_chart_data.push([item.year, item.value]);
            });

            return {
                ...currentState,

                operationLoading: false,

                forward_curve_modified_chart_data: action.payload.forward_curve_modified_chart_data,
                zcy_curve_modified_chart_data: zcy_curve_modified_chart_data
            };
        }

        case Actions.BondStatisticsLoaded: {
            var cpr_curve_data = [];
            var cdr_curve_data = [];

            if (action.payload.bondData != null) {
                action.payload.bondData.forEach(function (item, i, arr) {
                    cpr_curve_data.push([Date.parse(item.dateFrom), item.cpr]);
                    cdr_curve_data.push([Date.parse(item.dateFrom), item.cdr]);

                });
            }

            return {
                ...currentState,

                operationLoading: false,

                statistics: action.payload,
                cpr_curve_data: cpr_curve_data,
                cdr_curve_data: cdr_curve_data
            };
        }

        default:
            return currentState || initialState;
    }
};