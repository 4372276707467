import Globals from "@Base/Globals";

import { Permissions } from '@Components/Definitions';

//calculator pages
import CreateCalculationPage from '@Pages/instruments/calculator/CreateCalculationPage';
import CalculationsPage from '@Pages/instruments/calculator/CalculationsPage';
import CalculationResultsPage from '@Pages/instruments/calculator/CalculationResultsPage';

//data pages
import OriginatorsPage from '@Pages/instruments/data/OriginatorsPage';
import BondsPage from '@Pages/instruments/bonds/BondsPage';
import PortfoliosPage from '@Pages/instruments/data/PortfoliosPage';


// import CatalogPage from '@Pages/instruments/library/CatalogPage';

//administration
import DashboardPage from '@Pages/instruments/administration/DashboardPage';
import DataSourcesPage from '@Pages/instruments/administration/DataSourcesPage';
import UsersPage from '@Pages/instruments/administration/UsersPage';
import RolesPage from '@Pages/instruments/administration/RolesPage';
import MailingPage from '@Pages/instruments/administration/MailingPage';

//MBS stats
import MSFlowsPage from '@Pages/instruments/msdomrf/MSFlowsPage';
//convention
import ConventionCalculationPage from '@Pages/instruments/convention/ConventionCalculationPage';

// import BondsDataSourcePage from '@Pages/instruments/datasource/BondsDataSourcePage';

//import CreateCalculationPage_new from '@Pages/instruments/calculator_new/CreateCalculationPage_new';

import PoolsPage from '@Pages/instruments/pool/PoolsPage';

//securitization
import SecuritizationDealsPage from '@Pages/instruments/securitization/SecuritizationDealsPage';
import PoolStatisticsPage from '@Pages/instruments/securitization/PoolStatisticsPage';
//import ManagersPage from '@Pages/instruments/securitization/ManagersPage';

//debug
import FormTestPage from '@Pages/debugging/FormTestPage';

import Localization from '@Components/Localization';
import Comparation from "@Base/pages/instruments/msdomrf/Comparation";
import { t } from "i18next";

import Convention2 from "@Base/pages/instruments/convention2/Convention2";
import TestAPP from "@Base/pages/debugging/TestAPP";


export function Instruments() {
    var instruments = [
        {
            id: "1",
            img: "images/originators-paper.jpg",
            url: "/instruments/data/originators",
            libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            isLibraryExternalLink: true,
            title: Localization.Translate('Originators'),
            description: "",
            favourite: 1,
            area: "Data",
            permissionRequired: Permissions.PERMISSION_ORIGINATORS_VIEW,
            actions: [
                { id: "originators", permissionRequired: Permissions.PERMISSION_ORIGINATORS_VIEW, url: "/instruments/data/originators", name: Localization.Translate('StartPage'), component: OriginatorsPage },
            ]
        },
        {
            id: "2",
            img: "images/bonds-paper.jpg",
            url: "/instruments/bonds",
            libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            isLibraryExternalLink: true,
            title: Localization.Translate('MBSDOMRFIssues'),
            description: "",
            favourite: 1,
            area: "Data",
            permissionRequired: Permissions.PERMISSION_BONDS_VIEW,
            actions: [
                { id: "bonds", permissionRequired: Permissions.PERMISSION_BONDS_VIEW, url: "/instruments/bonds", name: Localization.Translate('StartPage'), component: BondsPage },
            ]
        },
        {
            id: "3",
            img: "images/portfolios-paper.jpg",
            url: "/instruments/data/portfolios",
            libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            isLibraryExternalLink: true,
            title: Localization.Translate('MortgagePools'),
            description: "",
            favourite: 1,
            area: "Data",
            permissionRequired: Permissions.PERMISSION_PORTFOLIOS_VIEW,
            actions: [
                { id: "portfolios", permissionRequired: Permissions.PERMISSION_PORTFOLIOS_VIEW, url: "/instruments/data/portfolios", name: Localization.Translate('StartPage'), component: PortfoliosPage },
            ]
        },

        //{
        //    id: "4",
        //    img: "images/wiki-paper.jpg",
        //    url: Globals.WikiServer,
        //    libraryUrl: Globals.WikiServer,
        //    isExternalLink: true,
        //    isLibraryExternalLink: true,
        //    title: Localization.Translate('WikiMBSDOMRF'),
        //    description: "",
        //    favourite: 1,
        //    area: "Library",
        //    //permissionRequired: Permissions.PERMISSION_WIKI_VIEW
        //},

        //{
        //    id: "5",
        //    img: "images/convention-paper.jpg",
        //    url: "/instruments/convention/convention",
        //    libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
        //    isLibraryExternalLink: true,
        //    title: Localization.Translate("ConventionNameInstrumentTitle"),
        //    description: "",
        //    favourite: 1,
        //    area: "Convention",
        //    //permissionRequired: Permissions.PERMISSION_CONVENTION_CALCULATOR_VIEW,
        //    actions: [
        //        {
        //            id: "convention", /*permissionRequired: Permissions.PERMISSION_CONVENTION_CALCULATOR_VIEW, name: Localization.Translate("Convention"),*/ url: "/instruments/convention/convention", name: Localization.Translate("StartPage"), component: ConventionCalculationPage
        //        }
        //    ]
        //},
        // {
        //     id: "14",
        //     img: "images/convention-paper.jpg",
        //     url: "/instruments/convention2",
        //     libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
        //     isLibraryExternalLink: true,
        //     title: Localization.Translate("Convention2_instrumentTitle"),
        //     description: "",
        //     favourite: 1,
        //     area: "Convention2",
        //     // permissionRequired: Permissions.PERMISSION_CONVENTION_CALCULATOR_VIEW,
        //     actions: [
        //         {
        //             id: "convention", /*permissionRequired: Permissions.PERMISSION_CONVENTION_CALCULATOR_VIEW, name: Localization.Translate("Convention"),*/ url: "/instruments/convention2", name: Localization.Translate("StartPage"), component: Convention2
        //         }
        //     ]
        // },
        {
            id: "14",
            img: "images/convention-paper.jpg",
            url: "/instruments/convention",
            libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            isLibraryExternalLink: true,
            title: Localization.Translate("Convention2_instrumentHeader"),
            description: "",
            favourite: 1,
            area: "Convention2",
            hideActions: true,
            // permissionRequired: Permissions.PERMISSION_CONVENTION_CALCULATOR_VIEW,
            actions: [
                {
                    id: "convention2", /*permissionRequired: Permissions.PERMISSION_CONVENTION_CALCULATOR_VIEW, name: Localization.Translate("Convention"),*/ url: "/instruments/convention", name: Localization.Translate("StartPage"), component: Convention2,
                },
                { id: "convention2_presentation", url: "/instruments/convention/demo", name: Localization.Translate("convention2.presentation"), component: Convention2, props: { demo: true } }
            ]
        },
        {
            id: "6",
            img: "images/pool-paper.jpg",
            url: "/instruments/pools/statistics",
            libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            isLibraryExternalLink: true,
            title: Localization.Translate("MortgagePool"),
            description: "",
            favourite: 1,
            area: "Pools",
            /*permissionRequired: Permissions.PERMISSION_POOL_STATISTICS_VIEW,*/
            actions: [
                { id: "statistics", /*permissionRequired: Permissions.PERMISSION_POOL_STATISTICS_VIEW,*/ name: Localization.Translate("StartPage"), url: "/instruments/pools/statistics", component: PoolsPage }
            ]
        },
        {
            id: "7",
            img: "images/dom-rf-quotes-paper.jpg",
            url: "/instruments/msdomrf/flows",
            libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            isLibraryExternalLink: true,
            title: Localization.Translate('MBSDOMRFQuotes'),
            description: "",
            favourite: 1,
            area: "DOMRF",
            permissionRequired: Permissions.PERMISSION_QUOTES_VIEW,
            actions: [
                {
                    id: "flows", permissionRequired: Permissions.PERMISSION_QUOTES_VIEW, url: "/instruments/msdomrf/flows", name: Localization.Translate('StartPage'), component: MSFlowsPage
                }
            ]
        },
        {
            id: "7_compare",
            img: "images/dom-rf-quotes-paper.jpg",
            url: "/instruments/quotes/compare",
            // libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            // isLibraryExternalLink: true,
            title: t('quotes.instrumentTitle'),
            description: "",
            favourite: 1,
            area: "DOMRF",
            permissionRequired: Permissions.PERMISSION_QUOTES_VIEW,
            actions: [
                {
                    id: "quotes.compare", permissionRequired: Permissions.PERMISSION_QUOTES_VIEW, url: "/instruments/quotes/compare", name: Localization.Translate('quotes.StartPage'), component: Comparation
                }
            ]
        },
        {
            id: "8",
            img: "images/calculator-paper.jpg",
            url: "/instruments/calculator/new",
            libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            isLibraryExternalLink: true,
            title: Localization.Translate('MBSCalculator'),
            description: "",
            favourite: 1,
            area: "Calculator",
            permissionRequired: Permissions.PERMISSION_CALCULATOR_VIEW,
            actions: [
                { id: "new", name: Localization.Translate('NewCalculation'), url: "/instruments/calculator/new", component: CreateCalculationPage },
                { id: "calculations", name: Localization.Translate('Calculations'), url: "/instruments/calculator/calculations", component: CalculationsPage },
                { id: "calculationResults", name: "", isHidden: true, url: "/instruments/calculator/results/:id", component: CalculationResultsPage },
                { id: "recalculate", name: "", isHidden: true, url: "/instruments/calculator/recalculate/:id", component: CreateCalculationPage, props: { action: 'recalculate' }, optionalParameters: { mode: 'simple' } },
                { id: "duplicate", name: "", isHidden: true, url: "/instruments/calculator/duplicate/:id/", component: CreateCalculationPage, props: { action: 'duplicate' }, optionalParameters: { mode: 'simple' } }
            ]
        },
        /*
        {
            id: "9",
            img: "images/calculator-paper.jpg",
            url: "/instruments/calculator_new/new",
            libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            isLibraryExternalLink: true,
            title: "Новый калькулятор",
            description: "",
            favourite: 1,
            area: "Calculator",
            permissionRequired: Permissions.PERMISSION_CALCULATOR_VIEW,
            actions: [
                { id: "new", name: "Новый расчёт", url: "/instruments/calculator_new/new", component: CreateCalculationPage_new },
                { id: "calculations", name: "Расчёты", url: "/instruments/calculator/calculations", component: CalculationsPage },
                { id: "calculationResults", name: "", isHidden: true, url: "/instruments/calculator/results/:id", component: CalculationResultsPage },
                { id: "recalculate", name: "", isHidden: true, url: "/instruments/calculator/recalculate/:id/:mode?", component: CreateCalculationPage, props: { action: 'recalculate' } },
                { id: "duplicate", name: "", isHidden: true, url: "/instruments/calculator/duplicate/:id/:mode?", component: CreateCalculationPage, props: { action: 'duplicate' } }
            ]

        },
        */
        {
            id: "10",
            img: "images/administration-paper.jpg",
            url: "/instruments/administration/dashboard",
            libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            isLibraryExternalLink: true,
            title: Localization.Translate('Administration'),
            description: "",
            favourite: 1,
            area: "Administration",
            permissionRequired: Permissions.PERMISSION_ADMINISTRATION_VIEW,
            actions: [
                { id: "dashboard", permissionRequired: Permissions.PERMISSION_ADMINISTRATION_DASHBOARD_VIEW, name: Localization.Translate('Dashboard'), url: "/instruments/administration/dashboard", component: DashboardPage },
                { id: "dataSources", permissionRequired: Permissions.PERMISSION_ADMINISTRATION_DATASOURCES_VIEW, name: Localization.Translate('DataSources'), url: "/instruments/administration/datasources", component: DataSourcesPage },
                { id: "users", permissionRequired: Permissions.PERMISSION_ADMINISTRATION_USERS_VIEW, name: Localization.Translate('Users'), url: "/instruments/administration/users", component: UsersPage },
                { id: "roles", permissionRequired: Permissions.PERMISSION_ADMINISTRATION_ROLES_VIEW, name: Localization.Translate('RolesAndPermissions'), url: "/instruments/administration/roles", component: RolesPage },
                { id: "mailing", permissionRequired: Permissions.PERMISSION_ADMINISTRATION_NEWSLETTERS_VIEW, name: Localization.Translate('NewslettersGroups'), url: "/instruments/calculator/mailingpage", component: MailingPage }
            ]
        },
        {
            id: "11",
            img: "images/securitization_paper.jpg",
            url: "/instruments/securitization/deals",
            libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
            isLibraryExternalLink: true,
            title: Localization.Translate('Securitization'),
            description: "",
            favourite: 1,
            area: "Securitization",
            permissionRequired: Permissions.PERMISSION_SECURITIZATION_VIEW,
            actions: [
                { id: "deals", permissionRequired: Permissions.PERMISSION_SECURITIZATION_VIEW, url: "/instruments/securitization/deals", name: Localization.Translate('Deals'), component: SecuritizationDealsPage },
                { id: "poolStatistics", permissionRequired: Permissions.PERMISSION_SECURITIZATION_VIEW, name: "", isHidden: true, url: "/instruments/securitization/statistics", component: PoolStatisticsPage }
                //{ id: "dealManagers", permissionRequired: Permissions.PERMISSION_SECURITIZATION_VIEW, url: "/instruments/securitization/managers", name: Localization.Translate('Managers'), component: ManagersPage }
            ]
        },
        //{
        //    id: "12",
        //    img: "images/webapi-paper.jpg",
        //    url: `${process.env.REACT_APP_BASE_APP_URL}:${process.env.REACT_APP_API_PORT}/swagger`,
        //    libraryUrl: `${process.env.REACT_APP_BASE_APP_URL}:${process.env.REACT_APP_API_PORT}`,
        //    isExternalLink: true,
        //    isLibraryExternalLink: true,
        //    title: Localization.Translate('WebAPI'),
        //    description: "",
        //    favourite: 1,
        //    area: "WebAPI"
        //}
    ];

    if (process.env.NODE_ENV !== 'production') {

    instruments.push({
        id: "100",
        img: "images/debug-paper.jpg",
        url: "/debug/form",
        libraryUrl: Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F",
        isLibraryExternalLink: true,
        title: Localization.Translate('Debug'),
        description: "",
        favourite: 1,
        area: "Debug",
        actions: [
            { id: "SERG", url: "/debug/form", name: Localization.Translate('SERG'), component: FormTestPage },
            { id: "BAM", url: "/debug/test", name: Localization.Translate('BAM'), component: TestAPP },
        ]
    });
    }


    return instruments;
}