import * as React from "react";
import '@Styles/components/SpinnerBars.scss';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    rootLabel: {
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        color: 'rgb(196,196,196)',
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    mainContainerFull: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    spinnerBackground: {
        zIndex: '+1',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.5)'
    }
});

const SpinnerBars = ({ label = null, size = null, withBackground = false, classes = { root: {}, rootLabel: {} } }) => {

    const cls = useStyles();

    const formSize = (size) => {

        if (size != null && size.length !== 0)
            return 'spinner-bars-' + size;

        return 'spinner-bars-normal';
    }

    const formContainerSize = (size) => {

        if (size != null && size.length !== 0)
            return 'spinner-bars-container-' + size;

        return 'spinner-bars-container-normal';
    }

    const renderSpinner = (isFullHeght) => {
        return (
            <div className={[isFullHeght ? cls.mainContainerFull : cls.mainContainer, classes.root].join(' ')}>
                <div className={formContainerSize(size)}>
                    <div className={formSize(size)}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                {/* {console.log('label', label)} */}
                {
                    (label !== null) && (
                        <div className={[cls.rootLabel, classes?.rootLabel].join(' ')}>{label}</div>
                    )
                }
            </div>
        )
    }

    return (

        withBackground ? (
            <div className={cls.spinnerBackground}>
                {renderSpinner(true)}
            </div>
        ) : (
            <React.Fragment>
                {renderSpinner(false)}
            </React.Fragment>
        )
    );
}

export default SpinnerBars;