import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import stringInject from 'stringinject';
import { Theme } from '@mui/material/styles';
import Localization from '@Components/Localization';
import { DropzoneArea } from '@Framework/components/DropZone';
import style from '@Styles/domrf-theme.module.scss';
import { DatePickerValidator } from "@Framework/components/Form";
import { Stack, TextField } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => createStyles({
    line: {
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'baseline',
        alignItems: 'stretch',
        marginTop: '1rem',
        '& .MuiInputBase-root': {
            marginTop: '7px',
        },
        '& label': {
            marginTop: '-7px'
        },
        '& label:not(.MuiFormLabel-filled):is(.Mui-focused)': {
            marginTop: '-4px',
        },
        '& .MuiFormLabel-filled': {
            marginTop: '-4px',
        },
    },
    inputField: {
        paddingBottom: '1rem'
    },
    dropZoneFileContainer: {
        // marginBottom:'1rem !important'
    },
    dropZoneFileItem: {
        padding: '0 !important',
        margin: '0 !important',

        zIndex: 'initial !important'

    },
    dropzone: {
        minHeight: 'inherit',
        border: '2px dashed ' + theme.palette.grey[500],
        backgroundColor: theme.palette.grey[200],
        padding: '2rem',
        // minHeight:'200px',
        '&:hover': {
            backgroundColor: theme.palette.grey[300],
        }
    },
    dropZoneLabel: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
    },
    dropZoneCaption: {
        marginTop: '16px',
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
        letterSpacing: theme.typography.caption.letterSpacing,
        color: 'rgba(0, 0, 0, 0.47)'
    }
}));

interface IProps {
    fileData: File;
    poolDate: Date;
    currentNodePoolId: number | null;
    nodeName: string | null;
    poolDateChanged: (date: Date) => void;
    fileLoaded: (file: File) => void;
    handleNodeNameChange: (name: string) => void;
}

const CreateNodePoolDialog = (props: IProps) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    // const poolDateRequirement = () => {
    //     if (props.fileData != null) {
    //         return props.poolDate != null;
    //     }
    //     return true;
    // }

    return (
        <>
            {/* {props?.currentNodePoolId == null &&
                <Stack className={classes.line}>
                    <TextField
                        fullWidth
                        label={t('NodeName')}
                        value={props?.nodeName ?? ''} onChange={(e) => props.handleNodeNameChange(e.currentTarget.value)}
                    />
                </Stack>
            } */}
            <Stack className={classes.line}>
                {/* {console.log('props.poolDate', props.poolDate)} */}
                <DatePickerValidator
                    // mobile={false}
                    disableErrorHighlight={true}
                    //disabled={props.fileData == null}
                    // inputClassName='me-1'
                    label={t('PoolMortgageDate')}
                    //tooltip={t('PoolDate_Info')}
                    fullWidth={true}
                    value={props.poolDate}
                    minDate={new Date('2016-12-01')}
                    maxDate={new Date()} //текущая 
                    mask={Localization.DateMask()}
                    format={Localization.ShortDatePattern()}
                    onChange={props.poolDateChanged}
                    validators={['required', 'invalidDate']}
                    errorMessages={[t('FieldRequired'), t('InvalidDate')]}
                />
            </Stack>
            <Stack className={classes.line}>
                <DropzoneArea
                    filesLimit={1}
                    dropzoneClass={classes.dropzone}
                    dropzoneParagraphClass={classes.dropZoneLabel}
                    // dropzoneText={t('DragOrSelectLLDFile')}
                    dropzoneText={null}
                    showAlerts={false}
                    showPreviews={false}
                    showPreviewsInDropzone={true}
                    previewGridClasses={{ item: classes.dropZoneFileItem, container: classes.dropZoneFileContainer }}
                    Icon={<CloudUpload sx={{ width: '100px', height: '100px', color: style.colorPrimary3_40 }} />}
                    disableDelete
                    useChipsForPreview={false}
                    getPreviewIcon={() => { }}
                    showFileNames={true}
                    // showFileSize={true}
                    showFileNamesInPreview={true}
                    maxFileSize={50000000}
                    previewText=''
                    onChange={props.fileLoaded}
                    getFileLimitExceedMessage={(filesLimit) => stringInject(t('MaximumNumberFilesExceeded_Params'), [filesLimit])}
                    getFileAddedMessage={(fileName) => stringInject(t('FileAdded_Params'), [fileName])}
                    getFileRemovedMessage={(fileName) => stringInject(t('FileRemoved_Params'), [fileName])}
                    getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                        let message = stringInject(t('FileRejected_Params'), [rejectedFile.name as string]);
                        if (!acceptedFiles.includes(rejectedFile.type)) {
                            message += t('FileTypeNotSupported')
                        }
                        if (rejectedFile.size > maxFileSize) {
                            message += stringInject(t('FileIsTooBig_Params'), [50])
                        }
                        return message;
                    }}
                    validators={['required']}
                    errorMessages={[t('FieldRequired')]}
                />
            </Stack>
        </>
    )
}


export default CreateNodePoolDialog