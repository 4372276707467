import React, { useState, useEffect, useCallback } from 'react';

import { debounce } from 'throttle-debounce';
import Globals from "@Base/Globals";
import { Paper, Stack, alpha, Box, Container, Tab, Tabs, Theme, Button, Typography, InputAdornment, Tooltip } from '@mui/material'

import Dialog from '@mui/material/Dialog';
// import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';

import { actionCreators as bondsStore } from "@Store/BondsStore";

import SearchPanelInput from '@Components/shared/SearchPanelInput';

import Localization from '@Components/Localization';
import { createStyles, makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'

import style from '@Styles/domrf-theme.module.scss';

import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridActionsCellItem,
    GridColumnGroupingModel,
    GridComparatorFn,
    GridValueFormatterParams,
    GridRenderCellParams,
    GridPaginationModel,
    GridCallbackDetails,
    GridSortModel,
    GridEventListener
} from '@mui/x-data-grid-premium';
import { ruRU, enUS } from '@mui/x-data-grid/locales';

interface IProps {
    show: false,
    area: string;
    onClose: any;
    onCancel: any;
    onOK: any;
    classes: any;
    t: any;
}

interface IState {
    isOkDisabled: boolean;
    selected: any[];
}


const useStyles = makeStyles((theme: Theme) => createStyles({

    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        flex: '1 1 auto',
        height: 0,
        width: '100%',
        minWidth: '100%'
    },
    searchField: {
        marginLeft: 'auto'
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    progress: {
        width: '100%'
    },
    warningIcon: {
        color: style.colorWarning
    },
    statusButton: {
        padding: '0'
    }
}));

const BondSelectDialog = ({ show, onClose, onCancel, onOK, area }) => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();

    //const [startPageIndex, setStartPageIndex] = useState(0); //CHECKME!!! add to user settings
    //const [pageSize, setPageSize] = useState(50); //CHECKME!!! add to user settings
    const [searchValue, setSearchValue] = useState('');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50
    });

    const [locale, setLocale] = useState(ruRU);

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'name',
            sort: 'asc',
        },
    ]);
    const [isOkDisabled, setIsOkDisabled] = useState(false);
    const [selected, setSelected] = useState(null);

    const {
        count,
        items
    } = useSelector((state: any) => state.bonds)

    const {
        bondsLoad
    } = useSelector((state: any) => state.loader)

    useEffect(() => {
        if (!show)
            setIsOkDisabled(true);

    }, [show]);

    useEffect(() => {
        searchDebounced(searchValue);
    }, [searchValue, paginationModel]);

    const onWindowClose = (event) => {
        if (onClose != null)
            onClose(event);
    }

    const handleCancel = (event) => {

        if (onCancel != null)
            onCancel(event);

        onWindowClose(event);
    }

    const handleOK = (event) => {
        if (onOK != null)
            onOK(event, selected != null ? [selected.id] : null);

        onWindowClose(event);
    }

    //const dataSourceQuery = async (request: any) => {
    //
    //    var filter = SiteHelper.CreateFilter(request);
    //    filter.Params = { Area: this.props.area };
    //
    //    var result = await CalculatorService.getBonds(filter);
    //    if (result.hasErrors) {
    //        throw ('Error while processing request');
    //    }
    //
    //    //IGridPayload
    //    return {
    //        totalCount: result.value.count,
    //        payload: result.value.items
    //    };
    //}

    //renderProductCell = (row: any) => {
    //    return row.productName;
    //};
    //
    //renderOriginatorCell = (row: any) => {
    //    return row.originatorName;
    //}
    //
    //handleRowClick = (row: any, selected: any[]) => {
    //    this.setState({ selected: selected, isOkDisabled: selected.length === 0 });
    //}
    //
    //handleRowDoubleClick = (row: any) => {
    //    if (this.props.onOK)
    //        this.props.onOK(null, this.state.selected);
    //
    //    this.onWindowClose(null);
    //}

    const columns: GridColDef[] = [

        { field: 'id', headerName: t('ID'), description: t('bonds.id') },
        {
            field: 'createdDate', headerName: t('CreatedDate'), description: t('bonds.createdDate'), width: 120,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return '';
                }
                return Localization.FormatDateStringLocale(params.value);
            }
        },
        {
            field: 'issueDate', headerName: t('IssueDate'), description: t('bonds.issueDate'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return '';
                }
                return Localization.FormatDateStringLocale(params.value);
            }
        },
        { field: 'isin', headerName: t('ISIN'), description: t('bonds.isin'), width: 300 },
        { field: 'name', headerName: t('Name'), description: t('bonds.name'), width: 300 },
        { field: 'description', headerName: t('Description'), description: t('originators.description'), width: 200 },

        {
            field: 'originatorID', headerName: t('Originator'), description: t('bonds.originator'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                var row = params.api.getRow(params.id)

                return row.originator?.name;
            }
        },
        { field: 'isShared', headerName: t('Shared'), description: t('bonds.shared'), type: 'boolean', width: 100 },
        { field: 'isValid', headerName: t('Valid'), description: t('bonds.valid'), type: 'boolean', width: 150 }
    ];

    const updateDataSource = (searchValue) => {

        var filterItem = null;
        if (searchValue != '') {

            if (paginationModel.page != 0) {
                setPaginationModel({ ...paginationModel, page: 0 });
                return;
            }

            filterItem = [{
                Operator: "or",
                Values: [
                    {
                        Name: "name",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "description",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    }
                ]
            }];
        }

        var sortItem = sortModel.map((item, index) => {
            return {
                Name: item.field,
                IsAscending: item.sort == 'asc',
                SortOrder: index
            };
        });

        var filter = {
            Filters: filterItem,
            Sorters: sortItem,
            Skip: paginationModel.page * paginationModel.pageSize,
            Take: paginationModel.pageSize
        };

        bondsStore.getBonds(filter)(dispatch);
    }

    const onPaginationModelChange = (model: GridPaginationModel, details: GridCallbackDetails) => {

        setPaginationModel(model);
    }

    const searchDebounced = useCallback(debounce(500, updateDataSource), [paginationModel, sortModel]);

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        setSelected(params.row);
        setIsOkDisabled(false);
    };


    return (

        <Dialog fullScreen onClose={onWindowClose} aria-labelledby="bonds-select-dialog-title" open={show}>
            <MuiDialogContent className={classes.root}>

                <Container className={classes.container}>

                    <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingX: '1rem', paddingY: '0.5rem' }}>
                        <SearchPanelInput className={classes.searchField} value={searchValue} onValueChange={setSearchValue} placeHolder={t('Search')} />
                    </Stack>
                    {/*console.log("portfoliosLoad => " + portfoliosLoad)*/}
                    <DataGridPremium
                        sx={{
                            ["& .bandHeaderCell .MuiDataGrid-columnHeaderTitleContainer"]: {
                                justifyContent: 'center'
                            }
                        }}
                        //experimentalFeatures={{ columnGrouping: true }}
                        disableMultipleRowSelection={true}
                        disableColumnMenu
                        loading={bondsLoad}
                        columnHeaderHeight={30}
                        rowHeight={25}
                        rows={items}
                        columns={columns}
                        //columnGroupingModel={columnGrouping}
                        localeText={locale.components.MuiDataGrid.defaultProps.localeText}

                        paginationMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={onPaginationModelChange}
                        rowCount={count}
                        pagination
                        pageSizeOptions={[30, 50, 100]}

                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        onRowClick={handleRowClick}
                        
                    />
                </Container >
            </MuiDialogContent>
            <MuiDialogActions>
                <Button variant="contained" color="primary" onClick={handleCancel}>
                    {t('Cancel')}
                </Button>
                <Button disabled={isOkDisabled} variant="contained" color="secondary" onClick={handleOK}>
                    {t('OK')}
                </Button>
            </MuiDialogActions>
        </Dialog>
    )
}

export default BondSelectDialog;