import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { isValid as isValidDateFns } from 'date-fns'
import { makeStyles, createStyles } from '@mui/styles';
import { alpha, Theme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import ChipAuthor from '@Components/shared/ChipAuthor';
import { DealType, efDealDataState, efDealValidationState } from '@Components/Definitions';
import { isValid, addMonths, setDate } from 'date-fns';
import { ValidatorForm } from "@Framework/components/Form";
import stringInject from 'stringinject';
import Localization from '@Components/Localization';
import { valueOrDefault, getType, toUnixDateGMT } from '@Utilities';
import style from '@Styles/domrf-theme.module.scss';
import UniversalInputField, { InputDataType } from "@Framework/components/Form/UniversalInputField";
import CustomTooltip from '../../../../components/shared/CustomTooltip';
import Globals from '../../../../Globals';
import { getCouponTypes } from '../../../../Queries';
import { CouponType } from '../../../../components/Definitions';
import { Backdrop, Box, IconButton, Paper, Popper, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import { Close, HighlightOffRounded, InsightsRounded, WarningAmber, Restore as RestoreIcon, Filter } from '@mui/icons-material';
import KeyRatePanel from '@Base/pages/common/KeyRatePanel';
import { debounce } from 'throttle-debounce';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => createStyles({
    resetButton: {
        color: style.colorPrimary1,
        position: 'absolute',
        right: '-2px',
        top: '0px',
    },
    infoField: {
        position: 'relative',
        right: 0
    },
    disabled: {
        color: `${style.colorPrimary3_120} !important`,
        '& .MuiInput-underline': {
            '&:before': {
                borderBottom: 'none !important',
            }
        }
    },
    changed: {
        // '&::before':{
        //     // content:'""',
        // },
        backgroundColor: style.colorPrimary1_185, ///*`rgb(248,248,250)`*/`#f2f8e8`,
    },
    Block: {
        display: 'flex',
        flexDirection: 'column',
        '& *': {
            fontSize: '1rem',
        },
        // marginLeft: '8px',
        marginRight: 0,
        // marginBottom:'33px',
        // '&>$rowBlock:nth-child(even)': {
        //     backgroundColor: `${alpha(style.colorPrimary3, 0.07)}`,
        // }
        // '&>$rowBlock:nth-child(n)': {
        //     boxShadow: '1px 1px #00000015',
        // },
    },
    blockName: {
        color: `${style.colorPrimary3_40}`,
        textTransform: 'uppercase',
        boxShadow: `0px 1px ${alpha(style.colorPrimary3, 0.7)}`,
        // borderBottom: `1px solid ${alpha(style.colorPrimary3, 1)}`,
        padding: '0',
        margin: '4px 0',
        // lineHeight:'2rem',
        // fontSize:'0.8rem'
    },
    rowBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        lineHeight: '2rem',
        height: '2rem !important',
    },
    rowName: {
        flexShrink: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1rem',
    },
    rowValuesBlock: {
        display: 'flex',
        flexWrap: 'nowrap',
        textAlign: 'right',
        justifyContent: 'flex-end',
        flexGrow: 1,
        // lineHeight: '32px',
        alignItems: 'baseline',
    },
    valueData: {
        width: '117px !important',
        whiteSpace: 'nowrap',
        height: '32px',
        '& svg': {
            height: '19px',
            width: '19px',
            padding: 0,
        },
        '& .MuiInputAdornment-root': {
        },
        '& .MuiInputAdornment-root svg': {
            margin: '6px',
        },
        '& input': {
            '&::selection': {
                backgroundColor: `rgb(200,232,250)`
            },
            textAlign: 'right',
            paddingRight: '0.5rem',
            paddingLeft: '0.5rem',
        }
    },
    valueUnit: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '3rem !important',
        fontSize: '0.8rem',
        paddingLeft: '0.3rem',
        color: `${alpha(style.colorPrimary3, 0.5)}`,
        textAlign: 'left',
    },
    valueAuthor: {
        display: 'flex',
        width: '25px !important',
        textAlign: 'right',
        alignSelf: 'center',
        '& *': {
            fontSize: '0.8rem',
        },
    },
    container: {
        flex: '1 1 auto',
        margin: '3px 5px 0 0',
        position: 'relative',
    },
    subheader: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        textTransform: 'uppercase',
        color: `${alpha(style.colorPrimary3, 0.6)}`,
        backgroundColor: '#FFFFFF',
        borderBottom: `1px solid ${alpha(style.colorPrimary3, 0.6)}`
    },
    grayColor: {
        backgroundColor: '#f7f7f7'
    },
    whiteColor: {
        backgroundColor: '#FFFFFF'
    },
    errorMessage: {
        margin: '0 0.5rem',
        color: style.colorError,
    },
    keyrateButtonsBlock: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginRight: '0.5rem',
    },
    keyrateButtonsIcon: { width: '20px', height: '20px' },
    keyRateIsInvalid: {
        color: '#F99',
        // backgroundColor: '#F99',
        // borderRadius: '50%',
    },
    backDrop: {
        zIndex: '+2',
        // position:'fixed',
        // left:0, right:0, top:0, bottom:0,
        // backgroundColor:`${alpha(style.colorPrimary3,0.3)}`,
        pointerEvents: 'auto'
    },
    popupPaper: {
        padding: '0.5rem',
        width: '80%',
        height: '70%',
        position: 'relative',
        overflow: 'visible',
        zIndex: '+1',
        display: 'flex',
        flexDirection: 'column'
    },
    popupPaperCloseElement: {
        position: 'absolute',
        right: '-43px', top: '-30px',
        '&:hover $popupPaperCloseElementIcon': {
            opacity: 0.8,
        },
    },
    popupPaperCloseElementIcon: {
        color: '#FFF',
        width: '30px',
        height: '30px',
        padding: '2px',
        border: 'solid 1px #FFF',
        borderRadius: '50%',
        opacity: 0.5,
    },
    changedButton: {
        backgroundColor: style.colorPrimary1_185
    }

}))

const DealParametersPanel = ({ isEdit /*, onFieldUpdate, onFieldLeft, pool*/ }) => {
    const { deal = null,
        backupDeal = null,
        backupNode = null,
        selectedNode = null,
        proceededIteration,
        permissions } = useSelector((state: any) => state.securitization)
    const { onlineDealChanges } = deal

    // console.log('deal.dealChanges', deal?.dealChanges)
    // console.log('deal?.onlineDealChanges', deal?.onlineDealChanges)
    // console.log('updatedFields', updatedFields)
    // console.log('pool', pool)
    const classes = useStyles();
    const dispatch = useDispatch();

    const updatedFields = useRef({});
    const submitForm = useRef(null);
    const { t } = useTranslation();
    const couponTypes = getCouponTypes()?.items;
    console.log('couponTypes', couponTypes)
    // console.log('deal?.deal', deal?.deal)
    //const poolDate = pool?.poolDate ? new Date(Localization.FormatDateTimeStringISO(pool.poolDate)) : null;// deal?.deal?.pool?.poolDate ? new Date(deal?.deal?.pool?.poolDate) : null;
    // console.log('poolDate!!!!!!!!!!!!!!!!!!!!!!!', poolDate, pool, deal)
    // const [poolDateField, setPoolDateField] = useState(poolDate);
    //const [formIsValid, setFormIsValid] = useState(false)
    //const [needRecalc, setNeedRecalc] = useState(false)


    //ActOfTransferDataPanel
    const [returnPercentsToOriginator, setReturnPercentsToOriginator] = useState(valueOrDefault(deal?.deal?.deliveryDayYieldToOriginator, false));
    const [partialPaymentAllowedAnytime, setPartialPaymentAllowedAnytime] = useState(valueOrDefault(deal?.deal?.partialPaymentAllowedAnytime, false));
    //const [iteration, setItertion] = useState({})
    //const [keyrateChartData, setKeyrateChartData] = useState(null);
    const [showKeyratePanel, setShowKeyratePanel] = useState(false)
    /*
    ManAccFloating	Вознаграждение УО и БО определяется коэффициентом
    ManAccCoef	Коэффициент вознаграждения УО и БО
    ManAccFixedSum	Фиксированная сумма вознаграждения, руб./квартал
    SettlementAgentYearly	Расчетный агент, руб. ежегодно
    SpecDepFixed	Возмещаемые расходы спец. депозитарию, руб./мес.
    ComissionMOEXYearly	Московская биржа, руб./год
    ComissionNSDQuarter	НРД, руб./квартал
    MarketMakerMonth	Маркет-мейкер, руб./мес.
    BankAccountPaymentsMonth	Банк счета сбора платежей, руб./мес.
    BankAccountIssuerAndAgentMonth	Банк счета эмитента и счета ипотечного покрытия, руб./мес.
     */

    //BondConstantExpensesPanel
    const [manAccFloating, setManAccFloating] = useState(valueOrDefault(deal?.deal?.manAccFloating, false));
    const [manAccCoef, setManAccCoef] = useState(valueOrDefault(deal?.deal?.manAccCoef, ''));
    const [manAccFixedSum, setManAccFixedSum] = useState(valueOrDefault(deal?.deal?.manAccFixedSum, ''));
    const [settlementAgentYearly, setSettlementAgentYearly] = useState(valueOrDefault(deal?.deal?.settlementAgentYearly, ''));
    const [specDepFixed, setSpecDepFixed] = useState(valueOrDefault(deal?.deal?.specDepFixed, ""));
    const [comissionMOEXYearly, setComissionMOEXYearly] = useState(valueOrDefault(deal?.deal?.comissionMOEXYearly, ""));
    const [comissionNSDQuarter, setComissionNSDQuarter] = useState(valueOrDefault(deal?.deal?.comissionNSDQuarter, ""));
    const [marketMakerMonth, setMarketMakerMonth] = useState(valueOrDefault(deal?.deal?.marketMakerMonth, ""));
    const [bankAccountPaymentsMonth, setBankAccountPaymentsMonth] = useState(valueOrDefault(deal?.deal?.bankAccountPaymentsMonth, ""));
    const [bankAccountIssuerAndAgentMonth, setBankAccountIssuerAndAgentMonth] = useState(valueOrDefault(deal?.deal?.bankAccountIssuerAndAgentMonth, ""));


    //DealDatesPanel
    //const [inclusionDebtDate, setInclusionDebtDate] = useState(deal?.deal?.inclusionDebtDate || '');
    const [inclusionDate, setInclusionDate] = useState(deal?.deal?.inclusionDate || '');
    const [fixationDate, setFixationDate] = useState(deal?.deal?.fixationDate || '');
    const [actDeliveryDate, setActDeliveryDate] = useState(deal?.deal?.actDeliveryDate || '');
    const [issueDate, setIssueDate] = useState(deal?.deal?.issueDate || '');
    const [secondPeriodStartDate, setSecondPeriodStartDate] = useState(deal?.deal?.secondPeriodStartDate || '');
    const [firstCouponDate, setFirstCouponDate] = useState(deal?.deal?.firstCouponDate || '');
    const [maturityDate, setMaturityDate] = useState(deal?.deal?.maturityDate || '');

    /*
    LoanServRate	Тариф основного сервиса с НДС, % год.
    LoanServMonthly	Тариф основного сервиса начисляется ежемесячно
    ResLoanServRate	Тариф резервного сервиса с НДС, % год.
    SpecDepRate	Тариф спец. депозитария, % год.
    SpecDepMin	Мин. вознаграждение спец. депозитария, руб./мес.
    InsuranceRate	Страховой тариф, % год.
    InsuranceFraction	Доля пуля, подлежащая страхованию, от 0 до 1
    */

    //BondTariffExpensesPanel
    const [loanServRate, setLoanServRate] = useState(valueOrDefault(deal?.deal?.loanServRate, ''));
    const [loanServMonthly, setLoanServMonthly] = useState(valueOrDefault(deal?.deal?.loanServMonthly, false));
    const [resLoanServRate, setResLoanServRate] = useState(valueOrDefault(deal?.deal?.resLoanServRate, ''));
    const [specDepRate, setSpecDepRate] = useState(valueOrDefault(deal?.deal?.specDepRate, ''));
    const [specDepMin, setSpecDepMin] = useState(valueOrDefault(deal?.deal?.specDepMin, ''));
    const [insuranceRate, setInsuranceRate] = useState(valueOrDefault(deal?.deal?.insuranceRate, ''));
    //const [insuranceFraction, setInsuranceFraction] = useState(valueOrDefault(deal?.deal?.insuranceFraction, ''));

    //BondParametersPanel
    const [couponType, setCouponType] = React.useState(deal?.deal?.couponType || CouponType.TYPE_FIXED);
    const [startIssueAmount, setStartIssueAmount] = React.useState(valueOrDefault(deal?.deal?.startIssueAmount, ''))
    const [nominal, setNominal] = React.useState(valueOrDefault(deal?.deal?.nominal, 1000))
    const [realCouponRate, setRealCouponRate] = useState(valueOrDefault(deal?.deal?.realCouponRate, ''));
    const [swapORIG, setSwapORIG] = useState(!!deal?.deal?.swapORIG);
    const [couponPeriod, setCouponPeriod] = React.useState(valueOrDefault(deal?.deal?.couponPeriod, ''));
    // const [ignoreSwapPayments, setIgnoreSwapPayments] = React.useState(valueOrDefault(deal?.deal?.ignoreSwapPayments, '')); //перенос в параметры оценки
    const [cleanUpCall, setCleanUpCall] = React.useState(valueOrDefault(deal?.deal?.cleanUpCall, ''));
    // const [compensationLagDays, setCompensationLagDays] = useState(valueOrDefault(deal?.deal?.compensationLagDays, 0)); //Уточнить подраздел у Паши //перенос в параметры оценки
    // const [lagDaysVisible, setLagDaysVisible] = useState(true) //перенос в параметры оценки
    // const [govProgrammsIsExist, setGovProgrammsIsExist] = useState(false)
    /*
        ComissionMOEXFirst	Московская биржа, руб. единовременно
        ComissionNSDFirst	НРД, руб. единовременно
        SettlementAgentFirst	Расчетный агент, руб. единовременно
        UnderwriterFirst	Андеррайтер эмитента, руб. единовременно
        */

    //BondOncedExpensesPanel
    const [comissionMOEXFirst, setComissionMOEXFirst] = useState(valueOrDefault(deal?.deal?.comissionMOEXFirst, ''));
    const [comissionNSDFirst, setComissionNSDFirst] = useState(valueOrDefault(deal?.deal?.comissionNSDFirst, ''));
    const [settlementAgentFirst, setSettlementAgentFirst] = useState(valueOrDefault(deal?.deal?.settlementAgentFirst, ''));
    const [underwriterFirst, setUnderwriterFirst] = useState(valueOrDefault(deal?.deal?.underwriterFirst, ''));

    //ExpectedDealsPanel
    //fields already have
    //const [actDeliveryDate, setActDeliveryDate] = useState(deal?.deal?.actDeliveryDate || '');
    //const [issueDate, setIssueDate] = useState(deal?.deal?.issueDate || '');
    //const [secondPeriodStartDate, setSecondPeriodStartDate] = useState(deal?.deal?.secondPeriodStartDate || '');
    //const [firstCouponDate, setFirstCouponDate] = useState(deal?.deal?.firstCouponDate || '');
    //const [maturityDate, setMaturityDate] = useState(deal?.deal?.maturityDate || '');

    /*
    PoolExpectedLoss	Ожидаемые потери EL, б.п.
    OPEX	Операционные расходы OPEX, б.п.
    PoolCapitalMargin	Маржа на капитал, б.п.
    PoolPrepaymentOption	Опцион досрочных погашений, б.п.
    PoolBankMargin	Маржа Банка ДОМ.РФ, б.п.
     */

    ///states
    const [poolExpectedLoss, setPoolExpectedLoss] = useState(valueOrDefault(deal?.deal?.poolExpectedLoss, ''));
    const [opex, setOPEX] = useState(valueOrDefault(deal?.deal?.opex, ''));
    const [poolCapitalMargin, setPoolCapitalMargin] = useState(valueOrDefault(deal?.deal?.poolCapitalMargin, ''));
    const [poolPrepaymentOption, setPoolPrepaymentOption] = useState(valueOrDefault(deal?.deal?.poolPrepaymentOption, ''));
    const [poolBankMargin, setPoolBankMargin] = useState(valueOrDefault(deal?.deal?.poolBankMargin, ''));

    const [holidayMonthsStart, setHolidayMonthsStart] = useState(valueOrDefault(deal?.deal?.holidayMonthsStart, ''));
    const [holidayDurationMonths, setHolidayDurationMonths] = useState(valueOrDefault(deal?.deal?.holidayDurationMonths, ''));
    const [holidaysFraction, setHolidaysFraction] = useState(valueOrDefault(deal?.deal?.holidaysFraction, ''));
    const [reinvestment, setReinvestment] = useState(valueOrDefault(deal?.deal?.reinvestment, false));
    const [expectedRUONIA, setExpectedRUONIA] = useState(valueOrDefault(deal?.deal?.expectedRUONIA, ''));
    const [deductionRUONIA, setDeductionRUONIA] = useState(valueOrDefault(deal?.deal?.deductionRUONIA, ''));
    const [stopAccrualDays, setStopAccrualDays] = useState(valueOrDefault(deal?.deal?.stopAccrualDays, ''));

    useLayoutEffect(() => {
        // useEffect(() => {
        // console.log('poolDate!!!!! iteeration', poolDate)
        // setPoolDateField(poolDate);

        //ActOfTransferDataPanel
        setReturnPercentsToOriginator(valueOrDefault(deal?.deal?.deliveryDayYieldToOriginator, false));
        setPartialPaymentAllowedAnytime(valueOrDefault(deal?.deal?.partialPaymentAllowedAnytime, false));

        //BondConstantExpensesPanel
        setManAccFloating(valueOrDefault(deal?.deal?.manAccFloating, false));
        setManAccCoef(valueOrDefault(deal?.deal?.manAccCoef, ''));
        setManAccFixedSum(valueOrDefault(deal?.deal?.manAccFixedSum, ''));
        setSettlementAgentYearly(valueOrDefault(deal?.deal?.settlementAgentYearly, ''));
        setSpecDepFixed(valueOrDefault(deal?.deal?.specDepFixed, ''));
        setComissionMOEXYearly(valueOrDefault(deal?.deal?.comissionMOEXYearly, ''));
        setComissionNSDQuarter(valueOrDefault(deal?.deal?.comissionNSDQuarter, ''));
        setMarketMakerMonth(valueOrDefault(deal?.deal?.marketMakerMonth, ''));
        setBankAccountPaymentsMonth(valueOrDefault(deal?.deal?.bankAccountPaymentsMonth, ''));
        setBankAccountIssuerAndAgentMonth(valueOrDefault(deal?.deal?.bankAccountIssuerAndAgentMonth, ''));

        //DealDatesPanel
        //setInclusionDebtDate(deal?.deal?.inclusionDebtDate || '');
        setInclusionDate(deal?.deal?.inclusionDate || '');
        setFixationDate(deal?.deal?.fixationDate || '');
        setActDeliveryDate(deal?.deal?.actDeliveryDate || '');
        setIssueDate(deal?.deal?.issueDate || '');
        setSecondPeriodStartDate(deal?.deal?.secondPeriodStartDate || '');
        setFirstCouponDate(deal?.deal?.firstCouponDate || '');
        setMaturityDate(deal?.deal?.maturityDate || '');

        //BondTariffExpensesPanel

        setLoanServRate(valueOrDefault(deal?.deal?.loanServRate, ''));
        setLoanServMonthly(valueOrDefault(deal?.deal?.loanServMonthly, false));
        setResLoanServRate(valueOrDefault(deal?.deal?.resLoanServRate, ''));
        setSpecDepRate(valueOrDefault(deal?.deal?.specDepRate, ''));
        setSpecDepMin(valueOrDefault(deal?.deal?.specDepMin, ''));
        setInsuranceRate(valueOrDefault(deal?.deal?.insuranceRate, ''));
        //setInsuranceFraction(valueOrDefault(deal?.deal?.insuranceFraction, ''));

        //BondParametersPanel
        setCouponType(valueOrDefault(deal?.deal?.couponType, ''));
        setStartIssueAmount(valueOrDefault(deal?.deal?.startIssueAmount, ''))
        setNominal(valueOrDefault(deal?.deal?.nominal, 1000))
        setRealCouponRate(valueOrDefault(deal?.deal?.realCouponRate, ''));
        setSwapORIG(valueOrDefault(!!deal?.deal?.swapORIG, false));

        setCouponPeriod(valueOrDefault(deal?.deal?.couponPeriod, ''));
        // setIgnoreSwapPayments(valueOrDefault(deal?.deal?.ignoreSwapPayments, ''));
        setCleanUpCall(valueOrDefault(deal?.deal?.cleanUpCall, ''));
        // setCompensationLagDays(valueOrDefault(deal?.deal?.compensationLagDays, 0))

        // setLagDaysVisible(deal?.deal?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM || ((deal?.deal?.validationState & efDealValidationState.STATE_KEYRATE_REQUIRED) != 0)
        //     /*Проверить условия отображения */
        //     // || selectedNode?.node?.preferenceFraction > 0
        //     // || selectedNode?.node?.familyFraction > 0
        //     /*Проверить условия отображения */
        // )

        //BondOncedExpensesPanel
        setComissionMOEXFirst(valueOrDefault(deal?.deal?.comissionMOEXFirst, ''));
        setComissionNSDFirst(valueOrDefault(deal?.deal?.comissionNSDFirst, ''));
        setSettlementAgentFirst(valueOrDefault(deal?.deal?.settlementAgentFirst, ''));
        setUnderwriterFirst(valueOrDefault(deal?.deal?.underwriterFirst, ''));

        //ExpectedDealsPanel
        //fields already have
        //setActDeliveryDate(deal?.deal?.actDeliveryDate || '');
        //setIssueDate(deal?.deal?.issueDate || '');
        //setSecondPeriodStartDate(deal?.deal?.secondPeriodStartDate || '');
        //setFirstCouponDate(deal?.deal?.firstCouponDate || '');
        //setMaturityDate(deal?.deal?.maturityDate || '');

        setPoolExpectedLoss(valueOrDefault(deal?.deal?.poolExpectedLoss, ''));
        setOPEX(valueOrDefault(deal?.deal?.opex, ''));
        setPoolCapitalMargin(valueOrDefault(deal?.deal?.poolCapitalMargin, ''));
        setPoolPrepaymentOption(valueOrDefault(deal?.deal?.poolPrepaymentOption, ''));
        setPoolBankMargin(valueOrDefault(deal?.deal?.poolBankMargin, ''));

        //updateFieldsVisibility();

        setHolidayMonthsStart(valueOrDefault(deal?.deal?.holidayMonthsStart, ''));
        setHolidayDurationMonths(valueOrDefault(deal?.deal?.holidayDurationMonths, ''));
        setHolidaysFraction(valueOrDefault(deal?.deal?.holidaysFraction, ''));
        setReinvestment(valueOrDefault(deal?.deal?.reinvestment, false));
        setExpectedRUONIA(valueOrDefault(deal?.deal?.expectedRUONIA, ''));
        setDeductionRUONIA(valueOrDefault(deal?.deal?.deductionRUONIA, ''));
        setStopAccrualDays(valueOrDefault(deal?.deal?.stopAccrualDays, ''));

        // console.log('deal?.deal', deal?.deal)
        // console.log('keyRateIsInvalid && ((deal?.deal?.validationState & efDealValidationState.STATE_KEYRATE_REQUIRED) == 0)', keyRateIsInvalid , '((',deal?.deal?.validationState,' & ', efDealValidationState.STATE_KEYRATE_REQUIRED,') == 0)')
        //CHECKME!!! removed as obsolete, need to check
        //if (keyRateIsInvalid && ((deal?.deal?.validationState & efDealValidationState.STATE_KEYRATE_REQUIRED) == 0)) {
        //    var state = deal?.deal?.validationState | efDealValidationState.STATE_KEYRATE_REQUIRED;
        //    updateDealValidationState(deal.deal.id, state)
        //}

        if (deal.deal?.keyRateGroup != null) {
            var chartData = deal.deal?.keyRateGroup.keyRates.map(x => { return { x: toUnixDateGMT(new Date(x.date)), y: x.key } });
            actionSecuritization.setKeyrates(chartData)(dispatch);
        }
        else
            actionSecuritization.setKeyrates(null)(dispatch);

        // console.log("-------------------deal changed!");

        handleFormValidation();

    }, [deal?.deal, selectedNode]);

    //CHECKME!!! obsolete, need to check
    //useEffect(() => {
    //    if (keyRateIsInvalid && ((deal?.deal?.validationState & efDealValidationState.STATE_KEYRATE_REQUIRED) == 0)) {
    //        var state = deal?.deal?.validationState | efDealValidationState.STATE_KEYRATE_REQUIRED;
    //        // actionSecuritization.setDealField({ validationState: state })(dispatch);
    //        // actionSecuritization.updateDealLight({ ID: deal?.deal?.id, ValidationState: { Value: state } })(dispatch); //update fiend in BD
    //
    //        updateDealValidationState(deal.deal.id, state)
    //    }
    //}, [deal?.deal?.dataState])

    //useEffect(() => {
    //the form covers all the tabs and tab switching not causes to re-validate existed data. We do it manually
    //submitForm?.current?.isFormValid(false, true);
    //handleFormValidation();

    //}, [deal])

    const handleFormValidation = debounce(700, async () => {

        //console.log("------------manual run form validation");

        await submitForm.current?.isFormValid(false, true);
    });

    const updateDealValidationState = (dealId: any = null, dealState: any) => {

        //console.log("------------updateDealValidationState", dealState);

        if (dealId == null)
            throw "Deal Id required"

        actionSecuritization.setDealField({ validationState: dealState })(dispatch);
        actionSecuritization.updateDealLight({ ID: dealId, ValidationState: { Value: dealState } })(dispatch); //update fiend in BD
    }

    const updateField = (name, setValueFn, value) => {

        console.log(`----------UPDATE FIELD, name: ${name} value: ${value}`);

        //datetime fields does not generates blur() because of modal input
        var field = null;
        for (var i = 0; i < groups.length; i++) {
            field = groups[i].fields.find(x => { if (x != null) return x.name == name });
            if (field != null)
                break;
        }

        //first, update fields
        setValueFn(value);
        onUpdateField(name, value /*, bIsForceToUpdate*/);


        //next, update dependencies
        if (name == "reinvestment") {
            if (!value) {
                let update = { DealID: deal.deal.id, DealFields: ["ExpectedRUONIA", "DeductionRUONIA", "StopAccrualDays"] };
                // console.log('update RUONIA', update)
                actionSecuritization.removeOnlineChanges(update)(dispatch);

                var state = deal?.deal?.validationState & ~efDealValidationState.STATE_KEYRATE_REQUIRED;
                updateDealValidationState(deal.deal.id, state)
                // actionSecuritization.setDealField({ validationState: state })(dispatch);
            }
        }
        // var bIsForceToUpdate = false;
        // if (field)
        //     bIsForceToUpdate = field.dataType == InputDataType.DATE || field.dataType == InputDataType.DATE_TIME || field.dataType == InputDataType.DATE_TIME_UTC;
        if (name == "couponType") {

            if (value != CouponType.TYPE_KEYRATE_AND_PREMIUM && ((deal?.deal?.dataState & efDealDataState.STATE_KEYRATE_CHART_DATA_REQUIRED) == 0)) {

                //cleanup
                //var update = { DealID: deal.deal.id, KeyRates: { Value: null } };
                let update = { DealID: deal.deal.id, DealFields: ["KeyRates"], NodeFields: ["RequiredIncrement"] };
                // console.log('update Deal', update)
                actionSecuritization.removeOnlineChanges(update)(dispatch);


                //actionSecuritization.sendDealChange(update)(dispatch);
                actionSecuritization.setKeyrates(null)(dispatch);

                var state = deal?.deal?.validationState & ~efDealValidationState.STATE_KEYRATE_REQUIRED;
                // actionSecuritization.setDealField({ validationState: state })(dispatch);
                updateDealValidationState(deal.deal.id, state)
            }
        }

        //setValueFn(value);
        //onUpdateField(name, value /*, bIsForceToUpdate*/);
    }

    const CheckDateLimits = (value) => {
        // console.log('value', value)
        return (
            (dateLimits.minDate != null && new Date(dateLimits.minDate)?.getTime() < new Date(value)?.getTime())
            ||
            (dateLimits.maxDate != null && new Date(dateLimits.maxDate)?.getTime() > new Date(value)?.getTime())
        )
    }

    const couponPeriods = [
        { key: 1, value: '1' },
        { key: 3, value: '3' }
    ];

    // const ignoreSwapPaymentsCalls = [
    //     { key: 1, value: '1' },
    //     { key: 2, value: '2' },
    //     { key: 3, value: '3' },
    //     { key: 4, value: '4' },
    //     { key: 5, value: '5' },
    // ];

    // const cleanUpCalls = [
    //     { key: 5, value: '5' },
    //     { key: 10, value: '10' },
    //     { key: 12, value: '12' },
    //     { key: 15, value: '15' },
    //     { key: 20, value: '20' },
    // ];

    const currentDate = new Date();


    const dateLimits = {
        minDate: new Date(2016, 10, 17), // 11 - декабрь // null, //for now
        maxDate: new Date(currentDate.getFullYear() + 30, currentDate.getMonth(), currentDate.getDate()), //+15 лет к тукущей дате
    }

    /*
    const mustBeGreaterThanPoolDate = (value: string) => {
        return poolDate == null || Date.parse(value) > poolDate.getTime();
    }
    */

    const checkValidMonthDate = (value: string) => {
        var date = new Date(value);
        if (isValid(date))
            return (date.getDate() >= 1 && date.getDate() <= 28);
    }

    const mustBe1stDay = (value: string) => {
        var date = new Date(value);
        if (isValid(date))
            return (date.getDate() == 1);
    }

    const mainDatesNotFilled = () => {
        return actDeliveryDate == null || issueDate == null || firstCouponDate == null || maturityDate == null;
    }

    /*Проверить условия отображения иконок */
    // console.log('>>>>>>>>>>>>>>>>>>deal?.deal?.dataState<<<<<<<<<<<<<<<<<<<<<<<', deal?.deal?.dataState)
    const showKeyRate = deal?.deal?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM || ((deal?.deal?.dataState & efDealDataState.STATE_KEYRATE_CHART_DATA_REQUIRED) == 1) /*((deal?.deal?.validationState & efDealValidationState.STATE_KEYRATE_REQUIRED) != 0); /*|| pool?.preferenceFraction > 0 || pool?.familyFraction > 0*/
    const keyRateIsInvalid = (showKeyRate && deal.deal?.keyRateGroupID == null && updatedFields.current?.keyRates == null);
    //console.log(`>>>>>>>>>>>>>>>>>>keyRateIsInvalid = ${keyRateIsInvalid}, deal.deal?.keyRateGroupID = ${deal.deal?.keyRateGroupID}, updatedFields.current?.keyRates = ${updatedFields.current?.keyRates}`)

    // console.log('(showKeyRate && deal.deal?.keyRateGroupID == null && updatedFields.current?.keyRates == null);', '(', showKeyRate, '&&', deal.deal?.keyRateGroupID == null, '&&', updatedFields.current?.keyRates == null, ')')
    // console.log('keyRateIsInvalid', keyRateIsInvalid)
    // const keyRateIsInvalid = (deal?.deal?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM && deal.deal?.keyRateGroupID == null && updatedFields.current?.keyRates == null);
    // const showKeyRate = deal?.deal?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM || ((deal?.deal?.validationState & efDealValidationState.STATE_KEYRATE_REQUIRED) != 0);

    /*Проверить условия отображения иконок */
    const KeyRateButton = ({ keyRateIsInvalid }) => {

        // console.log('keyRateIsInvalid!!!!!!!!!!!!!!!!!!', keyRateIsInvalid)
        if (!showKeyRate) return

        return (
            <Box className={classes.keyrateButtonsBlock}>
                {keyRateIsInvalid &&
                    <Tooltip title={t("KeyrateGroupEmptyWarning")}>
                        <WarningAmber className={[classes.errorMessage, classes.keyrateButtonsIcon].join(' ')} />
                    </Tooltip>}
                <Tooltip title={t('KeyrateChartButtonTooltip')} disableInteractive>
                    <IconButton onClick={() => setShowKeyratePanel(true)} size='small'>
                        <InsightsRounded className={clsx(classes.keyrateButtonsIcon, keyRateIsInvalid ? classes.keyRateIsInvalid : "", Object.hasOwn(updatedFields?.current, "keyRates") ? classes.changedButton : "")} htmlColor={style.colorPrimary1} />
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }

    const KeyratePanel = () => {

        // if (!localIteration || !isValid(parseISO(localIteration.zcycDate))) {
        //     return (
        //         <Grid item xs={12} className={classes.tabContainerNoData}>
        //             <h4 className={classes.labelColor}>{t("FillZCYCDateAtParameters")}</h4>
        //         </Grid>
        //     )
        // }
        if (showKeyratePanel == false)
            return


        const onApplyKeyRate = (data: any) => {

            var rates = data.editModel.map(x => { return { Date: x.x, Key: x.y }; });
            var keyRates = JSON.stringify(rates);

            // console.log('keyRates ', keyRates)
            // console.log('updatedFields', updatedFields)
            // updatedFields.current.keyRates = { ...updatedFields?.current?.keyRates ?? {}, newValue: keyRates }; //stub to show changes at UI, full update will receive from hub
            // var iterationKeyrates = { ...localIteration.keyRateGroup };
            // iterationKeyrates.keyRates = [...localIteration?.keyRateGroup?.keyRates ?? []];

            // mergeKeyrates(data.editModel, iterationKeyrates.keyRates);

            // setLocalIteration({ ...localIteration, keyRateGroup: iterationKeyrates });

            var update = { DealID: deal.deal.id, keyRates: { Value: keyRates } };

            // //we collect all changes
            // //Object.keys(updatedFields.current).forEach(name => update[name] = { Value: updatedFields.current[name].newValue });
            // console.log("onApplyKeyRate => send iteration change");
            // console.log(update);
            // setDealState(state => state &= ~DealValidationState.STATE_HISTORICAL_KEYRATE_OUTDATED);

            //CHECKME!!! updates at sendDealChange
            //if (!keyRateIsInvalid) {
            //    var state = deal?.deal?.validationState & ~efDealValidationState.STATE_KEYRATE_REQUIRED;
            //    actionSecuritization.setDealField({ validationState: state })(dispatch);
            //    // update['ValidationState'] = { Value: state } //update fiend in BD
            //    updateDealValidationState(deal.deal.id, state)
            //}

            actionSecuritization.sendDealChange(update)(dispatch);
        }

        const editPermission = true

        return (
            // <Popper className={classes.backDrop} disablePortal={false} open={showKeyratePanel}>
            <Backdrop open={showKeyratePanel} className={classes.backDrop} onClick={() => setShowKeyratePanel(false)}>
                <Paper className={classes.popupPaper} onClick={e => e.stopPropagation()}>
                    <IconButton className={classes.popupPaperCloseElement} onClick={() => setShowKeyratePanel(false)}>
                        <Close className={classes.popupPaperCloseElementIcon} />
                    </IconButton>
                    {/* Attention message here*/}
                    {keyRateIsInvalid && <Box><WarningAmber className={classes.errorMessage} />{t("KeyrateGroupEmptyWarning")}</Box>}
                    {
                        // <Paper className={classes.paper}>
                        <KeyRatePanel
                            keyrateChartData={deal?.keyRates}
                            //zcycDate={new Date(localIteration.zcycDate)}
                            //evaluationDate={new Date(localIteration.zcycDate)}
                            isEditable={editPermission}
                            isTrackChanges={!keyRateIsInvalid}
                            //onLoaded={onApplyLoadedKeyRate}
                            onApply={onApplyKeyRate} />
                        // </Paper>
                    }
                </Paper>
            </Backdrop>
            // </Popper>
        )
    }

    const formEditFields = (): any[] => {

        if (deal?.deal?.type == null)
            return [];

        const pricingParameters = {
            name: 'PricingParametersPanel', label: t('PricingParameters'), fields: [

                { name: 'poolExpectedLoss', label: t('PoolExpectedLoss'), tooltip: t('PoolExpectedLoss_Info'), value: poolExpectedLoss, updateValue: setPoolExpectedLoss, badgeContent: deal?.dealChanges?.poolExpectedLoss, dataType: InputDataType.FLOAT },
                { name: 'opex', label: t('OPEX'), tooltip: t('OPEX_Info'), value: opex, updateValue: setOPEX, badgeContent: deal?.dealChanges?.opex, dataType: InputDataType.FLOAT },
                { name: 'poolCapitalMargin', label: t('PoolCapitalMargin'), tooltip: t('PoolCapitalMargin_Info'), value: poolCapitalMargin, updateValue: setPoolCapitalMargin, badgeContent: deal?.dealChanges?.poolCapitalMargin, dataType: InputDataType.FLOAT },
                { name: 'poolPrepaymentOption', label: t('PoolPrepaymentOption'), tooltip: t('PoolPrepaymentOption_Info'), value: poolPrepaymentOption, updateValue: setPoolPrepaymentOption, badgeContent: deal?.dealChanges?.poolPrepaymentOption, dataType: InputDataType.FLOAT },
                { name: 'poolBankMargin', label: t('PoolBankMargin'), tooltip: t('PoolBankMargin_Info'), value: poolBankMargin, updateValue: setPoolBankMargin, badgeContent: deal?.dealChanges?.poolBankMargin, dataType: InputDataType.FLOAT }
            ]
        };


        // console.log('poolDateField !!! 1', poolDateField)
        const expectedDealDates = {
            name: 'ExpectedDealDatesPanel', label: t('Dates'), fields: [

                //дата пула
                // { name: 'poolDate', label: t('PoolSlaceDate'), tooltip: t('PoolDate_tooltip'), value: poolDateField, dataType: InputDataType.DATE, readOnly: true, },

                //Дата передачи закладных
                { name: 'actDeliveryDate', label: t('ActDeliveryDate'), tooltip: t('ActDeliveryDate_tooltip'), value: actDeliveryDate, updateValue: setActDeliveryDate, badgeContent: deal?.iterationChanges?.actDeliveryDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits], errorMessages: [t('FieldRequired'), '*Date is out of range*'] } },
                //Дата размещения ИЦБ
                { name: 'issueDate', label: t('IssueDate'), tooltip: t('IssueDate_tooltip'), value: issueDate, updateValue: setIssueDate, badgeContent: deal?.iterationChanges?.issueDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits], errorMessages: [t('FieldRequired'), '*Date is out of range*'] } },
                //Дата начала второго расч. периода
                { name: 'secondPeriodStartDate', label: t('SecondPeriodStartDate'), tooltip: t('SecondPeriodStartDate_tooltip'), value: secondPeriodStartDate, updateValue: setSecondPeriodStartDate, badgeContent: deal?.iterationChanges?.secondPeriodStartDate, dataType: InputDataType.DATE, validators: { validators: ['required'], errorMessages: [t('FieldRequired')] } },
                //Дата выплаты первого купона
                { name: 'firstCouponDate', label: t('FirstCouponDate'), tooltip: t('FirstCouponDate_tooltip'), value: firstCouponDate, updateValue: setFirstCouponDate, badgeContent: deal?.iterationChanges?.firstCouponDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits, checkValidMonthDate], errorMessages: [t('FieldRequired'), '*Date is out of range*', t('DateMustBeBetween1and28')] } },
                //Срок до погашения (WA), мес
                { name: 'maturityDate', label: t('MaturityDate'), tooltip: t('MaturityDate_tooltip'), value: maturityDate, updateValue: setMaturityDate, badgeContent: deal?.iterationChanges?.maturityDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits], errorMessages: [t('FieldRequired'), '*Date is out of range*'] } }
            ]
        };
        // console.log('poolDateField !!! 2', poolDateField)
        const dealDates = {
            name: 'DealDatesPanel', label: t('Dates'), fields: [
                //дата пула
                // { name: 'poolDate', label: t('PoolSlaceDate'), tooltip: t('PoolDate_tooltip'), value: poolDateField, dataType: InputDataType.DATE, readOnly: true, customProps: { disabled: true }, },

                //Дата актуализации ОСЗ
                //{ name: 'inclusionDebtDate', label: t('InclusionDebtDate'), tooltip: t('InclusionDebtDate_tooltip'), value: inclusionDebtDate, updateValue: setInclusionDebtDate, badgeContent: deal?.iterationChanges?.inclusionDebtDate, dataType: InputDataType.DATE, validators: { validators: [mustBeGreaterThanPoolDate], errorMessages: [t('ValueMustBeGreaterOrEqualThanReportDate')] }, disabled: mainDatesNotFilled() },
                //Дата включения в ИП
                { name: 'inclusionDate', label: t('InclusionDate'), tooltip: t('InclusionDate_tooltip'), value: inclusionDate, updateValue: setInclusionDate, badgeContent: deal?.iterationChanges?.inclusionDate, dataType: InputDataType.DATE, disabled: mainDatesNotFilled(), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] } },
                //Дата подписания решения
                { name: 'fixationDate', label: t('FixationDate'), tooltip: t('FixationDate_tooltip'), value: fixationDate, updateValue: setFixationDate, badgeContent: deal?.iterationChanges?.fixationDate, dataType: InputDataType.DATE, disabled: mainDatesNotFilled(), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] } },
                //Дата передачи закладных
                { name: 'actDeliveryDate', label: t('ActDeliveryDate'), tooltip: t('ActDeliveryDate_tooltip'), value: actDeliveryDate, updateValue: setActDeliveryDate, badgeContent: deal?.iterationChanges?.actDeliveryDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits], errorMessages: [t('FieldRequired'), '*Date is out of range*'] } },
                //Дата размещения ИЦБ
                { name: 'issueDate', label: t('IssueDate'), tooltip: t('IssueDate_tooltip'), value: issueDate, updateValue: setIssueDate, badgeContent: deal?.iterationChanges?.issueDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits], errorMessages: [t('FieldRequired'), '*Date is out of range*'] } },
                //Дата начала второго расч. периода
                { name: 'secondPeriodStartDate', label: t('SecondPeriodStartDate'), tooltip: t('SecondPeriodStartDate_tooltip'), value: secondPeriodStartDate, updateValue: setSecondPeriodStartDate, badgeContent: deal?.iterationChanges?.secondPeriodStartDate, dataType: InputDataType.DATE, validators: { validators: [CheckDateLimits, mustBe1stDay], errorMessages: ['*Date is out of range*', t('MustBeTheFirstDayOfMonth')] }, disabled: mainDatesNotFilled() },
                //Дата выплаты первого купона
                { name: 'firstCouponDate', label: t('FirstCouponDate'), tooltip: t('FirstCouponDate_tooltip'), value: firstCouponDate, updateValue: setFirstCouponDate, badgeContent: deal?.iterationChanges?.firstCouponDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits, checkValidMonthDate], errorMessages: [t('FieldRequired'), '*Date is out of range*', t('DateMustBeBetween1and28')] } },
                //Срок до погашения (WA), мес
                { name: 'maturityDate', label: t('LegalMaturityDate'), tooltip: t('LegalMaturityDate_tooltip'), value: maturityDate, updateValue: setMaturityDate, badgeContent: deal?.iterationChanges?.maturityDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits], errorMessages: [t('FieldRequired'), '*Date is out of range*'] } },
            ]
        };
        // console.log('poolDateField !!! 3', poolDateField)
        const actOfTransferData = {
            name: 'ActOfTransferDataPanel', label: t('AnotherPerametrs'), fields: [
                //ActOfTransferDataPanel
                { name: 'deliveryDayYieldToOriginator', label: t('DeliveryDayYieldToOriginator'), tooltip: t('DeliveryDayYieldToOriginator_tooltip'), value: returnPercentsToOriginator, updateValue: setReturnPercentsToOriginator, badgeContent: deal?.iterationChanges?.deliveryDayYieldToOriginator, dataType: InputDataType.BOOLEAN },

                { name: 'partialPaymentAllowedAnytime', label: t('PartialPaymentAllowedAnytime'), tooltip: t('PartialPaymentAllowedAnytime_tooltip'), value: partialPaymentAllowedAnytime, updateValue: setPartialPaymentAllowedAnytime, badgeContent: deal?.iterationChanges?.partialPaymentAllowedAnytime, dataType: InputDataType.BOOLEAN },

            ]
        };

        const bondParameters = {
            name: 'BondParametersPanel', label: t('ReleaseParameters'), fields: [
                //BondParametersPanel
                { name: 'couponType', label: t('CouponType'), startButton: KeyRateButton({ keyRateIsInvalid }), tooltip: t('CouponType_tooltip'), value: couponType, updateValue: setCouponType, badgeContent: deal?.iterationChanges?.couponType, dataType: InputDataType.ENUM, dataSource: couponTypes },
                { name: 'couponPeriod', unit: "months", label: t('CouponPeriod'), tooltip: t('CouponPeriod_tooltip'), value: couponPeriod, updateValue: setCouponPeriod, badgeContent: deal?.iterationChanges?.couponPeriod, dataType: InputDataType.ENUM, dataSource: couponPeriods },
                { name: 'startIssueAmount', unit: "rubSign", label: t('StartIssueAmountParametr'), tooltip: t('StartIssueAmount_tooltip'), value: startIssueAmount, updateValue: setStartIssueAmount, badgeContent: deal?.iterationChanges?.startIssueAmount, dataType: InputDataType.FLOAT, /*validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }*/ },
                { name: 'nominal', unit: "rubSign", label: t('BondNominal'), tooltip: t('BondNominal_tooltip'), value: nominal, updateValue: setNominal, badgeContent: deal?.iterationChanges?.nominal, dataType: InputDataType.STRING, readOnly: true },

                couponType !== CouponType.TYPE_FLOAT ?
                    { name: 'realCouponRate', unit: "percentInYear", label: couponType === CouponType.TYPE_KEYRATE_AND_PREMIUM ? t('PremiumValue') : t('CouponRate'), tooltip: couponType === CouponType.TYPE_KEYRATE_AND_PREMIUM ? t('RealCouponRate2_tooltip') : t('RealCouponRate1_tooltip'), value: realCouponRate, updateValue: setRealCouponRate, badgeContent: deal?.iterationChanges?.realCouponRate, dataType: InputDataType.FLOAT, validators: { validators: [/*'required',*/ 'minFloat:0.0', 'maxFloat:20.0'], errorMessages: [/*t('FieldRequired'),*/ stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 20.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 20.0])] } }
                    : null,

                couponType !== CouponType.TYPE_FLOAT ?
                    {
                        name: 'swapORIG', label: t('SwapORIG'),
                        tooltip: t(couponType == CouponType.TYPE_FIXED ? 'SwapORIG_fixed_tooltip' : 'SwapORIG_keyrate_tooltip'),
                        value: swapORIG, updateValue: setSwapORIG, badgeContent: deal.iterationChanges?.swapORIG, dataType: InputDataType.BOOLEAN
                    }
                    : null,
                // { name: 'ignoreSwapPayments', unit: 'securitization.pcs', label: t('securitization.ignoreSwapPayments'), tooltip: t('securitization.ignoreSwapPayments_tooltip'), value: ignoreSwapPayments, updateValue: setIgnoreSwapPayments, badgeContent: deal?.iterationChanges?.ignoreSwapPayments, dataType: InputDataType.ENUM, dataSource: ignoreSwapPaymentsCalls },
                { name: 'cleanUpCall', unit: 'percent', label: t('CleanUpCall'), tooltip: t('CleanUpCall_tooltip'), value: cleanUpCall, updateValue: setCleanUpCall, badgeContent: deal?.iterationChanges?.cleanUpCall, dataType: InputDataType.FLOAT, validators: { validators: ['required', 'minFloat:0.0', 'maxFloat:50.0'], errorMessages: [t('FieldRequired'), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 50]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 50])] } },
                // { name: 'compensationLagDays', unit: "days", label: t('CompensationLagDays'), tooltip: t('CompensationLagDays_tooltip'), visible: deal?.governProgramsExists ?? false, value: compensationLagDays, updateValue: setCompensationLagDays, badgeContent: deal?.iterationChanges?.compensationLagDays, dataType: InputDataType.FLOAT, validators: { validators: ['required', 'minFloat:0.0', 'maxFloat:150.0'], errorMessages: [t('FieldRequired'), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 150]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 150])] } }
            ]
        };

        const bondPublishFields = [

            dealDates,
            bondParameters,

            {
                name: 'BondTariffExpensesPanel', label: t('TariffExpenses'), fields: [
                    //BondTariffExpensesPanel
                    //LoanServRate	Тариф основного сервиса с НДС, % год.
                    { name: 'loanServRate', unit: 'percentInYear', label: t('LoanServiceRate'), tooltip: t('LoanServRate_tooltip'), value: loanServRate, updateValue: setLoanServRate, badgeContent: deal?.iterationChanges?.loanServRate, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:1.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1.0])] } },

                    couponPeriod != 1 ?
                        //LoanServMonthly	Тариф основного сервиса начисляется ежемесячно
                        { name: 'loanServMonthly', label: t('LoanServMonthly'), tooltip: t('LoanServMonthly_tooltip'), value: loanServMonthly, updateValue: setLoanServMonthly, badgeContent: deal?.iterationChanges?.loanServMonthly, dataType: InputDataType.BOOLEAN }
                        : null,

                    //ResLoanServRate	Тариф резервного сервиса с НДС, % год.
                    { name: 'resLoanServRate', unit: 'percentInYear', label: t('ResLoanServRate'), tooltip: t('ResLoanServRate_tooltip'), value: resLoanServRate, updateValue: setResLoanServRate, badgeContent: deal?.iterationChanges?.resLoanServRate, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:1.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1.0], stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1.0]))] } },

                    //SpecDepRate	Тариф спец. депозитария, % год.
                    { name: 'specDepRate', unit: 'percentInYear', label: t('SpecDepRate'), tooltip: t('SpecDepRate_tooltip'), value: specDepRate, updateValue: setSpecDepRate, badgeContent: deal?.iterationChanges?.specDepRate, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:1.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1.0])] } },

                    //SpecDepMin	Мин.вознаграждение спец.депозитария, руб./ мес.
                    { name: 'specDepMin', unit: 'rubPerMonth', label: t('SpecDepMin'), tooltip: t('SpecDepMin_tooltip'), value: specDepMin, updateValue: setSpecDepMin, badgeContent: deal?.iterationChanges?.specDepMin, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:500000.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 500000]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 500000])] } },

                    //SpecDepFixed	Возмещаемые расходы спец. депозитарию, руб./мес.
                    { name: 'specDepFixed', unit: 'rubPerMonth', label: t('SpecDepFixed'), tooltip: t('SpecDepFixed_tooltip'), value: specDepFixed, updateValue: setSpecDepFixed, badgeContent: deal?.iterationChanges?.specDepFixed, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:10000000'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 10000000]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 10000000])] } },

                    //InsuranceRate	Страховой тариф, % год.
                    { name: 'insuranceRate', unit: 'percentInYear', label: t('InsuranceRate'), tooltip: t('InsuranceRate_tooltip1'), value: insuranceRate, updateValue: setInsuranceRate, badgeContent: deal?.iterationChanges?.insuranceRate, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:1.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1.0])] } }

                    //InsuranceFraction	Доля пуля, подлежащая страхованию, от 0 до 1
                    //{ name: 'insuranceFraction', label: t('InsuranceFraction'), tooltip: t('InsuranceFraction_Info'), value: insuranceFraction, updateValue: setInsuranceFraction, badgeContent: deal?.iterationChanges?.insuranceFraction, dataType: InputDataType.FLOAT },
                ]
            },

            {
                name: 'BondOncedExpensesPanel', label: t('BondOncedExpenses'), fields: [
                    //BondOncedExpensesPanel
                    //ComissionMOEXFirst	Московская биржа, руб. единовременно
                    { name: 'comissionMOEXFirst', unit: 'rubSign', label: t('ComissionMOEXFirst'), tooltip: t('ComissionMOEXFirst_tooltip'), value: comissionMOEXFirst, updateValue: setComissionMOEXFirst, badgeContent: deal?.iterationChanges?.comissionMOEXFirst, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:50000000.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 50000000.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 50000000.0])] } },
                    //ComissionNSDFirst	НРД, руб. единовременно
                    { name: 'comissionNSDFirst', unit: 'rubSign', label: t('ComissionNSDFirst'), tooltip: t('ComissionNSDFirst_tooltip'), value: comissionNSDFirst, updateValue: setComissionNSDFirst, badgeContent: deal?.iterationChanges?.comissionNSDFirst, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:10000000.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 10000000.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 10000000.0])] } },
                    //SettlementAgentFirst	Расчетный агент, руб. единовременно
                    { name: 'settlementAgentFirst', unit: 'rubSign', label: t('SettlementAgentFirst'), tooltip: t('SettlementAgentFirst_tooltip'), value: settlementAgentFirst, updateValue: setSettlementAgentFirst, badgeContent: deal?.iterationChanges?.settlementAgentFirst, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:5000000.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 5000000.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 5000000.0])] } },
                    //UnderwriterFirst	Андеррайтер эмитента, руб. единовременно
                    { name: 'underwriterFirst', unit: 'rubSign', label: t('UnderwriterFirst'), tooltip: t('UnderwriterFirst_tooltip'), value: underwriterFirst, updateValue: setUnderwriterFirst, badgeContent: deal?.iterationChanges?.underwriterFirst, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:5000000.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 5000000.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 5000000.0])] } }
                ]
            },

            {
                name: 'BondConstantExpensesPanel', label: t('ConstantExpenses'), fields: [
                    //BondConstantExpensesPanel
                    //ManAccFloating	Вознаграждение УО и БО определяется коэффициентом
                    { name: 'manAccFloating', label: t('CalculatedAccFloating'), tooltip: t('ManAccFloating_tooltip'), value: manAccFloating, updateValue: setManAccFloating, badgeContent: deal?.iterationChanges?.manAccFloating, dataType: InputDataType.BOOLEAN },
                    //ManAccCoef	Коэффициент вознаграждения УО и БО
                    { name: 'manAccCoef', label: t('ManAccCoef'), tooltip: t('ManAccCoef_tooltip'), value: manAccCoef, updateValue: setManAccCoef, badgeContent: deal?.iterationChanges?.manAccCoef, dataType: InputDataType.FLOAT, visible: manAccFloating, validators: { validators: ['minFloat:0.0', 'maxFloat:0.01'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 0.01]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 0.01])] } },
                    //ManAccFixedSum	Фиксированная сумма вознаграждения, руб./квартал
                    { name: 'manAccFixedSum', unit: 'rubPerQuartal', label: t('ManAccFixedSum'), tooltip: t('ManAccFixedSum_tooltip'), value: manAccFixedSum, updateValue: setManAccFixedSum, badgeContent: deal?.iterationChanges?.manAccFixedSum, dataType: InputDataType.FLOAT, visible: !manAccFloating, validators: { validators: ['minFloat:0.0', 'maxFloat:1000000'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000])] } },
                    //SettlementAgentYearly	Расчетный агент, руб. ежегодно
                    { name: 'settlementAgentYearly', unit: 'rubPerYear', label: t('SettlementAgentYearly'), tooltip: t('SettlementAgentYearly_tooltip'), value: settlementAgentYearly, updateValue: setSettlementAgentYearly, badgeContent: deal?.iterationChanges?.settlementAgentYearly, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:5000000'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 5000000]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 5000000])] } },
                    //ComissionMOEXYearly	Московская биржа, руб./год
                    { name: 'comissionMOEXYearly', unit: 'rubPerYear', label: t('ComissionMOEXYearly'), tooltip: t('ComissionMOEXYearly_tooltip'), value: comissionMOEXYearly, updateValue: setComissionMOEXYearly, badgeContent: deal?.iterationChanges?.comissionMOEXYearly, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:1000000'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000])] } },
                    //ComissionNSDQuarter	НРД, руб./квартал
                    { name: 'comissionNSDQuarter', unit: 'rubPerQuartal', label: t('ComissionNSDQuarter'), tooltip: t('ComissionNSDQuarter_tooltip'), value: comissionNSDQuarter, updateValue: setComissionNSDQuarter, badgeContent: deal?.iterationChanges?.comissionNSDQuarter, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:1000000'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000])] } },
                    //MarketMakerMonth	Маркет-мейкер, руб./мес.
                    { name: 'marketMakerMonth', unit: 'rubPerMonth', label: t('MarketMakerMonth'), tooltip: t('MarketMakerMonth_tooltip'), value: marketMakerMonth, updateValue: setMarketMakerMonth, badgeContent: deal?.iterationChanges?.marketMakerMonth, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:1000000'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000])] } },
                    //BankAccountPaymentsMonth	Банк счета сбора платежей, руб./мес.
                    { name: 'bankAccountPaymentsMonth', unit: 'rubPerMonth', label: t('BankAccountPaymentsMonth'), tooltip: t('BankAccountPaymentsMonth_tooltip'), value: bankAccountPaymentsMonth, updateValue: setBankAccountPaymentsMonth, badgeContent: deal?.iterationChanges?.bankAccountPaymentsMonth, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:1000000'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000])] } },
                    //BankAccountIssuerAndAgentMonth	Банк счета эмитента и счета ипотечного покрытия, руб./мес.
                    { name: 'bankAccountIssuerAndAgentMonth', unit: 'rubPerMonth', label: t('BankAccountIssuerAndAgentMonth'), tooltip: t('BankAccountIssuerAndAgentMonth_tooltip'), value: bankAccountIssuerAndAgentMonth, updateValue: setBankAccountIssuerAndAgentMonth, badgeContent: deal?.iterationChanges?.bankAccountIssuerAndAgentMonth, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:1000000'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 1000000])] } },
                ]
            },

            actOfTransferData,
        ]

        const pricingFields = [
            {
                name: 'MortgageHolidaysPanel', label: t('MortgageHolidays'), fields: [

                    { name: 'holidayMonthsStart', unit: "months", label: t('HolidayMonthsStart'), tooltip: t('HolidayMonthsStart_tooltip'), value: holidayMonthsStart, updateValue: setHolidayMonthsStart, badgeContent: deal?.iterationChanges?.holidayMonthsStart, dataType: InputDataType.INT, validators: { validators: ['minNumber:0', 'maxNumber:36'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 36]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 36])] } },

                    { name: 'holidayDurationMonths', unit: "months", label: t('HolidayDurationMonths'), tooltip: t('HolidayDurationMonths_tooltip'), value: holidayDurationMonths, updateValue: setHolidayDurationMonths, badgeContent: deal?.iterationChanges?.holidayDurationMonths, dataType: InputDataType.INT, validators: { validators: ['minNumber:0', 'maxNumber:18'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 18]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 18])] } },

                    { name: 'holidaysFraction', unit: "percent", label: t('HolidaysFraction'), tooltip: t('HolidaysFraction_tooltip'), value: holidaysFraction, updateValue: setHolidaysFraction, badgeContent: deal?.iterationChanges?.holidaysFraction, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:100.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 100]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 100])] } }
                ]
            },
            {
                name: 'ChargeOnBalancesPanel', label: t('ChargeOnBalances'), fields: [

                    { name: 'reinvestment', label: t('Reinvestment'), tooltip: t('Reinvestment_tooltip'), value: reinvestment, updateValue: setReinvestment, badgeContent: deal?.iterationChanges?.reinvestment, dataType: InputDataType.BOOLEAN }
                ]
            },
        ];

        // ////////////////////////////////////////////////////////

        // console.log('selectedNode?.node', selectedNode?.node)
        // console.log('deal', deal?.deal?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM)
        if (reinvestment) {

            pricingFields[1].fields.push(
                showKeyRate ? //deal?.deal?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM ?
                    { name: 'expectedRUONIA', unit: null, label: t('ExpectedRUONIA'), tooltip: t('ExpectedRUONIA_tooltip'), value: t('Securitization_ExpectedRUONIA_KaP'), readOnly: true, dataType: InputDataType.STRING, validators: null }
                    : { name: 'expectedRUONIA', unit: "percentInYear", label: t('ExpectedRUONIA'), tooltip: t('ExpectedRUONIA_tooltip'), value: expectedRUONIA, updateValue: setExpectedRUONIA, badgeContent: deal?.iterationChanges?.expectedRUONIA, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:20.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 20.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 20.0])] } }
            );

            pricingFields[1].fields.push(
                { name: 'deductionRUONIA', unit: "percentInYear", label: t('DeductionRUONIA'), tooltip: t('DeductionRUONIA_tooltip'), value: deductionRUONIA, updateValue: setDeductionRUONIA, badgeContent: deal?.iterationChanges?.deductionRUONIA, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:-5.0', 'maxFloat:5.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [-5.0, 5.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [-5.0, 5.0])] } }
            );

            pricingFields[1].fields.push(
                { name: 'stopAccrualDays', unit: "days", label: t('StopAccrualDays'), tooltip: t('StopAccrualDays_tooltip'), value: stopAccrualDays, updateValue: setStopAccrualDays, badgeContent: deal?.iterationChanges?.stopAccrualDays, dataType: InputDataType.INT, validators: { validators: ['minNumber:0', 'maxNumber:15'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 15]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 15])] } }
            );
        }

        switch (deal?.deal?.type) {
            case DealType.TYPE_BOND_PUBLISH:
                {
                    return [...bondPublishFields, ...pricingFields]
                }
                break;
            case DealType.TYPE_BOND_AT_BALANCE:
                {
                    bondParameters.label = t('ExpectedBondParameters');
                    return [dealDates,
                        actOfTransferData,
                        bondParameters,
                        ...pricingFields
                    ];
                }
                break;
            case DealType.TYPE_POOL_BUYOUT:
                {
                    return [actOfTransferData,
                        expectedDealDates,
                        pricingParameters,
                        ...pricingFields
                    ];
                }
                break;
            default:
                {
                    throw "Unknown deal type!";
                }
        }
    }

    const groups = formEditFields();
    /*
    const handleKeyPress = (event) => {
        if (event.keyCode == 13) {
            onFieldLeft();
        }
    }
    */
    // console.log('{ ...deal?.dealChanges, ...deal.onlineDealChanges }', { ...deal?.dealChanges, ...deal.onlineDealChanges })
    const getCouponTypeName = (couponType) => {
        switch (couponType) {
            case CouponType.TYPE_FIXED:
                return t('securitization.CouponTypeFixedShort')

            case CouponType.TYPE_FLOAT:
                return t('securitization.CouponTypeFloatingShort')

            case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                return t('securitization.CouponTypeStrKeyrateAndPremiumShort')
        }
    }

    const getBadgeContent = (updFields = {}, row = {}) => {
        if (Object.hasOwn(updFields, row?.name)) {
            // if (updFields[row?.name]?.userID !== Globals.user.id)

            if (updFields[row?.name]?.userID != null) {
                let res = { ...updFields[row?.name] }
                if (row.name == 'couponType') res.oldValue = getCouponTypeName(updFields[row?.name]?.oldValue)

                return res
            }
        }
        // else
        //     if (row?.badgeContent?.userID !== Globals.user.id ) {
        // {console.log('row', row.name, !row?.badgeContent?.userID, row?.badgeContent)}
        //         // if (!row?.badgeContent?.userID) return ({ userName: 'UNDEFINED' })
        //         if (row.dataSource != null) {
        //             var content = { ...row?.badgeContent };
        //             var index = row.dataSource.findIndex(x => x.key == content.oldValue);
        //             if (index != -1) {
        //                 content.oldValue = row.dataSource[index].value;
        //                 return content;
        //             }
        //         }

        //         return row?.badgeContent
        //     }

        return {}
    }

    const checkIterationOnlineChanges = () => {
        updatedFields.current = {}

        if (onlineDealChanges != null) {

            Object.entries(onlineDealChanges).forEach(entry => {

                if (entry[1] == null) {
                    return;
                }

                // console.log('entry', {...entry[1], oldValue:entry[1]?.oldValue?? eval(entry[0]) })

                //if (entry[1]?.newValue != null)
                if (backupDeal[entry[0]] != entry[1].newValue)
                    updatedFields.current[entry[0]] = { ...entry[1], newValue: entry[1].newValue ?? '' }//, oldValue: deal.deal[entry[0]] }
            });
        }
    }

    //CHECKME!!! better to move at upper level, where calculate button lays
    /*
    const updatechangesDetector = () => {
        // console.log('updatechangeDetector')
        // console.log(updatedFields?.current)
        if (Object.keys(updatedFields?.current).length == 0)
            return false
    
        return Object.keys(updatedFields?.current).some((key) => {
            // console.log('updatedFields.current[key]', updatedFields.current[key])
            // console.log('val', updatedFields.current[key]?.newValue?.toString(), updatedFields.current[key]?.oldValue?.toString())
            if (getType(updatedFields.current[key]?.newValue) == 'date')
                return (Date.parse(updatedFields.current[key].newValue?.toString()) !== Date.parse(updatedFields.current[key].oldValue?.toString()))
    
            return updatedFields.current[key]?.newValue?.toString() !== updatedFields.current[key]?.oldValue?.toString()
        })
    }
    */
    /*
    const onApplyKeyRate = (data: any) => {
    
        var rates = data.editModel.map(x => { return { Date: x.x, Key: x.y }; });
        var keyRates = JSON.stringify(rates);
        // console.log('keyRates ', keyRates)
        // console.log('updatedFields', updatedFields)
        updatedFields.current.keyRates = { ...updatedFields?.current?.keyRates ?? {}, newValue: keyRates }; //stub to show changes at UI, full update will receive from hub
        var iterationKeyrates = { ...localIteration.keyRateGroup };
        iterationKeyrates.keyRates = [...localIteration?.keyRateGroup?.keyRates ?? []];
    
        mergeKeyrates(data.editModel, iterationKeyrates.keyRates);
    
        setLocalIteration({ ...localIteration, keyRateGroup: iterationKeyrates });
    
        var update = {
            IterationID: localIteration.id, keyRates: { Value: keyRates }
        };
        //we collect all changes
        //Object.keys(updatedFields.current).forEach(name => update[name] = { Value: updatedFields.current[name].newValue });
        // console.log("onApplyKeyRate => send iteration change");
        // console.log(update);
        actionSecuritization.sendIterationChange(update)(dispatch);
    
        setDealState(state => state &= ~DealValidationState.STATE_HISTORICAL_KEYRATE_OUTDATED);
    }
    */

    const updateDealState = debounce(100, (id, state) => {
        updateDealValidationState(id, state)
        // actionSecuritization.updateDealLight({ ID: id, ValidationState: { Value: state } })(dispatch);
    });

    const onFormStateChanged = (isValid: boolean) => {
        //console.log('onFormStateChanged')
        // console.log("!!!!!!form state changed!!!!! new value: " + isValid);
        // console.log('isDealParametersValid', isValid)
        //actionSecuritization.setDealData("isDealParametersValid", isValid)(dispatch);

        if (deal?.deal != null) {
            var state = deal.deal.validationState;
            if (isValid)
                state &= ~efDealValidationState.STATE_DEAL_PARAMETERS_INVALID;
            else
                state |= efDealValidationState.STATE_DEAL_PARAMETERS_INVALID;

            if (deal.deal.validationState != state) {

                // console.log(`===onFormStateChanged deal state changed: ${state}`);

                updateDealState(deal.deal.id, state);
            }
        }
    }

    /*
    const onFieldChanged = (name: string, value: any, isValid: boolean) => {
        
        if (!isValid)
            return; //non-valid is not checking

        console.log('-------------onFieldChanged')

        var updates = {};

        switch (name) {
            // case 'couponType':
            // console.log('name', name, value)

            //     break;
            case 'issueDate':
                {
                    //если
                    //issueDate < inclusionDebtDate
                    //если новая вбитая дата размещения < даты актуализации ОСЗ, то на дату актуализации ОСЗ ставится новая вбитая дата размещения
                    //issueDate < inclusionDate
                    //если новая вбитая дата размещения < даты включения в ИП, то на дату включения в ИП ставится новая вбитая дата размещения
                    //issueDate < fixationDate
                    //если новая вбитая дата размещения < даты подписания решения, то на дату подписания решения ставится новая вбитая дата размещения
                    var issueDate = Date.parse(value);
                    //var inclusionDebtDate = Date.parse(localIteration.inclusionDebtDate);
                    var inclusionDate = Date.parse(deal.deal.inclusionDate);
                    var fixationDate = Date.parse(deal.deal.fixationDate);

                    //if (issueDate < inclusionDebtDate) {
                    //localIteration.inclusionDebtDate = value;
                    //updates.inclusionDebtDate = { Value: value };
                    //}

                    if (issueDate < inclusionDate) {
                        deal.deal.inclusionDate = value;
                        updates.inclusionDate = { Value: value };
                    }

                    if (issueDate < fixationDate) {
                        deal.deal.fixationDate = value;
                        updates.fixationDate = { Value: value };
                    }
                }
                break;
            case 'firstCouponDate':
                {
                    //firstCouponDate
                    //secondPeriodStartDate
                    //независимо от того, что стоит в дате начала второго расчетного периода, в нее автоматически ставится дата первого дня месяца первого купона
                    var firstCouponDate = firstDayOfMonth(new Date(value));

                    if (isValidDateFns(firstCouponDate)) {
                        var secondPeriodStartDate = firstDayOfMonth(new Date(deal.deal.secondPeriodStartDate));

                        if (firstCouponDate.getTime() != secondPeriodStartDate.getTime())
                            updates.secondPeriodStartDate = { Value: Localization.FormatDateTimeISO(firstCouponDate) };
                    }
                }
                break;
            default:
                return;
        }

        updateFields(updates);
    }
    */

    const updateFields = (updates: any) => {

        // console.log('updates', updates)
        var keys = Object.keys(updates);
        if (keys.length == 0)
            return;

        //CHECKME!!! update selected deal state

        //var iterationChanges = { ...localIteration };
        var update = { DealID: deal.deal.id, ...updates };

        // keys.forEach(key => {

        //     //remove change if it matches to old value
        //     if (updates[key].Value.toString() === backupDeal[key]?.toString()) {
        //         delete updatedFields.current[key];
        //         return;
        //     }

        //     if (updatedFields.current[key] && updatedFields.current[key].oldValue === updates[key].Value) {
        //         return;
        //     }

        //     //iterationChanges[key] = updates[key].Value;
        //     updatedFields.current[key] = { ...updatedFields.current[key], newValue: updates[key].Value };
        // });

        //setLocalIteration(iterationChanges);
        // console.log('iterationChanges', iterationChanges)
        actionSecuritization.sendDealChange(update)(dispatch);
    }

    const onUpdateField = (name, value) => {

        updateFields({ [name]: { Value: value } });
        //
        //var iterationChanges = { ...localIteration, [name]: value };
        //var update = { IterationID: localIteration.id, [name]: { Value: value } };
        //
        //updatedFields.current[name] = { ...updatedFields.current[name], newValue: value } //stub to show changes at UI, full update will receive from hub
        //
        //setLocalIteration(iterationChanges);
        //
        //actionSecuritization.sendIterationChange(update)(dispatch);
    };

    const firstDayOfMonth = (date: number) => {
        var localDate = new Date(date);
        return new Date(localDate.getFullYear(), localDate.getMonth(), 1, 0, 0, 0, 0);
    }

    //Дата подписания решения{ name: 'fixationDate', label: t('FixationDate'), tooltip: t('FixationDate_tooltip'), value: fixationDate, updateValue: setFixationDate, badgeContent: deal?.iterationChanges?.fixationDate, dataType: InputDataType.DATE, disabled: mainDatesNotFilled(), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] } },
    //Дата передачи закладных{ name: 'actDeliveryDate', label: t('ActDeliveryDate'), tooltip: t('ActDeliveryDate_tooltip'), value: actDeliveryDate, updateValue: setActDeliveryDate, badgeContent: deal?.iterationChanges?.actDeliveryDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits], errorMessages: [t('FieldRequired'), '*Date is out of range*'] } },

    //Дата размещения{ name: 'issueDate', label: t('IssueDate'), tooltip: t('IssueDate_tooltip'), value: issueDate, updateValue: setIssueDate, badgeContent: deal?.iterationChanges?.issueDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits], errorMessages: [t('FieldRequired'), '*Date is out of range*'] } },
    //Дата начала второго расч. периода{ name: 'secondPeriodStartDate', label: t('SecondPeriodStartDate'), tooltip: t('SecondPeriodStartDate_tooltip'), value: secondPeriodStartDate, updateValue: setSecondPeriodStartDate, badgeContent: deal?.iterationChanges?.secondPeriodStartDate, dataType: InputDataType.DATE, validators: { validators: ['required'], errorMessages: [t('FieldRequired')] } },
    //Дата выплаты первого купона{ name: 'firstCouponDate', label: t('FirstCouponDate'), tooltip: t('FirstCouponDate_tooltip'), value: firstCouponDate, updateValue: setFirstCouponDate, badgeContent: deal?.iterationChanges?.firstCouponDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits, checkValidMonthDate], errorMessages: [t('FieldRequired'), '*Date is out of range*', t('DateMustBeBetween1and28')] } },
    //Юридическая дата погашения, мес{ name: 'maturityDate', label: t('MaturityDate'), tooltip: t('MaturityDate_tooltip'), value: maturityDate, updateValue: setMaturityDate, badgeContent: deal?.iterationChanges?.maturityDate, dataType: InputDataType.DATE, validators: { validators: ['required', CheckDateLimits], errorMessages: [t('FieldRequired'), '*Date is out of range*'] } }

    //Дата включения в ИП{ name: 'inclusionDate', label: t('InclusionDate'), tooltip: t('InclusionDate_tooltip'), value: inclusionDate, updateValue: setInclusionDate, badgeContent: deal?.iterationChanges?.inclusionDate, dataType: InputDataType.DATE, disabled: mainDatesNotFilled(), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] } },
    // console.log('deal?.deal', deal?.deal)

    const nodeDateValidateRules = [
        { primaryField: 'poolDate', condition: 'poolDate<', secondField: 'issueDate', error: '' },
        { primaryField: 'poolDate', condition: 'poolDate<', secondField: 'secondPeriodStartDate', error: '' },
        { primaryField: 'poolDate', condition: 'poolDate<', secondField: 'firstCouponDate', error: '' },
        { primaryField: 'poolDate', condition: 'poolDate<', secondField: 'maturityDate', error: '' },
    ]

    const dateValidateRules = [
        { primaryField: 'inclusionDate', condition: '<', secondField: 'issueDate', error: '' },
        { primaryField: 'inclusionDate', condition: '<', secondField: 'secondPeriodStartDate', error: '' },
        { primaryField: 'inclusionDate', condition: '<', secondField: 'firstCouponDate', error: '' },
        { primaryField: 'inclusionDate', condition: '<', secondField: 'maturityDate', error: '' },

        { primaryField: 'fixationDate', condition: '<', secondField: 'issueDate', error: '' },
        { primaryField: 'fixationDate', condition: '<', secondField: 'secondPeriodStartDate', error: '' },
        { primaryField: 'fixationDate', condition: '<', secondField: 'firstCouponDate', error: '' },
        { primaryField: 'fixationDate', condition: '<', secondField: 'maturityDate', error: '' },

        { primaryField: 'actDeliveryDate', condition: '<', secondField: 'issueDate', error: '' },
        { primaryField: 'actDeliveryDate', condition: '<', secondField: 'secondPeriodStartDate', error: '' },
        { primaryField: 'actDeliveryDate', condition: '<', secondField: 'firstCouponDate', error: '' },
        { primaryField: 'actDeliveryDate', condition: '<', secondField: 'maturityDate', error: '' },

        { primaryField: 'issueDate', condition: '<', secondField: 'secondPeriodStartDate', error: '' },
        { primaryField: 'issueDate', condition: '<', secondField: 'firstCouponDate', error: '' },
        { primaryField: 'issueDate', condition: '<', secondField: 'maturityDate', error: '' },

        { primaryField: 'secondPeriodStartDate', condition: '<', secondField: 'firstCouponDate', error: '' },
        { primaryField: 'secondPeriodStartDate', condition: '<', secondField: 'maturityDate', error: '' },

        { primaryField: 'firstCouponDate', condition: '<', secondField: 'maturityDate', error: '' },

        { primaryField: 'firstCouponDate', condition: 'equalFirstDay', secondField: 'maturityDate', error: '' },

        { primaryField: 'maturityDate', condition: '% couponPeriod = 0', secondField: 'firstCouponDate', error: '' },
    ]

    console.log('deal', deal)
    const checkCondition = (primaryField, secondField, condition, fields) => {
        // console.log('deal?.dial****************************************', deal?.deal)
        try {
            //if (deal?.deal == null) return

            const primaryFieldDate = (condition == 'poolDate<' && selectedNode?.node?.pools?.length) ?
                new Date(Localization.FormatDateStringISO(selectedNode?.node?.pools[selectedNode?.node?.pools?.length - 1]?.poolDate))
                : new Date(Localization.FormatDateStringISO(fields[primaryField]))
            const secondFieldDate = new Date(Localization.FormatDateStringISO(fields[secondField]))


            // console.log('primaryFieldDate', primaryFieldDate)
            // console.log('secondFieldDate', secondFieldDate)
            // console.log('primaryField, secondField, condition', primaryField, secondField, condition)

            if (primaryFieldDate?.toString() == 'Invalid Date' || secondFieldDate?.toString() == 'Invalid Date') return

            switch (condition) {
                case 'poolDate<':
                    if (primaryFieldDate?.getTime() < secondFieldDate?.getTime())
                        return
                    // "dateLessErrorMessage": "Не соблюдены следующие условия на даты: \"{0}\" \"{1}\" \"{2}\"",
                    return primaryFieldDate?.getTime() < secondFieldDate?.getTime() ? null : stringInject(t('securitization.dateErrorMessage'), [t('securitization.' + primaryField), '<', t('securitization.' + secondField)])

                case '<':
                    // "dateLessErrorMessage": "Не соблюдены следующие условия на даты: \"{0}\" \"{1}\" \"{2}\"",
                    return primaryFieldDate?.getTime() < secondFieldDate?.getTime() ? null : stringInject(t('securitization.dateErrorMessage'), [t('securitization.' + primaryField), condition, t('securitization.' + secondField)])

                case 'equalFirstDay':
                    return primaryFieldDate?.getDate() == secondFieldDate?.getDate() ? null : t('securitization.dateErrorMessage1')

                case '% couponPeriod = 0':
                    const deltaMonth = (secondFieldDate.getFullYear() - primaryFieldDate.getFullYear()) * 12 + secondFieldDate.getMonth() - primaryFieldDate.getMonth()
                    // console.log('deltaMonth', deltaMonth, ' ', deal.deal?.couponPeriod, deltaMonth % deal.deal?.couponPeriod)
                    return (deltaMonth % deal.deal?.couponPeriod) == 0 ? null : t('securitization.couponPeriodError')

                default:
                    return
            }
        } catch (error) {
            console.log('ERROR')
            return
        }
    }

    const addErrorMessage = (errorList = null, field, message) => {
        if (!errorList) {
            console.error('need exting errorList array')
            return
        }

        const errorIndex = errorList.findIndex(el => el.name == field)
        if (errorIndex != -1) {
            errorList[errorIndex].message.push(message)
        }
        else
            errorList.push({ name: field, message: [message] })
    }

    const validateFormFields = (fields) => {
        const errorList = []
        //console.log('validateFormFields', fields)

        const fieldBigger = (leftName, rightName) => {
            return stringInject(t('FieldBigger_Params'), [leftName, rightName]);
        };

        const fieldLess = (leftName, rightName) => {
            return stringInject(t('FieldLess_Params'), [leftName, rightName]);
        };

        const fieldBiggerOrEqual = (leftName, rightName) => {
            return stringInject(t('FieldBiggerOrEqual_Params'), [leftName, rightName]);
        };

        const fieldLessOrEqual = (leftName, rightName) => {
            return stringInject(t('FieldLessOrEqual_Params'), [leftName, rightName]);
        };

        // console.log(`----------validateFormFields, check name inclusionDate fields.inclusionDate: ${fields.inclusionDate}, deal.deal.inclusionDate: ${deal.deal.inclusionDate}`);

        dateValidateRules
            ?.forEach((rule) => {
                const res = checkCondition(rule.primaryField, rule.secondField, rule.condition, fields)
                //ERROR             { name: 'actDeliveryDate', message: fieldBigger(t('ActDeliveryDate'), t('IssueDate')) },
                if (res != null) {
                    // const primaryIndex = errorList.findIndex(el => el.name == rule.primaryField)
                    // if (primaryIndex != -1) {
                    //     errorList[primaryIndex].message.push(res)
                    //     // console.log('res', res)
                    // }
                    // else
                    //     errorList.push({ name: rule.primaryField, message: [res] })
                    // errorList.push({ name: rule.secondField, message: [res] })
                    addErrorMessage(errorList, rule.primaryField, res)
                    addErrorMessage(errorList, rule.secondField, res)
                }
            })

        /*-------------проверка дат ноды--------- START*/
        let dealValidation = deal?.deal?.validationState & ~efDealDataState.STATE_NODE_DATE_FAIL // remove PolDateValidationError
        // console.log('dealValidation-----start', dealValidation)
        const nodeDateErrors = nodeDateValidateRules?.map((rule) => {
            const res = checkCondition(rule.primaryField, rule.secondField, rule.condition, fields)
            if (res != null) {
                addErrorMessage(errorList, rule.primaryField, res)
                addErrorMessage(errorList, rule.secondField, res)
                return true
            }
            return null
        })
            ?.filter(item => item != null)

        if (nodeDateErrors?.length) {
            dealValidation = deal?.deal?.validationState | efDealDataState.STATE_NODE_DATE_FAIL
            // console.log('dealValidation----- COND', dealValidation)
        }

        // console.log('dealValidation-----END', dealValidation)
        if (deal?.deal?.validationState != dealValidation)
            actionSecuritization.setDealField({ validationState: dealValidation })(dispatch)
        /*-------------проверка дат ноды--------- END*/

        // ?.filter(res => res != null)
        // ?.flatMap(item => item)

        //console.log('dateErrors-------------------------------------------', errorList)

        /*
        const fieldBiggerOrEqualDateSecond = (leftName, day, rightName) => {
            return stringInject(t('FieldBiggerOrEqualDateSecond_Params'), [leftName, day, rightName]);
        };
    
        const fieldLessOrEqualDateFirst = (leftName, day, rightName) => {
            return stringInject(t('FieldLessOrEqualDateFirst_Params'), [leftName, day, rightName]);
        };
        */

        /////////////////////////////////////////////////////////////////////////////////////////////////
        // var actDeliveryDate = (fields.actDeliveryDate || '') != '' ? Date.parse(fields.actDeliveryDate) : null;
        // var issueDate = (fields.issueDate || '') != '' ? Date.parse(fields.issueDate) : null;
        // var firstCouponDate = (fields.firstCouponDate || '') != '' ? Date.parse(fields.firstCouponDate) : null;
        // var maturityDate = (fields.maturityDate || '') != '' ? Date.parse(fields.maturityDate) : null;
        // //var inclusionDebtDate = (fields.inclusionDebtDate || '') != '' ? Date.parse(fields.inclusionDebtDate) : null;
        // var inclusionDate = (fields.inclusionDate || '') != '' ? Date.parse(fields.inclusionDate) : null;
        // var fixationDate = (fields.fixationDate || '') != '' ? Date.parse(fields.fixationDate) : null;
        // var secondPeriodStartDate = (fields.secondPeriodStartDate || '') != '' ? Date.parse(fields.secondPeriodStartDate) : null;

        // var nextMonthOfIssueDate = null;
        // if (issueDate != null) {
        //     nextMonthOfIssueDate = addMonths(issueDate, 1);
        //     nextMonthOfIssueDate = setDate(nextMonthOfIssueDate, 1);
        //     nextMonthOfIssueDate = Date.parse(nextMonthOfIssueDate);
        // }

        //Дата начала второго расч. периода //Дата размещения ИЦБ
        //secondPeriodStartDate >= issueDate + 1month(1 day)
        // if (actDeliveryDate != null) {

        //     if (issueDate != null && actDeliveryDate > issueDate)
        //         return [
        //             { name: 'actDeliveryDate', message: fieldBigger(t('ActDeliveryDate'), t('IssueDate')) },
        //             { name: 'issueDate', message: fieldLess(t('IssueDate'), t('ActDeliveryDate')) }
        //         ];
        //     if (firstCouponDate != null && actDeliveryDate >= firstDayOfMonth(firstCouponDate).getTime())
        //         return [
        //             { name: 'actDeliveryDate', message: stringInject(t('FieldMustBeAtPrevMonth_Params'), [t('ActDeliveryDate'), t('FirstCouponDate')]) },
        //             { name: 'firstCouponDate', message: stringInject(t('FieldMustBeAtNextMonth_Params'), [t('FirstCouponDate'), t('ActDeliveryDate')]) }
        //         ];
        //     if (maturityDate != null && actDeliveryDate >= maturityDate)
        //         return [
        //             { name: 'actDeliveryDate', message: fieldBiggerOrEqual(t('ActDeliveryDate'), t('MaturityDate')) },
        //             { name: 'maturityDate', message: fieldLessOrEqual(t('MaturityDate'), t('ActDeliveryDate')) }
        //         ];
        // }

        // if (issueDate != null) {

        //     if (firstCouponDate != null && issueDate >= firstDayOfMonth(firstCouponDate).getTime())
        //         return [
        //             { name: 'issueDate', message: fieldBiggerOrEqual(t('IssueDate'), t('FirstCouponDate')) },
        //             { name: 'firstCouponDate', message: fieldLessOrEqual(t('FirstCouponDate'), t('IssueDate')) }
        //         ];
        //     if (maturityDate != null && issueDate >= maturityDate)
        //         return [
        //             { name: 'issueDate', message: fieldBiggerOrEqual(t('IssueDate'), t('MaturityDate')) },
        //             { name: 'maturityDate', message: fieldLessOrEqual(t('MaturityDate'), t('IssueDate')) }
        //         ];
        //     if (secondPeriodStartDate != null && secondPeriodStartDate < nextMonthOfIssueDate)
        //         return [
        //             { name: 'issueDate', message: stringInject(t('SecondPeriodAndIssueWarning_Params'), [t("SecondPeriodStartDate"), t("IssueDate")]) },
        //             { name: 'secondPeriodStartDate', message: stringInject(t('SecondPeriodAndIssueWarning_Params'), [t("SecondPeriodStartDate"), t("IssueDate")]) }
        //         ];
        // }

        // if (firstCouponDate != null) {

        //     if (maturityDate != null) {
        //         if (firstCouponDate >= maturityDate) {
        //             return [
        //                 { name: 'firstCouponDate', message: fieldBiggerOrEqual(t('FirstCouponDate'), t('MaturityDate')) },
        //                 { name: 'maturityDate', message: fieldLessOrEqual(t('MaturityDate'), t('FirstCouponDate')) }
        //             ];
        //         }

        //         if ((new Date(firstCouponDate)).getDate() != (new Date(maturityDate)).getDate())
        //             return [
        //                 { name: 'firstCouponDate', message: t('FirstCouponDateMustMatchToMaturityDate') },
        //                 { name: 'maturityDate', message: t('FirstCouponDateMustMatchToMaturityDate') }
        //             ];
        //     }
        // }

        // if (inclusionDate != null && issueDate != null && inclusionDate >= issueDate) {
        //     return [
        //         { name: 'inclusionDate', message: fieldBiggerOrEqual(t('InclusionDate'), t('IssueDate')) },
        //         { name: 'issueDate', message: fieldLessOrEqual(t('IssueDate'), t('InclusionDate')) }
        //     ];
        // }

        // if (fixationDate != null && issueDate != null && fixationDate >= issueDate) {
        //     return [
        //         { name: 'fixationDate', message: fieldBiggerOrEqual(t('FixationDate'), t('IssueDate')) },
        //         { name: 'issueDate', message: fieldLessOrEqual(t('IssueDate'), t('FixationDate')) }
        //     ];
        // }

        // if (secondPeriodStartDate != null && firstCouponDate != null && secondPeriodStartDate >= firstCouponDate) {
        //     return [
        //         { name: 'secondPeriodStartDate', message: fieldBiggerOrEqual(t('SecondPeriodStartDate'), t('FirstCouponDate')) },
        //         { name: 'firstCouponDate', message: fieldLessOrEqual(t('IssueDate'), t('FirstCouponDate')) }
        //     ];
        // }

        if ((fields.deductionRUONIA || '') != '') {
            // console.log('fields.deductionRUONIA', fields.deductionRUONIA)
            if ((fields.expectedRUONIA || '') == '') {
                errorList.push({ name: 'deductionRUONIA', message: t('PleaseFillRUONIAField') }, { name: 'expectedRUONIA', message: t('PleaseFillRUONIAField') })
            } else {
                var deductionRUONIA = parseFloat(fields.deductionRUONIA);
                var expectedRUONIA = parseFloat(fields.expectedRUONIA);

                if (expectedRUONIA < deductionRUONIA) {
                    errorList.push({ name: 'deductionRUONIA', message: fieldLess(t('ExpectedRUONIA'), t('DeductionRUONIA')) })
                    errorList.push({ name: 'expectedRUONIA', message: fieldBigger(t('DeductionRUONIA'), t('ExpectedRUONIA')) })
                }
            }
        }

        return errorList.map(item => ({ ...item, message: <div dangerouslySetInnerHTML={{ __html: `${t('securitization.dateErrorMessageTitle')}${item.message?.flatMap(m => `<br>${m}`)}` }} /> }))
    }

    const mergeKeyrates = (source, dest = []) => {
        var i = 0;
        for (; i < source?.length; i++) {

            if (dest?.length == i) {
                dest.push({ ...dest[0] });
            }
            dest[i].date = source[i].x;
            dest[i].key = source[i].y;
        }

        if (dest?.length > i) {
            dest.splice(i, dest.length - i);
        }
    }

    const updateIterationByOnlineContents = (updDeal) => {

        //setNeedRecalc(updatechangesDetector())

        var isChanged = false;

        if (updDeal == null)
            return isChanged;

        if (onlineDealChanges == null)
            return isChanged;

        //restore original
        //Object.keys(backupDeal).forEach((key) => {
        //updDeal[key] = backupDeal[key];
        //});

        // console.log("=========update online changes ", localIteration)
        // console.log('updIteration', updIteration)
        Object.entries(onlineDealChanges).forEach(entry => {
            // console.log(`changing name = ${entry[0]} value = ${entry[1].newValue}`, entry, localIteration)
            if (entry[1] == null)
                return;

            if (entry[0] == 'keyRates' && entry[1].newValue != null) {
                var data = JSON.parse(entry[1].newValue).map(x => { return { x: x.Date, y: x.Key } });
                // console.log('data', data)
                if (updDeal.keyRateGroup == null)
                    updDeal.keyRateGroup = { keyRates: [] };

                var updRates = updDeal.keyRateGroup.keyRates ?? [];

                // console.log('updRates', updRates)
                mergeKeyrates(data, updRates);
                // console.log('updRates?.keyRates', updRates)
                updDeal.keyRateGroup.keyRates = updRates;

                // isChanged = true;
                // return;
            }
            else {
                //if (entry[1].oldValue !== entry[1].newValue) {
                updDeal[entry[0]] = entry[1].newValue;
                // isChanged = true;
                //}
            }
            isChanged = true;
        });

        // console.log('updDeal', updDeal)
        //deserialize keyrates array

        //var keyrates = updatedFields.current["keyRateGroup"];
        //if (keyrates != null) {
        //    updatedFields.current["keyRateGroup"] = JSON.parse(keyrates);
        //}

        return isChanged;
    }

    /*
    useEffect(() => {
    
        //the form covers all the tabs and tab switching not causes to re-validate existed data. We do it manually
        setTimeout(() => submitForm?.current?.isFormValid(false), 0);
    
    }, []);
    */

    useEffect(() => {

        // console.log("=====onlineDealChanges", onlineDealChanges);

        checkIterationOnlineChanges();

        //setNeedRecalc(updatechangesDetector());

        //var isChanged = false;

        if (deal?.deal != null) {

            var newDeal = { ...deal?.deal };

            if (updateIterationByOnlineContents(newDeal)) {
                // console.log('{ ...deal?.deal, newDeal }', { ...deal?.deal, ...newDeal }, newDeal)
                actionSecuritization.setDealData('deal', newDeal)(dispatch);
            }

            //update keyrate chart data
            var chartData = null;

            if (newDeal?.keyRateGroup != null) {
                chartData = newDeal.keyRateGroup.keyRates.map(x => { return { x: toUnixDateGMT(new Date(x.date)), y: x.key } });
                actionSecuritization.setKeyrates(chartData)(dispatch);
            }
            else
                actionSecuritization.setKeyrates(null)(dispatch);
        }

    }, [onlineDealChanges]);

    /*
    useEffect(() => {
    
        // console.log("=====iteration", iteration);
        // console.log("=====changes", iterationOnlineChanges);
    
        var newIteration = { ...iteration };
        updateIterationByOnlineContents(newIteration);
    
        // console.log("=====iteration after changes", newIteration);
    
        setLocalIteration(newIteration);
    
        //update keyrate chart data
        var chartData = null;
        if (newIteration?.keyRateGroup != null) {
            chartData = newIteration.keyRateGroup.keyRates.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.key } });
            setKeyrateChartData(chartData);
        }
        else
            setKeyrateChartData(null);
    
    }, [iteration]);
    */
    const initialData = () => {
        //restore state
        //actionSecuritization.setDeal({ ...backupDeal })(dispatch);
        // actionSecuritization.setNodeDataParametr("node", { ...backupNode })(dispatch);
        //actionSecuritization.setDealData("isDealParametersValid", true)(dispatch);

        //reset data
        actionSecuritization.resetDealChanges(deal.deal.id)(dispatch);
        // actionSecuritization.resetIterationChanges(selectedNode.node.id)(dispatch);
    }

    // console.log('deal', deal)
    return (
        <ValidatorForm onSubmit={() => { }} ref={submitForm} validators={[validateFormFields]} /*onFieldChanged={onFieldChanged}*/ onFormStateChanged={onFormStateChanged}>
            <div className={classes.container}>
                {(permissions?.isAdmin || permissions?.isManager) &&
                    <IconButton
                        size='small'
                        color='primary'
                        onClick={initialData}
                        className={classes.resetButton}
                        disabled={Object.keys(updatedFields?.current ?? {}).length == 0}
                    >
                        <Tooltip title={t('RestoreParams')} disableInteractive>
                            <RestoreIcon />
                        </Tooltip>
                    </IconButton>
                }
                {KeyratePanel()}
                {groups?.map((group) => {
                    return (
                        <Box key={group.name + '_block'} className={classes.Block}>
                            <Box className={classes.blockName}>
                                {group.label}
                            </Box>
                            {group?.fields?.map((row, index) => {
                                if (row) {
                                    return (
                                        row.visible === false ? null : (
                                            <Box key={row.name + '_id'}
                                                className={[classes.rowBlock,
                                                    // (Object.hasOwn(updatedFields, row.name) && 
                                                    //     updatedFields[row.name]?.oldValue?.toString() !== updatedFields[row.name]?.newValue?.toString())?
                                                    // classes.changed:''
                                                ].join(' ')}
                                            >
                                                <Box className={[classes.rowName, row?.customProps?.disabled ? classes.disabled : ''].join(' ')}>
                                                    <CustomTooltip
                                                        id={`${row.name}-field-id`}
                                                        content={row.tooltip}
                                                        placement="left-start"
                                                        hideIcon
                                                        sx={{ ml: '5px', pb: '4px' }}
                                                    >
                                                        {row.label}
                                                    </CustomTooltip>
                                                </Box>
                                                <Box className={classes.rowValuesBlock}>
                                                    {(permissions?.isAdmin || permissions?.isManager) &&
                                                        (row.startButton != null) ? <>{row.startButton}</> : <></>}
                                                    <Box className={[classes.valueData, row?.customProps?.disabled ? classes.disabled : ''].join(' ')}>
                                                        <UniversalInputField
                                                            needConfirm
                                                            dataType={row.dataType}
                                                            name={row.name}
                                                            disabled={row?.disabled == true /*|| row?.readOnly*/}
                                                            readOnly={!isEdit || row?.readOnly || !(permissions?.isAdmin || permissions?.isManager)}
                                                            value={row.value}
                                                            fullWidth={true}
                                                            fieldProps={row.fieldProps}
                                                            handleValueChange={(name, value) => updateField(name, row.updateValue, value)}
                                                            //onBlur={() => onFieldLeft()}
                                                            //onKeyUp={(event) => handleKeyPress(event)}
                                                            inputProps={row?.customProps}
                                                            dataSource={row.dataSource}
                                                            {...row.validators}
                                                            sx={{
                                                                '& .Mui-disabled:before': { borderBottomStyle: 'solid !important', borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                '& .MuiInput-root:hover:not(.Mui-disabled):before': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                '& .MuiInput-underline:before': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                // '& .MuiInput-underline:after': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)}` },
                                                                '& .Mui-focused:not(.Mui-readOnly):after': !row?.readOnly ? { border: `solid 2px ${alpha(style.colorPrimary2, 1)} !important`, top: '1px' } : {},
                                                                '& .Mui-error:after': { border: `none` },
                                                                '& .Mui-readOnly': { cursor: 'text', '& *': { cursor: 'text' }, color: row?.readOnly ? style.colorPrimary3_60 : 'initial' },
                                                                '& .Mui-readOnly:after': { border: `none` },
                                                                '& .Mui-readOnly:before': { border: `none !important` },
                                                                '& .Mui-readOnly:hover:before': { border: `none` },
                                                            }}

                                                            minDate={
                                                                (row.dataType == InputDataType.DATE ||
                                                                    row.dataType == InputDataType.DATE_TIME ||
                                                                    row.dataType == InputDataType.DATE_TIME_UTC) ?
                                                                    dateLimits.minDate : null
                                                            }
                                                            maxDate={
                                                                (row.dataType == InputDataType.DATE ||
                                                                    row.dataType == InputDataType.DATE_TIME ||
                                                                    row.dataType == InputDataType.DATE_TIME_UTC) ?
                                                                    dateLimits.maxDate : null
                                                            }
                                                            isChanged={Object.hasOwn(updatedFields.current, row.name)}
                                                            disableCloseOnSelect={false}
                                                            hideValidationIcon={true}
                                                        />
                                                    </Box>
                                                    <Box className={classes.valueUnit}>
                                                        {row?.unit ? t(row.unit) : ''}
                                                    </Box>
                                                    <Box className={classes.valueAuthor}>
                                                        {(permissions?.isAdmin || permissions?.isManager) &&
                                                            <ChipAuthor content={getBadgeContent({ ...deal?.dealChanges, ...deal.onlineDealChanges }, row)} />
                                                        }

                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    )
                                }
                            })}
                        </Box>
                    )
                })
                }
            </div>
        </ValidatorForm>
    );
}

export default DealParametersPanel;
