import SecuritizationService from "@Services/SecuritizationService";
import CalculatorService from "@Services/CalculatorService";
import AccountService from "@Services/AccountService";
import CommonService from "@Services/CommonService";
import { Actions as LoaderActions } from "@Store/LoaderStore";
import Globals from "@Base/Globals";
import { Permissions } from "@Base/components/Definitions";

const Actions = {
    RequestStart: "SECURITIZATION_REQUEST_START",
    RequestSuccess: "SECURITIZATION_REQUEST_SUCCESS",
    RequestCleanup: "SECURITIZATION_REQUEST_CLEANUP",
    RequestFailed: "SECURITIZATION_REQUEST_FAILURE",

    AsyncRequestStart: "SECURITIZATION_ASYNC_REQUEST_START",
    AsyncRequestSuccess: "SECURITIZATION_ASYNC_REQUEST_SUCCESS",
    AsyncRequestFailed: "SECURITIZATION_ASYNC_REQUEST_FAILURE",

    UpdateDeal: "SECURITIZATION_UPDATE_DEAL",
    UpdateDealField: "SECURITIZATION_UPDATE_DEAL_FIELD",
    SetNode: "SECURITIZATION_SET_NODE",
    UpdateKeyrates: "SECURITIZATION_UPDATE_KEYRATES",
    //UpdateDealOnlineChanges: "SECURITIZATION_UPDATE_DEAL_ONLINE_CHANGES",
    UpdateDealData: "SECURITIZATION_UPDATE_DEAL_DATA",
    UpdateNodeData: "SECURITIZATION_UPDATE_NODE_DATA",
    UpdateNodeDataParametr: "SECURITIZATION_UPDATE_NODE_DATA_PARAMETR",

    UpdateBackups: "SECURITIZATION_UPDATE_BACKUPS",
    RemoveNode: "SECURITIZATION_REMOVE_NODE"
}

export const actionCreators = {

    /*
    getEmployeesByRole: (role) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await AccountService.getEmployeesByRole(role);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { availableManagers: result.value } });
    },
    */
    getPoolParameters: (poolId) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.getPoolParameters(poolId);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { pool: result.value } });
    },

    /*
    getDeals: (userId, index, pageSize) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart, payload: { dealsLoading: true } });

        var result = await SecuritizationService.getDeals(userId, index, pageSize);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { dealsLoading: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { dealsLoading: false, deals: result.value } });
    },
    */

    getDealsWithChanges: (userId, index, pageSize) => async (dispatch) => {
        console.log('*****************************************getDealsWithChanges')
        dispatch({ type: Actions.RequestStart, payload: { dealsLoading: true } });

        var result = await SecuritizationService.getDealsWithChanges(userId, index, pageSize);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { dealsLoading: false } });
            return;
        }
        /*-------------------------Сортировка нужная Паше ---------------------------------*/
        Array.prototype['customSortByPavelRules'] = function () {

            const arr = [...this.slice(0)]

            arr?.sort((a, b) => {
                const a_digs = a?.deal?.name?.match(/([0-9]+)/g)?.join().padStart(10, '0').slice(-5)
                const a_ = a?.deal?.name?.toUpperCase()?.replace(/([0-9]+)/g, a_digs ? a_digs : '')
                const b_digs = b?.deal?.name?.match(/([0-9]+)/g)?.join().padStart(10, '0').slice(-5)
                const b_ = b?.deal?.name?.toUpperCase()?.replace(/([0-9]+)/g, b_digs ? b_digs : '')
                // console.log('a', a_, a_digs)

                return (a_ > b_) ? 1 : (a_ < b_) ? -1 : 0
            })

            let i = 0
            arr?.some(((item, index) => {
                if (!item?.deal?.name?.match(/^[0-9a-zA-Z]/)?.length) {
                    i = index
                    return true
                }
                return false
            }))

            if (i)
                return [...arr?.slice(i), ...arr?.slice(0, i)]

            return arr
        }
        result.value.items = result.value.items?.customSortByPavelRules()
        /*-------------------------------------------------------------------------------*/
        dispatch({ type: Actions.RequestSuccess, payload: { dealsLoading: false, deals: result.value } });
    },

    getOriginatorsWithDeals: (userId) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart, payload: { dealsLoading: true } });

        var result = await SecuritizationService.getOriginatorsWithDeals(userId);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { dealsLoading: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { dealsLoading: false, deals: result.value } });
    },

    createDeal: (deal) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart, payload: { creatingDealEntity: true } });

        var result = await SecuritizationService.createDeal(deal);
        // console.log('result', result)

        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { creatingDealEntity: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { creatingDealEntity: false, createdDeal: result.value } });
    },

    clearCreatedDeal: () => async (dispatch) => {
        dispatch({ type: Actions.RequestSuccess, payload: { createdDeal: null } });
    },

    /*
    getDeal: (id: number) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.getDeal(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { deal: result.value } });
    },
    */

    getDealWithChanges: (id: number) => async (dispatch) => {

        const loader = "dealWithChangesLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        var result = await SecuritizationService.getDealWithChanges(id);
        if (result.hasErrors) {
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        if (result.isCancelled) {
            //console.log("store => result cancelled");
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        //remove params with NULL value
        const onlineDealChanges = Object.fromEntries(Object.entries(result.value?.onlineDealChanges).filter(([key, value]) => value != null))

        dispatch({
            type: Actions.RequestSuccess, payload: {
                deal: { ...result?.value || {}, onlineDealChanges: onlineDealChanges }, backupDeal:
                {
                    ...result.value.deal,
                    keyRateGroup: structuredClone(result.value.deal?.keyRateGroup)
                }
            }
        });
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },

    createPool: (pool) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart, payload: { creatingPoolEntity: true } });

        var result = await SecuritizationService.createPool(pool);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { creatingPoolEntity: false } });
            return;
        }
        // console.log('result', result)
        dispatch({ type: Actions.RequestSuccess, payload: { creatingPoolEntity: false, createdPool: result.value } });
    },

    createPoolNode: (node) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart, payload: { creatingPoolEntity: true } });

        var result = await SecuritizationService.createPoolNode(node);

        // console.log('result', result)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { creatingPoolEntity: false } });
            return;
        }
        // console.log('result', result)
        dispatch({ type: Actions.RequestSuccess, payload: { creatingPoolEntity: false, createdNode: result.value } });
    },

    clearCreatedPool: (pool) => async (dispatch) => {
        dispatch({ type: Actions.RequestCleanup, payload: { createdPool: null } });
    },

    loadPoolMessages: (filter: any[]) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.getProcessingMessages(filter);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { poolMessages: result.value } });
    },

    applyPoolWarnings: (id: number, isAccept: boolean) => async (dispatch) => {

        const loader = "applyPoolWarningsProgress";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });
        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.applyPoolWarnings(id, isAccept);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },
    /*
    getLastDealIteration: (dealId) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.getLastDealIteration(dealId);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { currentIteration: result.value } });
    },
    */
    updateDeal: (dealUpdate) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.updateDeal(dealUpdate);

        // console.log('result updateDeal', result)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isDealError: true, updatedDeal: null } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { isDealError: false, updatedDeal: result.value } });
        //dispatch({ type: Actions.RequestSuccess, payload: { isDealError: false, updatedDeal: null } });
    },

    updateDealLight: (dealUpdate) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.updateDealLight(dealUpdate);

        console.log('dealUpdate----------------------------', dealUpdate)
        console.log('result!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', result)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    },

    getIterationWithChanges: (iterationId) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.getIterationWithChanges(iterationId);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { iterationWithChanges: result.value } });
        dispatch({ type: Actions.RequestSuccess, payload: { iterationWithChanges: null } });
    },

    // getIteration: (iterationId) => async (dispatch) => {
    //     dispatch({ type: Actions.RequestStart });

    //     var result = await SecuritizationService.getIteration(iterationId);
    //     if (result.hasErrors) {
    //         dispatch({ type: Actions.RequestFailed });
    //         return;
    //     }

    //     dispatch({ type: Actions.RequestSuccess, payload: { iteration: result.value } });
    // },

    updateIteration: (iterationUpdate) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.updateIteration(iterationUpdate);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isIterationError: true, updatedIteration: null } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { updatedIteration: result.value } });
        dispatch({ type: Actions.RequestCleanup, payload: { isIterationError: false, updatedIteration: null } });
    },

    proceedIteration: (iterationUpdate) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.proceedIteration(iterationUpdate);
        // console.log('result****', result)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isIterationError: true, proceededIteration: null } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { proceededIteration: result.value } });
        dispatch({ type: Actions.RequestCleanup, payload: { isIterationError: false, proceededIteration: null } });
    },

    getOriginators: (index, pageSize) => async (dispatch, getState) => {

        dispatch({ type: Actions.RequestStart });


        var filter = {
            ItemIndex: index, PageSize: pageSize, Sorters: [{ Name: 'Name' }]
        };

        var result = await CalculatorService.getOriginators(filter);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { originators: result.value.items } });
    },

    updateGuarantee: (guaranteeUpdate) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.updateGuarantee(guaranteeUpdate);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isGuaranteeError: true, updatedGuarantee: null } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { updatedGuarantee: result.value } });
        dispatch({ type: Actions.RequestCleanup, payload: { isGuaranteeError: false, updatedGuarantee: null } });
    },

    cancelPoolCalculation: (poolId) => async (dispatch) => {

        const loader = "cancelPoolCalculationLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        await SecuritizationService.cancelPoolCalculation(poolId);
        //if (result.hasErrors) {
        //dispatch({ type: LoaderActions.HideLoader, payload: loader });
        //return;
        //}

        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },

    getDealComparationData: (dealList) => async (dispatch) => {

        dispatch({ type: Actions.AsyncRequestStart });

        var result = await SecuritizationService.getDealComparationData(dealList);
        if (result.hasErrors) {
            dispatch({ type: Actions.AsyncRequestFailed });
            return;
        }

        dispatch({ type: Actions.AsyncRequestSuccess, payload: { dealComparationData: result.value } });
    },



    getPoolPreferentialMortgageDistribution: (poolId) => async (dispatch) => {

        dispatch({ type: Actions.AsyncRequestStart });

        var result = await SecuritizationService.getPoolPreferentialMortgageDistribution(poolId);
        if (result.hasErrors) {
            dispatch({ type: Actions.AsyncRequestFailed });
            return;
        }

        dispatch({ type: Actions.AsyncRequestSuccess, payload: { preferentialDistribution: { poolId: poolId, distribution: result.value } } });
    },

    getPoolConstructionDistribution: (poolId) => async (dispatch) => {

        dispatch({ type: Actions.AsyncRequestStart });

        var result = await SecuritizationService.getPoolConstructionDistribution(poolId);
        if (result.hasErrors) {
            dispatch({ type: Actions.AsyncRequestFailed });
            return;
        }

        dispatch({ type: Actions.AsyncRequestSuccess, payload: { constructionsDistribution: { poolId: poolId, distribution: result.value } } });
    },

    getPoolCollateralDistribution: (poolId) => async (dispatch) => {

        dispatch({ type: Actions.AsyncRequestStart });

        var result = await SecuritizationService.getPoolCollateralDistribution(poolId);
        if (result.hasErrors) {
            dispatch({ type: Actions.AsyncRequestFailed });
            return;
        }

        dispatch({ type: Actions.AsyncRequestSuccess, payload: { collateralDistribution: { poolId: poolId, distribution: result.value } } });
    },


    getPoolIncomeConfirmedDistribution: (poolId) => async (dispatch) => {

        dispatch({ type: Actions.AsyncRequestStart });

        var result = await SecuritizationService.getPoolIncomeConfirmedDistribution(poolId);
        if (result.hasErrors) {
            dispatch({ type: Actions.AsyncRequestFailed });
            return;
        }

        dispatch({ type: Actions.AsyncRequestSuccess, payload: { incomeConfirmedDistribution: { poolId: poolId, distribution: result.value } } });
    },

    getPoolRegionDistribution: (poolId) => async (dispatch) => {

        dispatch({ type: Actions.AsyncRequestStart });

        var result = await SecuritizationService.getPoolRegionDistribution(poolId);
        if (result.hasErrors) {
            dispatch({ type: Actions.AsyncRequestFailed });
            return;
        }

        dispatch({ type: Actions.AsyncRequestSuccess, payload: { regionDistribution: { poolId: poolId, distribution: result.value } } });
    },

    getPoolProductDistribution: (poolId) => async (dispatch) => {

        dispatch({ type: Actions.AsyncRequestStart });

        var result = await SecuritizationService.getPoolProductDistribution(poolId);
        if (result.hasErrors) {
            dispatch({ type: Actions.AsyncRequestFailed });
            return;
        }

        dispatch({ type: Actions.AsyncRequestSuccess, payload: { productDistribution: { poolId: poolId, distribution: result.value } } });
    },

    getScurveCPR: (date, WAC) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.GetScurveCPR(date, WAC);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { SCurveSPR: { value: result.value } } });
    },

    getPoolData: (poolId, chartName, step, lod) => async (dispatch) => {

        const loader = chartName + "Load";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        var result = await SecuritizationService.getPoolData(poolId, chartName, step /*, lod*/);
        // console.log("store => result", result);
        if (result.hasErrors) {
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        if (result.isCancelled) {
            //console.log("store => result cancelled");
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { chartsData: result.value } });
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },

    getProducts: () => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CommonService.getProducts();
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { products: result } });
    },

    /*
    getIterationOnlineUpdates: (iterationId) => async (dispatch) => {
 
        dispatch({ type: Actions.RequestStart});
 
        var result = await SecuritizationService.getIterationOnlineUpdates(iterationId);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }
 
        dispatch({ type: Actions.RequestSuccess, payload: { iterationOnlineUpdates: result.value } });
    },
    */

    sendDealChange: (data) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.sendDealChange(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        // console.log('succes deaal update', result)
        dispatch({ type: Actions.RequestSuccess /*, payload: { iterationChanges: result } no need */ });
        //dispatch({ type: Actions.RequestSuccess, payload: { iterationChanges: null } });
    },

    sendIterationChange: (data) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.sendIterationChange(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess /*, payload: { iterationChanges: result } no need*/ });
        //dispatch({ type: Actions.RequestSuccess, payload: { iterationChanges: null } });
    },

    resetIterationChanges: (nodeId) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.resetIterationChanges(nodeId);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    },

    resetDealChanges: (dealId) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.resetDealChanges(dealId);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    },

    getZCYCDate: (date) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.getZCYCDate(date);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { ZCYCDate: { requertedDate: date, resultDate: result.value } } });
    },

    getDealsToCompare: (dealsId) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.getDealsToCompare(dealsId);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { dealsToCompare: result.value } });
    },

    getPoolsToCompare: (poolsId) => async (dispatch) => {
        var result = await SecuritizationService.getPoolsToCompare(poolsId);

        if (result.hasErrors) {
            return;
        }
        // console.log('result.value', result.value)
        dispatch({ type: Actions.RequestSuccess, payload: { poolsToCompareData: result.value } });
    },

    getNodeWithChanges: (nodeId) => async (dispatch) => {
        // console.log('nodeId', nodeId)
        const loader = "nodeWithChangesLoad"
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        var result = await SecuritizationService.getNodeWithChanges(nodeId);
        if (result.hasErrors) {
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        if (result.isCancelled) {
            //console.log("store => result cancelled");
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        // console.log('result', result)
        //remove params with NULL value
        const onlineIterationChanges = Object.fromEntries(Object.entries(result.value?.onlineIterationChanges).filter(([key, value]) => value != null))

        dispatch({ type: Actions.RequestSuccess, payload: { selectedNode: { ...result.value || {}, onlineIterationChanges: onlineIterationChanges }, backupNode: { ...result.value.node } } });
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },

    setPoolsHubMessage: (messages = null) => async (dispatch) => {

        dispatch({ type: Actions.AsyncRequestStart });

        if (messages == null) {
            dispatch({ type: Actions.AsyncRequestFailed });
            return;
        }

        dispatch({ type: Actions.AsyncRequestSuccess, payload: { poolsHubMessage: messages } });
    },

    setDeals: (data) => (dispatch) => {
        dispatch({ type: Actions.RequestSuccess, payload: { deals: data } });
    },

    setDeal: (data) => (dispatch) => {
        dispatch({ type: Actions.UpdateDeal, payload: data });
    },

    setDealField: (data) => (dispatch) => {
        console.log("====> state changed, ", data);
        dispatch({ type: Actions.UpdateDealField, payload: data });
    },

    setNodeData: (name, value) => (dispatch) => {
        // console.log('data', data)
        dispatch({ type: Actions.UpdateNodeData, payload: { name: name, value: value } });
    },

    setNodeDataParametr: (name, value) => async (dispatch) => {
        await dispatch({ type: Actions.UpdateNodeDataParametr, payload: { name: name, value: value } });
    },

    setKeyrates: (data) => (dispatch) => {
        dispatch({ type: Actions.UpdateKeyrates, payload: data });
    },

    setDealData: (name, value) => (dispatch) => {
        dispatch({ type: Actions.UpdateDealData, payload: { name: name, value: value } });
    },

    updateBackups: () => (dispatch) => {
        dispatch({ type: Actions.UpdateBackups });
    },

    setNode: (data) => (dispatch) => {
        dispatch({ type: Actions.SetNode, payload: data });
    },

    updateNode: (nodeUpdate) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.updateNode(nodeUpdate);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { updatedNode: null } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { updatedNode: result.value } });
    },

    removeNode: (id) => async (dispatch) => {

        //dispatch({ type: Actions.RequestStart });
        // console.log('#######################---remove NODE ', id)
        var result = await SecuritizationService.removeNode(id);
        if (result.hasErrors) {
            //dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RemoveNode, payload: { removedNode: result.value } });
        //dispatch({ type: Actions.RequestSuccess, payload: { removedNode: result.value } });
    },

    removeDeal: (dealId) => async (dispatch) => {
        await SecuritizationService.removeDeal(dealId);
    },

    archiveDeal: (dealId) => async (dispatch) => {
        await SecuritizationService.archiveDeal(dealId);
    },

    initialDeal: (data) => (dispatch) => {
        dispatch({ type: Actions.RequestSuccess, payload: { deal: null } });
    },
    removeOnlineChanges: (update) => async (dispatch) => {
        await SecuritizationService.removeOnlineChanges(update);
    },
    setPoolCompareArray: (poolCompareArray) => (dispatch) => {
        dispatch({ type: Actions.RequestSuccess, payload: { poolCompareArray: poolCompareArray } });
    },

    /**
     * 
     * @param {dealID: number, bondState: eBondIssueState} config
     * @returns
     */
    createDealBond: (config) => async (dispatch) => {

        var result = await SecuritizationService.createDealBond(config);
        if (result.hasErrors) {
            //dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, createdBond: result.value });
    },

    convertDealRIPToProtfolio: (dealId) => async (dispatch) => {
        dispatch({ type: Actions.RequestStart, payload: { isPoolTransferProcessing: true } });

        var result = await SecuritizationService.convertDealRIPToProtfolio(dealId);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isPoolTransferProcessing: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { isPoolTransferProcessing: false } });
        //a little hack
        dispatch({ type: Actions.UpdateDealData, payload: { name: 'isPortfolioCreating', value: true } });
    },

    cancelIterationCalculation: (iterationId) => async (dispatch) => {

        const loader = "cancelIterationCalculationLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        await SecuritizationService.cancelIterationCalculation(iterationId);
        //if (result.hasErrors) {
        //dispatch({ type: LoaderActions.HideLoader, payload: loader });
        //return;
        //}

        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    }
};

const updatePermissions = (currentState) => {
    const isAdmin = Globals.hasPermissionAccess(Permissions.PERMISSION_SECURITIZATION_ADMIN)
    const isUser = Globals.hasPermissionAccess(Permissions.PERMISSION_SECURITIZATION_VIEW)
    const isManager = Globals.hasPermissionAccess(Permissions.PERMISSION_SECURITIZATION_EDIT)

    currentState.permissions = { isAdmin, isUser, isManager }
}

const initialState = {
    creatingDealEntity: false,
    creatingPoolEntity: false,
    processingRequestCount: 0,
    permissions: { isAdmin: false, isUser: false, isManager: false }
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState;

    updatePermissions(currentState)

    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.RequestStart:
            return { ...currentState, ...action.payload };

        case Actions.RequestSuccess:
            return { ...currentState, ...action.payload };

        case Actions.RequestCleanup:
            return { ...currentState, ...action.payload };

        case Actions.RequestFailed:
            return { ...currentState, ...action.payload };

        case Actions.AsyncRequestStart:
            return { ...currentState, ...action.payload, processingRequestCount: currentState.processingRequestCount + 1, operationLoading: true };
        case Actions.AsyncRequestSuccess:
            return { ...currentState, ...action.payload, processingRequestCount: currentState.processingRequestCount - 1, operationLoading: (currentState.processingRequestCount - 1 != 0) };
        case Actions.AsyncRequestFailed:
            return { ...currentState, ...action.payload, processingRequestCount: currentState.processingRequestCount - 1, operationLoading: (currentState.processingRequestCount - 1 != 0) };

        case Actions.UpdateNodeData:
            {
                if (currentState.selectedNode != null) {
                    var node = currentState.selectedNode.node;
                    node[action.payload.name] = action.payload.value;
                }

                return { ...currentState };
            }
        case Actions.SetNode:
            {
                if (currentState.selectedNode != null)
                    currentState.selectedNode = action.payload;

                return { ...currentState };
            }
        case Actions.UpdateDeal:
            {
                if (currentState.deal != null)
                    currentState.deal/*.deal*/ = action.payload;

                return { ...currentState };
            }
        case Actions.UpdateKeyrates:
            {
                if (currentState.deal != null) {
                    currentState.deal.keyRates = action.payload;
                }

                return { ...currentState };
            }
        /*
    case Actions.UpdateDealOnlineChanges:
        {
            if (currentState.deal != null)
                currentState.deal.onlineDealChanges = action.payload;

            return currentState;
        }
        */
        case Actions.UpdateNodeDataParametr:
            {
                if (currentState.selectedNode != null) {
                    currentState.selectedNode = { ...currentState.selectedNode, [action.payload.name]: action.payload.value };
                    //currentState.selectedNode[action.payload.name] = action.payload.value;
                }
                // console.log('currentState--------------------------------', currentState)
                return { ...currentState };
            }
        case Actions.UpdateDealData:
            {
                if (currentState.deal != null) {
                    var deal = { ...currentState.deal };
                    deal[action.payload.name] = action.payload.value;
                    currentState.deal = deal;

                    if (currentState.deals != null) {
                        let dealIndex = currentState.deals?.items?.findIndex(item => item?.deal?.id == deal?.deal?.id)
                        // console.log('currentState.deals', currentState.deals, dealIndex, deal?.deal)
                        if (dealIndex != -1) {
                            currentState.deals.items[dealIndex].deal = deal?.deal
                        }
                    }
                }



                return { ...currentState };
            }
        case Actions.UpdateDealField:
            {
                if (currentState.deal != null) {

                    var deal = currentState.deal.deal;
                    Object.keys(action.payload).forEach(key => {
                        deal[key] = action.payload[key];
                    });

                    //currentState.deal.deal = deal;
                }

                return { ...currentState };
            }
        case Actions.UpdateBackups:
            {
                if (currentState.deal != null)
                    currentState.backupDeal = { ...currentState.deal.deal };
                if (currentState.selectedNode != null)
                    currentState.backupNode = { ...currentState.selectedNode.node };

                return { ...currentState };
            }
        case Actions.RemoveNode:
            {
                if (currentState.deal != null) {
                    var nodes = currentState.deal.deal.poolNodes;
                    if (nodes != null && nodes.length != 0) {
                        var nodeIndex = nodes.findIndex(x => x.id == action.payload.removedNode.id);
                        if (nodeIndex != -1) {

                            if (currentState.selectedNode != null && currentState.selectedNode.node.id != nodes[nodeIndex].id)
                                return { ...currentState };

                            var newNodes = [...nodes];
                            newNodes.splice(nodeIndex, 1);
                            currentState.deal.deal.poolNodes = newNodes;

                            currentState.removedNode = action.payload.removedNode
                            //cleanup node
                            currentState.selectedNode = null;
                        }
                    }
                }

                return { ...currentState };
            }
        default:
            return currentState || initialState;
    }
};