import ServiceBase from "./ServiceBase";
//import Globals from "../Globals";

export default class ConventionService extends ServiceBase {

    static async testSignalR(data, showError = true) {

        var result = await this.requestJson({
            url: "api/Convention/UpdateConventionStatus",
            method: "POST",
            data: data,
            showError: showError,
        });

        return result;
    }
    static async runCalculation(data, showError = true) {

        var result = await this.requestJson({
            url: "api/Convention/convention2",
            // url: "https://калькулятор.дом.рф:8193/Convention2/v1/Calculate",
            method: "POST",
            data: { ...data, rounding: true },
            showError: showError,
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getBondsQuery(value, existId, index, pageSize, showError = true) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBondsQuery",
            method: "GET",
            data: { query: value, existId: existId, top: pageSize },
            showError: showError,
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getBonds(filter, showError = true) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBonds",
            method: "POST",
            data: filter,
            showError: false,
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getDataForCalculation(bondID, showError = true) {
        var result = await this.requestJson({
            url: "api/Convention/GetDataForCalculation",
            method: "GET",
            data: { bondID: bondID },
            showError: showError,
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getSCurveExportedExcel(data, showError = true) {
        var result = await this.requestJson({
            url: "api/Convention/GetSCurveExportedExcel",
            method: "POST",
            data: data,
            showError: showError,
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getSCurveGraphWithPool(bondId, scurveDate, wacwamDate, refinancingRate, minIncentive, maxIncentive, step, showError = true) {
        var result = await this.requestJson({
            url: "api/Convention/GetSCurveGraphWithPool",
            method: "GET",
            data: { bondId: bondId, scurveDate: scurveDate, wacwamDate: wacwamDate, refinancingRate: refinancingRate, minIncentive: minIncentive, maxIncentive: maxIncentive, step: step },
            showError: showError,
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getSCurveGraph(scurveDate, minInc, maxInc, step, showError = true) {
        var result = await this.requestJson({
            url: "api/Convention/GetSCurveGraph",
            method: "GET",
            data: { date: scurveDate, minInc: minInc, maxInc: maxInc, step: step },
            showError: showError,
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getPool(bondId, date, showError = true) {
        var result = await this.requestJson({
            url: "api/Convention/GetPool",
            method: "GET",
            data: { bondId: bondId, date: date },
            showError: showError,
        });

        return result;
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // static async getPoolDebtDistribution(isin, date, refinancingRate, step, showError = true) {
    //     var result = await this.requestJson({
    //         url: "api/Convention/GetPoolDebtDistribution",
    //         method: "GET",
    //         data: { isin: isin, wacwamDate: date, refinancingRate: refinancingRate, step: step },
    //         showError: showError,
    //     });

    //     return result;
    // }
}