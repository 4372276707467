import React, { useEffect } from 'react'
import Helmet from "react-helmet"
import { useDispatch, useSelector } from 'react-redux'

import { actionCreators as actionTopNavbar } from '@Base/store/TopNavbarStore'
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import { t } from 'i18next'
import { Box, Container, Grid, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import BondsList from './BondsList'
import BondData from './BondData'
import { Spinner } from "@Components/shared/Spinner";

const useStyles = makeStyles((theme: Theme) => createStyles({

    rootContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
    },
    rootGrid: {
        flexGrow: 1
    },
    rootGridItem: {
        position: 'relative',
    },
    tabBox: {
        display: 'flex',
        position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
        flexGrow: 1,
        flexDirection: 'column',
    },
}))

function BondsPage() {

    const classes = useStyles()
    const dispatch = useDispatch()
    const { loading = false } = useSelector((state: any) => state.bonds)

    useEffect(() => { //установка\сброс title в NavBar
        actionTopNavbar.setTitle(t("MBSDOMRFIssues"))(dispatch)

        return () => {
            actionTopNavbar.setTitle("")(dispatch)
        }
    }, [])

    // useEffect(() => {
    //   if (products != null) {
    //     console.log('products', products)
    //   }
    // }, [products])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('MBSDOMRFIssuesTitle')}</title>
            </Helmet>
            <Container
                maxWidth="xxl"
                className={classes.rootContainer}
            >
                <Spinner show={loading} />
                <Grid container className={classes.rootGrid}>
                    <Grid item xs={4} className={classes.rootGridItem}>
                        <BondsList />
                    </Grid>
                    <Grid item xs={8} className={classes.rootGridItem}>
                        <BondData />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default BondsPage