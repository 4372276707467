import DeleteIcon from '@mui/icons-material/Delete';
import style from '@Styles/domrf-theme.module.scss';

import { debounce } from 'throttle-debounce';
import SearchPanelInput from '@Components/shared/SearchPanelInput';

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from 'react-i18next'


import { createStyles, makeStyles } from '@mui/styles'
import { Paper, Stack, alpha, Box, Container, Tab, Tabs, Theme, Button, Typography, InputAdornment, Tooltip } from '@mui/material'
import ConfirmationForm from "@Components/shared/ConfirmationForm";

import { actionCreators as administrationStore } from "@Store/AdministrationStore";
import { actionCreators as actionTopNavbar } from '@Store/TopNavbarStore'

import Localization from '@Components/Localization';

import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridActionsCellItem,
    GridColumnGroupingModel,
    GridComparatorFn,
    GridValueFormatterParams,
    GridRenderCellParams,
    GridPaginationModel,
    GridCallbackDetails,
    GridSortModel,
    GridSelectionModel
} from '@mui/x-data-grid-premium';
import { ruRU, enUS } from '@mui/x-data-grid/locales';

const useStyles = makeStyles((theme: Theme) => createStyles({

    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        flex: '1 1 auto',
        height: 0,
        width: '100%',
        minWidth: '100%'
    },
    searchField: {
        marginLeft: 'auto'
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    progress: {
        width: '100%'
    },
    warningIcon: {
        color: style.colorWarning
    },
    statusButton: {
        padding: '0'
    },
    removeButton: {
        marginRight: 'auto'
    }
}));

const NotificationsPage = () => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [selection, setSelection] = useState([]);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50
    });

    const [locale, setLocale] = useState(ruRU);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [sortModel, setSortModel] = useState([
        {
            field: 'date',
            sort: 'desc'
        }
    ]);

    const rowRef = useRef(null);

    const {
        notifications
    } = useSelector((state: any) => state.administration)

    const {
        notificationsLoad
    } = useSelector((state: any) => state.loader)

    useEffect(() => {

        actionTopNavbar.setTitle(t('Notifications'))(dispatch)
        return () => {
            actionTopNavbar.setTitle("")(dispatch)
            searchDebounced.cancel();
        }
    }, [])

    useEffect(() => {
        searchDebounced(searchValue);
    }, [searchValue, paginationModel, sortModel]);


    useEffect(() => {
        if (i18n.language == 'ru') {
            setLocale(ruRU);
        }
        else {
            setLocale(enUS);
        }

    }, [i18n]);

    useEffect(() => {

        setItems(notifications?.items ?? []);
        setCount(notifications?.count ?? 0);

    }, [notifications]);

    useEffect(() => {

    }, [notifications]);

    const handleDelete = (params: any) => {
        rowRef.current = params;
        setShowConfirmDialog(true);
    }

    const applyRecordRemove = () => {
        setShowConfirmDialog(false);

        //CHECKME!!! add remove result and bind table update
        administrationStore.removeNotification([rowRef.current.id])(dispatch);

        setItems(items.filter(row => row.id != rowRef.current.id));
        setCount(count - 1);

        rowRef.current = null;
    }

    const declineRecordRemove = () => {
        setShowConfirmDialog(false);
        rowRef.current = null;
    }

    const columns: GridColDef[] = [

        {
            field: 'actions',
            type: 'actions',
            width: 60,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={
                        <DeleteIcon />
                    } label={t("RemoveRecord")} onClick={() => handleDelete(params)} />
            ]
        },


        { field: 'id', headerName: t('ID'), description: t('notifications.id') },
        {
            field: 'date', headerName: t('CreatedDate'), description: t('notifications.createdDate'), width: 150,
            valueFormatter: (value: string) => {
                if (value == null) {
                    return '';
                }
                return Localization.FormatDateStringLocale(value);
            }
        },
        { field: 'server', headerName: t('Server'), description: t('notifications.server'), width: 300 },
        { field: 'name', headerName: t('Name'), description: t('notifications.name'), width: 300 },
        { field: 'information', headerName: t('Information'), description: t('notifications.information'), width: 500 },
        { field: 'error', headerName: t('Error'), description: t('notifications.error'), width: 500 }

    ];

    const updateDataSource = (searchValue) => {

        var filterItem = null;
        if (searchValue != '') {

            if (paginationModel.page != 0) {
                setPaginationModel({ ...paginationModel, page: 0 });
                return;
            }

            filterItem = [{
                Operator: "or",
                Values: [
                    {
                        Name: "name",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "description",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    }
                ]
            }];
        }

        var sortItem = sortModel.map((item, index) => {
            return {
                Name: item.field,
                IsAscending: item.sort == 'asc',
                SortOrder: index
            };
        });

        var filter = {
            Filters: filterItem,
            Sorters: sortItem,
            ItemIndex: paginationModel.page * paginationModel.pageSize,
            PageSize: paginationModel.pageSize
        };

        administrationStore.getNotifications(filter)(dispatch);
    }

    const onPaginationModelChange = (model: GridPaginationModel, details: GridCallbackDetails) => {

        setPaginationModel(model);
        searchDebounced(searchValue);
    }

    const searchDebounced = useCallback(debounce(500, updateDataSource), [paginationModel, sortModel]);

    const handleRemoveRecords = () => {

    }

    return (
        <Container className={classes.container}>

            <ConfirmationForm
                isOpen={showConfirmDialog}
                header={t("Deletion")}
                content={t("DoYouWantToRemoveTheRecord")}
                onAccept={applyRecordRemove}
                onDecline={declineRecordRemove}
            />

            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingX: '1rem', paddingY: '0.5rem' }}>
                <Button className={ classes.removeButton } variant="contained" color="secondary" disabled={selection.length == 0} onClick={handleRemoveRecords}>{t("Remove")}</Button>
                <SearchPanelInput className={classes.searchField} value={searchValue} onValueChange={setSearchValue} placeHolder={t('Search')} />
            </Stack>
            {/*console.log("portfoliosLoad => " + portfoliosLoad)*/}
            <DataGridPremium
                sx={{
                    ["& .bandHeaderCell .MuiDataGrid-columnHeaderTitleContainer"]: {
                        justifyContent: 'center'
                    }
                }}
                //experimentalFeatures={{ columnGrouping: true }}
                checkboxSelection
                disableRowSelectionOnClick
                gridRowId={(row) => row.id}
                onSelectionModelChange={setSelection}

                disableColumnMenu
                loading={notificationsLoad}
                columnHeaderHeight={30}
                rowHeight={25}
                rows={items}
                columns={columns}
                //columnGroupingModel={columnGrouping}
                localeText={locale.components.MuiDataGrid.defaultProps.localeText}

                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={onPaginationModelChange}
                rowCount={count}
                pagination
                pageSizeOptions={[30, 50, 100]}

                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            />
        </Container >
    )
}

export default NotificationsPage;