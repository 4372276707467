import React, { useState, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
import { CouponType } from '../../../components/Definitions';
import stringInject from 'stringinject';
import Localization from '../../../components/Localization';
import { DataType } from '../../../Definitions';

import {
    DataGridPremium,
    GridRenderCellParams
} from '@mui/x-data-grid-premium';
import { ruRU, enUS } from '@mui/x-data-grid/locales';

interface IProps {
    loading?: boolean;
    bonds: any[];
    poolsDate: Date;
}

const useStyles = makeStyles((theme: Theme) => createStyles({

    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        flex: '1 1 auto',
        height: 0,
        width: '100%',
        minWidth: '100%'
    },
    searchField: {
        marginLeft: 'auto'
    },
}));

const CompareBondsControl = ({
    loading,
    bonds
}: IProps) => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()

    const [locale, setLocale] = useState(ruRU);

    const [columns, setColumns] = useState([]);
    const [bondsToCompare, setBondsToCompare] = useState([]);

    useEffect(() => {

    }, [])

    useEffect(() => {

        updateGridData();

    }, [bonds]);

    useEffect(() => {
        if (i18n.language == 'ru') {
            setLocale(ruRU);
        }
        else {
            setLocale(enUS);
        }

    }, [i18n]);


    const nameMapping = {
        regNumber: { Label: t('RegistrationNumber'), Tooltip: t('PoolsTooltipRegistrationNumber'), DataType: DataType.STRING },
        //originator: { Label:t('BankOriginator'), Tooltip:t('BankOriginator_Info'), DataType: DataType.STRING },
        isin: { Label: t('ISIN'), Tooltip: t('PoolsTooltipISIN'), DataType: DataType.STRING },
        ticker: { Label: t('Ticker'), Tooltip: t('PoolsTooltipTickerSymbol'), DataType: DataType.STRING },
        couponRate: { Label: t('RatePrc'), Tooltip: t('PoolsTooltipCouponRate'), DataType: DataType.FLOAT },

        poolsHeader: { Label: t('PoolParametersHeader'), IsHeader: true },
        reportDate: { Label: t('ReportDate'), Tooltip: t('ReportDate_Info'), DataType: DataType.DATE },

        currentIssueAmount: { Label: t('CurrentPoolDebt'), Tooltip: t('CurrentPoolDebt_Info'), DataType: DataType.FLOAT },
        wac: { Label: t('WACPrc'), Tooltip: t('PoolStatisticsTooltipWAC'), DataType: DataType.FLOAT },
        wala: { Label: t('WALAPlain'), Tooltip: t('PoolStatisticsTooltipWALA'), DataType: DataType.FLOAT1 },
        wam: { Label: t('WAMYears'), Tooltip: t('PoolStatisticsTooltipWAM'), DataType: DataType.FLOAT1 },
        sixMonthCPR: { Label: t('ConventionNameHistoricalCPR'), Tooltip: t('PoolStatisticsTooltipCPR'), DataType: DataType.FLOAT },
        totalAverageCPR: { Label: t('ConventionNameHistoricalAverageCPR'), Tooltip: t('TooltipConventionNameHistoricalAverageCPR'), DataType: DataType.FLOAT },
        sixMonthCDR: { Label: t('ConventionNameHistoricalCDR'), Tooltip: t('PoolStatisticsTooltipCDR'), DataType: DataType.FLOAT },
        totalAverageCDR: { Label: t('ConventionNameHistoricalAverageCDR'), Tooltip: t('TooltipConventionNameHistoricalAverageCDR'), DataType: DataType.FLOAT }
    }

    const formatDataType = (datatype, value) => {
        switch (datatype) {
            case DataType.STRING:
                return value;
            case DataType.FLOAT:
                return Localization.FormatNumberString(value);
            case DataType.FLOAT1:
                return Localization.FormatNumberString(value, 1, 1);
            case DataType.INT:
                return Localization.FormatNumberString(value, 0, 0);
            case DataType.BOOLEAN:
                return value;
            case DataType.BOOLEAN_TRISTATE:
                return value;
            case DataType.DATE:
                return Localization.FormatDateStringLocale(value);
            case DataType.DATE_TIME:
                return Localization.FormatDateTimeStringLocale(value);
            default:
                return value;
        }
    }

    const formatLayoutDataType = (datatype, value, isFirstColumn) => {
        if (isFirstColumn)
            return <span>{value}</span>;
        if (value == null || value == '')
            return <div style={{ textAlign: 'right' }}>‒</div>;

        switch (datatype) {
            case DataType.STRING:
                return <div style={{ textAlign: 'right' }}>{value}</div>;
            case DataType.FLOAT:
            case DataType.FLOAT1:
                return <div style={{ textAlign: 'right' }}>{value}</div>;
            case DataType.INT:
                return <div style={{ textAlign: 'right' }}>{value}</div>;
            case DataType.DATE:
                return <div style={{ textAlign: 'right' }}>{value}</div>;
            case DataType.DATE_TIME:
                return <div>{value}</div>;
            case DataType.BOOLEAN:
                return <div>{value}</div>;
            case DataType.BOOLEAN_TRISTATE:
                return <div>{value}</div>;
            default:
                return value;
        }
    }

    const cellFormatter = (params: GridRenderCellParams<String>) => {

        if (nameMapping[params.row.id].IsHeader) {
            if (params.field == "name")
                return <span style={{ fontSize: '14px', fontWeight: 'bold', verticalAlign: 'baseline', width: '100%' }}>{nameMapping[params.row.id].Label}</span>
            else
                return <span></span>
        }

        if (params.field == "name") {
            return (
                <span style={{ fontSize: '14px', verticalAlign: 'baseline', width: '100%' }} >
                    <TooltipInfoLabel key={"pool-compare-tooltip-" + params.row.id} content={nameMapping[params.row.id].Tooltip}>
                        {formatLayoutDataType(nameMapping[params.row.id].DataType, params.value, params.colDef.first)}
                    </TooltipInfoLabel>
                </span>
            );
        }
        else {
            return (
                <span style={{ fontSize: '14px', verticalAlign: 'baseline', width: '100%' }} >
                    {formatLayoutDataType(nameMapping[params.row.id].DataType, params.value, params.colDef.first)}
                </span>
            );
        }
    };

    const updateGridData = () => {

        var bondsToCompare = [];
        var columns = [];
        var selected = bonds;

        if (selected.length != 0) {

            selected.forEach(x => x.wam = x.wam / 12.0);

            columns = [{ field: 'name', headerName: 'name', description: t('Parameter'), first: true, width: 270, renderCell: cellFormatter }, ...selected.map(x => { return { field: x.isin, description: x.isin, headerName: x.isin, width: 170, renderCell: cellFormatter } })];
            // console.log('Object.keys(this.state.nameMapping)', Object.keys(this.state.nameMapping))
            bondsToCompare = Object.keys(nameMapping)
                .map(key => {
                    var current = { id: key, name: nameMapping[key].Label };
                    var isHeader = nameMapping[key].IsHeader;

                    selected.forEach(item => {

                        if (key == 'couponRate') //CHECKME!!! dirty hack
                        {
                            switch (item.couponType) {
                                case CouponType.TYPE_FLOAT:
                                    {
                                        current[item.isin] = isHeader ? '' : t('floating');

                                    }
                                    return;
                                case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                                    {
                                        current[item.isin] = isHeader ? '' : stringInject(t('KeyRateAndPremium_Params'), [Localization.FormatNumberString(item[key])]);
                                    }
                                    return;
                            }
                        }

                        current[item.isin] = isHeader ? '' : formatDataType(nameMapping[key].DataType, item[key]);
                    });

                    return current;
                });
            //  console.log('bondsToCompare', bondsToCompare)       
        }

        setColumns(columns);
        setBondsToCompare(bondsToCompare);
    }

    if (bondsToCompare.length == 0)
        return null;

    return (
        <DataGridPremium
            sx={{
                ["& .bandHeaderCell .MuiDataGrid-columnHeaderTitleContainer"]: {
                    justifyContent: 'center'
                }
            }}
            disableColumnMenu
            loading={loading}
            rowHeight={25}
            rows={bondsToCompare}
            columns={columns}
            localeText={locale.components.MuiDataGrid.defaultProps.localeText}
            initialState={{ pinnedColumns: { left: ['name'] } }}
            hideFooter={true}
            slots={{
                columnHeaders: () => null
            }}
        />
    )
}

export default CompareBondsControl;