import ServiceBase from "./ServiceBase";
import Globals from "../Globals";
import { stringify } from "query-string";

export default class SecuritizationService extends ServiceBase {


    static async getDeals(userId, index, pageSize) {
        var result = await this.requestJson({
            url: "api/Securitization/GetDeals",
            method: "GET",
            data: { userId: userId, index: index, pageSize: pageSize }
        });

        return result;
    }

    static async getDealComparationData(dealList) {
        // console.log('data =', dealList.map(dealId =>{return `dealIsId=${dealId}`}).join('&'))
        var result = await this.requestJson({
            url: "api/securitization/GetDealsToCompare",
            method: "GET",
            data: {dealsId:dealList}
        });

        return result;
    }

    static async getDealsWithChanges(userId, index, pageSize) {

        return ServiceBase.requestWithCancellation("getDealsWithChanges", (token) => this.requestJson({
            url: "api/Securitization/GetDealsWithChanges",
            method: "GET",
            cancellationToken: token,
            data: { userId: userId, index: index, pageSize: pageSize }
        }));
    }

    static async createDeal(deal) {
        var result = await this.sendFormData({
            url: "api/Securitization/CreateDeal",
            method: "POST",
            data: deal
        });

        return result;
    }

    static async getLastDealIteration(dealId) {
        var result = await this.requestJson({
            url: "api/Securitization/GetLastDealIteration",
            method: "GET",
            data: { dealId: dealId }
        });

        return result;
    }

    static async getIterationWithChanges(id) {
        var result = await this.requestJson({
            url: "api/Securitization/GetIterationWithChanges",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async getIteration(id) {
        var result = await this.requestJson({
            url: "api/Securitization/GetIteration",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async getDeal(id) {
        var result = await this.requestJson({
            url: "api/Securitization/GetDeal",
            method: "GET",
            data: { dealId: id }
        });

        return result;
    }

    static async getDealWithChanges(id) {

        return ServiceBase.requestWithCancellation("getDealsWithChanges_1", (token) => this.requestJson({
            url: "api/Securitization/GetDealWithChanges",
            method: "GET",
            cancellationToken: token,
            data: { dealId: id }
        }));
    }


    static async createPool(pool) {
        var result = await this.sendFormData({
            url: "api/Securitization/CreatePool",
            method: "POST",
            data: pool
        });

        return result;
    }

    static async createPoolNode(node) {
        var result = await this.sendFormData({
            url: "api/Securitization/CreatePoolNode",
            method: "POST",
            data: node
        });

        return result;
    }

    /*
    static async getPoolMessages(id) {
        var result = await this.requestJson({
            url: "api/Securitization/GetPoolMessages",
            method: "GET",
            data: {id: id}
        });

        return result;
    }
    */

    static async applyPoolWarnings(id, isAccept) {
        var result = await this.requestJson({
            url: "api/Securitization/ApplyPoolWarnings",
            method: "GET",
            data: { id: id, isAccept: isAccept }
        });

        return result;
    }

    static async updateDeal(dealUpdate) {
        var result = await this.requestJson({
            url: "api/Securitization/UpdateDeal",
            method: "POST",
            data: dealUpdate
        });

        return result;
    }

    static async updateDealLight(dealUpdate) {
        var result = await this.requestJson({
            url: "api/Securitization/UpdateDealLight",
            method: "POST",
            data: dealUpdate
        });

        return result;
    }

    static async updateIteration(iterationUpdate) {
        var result = await this.requestJson({
            url: "api/Securitization/UpdateIteration",
            method: "POST",
            data: iterationUpdate
        });

        return result;
    }

    static async proceedIteration(iterationUpdate) {
        var result = await this.requestJson({
            url: "api/Securitization/ProceedIteration",
            method: "POST",
            data: iterationUpdate
        });

        return result;
    }

    static async updateGuarantee(guaranteeUpdate) {
        var result = await this.requestJson({
            url: "api/Securitization/UpdateGuarantee",
            method: "POST",
            data: guaranteeUpdate
        });

        return result;
    }

    static async cancelPoolCalculation(id) {
        var result = await this.requestJson({
            url: "api/Securitization/CancelPoolCalculation",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async calculateIteration(iterationUpdate) {
        var result = await this.requestJson({
            url: "api/Securitization/CalculateIteration",
            method: "POST",
            data: iterationUpdate
        });

        return result;
    }

    static async cancelIterationCalculation(id) {
        var result = await this.requestJson({
            url: "api/Securitization/CancelIterationCalculation",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async getPoolPreferentialMortgageDistribution(poolId) {

        return ServiceBase.requestWithCancellation("getPoolPreferentialMortgageDistribution", (token) => this.requestJson({
            url: "api/Securitization/GetPoolPreferentialMortgageDistribution",
            method: "GET",
            cancellationToken: token,
            data: { poolId: poolId }
        }));
    }

    static async getPoolIncomeConfirmedDistribution(poolId) {

        return ServiceBase.requestWithCancellation("getPoolIncomeConfirmedDistribution", (token) => this.requestJson({
            url: "api/Securitization/GetPoolIncomeConfirmedDistribution",
            method: "GET",
            cancellationToken: token,
            data: { poolId: poolId }
        }));
    }

    static async getPoolRegionDistribution(poolId) {

        return ServiceBase.requestWithCancellation("getPoolRegionDistribution", (token) => this.requestJson({
            url: "api/Securitization/GetPoolRegionDistribution",
            method: "GET",
            cancellationToken: token,
            data: { poolId: poolId }
        }));
    }

    static async getPoolProductDistribution(poolId) {

        return ServiceBase.requestWithCancellation("getPoolProductDistribution", (token) => this.requestJson({
            url: "api/Securitization/GetPoolProductDistribution",
            method: "GET",
            cancellationToken: token,
            data: { poolId: poolId }
        }));
    }

    static async getPoolConstructionDistribution(poolId) {

        return ServiceBase.requestWithCancellation("getPoolConstructionDistribution", (token) => this.requestJson({
            url: "api/Securitization/GetPoolConstructionDistribution",
            method: "GET",
            cancellationToken: token,
            data: { poolId: poolId }
        }));
    }

    static async getPoolCollateralDistribution(poolId) {

        return ServiceBase.requestWithCancellation("getPoolCollateralDistribution", (token) => this.requestJson({
            url: "api/Securitization/GetPoolCollateralTypeDistribution",
            method: "GET",
            cancellationToken: token,
            data: { poolId: poolId }
        }));
    }
    
    

    static async GetScurveCPR(date, WAC) {
        var result = await this.requestJson({
            url: "api/Securitization/GetScurveCPR",
            method: "GET",
            data: { date: date, WAC: WAC }
        });

        return result;
    }

    static async getPoolData(poolId, chartName, step, lod) {

        return ServiceBase.requestWithCancellation("getPoolData", (token) => this.requestJson({
            url: "api/Securitization/GetPoolData",
            method: "GET",
            cancellationToken: token,
            data: { poolId: poolId, chartName: chartName, step: step /*, lod: lod*/ }
        }));
    }

    static async getPoolParameters(poolId) {
        var result = await this.requestJson({
            url: "api/Securitization/GetPoolParameters",
            method: "GET",
            data: { id: poolId }
        });

        return result;
    }

    static async getIterationOnlineUpdates(iterationId) {

        var result = await this.requestJson({
            url: "api/Securitization/GetIterationOnlineUpdates",
            method: "GET",
            data: { iterationId: iterationId }
        });

        return result;
    }

    static async getDealOnlineUpdates(dealId) {

        var result = await this.requestJson({
            url: "api/Securitization/GetDealOnlineUpdates",
            method: "GET",
            data: { dealId: dealId }
        });

        return result;
    }

    static async sendIterationChange(data) {
        var result = await this.requestJson({
            url: "api/Securitization/SendIterationChange",
            method: "POST",
            data: data
        });

        return result;
    }

    static async sendDealChange(data) {
        var result = await this.requestJson({
            url: "api/Securitization/SendDealChange",
            method: "POST",
            data: data
        });

        return result;
    }

    static async resetIterationChanges(nodeId) {

        var result = await this.requestJson({
            url: "api/Securitization/ResetIterationOnlineUpdates",
            method: "GET",
            data: { nodeId: nodeId }
        });

        return result;
    }

    static async resetDealChanges(dealId) {

        var result = await this.requestJson({
            url: "api/Securitization/ResetDealOnlineUpdates",
            method: "GET",
            data: { dealId: dealId }
        });

        return result;
    }

    static async getZCYCDate(date) {
        var result = await this.requestJson({
            url: "api/Securitization/GetZCYCDate",
            method: "GET",
            data: { date: date }
        });

        return result;
    }

    static async getZCYCDateTime(date) {
        var result = await this.requestJson({
            url: "api/Securitization/GetZCYCDateTime",
            method: "GET",
            data: { date: date }
        });

        return result;
    }

    static async getOriginatorsWithDeals(userId) {
        var result = await this.requestJson({
            url: "api/Securitization/GetOriginatorsWithDeals",
            method: "GET",
            data: { userId: userId }
        });

        return result;
    }

    static async getDealsToCompare(dealsId) {
        var result = await this.requestJson({
            url: "api/Securitization/GetDealsToCompare",
            method: "GET",
            data: { dealsId: dealsId }
        });

        return result;
    }

    static async getPoolsToCompare(poolsId) {
        var result = await this.requestJson({
            url: "api/Securitization/GetPoolsToCompare",
            method: "GET",
            data: { poolsId: poolsId }
        });

        return result;
    }

    static async getNodeWithChanges(nodeId) {
        return ServiceBase.requestWithCancellation("getNodeWithChanges", (token) => this.requestJson({
            url: "api/Securitization/GetNodeWithChanges",
            method: "GET",
            cancellationToken: token,
            data: { nodeId: nodeId }
        }));
    }

    static async updateNode(nodeUpdate) {
        var result = await this.requestJson({
            url: "api/Securitization/UpdateNode",
            method: "POST",
            data: nodeUpdate
        });

        return result;
    }

    static async removeNode(nodeId) {
        var result = await this.requestJson({
            url: "api/Securitization/RemoveNode",
            method: "GET",
            data: {nodeId: nodeId}
        });

        return result;
    }

    static async removeDeal(dealId) {
        var result = await this.requestJson({
            url: "api/Securitization/RemoveDeal",
            method: "GET",
            data: { dealId: dealId }
        });

        return result;
    }

    static async archiveDeal(dealId) {
        var result = await this.requestJson({
            url: "api/Securitization/ArchiveDeal",
            method: "GET",
            data: { dealId: dealId }
        });

        return result;
    }

    static async removeOnlineChanges(update) {
        var result = await this.requestJson({
            url: "api/Securitization/RemoveOnlineChanges",
            method: "POST",
            data: update
        });

        return result;
    }

    static async convention2(data) {
        var result = await this.requestJson({
            url: "api/Securitization/convention2",
            method: "POST",
            data: data
        });

        return result;
    }

    /**
     * 
     * @param  {dealID: number, bondState: eBondIssueState} config
     * @returns {Deal}
     */
    static async createDealBond(config) {
        var result = await this.requestJson({
            url: "api/Securitization/CreateDealBond",
            method: "POST",
            data: config
        });

        return result;
    }

    static async convertDealRIPToProtfolio(dealId) {
        var result = await this.requestJson({
            url: "api/Securitization/convertDealRIPToProtfolio",
            method: "GET",
            data: {iDealID: dealId}
        });

        return result;
    }
}