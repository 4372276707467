import { useState, useRef, useEffect } from 'react';
import { actionCreators as calculatorStore } from "@Store/CalculatorStore";
import { Tabs, Tab } from "@mui/material";
import { ValidatorForm, TextValidator, SelectValidator, DatePickerValidator, AutocompleteComponent, TextInputComponent } from "../../../framework/components/Form";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Localization from '@Components/Localization';
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
import clsx from 'clsx';
import stringInject from 'stringinject';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'

import { Theme } from '@mui/material';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import UniversalInputField, { InputDataType } from '@Base/framework/components/Form/UniversalInputField'
import CustomTooltip from '@Components/shared/CustomTooltip';

const useStyles = makeStyles((theme: Theme) => createStyles({
    paddingBottom: {
        paddingBottom: '1rem'
    },
    paddingTop: {
        paddingTop: '1rem'
    },
    flex: {
        display: 'flex'
    },
    rowBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        lineHeight: '2rem'
    },
    rowValuesBlock: {
        display: 'flex',
        flexWrap: 'nowrap',
        textAlign: 'right',
        justifyContent: 'flex-end',
        flexGrow: 1,
        // lineHeight: '32px',
        alignItems: 'baseline',
    },
    rowName: {
        flexShrink: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1rem',
        minWidth: '120px'
    },
    leftColumn: {
        paddingRight: '0.5rem'
    },
    rightColumn: {
        paddingLeft: '0.5rem'
    },
    //dialogPaper: {
    //    minHeight: '442px'
    //},
    fileField: {
        paddingTop: '16px',
        marginLeft: '5px'
    }
}));

interface IProps {

    row: any;
    onChange: any;
    onApplyChanges: any;
    onCancelChanges: any;
    open: boolean;
    title: string;
}

const OriginatorEditorPage = ({
    row,
    onChange,
    onApplyChanges,
    onCancelChanges,
    open,
    title
}: IProps) => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();

    const editFormRef = useRef(null);
    const updatedFieldsRef = useRef({});

    const [localData, setLocalData] = useState<any>({});

    const {
        items
    } = useSelector((state: any) => state.calculator)

    useEffect(() => {
        calculatorStore.findProductsQuery(null, row?.productId, 0, 50)(dispatch);
    }, []);
    
    useEffect(() => {

        setLocalData(row || {}); // { ...row, ...localData });

    }, [row]);

    const handleInputChange = (name: string, value: any) => {

        if (localData[name] == value)
            return;

        onChange(name, value);

        setLocalData({ ...localData, [name]: value });
        updatedFieldsRef.current[name] = value;
    };

    const handleSubmit = () => {
        onApplyChanges(localData, updatedFieldsRef.current);
    }

    const onValidateChanges = (event) => {
        editFormRef.current.submit();
    }

    const onCancelChanges_Local = (event: any, reason: string) => {
        if (reason == 'backdropClick') {
            event.preventDefault();
            return;
        }

        onCancelChanges(event);
    }

    const locale = {
        DragOrSelectFileText: t('DragOrSelectFileText'),
        MaximumNumberFilesExceededFn: (filesLimit: number) => stringInject(t('MaximumNumberFilesExceeded_Params'), [filesLimit]),
        FileAddedFn: (fileName: string) => stringInject(t('FileAdded_Params'), [fileName]),
        FileRemoved: (fileName: string) => stringInject(t('FileRemoved_Params'), [fileName]),
        FileRejectedFn: (rejectedFile: string) => stringInject(t('FileRejected_Params'), [rejectedFile]),
        FileIsTooBigFn: (maxFileSize: string) => stringInject(t('FileIsTooBig_Params'), [maxFileSize]),
        FileTypeNotSupported: t('FileTypeNotSupported'),
        Preview: t('Preview'),
    }

    return (
        <Dialog
            /*disableBackdropClick*/
            classes={{ paper: classes.dialogPaper }}
            disableEscapeKeyDown
            fullWidth
            maxWidth='sm'
            open={open}
            scroll="paper"
            onClose={onCancelChanges_Local}>
            <DialogTitle>
                <Box sx={{ display: 'flex' }}>
                    <span>{title}</span>
                </Box>
            </DialogTitle>

            <DialogContent dividers>
                <ValidatorForm ref={editFormRef} onSubmit={handleSubmit}>
                    <Grid container sx={{ paddingBottom: '1rem' }}>
                        <Grid xs={6} className={classes.leftColumn}>
                            <Box className={classes.rowBlock}>
                                <Box className={classes.rowName}>
                                    <CustomTooltip
                                        content={t('originators.createdDate')}
                                        placement="left-start"
                                        hideIcon
                                        sx={{ ml: '5px', pb: '4px' }}
                                    >
                                        {t('CreatedDate')}
                                    </CustomTooltip>
                                </Box>
                                <Box className={classes.rowValuesBlock}>
                                    <UniversalInputField
                                        name='createdDate'
                                        disabled={true}
                                        dataType={InputDataType.DATE}
                                        handleValueChange={handleInputChange}
                                        value={localData.createdDate}
                                        fullWidth
                                        validators={['required']}
                                        errorMessages={[t('FieldRequired')]}
                                    />
                                </Box>
                            </Box>

                            <Box className={classes.rowBlock}>
                                <Box className={classes.rowName}>
                                    <CustomTooltip
                                        content={t('originators.shortName')}
                                        placement="left-start"
                                        hideIcon
                                        sx={{ ml: '5px', pb: '4px' }}
                                    >
                                        {t('ShortName')}
                                    </CustomTooltip>
                                </Box>
                                <Box className={classes.rowValuesBlock}>
                                    <UniversalInputField
                                        name='shortName'
                                        dataType={InputDataType.STRING}
                                        handleValueChange={handleInputChange}
                                        value={localData.shortName}
                                        fullWidth
                                        validators={['required']}
                                        errorMessages={[t('FieldRequired')]}
                                    />
                                </Box>
                            </Box>

                        </Grid>
                        <Grid xs={6} className={classes.rightColumn}>

                            <Box className={classes.rowBlock}>
                                <Box className={classes.rowName}>
                                    <CustomTooltip
                                        content={t('originators.name')}
                                        placement="left-start"
                                        hideIcon
                                        sx={{ ml: '5px', pb: '4px' }}
                                    >
                                        {t('Name')}
                                    </CustomTooltip>
                                </Box>
                                <Box className={classes.rowValuesBlock}>
                                    <UniversalInputField
                                        name='name'
                                        dataType={InputDataType.STRING}
                                        handleValueChange={handleInputChange}
                                        value={localData.name}
                                        fullWidth
                                        validators={['required']}
                                        errorMessages={[t('FieldRequired')]}
                                    />
                                </Box>
                            </Box>

                            <Box className={classes.rowBlock}>
                                <Box className={classes.rowName}>
                                    <CustomTooltip
                                        content={t('originators.englishName')}
                                        placement="left-start"
                                        hideIcon
                                        sx={{ ml: '5px', pb: '4px' }}
                                    >
                                        {t('EnglishName')}
                                    </CustomTooltip>
                                </Box>
                                <Box className={classes.rowValuesBlock}>
                                    <UniversalInputField
                                        name='nameEN'
                                        dataType={InputDataType.STRING}
                                        handleValueChange={handleInputChange}
                                        value={localData.nameEN}
                                        fullWidth
                                        validators={['required']}
                                        errorMessages={[t('FieldRequired')]}
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid xs={6} className={classes.leftColumn}>

                            <Box className={classes.rowBlock}>
                                <Box className={classes.rowName}>
                                    <CustomTooltip
                                        content={t('originators.product')}
                                        placement="left-start"
                                        hideIcon
                                        sx={{ ml: '5px', pb: '4px' }}
                                    >
                                        {t('Product')}
                                    </CustomTooltip>
                                </Box>
                                <Box className={classes.rowValuesBlock}>
                                    <UniversalInputField
                                        name='productId'
                                        dataType={InputDataType.ENUM_AUTOCOMPLETE}
                                        handleValueChange={handleInputChange}
                                        locale={locale}
                                        value={localData.productId}
                                        getOptionLabel={(x) => x.name}
                                        getKey={(x) => x?.id}
                                        dataSource={items}
                                        fullWidth
                                        validators={['required']}
                                        errorMessages={[t('FieldRequired')]}
                                    />
                                </Box>
                            </Box>

                        </Grid>

                        <Grid xs={6} className={classes.rightColumn}>

                            <Box className={classes.rowBlock}>
                                <Box className={classes.rowName}>
                                    <CustomTooltip
                                        content={t('originators.shared')}
                                        placement="left-start"
                                        hideIcon
                                        sx={{ ml: '5px', pb: '4px' }}
                                    >
                                        {t('Shared')}
                                    </CustomTooltip>
                                </Box>
                                <Box className={classes.rowValuesBlock}>
                                    <UniversalInputField
                                        name='isShared'
                                        dataType={InputDataType.BOOLEAN}
                                        handleValueChange={handleInputChange}
                                        value={localData.isShared}
                                        fullWidth
                                    />
                                </Box>
                            </Box>

                            <Box className={classes.rowBlock}>
                                <Box className={classes.rowName}>
                                    <CustomTooltip
                                        content={t('originators.visibleAtInternet')}
                                        placement="left-start"
                                        hideIcon
                                        sx={{ ml: '5px', pb: '4px' }}
                                    >
                                        {t('VisibleAtInternet')}
                                    </CustomTooltip>
                                </Box>
                                <Box className={classes.rowValuesBlock}>
                                    <UniversalInputField
                                        name='isVisibleAtInternet'
                                        dataType={InputDataType.BOOLEAN}
                                        handleValueChange={handleInputChange}
                                        value={localData.isVisibleAtInternet}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid xs={12}>
                            <Box className={classes.rowBlock}>
                                <Box className={classes.rowName}>
                                    <CustomTooltip
                                        content={t('originators.description')}
                                        placement="left-start"
                                        hideIcon
                                        sx={{ ml: '5px', pb: '4px' }}
                                    >
                                        {t('Description')}
                                    </CustomTooltip>
                                </Box>
                                <Box className={classes.rowValuesBlock}>
                                    <UniversalInputField
                                        name='description'
                                        dataType={InputDataType.STRING}
                                        handleValueChange={handleInputChange}
                                        value={localData.description}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </ValidatorForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelChanges_Local} variant="contained" color="primary">{t("Cancel")}</Button>
                <Button onClick={onValidateChanges} variant="contained" color="secondary">{t("Save")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default OriginatorEditorPage;
