import React, { useState, useEffect, useRef, forwardRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from 'react-i18next'

import isValid from 'date-fns/isValid';
import format from 'date-fns/format';

import { createStyles, makeStyles } from '@mui/styles'
import { Paper, Stack, alpha, Box, Container, Tab, Tabs, Theme, Button, Typography, IconButton, TextField, InputAdornment, DialogActions } from '@mui/material'

import InsertChartIcon from '@mui/icons-material/InsertChart';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Spinner } from "@Components/shared/Spinner";
import ChartsDialog from './ChartsDialog';
import style from '@Styles/domrf-theme.module.scss'

import { actionCreators as actionDomRF } from '../../../store/DomRfStore'
import { actionCreators as actionTopNavbar } from '../../../store/TopNavbarStore'

import Localization from '@Components/Localization';
// import DefaultDatePicker from '@Framework/components/Form/DefaultDatePicker';
import TableInfoTooltip from '@Components/shared/TableInfoTooltip';
import { MobileDatePicker } from '@mui/x-date-pickers-pro'
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridActionsCellItem,
    GridColumnGroupingModel,
    GridComparatorFn,
    GridValueFormatterParams,
    GridRowModel,
    GridCallbackDetails,
    MuiEvent,
    GridRowParams,
    GridCellParams
} from '@mui/x-data-grid-premium';
import { ruRU, enUS } from '@mui/x-data-grid/locales';

import { AddOutlined, Calculate, CloseRounded, Kitesurfing, TodayOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import { DatePicker } from '@mui/x-date-pickers';
import { id } from 'date-fns/locale';
import { positions } from '@mui/system';
import { useNavigate } from 'react-router';
import { Instruments } from '@Base/components/Instruments';

const useStyles = makeStyles((theme: Theme) => createStyles({
    endAdornment: {
        // margin: '0 0.5rem',
        // padding: 0,
    },
    markedCell: {
        backgroundColor: 'rgba(255,0,0,0.2)',
    },
    cellRoot: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        outline: 'none !important',
    },
    cellRowValueMinus: {
        color: 'rgba(255,128,128,1)',
        '&:before': {
            content: '"▼"',
            fontSize: '0.8rem',
            lineHeight: '1rem',
            alignSelf: 'center',
            color: 'rgba(255,128,128,1)',
        },
    },
    cellRowValuePlus: {
        color: style.colorPrimary2,
        '&:before': {
            content: '"▲"',
            fontSize: '0.8rem',
            lineHeight: '1rem',
            alignSelf: 'center',
            color: style.colorPrimary2,
        },
    },
    cellRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        lineHeight: '1rem',
        margin: '2px 0',
        height: '1rem',
    },
    icon: {
        width: '18px',
        height: '18px',
    },
    chartIcon: {
        width: '21px',
        height: '21px',
    },
    dateChip: {
        marginRight: '0.5rem',
        padding: '0 0.5rem 0 0.5rem',
        backgroundColor: alpha(style.colorPrimary3, 0.1),
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        maxWidth: '9rem',
    },
    dateBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginRight: 'auto'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        flex: '1 1 auto',
        height: 0,
        width: '100%',
        minWidth: '100%'
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        height: '100%'
        //marginTop: '1.5rem',
        //'& .MuiTableSortLabel-root > svg': {
        //    margin: 0,
        //    padding: 0,
        //    fontSize: '1rem',
        //    position: 'absolute',
        //    bottom: '-1rem',
        //    left:'Calc( 50% - 0.5rem )',
        //},
        // '& .Title-title':{width: 'min-content'}
    },
    headerCell: {
        textAlign: 'center',
        '& div': {
            justifyContent: 'center',
            '& div': {
                textAlign: 'center'
            }
        },

        //paddingLeft: '0 !important',
        //paddingRight: '0'
    },
    rowHeader: {
        '& :first-child': {
            paddingLeft: '0'
        }
    },
    bandHeaderCell: {
        textAlign: 'center'
    },
    table: {
        width: '100%',
        height: '100%',
        '& tbody tr:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
        }
    },
    stubCell: {
        backgroundColor: 'white'
    }
}));

interface IState {
    evaluationDate: any;
    zcycDate: any;
    showChart: boolean;
    calculationId: number;

    items: any[];
    count: number;

    startPageIndex: number;
    pageSize: number;
    pageSizes: number[];

    locale: any;
    columns: any[];
    actionColumns: any[];
    doubleColumns: any[];
    leftColumns: any[];
    rightColumns: any[];
    columnBands: any[];
    tableColumnExtensions: any[];
    columnWidths: any[];
    columnCustomWidths: any[];

    hiddenColumnNames: any[];
    columnVisibility: any[];
    sorting: any[]
    integratedSortingColumnExtensions: any[]
}

interface IProps {
    cls: any;
    history: any;

    getSystemCalculations: (filter: any) => void;
    data: any;

    isCalculationLoading: boolean;

    t: any;
}

const DateChip = (props) => {
    const { index, value, zcycDateArr, setZcycDateArr, setSelectedDate, ...otherProps } = props

    const handleRemoveDate = (index) => {
        let newArr = [...zcycDateArr]
        newArr.splice(index, 1)
        setZcycDateArr(newArr)
        setSelectedDate(null)
    }

    const cls = useStyles()
    // console.log('props', props)
    return <Box className={cls.dateChip} key={`dateInput_${index}`}>

        <TextField {...otherProps}
            sx={{
                '& input': { cursor: 'pointer' },
                '& .MuiInputBase-root': { cursor: 'pointer' },
                '& .MuiInput-underline:before': { border: 'none !important' },
                '& .MuiInput-underline:after': { border: 'none !important' }
            }}
            value={Localization.FormatDateLocale(value)}
            // value={value}
            InputProps={{

                startAdornment:
                    Boolean(index != 0) && <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleRemoveDate(index) }} disabled={Boolean(index == 0)}>
                        <CloseRounded className={[cls.icon].join(' ')} />
                    </IconButton>

                , endAdornment:
                    < InputAdornment position="end" >
                        {/* React.cloneElement(props?.InputProps?.endAdornment?.props?.children, { size: 'small', className: cls.endAdornment, }) */}
                        < IconButton size="small">
                            <TodayOutlined />
                        </IconButton>
                    </InputAdornment>
            }}
        />
    </Box>
}

const MSFlowsPage = ({ }: IProps) => {

    const cls = useStyles()
    const navigate = useNavigate()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();
    const elRef = useRef()
    var date = new Date();
    date.setDate(date.getDate() - 1);

    //const [evaluationDate, setEvaluationDate] = useState(date);
    const [zcycDate, setZcycDate] = useState(date);
    const [zcycDateArr, setZcycDateArr] = useState([date])
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    const [startPageIndex, setStartPageIndex] = useState(0); //CHECKME!!! add to user settings
    const [pageSize, setPageSize] = useState(100); //CHECKME!!! add to user settings

    const [showChart, setShowChart] = useState(false);
    const [calculationId, setCalculationId] = useState(null);
    const [locale, setLocale] = useState(ruRU);
    const [maxDate, setMaxDate] = useState(date);
    const [selectedDate, setSelectedDate] = useState(null)
    const {
        data,
        isCalculationLoading = false
    } = useSelector((state: any) => state.domrf)

    // console.log('data', data)
    useEffect(() => {
        actionTopNavbar.setTitle(t('IsDomRF'))(dispatch)
        return () => {
            actionTopNavbar.setTitle("")(dispatch)
        }
    }, [])

    useEffect(() => {
        if (i18n.language == 'ru') {
            setLocale(ruRU);
        }
        else {
            setLocale(enUS);
        }


    }, [i18n]);

    useEffect(() => {
        // console.log('updateItems')
        setItems(data?.calculations/*?.map((x) => { return { ...x, id: x.isin } }) */ ?? []);
        setCount(data?.calculations?.length ?? 0);

    }, [data]);

    useEffect(() => {

        updateDataSource();

    }, [zcycDateArr]);

    const customBondNameComparator: GridComparatorFn<string> = (a, b) => {

        const a_digs = a?.match(/([0-9]+)/g)?.join().padStart(10, '0').slice(-5)
        const a_ = a?.toUpperCase()?.replace(/([0-9]+)/g, a_digs ? a_digs : '')
        const b_digs = b?.match(/([0-9]+)/g)?.join().padStart(10, '0').slice(-5)
        const b_ = b?.toUpperCase()?.replace(/([0-9]+)/g, b_digs ? b_digs : '')
        // console.log('a', a_, a_digs)

        return (a_ > b_) ? 1 : (a_ < b_) ? -1 : 0
    }

    const doubleValueFormatter = (value, row, colDef, apiRef) => {
        const formatterFunc = (value) => {
            if (value == null)
                return '';

            return Localization.FormatNumber(value);
        }
        if (Array.isArray(value)) {
            const val = value?.map(v => formatterFunc(v))
            // console.log('val', val)
            return val
        }
        else {
            return formatterFunc(value)
        }
    }

    const rateValueFormatter = (value, row, colDef, apiRef) => {
        const formatterFunc = (value) => {
            if (value == null) {
                return '–';
            }

            return Localization.FormatNumber(value);
        }
        if (Array.isArray(value)) {
            const val = value?.map(v => formatterFunc(v))
            // console.log('val', val)
            return val
        }
        else {
            return formatterFunc(value)
        }
    }

    const nominalValueFormatter = (value, row, colDef, apiRef) => {
        const formatterFunc = (value) => {
            if (value == 1000) {
                return '1000';
            }

            return Localization.FormatNumber(value);
        }

        if (Array.isArray(value)) {
            const val = value?.map(v => formatterFunc(v))
            // console.log('val', val)
            return val
        }
        else {
            return formatterFunc(value)
        }
    }

    const doubleFormatter = (options: any) => (value, row, colDef, apiRef) => {

        const formatterFunc = (value) => {
            if (value == null)
                return '';

            var fractionPart = 2;
            var result = value;
            if (options.ceilTo != null) {
                var multiplier = Math.pow(10, options.ceilTo);
                result = Math.ceil(result * multiplier) / multiplier;
                fractionPart = options.ceilTo;
            }
            else if (options.roundTo != null) {
                var multiplier = Math.pow(10, options.roundTo);
                result = Math.round(result * multiplier) / multiplier;
                fractionPart = options.roundTo;
            }
            else if (options.fractionPart != null)
                fractionPart = options.fractionPart;

            return Localization.FormatNumber(result, fractionPart, options.fractionPart);
        }

        if (Array.isArray(value)) {
            const val = value?.map(v => formatterFunc(v))
            return val
        }
        else {
            return formatterFunc(value)
        }
    }

    const dateValueFormatter = (value, row, colDef, apiRef) => {

        const formatterFunc = (value) => {
            if (value == null)
                return '';

            return Localization.FormatDateStringLocale(value);
        }


        if (Array.isArray(value)) {
            const val = value?.map(v => formatterFunc(v))
            return val
        }
        else {
            return formatterFunc(value)
        }
    }

    const onCalculationOpen = (row: any) => {
        if (!row?.calculationID)
            return
        var instrument = Instruments().find(x => x.area === 'Calculator');
        if (instrument != null) {
            var action = instrument.actions.find(x => x.id === 'calculationResults');
            if (action != null) {
                window.open(action.url.split(':')[0] + row.calculationID, "_blank")
                // navigate(action.url.split(':')[0] + row.calculationID, {replace:false});
            }
        }
    }

    // const getNumber = (theNumber) => {
    //     if (theNumber > 0) {
    //         return "+" + theNumber
    //     } else {
    //         return theNumber.toString()
    //     }
    // }
    const dateReg = new RegExp('\\d{2}\.\\d{2}\.\\d{4}')
    const renderCell = (params) => {
        if (params?.formattedValue == null)
            return

        // console.log('params', params)
        if (Array.isArray(params?.formattedValue)) {
            return (
                <Box className={cls.cellRoot}>
                    {params?.formattedValue?.map((value, index) => {

                        if (dateReg.test(value)) {
                            // console.log('reg.test(value)', value, dateReg.test(value))
                            return <Box key={`cell_${params?.field}_${index}`} className={cls.cellRow}>{value}</Box>
                        }
                        const prevVal = index > 0 ? parseFloat(params?.formattedValue[index - 1]?.replace(',', '.')) : null
                        const v2 = parseFloat(value?.replace(',', '.'))
                        // console.log('v2 - prevVal', !isNaN(v2), !isNaN(prevVal))
                        const delta = prevVal != null && !isNaN(prevVal) && v2 != null && !isNaN(v2) ? v2 - prevVal : 0
                        // console.log('index > 0 ? params?.formattedValue[index - 1] : null', index, params?.formattedValue, delta)
                        return <Box key={`cell_${params?.field}_${index}`} className={[cls.cellRow, delta == 0 ? '' : delta > 0 ? cls.cellRowValuePlus : cls.cellRowValueMinus].join(' ')}
                            sx={{ justifyContent: params?.colDef?.align }}
                        >
                            <Box>{value}</Box>
                        </Box>
                    })
                    }
                </Box>
            )
        }
        else {
            return (
                <Box className={cls.cellRoot}>
                    {params?.formattedValue}
                </Box>
            )
        }
    }

    const cellParams = {
        // sortable: false,
        filterable: false,
        // disableReorder: true,
        hideable: false,
        // resizable: false,
        renderCell: renderCell
    }

    const columns: GridColDef[] = [

        { ...cellParams, field: 'bondName', headerName: t('Deal'), description: t('Deal_Info'), width: 150, sortComparator: customBondNameComparator, headerClassName: "headerOutline" },
        { ...cellParams, field: 'isin', headerName: t('ISIN'), description: t('ISIN_Info'), width: 140, headerClassName: "headerOutline" },
        { ...cellParams, field: 'zcycDate', sortable: false, headerName: t('Quotes_calcDate'), description: t('calcDate_info'), align: 'right', width: 110, headerClassName: "headerOutline", valueFormatter: dateValueFormatter },

        { ...cellParams, field: 'currentNominal', headerName: t('CurrentNominal'), description: t('CurrentNominal_Info'), type: 'number', width: 150, headerClassName: "headerOutline", valueFormatter: nominalValueFormatter },
        { ...cellParams, field: 'rate', headerName: t('CouponRate'), description: t('CouponRate_Info'), type: 'number', width: 120, headerClassName: "headerOutline", valueFormatter: rateValueFormatter },
        { ...cellParams, field: 'spreadOAS', headerName: t('OAS'), description: t('OAS_Info'), type: 'number', width: 80, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ fractionPart: 0 }) },
        { ...cellParams, field: 'zeroVolOAS', headerName: t('ZeroOAS'), description: t('ZeroOAS_Info'), type: 'number', width: 90, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ roundTo: 0 }) },
        { ...cellParams, field: 'oasDifference', headerName: t('OASDifference'), description: t('OASDifference_Info'), type: 'number', width: 120, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ fractionPart: 0 }) },
        //{...cellParams, field: 'convZSpread', headerName: t('ConvZSpread'), description: t('ConvZSpread_Info'), roundTo: 0 },
        { ...cellParams, field: 'gSpread', headerName: t('GSpread'), description: t('GSpread_Info'), type: 'number', width: 100, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ roundTo: 0 }) },
        { ...cellParams, field: 'dirtyPrice', headerName: t('DirtyPrice'), description: t('DirtyPrice_Info'), type: 'number', width: 130, headerClassName: "headerOutline", valueFormatter: doubleValueFormatter },
        { ...cellParams, field: 'clearPrice', headerName: t('CleanPrice'), description: t('CleanPrice_Info'), type: 'number', width: 130, headerClassName: "headerOutline", valueFormatter: doubleValueFormatter },
        { ...cellParams, field: 'ytm', headerName: t('YTM'), description: t('YTM_Info'), type: 'number', width: 120, headerClassName: "headerOutline", valueFormatter: doubleValueFormatter },
        { ...cellParams, field: 'macaulayDuration', headerName: t('MacaulayDuration'), description: t('MacaulayDuration_Info'), type: 'number', width: 130, headerClassName: "headerOutline", valueFormatter: doubleValueFormatter },
        { ...cellParams, field: 'effectiveDuration', headerName: t('EffectiveDuration'), description: t('EffectiveDuration_Info'), type: 'number', width: 130, headerClassName: "headerOutline", valueFormatter: doubleValueFormatter },
        { ...cellParams, field: 'wac', headerName: t('WAC'), description: t('WAC_Info'), type: 'number', width: 80, headerClassName: "headerOutline", valueFormatter: doubleValueFormatter },
        { ...cellParams, field: 'wam', headerName: t('WAM'), description: t('WAM_Info'), type: 'number', width: 80, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ ceilTo: 0 }) },
        { ...cellParams, field: 'cprModel', headerName: t('Model'), description: t('CPRModel_Info'), type: 'number', width: 130, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ roundTo: 1 }) },
        { ...cellParams, field: 'cprHist', headerName: t('Historical'), description: t('CPRHist_Info'), type: 'number', width: 140, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ roundTo: 1 }) },
        { ...cellParams, field: 'cdrModel', headerName: t('Model'), description: t('CDRModel_Info'), type: 'number', width: 130, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ roundTo: 1 }) },
        { ...cellParams, field: 'cdrHist', headerName: t('Historical'), description: t('CDRHist_Info'), type: 'number', width: 140, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ roundTo: 1 }) },
        { ...cellParams, field: 'cprSensitivityP', headerName: t('ToIncrease'), description: t('CPRSensitivityP_Info'), type: 'number', width: 140, headerClassName: "headerOutline", valueFormatter: doubleValueFormatter },
        { ...cellParams, field: 'cprSensitivityM', headerName: t('ToDecrease'), description: t('CPRSensitivityM_Info'), type: 'number', width: 140, headerClassName: "headerOutline", valueFormatter: doubleValueFormatter },
        { ...cellParams, field: 'expenses', headerName: t('Expenses'), description: t('Expenses_Info'), type: 'number', width: 100, headerClassName: "headerOutline", valueFormatter: doubleValueFormatter },
        { ...cellParams, field: 'swapMortgageAgentPV', headerName: t('MortgageAgentSwap_Short'), description: t('MortgageAgentSwap_Info'), type: 'number', width: 120, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ roundTo: 1 }) },
        { ...cellParams, field: 'swapOriginatorPV', headerName: t('OriginatorSwap_Short'), description: t('OriginatorSwap_Info'), type: 'number', width: 130, headerClassName: "headerOutline", valueFormatter: doubleFormatter({ roundTo: 1 }) },
        //{...cellParams, field: 'convZSpreadHist', headerName: t('ZSpread'), description: t('ConvZSpreadHist_Info'), roundTo: 0 },
        //{...cellParams, field: 'convIRRHist', headerName: t('YTM'), description: t('ConvIRRHist_Info') },
        //{...cellParams, field: 'convMacDurationHist', headerName: t('Duration'), description: t('ConvMacDurationHist_Info') },
        //{...cellParams, field: 'convPriceHist', headerName: t('Price'), description: t('ConvPriceHist_Info') },
        {
            field: 'actions',
            type: 'actions',
            width: 60,
            // getActions: (params) => {
            //     return params?.row?.calculationID?.map(calcId => <GridActionsCellItem icon={<InsertChartIcon />} label={t('Charts')} onClick={() => handleCharts({ id: calcId })} />)
            //     console.log('params', params)
            //     return [
            //         <GridActionsCellItem icon={<InsertChartIcon />} label={t('Charts')} onClick={() => handleCharts(params)} />
            //     ]
            // },
            renderCell: (params) => {
                return <Box className={cls.cellRoot}>
                    {params?.row?.calculationID?.map((calcId, index) => <Box key={`cell_calc_${params?.field}_${index}`} className={cls.cellRow}>
                        {Boolean(calcId) &&
                            <>
                                <IconButton
                                    onClick={() => handleCharts({ id: calcId })}
                                    size="small"
                                >
                                    <InsertChartIcon className={cls.chartIcon} />
                                    {/* <GridActionsCellItem icon={<InsertChartIcon />} label={t('Charts')} onClick={() => handleCharts({ id: calcId })} /> */}
                                </IconButton>
                                <IconButton
                                    onClick={() => onCalculationOpen({ calculationID: calcId })}
                                    size="small"
                                >
                                    <Kitesurfing className={cls.chartIcon} />
                                    {/* <GridActionsCellItem icon={<InsertChartIcon />} label={t('Charts')} onClick={() => handleCharts({ id: calcId })} /> */}
                                </IconButton>
                            </>}
                    </Box>)}
                </Box>
            }
        }
    ];

    const columnGrouping: GridColumnGroupingModel = [
        {
            groupId: 'CPR',
            headerName: t('CPR_AsIs'),
            headerClassName: "headerOutline",
            children: [{ ...cellParams, field: 'cprModel' }, { ...cellParams, field: 'cprHist' }]
        },
        {
            groupId: 'CDR',
            headerName: t('CDR_AsIs'),
            headerClassName: "headerOutline",
            children: [{ ...cellParams, field: 'cdrModel' }, { ...cellParams, field: 'cdrHist' }]
        },
        {
            groupId: 'CPRSensitivity',
            headerName: t('CPRSensitivity'),
            headerClassName: "headerOutline",
            children: [{ ...cellParams, field: 'cprSensitivityP' }, { ...cellParams, field: 'cprSensitivityM' }]
        }
    ];

    const handleCharts = (object: any) => {
        // console.log('object', object)
        setShowChart(true);
        setCalculationId(object.id);
    };

    const handleChartClose = (event: any) => {
        setShowChart(false);
    }

    // const handleDateChange = (value) => {
    //     setZcycDate(value);
    // };

    const handleDateChange = (value, index) => {
        let arr
        // console.log('index,', index, zcycDateArr)
        if (zcycDateArr[index] == null) {
            arr = [...zcycDateArr, value]
            setZcycDateArr(arr)
        }
        else {
            if (zcycDateArr[index] != value) {
                arr = [...zcycDateArr]
                arr[index] = value
                setZcycDateArr(arr)
            }
        }
        setSelectedDate(null)
    }

    const handleAddDate = () => {
        // setZcycDateArr([...zcycDateArr, null])
        setSelectedDate(zcycDateArr?.length)
    }

    const onExportClick = (event: React.MouseEvent<HTMLElement>): void => {
        var date = format(zcycDateArr[0], "yyyy-MM-dd");
        window.open('/api/DomRf/DownloadCashFlows?evaluateDate=' + date, '_blank');
    }

    const updateDataSource = () => {

        if (zcycDateArr.some(date => !isValid(date)))
            return;
        /*
    var filterItem = {
                    Operator: "or",
        Values: zcycDateArr.map(date => {
            return (
                {
                    Name: "zcycDate",
                Type: "Equals",
                FirstValue: format(date, "yyyy-MM-dd"),
                DataType: "date"
                })
        }
                )
    };

                var filter = {
                    Filters: filterItem,
                ItemIndex: startPageIndex,
                PageSize: pageSize
    };
                */
        var dates = zcycDateArr.map(x => format(x, "yyyy-MM-dd"));
        actionDomRF.getSystemCalculations(dates)(dispatch);
    }

    const handleClose = (index) => {
        // setZcycDateArr(zcycDateArr.filter(d => d != null))
        setSelectedDate(null)
    }


    const calcRowHeight = (params) => {
        // console.log('params', params)
        return data?.dates?.length * 20 + 10
    }

    const getRowId = (params: GridRowModel) => params?.bondID

    const checkForCalculationPresent = (row) => {
        // console.log('row', row?.calculationID)
        return row?.calculationID?.some(id => id == null) ? cls.markedCell : ''
        // const valArr = Object.values(row)?.filter(values => Array.isArray(values))
        // console.log('valArr', valArr)
        // return zcycDateArr.some((date, index) => valArr[index] != null) ? '' : cls.markedCell
    }
    // console.log('items', items)

    const MyActionBar = ({
        onAccept,
        onCancel,
        onClear,
        onSetToday,
        className,
    }) => {

        return (
            <DialogActions
                className={[className].join(' ')}
                sx={{ textAlign: 'right' }}
            >
                <Button onClick={onCancel}> {t('Cancel')} </Button>
                <Button onClick={onAccept}> {t('OK')} </Button>
            </DialogActions>
        );
    };

    return (
        <Container className={cls.container}>

            <ChartsDialog show={showChart} objectId={calculationId} onClose={handleChartClose} />

            <Paper className={cls.gridContainer}>
                <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingX: '1rem', paddingY: '0.5rem' }}>
                    <Stack className={cls.dateBox} >
                        <Typography sx={{ blockSize: 'fit-content', whiteSpace: 'nowrap', paddingRight: '1rem' }}>{t('EvaluationDate') + ':'}</Typography>
                        {Boolean(zcycDateArr?.length) &&
                            (selectedDate < zcycDateArr?.length ? zcycDateArr : [...zcycDateArr, null])?.map((datePoint, index) => {
                                // console.log('datePoint', datePoint)
                                return (
                                    <MobileDatePicker
                                        mobile={true}
                                        key={`date_${index}`}
                                        value={datePoint}
                                        closeOnSelect={false}
                                        open={selectedDate == index}
                                        onChange={(value) => handleDateChange(value, index)}
                                        onOpen={() => setSelectedDate(index)}
                                        onClose={() => { handleClose(index) }}
                                        // format={'dd.MM.yyyy'}
                                        // format={Localization.ShortDatePattern()}
                                        // slotProps={{
                                        //     inputAdornment: { position: 'end', }
                                        // }}
                                        // inputRef={(ref) => {
                                        //     if (!elRef.current)
                                        //         elRef.current = new Array()
                                        //     elRef.current[index] = ref
                                        // }}
                                        slotProps={{
                                            // popper: { anchorEl: () => elRef?.current[index] },
                                            toolbar: {
                                                hidden: true
                                            },
                                            field: {
                                                index: index,
                                                zcycDateArr: zcycDateArr,
                                                setZcycDateArr: setZcycDateArr,
                                                setSelectedDate: setSelectedDate,
                                            }
                                        }}
                                        slots={{
                                            actionBar: MyActionBar,
                                            field: DateChip
                                        }}
                                    />
                                )
                            })
                        }
                        {/* <DefaultDatePicker
                            value={zcycDate}
                            label={t('EvaluationDate')}
                            maxDate={maxDate}
                            onChange={handleDateChange} /> */}
                        <IconButton size="small" onClick={handleAddDate}>
                            <AddOutlined />
                        </IconButton>
                    </Stack>
                    <TableInfoTooltip content={t('DownloadFlows_Tooltip')}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            //className={cls.button}
                            startIcon={<GetAppIcon />}
                            onClick={onExportClick}>
                            {t('DownloadFlows')}
                        </Button>
                    </TableInfoTooltip>
                </Stack>

                <DataGridPremium
                    //CHECKME!!! it's a workaround only!
                    sx={{

                        ["& .MuiDataGrid-pinnedColumnHeaders--right"]: {
                            width: "60px" //actions
                        },
                        ["& .MuiDataGrid-pinnedColumnHeaders--left"]: {
                            width: "400px" //bondName + isin + zcycDate
                        },
                        ["& .MuiDataGrid-columnHeaderTitleContainer"]: {
                            justifyContent: 'center'
                        },

                        //"& .headerOutline": {
                        //border: '1px solid #E0E0E0'// + style.colorPrimary3_120
                        //}
                        "& .MuiDataGrid-virtualScroller": {
                            fontSize: '1rem'
                        },
                        '& .MuiDataGrid-cell': {
                            // border: 'solid 2px',
                        }
                    }}
                    cellSelection={false}
                    // onRowDoubleClick={(params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => { onCalculationOpen(params?.row) }}
                    // onCellClick={(params: GridCellParams, event: MuiEvent, details: GridCallbackDetails) => {console.log('params', params); return false}}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    loading={isCalculationLoading}
                    columnHeaderHeight={30}
                    // rowHeight={15}
                    getRowHeight={calcRowHeight}
                    // getRowClassName={(params) => checkForCalculationPresent(params?.row)}
                    getCellClassName={(params) => [checkForCalculationPresent(params?.row), cls.cellRoot].join(' ')}
                    // density={'compact'}
                    rows={items}
                    getRowId={getRowId}
                    columns={columns}
                    initialState={{ pinnedColumns: { left: ['bondName', 'isin', 'zcycDate'], right: ['actions'] } }}
                    columnGroupingModel={columnGrouping}
                    localeText={locale.components.MuiDataGrid.defaultProps.localeText}
                />
            </Paper >
        </Container >
    )
}

export default MSFlowsPage;