import { push } from 'redux-first-history';
import { clone } from "@Utilities";
import AccountService from "../services/AccountService";
import { ProcessingState } from "@Components/Definitions";
import { SharedActions } from "./SharedActions";
import Globals from "@Base/Globals";

const Actions = {
    /**
     * You need to have the initial state to
     * reset the indicators (e.g. loginSuccess)
     * that call redirect or any other actions.
     * It must be called in method 'componentDidMount'
     * of a component.
     */
    Init: "LOGIN_INIT",

    LoginRequest: "LOGIN_REQUEST",
    LoginSuccess: "LOGIN_SUCCESS",
    LoginFailure: "LOGIN_FAILURE",

    RequestStart: "REQUEST_START",
    RequestSuccess: "REQUEST_SUCCESS",
    RequestFailure: "REQUEST_FAILURE",

    RegisterRequest: "REGISTER_REQUEST",
    RegisterSuccess: "REGISTER_SUCCESS",
    RegisterFailure: "REGISTER_FAILURE",

    RecoverPasswordPrepareRequest: "RECOVER_PASSWORD_PREPARE_REQUEST",

    RecoverPasswordRequest: "RECOVER_PASSWORD_REQUEST",
    RecoverPasswordSuccess: "RECOVER_PASSWORD_SUCCESS",
    RecoverPasswordFailure: "RECOVER_PASSWORD_FAILURE",


    UpdateUserRequest: "UPDATE_USER_REQUEST",
    UpdateUserrSuccess: "UPDATE_USER_SUCCESS",
    UpdateUserFailure: "UPDATE_USER_FAILURE",

    /* not need right now
    LoginAsEmployeeRequest: "LOGIN_AS_EMPLOYEE_REQUEST",
    LoginAsEmployeeSuccess: "LOGIN_AS_EMPLOYEE_SUCCESS",
    LoginAsEmployeeFailure: "LOGIN_AS_EMPLOYEE_FAILURE"
    */
}


export const actionCreators = {

    init: () => async (dispatch) => {
        dispatch({ type: Actions.Init });
    },

    loginRequest: (model) => async (dispatch, getState) => {

        dispatch({ type: Actions.LoginRequest });

        var result = await AccountService.login(model);
        if (result.hasErrors) {
            dispatch({ type: Actions.LoginFailure });
            return;
        }

        dispatch({ type: Actions.LoginSuccess });
    },

    logoutRequest: () => async (dispatch) => {

        var result = await AccountService.logout();

        if (result.hasErrors) {
            dispatch({ type: Actions.LogoutFailure });
            return;
        }

        dispatch({ type: SharedActions.LogoutSuccess });
        dispatch(push("/")); //redirect to login page
    },


    loginEmployeeRequest: () => async (dispatch, getState) => {

        dispatch({ type: Actions.LoginRequest });

        var result = await AccountService.loginAsEmployee();
        if (result.hasErrors) {
            dispatch({ type: Actions.LoginFailure });
            return;
        }

        dispatch({ type: Actions.LoginSuccess });
    },

    loginOpenIdEmployeeRequest: (jwtToken) => async (dispatch) => {

        dispatch({ type: Actions.LoginRequest });

        var result = await AccountService.loginOpenIdAsEmployee(jwtToken);
        if (result.hasErrors) {
            dispatch({ type: Actions.LoginFailure });
            return;
        }

        dispatch({ type: Actions.LoginSuccess });
    },

    registerRequest: (model) => async (dispatch, getState) => {

        dispatch({ type: Actions.RegisterRequest });

        var result = await AccountService.register(model);
        if (result.hasErrors) {
            dispatch({ type: Actions.RegisterFailure });
            return;
        }

        dispatch({ type: Actions.RegisterSuccess, payload: result.value });
    },

    recoverPassword: () => async (dispatch) => {
        dispatch({ type: Actions.RecoverPasswordPrepareRequest });
    },

    forgotPasswordRequest: (model) => async (dispatch, getState) => {

        dispatch({ type: Actions.RecoverPasswordRequest });

        var result = await AccountService.forgotPassword(model);
        if (result.hasErrors) {
            dispatch({ type: Actions.RecoverPasswordFailure });
            return;
        }

        dispatch({ type: Actions.RecoverPasswordSuccess });
    },

    getUserSettings: () => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await AccountService.getUserSettings();
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: result.value });
    },

    updateUserSettings: (data) => async (dispatch) => {

        dispatch({ type: Actions.UpdateUserRequest });

        var toUpdate = new Object();

        //update all
        Object.keys(data).forEach((key) => {
            toUpdate[key] = { Value: data[key] };
        });

        var result = await AccountService.updateUserSettings(toUpdate);
        if (result.hasErrors) {
            dispatch({ type: Actions.UpdateUserFailure });
            return;
        }

        dispatch({ type: Actions.UpdateUserrSuccess });

        Globals.user = { ...Globals.user, ...data };
    },


    enterAtAccount: (id) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await AccountService.enterAtAccount(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    }
};

const initialState = {
    indicators: {
        operationLoading: false,
        loginSuccess: false,
        registerSuccess: false,
        recoverPasswordState: ProcessingState.Undefined,
        showInfoModal: false,
        content: ""
    },
    user: null
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState; 

    const action = incomingAction;

    var cloneIndicators = () => clone(currentState.indicators);

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.LoginRequest:
            var indicators = cloneIndicators();
            indicators.operationLoading = true;
            return { ...currentState, indicators };

        case Actions.LoginSuccess:
            var indicators = cloneIndicators();
            indicators.operationLoading = false;
            indicators.loginSuccess = true;
            return { ...currentState, indicators };

        case Actions.LoginFailure:
            var indicators = cloneIndicators();
            indicators.operationLoading = false;
            return { ...currentState, indicators };


        case Actions.RegisterRequest:
            var indicators = cloneIndicators();
            indicators.operationLoading = true;
            return { ...currentState, indicators };

        case Actions.RegisterSuccess:
            var indicators = cloneIndicators();
            indicators.operationLoading = false;
            if (action.payload != null) {
                indicators.registerSuccess = action.payload.isSuccess;
                if (!action.payload.isSuccess) {
                    if (action.payload.isShowError) {
                        indicators.content = action.payload.error;
                    }
                }
                else
                    indicators.content = "Вы успешно зарегистрированы. На вашу почту придёт письмо подтверждения регистрации.";
            }
            else {
                indicators.registerSuccess = false;
                indicators.content = "Произошла ошибка в процессе регистрации. Попробуйте зарегистрироваться снова позднее.";
            }
            indicators.showInfoModal = true;

            return { ...currentState, indicators }

        case Actions.RegisterFailure:
            var indicators = cloneIndicators();
            indicators.operationLoading = false;
            indicators.showInfoModal = true;
            indicators.content = "Произошла ошибка в процессе регистрации. Попробуйте зарегистрироваться снова позднее.";
            return { ...currentState, indicators };

        case Actions.RecoverPasswordPrepareRequest: {
            var indicators = cloneIndicators();
            indicators.recoverPasswordState = ProcessingState.Request;
            return { ...currentState, indicators };
        }

        case Actions.RecoverPasswordRequest:
            var indicators = cloneIndicators();
            indicators.recoverPasswordState = ProcessingState.Request;
            indicators.operationLoading = true;
            return { ...currentState, indicators };

        case Actions.RecoverPasswordSuccess:
            var indicators = cloneIndicators();
            indicators.operationLoading = false;
            indicators.recoverPasswordState = ProcessingState.Response_Success;

            indicators.showInfoModal = true;
            indicators.content = "На ваш почтовый ящик выслано письмо для сброса пароля. Пройдите по ссылке указанной в письме для завершения процедуры смены пароля.";

            return {...currentState, indicators};

        case Actions.RecoverPasswordFailure:
            var indicators = cloneIndicators();
            indicators.operationLoading = false;
            indicators.recoverPasswordState = ProcessingState.Response_Failed;
            return {...currentState, indicators};

        case Actions.RequestSuccess:
            {
                return { ...currentState, user: action.payload };
            }

        default:
            return currentState || initialState;
    }
};