import { Autocomplete, Box, alpha, Container, Stack, Button, Theme, TextField } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ValidatorForm, TextValidator } from "@Framework/components/Form";
// import { actionCreators as actionConvention } from '@Base/store/ConventionStore'

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { convention2ShowError } from '@Base/PlatformConfig';
import { useDispatch } from 'react-redux';
import { useNewDispatch, useNewSelector } from '@Base/index';
import useSignalR from '@Base/components/hook/useSignalR';
import { SignalRHubUrl } from '@Base/PlatformConfig';
import TestService from "@Base/services/TestService";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: 'flex'
    },
    formBox: {
        maxWidth: '300px',
        paddingBottom: '1rem',
        paddingRight: '1rem'
    }
}))

const FormTestPage = () => {
    const dispatch = useNewDispatch()
    const classes = useStyles();

    const [first, setFirst] = useState('');
    const [second, setSecond] = useState('');
    const [third, setThird] = useState('');
    const [signalMessage, setSignalMessage] = useState("test message");
    const [respondMessage, setRespondMessage] = useState("");

    const formRef = useRef();

    const callbackRef = useRef({})

    const {
        selectedBond = null,
        items: bondList = null,
        ...otherStore } = useNewSelector((state: any) => state.newConvention)

    useEffect(() => {
        // actionConvention.findBondsQuery(null, convention2ShowError)(dispatch)

        callbackRef.current = {
            ping: ping
        }
    }, [])

    const connection = useSignalR(SignalRHubUrl, {
        Ping: (message) => callbackRef.current.ping(message)
    })

    const ping = (message: any) => {

        console.log("===received: " + message);
        setRespondMessage(message);
    }

    const handleFormSubmit = () => {

    }

    const testSignalR = () => {
        TestService.test(signalMessage);
    }

    const validateForm = () => {
        formRef.current.isFormValid(false, true);
    }

    const validateFormFields = (fields) => {
        console.log('Global validation rules called', fields);

        if (fields.first !== fields.second) {
            return [{ name: 'first', message: 'Значения должны совпадать' }, { name: 'second', message: 'Значения должны совпадать' }];
        }

        return null;
    }

    const onFormStateChanged = (isValid: boolean) => {
        console.log(`Form state changed, isValid: ${isValid}`);
    }

    const onFieldChanged = (name: string, value: any, isValid: boolean) => {
        console.log(`Field changed, name: ${name}, value: ${value}, isValid: ${isValid}`);
    }


    return (

        <Container className={classes.container}>
            <div className={classes.formBox}>
                <Autocomplete
                    id="country-select-demo"
                    sx={{ width: 300 }}
                    options={bondList}
                    autoHighlight
                    getOptionLabel={(option) => `${option.ticker} ${option.isin}`}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {/* {console.log('option', option)} */}
                            {`${option.ticker} ${option.isin}`}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Choose a country"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />

                <ValidatorForm
                    ref={formRef}
                    onSubmit={handleFormSubmit}
                    validators={[validateFormFields]}
                    onFieldChanged={onFieldChanged}
                    onFormStateChanged={onFormStateChanged}
                >
                    <TextValidator
                        label="first"
                        name="first"
                        id="first"
                        fullWidth={true}
                        onChange={(event) => setFirst(event.target.value)}
                        value={first}
                        validators={['required']}
                        errorMessages={['Required']} />
                    <TextValidator
                        label="second"
                        name="second"
                        id="second"
                        fullWidth={true}
                        onChange={(event) => setSecond(event.target.value)}
                        value={second}
                        validators={['required']}
                        errorMessages={['Required']} />
                    <TextValidator
                        label="third"
                        name="third"
                        id="third"
                        fullWidth={true}
                        onChange={(event) => setThird(event.target.value)}
                        value={third}
                        validators={['required']}
                        errorMessages={['Required']} />

                </ValidatorForm>

                <div>
                    <Stack spacing={2} direction="row">
                        <Button variant="contained" onClick={validateForm}>Validate</Button>
                    </Stack>
                </div>

            </div>

            <Box>
                <Box sx={{ paddingBottom: '1rem' }}>
                    <Box>
                        <span>To send: </span><TextField sx={{ paddingBottom: '1rem' }} value={signalMessage} onChange={(evt) => setSignalMessage(evt.target.value)}></TextField>
                    </Box>
                    <div>Responded: {respondMessage}</div>
                </Box>
                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={testSignalR}>Test SignalR</Button>
                </Stack>
            </Box>
        </Container>
    );
}

export default FormTestPage;