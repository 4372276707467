import Localization from '@Base/components/Localization';
import CalculatorService from '@Base/services/CalculatorService';
import ConventionService from '@Base/services/ConventionService';
import PoolService from '@Base/services/PoolService';
import SecuritizationService from '@Base/services/SecuritizationService';
import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit'

const createSliceWithThunks = buildCreateSlice({
    creators: { asyncThunk: asyncThunkCreator },
})

const setError = (state, action) => {
    // state.status = 'rejected'
    state.error = action.payload ?? action.error
}

const conventionSlice = createSliceWithThunks({
    name: 'securitization',
    initialState: {
        storedEditMode: null,

        loading: false,
        loadingMOEX: false,
        loadingChartData: false,
        bondListLoading: false,

        items: [],
        poolInfo: null,
        poolStatistics: null,
        bondParameters: null,
        calculationParameters: null,
        selectedBond: null,
        zcycDate: null,
        chartsData: {},
        sCurvesGraph: {},
        sCurvesGraphLoading: false,

        status: null,
        error: null,
    },

    selectors: {
        conventionItems: state => state
    },

    reducers: create => ({
        setConventionData: (state, action) => {

            //  console.log('setConventionData')
            //  console.log('state, ...action.payload', state, action.payload, { ...state, ...action.payload })
            // state.status = 'loading'
            // state.error = null
            return { ...state, ...action.payload }
            // Object.keys(action.payload).forEach(key => state[key] = action.payload[key])
        },
        getDataForCalculation: create.asyncThunk(
            async ({ bondID, showError } = {}, { rejectWithValue, dispatch, getState }) => {
                const responce = await ConventionService.getDataForCalculation(bondID, showError)
                // console.log('responce', responce)
                return responce
            },
            {
                pending: state => {
                    // state.status = 'loading'
                    state.error = null
                    state.poolInfo = null
                    state.bondParameters = null
                },
                rejected: setError,
                fulfilled: (state, action) => {
                    // console.log('action1!!!!!!!!!!!!!!!', action)
                    state.poolInfo = action.payload.value?.pool
                    state.bondParameters = action.payload.value?.bondParameters
                },
                settled: (state, action) => {
                    //call for rejected and filfilled actions
                    // console.log('settled')
                    // state.status = 'ready'
                }
            }
        ),

        getZCYCDateTime: create.asyncThunk(
            async ({ date } = {}, { rejectWithValue, dispatch, getState }) => {
                const responce = await SecuritizationService.getZCYCDateTime(date)
                return responce
            },
            {
                pending: state => {
                    // state.status = 'loading'
                    state.error = null
                    state.zcycDate = null
                },
                rejected: setError,
                fulfilled: (state, action) => {
                    state.zcycDate = { requertedDate: action.meta.arg.date, resultDate: action.payload.value }
                },
                settled: (state, action) => {
                    // state.status = 'ready'
                }
            }
        ),

        clearValue: (state, action) => {
            state[action.payload] = null
        },

        getPoolLLDForChartV2: create.asyncThunk(
            async ({ bondID, poolReportDate, step } = {}, { rejectWithValue, dispatch, getState }) => {
                const responce = await PoolService.getPoolLLDForChartV2(bondID, poolReportDate, step)
                return responce
            },
            {
                pending: state => {
                    // state.status = 'loading'
                    state.error = null
                    state.loadingChartData = true
                },
                rejected: setError,
                fulfilled: (state, action) => {
                    if (action.payload.value?.hasErrors) {
                        const error = action.payload?.errors?.join('; ')
                        state.error = error
                        return;
                    }
                    state.chartsData = { currentRateChart: action.payload.value }
                    state.error = action.payload?.value?.error
                },
                settled: (state, action) => {
                    // state.status = 'ready'
                    state.loadingChartData = false
                }
            }
        ),

        runCalculation: create.asyncThunk(
            async ({ params, showError } = {}, { rejectWithValue, dispatch, getState }) => {
                const responce = await ConventionService.runCalculation(params, showError)
                // console.log('responce', responce)
                return responce
            },
            {
                pending: state => {
                    state.error = null
                    state.loading = true
                },
                rejected: setError,
                fulfilled: (state, action) => {
                    return { ...state, ...action.payload?.value ?? {} }
                },
                settled: (state, action) => {
                    // state.status = 'ready'
                    state.loading = false
                }
            }
        ),

        findBondsQuery: create.asyncThunk(
            async ({ query, showError } = {}, { rejectWithValue, dispatch, getState }) => {
                const responce = await ConventionService.getBondsQuery(query, null, null, null, showError);
                return responce
            },
            {
                pending: state => {
                    // state.status = 'loading'
                    state.error = null
                    state.bondListLoading = true
                },
                rejected: setError,
                fulfilled: (state, action) => {
                    state.items = action.payload?.value?.items
                },
                settled: (state, action) => {
                    // state.status = 'ready'
                    state.bondListLoading = false
                }
            }
        ),

        loadMoexSecurities: create.asyncThunk(
            async ({ date } = {}, { rejectWithValue, dispatch, getState }) => {
                const responce = await CalculatorService.loadMoexSecurities(Localization.FormatDateStringISO(date))
                return responce
            },
            {
                pending: state => {
                    state.error = null
                    state.loadingMOEX = true
                },
                rejected: setError,
                fulfilled: (state, action) => {
                    state.moexSecurities = action.payload?.value
                },
                settled: (state, action) => {
                    state.loadingMOEX = false
                }
            }
        ),

        getSCurveGraphWithPool: create.asyncThunk(
            async ({ bondId, scurveDate, poolReportDate, refinancingRate, minIncentive = null, maxIncentive = null, step } = {}, { rejectWithValue, dispatch, getState }) => {

                const responce = await ConventionService.getSCurveGraphWithPool(
                    bondId,
                    Localization.FormatDateStringISO(scurveDate),
                    Localization.FormatDateStringISO(poolReportDate),
                    refinancingRate,
                    minIncentive,
                    maxIncentive,
                    step);
                // console.log('responce', responce)
                // if (responce?.value) {
                //     return { ...getState(), sCurvesGraph: responce.value }
                // }
                return responce
            },
            {
                pending: state => {
                    state.error = null
                    state.sCurvesGraph = {}
                },
                rejected: setError,
                fulfilled: (state, action) => {
                    state.sCurvesGraph = action.payload.value
                },
                settled: (state, action) => {
                    state.sCurvesGraphLoading = false
                }
            }
        ),

        getSCurveGraph: create.asyncThunk(
            async ({ scurveDate, minInc, maxInc, step } = {}, { rejectWithValue, dispatch, getState }) => {
                const responce = await ConventionService.getSCurveGraph(
                    Localization.FormatDateStringISO(scurveDate),
                    minInc,
                    maxInc,
                    step);
                // if (responce?.value) {
                //     return { ...getState(), sCurvesGraph: responce.value }
                // }
                return responce
            },
            {
                pending: state => {
                    state.error = null
                    state.sCurvesGraphLoading = true
                    state.sCurvesGraph = {}
                },
                rejected: setError,
                fulfilled: (state, action) => {
                    state.sCurvesGraph = action.payload.value
                },
                settled: (state, action) => {
                    state.sCurvesGraphLoading = false
                }
            }
        ),

        getPool: create.asyncThunk(
            async ({ bondIsinOrRegNumber, date } = {}, { rejectWithValue, dispatch, getState }) => {
                const responce = await ConventionService.getPool(bondIsinOrRegNumber, Localization.FormatDateStringISO(date));
                return responce
            },
            {
                pending: state => {
                    state.error = null
                    state.loadingMOEX = true
                },
                rejected: setError,
                fulfilled: (state, action) => {
                    state.poolInfo = action.payload?.value
                },
                settled: (state, action) => {
                    state.loadingMOEX = false
                }
            }
        ),
        setModelKeyrate: (state, action) => {

            if (action.payload == null) {
                // dispatch({ type: Actions.RequestFailure, payload: { loading: false } });
                return;
            }
            state.modelKeyrate = action.payload
        },
    }),
})

export const {
    setConventionData, getDataForCalculation, getPoolLLDForChartV2, getZCYCDateTime, clearValue, findBondsQuery,
    loadMoexSecurities, runCalculation, getPool, setModelKeyrate, getSCurveGraphWithPool, getSCurveGraph
} = conventionSlice.actions

export const { conventionItems } = conventionSlice.selectors

export default conventionSlice.reducer