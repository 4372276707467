import * as AccountStore from "./AccountStore";
import * as InstrumentsStore from "./InstrumentsStore";
import * as PasswordResetStore from "./PasswordResetStore";
import * as ConfirmRegistrationStore from "./ConfirmRegistrationStore";
import * as TopNavbarStore from "./TopNavbarStore";
import * as CalculatorStore from "./CalculatorStore";
import * as AdministrationStore from "./AdministrationStore";
import * as LibraryStore from "./LibraryStore";
import * as DomRfStore from "./DomRfStore";
import * as ProfileStore from "./ProfileStore";
import * as PoolStore from "./PoolStore";
import * as BondsStore from "./BondsStore";
import * as SensitivityStore from "./SensitivityStore";
import * as KeyRateStore from "./KeyRateStore";
import * as SecuritizationStore from "./SecuritizationStore";
import * as LoaderStore from "./LoaderStore";
// import * as ConventionStore from "./ConventionStore";
import * as SidebarStore from "./SidebarStore";
import * as AlertStore from "./AlertStore";
import * as DownloadsStore  from './DownloadStore'
// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    account: AccountStore.reducer,
    instruments: InstrumentsStore.reducer,
    passwordReset: PasswordResetStore.reducer,
    confirmRegistration: ConfirmRegistrationStore.reducer,
    topNavbar: TopNavbarStore.reducer,
    calculator: CalculatorStore.reducer,
    sidebar: SidebarStore.reducer,
    administration: AdministrationStore.reducer,
    library: LibraryStore.reducer,
    domrf: DomRfStore.reducer,
    profile: ProfileStore.reducer,
    pool: PoolStore.reducer,
    bonds: BondsStore.reducer,
    sensitivity: SensitivityStore.reducer,
    keyrate: KeyRateStore.reducer,
    securitization: SecuritizationStore.reducer,
    loader: LoaderStore.reducer,
    // convention: ConventionStore.reducer,
    alerts: AlertStore.reducer,
    downloads: DownloadsStore.reducer
};