import { useState, useRef, useEffect, useMemo } from 'react'
import { Box, Grid, alpha, easing } from '@mui/material'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import style from '@Styles/style-main.scss'
import { axisGridLineColor, axisLabelsColor, axisLineColor, axisTitleColor, plotBorderColor } from '@Base/components/constants/charts'
import { t } from 'i18next'
import { fontSize } from '@mui/system'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  slideRow: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    // position:'relative'
  },
  slideCell: {
    display: 'flex',
    // flexDirection: 'column',
    // flexGrow: 1,
    position: 'relative',
    height: '360px',
    overflow: 'hidden',
    textAlign: 'justify',
    alignItems: 'center',
    // border: 'solid 1px',
    '& gr': {
      // fontStyle: 'italic',
      fontSize: '0.8rem',
      color: style.colorPrimary3_60
    },
    '& a': {
      color: style.colorPrimary2
    }
  },
  cellText: {
    fontSize: '1rem'
  }
})
const Slide1 = ({ step = 0 }) => {
  const cls = useStyles()
  const chartRef = useRef()
  const chartRef1 = useRef()
  const [historicalData, setHistoricalData] = useState(null)
  const [currentData, setCurrentData] = useState(null)
  const [refinancingData, setRefinancingData] = useState(null)
  const [areaData, setAreaData] = useState(null)
  const [slideText, setSlideText] = useState('')
  const [series, setSeries] = useState([])
  const sCurvesGraph = require('./sCurvesData.json')
  const keyrateData = require('./keyrateData.json')

  const sCurvesColor = [
    { loanAgeId: 0, key: 'меньше 1 года', color: 'rgb(50,72,77)', valColor: 'rgb(124,152,180)', isActive: true },
    { loanAgeId: 1, key: 'от 1 до 2 лет', color: 'rgb(70,101,72)', valColor: 'rgb(180,192,203)', isActive: true },
    { loanAgeId: 2, key: 'от 2 до 3 лет', color: 'rgb(88,126,67)', valColor: 'rgb(207,212,214)', isActive: true },
    { loanAgeId: 3, key: 'от 3 до 4 лет', color: 'rgb(106,152,62)', valColor: 'rgb(234,231,225)', isActive: true },
    { loanAgeId: 4, key: 'от 4 до 5 лет', color: 'rgb(125,179,57)', valColor: 'rgb(245,239,230)', isActive: true },
    { loanAgeId: 5, key: 'от 5 до 6 лет', color: 'rgb(139,191,70)', valColor: 'rgb(245,239,230)', isActive: true },
    { loanAgeId: 6, key: 'от 6 до 7 лет', color: 'rgb(152,199,90)', valColor: 'rgb(245,239,230)', isActive: true },
    { loanAgeId: 7, key: 'от 7 до 8 лет', color: 'rgb(164,207,108)', valColor: 'rgb(245,239,230)', isActive: true },
    { loanAgeId: 8, key: 'от 8 до 9 лет', color: 'rgb(177,215,128)', valColor: 'rgb(245,239,230)', isActive: true },
    { loanAgeId: 9, key: 'больше 9 лет', color: 'rgb(192,223,150)', valColor: 'rgb(245,239,230)', isActive: true },
  ]

  useEffect(() => {
    const subStep = step < 1 ? step : 1

    setHistoricalData([...
      Object.values(keyrateData.historical[subStep]?.date).map((date, index) => {
        const dateTime = new Date(date)
        return [dateTime.getTime(), keyrateData.historical[subStep]?.value[index]]
      })]
    )

    setCurrentData(
      Object.values(keyrateData.current[subStep]?.date).map((date, index) => {
        const dateTime = new Date(date)
        return [dateTime.getTime(), keyrateData.current[subStep]?.value[index]]
      })
    )

    setRefinancingData(
      Object.values(keyrateData.refinancing[subStep]?.date).map((date, index) => {
        const dateTime = new Date(date)
        return [dateTime.getTime(), keyrateData.refinancing[subStep]?.value[index]]
      })
    )

    setSlideText(keyrateData.text[subStep])

    if (step >= 2) {
      const cpr_series = []
      const newChart = { ...sCurvesChartOptions }
      newChart.xAxis[0].categories = sCurvesGraph?.incentive ?? []

      // console.log('sCurvesData', sCurvesData)
      sCurvesGraph?.data?.toReversed()?.forEach((item, index) => {
        cpr_series.push(
          {
            animation: false,
            // animation: { duration: 600, defer: 300 * index,},
            name: 'cpr',
            id: `sCurve_${item?.loanAgeID}`,
            loanAgeId: item?.loanAgeID,
            type: 'line',
            pointPlacement: 'on',
            xAxis: 0,
            yAxis: 0,
            lineWidth: 2,
            color: sCurvesColor[item?.loanAgeID].color,
            states: {
              hover: { enabled: false, },
            },
            marker: {
              enabled: false,
            },
            zIndex: 300,
            data: item?.cpr,
            visible: true,
            showInLegend: false,
          })
        // console.log('cpr_series', cpr_series)
        setsCurvesChartOptions({ ...newChart, series: cpr_series })
      })
    }

  }, [step])

  useEffect(() => {
    setAreaData(refinancingData?.map(([date, value], index) => {
      return ({
        x: date, high: value, low: currentData[index][1]
      })
    }))
  }, [refinancingData])

  // console.log('areaData', areaData)
  useEffect(() => {
    // console.log('update series')
    // console.log('historicalData, currentData, refinancingData, areaData', historicalData, currentData, refinancingData, areaData)
    setSeries([
      {
        name: t('convention2.historicalDataLegend'),
        id: `historicalData`,
        animation: false,
        //animation: { duration: 2000, easing: 'easeInOutElastic' },
        showInLegend: true,
        legendIndex: 1,
        type: 'line',
        step: 'left',
        stickyTracking: false,
        pointPadding: 0,
        xAxis: 0,
        yAxis: 0,
        lineWidth: 2,
        color: style.colorPrimary1,
        marker: {
          enabled: false,
        },
        zIndex: 60,
        data: historicalData,
        visible: true,
      },

      {
        name: t('convention2.currentForecastLegend'),
        id: `currentForecast`,
        animation: false,
        // animation: { duration: 3000, easing: 'easeInOutElastic' },
        showInLegend: true,
        legendIndex: 5,
        type: 'line',
        step: 'left',
        stickyTracking: false,
        pointPadding: 0,
        xAxis: 0,
        yAxis: 0,
        lineWidth: 2,
        color: style.colorPrimary2,
        zIndex: 55,
        data: currentData,
        visible: true,
      },
      {
        name: t('convention2.demoRefinancingRate'),
        id: `refinancingRate`,
        animation: false,
        // animation: { duration: 5000, easing: 'easeInOutElastic' },
        showInLegend: true,
        legendIndex: 6,
        type: 'line',
        step: 'left',
        stickyTracking: false,
        pointPadding: 0,
        xAxis: 0,
        yAxis: 0,
        lineWidth: 2,
        color: style.colorPrimary3,
        zIndex: 55,
        data: refinancingData,
        visible: true,

      },
      {
        id: 'arearange',
        animation: false,
        // animation: { duration: 5000, easing: 'easeInOutElastic' },
        type: 'arearange',
        step: 'left',
        zIndex: 1,
        data: null,
        yAxis: 0,
        xAxis: 0,
        lineWidth: 0,
        // lineColor: style.colorPrimary1_40,
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, 'rgba(183, 217, 139, 0.23)'], // Start color (transparent)
            [1, 'rgba(183, 217, 139, 0.23)'] // End color (transparent)
          ]
        },
        fillOpacity: 0.3,
        showInLegend: false,
        stacking: null,
        visible: true,
        data: areaData,
      },
    ])
  }, [historicalData, currentData, refinancingData, areaData])

  useEffect(() => {
    setChartOptions(options => ({ ...options, series: series }))
  }, [series])

  const [chartOptions, setChartOptions] = useState({
    exporting: {
      enabled: false,

    },
    chart: {
      animation: false,
      // animation: { duration: 1000 },
      // animation: { duration: 4000, easing: 'easeInOutQuad' },
      marginLeft: 40, // Keep all charts left aligned
      // marginRight: 50,
      // height: 300,
      //plotAreaHeight: 290,
      padding: 0,
      // plotBorderWidth:10,
      marginBottom: 45,
      // spacingBottom: 13,
      plotBorderWidth: 0,
      plotBorderColor: plotBorderColor,
      events: {
      },
    },

    title: {
      text: ''
    },

    credits: {
      enabled: false
    },

    plotOptions: {
      labels: {
        useHTML: true
      },
      series: {
        pointPlacement: 'on',
        // animation: true,
        // states: {
        //   inactive: {
        //     opacity: 0,
        //     enabled: false,
        //   },
        //   hover: {
        //     enabled: false,
        //     halo: {
        //       size: 0
        //     },
        //     marker: {
        //       radius: 0 // Указать желаемый радиус
        //     }
        //   }

        // },
        // marker: {
        //   symbol: 'circle', // Set the marker symbol to 'circle'
        //   radius: 0,
        // },
        events: {
          legendItemClick: function () {
            return false; // Prevent the default legendItemClick behavior
          }
        },

        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          },
          normal: {
            enabled: false
          },
          select: {
            enabled: false
          }
        },
      },
    },
    legend: {
      useHTML: true,
      // align: 'left',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      margin: 0,
      y: 17,
      itemHoverStyle: {
        textDecoration: 'none',
      },
      itemHiddenStyle: {
        color: style.colorPrimary1,
        textDecoration: 'none',
      },
      itemStyle: {
        fontSize: '12px',
        fontWeight: 'bold',
        fill: style.colorPrimary1,
        color: style.colorPrimary1,
        paddingLeft: 0,
        paddingRight: 0,
        cursor: 'default',
        textDecoration: 'none'
        // color: style.colorPrimary3// Задать цвет легенды
      },
      itemEvents: {
        mouseOver: function () {
          return false; // Отключить подсветку при наведении на легенду
        }
      }
    },
    xAxis: [{
      animation: false,
      type: 'datetime',
      visible: true,
      crosshair: false,
      gridLineWidth: 1,
      gridLineColor: axisGridLineColor,
      lineWidth: 1,
      lineColor: axisLineColor,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          lineHeight: '0.8rem',
          fontSize: '0.8rem',
          color: axisLabelsColor,
        },
        y: 17,
        formatter: function () {
          // console.log('first')
          if (this.value == null)
            return ''
          const date = new Date(this.value)
          // console.log('date', date)
          const y = date.getFullYear()
          const m = date?.getMonth()
          const d = date?.getDate()
          if (m == 0 && d == 1) {
            return y
          }

          return null
        },
      },
      tickPositioner: function (min, max) {
        const startYear = new Date(min).getFullYear() + 1
        const endYear = new Date(max).getFullYear()
        var positions = Array.from({ length: endYear - startYear + 1 }, (x, i) => new Date(min).setFullYear(startYear + i, 0, 1))
        return positions;
      },
      tickWidth: 0,
      minPadding: 0,
      maxPadding: 0,
      plotBands: [
        {
          from: Number.NEGATIVE_INFINITY,
          to: new Date(keyrateData.historical[0].date[keyrateData.historical[0].date.length - 1])?.getTime(),
          color: 'rgba(47, 68, 78, 0.05)'
        }
      ],
      plotLines: [
        {
          value: new Date(keyrateData.historical[0].date[keyrateData.historical[0].date.length - 1])?.getTime(),
          color: style.colorPrimary3_40,
          dashStyle: 'dash',
        }
      ]
    }],
    yAxis: [{
      animation: false,
      crosshair: false,
      gridLineColor: axisGridLineColor,
      gridLineWidth: 1,
      lineWidth: 1,
      lineColor: axisLineColor,
      min: 0,
      max: 25,
      tickInterval: 5,
      alignTicks: true,
      title: {
        text: t('convention2.demoKeyrateValue'),
        x: 5,
        style: {
          fontSize: '1rem',
          color: axisTitleColor,
        }
      },
      labels: {
        x: -5,
        style: {
          lineHeight: '0.8rem',
          fontSize: '0.8rem',
          color: axisLabelsColor,
        }
      },
      tickWidth: 0,
      plotLines: [

      ]
    }],

    tooltip: {
      enabled: false,
    },

    series: series
  })

  const [sCurvesChartOptions, setsCurvesChartOptions] = useState({
    exporting: {
      enabled: false,
    },
    chart: {
      animation: false,
      marginLeft: 40, // Keep all charts left aligned
      // marginRight: 50,
      padding: 0,
      marginBottom: 45,
      plotBorderWidth: 0,
      plotBorderColor: plotBorderColor,
      events: {
        load: function () {
          const chart = this;
          console.log('chart', chart)
          Highcharts.addEvent(Highcharts.Pointer.prototype, 'onMouseMove', function (e) {
            const point = chart.hoverPoint;
            console.log('point', point)
            if (point) {
              const seriesName = point.series.name;
              chart.renderer.label(seriesName, e.chartX + 10, e.chartY + 10)
                .css({
                  color: '#333',
                  background: 'rgba(255, 255, 255, 0.8)',
                  border: '1px solid #333',
                  padding: '5px'
                })
                .add();
            }
          })
        }
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        animation: false,
        // animation: { defer: 500 },
        states: {
          inactive: {
            opacity: 0,
            enabled: false,
          },
          hover: {
            halo: {
              size: 0
            }
          }
        },
        marker: {
          symbol: 'circle', // Set the marker symbol to 'circle'
          radius: 2,
        },
        events: {
          legendItemClick: function () {
            return false; // Prevent the default legendItemClick behavior
          }
        }
      },
    },

    xAxis: [{
      // crosshair: {
      //   color: alpha(style.colorPrimary3, 0.1)
      // },
      minPixelPadding: 0,
      minPointOffset: 0,
      gridLineColor: axisGridLineColor,
      gridLineWidth: 1,
      gridLineDashStyle: 'solid',
      tickInterval: 10,
      startOnTick: true,
      endOnTick: true,
      minPadding: 0,
      maxPadding: 0,
      tickWidth: 0,
      title: {
        text: t('convention2.GraphIncentiveToRefinance'),
        offset: 19,
        style: {
          fontSize: '1rem',
          color: axisTitleColor,
        }
      },
      labels: {
        rotation: 0,
        y: 17,
        allowOverlap: true,
        style: {
          lineHeight: '0.8rem',
          fontSize: '0.8rem',
          overflow: 'visible',
          textOverflow: 'visible',
          color: axisLabelsColor,
        }
      },
      lineWidth: 1,
      lineColor: axisLineColor,
      categories: [],
    },
    ],

    yAxis: [{
      crosshair: false,
      gridLineColor: axisGridLineColor,
      gridLineWidth: 1,
      lineWidth: 1,
      lineColor: axisLineColor,
      min: 0,
      max: 45,
      tickAmount: 10,
      alignTicks: true,
      title: {
        text: t('convention2.demoYAxisCPR'),
        x: 5,
        style: {
          fontSize: '1rem',
          color: axisTitleColor,
        }
      },
      labels: {
        x: -5,
        style: {
          lineHeight: '0.8rem',
          fontSize: '0.8rem',
          color: axisLabelsColor,
        }
      },
      tickWidth: 0,
    }],

    tooltip: {
      enabled: false
    },

    series: []
  })
  // console.log('chartOptions', chartOptions)


  return (<>
    <Grid container>
      <Grid item
        className={cls.slideCell}
        xs={7}>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          containerProps={{ style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
          options={chartOptions ? chartOptions : {}}
        // callback={() => onLoadFunc()}
        />
      </Grid>
      <Grid item
        className={cls.slideCell} sx={{ pl: 3, alignItems: 'start !important', pt: '4rem' }}
        xs={5}>
        <div dangerouslySetInnerHTML={{ __html: slideText }} />
      </Grid>
      <Grid item
        className={cls.slideCell} sx={{ pr: 3 }}
        xs={5}>
        {step > 1 && <div dangerouslySetInnerHTML={{ __html: sCurvesGraph.text[0] }} />}
      </Grid>
      <Grid item
        className={cls.slideCell}
        xs={7}>
        {step > 1 &&
          <HighchartsReact
            ref={chartRef1}
            highcharts={Highcharts}
            containerProps={{ style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
            options={sCurvesChartOptions ? sCurvesChartOptions : {}}
          // callback={() => onLoadFunc()}
          />
        }
      </Grid>
    </Grid>
  </>
  )
}

export default Slide1