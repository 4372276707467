import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import SpinnerBars from "@Components/shared/SpinnerBars";

import { IterationValidationState, IterationStatus, DealType, PoolProcessingStatus } from '@Components/Definitions';

import style from '@Styles/domrf-theme.module.scss'
import { alpha, Badge, Box, IconButton, Menu, MenuItem, Paper, Popper, rgbToHex, Stack, Tooltip } from '@mui/material';
import { AddCircleOutline, AddOutlined, CompareArrowsOutlined, HourglassEmpty } from '@mui/icons-material';
// import Localization from '../../../../components/Localization';
import { CouponType } from '../../../../components/Definitions';
import { useSelector } from 'react-redux';
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import useLocalStorage from '@Base/components/hook/useLocalStorage';
import { useDispatch } from 'react-redux';
import Processing from './Processing';
import { humanReadableStatuses } from '@Base/Utilities';
//import ChipAuthor from '../../../../components/shared/ChipAuthor';

const useStyles = makeStyles({
    info: {
        color: style.colorPrimary3_60
    },
    poolStatusList: {
        maxHeight: '400px',
    },
    poolStatusItem: {
        display: 'flex',
        flexDirection: 'row',
        '&>*:not(:first-child)': {
            marginLeft: '0.5rem'
        }
    },
    icon: {
        width: '35px',
        height: '35px',
        borderWidth: '3px',
        borderStyle: 'solid',
        borderRadius: '50%',
        marginTop: '2px',
    },
    iconButton: {
        padding: 0,
        marginLeft: '0.5rem',
        '& $icon': {
            borderColor: style.colorPrimary2,
        },
        '& *': {
            color: style.colorPrimary2,
        }
    },
    iconButtonDisabled: {
        '& $icon': {
            borderColor: style.colorPrimary3_20,
        },
        '& *': {
            color: style.colorPrimary3_20,
        }
    },
    dealCouponType: {
        color: 'rgba(0,183,50,0.5)', //эstyle.colorPrimary2,
        // fontWeight: '500',
        width: '50px !important',
        textAlign: 'right',
    },
    dealDebt: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        color: '#000',
    },
    dealDebtUnit: {
        color: style.colorPrimary3_40,
        marginLeft: '0.4rem',
    },
    dealItemName: {

        // marginRight: '2rem',
        color: '#000',
    },
    dealItemType: {
        marginLeft: '1rem !important',
        color: style.colorPrimary2_60,
    },
    dealItemInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexWrap: 'nowrap',
    },
    dealItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        width: '100%',
        '& *:not(:first-child)': {
            marginLeft: '0.5rem',
            // color: `${style.colorPrimary3_40}`,
            // '&::before': {
            //     content: '"/"',
            //     marginRight: '0.5rem',
            //     // color:`${style.colorPrimary3_40}`,
            // }
        },

    },
    originatorCardDivider: {
        marginRight: '0.5rem',
    },
    originatorCard: {
        borderRadius: '0.3rem',
        border: `solid 1px ${style.colorPrimary3}`, //`solid 1px ${style.colorPrimary2_80}`,
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '120px',
        // maxWidth: '120px',
        padding: '4px 0.5rem',
        transition: 'all 0.3s ease',
        position: 'relative',
        overflow: 'visible',
        cursor: 'pointer',
        marginBottom: '4px',
        textAlign: 'left',
        textTransform: 'initial',
        fontSize: '18px',
        fontWeight: 'normal',
        fontFamily: style.fontFamilyBase,
        '& .MuiBox-root': {
            alignItems: 'center',
            letterSpacing: 'initial',
        },
        '&:hover': {
            opacity: 1,
            backgroundColor: `${alpha(style.colorPrimary3, 0.07)}`
        }
    },
    active: {
        backgroundColor: `${alpha(style.colorPrimary2, 0.2)}`,
        borderColor: `${alpha(style.colorPrimary2, 1)}`
    },
    rootContainer: {
        display: 'flex',
        margin: '10px 3px 0 0',
        alignItems: 'flex-start',
        overflow: 'visible',
        '& *': {
            userSelect: 'none !important',
        }
    },
    originators: {
        '&*': {
            userSelect: 'none',
        },
        display: 'flex',
        overflowX: 'auto',
        width: '100%',
        // marginRight: '5px',
        marginBottom: '0px',
        marginTop: '3px',
        alignItems: 'flex-start',
        '&::-webkit-scrollbar': {
            height: '9px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '9px'
        },
    },
    dealContainer: {
        display: 'flex',
        marginRight: '1rem',
        alignItems: 'flex-start',
    },
    dealCard: {
        // alignSelf: 'self-start',
        borderRadius: '0.3rem',
        marginTop: '3px',
        padding: '2px 0.5rem 3px 0.5rem',
        border: `solid 1px ${style.colorPrimary2_80}`,
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '225px',
        maxWidth: '225px',

        transition: 'all 0.3s ease',
        position: 'relative',
        overflow: 'visible',

        alignSelf: 'flex-start',
        // marginTop: '5px',
        '&:hover': {
            opacity: 1
        }
    },
    originatorsContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexGrow: 1,
        overflow: 'hidden',
        height: '52px !important',
    },
    originatorNameBlock: {
        height: '1.5rem !important',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    originatorName: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        // paddingRight: '0.5rem',
        // height: '1.5rem !important',
        // display: 'flex',
        // alignItems: 'center',
    },
    userChips: {
        display: 'flex',
        alignItems: 'center',
    },
    floatStatusIcon: {
        // position: 'absolute',
        // left: 0, top: 0,

    },
    addDealButton: {
        height: '30px',
        width: '30px',
        // borderRadius: '100%',
        marginBottom: '14px',
        backgroundColor: 'transparent'
    },
    itemActionArea: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    itemContent: {
        display: 'flex',
        flex: '1 1 auto',
        height: '100%'
    },
    buttonContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    transparent: {
        opacity: 0.3
    },
    cardSelect: {
        backgroundColor: 'rgb(242,248,232)',//style.colorPrimary2_20,
        // border: '1px solid ' + style.colorPrimary1
        // '&::after': {
        //     content: '""',
        //     position: 'absolute',
        //     right: 0,
        //     bottom: '-7px',
        //     left: 0,
        //     height: '5px',
        //     borderLeft: '111px solid transparent',
        //     borderRight: '111px solid transparent',
        //     borderTop: `6px solid ${style.colorPrimary2}`,
        // }
    },
    cardText: {
        color: style.colorPrimary1,
        fontSize: '0.9rem'
    },
    cardName: {
        paddingRight: '0.5rem'
    },
    spinnerPlacement: {
        position: 'relative',
        color: style.colorPrimary1,
        fontSize: '0.9rem'
    },
    typeLabel: {
        color: style.colorPrimary3_80,
        // fontWeight:'bolder',
        textTransform: 'uppercase'
    },
    flexFullSize: {
        display: 'flex',
        //flex: '1 1 auto',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    popOverRoot: {
        pointerEvents: "none"
    }
});

interface IProps {
    selectedDeal: number
    deals: any[]
    createDeal: () => void
    openDeal: (dealId: number) => void
    removeDeal: (dealId: number) => void
    comparePools: () => void
    intermediate: boolean
    // iterationOnlineChanges: Object
    currentOnlineUsers: any
    // updatedFields: any
}

const DealsList = (props: IProps) => {

    const { deals, selectedDeal, intermediate, createDeal, openDeal, removeDeal, comparePools } = props

    let slider = useRef()
    const selectedDealRef = useRef()
    const [sliderState, setSliderState] = useState({ clientX: 0, scrollX: 0, isScrolling: null })
    const [onlineUsers, setOnlineUsers] = useState([])
    const [originatorList, setOriginatorList] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [originatorId, setOriginatorId] = useState(null)
    const [deal, setDeal] = useState(null)
    const {
        deal: storeDeal = null,
        selectedNode = null,
        poolCompareArray: storePoolCompareArray = [], poolsHubMessage = null,
        permissions,
        ...otherStor
    } = useSelector((state: any) => state.securitization)
    const [poolCompareArray] = useLocalStorage([], 'Securitization/PoolCompareArray')
    const dispatch = useDispatch()
    const [poolInProcessingArray, setPoolInProcessingArray] = useState([])
    const [showProcessingList, setShowProcessingList] = useState(null)

    useEffect(() => {
        //     console.log('poolInProcessingArray', poolInProcessingArray)
        if (!poolInProcessingArray?.length) setShowProcessingList(null)
    }, [poolInProcessingArray])

    useEffect(() => {
        let poolsArray = []
        let queueCount = 0
        // console.log('deals', deals)
        deals?.forEach(item => item?.deal?.poolNodes?.forEach(node => node?.pools?.forEach(pool => {
            // console.log('poolstatus', item.deal.id, pool.status)
            if ([
                // PoolProcessingStatus.STATUS_LOADED,
                // PoolProcessingStatus.STATUS_PROCESSING,
                // PoolProcessingStatus.STATUS_SENDING,
                PoolProcessingStatus.STATUS_SENT,
                // PoolProcessingStatus.STATUS_ATTENTION_REQUIRED,
            ].includes(pool.status)
            ) {
                // console.log('pool.status', node?.pools, pool.status, pool.poolStatusString)

                if (pool?.statusString?.toLowerCase() == 'В очереди на расчет'.toLowerCase()) queueCount++

                // console.log('queueCount', queueCount)
                poolsArray.push({
                    dealName: item?.deal?.name,
                    dealID: item?.deal?.id,
                    poolID: pool?.id,
                    // poolDEbt: pool?.currenrDebt,
                    poolStatus: pool?.status,
                    poolStatusString: pool?.statusString,
                    nodeID: node?.id,
                    percents: pool?.percents,
                    createdDate: new Date(pool?.createdDate).getTime(),
                })
            }
        })))
        // console.log('poolsArray', poolsArray)

        poolsArray = poolsArray
            ?.sort((a, b) => a?.createdDate < b?.createdDate ? 1 : a?.createdDate > b?.createdDate ? -1 : 0)
            ?.map((pool, index) => {
                // console.log('pool.status!!!!!!!!!!!!!!!!!', pool?.poolStatusString?.toLowerCase(), 'В очереди на расчет'.toLowerCase())
                if (pool?.poolStatusString?.toLowerCase() == 'В очереди на расчет'.toLowerCase()) {
                    return { ...pool, queue: queueCount-- }
                }

                return pool
            })
        // console.log('poolsArray>>>>>>>>>>>>>>>>>', poolsArray)

        setPoolInProcessingArray(poolsArray)

        return () => {
            // second
        }
    }, [deals])

    const { poolCompareArray: reduxPoolCompareArray } = useSelector((state: any) => state.securitization)
    useEffect(() => {
        // if (poolCompareArray?.some(item => !reduxPoolCompareArray?.includes(item))) {
        if (poolCompareArray?.length != reduxPoolCompareArray?.length) {
            actionSecuritization.setPoolCompareArray(poolCompareArray)(dispatch)
        }
    }, [poolCompareArray])

    var currentlyHovering = false;

    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()

    useEffect(() => {
        if (storeDeal?.deal?.id != deal?.id)
            setDeal(storeDeal?.deal)
    }, [storeDeal])

    useEffect(() => {
        // console.log('deals !!!!!', deals)

        let originators = []

        deals.map(item => {
            // console.log('deal', item.deal)
            if (item?.deal?.originator?.id && !originators.some(x => x.id == item.deal.originator.id)) {
                originators.push({ id: item.deal.originator.id, name: item.deal.originator.shortName })
            }
        })
        // console.log('originators !!!', originators !!!)
        originators = originators.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
        // console.log('originators', originators)
        setOriginatorList(originators)
        // setDeal(null)
    }, [deals])


    // console.log('originatorList', deals, originatorList)

    const onMouseMove = (e) => {
        // if (slider && slider.current && !slider.current.contains(e.target))
        //     return
        e.preventDefault()
        if (sliderState.isScrolling && slider?.current) {
            slider.current.scrollLeft = sliderState.scrollX - e.clientX + sliderState.clientX
        }
    }

    // console.log('currentOnlineUsers', currentOnlineUsers)
    const onMouseDown = (e) => {
        if (slider && slider.current && !slider.current?.contains(e.target))
            return
        e.preventDefault()
        window.addEventListener('mouseup', onMouseUp)
        setSliderState(prev => {
            return {
                ...prev,
                scrollX: slider.current.scrollLeft,
                isScrolling: true,
                clientX: e.clientX
            }
        })
    }

    const onMouseUp = (e) => {
        // if (slider && slider.current && !slider.current.contains(e.target))
        //     return
        e.preventDefault()
        window.removeEventListener('mouseup', this)
        setSliderState(prev => {
            return {
                ...prev,
                scrollX: prev.scrollX - e.clientX + prev.clientX,
                clientX: e.clientX,
                isScrolling: false
            }
        })
    }

    const GetCouponType = (type) => {
        switch (type) {
            case CouponType.TYPE_FIXED:
                return t("securitization.CouponTypeFixedShort")
                break;
            case CouponType.TYPE_FLOAT:
                return t("securitization.CouponTypeFloatingShort")
                break;
            case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                return t("securitization.CouponTypeStrKeyrateAndPremiumShort")
                break;
            default:
                return null
        }
    }

    useLayoutEffect(() => {
        // if (selectedDealRef.current)
        //     selectedDealRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
        // console.log('deals ###', deals)
        let item = deals.filter((x, index) => index == selectedDeal)[0]
        // console.log('item', item)
        if (Boolean(item))
            setDeal({ ...item?.deal, lastIterationStatus: item?.lastIteration?.status })
    }, [selectedDeal, deals])

    useEffect(() => {
        const el = slider.current
        if (el) {
            const onWheel = e => {
                e.preventDefault()
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY * 4,
                    behavior: 'smooth'
                })
            }

            el.addEventListener('wheel', onWheel)

            return () => {
                if (el)
                    el.removeEventListener('wheel', onWheel)
            }
        }
    }, [])

    // useEffect(() => {
    //   console.log('selectedDeal', selectedDeal)

    //   return () => {
    //     // second
    //   }
    // }, [currentOnlineUsers])


    const formStatus = (status) => {
        if (status != null) {
            switch (status) {
                case IterationStatus.STATUS_PROCESSING:
                    {
                        return <SpinnerBars size="middle" withBackground />
                    }
            }
        }
        return <span></span>
    }

    const formType = (dealType) => {
        switch (dealType) {
            case DealType.TYPE_BOND_PUBLISH:
                {
                    return t('MBSDOMRF');
                }
                break;
            case DealType.TYPE_BOND_AT_BALANCE:
                {
                    return t('Multioriginator');
                }
                break;
            case DealType.TYPE_POOL_BUYOUT:
                {
                    return t('PoolBuyout');
                }
                break;
        }

        return '';
    }

    const checkAlerts = (item) => {
        const validation = { valid: true, color: '', title: '' }
        if (item?.lastIteration?.validationState & IterationValidationState.STATE_PARAMETERS_INVALID) {
            validation.valid = false
            validation.color = 'rgb(0,191, 255)'
            validation.title = t('STATE_PARAMETERS_INVALID')
        } else
            if (item?.lastIteration?.validationState & IterationValidationState.STATE_POOL_INVALID) {
                validation.valid = false
                validation.color = 'rgb(0,255, 153)'
                validation.title = t('STATE_POOL_INVALID')
            } else
                if (item?.lastIteration?.validationState & IterationValidationState.STATE_GUARANTEE_INVALID) {
                    validation.valid = false
                    validation.color = 'rgb(185,185,185)'
                    validation.title = t('STATE_GUARANTEE_INVALID')
                } else
                    if (item?.lastIteration?.validationState & IterationValidationState.STATE_PRICING_PARAMETERS_INVALID) {
                        validation.valid = false
                        validation.color = 'rgb(192,0,0)'
                        validation.title = t('STATE_PRICING_PARAMETERS_INVALID')
                    } else

                        switch (item?.lastIteration?.status) {
                            case IterationStatus.STATUS_FAILED:
                                validation.valid = false
                                validation.color = 'rgb(192,0,0)'
                                validation.title = t('IterationFaled')
                            default:
                        }

        return validation
    }

    const handleMenuOpen = (e, id) => {
        if (e.currentTarget != anchorEl) {
            setAnchorEl(e.currentTarget)
            setOriginatorId(id)
        }
    }

    const handleMenuClose = () => {
        setOriginatorId(null)
        setAnchorEl(null)
    }

    function handleHover() {
        currentlyHovering = true
    }


    function handleCloseHover() {
        currentlyHovering = false
        setTimeout(() => {
            if (currentlyHovering == false) {
                handleMenuClose();
            }
        }, 0)
    }

    const getCouponType = (type) => {
        //   "CouponTypeFixedShort": "фикс.",
        //   "CouponTypeFloatingShort": "плав.",
        //   "CouponTypeStrKeyrateAndPremiumShort": "КС+",
        switch (type) {
            case CouponType.TYPE_FIXED:
                return t("securitization.CouponTypeFixedShort")

            case CouponType.TYPE_FLOAT:
                return t("securitization.CouponTypeFloatingShort")

            case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                return t("securitization.CouponTypeStrKeyrateAndPremiumShort")

            default:
                break
        }
    }
    return (
        <>
            {originatorId != null &&
                <Menu
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorEl={anchorEl}
                    // position="bottol-left"
                    autoFocus={false}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        onMouseEnter: handleHover,
                        // onMouseOver: handleHover,
                        onMouseLeave: handleCloseHover,
                        style: { pointerEvents: "auto", }
                    }}
                    // getContentAnchorEl={null}
                    PopoverClasses={{
                        root: classes.popOverRoot
                    }}
                >
                    {/* {console.log('deals', deals)} */}
                    {
                        deals.map((x, index) => {
                            if (x.deal.originator.id == originatorId) return { index: index, deal: x.deal, lastIteration: x.lastIteration }
                            else
                                return null
                        })
                            ?.filter(x => x != null)
                            ?.reverse()
                            ?.map(item => {
                                // console.log('item!!!!!!!!!!', item)
                                const couponTypeStr = GetCouponType(item?.lastIteration?.couponType);
                                return (
                                    <MenuItem component="div" className={classes.dealItemContainer} key={`deal-link-${item.deal.id}`}
                                        onClick={() => {
                                            // setAnchorEl(null) //close originator deals list on change 
                                            openDeal(item.index)
                                        }}
                                        disableRipple>

                                        <Box className={classes.dealItemName}>{item.deal.name}</Box>
                                        <Box className={classes.dealItemType}>{getCouponType(item?.deal?.couponType)}</Box>

                                        {/* {item?.lastIteration &&
                                            <Box className={classes.dealItemInfo}>
                                                <Box className={classes.dealDebt}>
                                                    {Localization.FormatNumber(item?.lastIteration?.pool?.currentMainDebt / 1000000000, 1, 1)}
                                                    <Box className={classes.dealDebtUnit} component="span">{t("Billions")}</Box>
                                                </Box>

                                                <Box className={classes.dealCouponType}>
                                                    {couponTypeStr ? couponTypeStr : <></>}
                                                </Box>
                                            </Box>
                                        } */}
                                    </MenuItem>
                                )
                            }
                            )}
                </Menu>
            }
            <div className={classes.rootContainer}>
                <div className={classes.originatorsContainer}>
                    <div ref={slider} className={classes.originators} key={'slider'}
                        onMouseDown={onMouseDown}
                        // onMouseUp={onMouseUp}
                        onMouseMove={onMouseMove}
                    >
                        {/* {console.log('originatorList',originatorList)} */}
                        {(originatorList?.length ?? 0) != 0 ? (
                            originatorList.map((item, index) => {
                                // console.log(deal)
                                return (
                                    <Box key={`originatorItem-${item.id}`} className={classes.originatorCardDivider}>
                                        {/* {console.log('deal', deal)} */}
                                        <Button
                                            className={[
                                                classes.originatorCard,
                                                (Boolean(deal) && deal.originator.id == item.id) /*(item.id == originatorId)*/ ? classes.active : '',
                                                (Boolean(deal) && deal.originator.id != item.id && item.id != originatorId) ? classes.transparent : ''
                                            ].join(' ')}
                                            onClick={(e) => { handleMenuOpen(e, item.id) }}
                                            // onMouseOver={(e) => { handleMenuOpen(e, item.id) }}
                                            onMouseLeave={handleCloseHover}
                                        >
                                            <Box className={classes.originatorNameBlock}>
                                                <Box className={classes.originatorName}>
                                                    {(Boolean(deal) && deal.originator.id == item.id) ? deal?.name : item.name}
                                                </Box>
                                            </Box>
                                        </Button>
                                    </Box>
                                )
                            })


                        ) : (!!intermediate) ? (
                            <div className={classes.flexFullSize}>
                                <SpinnerBars />
                            </div>
                        ) : (
                            <div className={classes.flexFullSize}>
                                <h4 style={{ color: "gray" }}>
                                    {/* {t('NoDeals')} */}
                                </h4>
                            </div>
                        )
                        }
                    </div>

                </div>
                {(permissions?.isAdmin || permissions?.isManager) &&
                    <Tooltip title={t('CreateNewDeal')} disableInteractive>
                        <IconButton size="small" onClick={createDeal} className={classes.iconButton}>
                            <AddOutlined color='secondary' className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                }
                {/* { */}
                {/* Boolean(storePoolCompareArray?.length) && */}
                <Tooltip title={t('Comparation')} disableInteractive>
                    <Badge badgeContent={storePoolCompareArray?.length} color="primary" overlap="circular">
                        <IconButton size="small" onClick={comparePools} className={classes.iconButton}>
                            <CompareArrowsOutlined color='secondary' className={classes.icon} />
                        </IconButton>
                    </Badge>
                </Tooltip>
                <IconButton
                    classes={{ root: classes.iconButton, disabled: classes.iconButtonDisabled }}
                    disabled={Boolean(!poolInProcessingArray?.length)}
                    onClick={(e) => { if (!showProcessingList) setShowProcessingList(e.currentTarget) }}
                >
                    <HourglassEmpty className={classes.icon} />
                </IconButton>

                {/* } */}
            </div>
            {/* {console.log('deals', deals)} */}
            {<Menu
                open={(showProcessingList ?? false) && Boolean(poolInProcessingArray.length)}
                anchorEl={showProcessingList}
                onClose={() => setShowProcessingList(null)}
                classes={{ root: classes.poolStatusList }}
            >
                {poolInProcessingArray
                    ?.sort((a, b) => a?.createdDate < b?.createdDate ? -1 : a?.createdDate > b?.createdDate ? 1 : 0)
                    ?.map(pool => <MenuItem
                        key={'processing_pool_' + pool.id}
                        className={classes.poolStatusItem}
                        onClick={() => {
                            // console.log('deals', deals)
                            let index = deals?.findIndex(x => x.deal.id == pool?.dealID)
                            if (index != -1) {
                                openDeal(index)
                            }
                            // actionSecuritization.getDealWithChanges(pool?.dealID)(dispatch)
                            // actionSecuritization.getNodeWithChanges(pool?.nodeID)(dispatch)

                        }
                        }>
                        <Box>{pool?.dealName}</Box>
                        <Box>{pool?.poolStatusString ? pool?.poolStatusString : t(humanReadableStatuses?.get(pool?.poolStatus))} {pool?.percents ? `${Math.floor(pool?.percents)}%` : ''}</Box>
                        {pool?.queue != null && <Box className={classes.info}>№ в очереди: {pool?.queue}</Box>}
                    </MenuItem>)
                }
            </Menu>}
        </>
    )
}

export default DealsList;