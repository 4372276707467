import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import AddBox from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import LinearProgress from '@mui/material/LinearProgress';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';


import clsx from 'clsx';
import style from '@Styles/domrf-theme.module.scss';

import { debounce } from 'throttle-debounce';
import SearchPanelInput from '@Components/shared/SearchPanelInput';
import PortfolioEditorPage from './PortfolioEditorPage';

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from 'react-i18next'


import { createStyles, makeStyles } from '@mui/styles'
import { Paper, Stack, alpha, Box, Container, Tab, Tabs, Theme, Button, Typography, InputAdornment, Tooltip } from '@mui/material'
import ConfirmationForm from "@Components/shared/ConfirmationForm";

import { actionCreators as calculatorStore } from "@Store/CalculatorStore";
import { actionCreators as actionTopNavbar } from '@Store/TopNavbarStore'

import Localization from '@Components/Localization';
import { ProcessingStatus } from '@Components/Definitions';
import MessagesListDialog from '@Components/shared/MessagesListDialog';
import { MessageType, EntityType } from '@Components/Definitions';
import CustomTooltip from '@Components/shared/CustomTooltip';

import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridActionsCellItem,
    GridColumnGroupingModel,
    GridComparatorFn,
    GridValueFormatterParams,
    GridRenderCellParams,
    GridPaginationModel,
    GridCallbackDetails,
    GridSortModel
} from '@mui/x-data-grid-premium';
import { ruRU, enUS } from '@mui/x-data-grid/locales';

const useStyles = makeStyles((theme: Theme) => createStyles({

    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        flex: '1 1 auto',
        height: 0,
        width: '100%',
        minWidth: '100%'
    },
    searchField: {
        marginLeft: 'auto'
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    progress: {
        width: '100%'
    },
    warningIcon: {
        color: style.colorWarning
    },
    statusButton: {
        padding: '0'
    }
}));

const PortfoliosPage = () => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    //const [startPageIndex, setStartPageIndex] = useState(0); //CHECKME!!! add to user settings
    //const [pageSize, setPageSize] = useState(50); //CHECKME!!! add to user settings
    const [searchValue, setSearchValue] = useState('');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50
    });

    const [locale, setLocale] = useState(ruRU);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [editPopupTitle, setEditPopupTitle] = useState(null);
    const [editedRow, setEditedRow] = useState(null);
    const [showErrors, setShowErrors] = useState(false);
    const [entityId, setEntityId] = useState(null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'actualDate',
            sort: 'desc',
        },
    ]);

    const rowRef = useRef(null);

    const {
        portfolios,
        newPortfolio,
        updatedPortfolio,
        isLoading = false
    } = useSelector((state: any) => state.calculator)

    const {
        portfoliosLoad
    } = useSelector((state: any) => state.loader) 

    useEffect(() => {

        actionTopNavbar.setTitle(t('Portfolios'))(dispatch)
        return () => {
            actionTopNavbar.setTitle("")(dispatch)
            searchDebounced.cancel();
        }
    }, [])

    useEffect(() => {
        searchDebounced(searchValue);
    }, [searchValue, paginationModel, sortModel]);

    useEffect(() => {

        if (newPortfolio == null)
            return;

        var newItems = [...items, newPortfolio];
        setItems(newItems);
        setCount(count + 1);

    }, [newPortfolio]);

    useEffect(() => {

        if (updatedPortfolio == null)
            return;

        var newItems = [...items];

        var index = newItems.findIndex(item => item.id == updatedPortfolio.id);
        if (index == -1) {
            throw `Updated element with id = ${updatedPortfolio.id} not found`;
        }

        newItems[index] = updatedPortfolio;

        setItems(newItems);

    }, [updatedPortfolio]);

    useEffect(() => {
        if (i18n.language == 'ru') {
            setLocale(ruRU);
        }
        else {
            setLocale(enUS);
        }

    }, [i18n]);

    useEffect(() => {

        setItems(portfolios?.items ?? []);
        setCount(portfolios?.count ?? 0);

    }, [portfolios]);

    const handleEdit = (params: any) => {
        rowRef.current = params;

        var row = {
            Id: params.row.id,
            createdDate: params.row.createdDate,
            actualDate: params.row.actualDate,
            bondId: params.row.bondId,
            name: params.row.name,
            description: params.row.description,
            fileName: params.row.fileName,
            sourceFileId: params.row.sourceFileId,
        }
        setEditedRow(row);

        setEditPopupTitle(t('Edit'));

        setShowEditPopup(true);
    }

    const handleDelete = (params: any) => {
        rowRef.current = params;
        setShowConfirmDialog(true);
    }

    const applyRecordRemove = () => {
        setShowConfirmDialog(false);

        //CHECKME!!! add remove result and bind table update
        calculatorStore.removePortfolio([rowRef.current.id])(dispatch);

        setItems(items.filter(row => row.id != rowRef.current.id));
        setCount(count - 1);

        rowRef.current = null;
    }

    const declineRecordRemove = () => {
        setShowConfirmDialog(false);
        rowRef.current = null;
    }

    const handleRowChange = (name, value) => {

        setEditedRow({ ...editedRow, [name]: value });
    }

    const applyRowChanges = (row, updatedFields) => {

        if (row.Id == null) {
            //add row
            var formData = new FormData();
            for (var propertyName in row) {

                formData.append(propertyName, row[propertyName] instanceof Date ? Localization.FormatDateISO(row[propertyName]) : row[propertyName]);
            }

            //result = await CalculatorService.createPortfolio(formData);

            calculatorStore.createPortfolio(formData)(dispatch);

            //administrationStore.createGroups([row])(dispatch);
        }
        else {
            //update row

            //var updated = [];
            var item = { id: row.Id };
            Object.entries(updatedFields).forEach(([key, value]) => {
                item[key] = { Value: value };
            });
            //updated.push(item);

            calculatorStore.updatePortfolio(item)(dispatch);

            /*
            var index = items.findIndex(x => x.id == row.id);
            if (index != -1) {
                var current = items[index];

                Object.entries(updatedFields).forEach(([key, value]) => {
                    current[key] = value;
                });
            }
            */
        }

        setShowEditPopup(false);
        rowRef.current = null;
        setEditedRow(null);
    }

    const cancelRowChanges = (test) => {
        setShowEditPopup(false);
        rowRef.current = null;
        setEditedRow(null);
    }

    const addNewRecord = () => {
        var dummy = { createdDate: new Date() };
        rowRef.current = dummy;

        setEditedRow(dummy);
        setEditPopupTitle(t('NewPortfolio'));
        setShowEditPopup(true);
    }

    const onPortfolioOpen = (row: any) => {

        setEntityId(row.id);
        setShowErrors(true);
    }

    const onErrorsDialogClose = () => {
        setShowErrors(false);
    }

    const renderStatusCell = (row: any) => {

        var content = null;
        switch (row.status) {
            case ProcessingStatus.STATUS_UNKNOWN:
                {
                    return t('Error');
                }

            case ProcessingStatus.STATUS_AWAITING:
                {
                    return t('Awaiting');
                }
            case ProcessingStatus.STATUS_PROCESSING:
                {
                    return (
                        <div className={classes.root}>
                            {row.currentTime}
                            <LinearProgress variant="determinate" value={row.percentage} color="secondary" className={classes.progress} />
                        </div>
                    );
                }
            case ProcessingStatus.STATUS_READY:
                {
                    if (row.messagesAvailable) {
                        return (
                            <DoneIcon color="secondary" />
                        )
                    }
                    else {
                        return (
                            <IconButton
                                className={classes.statusButton}
                                aria-label="ready"
                                onClick={() => onPortfolioOpen(row)}
                                color="inherit"
                                size="large">
                                <WarningIcon className={classes.warningIcon} />
                            </IconButton>
                        );
                    }
                }
            case ProcessingStatus.STATUS_FAILED:
                {
                    return (
                        <IconButton
                            className={classes.statusButton}
                            aria-label="ready"
                            onClick={() => onPortfolioOpen(row)}
                            color="inherit"
                            size="large">
                            <CloseIcon color="error" />
                        </IconButton>
                    );
                }
            case ProcessingStatus.STATUS_SENDING:
                {
                    return t('Sending');
                }
            case ProcessingStatus.STATUS_SENT:
                {
                    return t('Sent');
                }
            case ProcessingStatus.STATUS_PREPARING:
                {
                    return t('Loading');
                }
            case ProcessingStatus.STATUS_REMOVING:
                {
                    return t('Removing');
                }
        }

        return ({ content });
    }

    const columns: GridColDef[] = [

        {
            field: 'actions',
            type: 'actions',
            width: 60,
            getActions: (params) => [
                <GridActionsCellItem icon={
                    <EditIcon />
                } label={'Редактировать'} onClick={() => handleEdit(params)} />,
                <GridActionsCellItem
                    disabled={params.row.sourceFileId == null || !(params.row.status == ProcessingStatus.STATUS_READY || params.row.status == ProcessingStatus.STATUS_FAILED)}
                    icon={
                        <DeleteIcon />
                    } label={'Удалить запись'} onClick={() => handleDelete(params)} />
            ],
            renderHeader: () => (
                <Tooltip
                    disableFocusListener
                    title={t('Add')}
                    aria-label={t('Add')}>
                    <IconButton onClick={addNewRecord}>
                        <AddBox />
                    </IconButton>
                </Tooltip>

            ),
        },


        { field: 'id', headerName: t('ID'), description: t('PortfolioID_Info') },
        {
            field: 'createdDate', headerName: t('CreatedDate'), description: t('PortfolioCreatedDateID_Info'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return '';
                }
                return Localization.FormatDateStringLocale(params.value);
            }
        },
        {
            field: 'actualDate', headerName: t('ActualDate'), description: t('PortfolioActualDate_Info'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return '';
                }
                return Localization.FormatDateStringLocale(params.value);
            }
        },
        {
            field: 'originatorId', headerName: t('Originator'), description: t('PortfolioOriginator_Info'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                var row = params.api.getRow(params.id)

                return row.originatorName;
            }
        },
        {
            field: 'bondId', headerName: t('Bond'), description: t('PortfolioBond_Info'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                var row = params.api.getRow(params.id)

                return row.bondName;
            }
        },
        { field: 'name', headerName: t('Name'), description: t('PortfolioName_Info'), width: 200 },
        { field: 'description', headerName: t('Description'), description: t('PortfolioDescription_Info'), width: 200 },
        {
            field: 'status', headerName: t('Status'), description: t('PortfolioStatus_Info'), width: 200,
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return renderStatusCell(params.row);
            }
        }
    ];

    /*
        dataSourceUpdate = async (type: DataSourceChangeType, data: any) => {
            if (data == null)
                return false;
    
            var result = null;
            switch (type) {
                case DataSourceChangeType.CREATE:
                    {
    
                        var formData = new FormData();
                        for (var propertyName in data) {
    
                            formData.append(propertyName, data[propertyName] instanceof Date ? Localization.FormatDateISO(data[propertyName]) : data[propertyName]);
                        }
    
                        result = await CalculatorService.createPortfolio(formData);
                    }
                    break;
                case DataSourceChangeType.UPDATE:
                    {
                        //form update structure
                        var toUpdate = new Object();
                        var keyField = "id";
    
                        if (data.changedFields != null) {
                            (toUpdate as any)[keyField] = data[keyField];
                            data.changedFields.forEach((item: string) => {
                                (toUpdate as any)[item] = { Value: data[item] instanceof Date ? Localization.FormatDateISO(data[item]) : data[item] };
                            });
                        }
                        else {
                            //update all
                            Object.keys(data).forEach((key) => {
                                (toUpdate as any)[key] = { Value: data[key] instanceof Date ? Localization.FormatDateTimeISO(data[key]) : data[key] };
                            });
                        }
    
                        result = await CalculatorService.updatePortfolio(toUpdate);
                    }
                    break;
                case DataSourceChangeType.REMOVE:
                    {
                        result = await CalculatorService.removePortfolio(data.id);
                    }
                    break;
            }
    
            if (result.hasErrors) {
                throw ('Error while processing request');
            }
    
            return result.value;
        };
    */
    const updateDataSource = (searchValue) => {

        var filterItem = null;
        //var model = newModel ?? paginationModel;
        //console.log('===> search: ' + searchValue)
        if (searchValue != '') {

            if (paginationModel.page != 0) {
                setPaginationModel({ ...paginationModel, page: 0 });
                return;
            }

            filterItem = [{
                Operator: "or",
                Values: [
                    {
                        Name: "name",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "description",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    }
                ]
            }];
        }

        var sortItem = sortModel.map((item, index) => {
            return {
                Name: item.field,
                IsAscending: item.sort == 'asc',
                SortOrder: index
            };
        });

        var filter = {
            Filters: filterItem,
            Sorters: sortItem,
            ItemIndex: paginationModel.page * paginationModel.pageSize,
            PageSize: paginationModel.pageSize
        };

        calculatorStore.getPortfolios(filter)(dispatch);
    }

    const onPaginationModelChange = (model: GridPaginationModel, details: GridCallbackDetails) => {

        setPaginationModel(model);
        searchDebounced(searchValue);
    }

    const searchDebounced = useCallback(debounce(500, updateDataSource), [paginationModel, sortModel]);


    return (
        <Container className={classes.container}>
            <PortfolioEditorPage
                row={editedRow}
                onChange={handleRowChange}
                onApplyChanges={applyRowChanges}
                onCancelChanges={cancelRowChanges}
                title={editPopupTitle}
                open={showEditPopup}
            />

            <ConfirmationForm
                isOpen={showConfirmDialog}
                header='Удаление'
                content='Вы хотите удалить запись?'
                onAccept={applyRecordRemove}
                onDecline={declineRecordRemove}
            />

            <MessagesListDialog
                show={showErrors}
                onClose={onErrorsDialogClose}
                entityType={EntityType.TYPE_PORTFOLIO}
                entityId={entityId}
            />

            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingX: '1rem', paddingY: '0.5rem' }}>
                <SearchPanelInput className={classes.searchField} value={searchValue} onValueChange={setSearchValue} placeHolder={t('Search')} />
            </Stack>
            {/*console.log("portfoliosLoad => " + portfoliosLoad)*/}
            <DataGridPremium
                sx={{
                    ["& .bandHeaderCell .MuiDataGrid-columnHeaderTitleContainer"]: {
                        justifyContent: 'center'
                    }
                }}
                //experimentalFeatures={{ columnGrouping: true }}
                disableColumnMenu
                loading={portfoliosLoad}
                columnHeaderHeight={30}
                rowHeight={25}
                rows={items}
                columns={columns}
                //columnGroupingModel={columnGrouping}
                localeText={locale.components.MuiDataGrid.defaultProps.localeText}

                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={onPaginationModelChange}
                rowCount={count}
                pagination
                pageSizeOptions={[30, 50, 100]}

                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            />
        </Container >
    )
}

export default PortfoliosPage;