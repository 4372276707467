import { User } from "react-oidc-context";

const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
}

export const OIDCConfig = {
    authority: "https://lk.ahml.ru/auth/realms/master",
    client_id: "app-dom-pricing_center.portal",
    local_redirect_callback: "/authCallback",
    redirect_uri: "https://xn--80atbdbsooh2gqb.xn--d1aqf.xn--p1ai/authCallback",
    onSigninCallback: onSigninCallback
};

export const SignalRHubUrl = "/hubs/notifications";


//we'll check, where site layes
export const PublishConiguration = "prod"

export const convention2ShowError = false
export const convention2governProgramsFractionLowerBorder = 0.5