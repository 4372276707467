import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react'
import styled from '@emotion/styled'
import style from '@Styles/domrf-theme.module.scss'
import { createStyles, makeStyles } from '@mui/styles'
import { Box, Button, IconButton, Slider, Tab, Tabs, ToggleButton, ToggleButtonGroup, Tooltip, alpha, debounce } from '@mui/material'
// import { ReactComponent as ExcelIcon } from "../../../../../src/images/excel-icon.svg"
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { actionCreators as actionAlert } from '@Base/store/AlertStore'
import { useDispatch } from 'react-redux'
import { SaveOutlined } from '@mui/icons-material'
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_exporting_data from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official'
import { roundDown, roundUp, setHighchartsLocalization } from '@Base/Utilities'
// import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { actionCreators as actionDownloads } from '@Base/store/DownloadStore'

import Localization from '@Base/components/Localization'
import SpinnerBars from '@Base/components/shared/SpinnerBars'
import CustomTooltip, { getTooltip } from '@Base/components/shared/CustomTooltip'
import CurrentRateChartV1 from '../charts/CurrentRateChartV1'
import useAsyncState from '@Base/components/hook/useAsyncState'
import useFunc from '@Base/components/hook/useFunc'
import UniversalInputField, { InputDataType } from '@Base/framework/components/Form/UniversalInputField'
import CurrentRateChartV2 from '../charts/CurrentRateChartV2'
import DownloaderAlert from '../../downloader/DownloaderAlert'
import { useNewDispatch, useNewSelector } from '@Base/index'

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: "DINPro-Bold",
    fontWeight: "700",
    letterSpacing: '1.5px',
    fontSize: '0.8rem !important',
    '& *': {
        fontSize: '0.8rem !important',
    },
    // color: style.colorPrimary3,
    padding: '0 0.5rem 0 9px',
    marginRight: '0.5rem',
    backgroundColor: 'rgba(183, 217, 139, 0.23)',
    borderColor: 'rgb(61,149,109)',
    color: 'rgb(61,149,109)',
    '&:hover': {
        borderColor: 'rgb(61,149,109) !important',
        backgroundColor: 'rgba(183, 217, 139, 0.4) !important',
        // color: `rgb(0,176,80) !important`,
    },
    minWidth: '2rem',
}));

const useStyles = makeStyles((theme) => createStyles({
    chartSwitch: {
        position: 'absolute',
        bottom: '0.5rem',
        left: '1rem',
    },
    fullPanelAlert: {
        position: 'absolute',
        left: 0, top: 0, right: 0, bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: style.colorPrimary3_40,
        textAlign: 'center',
        userSelect: 'none',
        textTransform: 'uppercase',
    },
    headersButtonOutlined: {
        letterSpacing: '1.5px',
        fontSize: '0.8rem !important',
        // color: style.colorPrimary3,
        padding: '0 0.5rem',
        marginRight: '0.5rem',
        backgroundColor: 'rgba(183, 217, 139, 0.23)',
        borderColor: 'rgb(61,149,109)',
        color: 'rgb(61,149,109)',
        '&:hover': {
            borderColor: `${style.colorPrimary2} !important`,
            backgroundColor: 'rgba(183, 217, 139, 0.23) !important',
            color: `rgb(0,176,80) !important`,
        }
        // not(.Mui-disabled)
        // lineHeight: '2rem',
    },
    textIcon: {
        fontSize: '0.8rem !important',
        textTransform: 'initial',
        padding: '0 0.5rem !important',
        minWidth: '40px !important',
        letterSpacing: 'initial',
        color: style.colorPrimary3_80,
        filter: 'grayscale(1)',
        fontFamily: 'DINPro, Helvetica',
        fontWeight: 100,
    },
    wideTooltip: {
        maxWidth: '400px !important'
    },
    coverLayout: {
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        zIndex: '+100',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',

    },
    root: {
        // marginRight: '0.2rem',
        // marginLeft: '0.2rem',
        // marginTop: '0.5rem',
        // height: '421px',
        // position: 'relative',
        position: 'absolute',
        // left: '0.2rem', top: '0.5rem', bottom: 0, right: '0.2rem',
        left: 0, top: 0, bottom: 0, right: 0,
        // maxHeight:'375px',
        '& $panelHeader *': {
            fontSize: '1rem'
        }
    },
    rootPaper: {
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'visible',
        boxSizing: 'border-box',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 0',
        justifyContent: 'space-between',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        marginLeft: '0.5rem',
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80,
    },
    panelData: {
        margin: '0.8rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        wlexWrap: 'nopwrap',
    },
    tooltip: {
        '& *': { fontSize: '0.8rem !important' },
        // '&>span': {
        //     outline: 'solid',
        //     border: 'solid 1px #000',
        // }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    poolTabContaner: {
        margin: 0,
        backgroundColor: 'transparent',
        minHeight: 'unset',
        position: 'relative',
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        },
    },
    tabRoot: {
        backgroundColor: '#FFF !important',
        padding: '0 0.5rem !important',
        minWidth: 'fit-content !important',
        color: `${alpha(style.colorPrimary3_60, 0.5)} !important`,
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        minHeight: '2rem',
        height: '2rem',
        fontSize: '1rem',
        whiteSpace: 'nowrap',
        color: style.colorPrimary3_40,
        position: 'relative',
        overflow: 'visible',
        transition: 'all 0.2s ease-in-out',
        // borderRight: `solid 1px ${style.colorPrimary3_40}`,
        '&::before': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: '3px',
            bottom: '-1px',
            width: '1px',
            backgroundColor: `${style.colorPrimary3_40}`,
        },
    },
    selectedTab: {
        '&::after': {
            backgroundColor: '#0002',
        },
        color: `${style.colorPrimary3_60} !important`,
        backgroundColor: '#FFF !important',
        borderBottom: 'solid 1px #FFF !important',
    },
    chartContainer: {
        position: 'relative',
        flexGrow: 1
    },
    chartContainerFlex: {
        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    centerData: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: style.colorPrimary3_40
    },
    saveButton: {
        position: 'absolute',
        top: '5px',
        right: '60px',
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.2rem',
        color: style.colorPrimary3_40
    },
    markActive: {
        boxShadow: `0px 0px 0px 4px ${alpha(style.colorPrimary2, 0.5)} !important`
    },
    mark: {
        backgroundColor: 'transparent',
    },
    sliderRoot: {
        color: style.colorPrimary3,
        width: '240px',
        minWidth: '240px',
        maxWidth: '240px',
        paddingTop: 0,
        paddingBottom: 0,
        // zIndex: +1,

    },
    // container: {
    //     position: 'relative',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     height: '100%'
    // },

    controlsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        //alignItems: 'center',
        justifyContent: 'center',
        // position: 'absolute',
        // left: '60px',
        // top: '7px',
    },

    controlBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'max-content',
        flexWrap: 'nowrap',
        // width: '30%'
    },
    spinnerContainer: {
        position: 'relative',
        width: '100%',
        height: '100%'
    }
}))

const CurrentRateChartPanel = () => {

    const ref = useRef()
    const cls = useStyles()
    const dispatch = useDispatch()
    // const [anyChartWasLoading, setAnyChartWasLoading] = useState(false)
    const [chartSwitchValue, setChartSwitchValue] = useState(true)
    const [readyForExport, setReadyForExport] = useState(false)
    // const {
    //     selectedBond = null,
    //     loading = false,
    //     pricingParameters = null,
    //     poolStatistics = null,
    //     error = null,
    //     calculationParameters = null,
    //     chartsData = null,
    //     loadingChartData = false
    // } = useSelector((state: any) => state.convention)

    const {
        selectedBond = null,
        loading = false,
        pricingParameters = null,
        poolStatistics = null,
        // error = null,
        // calculationParameters = null,
        // chartsData = null,
        // loadingChartData = false
    } = useNewSelector((state: any) => state.newConvention)

    // useEffect(() => {
    //     if (chartsData != null && anyChartWasLoading == false){
    //         setAnyChartWasLoading(true)
    //     }
    // }, [chartsData])

    useEffect(() => {
        if (chartSwitchValue != true) setChartSwitchValue(true)
        // setAnyChartWasLoading(false)
    }, [selectedBond?.isin])

    const downloadHandler = () => {
        if (pricingParameters?.bondID && poolStatistics?.reportDate) {
            actionAlert.addAlert({ component: <DownloaderAlert />, key: `downloadAlert_${Date.now()}`, id: `downloadAlert_${Date.now()}`, link: `api/Convention/DownloadConventionPoolLLD?bondId=${pricingParameters.bondID}&poolReportDate=${poolStatistics.reportDate}&full=true`, color: style.colorPrimary1 })(dispatch)
        }
        else
            actionAlert.addAlert({ text: 'Расчет не может быть загружен', color: style.colorPrimary1 })(dispatch)
    }

    const onSaveChartAsImageButtonClick = () => {
        // console.log('run saveFunc', saveFunc)
        if (saveFunc != null) {
            saveFunc.call()
        }
    }

    const chartSwitchHandle = (name, val) => {
        // console.log('val', val)
        setChartSwitchValue(val)
    }

    const getPanelTooltip = (poolType) => {
        // console.log('poolType', poolType)
        let tooltip = null
        switch (poolType) {
            case 1:
                tooltip = 'convention2.currentRateTitleTooltip1'
                break

            case 2:
                tooltip = 'convention2.currentRateTitleTooltip2'
                break

            case 3:
                tooltip = 'convention2.currentRateTitleTooltip3'
                break

            default:
                return null
        }
        return getTooltip({ tooltip: tooltip, source: { isin: pricingParameters?.isin, reportDate: poolStatistics.reportDate } })
    }

    return (
        <Box className={cls.root}>
            <Box className={cls.rootPaper}>
                {loading && <Box className={cls.coverLayout} />}
                <Box className={cls.panelHeader}>

                    {poolStatistics?.reportDate ?
                        <>
                            <Box className={cls.panelHeaderTitle}>
                                <CustomTooltip
                                    // hideIcon
                                    classes={{ popper: cls.wideTooltip }}
                                    sx={{ '& .MuiButtonBase-root': { marginLeft: '4px', }, '& svg': { color: style.colorPrimary3_40, width: '20px', height: '20px', } }}
                                    right
                                    content={getPanelTooltip(poolStatistics?.poolType)}
                                // enterDelay={0} leaveDelay={0}
                                >
                                    {`${t('convention2.CurrentMortgageRateAt')} ${Localization.FormatDateStringLocale(poolStatistics?.reportDate)}`}
                                </CustomTooltip>
                            </Box>
                            <Box>
                                {/* кнопка API*/}
                                <Tooltip
                                    disableFocusListener
                                    disableInteractive
                                    title={t('convention2.APITooltip')}
                                    enterDelay={0} leaveDelay={0}
                                    TransitionComponent={null}
                                    TransitionProps={{ timeout: 0 }}
                                    classes={{ tooltip: cls.wideTooltip }}
                                >
                                    <StyledButton
                                        variant="outlined"
                                        size="small"
                                        // disabled={chartData?.steps == null}
                                        onClick={() => { window?.open('/files/Конвенция ИЦБ ДОМ.РФ.pdf#getPoolsData', '_blank') }}
                                    >
                                        {t("convention2.API")}
                                    </StyledButton>
                                </Tooltip>

                                {/* кнопка СКАЧАТЬ ПУЛ */}
                                <Tooltip
                                    disableFocusListener
                                    disableInteractive
                                    title={t('convention2.downloadPoolTooltip')}
                                    enterDelay={0} leaveDelay={0}
                                    TransitionComponent={null}
                                    TransitionProps={{ timeout: 0 }}
                                >
                                    <StyledButton
                                        variant="outlined"
                                        size="small"
                                        // disabled={chartData?.steps == null}
                                        onClick={downloadHandler}
                                    >
                                        {t("convention2.downloadPool")}
                                    </StyledButton>
                                </Tooltip>

                                {/* кнопка Save */}
                                {<Tooltip
                                    disableFocusListener
                                    disableInteractive
                                    title={t('convention2.SaveAsImage')}
                                    enterDelay={0} leaveDelay={0}
                                    TransitionComponent={null}
                                    TransitionProps={{ timeout: 0 }}
                                >
                                    <IconButton size="small"
                                        disabled={!readyForExport}
                                        onClick={() => ref.current.exportChart()}
                                    >
                                        <SaveOutlined className={cls.icon} />
                                    </IconButton>
                                </Tooltip>
                                }
                            </Box>
                        </>
                        :
                        <Box className={cls.panelHeaderTitle}>
                            {t('convention2.CurrentMortgageRate')}
                        </Box>
                    }
                </Box>
                <Box className={cls.chartContainer}>
                    {/* {chartSwitchValue ?*/}
                    <CurrentRateChartV2 ref={ref} setReadyForExport={setReadyForExport} />                    
                    {/* кнопка version switch */}
                    {/* {anyChartWasLoading
                        &&
                        <Box className={cls.chartSwitch}>
                            <Tooltip
                                disableFocusListener
                                disableInteractive
                                title={t('convention2.ChartVersionSwitchTooltip')}
                            >
                                <UniversalInputField
                                    dataType={InputDataType.BOOLEAN}
                                    value={chartSwitchValue}
                                    handleValueChange={chartSwitchHandle}
                                />
                            </Tooltip>
                        </Box>
                    } */}
                </Box>
            </Box>
        </Box >
    )
}

export default CurrentRateChartPanel