import { useNavigate } from 'react-router-dom';
import { generatePath } from "react-router-dom";

import IconButton from '@mui/material/IconButton';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';

import EditIcon from '@mui/icons-material/Edit';
import AddBox from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import LinearProgress from '@mui/material/LinearProgress';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';

import style from '@Styles/domrf-theme.module.scss';

import { debounce } from 'throttle-debounce';
import SearchPanelInput from '@Components/shared/SearchPanelInput';

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from 'react-i18next'
import ConfirmDialog from '@Framework/components/ConfirmDialog';

import { createStyles, makeStyles } from '@mui/styles'
import { Paper, Stack, alpha, Box, Container, Tab, Tabs, Theme, Button, Typography, InputAdornment, Tooltip } from '@mui/material'

import { actionCreators as calculatorStore } from "@Store/CalculatorStore";
import { actionCreators as actionTopNavbar } from '@Store/TopNavbarStore'

import Localization from '@Components/Localization';
import { ProcessingStatus } from '@Components/Definitions';
import MessagesListDialog from '@Components/shared/MessagesListDialog';
import { MessageType, EntityType } from '@Components/Definitions';
import { Instruments } from '@Components/Instruments';

import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridActionsCellItem,
    GridColumnGroupingModel,
    GridComparatorFn,
    GridValueFormatterParams,
    GridRenderCellParams,
    GridPaginationModel,
    GridCallbackDetails,
    GridSortModel
} from '@mui/x-data-grid-premium';
import { ruRU, enUS } from '@mui/x-data-grid/locales';

const useStyles = makeStyles((theme: Theme) => createStyles({

    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        flex: '1 1 auto',
        height: 0,
        width: '100%',
        minWidth: '100%'
    },
    searchField: {
        marginLeft: 'auto'
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    progress: {
        width: '100%'
    },
    warningIcon: {
        color: style.colorWarning
    },
    statusButton: {
        padding: '0'
    }
}));

const CalculationsPage = () => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    //const [startPageIndex, setStartPageIndex] = useState(0); //CHECKME!!! add to user settings
    //const [pageSize, setPageSize] = useState(50); //CHECKME!!! add to user settings
    const [searchValue, setSearchValue] = useState('');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50
    });


    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmActionQuestion, setConfirmActionQuestion] = useState('');

    const [locale, setLocale] = useState(ruRU);
    const [showErrors, setShowErrors] = useState(false);
    const [entityId, setEntityId] = useState(null);

    const callbackRef = useRef(null);

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'createdDate',
            sort: 'desc',
        },
    ]);

    const {
        calculations
    } = useSelector((state: any) => state.calculator)

    const {
        calculationsLoad
    } = useSelector((state: any) => state.loader)

    useEffect(() => {

        actionTopNavbar.setTitle(t('Calculations'))(dispatch)
        return () => {
            actionTopNavbar.setTitle("")(dispatch)
            searchDebounced.cancel();
        }
    }, [])

    useEffect(() => {
        searchDebounced(searchValue);
    }, [searchValue, paginationModel, sortModel]);

    useEffect(() => {
        if (i18n.language == 'ru') {
            setLocale(ruRU);
        }
        else {
            setLocale(enUS);
        }

    }, [i18n]);

    useEffect(() => {

        setItems(calculations?.items ?? []);
        setCount(calculations?.count ?? 0);

    }, [calculations]);

    const handleDelete = (params: any) => {
        setConfirmActionQuestion(t("CalculationWillBeRemovedPermanently"));

        callbackRef.current = () => {
            calculatorStore.removeCalculation(params.row.id)(dispatch)
            var index = items.findIndex(x => x.id == params.row.id);
            if (index != -1) {
                var newItems = [...items];
                newItems.splice(index, 1);
                setItems(newItems);
            }
        };
        setShowConfirm(true);
    }

    const handleAddCalculation = (event) => {

        var instrument = Instruments().find(x => x.area === 'Calculator');
        if (instrument != null) {
            var action = instrument.actions.find(x => x.id === 'new');
            if (action != null) {
                navigate(action.url);
            }
        }

        return true;
    }
    
    const handleEditCalculation = (params) => {

        var instrument = Instruments().find(x => x.area === 'Calculator');
        if (instrument != null) {
            var action = instrument.actions.find(x => x.id === 'recalculate');
            if (action != null) {
                var args = { id: params.row.id };

                var path = generatePath(action.url, args);
                navigate(path);
            }
        }
    }

    const onErrorsDialogClose = () => {
        setShowErrors(false);
    }

    const onCalculationOpen = (row: any) => {

        if (row.status == ProcessingStatus.STATUS_FAILED) {
            setEntityId(row.id);
            setShowErrors(true);
            return;
        }

        if (row.status != ProcessingStatus.STATUS_READY)
            return;

        var instrument = Instruments().find(x => x.area === 'Calculator');
        if (instrument != null) {
            var action = instrument.actions.find(x => x.id === 'calculationResults');
            if (action != null) {
                navigate(action.url.split(':')[0] + row.id);
            }
        }
    }

    const renderStatusCell = (row: any) => {

        var content = null;
        switch (row.status) {
            case ProcessingStatus.STATUS_UNKNOWN:
                {
                    return t('Error');
                }

            case ProcessingStatus.STATUS_AWAITING:
                {
                    return t('Awaiting');
                }
            case ProcessingStatus.STATUS_PROCESSING:
                {
                    return (
                        <div className={classes.root}>
                            {row.currentTime}
                            <LinearProgress variant="determinate" value={row.percentage} color="secondary" className={classes.progress} />
                        </div>
                    );
                }
            case ProcessingStatus.STATUS_READY:
                {
                    if (row.messagesAvailable) {
                        return (
                            <DoneIcon color="secondary" />
                        )
                    }
                    else {
                        return (
                            <IconButton
                                className={classes.statusButton}
                                aria-label="ready"
                                onClick={() => onCalculationOpen(row)}
                                color="inherit"
                                size="large">
                                <WarningIcon className={classes.warningIcon} />
                            </IconButton>
                        );
                    }
                }
            case ProcessingStatus.STATUS_FAILED:
                {
                    return (
                        <IconButton
                            className={classes.statusButton}
                            aria-label="ready"
                            onClick={() => onCalculationOpen(row)}
                            color="inherit"
                            size="large">
                            <CloseIcon color="error" />
                        </IconButton>
                    );
                }
            case ProcessingStatus.STATUS_SENDING:
                {
                    return t('Sending');
                }
            case ProcessingStatus.STATUS_SENT:
                {
                    return t('Sent');
                }
            case ProcessingStatus.STATUS_PREPARING:
                {
                    return t('Loading');
                }
            case ProcessingStatus.STATUS_REMOVING:
                {
                    return t('Removing');
                }
        }

        return ({ content });
    }

    const handleRecalculate = (params: any) => {

        var question = t('RecalculationWillTakeTime');

        switch (params.row?.status) {
            case ProcessingStatus.STATUS_PROCESSING:
                {
                    question = t('ProcessingInProgress');
                }
                break;
            case ProcessingStatus.STATUS_READY:
                {
                    question = t('ReadyCalculationNotChnaged');
                }
                break;
            case ProcessingStatus.STATUS_FAILED:
                {
                    question = t('TryToRecalculate');
                }
                break;
            case ProcessingStatus.STATUS_SENT:
                {
                    question = t('CalculationSentAndQuiet');
                }
                break;
        }

        callbackRef.current = () => calculatorStore.recalculateCalculationById(params.row.id)(dispatch);
        setShowConfirm(true);
        setConfirmActionQuestion(question);
    }

    const onConfirmAction = () => {

        if (callbackRef.current)
            callbackRef.current();

        onCloseConfirm();
    }

    const onCloseConfirm = () => {
        callbackRef.current = null;
        setShowConfirm(false);
    }

    const handleDuplicate = (row: any) => {
        navigate('/instruments/calculator/duplicate/' + row.id);
    }

    const columns: GridColDef[] = [

        {

            field: 'actions',
            type: 'actions',
            width: 150,
            sortable: false,

            getActions: (params) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip
                            disableFocusListener
                            title={t('RemoveRecord')}
                            aria-label={t('RemoveRecord')}>
                            <DeleteIcon />
                        </Tooltip>
                    } label={t("RemoveRecord")} onClick={() => handleDelete(params)} />,
                <GridActionsCellItem
                    icon={
                        <Tooltip
                            disableFocusListener
                            title={t('Edit')}
                            aria-label={t('Edit')}>
                            <EditIcon />
                        </Tooltip>
                    } label={t("Edit")} onClick={() => handleEditCalculation(params)} />,
                <GridActionsCellItem
                    icon={
                        <Tooltip
                            disableFocusListener
                            title={t('RecalculateCalculation')}
                            aria-label={t('RecalculateCalculation')}>
                            <AutorenewIcon />
                        </Tooltip>
                    } label={t("RecalculateCalculation")} onClick={() => handleRecalculate(params)} />,
                <GridActionsCellItem
                    icon={
                        <Tooltip
                            disableFocusListener
                            title={t('Duplicate')}
                            aria-label={t('Duplicate')}>
                            <FileCopyIcon />
                        </Tooltip>
                    } label={t("Duplicate")} onClick={() => handleDuplicate(params)} />
            ],
            renderHeader: () => (
                <Tooltip
                    disableFocusListener
                    title={t('Add')}
                    aria-label={t('Add')}>
                    <IconButton onClick={handleAddCalculation}>
                        <AddBox />
                    </IconButton>
                </Tooltip>

            )
        },


        { field: 'id', headerName: t('ID'), description: t('calculations.id') },
        {
            field: 'createdDate', headerName: t('CreatedDate'), description: t('calculations.createdDate'), width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return '';
                }
                return Localization.FormatDateStringLocale(params.value);
            }
        },
        {
            field: 'originatorId', headerName: t('Originator'), description: t('calculations.originator'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                var row = params.api.getRow(params.id)

                return row.originatorName;
            }
        },
        {
            field: 'bondId', headerName: t('Bond'), description: t('calculations.bond'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                var row = params.api.getRow(params.id)

                return row.bondName;
            }
        },
        {
            field: 'portfolioId', headerName: t('Portfolio'), description: t('calculations.portfolio'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                var row = params.api.getRow(params.id)

                return row.portfolioName;
            }
        },
        { field: 'name', headerName: t('Name'), description: t('calculations.name'), width: 400 },
        { field: 'description', headerName: t('Description'), description: t('calculations.description'), width: 400 },

        {
            field: 'status', headerName: t('Status'), description: t('calculations.status'), width: 150, display: 'flex',
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return renderStatusCell(params.row);
            }
        }
    ];

    const updateDataSource = (searchValue) => {

        var filterItem = null;
        if (searchValue != '') {

            if (paginationModel.page != 0) {
                setPaginationModel({ ...paginationModel, page: 0 });
                return;
            }

            filterItem = [{
                Operator: "or",
                Values: [
                    {
                        Name: "name",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "description",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    }
                ]
            }];
        }

        var sortItem = sortModel.map((item, index) => {
            return {
                Name: item.field,
                IsAscending: item.sort == 'asc',
                SortOrder: index
            };
        });

        var filter = {
            Filters: filterItem,
            Sorters: sortItem,
            ItemIndex: paginationModel.page * paginationModel.pageSize,
            PageSize: paginationModel.pageSize
        };

        calculatorStore.getCalculations(filter)(dispatch);
    }

    const onPaginationModelChange = (model: GridPaginationModel, details: GridCallbackDetails) => {

        setPaginationModel(model);
        searchDebounced(searchValue);
    }

    const searchDebounced = useCallback(debounce(500, updateDataSource), [paginationModel, sortModel]);


    return (
        <Container className={classes.container}>
            <ConfirmDialog
                show={showConfirm}
                title={t('AreYouSureQuestion')}
                description={confirmActionQuestion}
                onConfirm={onConfirmAction}
                onClose={onCloseConfirm} />

            <MessagesListDialog
                show={showErrors}
                onClose={onErrorsDialogClose}
                entityType={EntityType.TYPE_CALCULATION}
                entityId={entityId}
            />

            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingX: '1rem', paddingY: '0.5rem' }}>
                <SearchPanelInput className={classes.searchField} value={searchValue} onValueChange={setSearchValue} placeHolder={t('Search')} />
            </Stack>
            {/*console.log("portfoliosLoad => " + portfoliosLoad)*/}
            <DataGridPremium
                sx={{
                    ["& .bandHeaderCell .MuiDataGrid-columnHeaderTitleContainer"]: {
                        justifyContent: 'center'
                    }
                }}
                //experimentalFeatures={{ columnGrouping: true }}
                disableColumnMenu
                disableRowSelectionOnClick={true}
                loading={calculationsLoad}
                columnHeaderHeight={30}
                rowHeight={25}
                rows={items}
                columns={columns}
                //columnGroupingModel={columnGrouping}
                localeText={locale.components.MuiDataGrid.defaultProps.localeText}

                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={onPaginationModelChange}
                rowCount={count}
                pagination
                pageSizeOptions={[30, 50, 100]}

                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            />
        </Container >
    )
}

export default CalculationsPage;