import "@Styles/components/AnonymousLayout.scss";
import * as React from 'react';
import Footer from "@Components/shared/Footer"
import { ToastContainer } from 'react-toastify';
import TopNavbar from "../components/shared/TopNavbar";
import { makeStyles } from '@mui/styles';
import Notifications from "@Base/components/shared/Notifications";

const useStyles = makeStyles({
    body: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    footer: {
        display: 'flex',
        flexShrink: '0',
        justifyContent: 'end',
        paddingBottom: '1rem'
    }

});

const AnonymousLayout = ({ children }) => {

    const classes = useStyles();

    return (

        <div id="anonymousLayout" className={classes.body}>
            <Notifications />
            <TopNavbar />
            <div className={classes.body} >
                {children}
            </div>
            <div className={classes.footer} >
                <Footer />
            </div>
            <ToastContainer />
        </div>);
};

export default AnonymousLayout;