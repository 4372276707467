// import useFormValidation from './ValidationForm/useFormValidation';
import DatePickerStandaloneValidator from "./DatePickerStandaloneValidator";
import useFormValidation from "./ValidationForm/useFormValidation";

const DatePickerValidator = ({
    name,
    value,
    errorMessages,
    validators,
    disabled,

    ...props
}) => {

    const { isValid, errorMessage } = useFormValidation(disabled, name, value, errorMessages, validators);
    // console.log(name, '>>>>> isValid=', isValid)
    //if (name == 'inclusionDate') console.log('rerender DATE', name, isValid)
    return (
        <DatePickerStandaloneValidator isValid={isValid} errorMessage={errorMessage} value={value} disabled={disabled} {...props} />
    );
}

export default DatePickerValidator;