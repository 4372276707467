import { Theme, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress'
import { createStyles, makeStyles } from '@mui/styles'
import { Children, useLayoutEffect, useState } from 'react'

const useStyles = makeStyles((theme: Theme) => {
  // console.log('theme', theme)
  return createStyles({
    bgCover: {
      zIndex: '+1000',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      // background: 'rgba(255, 255, 255, 0.5)',
    },
    showCover: {
      background: 'rgba(255, 255, 255, 0.5)',
    },
    spinnerBase: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    ProgressBase: {
      position: 'absolute',
      left: 0,
      color: theme?.palette?.grey[300],
    },
    Progress: {
      position: 'absolute',
      left: 0,
      [`& .${circularProgressClasses.circle}`]: {
        strokeLinecap: 'round',
      },
    },
    ValueRoot: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 0,
      borderRadius: '50%',
    },
    Value: {
      letterSpacing: 'normal'
    }
  })
})


const CustomCircularProgress = (props: CircularProgressProps) => {
  const { sx, value = null, color, classes = { ValueRoot: null, ProgressBase: null, Progress: null }, showCover = false, ...otherProps } = props
  // console.log('value', value)
  const [displayValue, setDisplayValue] = useState(null)
  // const displayValue = value == null ? null : value > 99 ? 99 : Math.round(value)
  const [showState, setShowState] = useState(false)

  useLayoutEffect(() => {
    if (value != null)
      setDisplayValue(value > 99 ? 99 : Math.round(value))
  }, [value])

  useLayoutEffect(() => {
    setTimeout(() => {
      setShowState(true)
    }, 500)
  }, [])

  const cls = useStyles()
  // console.log('Progress value', value, ' dv = ',displayValue)
  if (!showState)
    return <></>

  return <Box className={[cls.bgCover, showCover ? cls.showCover : ''].join(' ')} >
    <Box className={cls.spinnerBase}>
      <Box sx={{
        position: 'relative', display: 'inline-flex', justifyContent: 'center',
        width: `${(otherProps?.size) ?? 24}px`,
        height: `${(otherProps?.size) ?? 24}px`,
        ...sx
      }}>
        <CircularProgress
          variant="determinate"
          size={24}
          thickness={6}
          value={100}
          className={[cls?.ProgressBase, classes?.ProgressBase].join(' ')}
          {...otherProps}
        />
        <CircularProgress
          variant={value !== null ? "determinate" : "indeterminate"}
          // variant={"indeterminate"}
          disableShrink={value !== null ? true : false}
          value={displayValue}
          size={24}
          thickness={6}
          sx={{ color: color }}
          className={[cls?.Progress, classes?.Progress].join(' ')}
          {...otherProps}
        />
        {/* {console.log('classes?.ValueRoot', classes?.ValueRoot)} */}
        {value != null &&
          <Box
            className={[cls?.ValueRoot, classes?.ValueRoot].join(' ')}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
              fontSize={otherProps?.size ? Math.round(otherProps?.size / 3) : 8}

              className={[cls?.Value, classes?.Value].join(' ')}
            >{`${displayValue}%`}</Typography>
          </Box>
        }
      </Box>
    </Box>
  </Box>
}

export default CustomCircularProgress