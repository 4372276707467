/* eslint-disable */
import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
//import Promise from 'promise-polyfill';
/* eslint-enable */
import rules from './ValidationRules';

export interface FormContextProps {
    attachToForm: (component: any) => void;
    detachFromForm: (component: any) => void;
    onChildStateChanged: (name, value, isValid, isStateChanged) => void;
    instantValidate: boolean;
    debounceTime: number;
    //validate: (validator, value) => boolean;
    getValidator: (validator, value) => boolean;
}

export interface FormContextType {
    form: FormContextProps
};

interface IProps {
    onSubmit?: (event: any) => void;
    instantValidate?: boolean;
    onError?: () => void;
    debounceTime?: number;
    children: any[] | any;
    validators?: any[];
    onFieldChanged?: (name, value, isValid) => void;
    onFormStateChanged?: (isValid) => void;
}

interface IRefProps {
    isFormValid: (dryRun?: boolean, notifyFormChange?: boolean) => Promise<boolean>;
    submit: (event) => void;
    resetValidations: () => void;
}

const FormContext = React.createContext<FormContextType | null>(null);

const ValidatorForm = forwardRef<IRefProps, IProps>(({ onSubmit, instantValidate, onError, debounceTime, children, validators, onFieldChanged, onFormStateChanged, ...rest }: IProps, ref) => {

    const [localDebounceTime, setLocalDebounceTime] = useState(debounceTime);
    const [localInstantValidate, setLocalInstantValidate] = useState(instantValidate !== undefined ? instantValidate : true);

    const localData = useRef({
        childs: [],
        isValid: undefined,
        globalValidatedChilds: new Set(),
        //childsToUpdate: new Set(),
        isValidating: false
    });

    useImperativeHandle(ref, () => ({
        isFormValid: isFormValid,
        submit: submit,
        resetValidations: resetValidations
    }));

    useEffect(() => {

        return () => {
            localData.current.childs.forEach(input => {
                if (input.current != null) {
                    input.current.onFormUnmounted();
                }
            });

            localData.current.childs = [];
            localData.current.isValid = undefined;
            localData.current.globalValidatedChilds = undefined;
            //localData.current.childsToUpdate = undefined;
        };

    }, [])

    const attachToForm = (component) => {
        if (localData.current.childs.indexOf(component) === -1) {
            localData.current.childs.push(component);
        }
    }

    const detachFromForm = (component) => {
        const componentPos = localData.current.childs.indexOf(component);
        if (componentPos !== -1) {
            localData.current.childs = localData.current.childs.slice(0, componentPos)
                .concat(localData.current.childs.slice(componentPos + 1));
        }
    }

    const submit = (event) => {
        if (event) {
            event.preventDefault();
            event.persist();
        }

        walk(localData.current.childs).then((isValid) => {

            if (isValid && onSubmit)
                onSubmit(event);
        });
    }

    const proceedGlobalRules = (dryRun = false) => {

        //console.log('-----------------proceed global rules');

        if (validators == null || validators.length == 0) {
            return Promise.resolve([]);
        }

        if (localData.current.childs.length == 0)//no children
            return Promise.resolve([]);

        //apply global parameters
        var data = localData.current.childs
            .filter(x => x.current != null)
            .map(input => { return { input: input, state: input.current.getState() } });

        var fields = {};
        data.forEach(item => fields[item.state.name] = item.state.value);


        return Promise.all(validators.map(validator => validator(fields))).then((result) => {

            var merged = [].concat.apply([], result).filter(x => x != null);

            localData.current.globalValidatedChilds.clear();

            ////distinct invalid values
            var hash = {}
            merged.forEach(item => {
                hash[item.name] = item.message;
                //save for future fields update
                localData.current.globalValidatedChilds.add(item.name);
            });

            //console.log('-----------------proceed global rules --- set global states');
            //apply updates
            data.forEach(item => {

                if (hash[item.state.name] != null && !dryRun) {

                    item.input.current.setState(false, hash[item.state.name]);

                }/* else {

                    item.input.current.setState(true, '');
                }*/

                //if (onFieldChanged)
                //onFieldChanged(item.state.name, item.state.value, isValid);
            });

            return merged;
        });
    }

    const walk = (children, dryRun = false, notifyFormChange = false) => {

        localData.current.isValidating = true;

        return Promise
            .all(children
                .filter(x => x.current != null) //can be null if element not visible, for example
                .map(input => {
                    return validateChild(input, dryRun)
                })
            ).then((data) => {

                if (data.length == 0)
                    return true;

                if (data.indexOf(false) != -1) {
                    return false;
                }

                return proceedGlobalRules(dryRun).then(result => {

                    return result.length == 0; //doesn't have errors
                });

            }).then(result => {

                updateFields();
                localData.current.isValidating = false;
                notifyFormStateChanged(result, notifyFormChange);

                return result;
            });
    }

    const updateFields = () => {

        //console.log('-----------------proceed updateFields childs');

        //if (localData.current.childsToUpdate == null) {
        //    console.log('-----------------proceed updateFields childs empty');
        //    return Promise.resolve();
        //}

        localData.current.childs
            .filter(x => x.current != null) //can be null if element not visible, for example
            .forEach(input => input.current.updateState());
    }

    const validateChild = (input, dryRun) => {

        return input.current?.validate(dryRun).then((result) => {
            return result.isValid;
        });
    }

    const getValidator = (validator, value) => {
        let result = true;
        if (typeof (validator) == 'function') {
            result = validator(value);
        }
        else {
            let name = validator;
            let extra;

            const splitIdx = validator.indexOf(':');
            if (splitIdx !== -1) {
                name = validator.substring(0, splitIdx);
                extra = validator.substring(splitIdx + 1);
            }

            result = rules[name](value, extra);

            //console.log('Validator name: ' + name + ' value: ' + value + ' result: ' + result);;
        }
        return result;
    }

    const notifyFormStateChanged = (isValid: boolean, forceToNotify = false) => {

        //console.log(`====notifyFormStateChanged, current: ${localData.current.isValid}, new: ${isValid}`);

        if (localData.current.isValid != isValid || forceToNotify) {

            localData.current.isValid = isValid;

            if (onFormStateChanged) {
                onFormStateChanged(isValid);
            }
        }
    }

    const onChildStateChanged = (/*child,*/ name, value, isValid, isStateChanged) => {

        if (isStateChanged) {

            //localData.current.childsToUpdate[name] = isValid;

            if (onFieldChanged) {
                onFieldChanged(name, value, isValid);
            }
        }

        if (localData.current.isValidating) {
            //validated childs will be updated at end of process
            return;
        }

        var childsState = localData.current.childs
            .filter(x => x.current != null)
            .map(input => { return { child: input, state: input.current.getState() } });
        var isChildsValid = childsState.every(x => x.state.isValid);


        if (isChildsValid) {
            //console.log("=====global rules call at child validation=====");

            proceedGlobalRules(false).then(result => {

                updateFields();

                var isValid = result.length == 0;
                notifyFormStateChanged(isValid);
            })
        }
        else {

            //console.log("!!!!!!!!!!!!!RESTORE GLOBAL VALIDATING RULES!!!!!!!!!!");

            var childs = localData.current.globalValidatedChilds;
            if (childs.size != 0) {
                childsState.forEach(item => {
                    if (name != item.state.name && childs.has(item.state.name)) {
                        validateChild(item.child, false);
                    }
                });
            }
            
            localData.current.globalValidatedChilds.clear();

            updateFields();
        }
    }

    const resetValidations = () => {
        localData.current.childs.forEach((child) => {
            child.current.cancelValidation();
            child.current.setState({ isValid: true });
        });
    }

    const isFormValid = (dryRun = true, notifyFormChange = false) => walk(localData.current.childs, dryRun, notifyFormChange);

    const context: FormContextType = {
        form: {
            attachToForm: attachToForm,
            detachFromForm: detachFromForm,
            onChildStateChanged: onChildStateChanged,
            instantValidate: localInstantValidate,
            debounceTime: localDebounceTime,
            getValidator: getValidator
        }
    };

    return (
        <FormContext.Provider value={context} /*ref={ref}*/>
            <form {...rest} onSubmit={submit} style={{ display: 'contents' }}>
                {children}
            </form>
        </FormContext.Provider>
    );
})

export default ValidatorForm;
export { FormContext };