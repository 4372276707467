import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, FormGroup } from "@mui/material";
//import "@Styles/pages/CreateNewCalculationPage.scss";
import CalculatorService from "../../../services/CalculatorService";
import CommonService from "../../../services/CommonService";

import { Spinner } from "@Components/shared/Spinner";
import * as CalculatorStore from "@Store/CalculatorStore";
import { withRouter } from "../../../components/shared/CustomRouter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Card from '@mui/material/Card';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { NestedMenuItem } from "mui-nested-menu";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from "highcharts/highcharts-more"; //module

import UI from "../../../UIHelper";
//import CalculationParameters from '@Pages/instruments/calculator/CalculationParameters';
import Localization from '@Components/Localization';
import { ValidatorForm, TextValidator, SelectValidator, DatePickerValidator, ComboboxValidator } from "@Framework/components/Form";
import BondSelectDialog from "@Components/shared/BondSelectDialog";
import CurvesParametersSelectDialog from "@Components/shared/CurvesParametersSelectDialog";
import ConfirmationDialog from "@Pages/ConfirmationDialog";
import NameDescriptionDialog from "@Pages/NameDescriptionDialog";
import NameDialog from "@Pages/NameDialog";
import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';

//import classes from '*.module.sass';
import style from '@Styles/domrf-theme.module.scss';
import { setHighchartsLocalization } from '@Utilities';
import HelpButton from '@Components/shared/HelpButton';
import { CalculationType } from '@Components/Definitions';
import Globals from "@Base/Globals";

//import 'react-grid-layout/css/styles.css';
//import 'react-resizable/css/styles.css';

//import { Responsive, WidthProvider } from "react-grid-layout";
//import /*type*/ { CompactType, Layout } from 'react-grid-layout';

import { withTranslation } from "react-i18next";

import { WidthProvider, Responsive } from "react-grid-layout";
//import { default as Responsive } from "@Framework/components/Layout/ResponsiveReactGridLayout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const ResponsiveReactGridLayout = WidthProvider(Responsive);

require("highcharts/modules/draggable-points")(Highcharts);

const styles = {

    root: {
        position: 'absolute',
        left: '15px',
        bottom: '15px',
        zIndex: '1100'
    },
    stickyHeader: {
        position: 'sticky',
        top: '0',
        zIndex: '1100',
        backgroundColor: '#FFFFFF'
    },
    infoText: {
        fontWeight: '400',
        fontStyle: 'italic',
        fontSize: '14px',
        color: '#2e454f',
        marginBottom: '0',

        '& a': {
            fontWeight: 'bold'
        }
    },
    switch: {
        marginLeft: 0,
        marginBottom: 0,
    },
    tableHeader: {
        fontSize: '14px',
        //fontWeight: '600',
        color: style.colorPrimary3
    },
    tableStyle: {
        tableLayout: 'fixed',

        '& th': {
            padding: "0"
        },
        '& td': {
            padding: "0"
        },
        '& input': {
            padding: '0',
            textAlign: "left",
            fontSize: '14px'
        }
    },
    hideButton: {
        position: 'absolute',
        cursor: 'pointer',
        fontSize: '20px',
        top: '0',
        right: '5px'
    },
    border: {
        border: '1px solid red'
    },
    hidden: {
        display: 'none'
    },
    tabs: {
        '& .MuiTab-root': {
            minWidth: '72px'
        }
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    infoLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flex: '1 1 auto'
    },
    flex: {
        display: 'flex'
    },
    checkbox: {
        marginRight: 0,
        marginBottom: 0
    },
    padding: {
        padding: '1rem',
        flex: '1 1 auto'
    },
    container: {
        display: 'flex',
    },
    buttonsGroups: {
        padding: '1rem 0'
    },
    button: {
        flex: '1 1 auto'
    }
};

class CreateCalculationPage extends React.PureComponent {

    constructor(props) {
        super(props);

        HC_more(Highcharts);
        setHighchartsLocalization(Highcharts, this.props.t);

        var savedLayout = Globals.state.createCalculationLayout;

        this.state = {
            loading: false,
            showBondSelectionDialog: false,
            showCurveParametersSelectionDialog: false,
            showConfirmationDialog: false,
            showCurveParametersSaveDialog: false,

            mounted: false,
            breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 320 },
            currentBreakpoint: savedLayout?.breakpoint, //"lg",
            compactType: "vertical",
            rowHeight: 30,
            cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },

            layouts: savedLayout?.layouts,
            /*
            layouts: {
                lg: [
                    { w: 5, h: 11, x: 0, y: 0, i: 'base', minW: 5, minH: 11 },
                    { w: 7, h: 15, x: 4, y: 0, i: 'common', minW: 6, minH: 15 },
                    { w: 6, h: 7, x: 0, y: 15, i: 'bond', minW: 6, minH: 7 },
                    { w: 6, h: 10, x: 5, y: 22, i: 'issue', minW: 6, minH: 10 },
                    { w: 6, h: 7, x: 5, y: 15, i: 'expenses', minW: 6, minH: 7 },
                    { w: 6, h: 12, x: 0, y: 22, i: 'calcParams', minW: 6, minH: 12 },
                    { w: 6, h: 7, x: 5, y: 32, i: 'basicRisk', minW: 6, minH: 7 },
                    { w: 6, h: 7, x: 0, y: 34, i: 'volatility', minW: 6, minH: 7 },
                    { w: 4, h: 4, x: 0, y: 11, i: 'extraParams', minW: 4, minH: 4 }
                ],
                md: [
                    { w: 4, h: 11, x: 0, y: 0, i: 'base', minW: 4, minH: 11 },
                    { w: 6, h: 15, x: 4, y: 0, i: 'common', minW: 6, minH: 15 },
                    { w: 5, h: 7, x: 0, y: 15, i: 'bond', minW: 5, minH: 7 },
                    { w: 5, h: 10, x: 5, y: 22, i: 'issue', minW: 5, minH: 10 },
                    { w: 5, h: 7, x: 5, y: 15, i: 'expenses', minW: 5, minH: 7 },
                    { w: 5, h: 12, x: 0, y: 22, i: 'calcParams', minW: 5, minH: 12 },
                    { w: 5, h: 7, x: 5, y: 32, i: 'basicRisk', minW: 5, minH: 7 },
                    { w: 5, h: 7, x: 0, y: 34, i: 'volatility', minW: 5, minH: 7 },
                    { w: 4, h: 4, x: 0, y: 11, i: 'extraParams', minW: 4, minH: 4 }
                ]
            },*/

            defaultLayouts: {
                lg: [
                    { w: 5, h: 11, x: 0, y: 0, i: 'base', /*minW: 5, minH: 11,*/ isDraggable: false, isResizable: false },
                    { w: 7, h: 15, x: 4, y: 0, i: 'common', /*minW: 6, minH: 15,*/ isDraggable: false, isResizable: false },
                    { w: 6, h: 7, x: 0, y: 15, i: 'bond', /*minW: 6, minH: 7,*/ isDraggable: false, isResizable: false },
                    { w: 6, h: 10, x: 5, y: 22, i: 'issue', /*minW: 6, minH: 10,*/ isDraggable: false, isResizable: false },
                    { w: 6, h: 7, x: 5, y: 15, i: 'expenses', /*minW: 6, minH: 7,*/ isDraggable: false, isResizable: false },
                    { w: 6, h: 10, x: 0, y: 22, i: 'calcParams', /*minW: 6, minH: 12,*/ isDraggable: false, isResizable: false },
                    { w: 6, h: 7, x: 5, y: 32, i: 'basicRisk', /*minW: 6, minH: 7,*/ isDraggable: false, isResizable: false },
                    { w: 6, h: 7, x: 0, y: 34, i: 'volatility', /*minW: 6, minH: 7,*/ isDraggable: false, isResizable: false },
                    { w: 4, h: 4, x: 0, y: 11, i: 'extraParams', /*minW: 4, minH: 4,*/ isDraggable: false, isResizable: false },
                    { w: 4, h: 4, x: 0, y: 36, i: 'bondParams', /*minW: 4, minH: 4,*/ isDraggable: false, isResizable: false }
                ],
                md: [
                    { w: 5, h: 11, x: 0, y: 0, i: 'base', /*minW: 4, minH: 11,*/ isDraggable: false, isResizable: false },
                    { w: 6, h: 15, x: 0, y: 11, i: 'common', /*minW: 6, minH: 15,*/ isDraggable: false, isResizable: false },
                    { w: 5, h: 8, x: 0, y: 26, i: 'bond', /*minW: 5, minH: 7,*/ isDraggable: false, isResizable: false },
                    { w: 4, h: 11, x: 6, y: 11, i: 'issue', /*minW: 5, minH: 10,*/ isDraggable: false, isResizable: false },
                    { w: 5, h: 8, x: 5, y: 34, i: 'expenses', /*minW: 5, minH: 7,*/ isDraggable: false, isResizable: false },
                    { w: 5, h: 10, x: 0, y: 34, i: 'calcParams', /*minW: 5, minH: 12,*/ isDraggable: false, isResizable: false },
                    { w: 5, h: 8, x: 5, y: 26, i: 'basicRisk', /*minW: 5, minH: 7,*/ isDraggable: false, isResizable: false },
                    { w: 5, h: 7, x: 5, y: 42, i: 'volatility', /*minW: 5, minH: 7,*/ isDraggable: false, isResizable: false },
                    { w: 4, h: 4, x: 6, y: 22, i: 'extraParams', /*minW: 4, minH: 4,*/ isDraggable: false, isResizable: false },
                    { w: 5, h: 11, x: 5, y: 0, i: 'bondParams', /*minW: 4, minH: 4,*/ isDraggable: false, isResizable: false }
                ]
            },

            //temporary!!
            loadedLayouts: [],

            /*
            menuLayouts: [
                { id: '101', name: 'Редактировать', isDisabled: false },
                { id: '102', name: 'Сохранить', isDisabled: true },
                { id: '103', name: 'Отменить', isDisabled: true }
            ],
            */

            isEditLayoutMode: false,
            layoutNameDialog: false,
            panels: [
                //{ id: 'base', name: this.props.t('Data'), visible: true },
                { id: 'common', name: this.props.t('ZCYC') },
                { id: 'bond', name: this.props.t('BondParameters') },
                { id: 'issue', name: this.props.t('IssueParameters') },
                { id: 'expenses', name: this.props.t('ExpensesParameters') },
                { id: 'calcParams', name: this.props.t('CalculationParameters') },
                { id: 'basicRisk', name: this.props.t('BasicRiskFunctions') },
                { id: 'volatility', name: this.props.t('VolatilityModification') },
                { id: 'extraParams', name: this.props.t('ExtraParameters') },
                { id: 'bondParams', name: this.props.t('BondParameters') }
            ],

            //bond: {},
            //if we edit calculation
            calculationId: this.props.router.params.id && parseInt(this.props.router.params.id, 10),
            calculationName: '',
            calculationDescription: '',
            bondId: '',
            bondName: '',
            calculationPurpose: 0,
            type: this.props.action,
            validateErrors: [],
            //updateFlag: false,
            anchorEl: null,
            layoutAnchorEl: null,

            zcycStresses: [],

            parameters: {
                evaluationDate: new Date().toISOString(),
                calculationType: CalculationType.TYPE_OAS,
                calculationValue: 130,
                actualLLDDate: new Date().toISOString(),
                zcycDate: new Date().toISOString(),
                //forwardCurveValues:null,
                //ZCYCValues: null,
                currentNominal: '',
                couponType: 1, //fixed
                couponPercentage: '',
                couponPeriod: 3,
                issueDate: null,

                firstCouponDate: null,
                startIssueAmount: '',
                cleanUpCall: '',
                nonRecurringExpenses: '',
                constantExpenses: '',
                //variableExpenses: '',
                numberOfMonteCarloScenarios: 5000,
                modelingHorizon: 20,
                numberOfEventSimulations: 1000,
                numberOfSyntheticLoans: 25,
                randomNumberSeed: '',
                sensitivityIRDelta: 100,
                sensitivityCPRDelta: 1.1,
                modelsOfDefaults: 1,
                earlyPaymentModels: 1,
                modelsOfAcceleratedDepreciation: 1,
                betModels: 1,
                mortgageRatesModels: 1,
                realEstateIndexModels: 1,
                taskArgs: ''
            },

            calculationOfEffectiveIndicators: true,
            calculationOfCPRSensitivity: true,

            bondStats: null,

            zcycChartOptions: {

                chart: {
                    zoomType: 'x'
                },

                exporting: { enabled: false },

                title: {
                    text: this.props.t('ChartsAndGCurveValues'),
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                credits: {
                    enabled: false
                },

                xAxis: {
                    //type: 'logarithmic',
                    title: this.props.t('Years')
                },

                yAxis: {
                    title: null
                    //min: 0
                },

                tooltip: {
                    headerFormat: '<b>{point.x:.2f}</b><br>',
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                    //crosshairs: true,
                    //shared: true,
                    positioner: function () {
                        return { x: 0, y: 0 };
                    }
                },

                series: [
                    {
                        name: this.props.t('ForwardCurve'),
                        visible: false,
                        //type: 'spline',
                        dashStyle: 'longdash',
                        data: null,
                        color: '#8BC540',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },
                        /*
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        */
                        zIndex: 1
                    },
                    {
                        name: this.props.t('GCurve'),
                        //type: 'spline',
                        data: null,
                        color: '#8BC540',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },
                        /*states: {
                            hover: {
                                enabled: false
                            }
                        },
                        */
                        zIndex: 2
                    },
                    {
                        name: this.props.t('StressedForwardCurve'),
                        //type: 'spline',
                        dashStyle: 'longdash',
                        data: null,
                        color: '#979ba2',
                        lineWidth: 1,
                        dragDrop: {
                            draggableY: true,
                            dragPrecisionY: 0.01
                        },
                        marker: {
                            enabled: true,
                            symbol: 'circle',
                            radius: 5
                        },
                        stickyTracking: true,
                        point: {
                            events: {
                                drop: function (e) {

                                    this.props.getZCYCUpdateData(this.state.parameters.zcycDate, e.target.series.data.map((item) => {
                                        return {
                                            year: item.x,
                                            value: item.y
                                            //no stress
                                        }
                                    }));
                                }.bind(this)
                            }
                        },
                        states: {
                            inactive: {
                                opacity: 1
                            }
                        },
                        zIndex: 10
                    },
                    {
                        name: this.props.t('StressedGCurve'),
                        //type: 'spline',
                        data: null,
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },
                        /*
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        */
                        zIndex: 3
                    }
                ]
            }
        };

        if (this.state.layouts == null) {
            this.state.layouts = {};
            Object.keys(this.state.defaultLayouts).forEach(key => {
                this.state.layouts[key] = this.state.defaultLayouts[key].map(panel => { return { ...panel } });
            });
        }
        if (this.state.currentBreakpoint == null)
            this.state.currentBreakpoint = 'lg';
    }

    calculationFormRef = null;
    chartRef = null;

    calculationTypeSource = [
        { key: 1, value: this.props.t('OAS') },
        { key: 2, value: this.props.t('Price') },
        { key: 3, value: this.props.t('ZSpread') },
        { key: 4, value: this.props.t('GSpread') }
    ];

    couponTypeSource = [
        { key: 1, value: this.props.t('FixedCoupon') },
        { key: 2, value: this.props.t('FloatCoupon') },
        { key: 3, value: this.props.t('AHMLCoupon') },
        { key: 4, value: this.props.t('ThirdCoupon') },
        { key: 5, value: this.props.t('LossesCoupon') }
    ];

    couponPeriodSource = [
        { key: 1, value: '1' },
        { key: 2, value: '3' },
        { key: 3, value: '6' },
    ];

    calculationPurposeSource = [
        { key: 0, value: this.props.t('Standard') },
        { key: 1, value: this.props.t('Statistical') },
        { key: 2, value: this.props.t('System') },
        { key: 3, value: this.props.t('Marketing') },
        { key: 4, value: this.props.t('EndOfDate') },
        { key: 5, value: this.props.t('Intradate') }
    ];

    componentDidMount() {

        this.props.init();

        this.props.loadLayouts(1); //1 - id of create calculation page

        if ((this.state.type === 'recalculate' || this.state.type === 'duplicate') && this.state.calculationId != null) {
            this.props.getCalculation(this.state.calculationId);
        }
        else
            this.props.getComplexZCYCC(this.state.parameters.zcycDate);

        if (this.state.layouts[this.state.currentBreakpoint] == null) {
            this.setState({ layouts: { ...this.state.defaultLayout }, mounted: true });
        }
        else
            this.setState({ mounted: true });
    }

    componentDidUpdate(prevProps, prevState) {

        var totalState = null;

        if (this.props.operationLoading != prevState.loading) {

            totalState = { loading: this.props.operationLoading };
            //this.setState({ loading: this.props.operationLoading });
        }

        if (this.props.loadedLayouts != prevProps.loadedLayouts && this.props.loadedLayouts != null) {

            var layouts = [...this.props.loadedLayouts];

            if (totalState) {
                totalState.loadedLayouts = layouts;
            }
            else
                totalState = { loadedLayouts: layouts };

            //this.setState({ loadedLayouts: layouts });
        }

        if (this.props.newLayout != prevProps.newLayout) {
            //add new layout to menu
            var layouts = [...this.state.loadedLayouts];
            layouts.push({ ...this.props.newLayout });

            if (totalState) {
                totalState.loadedLayouts = layouts;
            }
            else
                totalState = { loadedLayouts: layouts };

            //this.setState({ loadedLayouts: layouts });
        }

        if (this.props.parameters != null) {

            if (prevProps.parameters?.zcycDate != this.props.parameters.zcycDate) {

                if (this.state.calculationId != null) {

                    //fix nulls
                    this.props.parameters.randomNumberSeed = this.props.parameters.randomNumberSeed || '';
                    this.props.parameters.sensitivityIRDelta = this.props.parameters.sensitivityIRDelta || '';
                    this.props.parameters.sensitivityCPRDelta = this.props.parameters.sensitivityCPRDelta || '';

                    var calculationName = this.props.name;
                    if (this.state.type === 'duplicate')
                        calculationName += ' - ' + this.props.t('Duplicate_Noun');

                    if (totalState) {
                        totalState.loading = false;
                        totalState.calculationName = calculationName;
                        totalState.calculationDescription = this.props.description;
                        totalState.calculationPurpose = this.props.calculationPurpose;

                        totalState.zcycDate = this.props.parameters.zcycDate;
                        totalState.currentNominal = this.props.parameters.currentNominal || '';
                        totalState.bondName = this.props.bondName;
                        totalState.portfolioId = this.props.portfolioId;
                        totalState.bondId = this.props.bondId;

                        totalState.parameters = {
                            ...this.state.parameters,
                            ...this.props.parameters,
                            actualLLDDate: this.props.portfolioActualDate,
                        }
                    }
                    else {
                        totalState = {

                            loading: false,

                            calculationName: calculationName,
                            calculationDescription: this.props.description,
                            calculationPurpose: this.props.calculationPurpose,

                            zcycDate: this.props.parameters.zcycDate,
                            currentNominal: this.props.parameters.currentNominal,
                            bondName: this.props.bondName,
                            portfolioId: this.props.portfolioId,
                            bondId: this.props.bondId,

                            parameters: {
                                ...this.state.parameters,
                                ...this.props.parameters,
                                actualLLDDate: this.props.portfolioActualDate,
                            }
                        };
                    }

                    /*
                    this.setState({

                        loading: false,

                        calculationName: calculationName,
                        calculationDescription: this.props.description,
                        calculationPurpose: this.props.calculationPurpose,

                        zcycDate: this.props.parameters.zcycDate,
                        currentNominal: this.props.parameters.currentNominal,
                        bondName: this.props.bondName,
                        portfolioId: this.props.portfolioId,
                        bondId: this.props.bondId,

                        parameters: {
                            ...this.state.parameters,
                            ...this.props.parameters,
                            actualLLDDate: this.props.portfolioActualDate,
                        }
                    });
                    */
                }
            }
            if (this.state.zcycChartOptions.series[2].data != this.props.forward_curve_modified_chart_data) {

                var newZcycChart = { ...this.state.zcycChartOptions };
                newZcycChart.series[0].data = this.props.forward_curve_base_chart_data;
                newZcycChart.series[1].data = this.props.zcy_curve_base_chart_data;
                newZcycChart.series[2].data = this.props.forward_curve_modified_chart_data;
                newZcycChart.series[3].data = this.props.zcy_curve_modified_chart_data;
                var newZcycStresses = newZcycChart.series[2].data.map((item) => { return { x: item.x, y: Localization.FormatNumberStringISO(item.y) } })

                if (totalState) {
                    totalState.zcycChartOptions = newZcycChart;
                    totalState.zcycStresses = newZcycStresses;
                }
                else {
                    totalState = {
                        zcycChartOptions: newZcycChart,
                        zcycStresses: newZcycStresses
                    }
                }

                /*
                this.setState({
                    zcycChartOptions: newZcycChart,
                    zcycStresses: newZcycStresses
                });
                */
            }
            else if (prevState.parameters.zcycDate != this.state.parameters.zcycDate) {
                if (this.state.parameters.zcycDate.toString() != 'Invalid Date')
                    this.props.getComplexZCYCC(this.state.parameters.zcycDate);
            }
        }

        if (prevProps.bondStats != this.props.bondStats) {

            var value = this.props.bondStats;
            var bond = value.bond;

            var parameters = {
                ...this.state.parameters,
                actualLLDDate: value.portfolioActualDate,
                zcycDate: value.zcycDate,
                currentNominal: value.nominal,
            };

            var coupon = this.couponTypeSource.find(x => x.key == bond.couponType)?.value;

            var bondStats = [
                { name: this.props.t('IssueDate'), description: this.props.t('Parameters_IssueDate'), value: Localization.FormatDateStringLocale(bond.issueDate) || '–' },
                { name: this.props.t('NextCouponPaymentDate'), description: this.props.t('CouponDateDescription'), value: Localization.FormatDateStringLocale(value.nextCouponPaymentDate) || '–' },
                { name: this.props.t('CouponPeriod'), description: this.props.t('Parameters_CouponPeriod'), value: bond.couponPeriod },
                { name: this.props.t('StartIssueAmount'), description: this.props.t('Parameters_StartIssueAmount'), value: bond.startIssueAmount || '–' },
                { name: this.props.t('CurrentIssueAmount'), description: this.props.t('CurrentIssueAmount_Info'), value: value.currentIssueAmount || '–' },
                { name: this.props.t('CurrentNominalPrc'), description: this.props.t('NominalDescription'), value: value.nominal || '–' },
                { name: this.props.t('CouponType'), description: this.props.t('Parameters_CouponType'), value: coupon || '–' },
                { name: this.props.t('CurrentCouponRate'), description: this.props.t('RateDescription'), value: value.currentCouponRate || '–' },
                { name: this.props.t('CleanUpCall'), description: this.props.t('Parameters_CleanUpCall'), value: bond.cleanUpCall || '–' },
                { name: this.props.t('AverageCPR_Info'), description: this.props.t('CPRHist_Info'), value: value.historicalCPR || '–' },
                { name: this.props.t('AverageCDR_Info'), description: this.props.t('CDRHist_Info'), value: value.historicalCDR || '–' }
            ];

            this.copyBondParameters(parameters, bond);

            if (totalState) {

                totalState.bondName = bond.name;
                totalState.bondId = bond.id;
                totalState.portfolioId = value.portfolioId;
                totalState.parameters = parameters;
                totalState.bondStats = bondStats;
            }
            else {
                totalState = {
                    bondName: bond.name,
                    bondId: bond.id,
                    portfolioId: value.portfolioId,
                    parameters: parameters,
                    bondStats: bondStats
                };
            }
        }

        if (totalState)
            this.setState(totalState);
    }

    resetState() {

        this.setState({
            loading: false,
            showBondSelectionDialog: false,
            showConfirmationDialog: false,
            showCurveParametersSelectionDialog: false,
            showCurveParametersSaveDialog: false,

            calculationId: null,
            calculationName: '',
            calculationDescription: '',

            bondName: '',
            calculationPurpose: 0,

            showConfirmationDialog: false,
            validateErrors: [],
            //updateFlag: false,
            anchorEl: null,
            layoutAnchorEl: null,

            parameters: {
                evaluationDate: new Date().toISOString(),
                calculationType: CalculationType.TYPE_OAS,
                calculationValue: 130,
                actualLLDDate: new Date().toISOString(),
                zcycDate: new Date().toISOString(),
                //forwardCurveValues:null,
                //ZCYCValues: null,
                currentNominal: '',
                couponType: 1, //fixed
                couponPercentage: '',
                couponPeriod: 3,
                issueDate: null,

                firstCouponDate: null,
                startIssueAmount: '',
                cleanUpCall: '',
                nonRecurringExpenses: '',
                constantExpenses: '',
                //variableExpenses: '',
                numberOfMonteCarloScenarios: 5000,
                modelingHorizon: 20,
                numberOfEventSimulations: 1000,
                numberOfSyntheticLoans: 25,
                randomNumberSeed: '',
                sensitivityIRDelta: '',
                sensitivityCPRDelta: '',
                modelsOfDefaults: 1,
                earlyPaymentModels: 1,
                modelsOfAcceleratedDepreciation: 1,
                betModels: 1,
                mortgageRatesModels: 1,
                realEstateIndexModels: 1,
                taskArgs: ''
            }
        });
    }

    handleTopInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleInputChange = (event) => {
        this.setState({ parameters: { ...this.state.parameters, [event.target.name]: event.target.value } });
    }

    handleCheckChange = (event) => {
        this.setState({ parameters: { ...this.state.parameters, [event.target.name]: event.target.checked } });
    }

    handleDateChange = (name, date) => {
        this.setState({ parameters: { ...this.state.parameters, [name]: date } });
    }

    handleFakeCheckChange = (event) => {

        if (event.target.name == 'calculationOfEffectiveIndicators') {

            var value = '';
            if (event.target.checked)
                value = '100';

            this.setState({ [event.target.name]: event.target.checked, parameters: { ...this.state.parameters, sensitivityIRDelta: value } });
        }
        else if (event.target.name == 'calculationOfCPRSensitivity') {

            var value = '';
            if (event.target.checked)
                value = '1.1';

            this.setState({ [event.target.name]: event.target.checked, parameters: { ...this.state.parameters, sensitivityCPRDelta: value } });
        }
    }

    handleZCYCDateChange = (name, event) => {
        this.handleDateChange(name, event);
        //if (event.toString() != 'Invalid Date')
        //this.props.getComplexZCYCC(event, false);
    }

    handleZCYCChange = (event, index) => {
        var newZcycStresses = this.state.zcycStresses.slice();
        newZcycStresses[index].y = event.target.value;

        this.setState({ zcycStresses: newZcycStresses });
    }

    handleZCYCBlur = (event, index) => {
        var data = this.state.zcycStresses[index].y;

        if (data == null || data == '') {

            var newZcycStress = this.state.zcycStresses.slice();
            newZcycStress[index].y = this.state.zcycChartOptions.series[2].data[index].y;
            this.setState({ zcycStresses: newZcycStress });
            return;
        }


        if ((Math.round(this.state.zcycChartOptions.series[2].data[index].y * 100) / 100) == parseFloat(data))
            return;

        /*
        var newZcycChart = { ...this.state.zcycChartOptions };

        newZcycChart.series[2].data[index].y = parseFloat(data);

        this.setState({ zcycChartOptions: newZcycChart });
        */

        var newData = this.state.zcycChartOptions.series[2].data.slice();
        newData[index].y = parseFloat(data);

        this.props.getZCYCUpdateData(this.state.parameters.zcycDate, newData.map((item) => {
            return {
                year: item.x,
                value: item.y
                //no stress
            }
        }));
    }

    //update portfolio
    handleEvaluationDateChange = (name, date) => {

        this.handleDateChange(name, date);

        if ((this.state.bondId || '') == '') {
            return;
        }

        this.props.getBondInformation(this.state.bondId, date.toISOString());
    }


    startCalculation = () => {
        this.calculationFormRef.submit();
    }

    handleConfirmationDialogClose = (event, isApply) => {
        this.setState({ showConfirmationDialog: false });

        if (isApply == false)
            return;

        (async () => {

            var result = null;

            var data = {
                calculationId: this.state.calculationId,
                name: this.state.calculationName,
                description: this.state.calculationDescription,
                portfolioId: this.state.portfolioId,
                bondId: this.state.bondId,
                calculationPurpose: this.state.calculationPurpose,
                parameters: this.state.parameters
            };

            var stresses = [];
            for (var i = 0; i < this.props.forward_curve_modified_chart_data.length; i++) {
                var point = this.props.forward_curve_base_chart_data.find(x => x[0] == this.props.forward_curve_modified_chart_data[i].x);
                if (point != null)
                    stresses.push({ year: this.props.forward_curve_modified_chart_data[i].x, stress: Math.round((this.props.forward_curve_modified_chart_data[i].y - point[1] + 0.0005) * 100) / 100 });
            }

            if (!stresses.every((item) => item.stress == 0))
                data.parameters.forwardCurveValues = stresses;

            this.setState({ loading: true });

            switch (this.state.type) {
                case 'recalculate':
                    {
                        result = await CalculatorService.recalculateCalculation(data);
                    }
                    break;
                default:
                    result = await CalculatorService.createCalculation(data);
            }

            this.setState({ loading: false });

            if (result.hasErrors) {
                return;
            }

            UI.showSuccess(this.props.t('CalculationCreated'));

            this.resetState();
        })();
    }

    handleConfirmRender = () => {
        return (
            <div>
                {
                    (this.state.validateErrors.length != 0) && (
                        <div>
                            <h4 className="color-error">
                                {this.props.t('Attention')}
                            </h4>
                            <ul>
                                {
                                    this.state.validateErrors.map((item, index) => {
                                        return <li key={"error-" + index}>{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                    )
                }
                {this.props.t('CreateCalculationQuestion')}
            </div >
        )
    }

    handleCalculationSubmit = (event) => {

        var evalDate = new Date(this.state.parameters.evaluationDate);
        var lldDate = new Date(this.state.parameters.actualLLDDate);

        var errors = [];

        var futureLLDDate = new Date(lldDate);
        futureLLDDate.setMonth(lldDate.getMonth() + 2);

        if (lldDate > evalDate) {
            errors.push(this.props.t('ActualLLDDateBiggerThanCalculationDate_Info'));
        }

        if (futureLLDDate < evalDate) {
            errors.push(this.props.t('ActualLLDDateLesser2Month_Info'));
        }

        this.setState({ showConfirmationDialog: true, validateErrors: errors });
    }

    handleCurveDialogClose = () => {
        this.setState({ showCurveParametersSelectionDialog: false });
    }

    handleCurveSelection = (event, selected) => {
        if (selected == null || selected.length == 0)
            return;

        this.props.getCurveData(selected[0], this.state.parameters.zcycDate);
    }

    handleClickBondSearch = () => {

        this.setState({ showBondSelectionDialog: true });
    }

    handleBondDialogClose = () => {
        this.setState({ showBondSelectionDialog: false });
    }

    handleBondSelection = (event, selected) => {

        if (selected == null || selected.length == 0)
            return;

        this.props.getBondInformation(selected[0], this.state.parameters.evaluationDate);
    }

    copyBondParameters = (parameters, bond) => {

        if (bond.couponType != null)
            parameters.couponType = bond.couponType;

        if (bond.couponPercentage != null)
            parameters.couponPercentage = bond.couponPercentage;

        if (bond.couponPeriod != null)
            parameters.couponPeriod = bond.couponPeriod;

        if (bond.issueDate != null)
            parameters.issueDate = bond.issueDate;

        if (bond.firstCouponDate != null)
            parameters.firstCouponDate = bond.firstCouponDate;

        if (bond.startIssueAmount != null)
            parameters.startIssueAmount = bond.startIssueAmount;

        if (bond.cleanUpCall != null)
            parameters.cleanUpCall = bond.cleanUpCall;

        if (bond.nonRecurringExpenses != null)
            parameters.nonRecurringExpenses = bond.nonRecurringExpenses;

        if (bond.constantExpenses != null)
            parameters.constantExpenses = bond.constantExpenses;

        //if (bond.variableExpenses != null)
        //parameters.variableExpenses = bond.variableExpenses;
    }

    onExtraButtonClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    onZCYCMenuClose = (event) => {
        this.setState({ anchorEl: null });
    }

    onZCYCReset = async (event) => {

        this.setState({ anchorEl: null });

        this.props.getComplexZCYCC(this.state.parameters.zcycDate, true);
    }

    onZCYCLoad = (event) => {

        this.setState({ anchorEl: null });
        this.setState({ showCurveParametersSelectionDialog: true });
    }

    onZCYCSave = (event) => {

        this.setState({ anchorEl: null });
        this.setState({ showCurveParametersSaveDialog: true });
    }

    handleSaveCurveDialogClose = (event, data) => {
        if (data != null) {

            //save data
            data.values = [];

            for (var i = 0; i < this.state.forward_curve_modified_chart_data.length; i++) {
                var point = this.state.forward_curve_base_chart_data.find(x => x[0] == this.state.forward_curve_modified_chart_data[i].x);
                if (point != null)
                    data.values.push({ year: this.state.forward_curve_modified_chart_data[i].x, value: Math.round((this.state.forward_curve_modified_chart_data[i].y - point[1] + 0.0005) * 100) / 100 });
            }

            this.props.saveZCYCValues(data)
        }

        this.setState({ showCurveParametersSaveDialog: false });
    }

    handleLayoutMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ layoutAnchorEl: event.currentTarget });
    };

    handleLayoutMenuClose = () => {
        this.setState({ layoutAnchorEl: null });
    };

    handleLayoutMenuItemClick = (event: React.MouseEvent<HTMLElement>, id) => {

        if (id == '101') {

            var layouts = {};
            Object.keys(this.state.layouts).forEach(key => {
                layouts[key] = this.state.layouts[key].map(panel => { return { ...panel, isResizable: true, isDraggable: true } });
            });

            this.setState({ isEditLayoutMode: true, layouts: layouts });
        }
        else if (id == '102' || id == '103') {

            var layouts = {};
            Object.keys(this.state.layouts).forEach(key => {
                layouts[key] = this.state.layouts[key].map(panel => { return { ...panel, isResizable: false, isDraggable: false } });
            });

            if (id == '102') {
                //save current layout data
                this.setState({ layoutNameDialog: true });
            }

            this.setState({ isEditLayoutMode: false, layouts: layouts });
            this.handleLayoutMenuClose();
        }
        else {
            //load layout
            var layout = this.state.loadedLayouts.find((item) => item.id == id);
            if (layout) {
                this.setState({ layouts: JSON.parse(layout.pageLayout) });
                this.handleLayoutMenuClose();
            }
        }
    }

    handlePanelMenuItemClick = (event: React.MouseEvent<HTMLElement>, id: any) => {

        var currentItem = this.state.layouts[this.state.currentBreakpoint].find(item => item.i == id);

        if (currentItem == null) //add
        {
            currentItem = this.state.defaultLayouts[this.state.currentBreakpoint].find(item => item.i == id);

            this.setState(prevState => ({
                layouts: {
                    ...prevState.layouts,
                    [prevState.currentBreakpoint]: [
                        ...prevState.layouts[prevState.currentBreakpoint],
                        { ...currentItem, isResizable: true, isDraggable: true }
                    ]
                }
            }));
        }
        else {

            this.setState(prevState => {
                return {
                    layouts: {
                        ...prevState.layouts,
                        [prevState.currentBreakpoint]: prevState.layouts[
                            prevState.currentBreakpoint
                        ].filter(({ i }) => i !== id)
                    }
                };
            });
        }
    }

    handlePanelResetMenuItemClick = (event: React.MouseEvent<HTMLElement>, id: any) => {

        var layouts = {};
        Object.keys(this.state.defaultLayouts).forEach(key => {
            layouts[key] = this.state.defaultLayouts[key].map(panel => { return { ...panel } });
        });

        this.setState({ layouts: layouts });
    }

    handleTypeTabChange = (event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
        this.setState({ parameters: { ...this.state.parameters, calculationType: newValue } });
    }

    handleLayoutDeleteMenuClick = (event, id) => {
        event.preventDefault();
        event.stopPropagation();

        this.props.removeLayout(id);

        var newLayout = [...this.state.loadedLayouts];

        newLayout = newLayout.filter((item) => item.id != id);

        //update menu
        this.setState({ loadedLayouts: newLayout });
    }

    handleLayoutNameDialogClose = (event, data) => {

        if (data != null) {

            //save data
            //layout to save
            var layoutData = {
                name: data.name,
                pageLayout: JSON.stringify(this.state.layouts),
                location: 1
            };

            this.props.saveLayout(layoutData);
        }

        this.setState({ layoutNameDialog: false });
    }

    onHideItem = (event) => {

        var id = event.target.id;
        var currentItem = this.state.layouts[this.state.currentBreakpoint].find(item => item.i == id);
        if (currentItem == null)
            return; //already removed

        this.setState(prevState => {
            return {
                layouts: {
                    ...prevState.layouts,
                    [prevState.currentBreakpoint]: prevState.layouts[
                        prevState.currentBreakpoint
                    ].filter(({ i }) => i !== id)
                }
            };
        });
    }

    onBreakpointChange = breakpoint => {

        this.setState({ currentBreakpoint: breakpoint });
    };

    onLayoutChange = (layout, layouts) => {

        //we don't save "edit" state

        var layoutsToSave = {};
        Object.keys(layouts).forEach(key => {
            layoutsToSave[key] = layouts[key].map(panel => { return { ...panel, isResizable: false, isDraggable: false } });
        });

        Globals.setState('createCalculationLayout', { layouts: layoutsToSave, breakpoint: this.state.currentBreakpoint });

        this.setState({ layouts: layouts });
    };

    /*define all panels */
    basePanel = () => {
        return (
            <Box key='base' className={this.props.classes.container}>
                <Paper classes={{ root: this.props.classes.padding }}>

                    <div>
                        <h5>{this.props.t('Data')}</h5>
                    </div>

                    <div>
                        <FormGroup>
                            <TextValidator
                                type="text"
                                label={this.props.t('Bond')}
                                value={this.state.bondName}
                                name="bond"
                                id="bond"
                                tooltip={this.props.t('CalculationBond_Info')}
                                fullWidth={true}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <IconButton
                                                aria-label="search"
                                                onClick={this.handleClickBondSearch}
                                                edge="end"
                                                size="large">
                                                {<SearchIcon />}
                                            </IconButton>
                                        </InputAdornment>)
                                }}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Grid container>
                                <Grid className="pe-0">
                                    <DatePickerValidator
                                        label={this.props.t('ActualZCYCDate')}
                                        name="zcycDate"
                                        id="zcycDate"
                                        tooltip={this.props.t('Parameters_ZCYCDate')}
                                        locale={Localization.locale}
                                        fullWidth={true}
                                        mask={Localization.DateMask()}
                                        value={this.state.parameters.zcycDate}
                                        format={Localization.ShortDatePattern()}
                                        onChange={(e) => this.handleZCYCDateChange("zcycDate", e)}
                                        validators={['required', 'invalidDate']}
                                        errorMessages={[this.props.t('FieldRequired'), this.props.t('InvalidDate')]} />
                                </Grid>
                                <Grid className="ps-0">
                                    <DatePickerValidator
                                        label={this.props.t('EvaluationDate')}
                                        name="evaluationDate"
                                        id="evaluationDate"
                                        tooltip={this.props.t('Parameters_EvaluationDate')}
                                        locale={Localization.locale}
                                        fullWidth={true}
                                        value={this.state.parameters.evaluationDate}
                                        mask={Localization.DateMask()}
                                        format={Localization.ShortDatePattern()}
                                        onChange={(e) => this.handleEvaluationDateChange("evaluationDate", e)}
                                        validators={['required', 'invalidDate']}
                                        errorMessages={[this.props.t('FieldRequired'), this.props.t('InvalidDate')]} />
                                </Grid>
                            </Grid>
                        </FormGroup>

                        <FormGroup>
                            <ToggleButtonGroup
                                className={this.props.classes.buttonsGroups}
                                value={this.state.parameters.calculationType}
                                exclusive
                                onChange={this.handleTypeTabChange}
                            >
                                {
                                    (this.calculationTypeSource || []).map(option => (
                                        <ToggleButton key={option.key} value={option.key} className={this.props.classes.button}>
                                            {option.value}
                                        </ToggleButton>))
                                }
                            </ToggleButtonGroup>

                            {/*
                                <Tabs
                                    className={this.props.classes.tabs}
                                    value={this.state.parameters.calculationType}
                                    name="calculationType"
                                    id="calculationType"
                                    onChange={this.handleTypeTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    {
                                        (this.calculationTypeSource || []).map(option => (
                                            <Tab key={option.key} value={option.key} label={option.value} />))
                                    }
                                </Tabs>
                                */}
                            <div
                                role="tabpanel"
                                //hidden={this.state.parameters.calculationType !== 1}
                                hidden={false}
                                id='wrapped-tabpanel-1'
                                aria-labelledby='wrapped-tab-1'
                            >
                                <div className="pt-3">
                                    <TextValidator
                                        type="number"
                                        label={this.props.t('Value')}
                                        name="calculationValue"
                                        id="calculationValue"
                                        tooltip={this.props.t('Parameters_CalculationType')}
                                        fullWidth={true}
                                        onChange={this.handleInputChange}
                                        value={this.state.parameters.calculationValue}
                                        validators={['required']}
                                        errorMessages={[this.props.t('FieldRequired')]} />
                                </div>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <TextValidator
                                type="text"
                                label={this.props.t('Notes')}
                                name="calculationName"
                                id="calculationName"
                                tooltip={this.props.t('Parameters_Notes')}
                                fullWidth={true}
                                onChange={this.handleTopInputChange}
                                value={this.state.calculationName} />
                        </FormGroup>
                    </div>
                </Paper>
            </Box>
        );
    };

    commonPanel = () => {
        return (
            <Box key='common' className={this.props.classes.container}>
                <Paper classes={{ root: this.props.classes.padding }}>
                    <div id='common' className={clsx(this.props.classes.hideButton, this.state.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.onHideItem}>&times;</div>

                    <div>
                        <h5>{this.props.t('ZCYC')}</h5>
                    </div>

                    <div>

                        <FormGroup>
                            <HighchartsReact
                                ref={x => this.chartRef = x}
                                highcharts={Highcharts}
                                options={this.state.zcycChartOptions}
                            />
                        </FormGroup>
                        <FormGroup>
                            {
                                this.state.zcycStresses.length != 0 && (
                                    <Table aria-label="zcyc table" className={this.props.classes.tableStyle}>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    this.state.zcycStresses.map((item, index) => {
                                                        return (
                                                            <TableCell key={"zcyc-x-" + index} className={this.props.classes.tableHeader}>
                                                                {Localization.FormatNumberString(item.x)}
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {
                                                    this.state.zcycStresses.map((item, index) => {
                                                        return (
                                                            <TableCell key={"zcyc-y-" + index}>
                                                                <InputBase type="number" value={item.y} onBlur={(event) => this.handleZCYCBlur(event, index)} onChange={(event) => this.handleZCYCChange(event, index)} />
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                )}
                        </FormGroup>
                        <FormGroup>
                            <Grid container>
                                <Grid md={9}>
                                    <p className={this.props.classes.infoText}>
                                        {this.props.t('LearnAbountZCYC')}
                                        <a href="https://www.moex.com/ru/marketdata/indices/state/g-curve/">{' ' + this.props.t('here')}</a>
                                    </p>
                                </Grid>
                                <Grid md={3} className="text-right">
                                    <Button aria-controls="extra-menu" aria-haspopup="true" variant="text" color="primary" onClick={this.onExtraButtonClick}>
                                        {this.props.t('Extra')}
                                    </Button>

                                    <Menu
                                        id="extra-menu"
                                        anchorEl={this.state.anchorEl}
                                        keepMounted
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={this.onZCYCMenuClose}>

                                        <MenuItem onClick={this.onZCYCReset}>{this.props.t('Reset')}</MenuItem>
                                        <MenuItem onClick={this.onZCYCLoad}>{this.props.t('Load')}</MenuItem>
                                        <MenuItem onClick={this.onZCYCSave}>{this.props.t('Save')}</MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </FormGroup>

                    </div>
                </Paper></Box>)
    };

    bondPanel = () => {
        return (
            <Box key='bond' className={this.props.classes.container}>
                <Paper classes={{ root: this.props.classes.padding }}>

                    <div id='bond' className={clsx(this.props.classes.hideButton, this.state.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.onHideItem}>&times;</div>

                    <div>
                        <h5>{this.props.t('BondParameters')}</h5>
                    </div>

                    <div>
                        <FormGroup>
                            <TextValidator
                                type="number"
                                label={this.props.t('CurrentNominal_Info')}
                                name="currentNominal"
                                id="currentNominal"
                                tooltip={this.props.t('Parameters_CurrentNominal')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                value={this.state.parameters.currentNominal}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]} />
                        </FormGroup>
                        <FormGroup>
                            <SelectValidator
                                label={this.props.t('CouponType')}
                                name="couponType"
                                id="couponType"
                                tooltip={this.props.t('Parameters_CouponType')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                value={this.state.parameters.couponType}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]}>
                                {
                                    (this.couponTypeSource || []).map(option => (
                                        <MenuItem key={option.key} value={option.key}>
                                            {option.value}
                                        </MenuItem>))
                                }
                            </SelectValidator>
                        </FormGroup>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                label={this.props.t('CouponPercentage')}
                                value={this.state.parameters.couponPercentage}
                                name="couponPercentage"
                                id="couponPercentage"
                                tooltip={this.props.t('Parameters_CouponPercentage')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]}
                                inputProps={{ step: '0.05' }} />
                        </FormGroup>
                    </div>
                </Paper></Box>)
    };

    issuePanel = () => {
        return (
            <Box key='issue' className={this.props.classes.container}>
                <Paper classes={{ root: this.props.classes.padding }}>

                    <div id='issue' className={clsx(this.props.classes.hideButton, this.state.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.onHideItem}>&times;</div>

                    <div>
                        <h5>{this.props.t('IssueParameters')}</h5>
                    </div>

                    <div>
                        <FormGroup>
                            <SelectValidator
                                label={this.props.t('CouponPeriod')}
                                name="couponPeriod"
                                id="couponPeriod"
                                tooltip={this.props.t('Parameters_CouponPeriod')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                value={this.state.parameters.couponPeriod}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]}>
                                {
                                    (this.couponPeriodSource || []).map(option => (
                                        <MenuItem key={option.key} value={option.value}>
                                            {option.value}
                                        </MenuItem>))
                                }
                            </SelectValidator>
                        </FormGroup>

                        <FormGroup>
                            <DatePickerValidator
                                label={this.props.t('IssueDate')}
                                name="issueDate"
                                id="issueDate"
                                tooltip={this.props.t('Parameters_IssueDate')}
                                locale={Localization.locale}
                                fullWidth={true}
                                value={this.state.parameters.issueDate}
                                mask={Localization.DateMask()}
                                format={Localization.ShortDatePattern()}
                                onChange={(e) => this.handleDateChange("issueDate", e)}
                                validators={['required', 'invalidDate']}
                                errorMessages={[this.props.t('FieldRequired'), this.props.t('InvalidDate')]} />

                        </FormGroup>

                        <FormGroup>
                            <DatePickerValidator
                                label={this.props.t('FirstCouponDate')}
                                name="firstCouponDate"
                                id="firstCouponDate"
                                tooltip={this.props.t('Parameters_FirstCouponDate')}
                                locale={Localization.locale}
                                fullWidth={true}
                                value={this.state.parameters.firstCouponDate}
                                mask={Localization.DateMask()}
                                format={Localization.ShortDatePattern()}
                                onChange={(e) => this.handleDateChange("firstCouponDate", e)}
                                validators={['required', 'invalidDate']}
                                errorMessages={[this.props.t('FieldRequired'), this.props.t('InvalidDate')]} />
                        </FormGroup>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                label={this.props.t('StartIssueAmount')}
                                value={this.state.parameters.startIssueAmount}
                                name="startIssueAmount"
                                id="startIssueAmount"
                                tooltip={this.props.t('Parameters_StartIssueAmount')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]} />
                        </FormGroup>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                label={this.props.t('CleanUpCall')}
                                value={this.state.parameters.cleanUpCall}
                                name="cleanUpCall"
                                id="cleanUpCall"
                                tooltip={this.props.t('Parameters_CleanUpCall')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]} />
                        </FormGroup>

                    </div>
                </Paper></Box>)
    };

    expensesPanel = () => {
        return (
            <Box key='expenses' className={this.props.classes.container}>
                <Paper classes={{ root: this.props.classes.padding }}>

                    <div id='expenses' className={clsx(this.props.classes.hideButton, this.state.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.onHideItem}>&times;</div>

                    <div>
                        <h5>{this.props.t('ExpensesParameters')}</h5>
                    </div>

                    <div>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                label={this.props.t('NonRecurringExpenses')}
                                name="nonRecurringExpenses"
                                id="nonRecurringExpenses"
                                tooltip={this.props.t('Parameters_NonRecurringExpenses')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                value={this.state.parameters.nonRecurringExpenses}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]} />
                        </FormGroup>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                id="constantExpenses"
                                name="constantExpenses"
                                label={this.props.t('ConstantExpensesWithUnits')}
                                value={this.state.parameters.constantExpenses}
                                tooltip={this.props.t('Parameters_ConstantExpenses')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]} />
                        </FormGroup>
                        {/*
                    <FormGroup>
                        <TextValidator
                            type="number"
                            id="variableExpenses"
                            name="variableExpenses"
                            label={this.props.t('VariableExpenses')}
                            value={this.state.parameters.variableExpenses}
                            tooltip={this.props.t('Parameters_VariableExpenses')}
                            fullWidth={true}
                            onChange={this.handleInputChange}
                            validators={['required']}
                            errorMessages={[this.props.t('FieldRequired')]} />
                    </FormGroup>
                    */}
                    </div>
                </Paper></Box>)
    };

    calcParamsPanel = () => {
        return (
            <Box key='calcParams' className={this.props.classes.container}>
                <Paper classes={{ root: this.props.classes.padding }}>

                    <div id='calcParams' className={clsx(this.props.classes.hideButton, this.state.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.onHideItem}>&times;</div>

                    <div>
                        <h5>{this.props.t('CalculationParameters')}</h5>
                    </div>

                    <div>
                        <FormGroup>
                            <Grid container>
                                <Grid className='pe-0'>
                                    <TextValidator
                                        type="number"
                                        label={this.props.t('NumberOfMonteCarloScenarios')}
                                        name="numberOfMonteCarloScenarios"
                                        id="numberOfMonteCarloScenarios"
                                        tooltip={this.props.t('Parameters_NumberOfMonteCarloScenarios')}
                                        fullWidth={true}
                                        onChange={this.handleInputChange}
                                        value={this.state.parameters.numberOfMonteCarloScenarios}
                                        validators={['required']}
                                        errorMessages={[this.props.t('FieldRequired')]} />
                                </Grid>
                                <Grid className='ps-0'>
                                    <TextValidator
                                        type="number"
                                        id="numberOfSyntheticLoans"
                                        name="numberOfSyntheticLoans"
                                        label={this.props.t('NumberOfSyntheticLoans')}
                                        value={this.state.parameters.numberOfSyntheticLoans}
                                        tooltip={this.props.t('Parameters_NumberOfSyntheticLoans')}
                                        fullWidth={true}
                                        onChange={this.handleInputChange}
                                        validators={['required']}
                                        errorMessages={[this.props.t('ValueRequired')]} />
                                </Grid>
                            </Grid>
                        </FormGroup>

                        <FormGroup>
                            <Grid container>
                                <Grid className='pe-0'>
                                    <TextValidator
                                        type="number"
                                        id="numberOfEventSimulations"
                                        name="numberOfEventSimulations"
                                        label={this.props.t('NumberOfEventSimulations')}
                                        value={this.state.parameters.numberOfEventSimulations}
                                        tooltip={this.props.t('Parameters_NumberOfEventSimulations')}
                                        fullWidth={true}
                                        onChange={this.handleInputChange}
                                        validators={['required']}
                                        errorMessages={[this.props.t('FieldRequired')]} />
                                </Grid>
                                <Grid className='ps-0'>
                                    <TextValidator
                                        type="number"
                                        id="randomNumberSeed"
                                        name="randomNumberSeed"
                                        label={this.props.t('RandomNumberSeed')}
                                        value={this.state.parameters.randomNumberSeed}
                                        tooltip={this.props.t('Parameters_RandomNumberSeed')}
                                        fullWidth={true}
                                        onChange={this.handleInputChange} />
                                </Grid>
                            </Grid>
                        </FormGroup>

                        <FormGroup>

                            <TextValidator
                                type="number"
                                id="modelingHorizon"
                                name="modelingHorizon"
                                label={this.props.t('ModelingHorizon')}
                                value={this.state.parameters.modelingHorizon}
                                tooltip={this.props.t('Parameters_ModelingHorizon')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]} />

                        </FormGroup>

                        <FormGroup>
                            <Grid container>
                                <Grid md={10}>
                                    <TooltipInfoLabel className={this.props.classes.flex} content={this.props.t('Parameters_SensitivityIRDelta')}>
                                        <FormControlLabel
                                            className={this.props.classes.checkbox}
                                            control={
                                                <Checkbox
                                                    id="calculationOfEffectiveIndicators"
                                                    name="calculationOfEffectiveIndicators"
                                                    checked={this.state.calculationOfEffectiveIndicators}
                                                    onChange={this.handleFakeCheckChange}
                                                    color="primary"
                                                />
                                            }
                                            label={this.props.t('CalculationOfEffectiveIndicators')}
                                        />
                                    </TooltipInfoLabel>
                                </Grid>
                                <Grid md={2}>
                                    <TextValidator
                                        type="number"
                                        id="sensitivityIRDelta"
                                        name="sensitivityIRDelta"
                                        value={this.state.parameters.sensitivityIRDelta}
                                        fullWidth={true}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            readOnly: !this.state.calculationOfEffectiveIndicators
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid md={10}>
                                    <TooltipInfoLabel className={this.props.classes.flex} content={this.props.t('Parameters_SensitivityCPRDelta')}>
                                        <FormControlLabel
                                            className={this.props.classes.checkbox}
                                            control={
                                                <Checkbox
                                                    id="calculationOfCPRSensitivity"
                                                    name="calculationOfCPRSensitivity"
                                                    checked={this.state.calculationOfCPRSensitivity}
                                                    onChange={this.handleFakeCheckChange}
                                                    color="primary"
                                                />
                                            }
                                            label={this.props.t('CalculationOfCPRSensitivity')}
                                        />
                                    </TooltipInfoLabel>
                                </Grid>
                                <Grid md={2}>
                                    <TextValidator
                                        type="number"
                                        id="sensitivityCPRDelta"
                                        name="sensitivityCPRDelta"
                                        value={this.state.parameters.sensitivityCPRDelta}
                                        fullWidth={true}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            readOnly: !this.state.calculationOfCPRSensitivity
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </FormGroup>
                    </div>
                </Paper></Box>)
    };

    basicRiskPanel = () => {
        return (
            <Box key='basicRisk' className={this.props.classes.container}>
                <Paper classes={{ root: this.props.classes.padding }}>

                    <div id='basicRisk' className={clsx(this.props.classes.hideButton, this.state.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.onHideItem}>&times;</div>

                    <div>
                        <h5>{this.props.t('BasicRiskFunctions')}</h5>
                    </div>

                    <div>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                id="modelsOfDefaults"
                                name="modelsOfDefaults"
                                label={this.props.t('ModelsOfDefaults')}
                                value={this.state.parameters.modelsOfDefaults}
                                tooltip={this.props.t('Parameters_ModelsOfDefaults')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]}
                                inputProps={{ step: '0.1' }} />
                        </FormGroup>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                id="earlyPaymentModels"
                                name="earlyPaymentModels"
                                label={this.props.t('EarlyPaymentModels')}
                                value={this.state.parameters.earlyPaymentModels}
                                tooltip={this.props.t('Parameters_EarlyPaymentModels')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]}
                                inputProps={{ step: '0.1' }} />
                        </FormGroup>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                id="modelsOfAcceleratedDepreciation"
                                name="modelsOfAcceleratedDepreciation"
                                label={this.props.t('ModelsOfAcceleratedDepreciation')}
                                value={this.state.parameters.modelsOfAcceleratedDepreciation}
                                tooltip={this.props.t('Parameters_ModelsOfAcceleratedDepreciation')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]}
                                inputProps={{ step: '0.1' }} />
                        </FormGroup>
                    </div>
                </Paper></Box>)
    };

    volatilityPanel = () => {
        return (
            <Box key='volatility' className={this.props.classes.container}>
                <Paper classes={{ root: this.props.classes.padding }}>

                    <div id='volatility' className={clsx(this.props.classes.hideButton, this.state.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.onHideItem}>&times;</div>

                    <div>
                        <h5>{this.props.t('VolatilityModification')}</h5>
                    </div>

                    <div>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                id="betModels"
                                name="betModels"
                                label={this.props.t('BetModels')}
                                value={this.state.parameters.betModels}
                                tooltip={this.props.t('Parameters_BetModels')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]}
                                inputProps={{ step: '0.1' }} />
                        </FormGroup>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                id="mortgageRatesModels"
                                name="mortgageRatesModels"
                                label={this.props.t('MortgageRatesModels')}
                                value={this.state.parameters.mortgageRatesModels}
                                tooltip={this.props.t('Parameters_MortgageRatesModels')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]}
                                inputProps={{ step: '0.1' }} />
                        </FormGroup>

                        <FormGroup>
                            <TextValidator
                                type="number"
                                id="realEstateIndexModels"
                                name="realEstateIndexModels"
                                label={this.props.t('RealEstateIndexModels')}
                                value={this.state.parameters.realEstateIndexModels}
                                tooltip={this.props.t('Parameters_RealEstateIndexModels')}
                                fullWidth={true}
                                onChange={this.handleInputChange}
                                validators={['required']}
                                errorMessages={[this.props.t('FieldRequired')]}
                                inputProps={{ step: '0.1' }} />
                        </FormGroup>
                    </div>
                </Paper></Box>)
    };

    extraParamsPanel = () => {
        return Globals.user.isEmployee && (
            <Box key='extraParams' className={this.props.classes.container}>
                <Paper classes={{ root: this.props.classes.padding }}>

                    <div id='extraParams' className={clsx(this.props.classes.hideButton, this.state.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.onHideItem}>&times;</div>

                    <div>
                        <h5>{this.props.t('ExtraParameters')}</h5>
                    </div>


                    <FormGroup>
                        <SelectValidator
                            id="calculationPurpose"
                            name="calculationPurpose"
                            label={this.props.t('CalculationPurpose')}
                            value={this.state.calculationPurpose}
                            tooltip={this.props.t('Calculation_CalculationPurpose')}
                            fullWidth={true}
                            onChange={this.handleTopInputChange}
                            validators={['required']}
                            errorMessages={[this.props.t('FieldRequired')]}>
                            {
                                (this.calculationPurposeSource || []).map(option => (
                                    <MenuItem key={option.key} value={option.key}>
                                        {option.value}
                                    </MenuItem>))
                            }
                        </SelectValidator>
                    </FormGroup>
                    <FormGroup>
                        <TextValidator
                            type="text"
                            id="taskArgs"
                            name="taskArgs"
                            label={this.props.t('TaskParameters')}
                            value={this.state.parameters.taskArgs}
                            fullWidth={true}
                            onChange={this.handleInputChange} />
                    </FormGroup>
                </Paper>
            </Box>)
    };

    bondParamsPanel = () => {
        return (
            <Box key='bondParams' className={this.props.classes.container}>
                <Paper className={this.props.classes.flexContainer} classes={{ root: this.props.classes.padding }}>

                    <div id='bondParams' className={clsx(this.props.classes.hideButton, this.state.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.onHideItem}>&times;</div>

                    <div>
                        <h5>{this.props.t('BondParameters')}</h5>
                    </div>
                    {
                        (this.state.bondStats != null) ? (

                            <Table aria-label="parameters table">
                                <TableBody>
                                    {
                                        this.state.bondStats.map((item, index) => (

                                            <TableRow key={'bondstats_' + index}>
                                                <TableCell>
                                                    <TooltipInfoLabel content={item.description}>
                                                        {item.name}
                                                    </TooltipInfoLabel>
                                                </TableCell>
                                                <TableCell>{item.value}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                        ) : (
                            <div className={this.props.classes.infoLabel}>
                                <h6>{this.props.t('SelectBondToViewParameters')}</h6>
                            </div>
                        )
                    }
                </Paper>
            </Box>)
    };

    render() {

        const layoutPanels = {
            base: this.basePanel,
            common: this.commonPanel,
            bond: this.bondPanel,
            issue: this.issuePanel,
            expenses: this.expensesPanel,
            calcParams: this.calcParamsPanel,
            basicRisk: this.basicRiskPanel,
            volatility: this.volatilityPanel,
            extraParams: this.extraParamsPanel,
            bondParams: this.bondParamsPanel
        };

        const { classes, t } = this.props;

        var title = this.props.t('NewCalculation');
        if (this.state.calculationId != null) {
            switch (this.state.type) {
                case 'recalculate': {
                    title = this.props.t('Recalculation');
                }
                    break;
                case 'duplicate':
                    {
                        title = this.props.t('Duplicate');
                    }
                    break;
            }
        }

        return (
            <Container>
                <Spinner show={this.state.loading} fullScreen content="Обработка..." />
                <BondSelectDialog show={this.state.showBondSelectionDialog} onClose={this.handleBondDialogClose} onOK={this.handleBondSelection} />
                <CurvesParametersSelectDialog show={this.state.showCurveParametersSelectionDialog} onClose={this.handleCurveDialogClose} onOK={this.handleCurveSelection} />
                <NameDescriptionDialog show={this.state.showCurveParametersSaveDialog} onClose={this.handleSaveCurveDialogClose} title={t('SaveZCYCValues')} />
                <NameDialog show={this.state.layoutNameDialog} onClose={this.handleLayoutNameDialogClose} title={t('EnterLayoutName')} />

                <ConfirmationDialog show={this.state.showConfirmationDialog} onClose={this.handleConfirmationDialogClose} title={t('Calculation')} renderBody={this.handleConfirmRender} />


                <Button className={classes.root} variant="contained" color="secondary" onClick={this.startCalculation}>
                    {t('BeginCalculation')}
                </Button>


                <Grid container sx={{ alignItems: 'center', paddingTop: '1rem' }}>
                    <Grid md={8}>
                        <h2>{title}</h2>
                    </Grid>
                    <Grid md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip
                            //interactive
                            disableFocusListener
                            enterDelay={300}
                            title='Список сохранённых схем отображения'>

                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={this.handleLayoutMenuClick}
                                size="large">

                                <MenuIcon />

                            </IconButton>

                        </Tooltip>

                        <Menu
                            id="layout-menu"
                            anchorEl={this.state.layoutAnchorEl}
                            keepMounted
                            open={this.state.layoutAnchorEl != null}
                            onClose={this.handleLayoutMenuClose}
                        >
                            {
                                (this.state.isEditLayoutMode) ? [
                                    (
                                        this.state.loadedLayouts.map((option) => {
                                            return (
                                                <MenuItem id={option.id + '_loaded'} key={option.id + '_loaded'} onClick={(event) => this.handleLayoutMenuItemClick(event, option.id)}>
                                                    {option.name}
                                                    <IconButton
                                                        name={option.id}
                                                        className='ms-auto'
                                                        aria-label="delete"
                                                        onClick={(event) => this.handleLayoutDeleteMenuClick(event, option.id)}
                                                        size="large">
                                                        <ClearIcon />
                                                    </IconButton>
                                                </MenuItem>
                                            );
                                        })
                                    ),

                                    (this.state.loadedLayouts.length != 0) && (< Divider key='panels-id' />),

                                    (
                                        <NestedMenuItem
                                            key='menu-item-panels'
                                            label="Панели"
                                            parentMenuOpen={this.state.layoutAnchorEl != null}
                                        >
                                            {
                                                this.state.panels.map((panel) => (
                                                    <MenuItem key={panel.id + '_panels'} id={panel.id + '_panels'} onClick={(e) => this.handlePanelMenuItemClick(e, panel.id)}>
                                                        {(this.state.layouts[this.state.currentBreakpoint].findIndex(item => item.i == panel.id) != -1) ? (<CheckBoxOutlinedIcon key={panel.id + '_icon'} />) : (<CheckBoxOutlineBlankOutlinedIcon key={panel.id + '_icon'} />)}
                                                        {panel.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </NestedMenuItem>
                                    ),
                                    <MenuItem key={'reset_panel'} id={'reset_panel'} onClick={this.handlePanelResetMenuItemClick}>{t('ResetToDefault')}</MenuItem>,
                                    <Divider key='actions-id' />,
                                    <MenuItem key='102' id='102' onClick={(event) => this.handleLayoutMenuItemClick(event, '102')}>Сохранить</MenuItem>,
                                    <MenuItem key='103' id='103' onClick={(event) => this.handleLayoutMenuItemClick(event, '103')}>Завершить</MenuItem>
                                ] : (
                                    <MenuItem key='101' id='101' onClick={(event) => this.handleLayoutMenuItemClick(event, '101')}>Редактировать</MenuItem>
                                )
                            }
                        </Menu>
                    </Grid>
                </Grid>

                <ValidatorForm ref={x => this.calculationFormRef = x} onSubmit={this.handleCalculationSubmit}>
                    <ResponsiveReactGridLayout
                        cols={this.state.cols}
                        rowHeight={this.state.rowHeight}
                        layouts={this.state.layouts}
                        //breakpoint={this.state.currentBreakpoint}
                        breakpoints={this.state.breakpoints}
                        onBreakpointChange={this.onBreakpointChange}
                        onLayoutChange={this.onLayoutChange}
                        // WidthProvider option
                        measureBeforeMount={false}
                        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                        // and set `measureBeforeMount={true}`.
                        useCSSTransforms={this.state.mounted}
                        compactType={this.state.compactType}
                        preventCollision={!this.state.compactType}
                    >
                        {
                            this.state.layouts[this.state.currentBreakpoint] && this.state.layouts[this.state.currentBreakpoint].map((panel) => {
                                return layoutPanels[panel.i]();
                            })
                        }
                    </ResponsiveReactGridLayout>
                </ValidatorForm>
            </Container >
        );
    }
}

var component = connect(
    state => state.calculator, // Selects which state properties are merged into the component's props
    CalculatorStore.actionCreators // Selects which action creators are merged into the component's props
)(CreateCalculationPage);

export default withStyles(styles)(withRouter(withTranslation('translations')(component)));