import * as React from 'react';
import { connect } from 'react-redux';

import * as AdministrationStore from "@Store/AdministrationStore";
import { ValidatorForm, TextValidator, SelectValidator, DatePickerValidator, AutocompleteComponent, TextInputComponent, DateTimePickerValidator } from "../../../framework/components/Form";

import Button from '@mui/material/Button';

import withStyles from '@mui/styles/withStyles';
import Localization from '@Components/Localization';
import DialogInput from '../../../framework/components/Form/DialogInput';

import { InputDataType } from '../../../framework/components/Form/DialogInput';
import { withTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import UserPermissionsDialog from './UserPermissionsDialog';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


const styles = {
    listText: {
        fontSize: '1.15rem'
    }
};

interface IState {
    isShowRolesDialog: boolean;
}

interface IProps {

    row: any;
    onChange: any;
    onApplyChanges: any;
    onCancelChanges: any;
    open: boolean;
    title: string;
    isPermissionsSaved: boolean | null;

    classes: any;

    loadRoles: () => void;
    loadPermissions: () => void;
    loadUserPermissions: (id: number) => void;
    updateUserPermissions: (update: any) => void;

    roles: any[];
    permissions: any[];
    userPermissions: [];

    t: any;
}

class UserEditorPage extends React.PureComponent<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            isShowRolesDialog: false,
        }
    }

    editFormRef = null;

    componentDidMount() {
        if (this.props.roles == null)
            this.props.loadRoles();
        if (this.props.permissions == null)
            this.props.loadPermissions();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.isPermissionsSaved != this.props.isPermissionsSaved) {

            if (this.props.isPermissionsSaved)
                this.setState({ isShowRolesDialog: false });
        }

        /*
        if (this.props.row != prevProps.row && this.props.row != null &&
            prevProps.open != this.props.open && this.props.open == true) {

            var mailType = this.getMailType(this.props);
            this.setState({ mailType: mailType });
        }
        */
    }

    handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        this.props.onChange(event.target.name, event.target.value);

    };

    handleCheckChange = (event) => {

        this.props.onChange(event.target.name, event.target.value);
    }


    handleInputChange = (name: string, value: any) => {

        /*
        switch (name) {
            case 'groupID':
                {
                    if (this.props.groups != null) {

                        var index = this.props.groups.findIndex((item) => item.key == value);
                        if (index != -1) {
                            this.props.onChange('name', this.props.groups[index].value);
                        }
                    }
                }
                break;
            case 'userID':
                {
                    if (this.props.users != null) {

                        var index = this.props.users.findIndex((item) => item.key == value);
                        if (index != -1) {
                            this.props.onChange('receiver', this.props.users[index].value);
                        }
                    }
                }
                break;
        }
        */
        this.props.onChange(name, value);
    };

    handleSubmit = () => {
        this.props.onApplyChanges();
    }

    onValidateChanges = (event) => {
        this.editFormRef.submit();
    }

    onCancelChanges_Local = (event: any, reason: string) => {
        if (reason == 'backdropClick') {
            event.preventDefault();
            return;
        }

        this.props.onCancelChanges(event);
    }

    webTokenExistenceValidate = () => {
        return this.props.row.webToken != ''; //token exists, expired date required
    }

    onPermissionsChanged = (newPermissions: string) => {
        this.props.onChange('permissions', newPermissions);
    }

    onRolesOpenButtonClick = () => {
        this.props.loadUserPermissions(this.props.row.id);
        this.setState({ isShowRolesDialog: true });
    }

    saveUserRoles = (data: any) => {  //{ roles: this.state.selectedRoles, permissions: this.state.selectedPermissions }
        //CHECKME!!! save save roles
        this.props.updateUserPermissions({ ID: this.props.row.id, Roles: { Value: data.roles.map(x => x.id) }, Permissions: { Value: data.permissions } });
    }

    closeUserRoles = () => {
        this.setState({ isShowRolesDialog: false });
    }

    render() {

        const {
            classes,
            row,
            onChange,
            onApplyChanges,
            //onCancelChanges,
            open,
            title, //CHECKME!!! add
            t
        } = this.props;

        return (
            <Dialog
                /*disableBackdropClick*/
                disableEscapeKeyDown
                fullWidth
                maxWidth='sm'
                open={open}
                onClose={this.onCancelChanges_Local}>

                <UserPermissionsDialog
                    open={this.state.isShowRolesDialog}
                    roles={this.props.roles}
                    permissions={this.props.permissions}
                    userPermissions={this.props.userPermissions}
                    onApplyChanges={this.saveUserRoles}
                    onCancelChanges={this.closeUserRoles}
                />

                <DialogTitle>{t('Edit')}</DialogTitle>
                <DialogContent>
                    <ValidatorForm ref={x => this.editFormRef = x} onSubmit={this.handleSubmit}>
                        <Grid container>
                            <Grid xs={12}>
                                <DialogInput
                                    name='emailConfirmed'
                                    dataType={InputDataType.BOOLEAN}
                                    handleValueChange={this.handleInputChange}
                                    locale={Localization}
                                    label={t('EmailConfirmed')}
                                    tooltip={t('EmailConfirmed_Info')}
                                    value={this.props.row.emailConfirmed}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        <Grid container sx={{ paddingBottom: '1rem'}}>
                            <Grid xs={12}>
                                <DateTimePickerValidator
                                    label={t('WebToketExpiredDate')}
                                    name="expiresAt"
                                    id="expiresAt"
                                    tooltip={t('WebToketExpiredDate')}
                                    fullWidth={true}
                                    value={this.props.row.expiresAt}
                                    onChange={(event) => this.handleInputChange('expiresAt', event.target.value)}
                                    validators={[this.webTokenExistenceValidate]}
                                    errorMessages={[t('FieldRequired')]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid>
                                <Button variant="contained" color="secondary" onClick={this.onRolesOpenButtonClick}>
                                    {this.props.t('RolesAndPermissions')}
                                </Button>
                            </Grid>
                        </Grid>

                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCancelChanges_Local} variant="contained" color="primary">Отмена</Button>
                    <Button onClick={this.onValidateChanges} variant="contained" color="secondary">Сохранить</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

var component = connect(
    (state: any) => state.administration, // Selects which state properties are merged into the component's props
    AdministrationStore.actionCreators // Selects which action creators are merged into the component's props
)(UserEditorPage);

export default withStyles(styles)(withTranslation('translations')(component));
