/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Popover, Popper, Tooltip } from "@mui/material";
/* eslint-enable */
import useFormValidation from './ValidationForm/useFormValidation';
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
import ErrorTooltip from '../../../components/shared/ErrorTooltip';

import { makeStyles } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import Localization, { i18nLocales } from '@Base/components/Localization';

const useStyles = makeStyles({
    error: {
        backgroundColor: 'rgb(255,237,237) !important',
    },
    errorPopper: {
        backgroundColor: style.colorPrimary3,
        maxWidth: '300px',
        color: '#FFF',
        fontSize: '1rem',
        padding: '0.2rem 0.5rem',
        borderRadius: '4px',
        margin: '0 4px !important',
        zIndex: '+2'
    },
    tooltip: {
        //zIndex: +3,
        display: 'flex',
        width: '100%'
    }

});

type IProps = {
    name: string;
    errorMessages: string[];
    validators: any[];
    tooltip: string;
    tooltipLink: string;
    tooltipButtonName: string;
    onLinkClick: () => void;
    readOnly: boolean;
    inputProps: any;
    InputProps: any;
    value: any;
    disabled: boolean;
    type: any;
    hideValidationIcon: boolean;
    isValid: boolean;
    errorMessage: string;
    className: any;
    fullWidth: boolean;
    onFocus: () => void;
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}



const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;
        // console.log('onChange', other)

        return (
            <NumericFormat
                getInputRef={ref}
                onValueChange={(values) => {
                    // console.log(values)
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                autoComplete='off'
                thousandSeparator={Localization.delimiter}
                decimalSeparator={Localization.separator}
                valueIsNumericString
                prefix=""
                title={''}
                {...other}
            />
        );
    },
);

const NumericStandaloneValidator = React.forwardRef((props: IProps, ref) => {
    const {
        name,
        value,
        tooltip,
        tooltipLink,
        tooltipButtonName,
        onLinkClick,
        readOnly,
        inputProps,
        InputProps,
        disabled,
        hideValidationIcon = false,
        isValid,
        errorMessage,
        onFocus: onFocusOriginal = () => { },

        ...rest
    } = props

    const classes = useStyles();
    const [displayWarning, setDisplayWarning] = useState(false)
    const [displayActiveWarning, setDisplayActiveWarning] = useState(false)
    const elRef = useRef()

    //console.log(`=====field ${name} value: ${value} isValid: ${isValid}`);

    useEffect(() => {
        if (isValid) {
            setDisplayWarning(false)
            setDisplayActiveWarning(false)
        }
    }, [isValid])

    const [redraw, setRedraw] = useState(0)

    function handleRedraw() {
        // console.log('redraw', redraw)
        setRedraw(redraw => redraw + 1)
    }
    useEffect(() => {
        Localization.onLanguageChanged(handleRedraw)

        return Localization.offLanguageChanged(() => handleRedraw)
    }, [])


    // console.log('InputProps*', InputProps)
    return (
        <>
            {(hideValidationIcon == true && !isValid) &&
                <Popper open={displayWarning || displayActiveWarning} anchorEl={elRef.current}
                    // disablePortal={false}
                    placement="right-start"
                    className={classes.errorPopper}
                >
                    {errorMessage}
                </Popper>
            }
            {/* {console.log('rest', rest)} */}
            {/* {console.log('InputProps', inputProps)}  */}
            <TooltipInfoLabel
                className={classes.tooltip}
                content={tooltip}
                link={tooltipLink}
                linkName={tooltipButtonName}
                onLinkClick={onLinkClick}>
                {/* {(!isValid) ? console.log('not sValid', name, (!isValid)?classes.error:''): ''} */}
                <TextField
                    {...rest}
                    ref={elRef}
                    disabled={disabled}
                    name={name}
                    //type="number"
                    value={value}
                    variant="standard"
                    autoComplete='off'
                    error={!isValid}
                    //InputLabelProps={{ shrink: !isValid}} //suppress label move if field of error
                    inputRef={ref}
                    inputProps={{
                        autoComplete: "off",
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        ...inputProps,
                        className: [(hideValidationIcon == true && !isValid) ? classes.error : ''].join(' ')
                        // autocomplete:"off"
                    }}
                    onFocus={(e) => { if (hideValidationIcon == true && !isValid && !displayActiveWarning) setDisplayActiveWarning(true); onFocusOriginal(e); }}
                    onBlurCapture={() => { if (hideValidationIcon == true && !isValid && displayActiveWarning) setDisplayActiveWarning(false) }}
                    onMouseOver={(e) => { if (hideValidationIcon == true && !isValid && !displayWarning) setDisplayWarning(true) }}
                    onMouseOut={(e) => { if (hideValidationIcon == true && !isValid && displayWarning) setDisplayWarning(false) }}

                    InputProps={{
                        readOnly: !!readOnly
                        , startAdornment: (InputProps?.startAdornment ? InputProps?.startAdornment : null)
                        , endAdornment: (
                            (!InputProps?.endAdornment && hideValidationIcon) ? null :
                                <InputAdornment sx={!!isValid && !InputProps?.endAdornment ? { display: 'none' } : {}} position="end">
                                    {InputProps?.endAdornment}
                                    {(hideValidationIcon == false && !isValid) && (
                                        <ErrorTooltip content={errorMessage} placement="left-start" />
                                    )}
                                </InputAdornment>
                        )
                        , inputComponent: NumericFormatCustom as any,
                    }}
                />
            </TooltipInfoLabel>
        </>
    )
}
)

export default NumericStandaloneValidator;