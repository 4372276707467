import { push } from 'redux-first-history';
import { jwtDecode } from 'jwt-decode';
import Localization from '@Components/Localization';
// import { i18nLocales } from '@Components/Localization';
import { v4 as uuidv4 } from 'uuid';
import AccountService from './services/AccountService';
import { isPermissionGranted } from "@Utilities";

export default class Globals {

    static Administrator_Role = 'Admin';

    static isInitialized = false;
    static _storage = localStorage;
    static _store = null;
    static _listeners = [];
    static _token = null;
    static _state = null;
    static _deviceId = null;

    static get WikiServer() {
        //return 'http://matlab-3.ahml1.ru:3000';
        //return window.location.protocol + '//' + window.location.hostname + ':3000';
        return 'https://вики.калькулятор.дом.рф';
    }

    static reset() {
        this._storage.removeItem('backup_user');
        this._storage.removeItem('user');
        this._storage.removeItem('session');
        this._storage.removeItem('state');

        //CHEKME!! need?
        this._storage = localStorage;
        this._token = null;
        this._state = null;
    }

    static init(session, user) {
        if (this.isInitialized) {
            throw Error("Globals is already initialized.");
        }

        if (session)
            this.session = session;

        if (user)
            this.user = user;

        //try to find user
        if (this.user != null) {
            if (!this.isAuthenticated) {
                this.reset();
            }
        }

        if (this.user?.token != null)
            this._token = jwtDecode(this.user.token);

        var saved = this.locale;
        if (saved == null) {
            saved = navigator.language || navigator.userLanguage;
            if (saved == null)
                saved = 'ru'; //by default

            this.locale = saved;
        }
        else {
            Localization.setLocalization(saved);
        }

        //i18nLocales.changeLanguage(saved);
        //Localization.setLocalization(saved);

        this._deviceId = localStorage.getItem('deviceId');
        if (this._deviceId == null) {
            this._deviceId = uuidv4();
            localStorage.setItem('deviceId', this._deviceId);
        }

        this.isInitialized = true;
    }

    static LogActivity(activityId) {
        AccountService.addVisitor(this._deviceId, activityId);
    }

    static get deviceId() {
        if (this._deviceId == null)
            this._deviceId = this.localStorage.getItem('deviceId');

        return this._deviceId;
    }

    static redirect(url) {
        if (this._store) {
            this._store.dispatch(push(url));
        }
    }

    static throwIfNotInitialized() {
        if (!this.isInitialized) {
            throw Error("'Globals' is not initialized. You have to call 'Globals.init' before.");
        }
    }

    /////////////////////////////////////////////////////////////////
    static get locale() {

        return this._storage.getItem('lang');
    }

    static set locale(locale) {


        var loc = locale;
        if (loc.length > 2) {
            loc = loc.substring(0, 2);
        }

        this._storage.setItem('lang', loc);

        Localization.setLocalization(loc);
    }

    static get user() {

        var user = this._storage.getItem('user');

        if (user != null) {
            return JSON.parse(user);
        }

        return {};
        //throw Error("Globals: current user was not initialized.");
    }

    static set store(store) {
        if (store == null)
            throw Error("Globals: store cannot be empty.");
        this._store = store;
    }

    static set user(user) {

        if (!user.isRemember)
            this._storage = sessionStorage;

        this._storage.setItem('user', JSON.stringify(user));

        if (user.token)
            this._token = jwtDecode(user.token);
    }

    static switchToUser(newUser) {

        var currentUser = this._storage.getItem('user');
        if (this._storage.getItem('backup_user') == null)
            this._storage.setItem('backup_user', currentUser);
        this.user = newUser;

        var changed = this.isUserSwitched;
        this._listeners.forEach((item) => {
            item.call(null, changed);
        });
    }

    static restoreToUser() {

        var currentUser = this._storage.getItem('backup_user');
        if (currentUser != null) {
            this.user = JSON.parse(currentUser);
            this._storage.removeItem('backup_user');

            var changed = this.isUserSwitched;
            this._listeners.forEach((item) => {
                item.call(null, changed);
            });
        }
    }

    static get isUserSwitched() {
        return (this._storage.getItem('backup_user') != null);
    }

    static updateUser(user) {
        if (user == null)
            throw Error("Globals: user argument is null");

        var oldUser = this.user;
        user.isRemember = oldUser.isRemember;
        this.user = user;
    }

    /////////////////////////////////////////////////////////////////
    static get session() {

        var session = this._storage.getItem('session');

        if (session != null) {
            return JSON.parse(session);
        }

        return null;

        //throw Error("Globals: current session was not initialized.");
    }

    static set session(session) {
        this._storage.setItem('session', JSON.stringify(session));
    }

    /////////////////////////////////////////////////////////////////
    static get isAuthenticated() {
        var user = this._storage.getItem('user');
        if (user == null)
            return false;

        var authUser = JSON.parse(user);
        return authUser.token != null;
    }

    static subscribeToSwitch(func) {
        this._listeners.push(func);
    }

    static unsubscribeFromSwitch(func) {
        var index = this._listeners.indexOf(func);
        if (index !== -1)
            this._listeners = this._listeners.splice(index, 1);
    }

    static hasRole(roleName) {

        if ((roleName?.length ?? 0) === 0)
            return false;

        if ((this._token?.role?.length ?? 0) === 0)
            return false;

        return this._token.role.includes(roleName); //CHEKME!!! add toLowerCase?
    }

    static hasAccess(roles: string[]): boolean {

        if (Array.isArray(roles)) {
            if (roles == null || roles.length === 0)
                return true; //no requested roles, - access granted

            return roles.find(x => this.hasRole(x)) != null;
        }

        if (roles == null)
            return true; //no requested roles, - access granted

        return this.hasRole(roles);
    }

    static hasPermissionAccess(permission: number): boolean {
        if (permission == null || permission === 0)
            return true;

        if ((this._token?.permissions?.length ?? 0) === 0)
            return false;

        return isPermissionGranted(permission, this._token.permissions);
    }


    static get state() {

        if (this._state == null) {
            var state = this._storage.getItem('state');
            if (state == null) {
                this._state = {};
            }
            else {
                this._state = JSON.parse(state)
            }
        }

        return this._state;
    }

    static set state(newState) {

        this._state = newState;
        if (this._state != null)
            this._storage.setItem('state', JSON.stringify(this._state));
        else
            this._storage.removeItem('state');
    }

    static setState(name, customState) {

        var localState = this.state;
        localState[name] = customState;
        this.state = localState;
    }

    static redirectToArticle(link) {

        if (link == null || link.length === 0)
            return;

        var redirect = link;
        if (!link.startsWith('http')) //not absolute link
            redirect = Globals.WikiServer + '/' + link;

        window.open(redirect, "_blank");
    }
}