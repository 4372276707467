import { actionCreators as actionAlert } from '@Base/store/AlertStore'
import { Box, Fade, Grow, Slide, Zoom, alpha, duration } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'


function NotificationAlert({ id, text, timeout = 4000, transitionTime = 600, color = 'rgb(255,0,0)' }) {
    const dispatch = useDispatch()
    const [fadeOn, setFadeOn] = useState(true)

    const cls = makeStyles({
        root: {
            margin: '0.3rem 0 0 1rem',
            borderRadius: '0.5rem',
            border: `solid 1px`,
            borderColor: alpha(color, 1),
            width: 'fit-content !important',
            padding: '0.5rem',
            fontSize: '1rem',
            backgroundColor: '#FFF',//alpha(color, 0.1),
            color: alpha(color, 1),
            position: 'relative',
            whiteSpace: 'nowrap',
            float: 'left',
            '&:before': {
                position: 'absolute',
                left: 0, top: 0, right: 0, bottom: 0,
                content: '""',
                backgroundColor: alpha(color, 0.05),
            }
            // height: 'fit-content',
            // opacity: '1',
            // transition: 'all 1s ease-out',
        },
        zoom: {
            // display: 'flex',
            height: '0px',
            overflow: 'hidden',
            transition: `all ${transitionTime}ms ease-in-out`,
        },

        // zoomIn: {
        //     height: 'fit-content',
        //     transition: `all ${transitionTime}ms ease-in-out`,
        // },
        // zoomOut: {
        //     height: '0px',
        // }
    })()

    useEffect(() => {
        setTimeout(() => {
            setFadeOn(false)
        }, timeout)

        setTimeout(() => {
            actionAlert.removeAlert(id)(dispatch)
        }, timeout + transitionTime)
    }, [])


    return (
        // <Zoom in={fadeOn} easing={{ enter: 'linear', exit: 'linear' }}
        //     timeout={transitionTime}
        //     addEndListener={removeFunc} style={{ transformOrigin: 'bottom left' }}>
        //<Box className={[cls.zoom , fadeOn ? cls.zoomIn :''].join(' ')}>
        // <Slide direction='right' in={fadeOn} mountOnEnter unmountOnExit easing={{ enter: 'linear', exit: 'linear' }}>
        <Fade in={fadeOn} mountOnEnter unmountOnExit easing={{ enter: 'linear', exit: 'linear' }}>
            <Box className={cls.root}>
                {text}
            </Box>
        </Fade >
        // </Slide>
        // </Box> 
        // </Zoom>
    )
}

export default NotificationAlert