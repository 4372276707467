/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { DefaultNullValue } from "../Common/Definitions";
import { debounce } from 'throttle-debounce';
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
import TextValidator from "./TextValidator";
import clsx from 'clsx';
/* eslint-enable */
import useFormValidation from './ValidationForm/useFormValidation';
// import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import { Popper } from '@mui/material';
import ErrorTooltip from '@Components/shared/ErrorTooltip';

const useStyles = makeStyles({

    tooltip: {
        display: 'flex'
    },
    popper: {
        width: "fit-content"
    }
});

const AutocompleteComponent = ({

    value,
    errorMessages,
    validators,
    requiredError,
    helperText,
    loadingText,
    noOptionsText,
    label,
    tooltip,
    fullWidth,
    freeSolo,
    name,
    disabled,
    className,

    getOptionLabel,
    isOptionEqualToValue,

    //not used here
    //id,
    onChange: handleValueChange,
    loadDataSource = null,
    dataSource,
    getKey,

    hideValidationIcon = false,
    disableErrorHighlight = false,

    ...rest
}) => {

    const classes = useStyles();
    // const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectValue, setSelectValue] = useState(value || null);
    const [localDataSource, setLocalDataSource] = useState([]);

    const { isValid, errorMessage } = useFormValidation(disabled, name, selectValue, errorMessages, validators);

    const getData = () => {

        if (loadDataSource) {
            if (typeof loadDataSource === 'function') {

        (async () => {
            var localValue = value || '';

            var queryValue = localValue;
            if (queryValue === '')
                queryValue = null; //for our query

            await loadDataSource("", queryValue, null, null);
                })();
            }
        }
    }

    useEffect(() => {
        if (dataSource != null) {
            updateDataSourceState();
            return;
        }

        getData();

    }, []);

    const keyGetter = (item) => {
        if (getKey != null)
            return getKey(item);

        return item?.key;
    }

    useEffect(() => {
        updateDataSourceState();
    }, [dataSource]);

    useEffect(() => {

        var noValueInList = localDataSource.findIndex(x => keyGetter(x) == value);
        var loadData = (value != null) && (localDataSource.length == 0 || noValueInList == -1);

        if (loadData)
            getData();
        else if (value != null) {
            var selectedValue = localDataSource[noValueInList]
            setSelectValue(selectedValue);
        }

    }, [value]);

    const updateDataSourceState = () => {

        var items = [];
        if (dataSource != null) {
            items = dataSource.map((x: any) => (keyGetter(x) === null) ? { key: DefaultNullValue, value: x.value } : x);
        }

        var localValue = value || '';

        var emptyValue = items.find((x: any) => keyGetter(x) === DefaultNullValue);
        if (emptyValue == null) {
            emptyValue = { key: DefaultNullValue, value: '' };
        }

        //response.count - total count of records
        if (selectValue != null && (selectValue !== '')) {

            var index = items.indexOf(selectValue);
            if (index == -1) { //not found, try to find by key
                index = items.findIndex((x) => keyGetter(x) == selectValue);
                if (index != -1)
                    setSelectValue(items[index]);
            }

            setLocalDataSource(items);
            setLoading(false);
        }
        else {
            var selectedValue = items.find((x: any) => keyGetter(x) === localValue);
            if (selectedValue === undefined) {
                selectedValue = null; //emptyValue;
            }

            setLocalDataSource(items);
            setSelectValue(selectedValue);
            setLoading(false);
        }
    }

    const handleDynamicSelectChange = (e: React.ChangeEvent<{}>, value: any) => {


        //console.log('value!!!!!!!!!!!!!!!!!!', name, value)
        setSelectValue(value);

        var key = keyGetter(value);
        if (key == DefaultNullValue)
            key = null;

        handleValueChange(name, key);

        // if (value == null && loadDataSource !=null) {
        //     //update datasource
        //     loadDataSource('');
        // }
    }

    function isOptionEquals(option, value) {
        return option?.key === value?.key;
    }

    const ResizedPopper = function (props) {
        return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
    };

    return (
        <TooltipInfoLabel className={clsx(classes.tooltip, className)} content={tooltip}>
            {/* {console.log('rest', rest)} */}
            <Autocomplete
                name={name}
                fullWidth={fullWidth}
                freeSolo={freeSolo}
                disabled={disabled}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                getOptionLabel={getOptionLabel != null ? getOptionLabel : option => option?.value || ''}
                isOptionEqualToValue={isOptionEqualToValue != null ? isOptionEqualToValue : isOptionEquals}
                value={selectValue}
                onChange={handleDynamicSelectChange}

                loadingText={loadingText}
                noOptionsText={noOptionsText}
                options={localDataSource}
                loading={isOpen && loading}
                renderInput={params => (

                    <TextField
                        {...params}
                        id={name}
                        name={name}
                        margin="none"
                        variant="standard"
                        label={label}
                        error={!isValid}
                        //helperText={(!isValid && errorMessage) || helperText}
                        fullWidth={fullWidth}
                        onChange={(event) => {
                            if (loadDataSource != null)
                                loadDataSource(event.target.value);
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    {(!disableErrorHighlight && hideValidationIcon == false && !isValid) && (
                                        <ErrorTooltip content={errorMessage} placement="left-start" />
                                    )}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
                PopperComponent={ResizedPopper}
                {...rest}
            />
        </TooltipInfoLabel>
    );
}

export default AutocompleteComponent;