import React, { useEffect, useLayoutEffect, useRef } from 'react'
import Helmet from "react-helmet"
import { useDispatch } from 'react-redux'
import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { actionCreators as actionTopNavbar } from '@Base/store/TopNavbarStore'
import { t } from 'i18next'
import { Box, Container, Grid, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import InputPanel from './panels/InputPanel'
import FlowsChartPanel from './panels/FlowsChartPanel'
import FlowsPanel from './panels/FlowsPanel'
import SCurveChartPanel from './panels/SCurveChartPanel'
import { useSelector } from 'react-redux'
import Alert from './components/Alert'
import useMobileDetect from '@Base/components/hook/useMobileDetect'
import { getTooltip } from '@Base/components/shared/CustomTooltip'
import style from '@Styles/domrf-theme.module.scss'
import CurrentRateChartPanel from './panels/CurrentRateChartPanel'
import DownloaderAlert from '../downloader/DownloaderAlert'
import Notifications from './panels/Notifications'
import CurrentRateTab from './charts/CurrentRateTab'
import TechSupportPage from './components/TechSupportPage'
import CPRChartPanel from './panels/CPRChartPanel'
import { useNewDispatch, useNewSelector } from '@Base/index'
import { setConventionData } from '@Base/newStore/conventionSlice'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => createStyles({
    startRoot: {

    },
    root: {
        // position: 'absolute',
        // left: 0, top: 0, right: 0, bottom: 0,
        marginTop: '0.5rem',
        flexGrow: 1,
        display: 'grid',
        gridColumnGap: '8px',
        gridRowGap: '8px',
        overflow: 'hidden',
        height: 'Calc(100% - 8px)',
        justifyContent: 'center',
        [theme.breakpoints.down(980)]: {
            display: 'flex',
            // gridTemplateAreas: `'b3'`,
            // gridTemplateColumns: '1fr',
            // gridTemplateRows: 'auto',
            '& $block1': { display: 'none' },
            '& $block2': { display: 'none' },
            '& $block3': { width: '100%' },
            '& $block4': { display: 'none' },
            '& $block5': { display: 'none' },

        },
        [theme.breakpoints.down(1530) && theme.breakpoints.up(980)]: {
            gridTemplateAreas: `'b3 b1' 'b3 b2' 'b3 b4' 'b3 b5'`,
            gridTemplateColumns: 'max-content max-content',
            // gridTemplateRows: 'max-content max-content max-content max-content'
            gridTemplateRows: 'minmax(350px, 418px) minmax(350px, 418px) minmax(350px, 418px) minmax(350px, 418px)',
        },
        [theme.breakpoints.up(1530)]: {
            gridTemplateAreas: `'b1 b3 b4' 'b2 b3 b5'`,
            gridTemplateColumns: 'max-content max-content max-content',
            // gridTemplateRows: '1fr 1fr',
            // gridTemplateRows: 'max-content max-content'
            gridTemplateRows: 'minmax(368px, 418px) minmax(368px, 418px)',
            // gridTemplateAreas: `'b1 b3 b4' minmax(350px, 418px) 'b1 b3 b4' minmax(350px, 418px) 'b2 b3 b5' minmax(350px, 418px) 'b2 b3 b5' minmax(350px, 418px)`
        },
    },
    block1: {
        position: 'relative',
        minWidth: '550px',
        maxWidth: '600px',
        // minHeight: '368px',
        // maxHeight: '418px',
        gridArea: 'b1',
        [theme.breakpoints.down(1530)]: {
            marginLeft: 'none',
            marginRight: 'auto',
        },
        [theme.breakpoints.up(1530)]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    block2: {
        position: 'relative',
        minWidth: '550px',
        maxWidth: '600px',
        // minHeight: '368px',
        // maxHeight: '418px',
        gridArea: 'b2',
        [theme.breakpoints.down(1530)]: {
            marginLeft: 'none',
            marginRight: 'auto',
        },
        [theme.breakpoints.up(1530)]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    block3: {
        position: 'relative',
        minWidth: '387px',
        maxWidth: '400px',
        minHeight: '730px',
        // maxHeight: '736px',
        gridArea: 'b3',
        [theme.breakpoints.down(1530) && theme.breakpoints.up(980)]: {
            marginLeft: 'auto',
            marginRight: 'none',
        },
        [theme.breakpoints.up(1530) && theme.breakpoints.down(980)]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    block4: {
        position: 'relative',
        minWidth: '550px',
        maxWidth: '600px',
        // minHeight: '368px',
        // maxHeight: '418px',
        gridArea: 'b4',
        [theme.breakpoints.down(1530)]: {
            marginLeft: 'none',
            marginRight: 'auto',
        },
        [theme.breakpoints.up(1530)]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    block5: {
        position: 'relative',
        minWidth: '550px',
        maxWidth: '600px',
        // minHeight: '368px',
        // maxHeight: '418px',
        gridArea: 'b5',
        [theme.breakpoints.down(1530)]: {
            marginLeft: 'none',
            marginRight: 'auto',
        },
        [theme.breakpoints.up(1530)]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    container: {
        minHeight: '725px',
        maxHeight: '830px',
        position: 'absolute',
        left: 0, top: 0, right: 0, bottom: 0
    },
    topContainer: {
        position: 'absolute',
        left: 0, right: 0,
        top: 0, bottom: '51%',
        overflow: 'hidden',
        minHeight: '355px',
    },
    bottomContainer: {
        position: 'absolute',
        left: 0, right: 0,
        top: '49%', bottom: 0,
        overflow: 'hidden',
        minHeight: '370px',
    },
    virtualScrollablePanel: {
        // border: 'solid 1px #000',
        // padding: '0 11px 0 0',
        flexGrow: 1,
        overflowY: 'scroll',
        overflowX: 'none',
        // marginTop: '0.5rem',
        // '& *': { userSelect: 'none', },
        // '& .Mui-selected': {
        //     // backgroundColor: `#e4f1d5 !important`,
        //     backgroundColor: `#e4f1d500 !important`,
        // },
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        // overflowY: 'auto',
        // padding: 0,
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '6px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            // '&::-webkit-scrollbar-track': {
            //     backgroundColor: '#00000008',
            // },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px',
        },
    },
    hidden: {
        display: 'none',
    },
    bottomInfo: {
        position: 'absolute',
        left: 0, right: 0, bottom: '21px',
        textAlign: 'center',
        color: style.colorPrimary3_60,
        // zIndex: 100,
        '& a': {
            color: 'rgb(0,176,80)'
        }
    },
    bottomInfoMobile: {
        // fontSize: '0.9em'
    },
    marginPanel: {
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        marginBottom: '0.5rem',
    },
    rootMobile: {
        minWidth: '100%',
    },
    rootInputPanel: {
        // height: '100%',
        minWidth: '550px',
        [theme.breakpoints.down(396)]: {
            minWidth: '358px',
            // paddingRight: '11px !important',
        },
        // width:'396px',
        minHeight: 'Calc( 100vh - 101px)',
        position: 'relative',
    },
    rootFlowPanel: {
        // height: '100%',
        // width: 'auto',
        position: 'relative',
        // border: 'solid 1px',
        // overflow: 'hidden',
        // minWidth: '600px',
        // marginLeft: '4px',
        maxWidth: '560px',
        maxHeight: 'Calc(100vh - 101px)',
        [theme.breakpoints.down(1530)]: {
            '&': { display: 'none' },
        },
    },
    rootChartsPanel: {
        // width: 'auto',
        // height: '100%',
        position: 'relative',
        maxHeight: 'Calc(100vh - 101px)',
        [theme.breakpoints.down(1520)]: {
            '&': { display: 'none' },
        },
    },
    fakedPreloadFont: {//need for PREloading font before render real elements with this font
        fontFamily: 'DINPro-Bold',
        userSelect: 'none',
    },
    rootContainer: {

        '&:parent': {
            backgroundColor: '#0001',
        },
        '& >*>*': {
            // border: 'solid 1px #0002',
        },
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
    },
    rootGridMobile: {
        flexGrow: 1,
        alignContent: 'flex-start',
        position: 'relative',
        right: '-2px',
    },
    rootGrid: {
        flexGrow: 1,
        alignContent: 'flex-start',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
}))

const Convention2 = ({ demo = false }) => {

    /*-------------for Demonstration navigation--START----------------------*/
    const navigate = useNavigate()
    useLayoutEffect(() => {
        if (demo) setTimeout(() => { navigate("/instruments/convention") }, 100)
    })
    /*-------------for Demonstration navigation--END----------------------*/

    const cls = useStyles()
    const dispatch = useDispatch()
    const newDispatch = useNewDispatch()
    // const {
    //     pricingResult = null,
    //     error: apiError = null,
    //     ...otherStore } = useSelector((state: any) => state.convention)

    const {
        pricingResult = null,
        error: apiError = null,
        ...otherStore } = useNewSelector((state: any) => state.newConvention)

    const { isMobile, isLandscape } = useMobileDetect()
    // console.log('alerts ', alerts)
    useEffect(() => { //установка\сброс title в NavBar
        actionTopNavbar.setTitle(t("Convention2"))(dispatch)
        // actionConvention.setData({
        //     showHistoryFlows: false,
        //     mbsCashflowTable: null,
        //     mbsCashflowGraph: null,
        //     selectedBond: null,
        //     zcycGraph: null,
        //     sCurveGraph: null,
        //     calculationParameters: null
        // })(dispatch)
        newDispatch(setConventionData({
            showHistoryFlows: false,
            mbsCashflowTable: null,
            mbsCashflowGraph: null,
            selectedBond: null,
            zcycGraph: null,
            sCurveGraph: null,
            calculationParameters: null
        }))
        return () => {
            actionTopNavbar.setTitle("")(dispatch)
            // actionConvention.setData({
            //     error: null,
            //     showHistoryFlows: false,
            //     mbsCashflowTable: null,
            //     mbsCashflowGraph: null,
            //     selectedBond: null,
            //     zcycGraph: null,
            //     sCurveGraph: null,
            //     calculationParameters: null
            // })(dispatch)
            newDispatch(setConventionData({
                error: null,
                showHistoryFlows: false,
                mbsCashflowTable: null,
                mbsCashflowGraph: null,
                selectedBond: null,
                zcycGraph: null,
                sCurveGraph: null,
                calculationParameters: null
            }))
        }

    }, [])

    // console.log('apiError', apiError)
    if (apiError != null)
        return (<TechSupportPage />)

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('Convention2_instrumentHeader')}</title>
            </Helmet>
            {/* <Notifications /> */}
            <Container
                maxWidth={isMobile ? 'xxl' : 'xxl'}
                sx={{ flexGrow: 1, position: 'relative' }}
            >
                <Box className={cls.virtualScrollablePanel}>
                    <Box className={[cls.root, cls.virtualScrollablePanel].join(' ')}>
                        {/* <Grid item xs className={[cls.rootChartsPanel, (!Boolean(pricingResult) || isMobile) && cls.hidden].join(' ')}> */}
                        {/* <Box className={cls.container}> */}
                        <Box className={[cls.block1, (!Boolean(pricingResult)) && cls.hidden].join(' ')}>
                            <CPRChartPanel />
                        </Box>
                        <Box className={[cls.block2, (!Boolean(pricingResult)) && cls.hidden].join(' ')}>
                            <CurrentRateChartPanel />
                        </Box>
                        {/* </Box> */}
                        {/* </Box> */}
                        {/* </Grid> */}

                        {/* <Grid item className={[cls.rootInputPanel, isMobile ? cls.rootMobile : ''].join(' ')}> */}
                        <Box className={cls.block3}>
                            <InputPanel demo={demo} />
                            {/* {!Boolean(pricingResult) && <Box className={[cls.bottomInfo].join(' ')}>
                                <div dangerouslySetInnerHTML={{ __html: getTooltip({ tooltip: "convention2.bottomInfo" }) }}></div>
                            </Box>} */}
                            <pre className={cls.fakedPreloadFont}> </pre>{/*do not delete*/}
                        </Box>
                        {/* </Grid> */}

                        {/* <Grid item xs className={[cls.rootFlowPanel, (!Boolean(pricingResult) || isMobile) && cls.hidden].join(' ')}> */}
                        {/* <Box className={cls.virtualScrollablePanel}> */}
                        {/* <Box className={cls.container}> */}
                        <Box className={[cls.block4, (!Boolean(pricingResult)) && cls.hidden].join(' ')}>
                            <FlowsPanel />
                        </Box>
                        <Box className={[cls.block5, (!Boolean(pricingResult)) && cls.hidden].join(' ')}>
                            <FlowsChartPanel />
                        </Box>
                        {/* </Box> */}
                        {/* </Grid> */}

                        {/* </Grid> */}
                    </Box>
                </Box>
            </Container >
        </>
    )
}

export default Convention2