import { alpha, Box, Stack, Tooltip, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { format, isValid, parse } from 'date-fns'
import React, { useState, useEffect, useLayoutEffect, useRef, useCallback, forwardRef, useMemo } from 'react'
import usePrevious from '../../../components/hook/usePrevious'
import Localization from '../../../components/Localization'
import style from '@Styles/domrf-theme.module.scss';
import { Theme } from '@mui/material'
import { formDatePattern, formDateFromParts } from '@Utilities';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            fullWidth: {
                width: '100%'
            },
            errorPopper: {
                backgroundColor: style.colorPrimary3,
                maxWidth: '620px !important',
                color: '#FFF',
                fontSize: '1rem',
                padding: '3px 8px',
                borderRadius: '4px',
                marginLeft: '4px !important',
                zIndex: '+2',
            },
            disabled: {
                color: `${style.colorPrimary3_40} !important`,
                pointerEvents: 'none'
            },
            inputField: {
                // padding: '4px 0 5px 0',
                marginBottom: '-1px',
            },
            focusBox: {
                '&:focus-visible': {
                    outline: 'none'
                }
            },
            Selectless: {
                userSelect: 'none',
            },
            root: {
                position: 'relative',
                display: 'inline-flex', flexDirection: 'row', flexWrap: 'nowrap',
                // overflow:'hidden',
                width: '100%',
                color: 'currentColor',
                padding: 0,//'4px 0 4px',
                border: 0,
                boxSizing: 'content-box',
                background: 'none',
                // height: '1.4375em',
                margin: 0,
                paddingLeft: '3px',
                alignItems: 'center',
                textAlign: 'left',
                justifyContent: 'flex-end',//'space-between',
                overflow: 'visible',
                '& .Mui-focused': {
                    '&:after': {
                        transform: 'scaleX(1)',
                        top: '0'
                    }
                },
                '& .MuiInput-underline': {
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        left: 0, bottom: 0, right: 0,
                        pointerEvents: 'none',
                        transform: 'scaleX(0)',
                        transformOrigin: 'left top',
                        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                        pointerEvents: 'none',

                    },
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        left: 0, bottom: '0px', right: 0,
                        pointerEvents: 'none',
                        borderBottomWidth: '1px',
                        borderBottomStyle: 'solid',
                        borderBottomColor: 'currentColor',
                        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                        pointerEvents: 'none',
                    },
                    '&:hover:before': {
                        borderBottomWidth: '2px',
                    }
                },
                '& .error': {
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        left: 0, top: 0, bottom: 0, right: 0,
                        borderBottom: `2px solid ${theme.palette.error.main}`,
                        pointerEvents: 'none',
                    }
                }
            },
            body: {
                // '& :after': {
                // content: '""',
                // position: 'absolute',
                // left: 0, top: 0, bottom: 0, right: 0,
                // pointerEvents: 'none',
                // }
            },

            datePart: {
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                wordBreak: 'keep-all',
            },
            maskSign: {
                width: '0.5rem',
                position: 'relative',
                display: 'inline-block',
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    left: '1px', top: 0, bottom: '-2px', right: '1px',
                    borderBottom: `1px solid ${style.colorPrimary1}`,
                    pointerEvents: 'none',
                }

            },
            active: {
                backgroundColor: 'rgb(200,232,250)'//'rgb(205,237,255)'
            },
            mask: { opacity: 0.5 },
            editMode: {
                // backgroundColor: '#FFF !important'
            },
            // notValid: { backgroundColor: 'rgb(250,232,200)' },
            // ...propscls,
        }
    ))

const DateInputPicker = forwardRef((props, ref) => {
    // console.log('props', props)
    const replaceSign = '_'
    const { value,
        onChange = null,
        cls: propscls,
        className,
        error,
        placeholder, mask,
        // onMouseOver = () => { }, onMouseOut = () => { },
        name = Date.now().toString(),
        sx,
        allowKeyboardControl = false,
        showDatePicker,
        open = false,
        views = null,
        disabled,
        errorMessage = null,
        readOnly,
        fullWidth = false,

        ...otherProps } = props

    // console.log('placeholder, mask,', placeholder, mask)
    // console.log('name', name, otherProps)
    // const { ...otherinputProps } = props?.inputProps
    const { endAdornment, ...otherInputProps } = props?.InputProps

    const datePartDivider = mask.match(/[.|\/|-]/)
    const [activeDatePart, setActiveDatePart] = useState(null)
    const prevActiveDatePart = usePrevious(activeDatePart)
    const dateParts = useRef()
    const [datePlaceholderParts, setDatePlaceholderParts] = useState([])
    const [date, setDate] = useState(null)
    const [redraw, setRedraw] = useState(false)
    const oldVal = useRef()
    const oldDate = useRef()
    const newVal = useRef()
    const reg = new RegExp(`([` + datePartDivider + `])`)



    const cls = useStyles()
    const init = (value) => {
        // console.log('init value=', value)
        //let phParts = null; //placeholder.split(datePartDivider)
        //let msParts = null; //mask.split(datePartDivider)

        const { phParts, msParts } = formDatePattern(views, datePartDivider, placeholder, mask);

        setDatePlaceholderParts(phParts)
        if (isValid(value)) {
            dateParts.current = phParts.map(x => format(value, x))
            newVal.current = null
            setDate(value)
        }
        else {
            // console.log('msParts', msParts)
            dateParts.current = msParts.map(x => x.replace(/./g, ''))
            setRedraw((x) => !x)

        }
    }

    useEffect(() => {
        const parsedDate = parse(value, placeholder, new Date())
        init(parsedDate)
        oldDate.current = value
    }, [value])

    useLayoutEffect(() => {
        // console.log('newVal.current###  ', newVal.current)
        init(date)
        oldDate.current = date
    }, [date/*, newVal.current*/])

    useLayoutEffect(() => {
        const f = (e) => editActivePart(e, activeDatePart)

        //перехваты событий на погружении события
        if (activeDatePart == null) {
            document.removeEventListener('click', disableActivePart, true)
            document.removeEventListener('keydown', f, true)
            // console.log('удаление событий ----------------------')
            return
        }

        document.addEventListener('click', disableActivePart, true)
        document.addEventListener('keydown', f, true)
        // console.log('перехваты событий на погружении события')

        return () => {
            document.removeEventListener('click', disableActivePart, true)
            document.removeEventListener('keydown', f, true)
            // onMouseOut()
            // console.log('удаление событий **************************')
        }
    }, [activeDatePart, redraw])

    const Escape = () => {
        init(oldDate.current)
        setActiveDatePart(null)
    }
    const submit = () => {
        setActiveDatePart(null)

        // console.log('dateParts.current', dateParts.current.join(''))
        if (dateParts.current.join('') == '') {
            onChange('')
            return
        }

        var formedDate = formDateFromParts(views, dateParts.current, datePartDivider, placeholder)

        const newDate = parse(formedDate /*dateParts.current.join(datePartDivider)*/, placeholder, new Date())
        if (oldDate.current?.toString() != newDate?.toString()) {
            // console.log('newDate?.toString()', newDate?.toString())
            if (isValid(newDate) || newDate == '') {
                if (onChange != null)
                    onChange(newDate)
                setActiveDatePart(null)
            }
            else {
                console.log('not Valid Date', dateParts.current, 'oldDate.current=', oldDate.current)
                init(oldDate.current)
            }
        }

    }
    const disableActivePart = () => {
        if (dateParts.current[activeDatePart])
            update(dateParts.current[activeDatePart].padStart(datePlaceholderParts[activeDatePart]?.length, 0))
        submit()
    }

    const editDatePart = (index) => {
        if (index == null)
            return

        if (!allowKeyboardControl) {
            if (showDatePicker != null) showDatePicker()
            return
        }

        let firstEmptyPartIndex = dateParts.current.findIndex(x => x == '')
        // let lastNonEmptyPartIndex = dateParts.current.findLastIndex(x => x != '')
        let activePartIndex = firstEmptyPartIndex == -1 ? index : (index < firstEmptyPartIndex ? index : firstEmptyPartIndex)

        oldVal.current = dateParts.current[activePartIndex]
        // console.log('activePartIndex', activePartIndex)
        setActiveDatePart(activePartIndex)
    }

    const update = (val) => {
        // console.log('`${val}0`, datePlaceholderParts[activeDatePart], new Date()', `${val}0`, datePlaceholderParts[activeDatePart], new Date())

        // console.log('val', val, dateParts.current)
        let ValidWithNil = isValid(parse(`${val}0`, datePlaceholderParts[activeDatePart], new Date("2023-01-31")))
        let Valid = isValid(parse(val, datePlaceholderParts[activeDatePart], new Date("2023-01-31")))

        if (Valid) {
            dateParts.current[activeDatePart] = val
        }

        if (val == '' || val == '0')
            dateParts.current[activeDatePart] = val

        newVal.current = null

        if (!ValidWithNil && Valid && activeDatePart < dateParts.current?.length - 1) {
            dateParts.current[activeDatePart] = val.padStart(datePlaceholderParts[activeDatePart]?.length, 0)
            editDatePart(activeDatePart + 1)
        }
        //console.log('dateParts', dateParts.current)
        setRedraw((x) => !x)
    }

    function findPrevTabStop(el) {
        var universe = document.querySelectorAll('[tabindex]')
        // console.log('universe', universe)
        var list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= "0" })
        var index = list.indexOf(el)
        return list[index - 1] || list[0]
    }

    function findNextTabStop(el) {
        var universe = document.querySelectorAll('[tabindex]')
        var list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= "0" })
        var index = list.indexOf(el)
        return list[index + 1] || list[0]
    }

    const editActivePart = (e, index = null) => {
        // console.log('e', e)
        // e.stopPropagation()
        e.preventDefault()
        if (index === null) return
        // console.log('dateParts.current**', dateParts.current)
        newVal.current = dateParts.current[index]
        switch (e.key) {
            case "Enter":
                submit()
                break
            case "ArrowLeft":
                if (activeDatePart > 0) {
                    update(newVal.current.padStart(datePlaceholderParts[activeDatePart]?.length, 0))
                    newVal.current = null
                    editDatePart(activeDatePart - 1)
                }
                break
            case "ArrowRight":
            case ".":
            case "Tab":
            case " ":

                if (e.shiftKey && e.key == "Tab" && activeDatePart == 0) {
                    if (ref.current) {
                        var prevEl = findPrevTabStop(ref.current)
                        submit()
                        prevEl.focus()
                    }
                }
                if ((e.shiftKey && activeDatePart > 0) || (!e.shiftKey && activeDatePart < dateParts.current?.length)) {
                    update(newVal.current.padStart(datePlaceholderParts[activeDatePart]?.length, 0))
                    newVal.current = null

                    if (e.shiftKey && activeDatePart > 0)
                        editDatePart(activeDatePart - 1)
                    if (!e.shiftKey && activeDatePart + 1 < dateParts.current?.length)
                        editDatePart(activeDatePart + 1)
                }
                if (!e.shiftKey && e.key == "Tab" && (activeDatePart == dateParts.current?.length - 1 || dateParts.current.join('') == '')) {
                    submit()
                    if (ref.current) {
                        var nextEl = findNextTabStop(ref.current)
                        nextEl.focus()
                    }
                }
                break
            case "Backspace":
                if (newVal.current?.length == 0 && activeDatePart > 0) {
                    editDatePart(activeDatePart - 1)
                }
                else {
                    // console.log('newVal.current___', newVal.current)
                    newVal.current = newVal.current?.slice(0, newVal.current?.length - 1)
                    // console.log('newVal.current', newVal.current)
                }
                update(newVal.current)
                break
            case "Delete":
                newVal.current = ''
                update(newVal.current)
                break
            case "z":
            case "Z":
                if (e.ctrlKey == true)
                    update(oldVal.current)
                break
            case "Escape":
                Escape()
                break
            case "0":
            case "1":
            case "2":
            case "3":
            case "4":
            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
                if (oldVal.current == dateParts.current[activeDatePart])
                    newVal.current = ''

                if (dateParts.current[index] != null && newVal.current?.length < datePlaceholderParts[index]?.length)
                    newVal.current = `${newVal.current}${e.key}`.slice(-datePlaceholderParts[index]?.length)
                update(newVal.current)
                break
        }
    }

    if (!dateParts.current) {
        return (<></>)
    }

    // function* output() {
    //     let i = 0
    //     const elements = dateParts.current?.map((x, index) => {
    //         let mask = []
    //         if (datePlaceholderParts[index]?.length - x?.length > 0) {
    //             for (let i = 0; i < datePlaceholderParts[index]?.length - x?.length; i++) {
    //                 mask.push('')
    //             }
    //         }
    //         // console.log('readonly', readOnly)
    //         return (
    //             <span
    //                 key={`${name}-${index}`}
    //                 className={[cls.datePart, (index == activeDatePart) ? cls.active : ''].join(' ')}
    //                 onClickCapture={readOnly ? () => { } : (e) => { /*e.preventDefault(); */editDatePart(index) }}
    //             >
    //                 {x}
    //                 {(datePlaceholderParts[index]?.length - x?.length > 0) &&
    //                     <span className={cls.mask}>
    //                         {mask.map((x, index) => <span key={`${name}-part-${index}`} className={cls.maskSign}></span>)}
    //                     </span>
    //                 }
    //             </span>
    //         )
    //     })
    //     const length = elements.length
    //     while (i < length)
    //         yield elements[i++]
    // }

    const Date_out = () => {
        let i = 0
        const elements = dateParts.current?.map((x, index) => {
            let mask = []
            if (datePlaceholderParts[index]?.length - x?.length > 0) {
                for (let i = 0; i < datePlaceholderParts[index]?.length - x?.length; i++) {
                    mask.push('')
                }
            }
            // console.log('readonly', readOnly)
            return (
                <React.Fragment key={`${name}-${index}`}>
                    <span
                        className={[cls.datePart, (index == activeDatePart) ? cls.active : ''].join(' ')}
                        onClickCapture={readOnly ? () => { } : (e) => { /*e.preventDefault(); */editDatePart(index) }}
                    >
                        {x}
                        {(datePlaceholderParts[index]?.length - x?.length > 0) &&
                            <span className={cls.mask}>
                                {mask.map((x, index) => <span key={`${name}-part-${index}`} className={cls.maskSign}></span>)}
                            </span>
                        }
                    </span>
                    {(index < dateParts.current?.length - 1) &&
                        <span key={`${name}-divider-${index}`} className={cls.mask}
                            onClickCapture={readOnly ? () => { } : (e) => { /*e.preventDefault(); */editDatePart(index) }}
                        >
                            {datePartDivider}
                        </span>}
                </React.Fragment>
            )
        })
        return elements
    }

    //console.log("----------className", className);
    // const data = output()
    // const data = date_out()
    return (
        <Tooltip
            disableInteractive
            title={errorMessage}
            placement="right-start"
            classes={{ tooltip: cls.errorPopper }}
        >
            <Box className={[
                // 'MuiInput-root', 
                cls.focusBox,
                className,
                cls.root,
            ].join(' ')} sx={{ ...sx }} /*tabIndex={-1} */
                ref={ref}
                onFocus={readOnly ? () => { /*console.log('readonly')*/ } : (e) => {
                    e.preventDefault();
                    if (e.target.matches(':focus-visible') && allowKeyboardControl) editDatePart(0)  // :focus-visible - Focused by keyboard
                }}
                tabIndex={readOnly || !allowKeyboardControl ? -1 : 0}
            // onMouseOver={onMouseOver}
            // onMouseOut={onMouseOut}
            >
                <Box
                    className={[
                        cls.inputField,
                        'MuiInput-root',
                        'MuiInput-underline',
                        fullWidth ? cls.fullWidth : '',
                        disabled ? cls.disabled : '',
                        !readOnly ? cls.Selectless : 'Mui-readOnly',
                        // activeDatePart != null ? cls.editMode : '',
                        error ? 'error' : '',
                        activeDatePart != null || open ? 'Mui-focused' : '',
                    ].join(' ')}

                >
                    <span style={{ width: '100%', whiteSpace: 'nowrap', wordBreak: 'keep-all' }}
                    >
                        {/* {data} */}
                        <Date_out />
                        {/* {!!dateParts.current?.length && mask.split(reg).map((x, index) => {
                        if (x.match(reg) != null)
                            return <span key={`${name}-divider-${index}`} className={cls.mask}>{x}</span>
                        return data.next().value
                    })} */}
                    </span>
                </Box>
                {!readOnly && (endAdornment ?? <></>)}
            </Box>
        </Tooltip>
    )
})

export default DateInputPicker