import React, { useRef, useState, useEffect, useMemo } from 'react'
// import * as signalR from '@microsoft/signalr'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

function useSignalR(url, listeners) {

    const [connection, setConnection] = useState(null)

    useEffect(() => {

        const newConnection = new HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(process.env.NODE_ENV == 'production' ? LogLevel.Information : LogLevel.Debug)
            .withAutomaticReconnect()
            .build()

        setConnection(newConnection)
        // console.log('newConnection established')
    }, [url])


    useEffect(() => {
        // console.log('ИЗМЕНИЛСЯ connection', connection)
        if (connection) {
            connection.start().then(
                () => {
                    if (listeners)
                        for (const [method, callback] of Object.entries(listeners)) {
                            // console.log('add', method)
                            connection.on(method, callback)
                        }
                }
            ).catch((error) => { console.log('Connection failed: ', error) });
        }
        return (() => {
            if (connection) {
                if (listeners)
                    for (const [method, callback] of Object.entries(listeners)) {
                        //  console.log('remove', method)
                        connection.off(method, callback)
                    }
                connection.stop()
            }
        })

    }, [connection])

    return connection
}

export default useSignalR