import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import Localization from '@Components/Localization';
import clsx from 'clsx';
import Chip from '@mui/material/Chip';
import Tooltip, { /*TooltipProps,*/ tooltipClasses } from '@mui/material/Tooltip';
import { Avatar, Fade } from '@mui/material';
import style from '@Styles/domrf-theme.module.scss'
import { getNameAbbreviation } from '../../Utilities';
import { isValid, parseISO } from 'date-fns';

const useStyles = makeStyles({

    //root: {
    //width: '100%'
    //},
    label: {
        textTransform: 'uppercase'
    },
    table: {
        fontSize: '0.8rem'
    },
    tooltip: {
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 350,
        }
    }
});

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 2) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i + 3)) & 0xFF;
        color += `00${value.toString(16)}`.slice(-2);
    }
    color += 'bd'
    /* eslint-enable no-bitwise */

    return color;
}

const ChipAuthor = ({ className = null, content, sx = {} }) => {

    const classes = useStyles();
    const { t /*, i18n*/ } = useTranslation();
    const [openTooltip, setOpenTooltip] = useState(false)
    // console.log('content', content)
    if (content == null)
        return <React.Fragment />;

    const getAbbreviation = (source: string) => {
        if (source == null || source.length === 0)
            return '?';

        return source.split(' ', 2).map(x => x[0]).join('');
    }

    let ValidateOldValueDate = null;
    if (content.oldValue != null) {
        if (typeof content.oldValue == 'string' && content.oldValue.length >= 10) //minimal length of iso-formatted date
        {
            const parsedRes = parseISO(content.oldValue)
            if (isValid(parsedRes))
                ValidateOldValueDate = Localization.FormatDateStringLocale(content.oldValue)
        }
    }

    const tooltip = (
        <table className={classes.table}>
            <tbody>
                <tr>
                    <td className="pe-2">{t('Author')}</td>
                    {/* <td>{content.userName}</td> */}
                    <td>{getNameAbbreviation(content.userName)}</td>
                </tr>
                {content?.createDate &&
                    <tr>
                        <td className="pe-2">{(content.oldValue || ValidateOldValueDate) ? t('Changed') : t('Setup')}</td>
                        <td>{Localization.FormatDateTimeStringLocale(content.createDate)}</td>
                    </tr>
                }
                {(content?.oldValue || ValidateOldValueDate) &&
                    <tr>
                        <td className="pe-2">{t('OldValue')}</td>
                        <td>{ValidateOldValueDate != null ? ValidateOldValueDate : content.oldValue?.toString()}</td>
                    </tr>
                }
            </tbody>
        </table>
    );

    const handleTooltipClose = () => {
        setOpenTooltip(false)
        removeListeners()
    }

    const addListeners = () => {
        // console.log('addListeners')
        document.body.addEventListener('wheel', handleTooltipClose, false);
        document.body.addEventListener('touchstart', handleTooltipClose, false);
    }

    const removeListeners = () => {
        // console.log('removeListeners')
        document.body.removeEventListener('wheel', handleTooltipClose, false);
        document.body.removeEventListener('touchstart', handleTooltipClose, false);
    }

    const handleTooltipOpen = () => {
        addListeners()
        setOpenTooltip(true)
    }

    return (
        <>
            {/* {console.log('content', content)} */}
            {!!content?.userName &&
                <Tooltip disableInteractive
                    title={tooltip}
                    classes={{ popper: classes.tooltip }}
                    open={openTooltip}
                    placement='right'
                    onOpen={handleTooltipOpen} onClose={handleTooltipClose}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: { appear: 0, enter: 300, exit: 0 } }}
                >
                    <Avatar
                        sx={{
                            bgcolor: 'transparent',
                            // bgcolor: stringToColor(content.userName),
                            border: `solid 1px ${stringToColor(content.userName)}`,
                            // filter: 'saturate(0.8)',
                            width: '25px',
                            height: '25px',
                            fontSize: '0.8rem',
                            textTransform: 'uppercase',
                            ...sx
                        }}>
                        <span style={{/*color:style.colorPrimary2_80, */ color: stringToColor(content.userName)/*, filter: 'invert(1) grayscale(1) contrast(8)'*/ }}>
                            {getAbbreviation(content.userName)}
                        </span>
                    </Avatar>
                    {/* <Chip
                        sx={{fontSize: '0.75rem'}}
                        size="small"
                        className={clsx(className, classes.label)}
                        color="secondary"
                        label={getAbbreviation(content.userName)}
                    /> */}
                </Tooltip>
            }
        </>
    )
}

export default ChipAuthor;