import React, { useState, useEffect } from 'react';
import Localization from "./components/Localization";
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
//import DateFnsUtils from '@date-io/date-fns';

import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Locale } from 'date-fns';

const getActualLocalization = (): Locale => {
    return Localization.locale;
}

export const LocalizationContext = React.createContext({ updateLocalization: () => { } });

interface IProps {
    children: any;
}

interface IState {
    localization: any;
}

const LocalizationWrapper = ({ children }) =>
{
    const [localization, setLocalization] = useState(getActualLocalization());

    const updateLocalization = () => {
        setLocalization(getActualLocalization());
    }

    useEffect(() => {
        if (Localization?.locale?.code != 'ru') { //принудительно переключаем на русский
            // console.log('Localization.locale', Localization.locale)
            Localization.setLocalization('ru')
        }
    }, []);

    return (
        <LocalizationContext.Provider value={{ updateLocalization: updateLocalization }} >
            {/* {console.log('this.state.localization', this.state.localization)} */}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localization} locale={localization}>
                {children}
            </LocalizationProvider>
        </LocalizationContext.Provider>
    );
}

export default LocalizationWrapper;