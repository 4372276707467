import { hexToRgbA, roundDown, roundUp, toUnixDate } from '@Base/Utilities'
import style from '@Styles/domrf-theme.module.scss';
import Localization from '@Base/components/Localization'
import { Box, Button, IconButton, Tooltip, alpha, debounce } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import { t } from 'i18next'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { useSelector, useDispatch } from 'react-redux'
import useMobileDetect from '@Base/components/hook/useMobileDetect'
import { HighchartsReact } from 'highcharts-react-official'
import Highcharts, { swapXY } from 'highcharts'
import SpinnerBars from '@Base/components/shared/SpinnerBars';
import variwide from 'highcharts/modules/variwide';
import { ChatTwoTone, Help, Replay, VerticalAlignBottom } from '@mui/icons-material';
import { id } from 'date-fns/locale';
import { getTooltip } from '@Base/components/shared/CustomTooltip';
import { useNewDispatch, useNewSelector } from '@Base/index';
import { clearValue, setConventionData, setModelKeyrate } from '@Base/newStore/conventionSlice';
import { axisGridLineColor, axisLabelsColor, axisLineColor, axisTitleColor, plotBorderColor } from '@Base/components/constants/charts';

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: "DINPro-Bold",
    fontWeight: "700",
    // letterSpacing: '2px',
    // fontSize: '1.1rem !important',
    letterSpacing: '1.5px',
    fontSize: '0.8rem !important',
    '& *': {
        fontSize: '0.8rem !important',
    },
    // color: style.colorPrimary3,
    padding: '0 0.5rem 0 9px',
    marginRight: '0.5rem',
    backgroundColor: 'rgba(183, 217, 139, 0.23)',
    borderColor: 'rgb(61,149,109)',
    color: 'rgb(61,149,109)',
    '&:hover': {
        borderColor: 'rgb(61,149,109) !important',
        backgroundColor: 'rgba(183, 217, 139, 0.4) !important',
        // borderColor: `${style.colorPrimary2} !important`,
        // backgroundColor: 'rgba(183, 217, 139, 0.23) !important',
        // color: `rgb(0,176,80) !important`,
    }
}))

const useStyles = makeStyles({
    label: {
        color: 'red'
    },
    runCalculationButton: {
        minWidth: '150px'
    },
    resetButton: {
        backgroundColor: `${alpha(style.colorPrimary3, 0.2)}`,
        borderColor: `${style.colorPrimary3_60}`,
        color: `${style.colorPrimary1}`,
        '&:hover': {
            borderColor: `${style.colorPrimary3} !important`,
            backgroundColor: `${alpha(style.colorPrimary3, 0.4)} !important`,
            // color: `${style.co   lorPrimary1} !important`,
        }
    },
    mr05: {
        marginRight: '0.5rem',
    },
    grayed: {
        filter: 'grayscale(1)',
        opacity: 0.5,
    },
    decreaseFontSize: {
        fontSize: '0.8em',
    },
    root: {
        flexGrow: 1,
        position: 'relative',
        boxSizing: 'border-box',
    },
    rootData: {
        position: 'absolute',
        top: 0, left: 0, bottom: 0, right: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: '0.5rem',
        alignItems: 'center',
        '&>*': { width: '100%' },
    },
    buttonsRoot: {
        overflow: 'visible',
        minHeight: '23px',
        height: '23px',
        // width: '150px',
        // marginBottom: '55px',
    },
    buttonsBox: {
        position: 'absolute',
        right: 0, bottom: 0,
        // width: '150px',
        // margin: 'auto',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonsContaner: {
        display: 'flex',
        flexDirection: 'row',
        // margin: 'auto',
        textAlign: 'center',
        '&:has($buttonBox:is(.active):hover)': {
            '& $buttonBox:not(:hover) $button': {
                filter: 'grayscale(1)',
                opacity: 0.5,
                outline: `solid 1px ${style.colorPrimary3}`,
                backgroundColor: `${alpha(style.colorPrimary3, 0.5)} !important`,
                '&>*': {
                    color: `white !important`,
                },
            }
        }
    },

    disabled: {
        '& $button': {
            filter: 'grayscale(1)',
            opacity: 0.5,
            outline: `solid 1px ${style.colorPrimary3}`,
            backgroundColor: `${alpha(style.colorPrimary3, 0.5)} !important`,
            '&>*': {
                color: `white !important`,
            },
            cursor: 'auto !important'
        }
    },
    buttonBox: {
        padding: '0px 4px',
        fontSize: '0.9rem',
    },
    button: {
        '&>*': {
            filter: 'grayscale(1) brightness(0.8) invert(1) contrast(50)'
        },
        fontFamily: 'DINPro',
        borderRadius: '0.2rem',
        overflow: 'hidden',
        padding: '0.2rem',
        minWidth: '120px',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        fontWeight: 100,
        cursor: 'pointer',
    },
    chartRoot: {
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    functionBlock: {
        height: '1.5rem',
        // marginLeft: '40px',
        marginTop: '0.3rem',
        fontSize: '1.3rem',
        textAlign: 'center',
        '& sub': {
            fontSize: '0.8rem'
        }
        // zIndex: 999,
        // position: 'absolute',
        // top: '20px',
        // margin: '0 auto',
    },
    functionText: {
        lineHeight: '1.3rem',
    },
    '& sub': {
        fontSize: '0.7rem',
    }
})

const KeyrateDialog = forwardRef(({ editMode, hidePanel, ...otherProps }, ref) => {

    variwide(Highcharts)

    const cls = useStyles();
    const chartRef = useRef();
    const [series, setSeries] = useState([])
    const [chartLabels, setChartLabels] = useState([])
    const [chartLabelsPath, setChartLabelsPath] = useState([])

    const dispatch = useDispatch()
    const newDispatch = useNewDispatch()
    // const { selectedBond = null,
    //     keyRateInteractiveGraph = null,
    //     calculationParameters = null,
    //     sCurveGraph = null,
    //     useModelKeyrate = false,
    //     // modelKeyrate = null,
    //     // keyrateWasChanged = false,
    //     ...otherStore } = useSelector((state) => state.convention)

    const { selectedBond = null,
        keyRateInteractiveGraph = null,
        calculationParameters = null,
        sCurveGraph = null,
        useModelKeyrate = false,
        // modelKeyrate = null,
        // keyrateWasChanged = false,
        ...otherStore } = useNewSelector((state) => state.newConvention)

    const [keyrateWasChanged, setKeyrateWasChanged] = useState(false)
    const clearModelKeyrate = () => {
        // console.log('reset modelKeyrate')
        // actionConvention.setData({ useModelKeyrate: false })(dispatch)
        // newDispatch(setConventionData({ useModelKeyrate: false }))
        // actionConvention.clearValue('modelKeyrate')(dispatch)
        // newDispatch(clearValue('modelKeyrate'))
        // actionConvention.setModelKeyrate(keyRateInteractiveGraph?.model)(dispatch)
        fillSeriesData()
        // updateChartData()
        // actionConvention.setData({ keyrateWasChanged: !keyrateWasChanged })(dispatch)
    }

    const runCalculateWithModelKeyrate = () => {
        // console.log('modelKeyrate', modelKeyrate)
        // let prevVal = null
        const barsSeries = chartRef?.current?.chart?.series[1]

        const res = barsSeries?.data?.map((el, index) => {
            // console.log('el.value', el.value, modelKeyrate[index]?.value)
            // if (/*modelKeyrate[index]?.date == dateTime && */modelKeyrate[index]?.value != prevVal) {
            // prevVal = modelKeyrate[index]?.value 
            return ({ ...keyRateInteractiveGraph?.model[index], value: el?.y })
            // }
            // return null
        }) //?.filter(el => el != null)

        // console.log('res==================================', res)
        // actionConvention.setModelKeyrate(res)(dispatch)
        newDispatch(setModelKeyrate(res))
        // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! actionConvention.setModelKeyrate(res)', res)
        // actionConvention.setData({ useModelKeyrate: true, keyrateWasChanged: false })(dispatch)
        newDispatch(setConventionData({ useModelKeyrate: true, keyrateWasChanged: false }))
        // setKeyrateWasChanged(false)

        return true
    }

    useImperativeHandle(ref, () => {
        // console.log('renew ImpHandle', chartData?.steps)
        return {
            chartHelper: chartHelper,
            chartHelperVisibleFlag: !editMode && Boolean(chartLabels?.length)
        }
    }, [series, chartLabels, editMode])

    const regular_marker = {
        enabled: true,
        radius: 5,
        position: 'left',
        fillColor: 'white',
        lineWidth: 2,
        lineColor: style.colorPrimary1,
        zIndex: 200,
    }
    const hovered_marker = {
        enabled: true,
        radius: 5,
        position: 'left',
        fillColor: 'white',
        lineWidth: 2,
        lineColor: style.colorPrimary2,
        zIndex: 400,
    }
    const dragged_marker = {
        enabled: true,
        position: 'left',
        radius: 5,
        fillColor: style.colorPrimary2,
        lineWidth: 2,
        lineColor: style.colorPrimary2,
        zIndex: 400,
    }

    const fillSeriesData = () => {
        // if (modelKeyrate == null && keyRateInteractiveGraph?.model != null) {
        //     console.log('init modelKeyrate')
        //     actionConvention.setModelKeyrate(keyRateInteractiveGraph?.model)(dispatch)
        // }
        // console.log('keyRateInteractiveGraph', keyRateInteractiveGraph)
        // console.log('fill SeriesData')
        const model_series_data_bars = []
        const dashed_model_series_data_points = []
        const solid_model_series_data_points = []
        let cbForecast_data = []
        let cbForecastSmooth_data = []
        let swapForecast_data = []
        let historical_series_data = []
        let current_series_data_points = []

        keyRateInteractiveGraph?.model?.forEach((item, index) => {
            model_series_data_bars.push([new Date(item.start).getTime(), item.value, (new Date(item.end)?.getTime() - new Date(item.start)?.getTime())])
            solid_model_series_data_points.push([new Date(item.start).getTime(), item.value], [new Date(item.end).getTime(), item.value], [new Date(item.end).getTime(), null])
            dashed_model_series_data_points.push([new Date(item.start).getTime(), item.value], [new Date(item.start).getTime(), null], [new Date(item.end).getTime(), item.value])
        })
        // console.log('solid_model_series_data_points', solid_model_series_data_points)
        let dateTime = null
        current_series_data_points = Object.values(keyRateInteractiveGraph?.currentForecast?.date).map((date, index) => {
            dateTime = new Date(date)
            return [dateTime.getTime(), keyRateInteractiveGraph?.currentForecast?.value[index]]
        })

        // keyRateInteractiveGraph?.currentForecast?.forEach(item => {
        //     dateTime = new Date(item.date)
        //     current_series_data_points.push([dateTime.getTime(), item.value])
        // })

        if (keyRateInteractiveGraph?.history)
            historical_series_data = Object.values(keyRateInteractiveGraph?.history?.date).map((date, index) => {
                dateTime = new Date(date)
                return [dateTime.getTime(), keyRateInteractiveGraph?.history?.value[index]]
            })

        // keyRateInteractiveGraph?.history?.forEach(item => {
        //     dateTime = new Date(item.date)
        //     historical_series_data.push([dateTime.getTime(), item.value])
        // })

        if (keyRateInteractiveGraph?.swapForecast)
            swapForecast_data = Object.values(keyRateInteractiveGraph?.swapForecast?.date).map((date, index) => {
                dateTime = new Date(date)
                return [dateTime.getTime(), keyRateInteractiveGraph?.swapForecast?.value[index]]
            })

        // keyRateInteractiveGraph?.swapForecast?.forEach(item => {
        //     dateTime = new Date(item.date)
        //     swapForecast_data.push([dateTime.getTime(), item.value])
        // })

        if (keyRateInteractiveGraph?.cbForecast)
            cbForecast_data = Object.values(keyRateInteractiveGraph?.cbForecast?.date).map((date, index) => {
                dateTime = new Date(date)
                return [dateTime.getTime(), keyRateInteractiveGraph?.cbForecast?.value[index]]
            })

        // keyRateInteractiveGraph?.cbForecast?.forEach(item => {
        //     dateTime = new Date(item.date)
        //     cbForecast_data.push([dateTime.getTime(), item.value])
        // })

        if (keyRateInteractiveGraph?.cbForecastSmooth)
            cbForecastSmooth_data = Object.values(keyRateInteractiveGraph?.cbForecastSmooth?.date).map((date, index) => {
                dateTime = new Date(date)
                return [dateTime.getTime(), keyRateInteractiveGraph?.cbForecastSmooth?.value[index]]
            })
        // keyRateInteractiveGraph?.cbForecastSmooth?.forEach(item => {
        //     dateTime = new Date(item.date)
        //     cbForecastSmooth_data.push([dateTime.getTime(), item.value])
        // })

        if (current_series_data_points[0] !== null)
            historical_series_data.push(current_series_data_points[0])

        // let cbForecastArea_data = []
        // if (swapForecast_data?.length && cbForecast_data?.length) {
        //     cbForecastArea_data = swapForecast_data?.map((swapForecast, index) => {
        //         return ({ x: swapForecast[0], low: swapForecast[1], high: cbForecast_data[index][1] })//, lowTitle, highTitle,
        //     })
        // }

        setSeries([
            {
                name: t('convention2.historicalDataLegend'),
                id: `historicalData`,
                showInLegend: true,
                legendIndex: 1,
                type: 'line',
                step: 'left',
                stickyTracking: false,
                pointPadding: 0,
                xAxis: 0,
                yAxis: 0,
                lineWidth: 2,
                color: style.colorPrimary1,
                // states: {
                //     hover: { enabled: false, },
                // },
                marker: {
                    enabled: false,
                },
                zIndex: 60,
                data: historical_series_data,
                visible: true,
            },
            {
                name: 'modelDataBars',
                id: `modelDataBars`,
                showInLegend: false,
                type: 'variwide',
                xAxis: 0,
                yAxis: 0,
                borderColor: 'transparent',
                color: 'transparent',
                cursor: 'pointer',
                // states: {
                //     hover: {
                //         enabled: false,
                //     },
                // },
                zIndex: 0,
                data: model_series_data_bars,
                visible: true,
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    color: style.colorPrimary1,
                    align: 'center',
                    formatter: function (this, options) {
                        var point = this.point;
                        var series = point.series;
                        var isLastPoint = (point.index === series.data.length - 1);
                        const active = `color:${style.colorPrimary2}`
                        // console.log('point.state', point?.hover)
                        return (`<div style="text-align:${options?.align};${point?.hover ? active : ''}">${new Date(this.x)?.getFullYear()}${isLastPoint ? '+' : ''}<br>${Localization.FormatNumber(this.y, 2, 2)}%</div>`)
                    },
                    style: {
                        fontSize: '16px',
                        fontWeight: 100,
                    }
                }
            },
            {
                name: 'modelDataLine',
                id: `modelDataLine`,
                showInLegend: false,
                type: 'line',
                step: 'left',
                xAxis: 0,
                yAxis: 0,
                lineWidth: 1.9,
                color: style.colorPrimary1,
                // states: {
                //     hover: {
                //         enabled: false,
                //     },
                // },
                marker: regular_marker,
                zIndex: 100,
                data: solid_model_series_data_points,
                visible: true,
                connectNulls: false
            },
            {
                name: 'hoveredLineSerment',
                id: `hoveredLineSerment`,
                showInLegend: false,
                type: 'line',
                xAxis: 0,
                yAxis: 0,
                lineWidth: 2.3,
                color: style.colorPrimary2,
                // states: {
                //     hover: {
                //         enabled: false,
                //     },
                // },
                marker: hovered_marker,
                zIndex: 400,
                data: [],
                visible: true,
            },
            {
                name: 'modelDataLineDashed',
                id: `modelDataLineDashed`,
                showInLegend: false,
                type: 'line',
                xAxis: 0,
                yAxis: 0,
                lineWidth: 1,
                dashStyle: 'dash',
                color: alpha(style.colorPrimary1, 1),
                // states: {
                //     hover: {
                //         enabled: false,
                //     },
                // },
                zIndex: 65,
                data: dashed_model_series_data_points,
                visible: true,
                connectNulls: false
            },
            {
                name: 'crosshairBar',
                id: `crosshairBar`,
                showInLegend: false,
                type: 'variwide',
                xAxis: 0,
                yAxis: 0,
                borderColor: 'transparent',
                color: 'transparent', //alpha(style.colorPrimary2, 0.07),
                cursor: 'pointer',
                // states: {
                //     hover: {
                //         enabled: false,
                //     },
                // },
                zIndex: 660,
                data: [],
                visible: true,
            },
            {
                name: t('convention2.currentForecastLegend'),
                id: `currentForecast`,
                showInLegend: true,
                legendIndex: 5,
                type: 'line',
                step: 'left',
                stickyTracking: false,
                pointPadding: 0,
                xAxis: 0,
                yAxis: 0,
                lineWidth: 2,
                color: style.colorPrimary2,
                // states: {
                //     hover: { enabled: false, },
                // },
                // marker: {
                //     symbol: 'circle', // Set the marker symbol to 'circle'
                //     radius: 5,
                //     enabled: false,
                //     states: {
                //         hover: {
                //             enabled: true,
                //         }
                //     },
                // },
                zIndex: 55,
                data: current_series_data_points,
                visible: true,
            },
            {
                name: `${t('convention2.swapForecastLegend')} ${Localization.FormatDateStringLocale(calculationParameters['keyRateSwapForecastDate'])}`,
                id: `swapForecast`,
                showInLegend: true,
                legendIndex: 3,
                type: 'line',
                step: 'left',
                stickyTracking: false,
                pointPadding: 0,
                xAxis: 0,
                yAxis: 0,
                lineWidth: 1.5,
                dashStyle: 'solid',
                color: style.colorPrimary3_40,
                // states: {
                //     hover: { enabled: false, },
                // },
                marker: {
                    enabled: false,
                },
                zIndex: 50,
                data: swapForecast_data,
                visible: true,
            },
            {
                name: `${t('convention2.cbForecastLegend')} ${Localization.FormatDateStringLocale(calculationParameters['currentCBForecastDate'])}`,
                id: `cbForecast`,
                showInLegend: true,
                legendIndex: 4,
                type: 'line',
                step: 'left',
                dashStyle: 'dash',
                stickyTracking: false,
                pointPadding: 0,
                xAxis: 0,
                yAxis: 0,
                lineWidth: 1.5,
                color: 'rgb(176,229,242)',
                // color: style.colorPrimary3_40,
                // states: {
                //     hover: { enabled: false, },
                // },
                marker: {
                    enabled: false,
                },
                zIndex: 40,
                data: cbForecast_data,
                visible: true,
            },
            {
                name: `${t('convention2.cbForecastSmoothLegend')}`,// ${Localization.FormatDateStringLocale(calculationParameters['currentCBForecastDate'])}`,
                id: `cbForecastSmooth`,
                showInLegend: true,
                legendIndex: 2,
                type: 'line',
                step: 'left',
                dashStyle: 'solid',
                stickyTracking: false,
                pointPadding: 0,
                xAxis: 0,
                yAxis: 0,
                lineWidth: 1.5,
                color: 'rgb(78,195,224)',
                // states: {
                //     hover: { enabled: false, },
                // },
                marker: {
                    enabled: false,
                },
                zIndex: 45,
                data: cbForecastSmooth_data,
                visible: true,
            },
            // {
            //     name: 'cbForecastArea',
            //     id: 'cbForecastArea',
            //     type: 'arearange',
            //     step: 'left',
            //     animation: false,
            //     zIndex: 1,
            //     data: cbForecastArea_data,
            //     yAxis: 0,
            //     xAxis: 0,
            //     lineWidth: 0,
            //     // lineColor: style.colorPrimary1_40,
            //     fillColor: {
            //         linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //         stops: [
            //             [0, alpha(style.colorPrimary3, 0.07)], // Start color
            //             [1, alpha(style.colorPrimary3, 0.07)] // End color
            //         ]
            //     },
            //     fillOpacity: 0.3,
            //     showInLegend: false,
            //     stacking: null,
            //     // visible: false,
            //     marker: {
            //         enabled: false,
            //     },
            //     states: {
            //         hover: {
            //             enabled: false
            //         },
            //         inactive: {
            //             enabled: false
            //         },
            //         normal: {
            //             enabled: false
            //         },
            //         select: {
            //             enabled: false
            //         }
            //     },
            //     // events: {
            //     //     legendItemClick: function () {
            //     //         return false
            //     //     }
            //     // }
            // },
        ])
        // actionConvention.setData({ keyrateWasChanged: false })(dispatch)
        setKeyrateWasChanged(false)
    }

    useLayoutEffect(() => {
        if (keyRateInteractiveGraph?.model == null) {
            setSeries([])
            return
        }

        fillSeriesData()
    }, [selectedBond?.isin, keyRateInteractiveGraph?.model])

    const yAxisPlotLine = {
        color: alpha(style.colorPrimary3, 0.05),
        dashStyle: 'solid',
        width: 1,
        zIndex: 3,
    }

    const plotLines = {
        color: style.colorPrimary3_40,
        dashStyle: 'Dash',
        width: 1,
        value: Date.now(),
        zIndex: 0,
        label: {
            rotation: 0,
            align: 'left',
            verticalAlign: 'top',
            x: 3,
            y: 18,
            style: {
                zIndex: 0,
                color: style.colorPrimary3,
                // fontWeight: 'bolder',
                fontSize: '1.2rem'
            },
            text: '',
        }
    }

    const [chartOptions, setChartOptions] = useState({
        exporting: {
            enabled: false,
            // filename: ``,
            // scale: 2,
            // chartOptions: {
            //     chart: {
            //         width: 1200,
            //         height: 665,
            //         marginLeft: 50, // Keep all charts left aligned
            //         marginRight: 50,
            //         padding: 0,
            //         plotBorderWidth: 0,
            //     },
            //     plotOptions: {
            //         line: {
            //             marker: {
            //                 enabled: false
            //             }
            //         }
            //     },
            //     xAxis: {
            //         title: {
            //             enabled: true
            //         }
            //     },
            // },
        },
        chart: {
            animation: false,
            marginLeft: 40, // Keep all charts left aligned
            // marginRight: 50,
            // height: 300,
            //plotAreaHeight: 290,
            padding: 0,
            // plotBorderWidth:10,
            // marginBottom: 45,
            // spacingBottom: 13,
            plotBorderWidth: 1,
            plotBorderColor: plotBorderColor,
            events: {
                // render: (e) => {},
                // load: (e) => {
                //     e.target?.callback()
                // }
            },
        },

        title: {
            text: ''
        },

        credits: {
            enabled: false
        },

        plotOptions: {
            labels: {
                useHTML: true
            },
            series: {
                pointPlacement: 'on',
                animation: false,
                states: {
                    inactive: {
                        opacity: 0,
                        enabled: false,
                    },
                    hover: {
                        enabled: false,
                        halo: {
                            size: 0
                        },
                        marker: {
                            radius: 0 // Указать желаемый радиус
                        }
                    }
                },
                marker: {
                    symbol: 'circle', // Set the marker symbol to 'circle'
                    radius: 0,
                },
                events: {
                    legendItemClick: function () {
                        return false; // Prevent the default legendItemClick behavior
                    }
                }
            },
        },
        legend: {
            useHTML: true,
            align: 'left',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            margin: 0,
            y: 17,
            itemHoverStyle: {
                textDecoration: 'none',
            },
            itemHiddenStyle: {
                color: style.colorPrimary1,
                textDecoration: 'none',
            },
            itemStyle: {
                fontSize: '12px',
                fontWeight: 'bold',
                fill: style.colorPrimary1,
                color: style.colorPrimary1,
                paddingLeft: 0,
                paddingRight: 0,
                cursor: 'default',
                textDecoration: 'none'
                // color: style.colorPrimary3// Задать цвет легенды
            },
            itemEvents: {
                mouseOver: function () {
                    return false; // Отключить подсветку при наведении на легенду
                }
            }
        },
        xAxis: [{
            type: 'datetime',
            visible: true,
            // categories:[],
            crosshair: false,
            // {
            //     enabled: false,
            //     color: style.colorPrimary2,
            //     snap: false,
            //     width: 1,
            // },
            gridLineWidth: 1,
            gridLineColor: axisGridLineColor,
            lineWidth: 1,
            lineColor: axisLineColor,
            title: {
                enabled: false,
            },
            labels: {
                style: {
                    lineHeight: '0.8rem',
                    fontSize: '0.8rem',
                    color: axisLabelsColor,
                },
                y: 17,
                // style: {
                //     lineHeight: '0.8rem',
                //     fontSize: '0.8rem',
                //     color: style.colorPrimary3
                // }
                formatter: function () {
                    // console.log('first')
                    if (this.value == null)
                        return ''
                    const date = new Date(this.value)
                    // console.log('date', date)
                    const y = date.getFullYear()
                    const m = date?.getMonth()
                    const d = date?.getDate()
                    if (m == 0 && d == 1) {
                        return y
                    }

                    return null
                },
            },
            tickPositioner: function (min, max) {
                const startYear = new Date(min).getFullYear() + 1
                // console.log('startYear', startYear)
                const endYear = new Date(max).getFullYear()
                // console.log('endYear', endYear)
                var positions = Array.from({ length: endYear - startYear + 1 }, (x, i) => new Date(min).setFullYear(startYear + i, 0, 1))
                // console.log('positions', positions)
                return positions;
            },
            tickWidth: 0,
            // startOnTick: true,
            // endOnTick: true,
            minPadding: 0,
            maxPadding: 0,
            plotLines: [

            ]
        }],
        yAxis: [{
            crosshair: false,
            // startOnTick: true,
            // endOnTick: true,
            gridLineColor: axisGridLineColor,
            gridLineWidth: 1,
            lineWidth: 1,
            lineColor: axisLineColor,
            min: 0,
            max: 25,
            tickInterval: 5,
            // tickAmount: 6,
            alignTicks: true,
            title: {
                text: t('convention2.keyrateValue'),
                // y: -85,
                x: 5,
                style: {
                    fontSize: '1rem',
                    color: axisTitleColor,
                }
            },
            labels: {
                x: -5,
                style: {
                    lineHeight: '0.8rem',
                    fontSize: '0.8rem',
                    color: axisLabelsColor,
                }
            },
            // offset: 100, //for hide
            tickWidth: 0,
            plotLines: [

            ]
        }],

        tooltip: {
            // followPointer: false,
            enabled: true,
            animation: false,
            shape: "square",
            hideDelay: 0,
            shared: true,
            useHTML: true,
            style: {
                fontSize: '1rem'
            },
            positioner: function (labelWidth, labelHeight, point) {
                // console.log(this.chart, this.chart.plotWidth - labelWidth - 3)
                return {
                    x: /*this.chart.plotLeft + 7,/*/this.chart.plotBox.x + this.chart.plotWidth - labelWidth - 9,
                    y: this.chart.plotTop + 9//this.chart.plotHeight - labelHeight - 3
                };
            },
            formatter: function () {
                // console.log('this.points', this.points)
                if (this?.points == null || !this?.points?.length) return

                const getPointValue = (point) => {
                    // console.log('point?.series?.userOptions?.id', point?.series?.userOptions?.id)
                    switch (point?.series?.userOptions?.id) {
                        // case 'modelDataBars':
                        //     return ({ name: `${t('convention2.keyrateValue')}`, value: Localization.FormatNumber(point.y, 2, 2), sortOrder: 1 })
                        case 'historicalData':
                            return ({ type: 1, name: ``, date: Localization.FormatDateLocale(point.x), value: Localization.FormatNumber(point.y, 2, 2), sortOrder: 1 })

                        case 'currentForecast':
                            return ({ name: `${t('convention2.currentForecastValue')} ${Localization.FormatDateLocale(point.x)}`, value: Localization.FormatNumber(point.y, 2, 2), sortOrder: 4 })
                        case 'swapForecast':
                            return ({ name: `${t('convention2.swapForecastValue')} ${Localization.FormatDateLocale(point.x)}`, value: Localization.FormatNumber(point.y, 2, 2), sortOrder: 3 })
                        case 'cbForecastSmooth':
                            return ({ name: `${t('convention2.cbForecastSmoothValue')} ${Localization.FormatDateLocale(point.x)}`/*${t('convention2.fromDate')} ${Localization.FormatDateStringLocale(calculationParameters['currentCBForecastDate'])}`*/, value: Localization.FormatNumber(point.y, 2, 2), sortOrder: 2 })
                        case 'cbForecast':
                            return ({ name: `${t('convention2.cbForecastValue')} ${new Date(point.x).getFullYear()} ${t('convention2.fromDate')} ${Localization.FormatDateStringLocale(calculationParameters['currentCBForecastDate'])}`, value: Localization.FormatNumber(point.y, 2, 2), sortOrder: 1 })

                        default:
                            return //({ name: point?.series?.userOptions?.id, value: point.y })
                    }

                }
                // const date = new Date(this.x)
                // const y = date.getFullYear()
                // const m = date?.getMonth() + 1
                // var points = [{ name: t('convention2.keyrateDate'), value: `${`${m}`.padStart(2, '0')}-${y}` },]

                var points = []//{ name: t('convention2.keyrateData'), date: '', value: `` },]

                this.points.forEach(point => {
                    const val = getPointValue(point)
                    if (val) points.push(val)
                })


                return points.sort((a, b) => a?.sortOrder < b?.sortOrder ? -1 : a?.sortOrder > b?.sortOrder ? 1 : 0)
                    // .filter((item, index) => {
                    //     if (index == 0 || (index != 0 && points[index - 1]?.sortOrder != item?.sortOrder))
                    //         return item
                    // })
                    .reduce(function (s, point) {
                        switch (point?.type) {
                            case 1:
                                // Дата заседания ЦБ РФ" (в формате 01-01-1999) и "КС по итогам заседания, % год."
                                return s + `<div style='display:flex;flex-direction:column;justify-content:space-between;'>
                                            <div style='display:flex;flex-direction:row;justify-content:space-between;'>${t('convention2.cbMeetingDate')}<div style='margin-left:1rem;min-width:50px;text-align:right;font-weight:bold;'>${point.date}</div></div>
                                            <div style='display:flex;flex-direction:row;justify-content:space-between;'>${t('convention2.establishedKeyrate')}<div style='margin-left:1rem;width:50px;text-align:right;font-weight:bold;'>${point.value}</div></div>
                                      </div>`
                            default:
                                return s + `<div style='display:flex;flex-direction:row;justify-content:space-between;'>
                                      <div>${point.name}${t('convention2.ppy')}</div>
                                            <div style='margin-left:1rem;min-width:3rem;text-align:right;font-weight:bold;'>${point.value}</div>
                                      </div>`
                        }
                    }, '')
            },

        },

        series: []
    })

    useLayoutEffect(() => {
        // console.log('update ChartData')
        updateChartData()
    }, [series, editMode])


    const updateChartData = () => {
        if (!series?.length)
            return

        hideChartHelper()
        // console.log('series', series)
        const dateArr = series?.map(s => s?.data?.map(d => d[0]))?.flat()
        // console.log('dateArr', dateArr)
        const histArr = []// keyRateInteractiveGraph?.history?.map(i => new Date(i?.date))
        // const dateArr = keyRateInteractiveGraph?.model?.map(i => new Date(i?.end)) //series?.map(serie => serie?.data?.map(i => Localization.FormatDateLocale(i[0]))).flat()

        const max = Math.max.apply(null, [...dateArr/*, ...histArr*/]?.filter(i => i != null))
        const min = Math.min.apply(null, [...dateArr/*, ...histArr*/]?.filter(i => i != null))
        const newSeries = [...series]
        // console.log('updatechart', newSeries)
        if (!editMode) {
            newSeries[1].visible = false
            newSeries[2].visible = false
            newSeries[3].visible = false
            newSeries[4].visible = false
            newSeries[5].visible = false
            newSeries[6].color = style.colorPrimary2
            newSeries[7].color = style.colorPrimary3_40
            newSeries[8].color = 'rgb(176,229,242)'//style.colorPrimary3_40
            newSeries[9].color = 'rgb(78,195,224)'//style.colorPrimary3_40
        } else {
            newSeries[1].visible = true
            newSeries[2].visible = true
            newSeries[3].visible = true
            newSeries[4].visible = true
            newSeries[5].visible = true
            newSeries[6].color = alpha(style.colorPrimary2, 0.2)
            newSeries[7].color = alpha(style.colorPrimary3, 0.2)
            newSeries[8].color = alpha('rgb(176,229,242)'/*style.colorPrimary3*/, 0.2)
            newSeries[9].color = alpha('rgb(78,195,224)'/*style.colorPrimary3*/, 0.2)
        }

        //custom order at legend
        newSeries[0].legendIndex = 10 //History
        newSeries[8].legendIndex = 11 // cbForecast
        newSeries[9].legendIndex = 12 //cbForecastSmoth
        newSeries[7].legendIndex = 13 // swapForecast
        newSeries[6].legendIndex = 14 //currentForecast

        if (!keyRateInteractiveGraph?.model)
            newSeries[6].showInLegend = false //currentForecast  
        if (!keyRateInteractiveGraph?.swapForecast)
            newSeries[7].showInLegend = false //swapForecast
        if (!keyRateInteractiveGraph?.cbForecast)
            newSeries[8].showInLegend = false //cbForecast
        if (!keyRateInteractiveGraph?.cbForecastSmooth)
            newSeries[9].showInLegend = false //cbForecastSmoth

        // newSeries.forEach((serie,index)=>{newSeries[index].legendIndex = newSeries[index].legendIndex})
        var newChart = { ...chartOptions, series: newSeries }
        if (editMode)
            newChart.tooltip.enabled = false
        else
            newChart.tooltip.enabled = true

        if (!editMode)
            newChart.xAxis[0].crosshair = {
                color: style.colorPrimary3,
                snap: false,
                width: 1,
            }
        else
            newChart.xAxis[0].crosshair = false

        newChart.xAxis[0].max = max
        newChart.xAxis[0].min = min
        if (keyRateInteractiveGraph?.minRateLimit)
            newChart.yAxis[0].min = roundDown(keyRateInteractiveGraph?.minRateLimit, 1)
        if (keyRateInteractiveGraph?.maxRateLimit)
            newChart.yAxis[0].max = roundUp(keyRateInteractiveGraph?.maxRateLimit, 1)

        newChart.xAxis[0].plotBands = []
        const marcoDate = new Date(keyRateInteractiveGraph?.macroDate)
        if (marcoDate != 'Invalid date') {
            newChart.xAxis[0].plotLines = [{ ...plotLines, value: marcoDate?.getTime(), label: { ...plotLines?.label, align: 'left', x: 3, text: Localization.FormatDateLocale(marcoDate) } }]

            newChart.xAxis[0].plotBands.push({
                from: -Infinity,
                to: marcoDate?.getTime(),
                color: alpha(style.colorPrimary3, 0.02) // Серый цвет фона при x < 1
            })
        }

        // const legalRedemptionDate = new Date(keyRateInteractiveGraph?.legalRedemptionDate)
        // if (legalRedemptionDate != 'Invalid date') {
        //     newChart.xAxis[0].plotLines.push({ ...plotLines, value: legalRedemptionDate?.getTime(), label: { ...plotLines?.label, text: Localization.FormatDateLocale(legalRedemptionDate) } })

        //     newChart.xAxis[0].plotBands.push({
        //         from: legalRedemptionDate?.getTime(),
        //         to: Infinity,
        //         color: alpha(style.colorPrimary3, 0.02) // Серый цвет фона при x < 1
        //     })
        // }
        // console.log('newChartData-----------------------------------', newChart)
        setChartOptions(newChart)
    }

    useLayoutEffect(() => {
        //     console.log('5')
        if (chartRef.current != null) {
            //         // console.log('chartRef.current.chart.callback', chartRef.current.chart.callback)
            //         // chartRef.current.chart.callback = onLoadFunc?.bind(chartRef?.current?.chart)
            //         // chartRef.current.chart.callback()
            onLoadFunc()
        }
    }, [series, editMode])

    const mousemoveEvent = useCallback(function (e) {
        if (!chartRef?.current?.chart?.series) return
        // console.log('barsSeries at Moving'/*, barsSeries?.data?.map(el => el.y)*/)

        const dragPointIndex = searchPointIndex(e)
        const barsSeries = chartRef?.current?.chart.series[1]
        const pointSeries = chartRef?.current?.chart.series[2]
        const hoverLineSerie = chartRef?.current?.chart.series[3]
        const crosshairSerie = chartRef?.current?.chart.series[5]

        const max = chartRef?.current?.chart?.yAxis[0]?.max
        if (dragPointIndex != -1) {
            barsSeries?.data?.forEach((el, index) => {
                if (dragPointIndex == index) {
                    crosshairSerie?.setData([{ x: el?.x, y: max, z: el?.z }], false)
                    hoverLineSerie?.setData([
                        { x: pointSeries?.data[index * 3]?.x, y: pointSeries?.data[index * 3]?.y, marker: hovered_marker },
                        { x: pointSeries?.data[index * 3 + 1]?.x, y: pointSeries?.data[index * 3 + 1]?.y, marker: hovered_marker },
                    ], false)
                    barsSeries.data[index].hover = true
                }
                else {
                    barsSeries.data[index].hover = false
                }
            })
            barsSeries?.redraw()
            hoverLineSerie?.redraw()
            crosshairSerie?.redraw()
        }
        // chart.redraw()
        // console.log('freeMove')
    }, [series, editMode])

    const findNearestLeftPoint = useCallback((serie, xValue, radius = null) => {

        if (xValue < serie.xData[0] || xValue > serie.xData[serie.xData?.length - 1 ?? 0])
            return null

        if (serie?.points == null || serie?.points?.length == 0)
            return

        let nearestPoint = serie?.points?.reduce((closest, current) => {
            return Math.abs(current.x - xValue) < Math.abs(closest.x - xValue) && (xValue > current.x) ? current : closest
        })

        if (radius != null)
            return (Math.abs(xValue - nearestPoint.x) <= radius) ? nearestPoint : null //* 24 * 60 * 60 * 1000
        else
            return nearestPoint
    }, [series, editMode])

    const searchPointIndex = useCallback(function (e) {
        // console.log('e!!!!!!!!!!!!!!!!!!!!', e)
        const event = chartRef?.current?.chart.pointer.normalize(e)
        const xAxis = chartRef?.current?.chart.xAxis[0]
        const xVal = xAxis?.toValue(event.chartX)

        const series = chartRef?.current?.chart.series.find(s => s.userOptions.id == 'modelDataBars')
        const index = series?.xData?.findLastIndex(i => { return xVal >= i })

        return index
    }, [series, editMode])

    const crosshair = useCallback(async (e, chart) => {
        // console.log('e', e)
        const event = chart.pointer.normalize(e)
        const xAxis = chart.xAxis[0]
        const xVal = xAxis?.toValue(event.chartX)
        const s = [chart.series[0], chart.series[9], chart.series[8], chart.series[7], chart.series[6],] //серии отображаемые в тултипе
        const hoveredPoints = []

        xAxis.drawCrosshair(e, e.point);

        s.forEach(serie => {
            serie?.points?.forEach(p => p.setState(''))
            let nearestPoint = findNearestLeftPoint(serie, xVal);

            if (nearestPoint) {
                nearestPoint.setState('hover') //onMouseOver() //
                hoveredPoints.push(nearestPoint)
            }
        })
        if (hoveredPoints?.length)
            chart.tooltip.refresh(hoveredPoints)
        chart.redraw()
    }, [series, editMode])

    const mouseUpEvent = useCallback(function (e) {
        // console.log('mouseUp')
        e.stopPropagation()
        const barsSeries = chartRef?.current?.chart.series[1]
        const hoverLineSerie = chartRef?.current?.chart.series[3]
        const crosshairSerie = chartRef?.current?.chart.series[5]

        hoverLineSerie.update({ shadow: null }, false)
        crosshairSerie.update({ cursor: 'pointer' }, false)

        chartRef.current.chart.container.onmousemove = mousemoveEvent
        mousemoveEvent(e)
    }, [series, editMode])

    var startDY = null
    var dragPointIndex = null

    const drag = useCallback(function (e) {
        const barsSeries = chartRef?.current?.chart?.series[1]
        const pointSeries = chartRef?.current?.chart?.series[2]
        const hoverLineSerie = chartRef?.current?.chart?.series[3]
        const dashedLineSerie = chartRef?.current?.chart?.series[4]

        const yAxis = chartRef?.current?.chart?.yAxis[0]
        const event = chartRef?.current?.chart?.pointer.normalize(e)

        let y = roundDown(startDY + yAxis?.toValue(event.chartY), 0.25)

        if (y <= yAxis?.min) y = keyRateInteractiveGraph?.minRateLimit ?? yAxis?.min
        if (y >= yAxis?.max) y = keyRateInteractiveGraph?.maxRateLimit ?? yAxis?.max

        pointSeries.data[dragPointIndex * 3].update({ y: y }, false)
        pointSeries.data[dragPointIndex * 3 + 1].update({ y: y }, false)
        dashedLineSerie.data[dragPointIndex * 3].update({ y: y }, false)
        dashedLineSerie.data[dragPointIndex * 3 + 2].update({ y: y }, false)
        hoverLineSerie?.setData([
            { x: pointSeries.data[dragPointIndex * 3].x, y: pointSeries.data[dragPointIndex * 3].y, marker: dragged_marker },
            { x: pointSeries.data[dragPointIndex * 3 + 1].x, y: pointSeries.data[dragPointIndex * 3 + 1].y, marker: dragged_marker },
        ], false)

        barsSeries.data[dragPointIndex].update({ y: y }, false)
        pointSeries.redraw()
        dashedLineSerie.redraw()
        barsSeries.redraw()
        hoverLineSerie.redraw()

        if (barsSeries?.data?.some((el, index) => {
            return keyRateInteractiveGraph?.model[index].value !== el.y
        })) {
            // if (keyrateWasChanged == false)
            setKeyrateWasChanged(true)
        }
        else {
            // if (keyrateWasChanged == true)
            setKeyrateWasChanged(false)
        }
    }, [series, editMode])

    const mouseDownEvent = useCallback(function (e) {
        // console.log('mouseDown')
        const barsSeries = chartRef?.current?.chart?.series[1]
        const pointSeries = chartRef?.current?.chart.series[2]
        const hoverLineSerie = chartRef?.current?.chart.series[3]
        const dashedLineSerie = chartRef?.current?.chart.series[4]
        const crosshairSerie = chartRef?.current?.chart.series[5]

        // e.preventDefault()
        dragPointIndex = searchPointIndex(e)

        if (dragPointIndex >= 0) {
            crosshairSerie.update({ cursor: 'row-resize' }, false)
            crosshairSerie.redraw()
            barsSeries.update({ cursor: 'row-resize' }, false)
            barsSeries.redraw()
            hoverLineSerie.update({
                shadow: {
                    color: alpha(style.colorSecondary1, 0.4),//оранжевая
                    offsetX: 3,
                    offsetY: 3,
                    opacity: 0.5,
                    width: 5
                }
            }, false)

            const event = chartRef?.current?.chart.pointer.normalize(e)
            const yAxis = chartRef?.current?.chart.yAxis[0]

            startDY = pointSeries.data[dragPointIndex * 3 + 1].y - yAxis?.toValue(event.chartY)

            chartRef.current.chart.container.onmousemove = drag
            drag(e)
        }
    }, [series, editMode])

    const onLoadFunc = useCallback(function () {
        // console.log('onLoadCallBack')
        // const chart = chartRef?.current?.chart
        if (!chartRef?.current?.chart) return

        if (!editMode) {
            chartRef.current.chart.container.onmouseleave = null
            chartRef.current.chart.container.onmouseup = null
            chartRef.current.chart.container.onmousemove = (e) => crosshair(e, chartRef?.current?.chart)
            chartRef.current.chart.container.onmousedown = null
            return
        }

        chartRef.current.chart.container.onmouseleave = function (e) {
            const crosshairSerie = chartRef?.current?.chart.series[5]
            crosshairSerie?.setData([])
            crosshairSerie?.redraw()

            const hoverLineSerie = chartRef?.current?.chart.series[3]
            hoverLineSerie?.setData([])
            hoverLineSerie?.redraw()

            const barsSeries = chartRef?.current?.chart?.series[1]
            barsSeries?.data?.forEach((el, index) =>
                barsSeries.data[index].hover = false)
            barsSeries?.redraw()
        }

        chartRef.current.chart.container.onmouseup = mouseUpEvent

        chartRef.current.chart.container.onmousemove = mousemoveEvent

        chartRef.current.chart.container.onmousedown = mouseDownEvent

    }, [series, editMode])

    const hideChartHelper = () => {
        const chart = chartRef.current?.chart
        if (chart == null)
            return

        if (chartLabels?.length) {
            chartLabels?.forEach(l => l.destroy())
        }

        if (chartLabelsPath?.length) {
            chartLabelsPath?.forEach(l => l.destroy())
        }

        setChartLabels([])
        setChartLabelsPath([])
    }

    const chartHelper = useCallback(function () {
        const chart = chartRef.current?.chart

        if (chart == null)
            return

        if (chartLabels?.length) {
            hideChartHelper()
            return
        }

        const s = [
            { serie: chart.series[0], xDate: '2023-02-01', x: 265, y: 100, yOffset: 20, },// attr: { stroke: style.colorPrimary1, } }, //history
            { serie: chart.series[7], x: 1175, yOffset: -20, },// attr: { stroke: style.colorPrimary3_40, } }, //swap
            // { serie: chart.series[8], x: 1180, yOffset: 20, },// attr: { stroke: style.colorPrimary3_40, } }, //cb
            { serie: chart.series[9], x: 1175, yOffset: 20, },// attr: { stroke: style.colorPrimary3_40, } }, //cb
            { serie: chart.series[6], x: 725, yOffset: useModelKeyrate ? -20 : 77, },//attr: { stroke: style.colorPrimary2, fill: 'rgba(229, 255, 211, 1)', } }, //current
        ]

        const helperPoints = []
        let event = null
        let xVal = null
        s.forEach(el => {
            if (el?.xDate) {
                xVal = new Date(el.xDate)
                // console.log('xVal', xVal)
            }
            else
                if (el?.x != null) {
                    event = chart.pointer.getCoordinates({
                        chartX: chart.plotLeft + el.x,
                        chartY: 0,
                    })
                    xVal = event.xAxis[0].value
                }
            if (xVal != null) {
                // const xVal = new Date(el.xDate)?.getTime()
                // console.log('xVal1111', xVal)
                const p = findNearestLeftPoint(el?.serie, xVal)

                if (p) {
                    // p.update({ x: xVal })
                    // console.log('p', p, xVal)
                    p.xPos = el?.x
                    p.yPos = el?.y
                    if (!el.xDate)
                        p.xOffset = -30

                    if (el?.yOffset)
                        p.yOffset = el.yOffset
                    if (el?.attr)
                        p.attr = el.attr

                    helperPoints.push(p)
                }
            }
        })

        const getSerieHelperLabel = (seriesId) => {
            switch (seriesId) {
                case 'historicalData':
                    return `${getTooltip({ tooltip: t('convention2.historicalDataChartHelper') })} `

                // case 'cbForecast':
                //     return `${getTooltip({ source: { date: calculationParameters['currentCBForecastDate'] }, tooltip: t('convention2.cbForecastChartHelper') })} `

                case 'cbForecastSmooth':
                    return `${getTooltip({ source: { date: calculationParameters['currentCBForecastDate'] }, tooltip: t('convention2.cbForecastSmoothChartHelper') })} `

                case 'swapForecast':
                    return `${getTooltip({ source: { date: calculationParameters['keyRateSwapForecastDate'] }, tooltip: t('convention2.swapForecastChartHelper') })} `

                case 'currentForecast':
                    return `${getTooltip({ tooltip: useModelKeyrate ? t('convention2.currentForecastChartHelper1') : t('convention2.currentForecastChartHelper0') })} `
            }
        }

        // console.log('helperPoints', helperPoints)
        const lbArr = []
        const lbPathArr = []
        helperPoints.forEach(function (point, i) {
            if (point == null)
                return
            // console.log('chart', chart)

            // var x = point.xPos + chart.plotLeft,// point.plotX ,
            //     y = point.plotY + chart.plotTop,
            //     lX = x,
            //     lY = y + (point.yOffset ? point.yOffset : 0);

            // console.log('point', point)
            if (point?.xOffset)
                console.log('point.xPos + point.xOffset < point.plotX', point.xPos + point.xOffset, point.plotX)

            var x = //point.plotX + chart.plotLeft,
                (point?.xOffset != null ?
                    (
                        point.xPos + point.xOffset > point.plotX + chart.plotLeft ?
                            point.xPos + point.xOffset  // точка поиска + смещение
                            :
                            point.plotX + chart.plotLeft // найденная точка
                    )
                    : point.plotX + chart.plotLeft),
                // point.plotX ,
                y = point.plotY + chart.plotTop,
                lX = point.xPos,
                lY = point?.yPos != null ? (chart.plotHeight - point.yPos) : (y + (point.yOffset ? point.yOffset : 0))

            const lb = chart.renderer.label(getSerieHelperLabel(point?.series.userOptions?.id), lX, lY, 'rect', null, null, true)
                // const lb = chart.renderer.label(getSerieHelperLabel(point?.series.userOptions?.id), x, y, 'callout', x, y, true)
                // const lb = chart.renderer.label(getSerieHelperLabel(point?.series.userOptions?.id), x, y, 'callout', x, y, true)
                .attr({
                    padding: 8,
                    r: 4,
                    fill: 'rgba(97,97,97,0.92)',
                    // 'rgba(109,109,109,1)',
                    stroke: 'rgba(97,97,97,0.92)',
                    'stroke-width': 0.5,
                    zIndex: 9999,
                    // shape: 'connector',
                    ...point?.attr,
                })
                .css({
                    color: 'white',
                    fontSize: '16px',
                    fontFamily: 'Arial, sans-serif',
                    zIndex: 9999,
                })
                .add()

            if (lb) {
                // console.log('lb', lb)
                lb.attr({
                    x: lb?.x - lb.width / 2 > 0 ? (lb?.x - lb.width / 2) : 0,
                })

                if (lb.y < y) {
                    lb.attr({
                        y: lb?.y - lb.height,
                    })
                }

                lbArr.push(lb)

                if (lb?.y < 0) {
                    lY = y + chart?.plotTop + point.plotY + (point.yOffset ? -point.yOffset : 0)
                    lb.attr({
                        y: chart?.plotTop + point.plotY + (point.yOffset ? -point.yOffset : 0),
                    })
                }

                if (lb?.y + lb.height > chart.plotHeight) {
                    lY = lb.y - lb.height + (point.yOffset ? -point.yOffset : 0)
                    lb.attr({
                        y: lY + (point.yOffset ? -point.yOffset : 0),
                    })
                }

                const labelPath = chart.renderer.path(['M', lX, lY, 'L', x, y])
                    .attr({
                        'stroke-width': 1,
                        stroke: lb.stroke // Цвет линии привязки
                    })
                    .add()
                const pointer = chart.renderer.circle(x, y, 3)
                    .attr({
                        zIndex: 9999,
                        'stroke-width': 1,
                        stroke: 'rgba(97,97,97,0.92)',
                        fill: 'rgba(255,255,255,0.92)',
                    })
                    .add();

                if (labelPath)
                    lbPathArr.push(labelPath)

                if (pointer)
                    lbPathArr.push(pointer)
                // var links = lb.element.getElementsByTagName('a');

                // [...links]?.forEach(el => {
                //     el.style.color = 'red'
                // })
                // // lb.element.childNodes[1].class = cls.label
            }
        })

        setChartLabels(lbArr)
        // console.log('lbPathArr', lbPathArr)
        setChartLabelsPath(lbPathArr)
        // chart.redraw()   

    }, [series, editMode, chartLabels, chartLabelsPath, useModelKeyrate])
    if (calculationParameters == null) return <></>
    // console.log('keyrateWasChanged!!!!!!!!!!!', keyrateWasChanged)

    // console.log('render Cart--------------------------------------------------')

    return (
        <>
            {Boolean(!calculationParameters?.calculationSCurvesReportDate) ?
                <Box className={cls.fullPanelAlert}>
                    {selectedBond?.isin == 'RU000A100DQ4' ?
                        <div dangerouslySetInnerHTML={{ __html: t('Convention2_sCurveChartDQ4Alert') }} />
                        :
                        <div dangerouslySetInnerHTML={{ __html: t('Convention2_sCurveChartNotDQ4Alert') }} />
                    }
                </Box>
                :
                <Box className={cls.root}>
                    <Box className={cls.rootData}>
                        {/* {(keyRateInteractiveGraphLoading || series?.length == 0) && <SpinnerBars withBackground />} */}
                        {/* <Box style={{ textAlign: 'center', fontSize: '1.2rem', }} dangerouslySetInnerHTML={{ __html: t('convention2.modelKeyrateTopText') }} /> */}
                        {/* <Box className={cls.functionBlock}>
                            <Box className={cls.functionText}>
                                CPR(𝑠,ℎ) = {betas?.beta0 ?? <>𝛽<sub><i>h</i>,0</sub></>} + {betas?.beta1 ?? <>𝛽<sub><i>h</i>,1</sub></>} ∙ arctg({betas?.beta2 ?? <>𝛽<sub><i>h</i>,2</sub></>} + {betas?.beta3 ?? <>𝛽<sub><i>h</i>,3</sub></>} ∙ 𝑠) + {betas?.beta4 ?? <>𝛽<sub><i>h</i>,4</sub></>} ∙ arctg({betas?.beta5 ?? <>𝛽<sub><i>h</i>,5</sub></>} + {betas?.beta6 ?? <>𝛽<sub><i>h</i>,6</sub></>} ∙ 𝑠)
                            </Box>
                        </Box> */}
                        {/* <Box>CPR<sub><i>𝑡</i></sub>(𝑠) — <span className={cls.decreaseFontSize}>ожидаемый СPR кредита при стимуле к рефинансированию s на году жизни t, % год.</span></Box> */}
                        <Box className={cls.chartRoot}>
                            <HighchartsReact
                                ref={chartRef}
                                highcharts={Highcharts}
                                containerProps={{ style: { /*height: "100%", width: "100%"*/ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
                                options={chartOptions ? chartOptions : {}}
                            // callback={() => onLoadFunc()}
                            />
                            <Box className={cls.buttonsBox}>
                                {/* <IconButton
                                    size="small"
                                    // disabled={modelKeyrate == null}
                                    onClick={() => {
                                        chartHelper(chartRef.current?.chart)
                                    }}
                                    className={cls.mr05}
                                >
                                    <Help />
                                </IconButton> */}
                                {editMode &&
                                    <>
                                        <StyledButton
                                            variant="outlined"
                                            size="small"
                                            disabled={!keyrateWasChanged}
                                            onClick={() => clearModelKeyrate()}
                                            className={cls.resetButton}
                                        >
                                            {t('convention2.resetModelKeyrate')}
                                        </StyledButton>

                                        <StyledButton
                                            variant="outlined"
                                            size="small"
                                            disabled={!keyrateWasChanged}
                                            onClick={() => {
                                                if (runCalculateWithModelKeyrate())
                                                    hidePanel()
                                            }}
                                            className={cls.runCalculationButton}
                                        >
                                            {t("convention2.runCalculateWithModelKeyrate")}
                                        </StyledButton>
                                    </>
                                }
                            </Box>
                        </Box>
                    </Box>

                </Box >
            }
        </>
    )
})

export default KeyrateDialog