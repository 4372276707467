import { Box, Button, IconButton, Modal } from '@mui/material';
import { createStyles, makeStyles, styled } from '@mui/styles';
import React, { useState } from 'react'
import style from '@Styles/style-main.scss'
import { t } from 'i18next';
import { Close } from '@mui/icons-material';
import Slide1 from '../slides/Slide1';
import Slide2 from '../slides/Slide2';

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: "DINPro-Bold",
    fontWeight: "700",
    letterSpacing: '1.5px',
    fontSize: '0.8rem !important',
    '& *': {
        fontSize: '0.8rem !important',
    },
    // color: style.colorPrimary3,
    padding: '0 0.5rem 0 9px',
    marginRight: '0.5rem',
    backgroundColor: 'rgba(183, 217, 139, 0.23)',
    borderColor: 'rgb(61,149,109)',
    color: 'rgb(61,149,109)',
    '&:hover': {
        borderColor: 'rgb(61,149,109) !important',
        backgroundColor: 'rgba(183, 217, 139, 0.4) !important',
        // color: `rgb(0,176,80) !important`,
    },
    minWidth: '2rem',
}));

const useStyles = makeStyles((theme) => createStyles({
    navigationBox: {
        padding: '0 0.5rem 0.5rem 0.5rem',
        textAlign: 'right',
    },
    modal: {
        display: 'flex',
        padding: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    rootModal: {
        position: 'relative',
        width: '1200px',
        height: 'max-content',
        outline: 'none',
        '& $rootPaper': {
            backgroundColor: '#FFF',
        }
    },
    root: {
        position: 'absolute',
        left: '0.2rem', top: 0, bottom: 0, right: '0.2rem',
        '& $panelHeader *': {
            fontSize: '1rem'
        }
    },
    rootPaper: {
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'hidden',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 0',
        justifyContent: 'space-between',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
        marginLeft: '0.5rem',
    },
    panelHeaderButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // '&>*:not(:first-child)': {
        //     marginLeft: '0.5rem',
        // }
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80
    },
    panelData: {
        margin: '0.5rem 0.8rem 0 0.8rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'no-wrap',
        alignItems: 'center',
        height: '720px',
        overflow: 'hidden',
        '& sup': {
            lineHeight: '0.6rem',
            fontSize: '0.6rem',
        }
    },
}))

const Presentation = ({ show, setShow = () => null }) => {

    const cls = useStyles()

    const hideModal = () => { setShow(false) }
    const [step, setStep] = useState(0)

    const slides = [
        {
            index: 0, component: <Slide1 step={0} />
        },
        { index: 1, component: <Slide1 step={1} /> },
        { index: 2, component: <Slide1 step={2} /> },
        { index: 3, component: <Slide2 step={0} /> },
        { index: 4, component: <Slide2 step={1} /> },
        { index: 5, component: <Slide2 step={2} /> },
    ]

    const showSlide = (step) => {
        return <>
            {slides[step].component}
        </>
    }

    const nextPage = () => { if (step < slides?.length - 1) setStep(x => x + 1) }
    const previousPage = () => { if (step > 0) setStep(x => x - 1) }
    console.log('render Presentation')
    return (
        <Modal
            className={cls.modal}
            open={show}
            onClose={hideModal}
        >
            <Box className={cls.rootModal}>
                <Box className={cls.rootPaper}>
                    <Box className={cls.panelHeader}>
                        <Box className={cls.panelHeaderTitle}>
                            {`${t('convention2.presentationTitle')}`}
                        </Box>
                        <Box className={cls.panelHeaderButtons}>
                            <IconButton size="small" onClick={hideModal}>
                                <Close className={cls.icon} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className={cls.panelData}>
                        {showSlide(step)}
                    </Box>
                    <Box className={cls.navigationBox}>
                        <StyledButton
                            variant='outlined'
                            size='small'
                            onClick={previousPage}
                            disabled={step == 0}
                        >
                            {t("convention2.prevPage")}
                        </StyledButton>
                        <StyledButton
                            variant='outlined'
                            size='small'
                            onClick={nextPage}
                            disabled={step >= slides?.length - 1}
                        >
                            {t("convention2.nextPage")}
                        </StyledButton>
                    </Box>
                </Box>
            </Box>
        </Modal >
    )
}

export default Presentation