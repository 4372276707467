import ServiceBase from "./ServiceBase";
//import Globals from "../Globals";

export default class PoolService extends ServiceBase {

    static async getPoolLLDForChart(isin, wacwamDate, step = 1) {
        var result = await this.requestJson({
            url: "api/Convention/GetPoolLLD",
            method: "GET",
            data: { isin: isin, dtWACWAM: wacwamDate, step: step }
        });

        return result;
    }

    static async getPoolLLDForChartV2(bondID, wacwamDate, step = 1) {
        var result = await this.requestJson({
            url: "api/Convention/GetPoolLLDV2",
            method: "GET",
            data: { bondID: bondID, dtWACWAM: wacwamDate, step: step }
        });

        return result;
    }
}
