import { alpha, Box, Divider, Paper, Stack, Theme, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SpinnerBars from '../../../../components/shared/SpinnerBars'
import { actionCreators as actionSeciritization } from '../../../../store/SecuritizationStore'

const useStyles = makeStyles((theme: Theme) => createStyles({
    info: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.2rem',
        color: style.colorPrimary3_40
    },
    'capitalize': {
        textTransform: 'capitalize'
    },
    line: {
        lineHeight: '2.1rem',
        flexDirection: 'row',
        position: 'relative',
        paddingLeft: '8px',
        paddingRight: '8px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: alpha(style.colorPrimary2, 0.17),
            '& $percentsBar': {
                borderColor: style.colorPrimary2_80,
                backgroundColor: alpha(style.colorPrimary2, 0.17),
            }
        },

    },
    columnHeader: {
        textTransform: 'uppercase',
        lineHeight: '2rem',
        maxHeight: '2rem',
        overflow: 'hidden',
        marginLeft: 1,
        flexGrow: 1,
        fontSize: '14px',
        color: style.colorPrimary3_40
    },
    tableDataSign: {
        color: style.colorPrimary3_40,
        fontSize: '0.9rem',
        display: 'inline-block',
        marginLeft: '4px',
    },
    percentsBar: {
        position: 'absolute',
        left: 0, height: '8px',
        bottom: '4px',
        backgroundColor: style.colorPrimary3_5,
        border: `solid 1px ${style.colorPrimary3_60}`
    },
    scrollablePanel: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    wrappedText: {
        fontSize: '14px',
        color: style.colorPrimary3_60
    },
    grayscaled: {
        color: style.colorPrimary3_60
    }
}))

function CollateralDistribution({ poolId = null }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { collateralDistribution = null } = useSelector((state: any) => state.securitization)

    useEffect(() => {
        if (poolId != null) {
            actionSeciritization.getPoolCollateralDistribution(poolId)(dispatch)
        }
    }, [poolId])

    // console.log('collateralDistribution', collateralDistribution)

    const getTypeName = ({ type = null }) => {

        // const Capitalize = ({ name }) => <span className={classes.capitalize}>{name}</span>

        if (type == null) return null

        switch (type) {
            case 1:
                return t("securitization.flat")

            case 2:
                return t("securitization.houseWithBuiding")

            case 3:
                return t("securitization.townHouse")

            case 4:
                return t("securitization.apartments")

            default:
                return null //t("erp2_dataAbsent")
        }
    }

    const Output = ({ distribution = [] }) => {
        if (!distribution?.length)
            return <></>

        const LineOutput = (id, name, percents, wac) =>
            <Stack key={`distribution_line_id_${id}`} className={classes.line}>
                <Box sx={{ zIndex: +1, width: '1.5rem !important', minWidth: '2rem !important', textAlign: 'right', whiteSpace: 'nowrap' }}>
                    {parseFloat(percents)?.toFixed(0)}<span className={classes.tableDataSign}>%</span>
                </Box>
                <Typography component='div' sx={{ ml: 1.5, height: '2rem', position: 'relative', flexGrow: 1, fontSize: '0.9rem' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        <Box sx={{ right: `${100 - parseFloat(percents)}%` }} className={classes.percentsBar} />
                        {name}
                    </Box>
                </Typography>
                <Box sx={{ zIndex: +1, width: '4rem !important', minWidth: '4rem !important', textAlign: 'right', whiteSpace: 'nowrap' }}>
                    {parseFloat(wac)?.toFixed(1)}
                    <span className={classes.tableDataSign}>%</span>
                </Box>
            </Stack>

        let dataAbsent: any = null
        let knowDataCount = 0
        // console.log('distribution', distribution)
        return (
            <>
                {distribution
                    .sort((a, b) => parseFloat(a?.percents) > parseFloat(b?.percents) ? -1 : parseFloat(a?.percents) < parseFloat(b?.percents) ? 1 : 0)
                    .map((item) => {
                        // console.log('item', item)
                        let name = getTypeName(item)
                        const itemPercents = parseFloat(item.percents)
                        const itemWac = parseFloat(item.wac)
                        // console.log('name', name)

                        if (name == null) {
                            const percents = parseFloat(dataAbsent?.percents)
                            const fraction_sum = parseFloat(dataAbsent?.wac_fraction_sum)
                            const count = parseInt(dataAbsent?.count)
                            dataAbsent = {
                                percents: (!isNaN(percents) ? percents : 0) + (!isNaN(itemPercents) ? itemPercents : 0),
                                wac_fraction_sum: (!isNaN(fraction_sum) ? fraction_sum : 0) + ((!isNaN(itemWac) && !isNaN(itemPercents)) ? itemWac * itemPercents : 0),
                                count: (!isNaN(count) ? count + 1 : 0)
                            }
                        }
                        else {
                            knowDataCount++
                            return LineOutput(`id_${item?.type}`, name, item.percents, item.wac)
                        }
                    })}
                {/* {console.log('dataAbsent', dataAbsent)} */}
                {knowDataCount == 0 ?
                    <div className={classes.info} dangerouslySetInnerHTML={{ __html: t("securitization.collautteralDistributionLoadingFail") }} /> :
                    <>{
                        dataAbsent != null &&
                        LineOutput('erp2_dataAbsent', t("erp2_dataAbsent"), dataAbsent.percents?.toFixed(0) == 0 ? 1 : dataAbsent.percents, (dataAbsent.wac_fraction_sum / dataAbsent.percents)?.toFixed(2))
                    }</>
                }
            </>)
    }

    return (
        <Paper sx={{
            // position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
            ml: '2px', mt: 1, mb: '2px', mr: '4px',
            flexGrow: 1,
            display: 'flex', flexDirection: 'column',
            boxShadow: 'none',
            minHeight: '120px'
        }}>
            <Stack sx={{ flexShrink: 1 }}>
                <Box sx={{
                    display: 'flex', pl: 1,
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    maxHeight: '30px', overflow: 'hidden'
                }}
                >
                    <Typography className={classes.columnHeader} >
                        {t("securitization.CollateralDistributionTitle")}
                    </Typography>
                    <Typography className={classes.columnHeader} sx={{ textAlign: 'right', pr: 3 }}>
                        {t("epr2_wac")}
                    </Typography>
                </Box>
                <Divider />
            </Stack>
            <Stack sx={{
                position: 'relative', flexGrow: 1,
                ...Boolean(collateralDistribution?.distribution?.length > 8)
                    ? { flexBasis: '310px' }
                    : { ...Boolean(collateralDistribution?.distribution?.length) ? { flexBasis: `${collateralDistribution.distribution.length * 35}px` } : { flexBasis: '200px' } }
            }}>
                {!(collateralDistribution?.distribution?.length && collateralDistribution.poolId == poolId) ?
                    <>
                        <SpinnerBars withBackground />
                        {/* <Box className={classes.info}>{t("collateral")}</Box> */}
                    </>
                    :
                    <>
                        <Stack sx={{ position: 'relative', flexGrow: 1 }}>
                            <Box className={classes.scrollablePanel}>
                                <Output distribution={collateralDistribution.distribution} />
                            </Box>
                        </Stack>
                    </>

                }
            </Stack>
        </Paper>

    )
}

export default CollateralDistribution