import { applyMiddleware, combineReducers, /*compose,*/ createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from "@redux-devtools/extension";
import { reducers } from './store';
import { SharedActions } from './store/SharedActions';

export default function configureStore(initialState, routerReducer, middleware) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    //const windowIfDefined = typeof window === 'undefined' ? null : window;

    // If devTools is installed, connect to it.
    //const devToolsExtension = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__;

    const createStoreWithMiddleware = (allReducers, routerMiddleware) => createStore(
        allReducers,
        initialState,
        composeWithDevTools(
            applyMiddleware(thunk, routerMiddleware)
        )
    );

    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = buildRootReducer(reducers, routerReducer);
    const store = createStoreWithMiddleware(allReducers, middleware);

    // Enable Webpack hot module replacement for reducers
    
    //if (module.hot) {
        //module.hot.accept('./store', () => {
            //const nextRootReducer = require('./store');
            //store.replaceReducer(buildRootReducer(nextRootReducer.reducers, history));
        //});
    //}
    
    return store;
}

/*
const routerReducer = (history) => {

    const initialState = {
        location: history.location,
        action: history.action,
    };

    return (state = initialState, arg) => {
        if (arg.type === LOCATION_CHANGE) {
            return { ...state, ...arg.payload };
        }
        return state;
    }
};
*/

const rootReducer = (allReducers) => (state, action) => {
    if (action.type === SharedActions.LogoutSuccess) {
        state = undefined
    }

    return allReducers(state, action)
}

function buildRootReducer(allReducers, routerReducer) {

    var reducer = combineReducers({ ...allReducers, ...{ router: routerReducer } });
    return rootReducer(reducer);
}