import { useState, useEffect, useRef } from 'react'
import style from '@Styles/domrf-theme.module.scss';
import Helmet from "react-helmet"
import { alpha, Box, Container, Tab, Tabs, Theme } from '@mui/material'
import ConfirmDialog2 from '../../../framework/components/ConfirmDialog2'
import DealsList from './components/DealsList'
import { DealType, IterationStatus, PoolProcessingStatus, eDealStatus } from "@Base/components/Definitions"
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'
import { actionCreators as actionSecuritization } from '../../../store/SecuritizationStore'
import { actionCreators as actionTopNavbar } from '../../../store/TopNavbarStore'
import CreateDealDialog from './CreateDealDialog'
import DealTemplate from './components/DealTemplate';
import ComparePools from './components/ComparePools';
import useSignalR from '@Base/components/hook/useSignalR';
import { SignalRHubUrl } from '@Base/PlatformConfig';
import Notifications from './panels/Notifications';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'visible',
        padding: '0 4px 0 4px !important'
    },
    infoPanel: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        overflow: 'hidden',
        // border:'dashed 2px'
    },
    panel: {
        height: 0,
        display: 'flex',
        position: 'relative',
        flex: '1 1 auto',
        flexDirection: 'column',
        paddingTop: '3px'
    },
    fixedTabs: {
        overflow: 'visible !important',
    },
    dealTabContaner: {
        overflow: 'visible',
        borderTop: `solid 1px ${style.colorPrimary3_5}`,
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
        borderLeft: `solid 1px ${style.colorPrimary3_5}`,
        borderRight: `solid 1px ${style.colorPrimary3_5}`,
        backgroundColor: 'transparent',
        // marginTop: '4px',
        minHeight: 'unset',
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        }
    },
    dealTab: {
        color: '#0005',
        // backgroundColor:'#0002',
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
        borderBottom: `solid 1px ${style.colorPrimary3_80}`,
        borderRight: `solid 1px ${style.colorPrimary3_5}`,
        // borderTop: `solid 1px #0001`,
        marginTop: '-1px',
        padding: '4px 1rem',
        // opacity: '0.7',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            backgroundColor: alpha(style.colorPrimary3, 0.05),
            // borderBottom: `none`,
            color: '#0008',
            fontWeight: 'bold',
        },
        '&.Mui-selected': {
            '&:nth-child(-1)': {
                backgroundColor: '#000',
            },
            backgroundColor: '#FFF',
            // zIndex: '+1',
            borderTopLeftRadius: '0.6rem',
            borderTop: `solid 1px ${style.colorPrimary3_80}`,
            borderRight: `solid 1px ${style.colorPrimary3_80}`,
            borderLeft: `solid 1px ${style.colorPrimary3_80}`,
            borderBottom: 'none',
            '&:hover': { color: 'initial', fontWeight: 'initial', },
        },
        '&.Mui-disabled': {
            color: style.colorPrimary3_20
        },
        '&.MuiTab-root': {
            minHeight: '2rem',
            height: '2rem',
        }
    },
}))

const SecuritizationDealsPage = (
    {
        creatingDealEntity,
        creatingPoolEntity,
    }
) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [selectedDealIndex, setSelectedDealIndex] = useState(null)
    const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false)
    const [assignedDeals, setAssignedDeals] = useState([])
    const dealTypes = [
        { key: DealType.TYPE_BOND_PUBLISH, value: t('DealType_TYPE_BOND_PUBLISH') },
        { key: DealType.TYPE_BOND_AT_BALANCE, value: t('DealType_TYPE_BOND_AT_BALANCE') },
        { key: DealType.TYPE_POOL_BUYOUT, value: t('DealType_TYPE_POOL_BUYOUT') }
    ]

    const [isConfirmDealRemoveDialogOpened, setIsConfirmDealRemoveDialogOpened] = useState(false)
    const [dealIdToRemove, setDealIdToRemove] = useState(null)
    const [tabIndex, setTabIndex] = useState(0)
    const [editPermission, setEditPermission] = useState(true) //CHECKME!!! add permission per user and check permissions Globals.hasPermissionAccess(Permissions.PERMISSION_SECURITIZATION_EDIT)
    const [currentOnlineUsers, setCurrentOnlineUsers] = useState({})
    // const [loadingDeal, setLoadingDeal] = useState(false)
    // const [newPoolId, setNewPoolId] = useState(null)
    const [createdDealId, setCreatedDealId] = useState(null)
    const [showPoolsCompare, setShowPoolsCompare] = useState(false)

    const dispatch = useDispatch();

    const [dealId, setDealId] = useState(null)
    const {
        deals = null,
        deal = null,
        dealsLoading = false,
        selectedNode = null,
        originators = [],
        products = [],
        iterationWithChanges = null,
        //availableManagers = null,
        createdPool = null,
        createdDeal = null,
        backupNode = null,
        backupDeal = null,
        permissions = null,
        ...otherStore
    } = useSelector((state: any) => state.securitization)

    const callbackRef = useRef({})

    useEffect(() => {
        callbackRef.current = {
            handleIterationNotification: handleIterationNotification,
            handlePoolNotification: handlePoolNotification,
            handleDealChange: handleDealParametersNotification,
            handleIterationChange: handleIterationParametersNotification,
            handleValidateNotification: handleValidationChange,
            handleDealNotification: handleDealNotification
        }
    })

    const connection = useSignalR(SignalRHubUrl, {
        IterationNotification: (message) => callbackRef.current.handleIterationNotification(message)
        , PoolNotification: (message) => callbackRef.current.handlePoolNotification(message)
        , DealParametersChangedNotification: (message) => callbackRef.current.handleDealChange(message)
        , IterationParametersChangedNotification: (message) => callbackRef.current.handleIterationChange(message)
        , ValidateNotification: (message) => callbackRef.current.handleValidateNotification(message)
        , DealNotification: (message) => callbackRef.current.handleDealNotification(message)
    })

    useEffect(() => {

        actionTopNavbar.setTitle(t("Securitization"))(dispatch)

        actionSecuritization.getDealsWithChanges()(dispatch)
        actionSecuritization.getOriginators(0, 100)(dispatch)
        actionSecuritization.getProducts()(dispatch)

        return () => {
            actionTopNavbar.setTitle("")(dispatch)
        }
    }, [])

    useEffect(() => {
        setAssignedDeals(deals?.items || [])
    }, [deals])

    useEffect(() => {
        if (createdDealId != null) {
            let index = deals?.items.findIndex(x => x.deal.id == createdDealId)
            if (index != -1) {
                onOpenDeal(index)
                setCreatedDealId(null)
            }
        }
    }, [assignedDeals])

    useEffect(() => {
        if (createdDeal != null) {
            // setLoadingDeal(true)
            setCreatedDealId(createdDeal?.deal?.id)
            actionSecuritization.getDealsWithChanges()(dispatch)
            setIsCreateDialogOpened(false)
        }
    }, [createdDeal])

    useEffect(() => {
        if (isCreateDialogOpened === false && deals?.length > 1)
            onOpenDeal(deals?.length - 1)
    }, [isCreateDialogOpened])

    const handleIterationNotification = (message: any) => {
        try {

            console.log("===============IterationNotification, data: ", message, deal)

            if (message == null)
                return
            //no pools

            // console.log('deal', deal)
            if (message?.dealID !== deal?.deal?.id) {
                return
            }

            if (message?.nodeID !== selectedNode?.node?.id) {
                return
            }

            if (assignedDeals == null || assignedDeals.length == 0) {
                console.log("===============RETURN 1")
                return
            }

            //try to find and update iteration, else reload whole deal
            var dealIndex = assignedDeals.findIndex(x => x.deal.id == message.dealID)
            if (dealIndex == -1) //no deal, upload
            {
                actionSecuritization.getDealWithChanges(message.dealID)(dispatch)
                console.log("===============RETURN 2")
                return
            }

            switch (message.status) {
                case IterationStatus.STATUS_PROCESSING:
                    // keyRateGroup: {id: 56, keyRates: Array(5)}
                    // keyRateGroupID: 56

                    actionSecuritization.setDealField({ keyRateGroupID: message?.iteration?.iteration?.keyRateGroupID })(dispatch)
                    actionSecuritization.setDealData('dealChanges', { ...deal?.dealChanges, ...deal?.onlineDealChanges })(dispatch)
                    actionSecuritization.setNode({
                        ...selectedNode
                        , iterationChanges: { ...selectedNode?.iterationChanges, ...selectedNode?.onlineIterationChanges }
                        , lastIteration: { /*...selectedNode?.lastIteration,*/ ...message.iteration?.iteration, status: message.status }
                    })(dispatch)

                    actionSecuritization.updateBackups()(dispatch)
                    break

                case IterationStatus.STATUS_FAILED:
                    actionSecuritization.setNodeDataParametr("lastIteration", { ...selectedNode?.lastIteration, /*...message.iteration?.iteration,*/ status: message.status })(dispatch)
                    break

                case IterationStatus.STATUS_SUCCESS:
                    actionSecuritization.setNodeDataParametr("lastIteration", { ...selectedNode?.lastIteration, /*...message.iteration?.iteration,*/ status: message.status })(dispatch)
                    actionSecuritization.setNodeDataParametr("structure", message.result)(dispatch)
                    break;

                default:
                    actionSecuritization.setNodeDataParametr("lastIteration", { ...selectedNode?.lastIteration, /*...message.iteration?.iteration,*/ status: message.status })(dispatch)
            }
        }
        catch (e) {
            console.error(e)
        }
    }


    const handleDealParametersNotification = (message: any) => {
        // console.log('message', message)
        try {
            if (message == null)
                return

            console.log("=====DealParametersChangedNotification", message);

            //CHECKME!!! fix it later
            //getOnlineUserList(message)

            //track only if paricing tab selected
            //CHECKME!!! better be to turn off subscription
            if (deal == null) {
                console.log("=====DealParametersChange deal null");
                return;
            }

            if (deal.deal.id != message.dealID) {
                console.log(`=====DealParametersChange deal ${message.dealID} not found`);
                return;
            }

            if (message.isReset) {
                console.log('=========================', backupDeal, deal.dealChanges)
                // actionSecuritization.setDealData('deal', backupDeal)(dispatch); ///old verdion before addinf release Tab parameters
                var finalDeal = {
                    ...backupDeal,
                    keyRateGroup: structuredClone(backupDeal.keyRateGroup)
                    /*
                    ...(deal.dealChanges ?
                        Object.fromEntries(
                            Object.entries(deal.dealChanges).map(([key, x]) => { return [key, x?.newValue] })
                        )
                        :
                        {})
                    */
                };

                actionSecuritization.setDealData('deal', finalDeal)(dispatch)
            }

            actionSecuritization.setDealData('onlineDealChanges',
                message.dealChanges ?
                    Object.fromEntries(Object.entries(message.dealChanges).filter(([key, value]) => value != null))
                    :
                    {}
            )(dispatch);
        }
        catch (e) {
            console.error(e);
        }
    }

    const handleIterationParametersNotification = (message: any) => {
        try {
            if (message == null)
                return

            console.log("=====NodeParametersChangedNotification", message);

            if (selectedNode?.node?.id != message.nodeID) {
                console.log(`=====NodeParametersChange node ${message.nodeID} not found`);
                return;
            }

            if (message.isReset) {
                actionSecuritization.setNodeDataParametr("node", { ...backupNode })(dispatch);
                // return
            }

            const onlineIterationChanges = message.iterationChanges ?
                Object.fromEntries(Object.entries(message.iterationChanges).filter(([key, value]) => value !== null))
                :
                {}

            actionSecuritization.setNodeDataParametr("onlineIterationChanges", { ...onlineIterationChanges })(dispatch);

        }
        catch (e) {
            console.error(e);
        }
    }

    const notifyUsers = (message) => {

        // console.log('---update pool status', message.dealID, message);
        var nodeIndex = deal.deal.poolNodes.findIndex(x => x.id == message.nodeID)
        if (nodeIndex == -1) {

            //var node = {};
            //CHECKME!!! need to send new available node?
            //var nodes = [...deal.deal.poolNodes, node];


            // console.log('no node - getDealWithChanges (securitization deal page) ', message.dealID, message);
            //we don't have a pool at deal. Load it. //CHECKME!!! better to load node only
            actionSecuritization.getDealWithChanges(message.dealID)(dispatch)
            return;
        }

        var nodes = [...deal.deal.poolNodes];
        deal.deal.poolNodes = nodes;

        var node = nodes[nodeIndex];
        var poolIndex = node.pools.findIndex(x => x.id == message.poolID);
        if (poolIndex == -1) {

            console.log('no pool - getDealWithChanges (securitization deal page) ', message.dealID, message);
            //we don't have a pool at deal. Load it. //CHECKME!!! better to load node only
            actionSecuritization.getDealWithChanges(message.dealID)(dispatch)

            return;
        }

        var pools = [...node.pools];
        var pool = pools[poolIndex];
        pool.status = message.status;
        pool.statusString = message.statusString;
        node.pools = pools;
    }

    const handlePoolNotification = function (message) {
        try {

            console.log('===========incoming pool notification', message);

            actionSecuritization.setPoolsHubMessage(message)(dispatch)

            //update pool status in DEALS
            // console.log('deals', deals)
            const newPoolData = {
                id: message.poolID,
                status: message?.status,
                statusString: message?.statusString,
                percents: message?.percents,
                createdDate: Date.now()
            }
            const newItems = deals?.items?.map(item => {
                return {
                    ...item,
                    deal: {
                        ...item?.deal,
                        poolNodes: item?.deal?.poolNodes?.length && item.deal.poolNodes.some(node => node.id == message?.nodeID)
                            ? item?.deal?.poolNodes?.map(node => {
                                return {
                                    ...node,
                                    pools: node?.pools?.length && node?.pools?.some(pool => pool?.id == message?.poolID)
                                        ? node?.pools?.map(pool => {
                                            if (message?.nodeID == node?.id && message?.dealID == item.deal.id)
                                                //console.log('pool?.status', item.deal.id, pool?.status)
                                                return {
                                                    ...pool,
                                                    id: message.poolID,
                                                    status: message?.status != null ? message.status : pool?.status,
                                                    statusString: message?.statusString,
                                                    percents: message?.percents
                                                }

                                            return pool
                                        })
                                        : node.id == message?.nodeID
                                            ? [newPoolData]
                                            : node?.pools
                                }
                            })
                            : item.deal.id == message?.dealID
                                ? [
                                    ...item?.deal?.poolNodes,
                                    {
                                        id: message.nodeID,
                                        pools: [newPoolData]
                                    }]
                                : item?.deal?.poolNodes
                    }
                }
            })

            // console.log('newItems', newItems)
            actionSecuritization.setDeals({ ...deals, items: newItems })(dispatch)

            //we have a new pool uploaded
            if (message == null)
                return

            if (deal?.deal?.id == message?.dealID && message?.poolID != null) {

                notifyUsers(message);

                if (message.status == PoolProcessingStatus.STATUS_ARCHIVED || message.status == PoolProcessingStatus.STATUS_REMOVED) {

                    var nodeIndex = deal.deal.poolNodes.findIndex(x => x.id == message.nodeID)
                    if (nodeIndex == -1)
                        return;
                    console.log('---remove pool getDealWithChanges (securitization deal page) ', message.dealID, message);

                    actionSecuritization.setNode({})(dispatch)
                    actionSecuritization.getDealWithChanges(message.dealID)(dispatch);
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    const handleDealNotification = (message) => {

        try {

            console.log('===========incoming deal notification', message);

            if (message == null)
                return

            if (deal?.deal?.id != message?.dealID)
                return

            if (message.status != null) {
                if (message.status == eDealStatus.STATUS_NORMAL) //created
                {
                    //CHEKME!!! too heavy, better to get only new deal with data
                    actionSecuritization.getDealsWithChanges()(dispatch)
                }
                else if (message.status == eDealStatus.STATUS_REMOVED || message.status == eDealStatus.STATUS_ARCHIVED) {
                    var dealIndex = assignedDeals.findIndex(x => x.deal.id == message.dealID)
                    if (dealIndex == -1)
                        return;

                    console.log('---remove deal', message.dealID, message);

                    var deals = [...assignedDeals]
                    deals.splice(dealIndex, 1);

                    setAssignedDeals(deals)
                    setSelectedDealIndex(null)
                    actionSecuritization.initialDeal()(dispatch)
                }
                else if (message.status == eDealStatus.STATUS_FINISHED) {
                    actionSecuritization.setDealField({ status: message.status, bondID: message?.bondID })(dispatch)

                }
            }

            if (message.dataState != null) {
                actionSecuritization.setDealField({ dataState: message.dataState })(dispatch);
            }

            if (message.dealChanges != null) {
                const newDealData = {}

                for (const [key, value] of Object.entries(message.dealChanges || {})) {
                    newDealData[key] = value?.newValue
                }

                for (const [key, value] of Object.entries(deal?.onlineDealChanges || {})) {
                    newDealData[key] = value?.newValue
                }

                actionSecuritization.setDeal({
                    ...deal,
                    deal: { ...deal?.deal, ...newDealData },
                    dealChanges: { ...deal?.dealChanges, ...message?.dealChanges }
                })(dispatch)
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    const handleValidationChange = (message) => {
        try {

            console.log('===========incoming validation notification', message);

            if (message == null)
                return;

            if (message.dealID != null) {

                if (deal == null) {
                    console.log("=====handleValidationChange deal null");
                    return;
                }

                if (deal.deal.id != message.dealID) {
                    console.log(`=====handleValidationChange deal ${message.dealID} not found`);
                    return;
                }

                if (deal.deal.validationState == message.dealValidationState) {
                    console.log(`=====handleValidationChange deal validation state ${message.dealValidationState} not changed`);
                    return;
                }

                actionSecuritization.setDealField({ validationState: message.dealValidationState })(dispatch);
            }

            if (message.nodeID != null) {

                if (selectedNode == null) {
                    console.log("=====handleValidationChange selectedNode null");
                    return;
                }

                if (selectedNode.node.id != message.nodeID) {
                    console.log(`=====handleValidationChange selectedNode ${message.nodeID} not found, message:`, message);
                    return;
                }

                if (selectedNode.node.validationState == message.nodeValidationState) {
                    console.log(`=====handleValidationChange node validation state ${message.dealValidationState} not changed`);
                    return;
                }

                console.log(`=====handleValidationChange node validation state CHANGED ${message.dealValidationState} `);
                actionSecuritization.setNodeData("validationState", message.nodeValidationState)(dispatch);
            }

        }
        catch (e) {
            console.error(e);
        }
    }

    const getOnlineUserList = (message) => {
        if (message == null || message?.iterationChanges == null)
            return null;

        if (message?.iterationChanges == null)
            return;

        if (message.iterationID) {
            const users = Array.from(new Set(Object.keys(message.iterationChanges).map(key => {
                if ((message.iterationChanges[key]?.userID !== 1) && message.iterationChanges[key]?.userID) {
                    return message.iterationChanges[key]?.userName
                }
            }).filter(val => val != null)))
            let arr = {}
            arr[message.iterationID] = users
            setCurrentOnlineUsers({ ...currentOnlineUsers, ...arr })
        }
    }

    const onCreateDealButtonClick = () => {
        setIsCreateDialogOpened(true)
    }

    const onOpenDeal = (index) => {
        setShowPoolsCompare(false)
        setSelectedDealIndex(index)
        setDealId(assignedDeals[index].deal.id)
        actionSecuritization.getDealWithChanges(assignedDeals[index].deal.id)(dispatch)
    }

    const onApplyChanges = (value) => {
        var formData = new FormData();

        formData.append('OriginatorID', value.originator.id)
        formData.append('Name', value.dealName)
        formData.append('Type', value.dealType)

        actionSecuritization.createDeal(formData)(dispatch)
    }

    const onCancelChanges = () => {
        setIsCreateDialogOpened(false)
    }

    const removeDeal = (dealId) => {
        setDealIdToRemove(dealId)
        setIsConfirmDealRemoveDialogOpened(true)
    }

    const onCloseConfirmationDealRemove = () => {
        setDealIdToRemove(null)
        setIsConfirmDealRemoveDialogOpened(false)
    }

    const onConfirmRemoveDeal = () => {
        alert('will be soon');
    }

    const onCancelRemoveDeal = () => {
        onCloseConfirmationDealRemove();
    }

    const downloadTemplate = () => {
        // console.log('download template')
        window.open('/files/Шаблон для загрузки пула.xlsx', '_blank')
    }

    const comparePools = () => {
        actionSecuritization.setDeal(null)(dispatch)
        actionSecuritization.setNode(null)(dispatch)
        setShowPoolsCompare(true)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('SecuritizationPageNameInstrumentTitle')}</title>
            </Helmet>
            {/* <Notifications /> */}
            <Container
                maxWidth="xxl"
                className={classes.root}
            >
                {!(permissions?.isAdmin || permissions?.isUser || permissions?.isManager) ?
                    <Box>Доступ ограничен. Свяжитесь с техподдержкой</Box>
                    : <>
                        <CreateDealDialog
                            deals={assignedDeals}
                            open={isCreateDialogOpened}
                            dealTypes={dealTypes}
                            //availableManagers={availableManagers}
                            originators={originators}
                            onApplyChanges={onApplyChanges}
                            onCancelChanges={onCancelChanges}
                            isBusy={creatingDealEntity}
                            downloadTemplate={downloadTemplate}
                        />

                        <ConfirmDialog2
                            show={isConfirmDealRemoveDialogOpened}
                            description={t('AreYouSureToRemoveDeal')}
                            onConfirm={onConfirmRemoveDeal}
                            onCancel={onCancelRemoveDeal}
                            onClose={onCloseConfirmationDealRemove}
                        />

                        <DealsList
                            key='dialsListComp'
                            selectedDeal={selectedDealIndex}
                            deals={assignedDeals}
                            createDeal={onCreateDealButtonClick}
                            openDeal={onOpenDeal}
                            removeDeal={removeDeal}
                            comparePools={comparePools}
                            intermediate={dealsLoading}
                            currentOnlineUsers={currentOnlineUsers}
                        />

                        <div className={classes.infoPanel}>
                            {showPoolsCompare ?
                                <ComparePools />
                                : <DealTemplate
                                    downloadTemplate={downloadTemplate}
                                    creatingPoolEntity={creatingPoolEntity}
                                    editPermission={editPermission}
                                />
                            }
                        </div>
                    </>}
            </Container>
        </>
    )
}

export default SecuritizationDealsPage