import { useState, useRef, useEffect, useMemo, useLayoutEffect } from 'react'
import { Box, Grid, alpha } from '@mui/material'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import style from '@Styles/style-main.scss'
import { axisGridLineColor, axisLabelsColor, axisLineColor, axisTitleColor, plotBorderColor } from '@Base/components/constants/charts'
import { t } from 'i18next'
import { makeStyles } from '@mui/styles'
import { fontStyle } from '@mui/system'

const useStyles = makeStyles({
  slideHeader: {
    width: '100%',
    textAlign: 'left',
    marginBottom: '7px',
    fontSize: '1.145rem',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: '0.5rem',
    border: `solid ${style.colorPrimary3_40} 1px`,
    borderRadius: '12px'
  },
  cardRow: {
    color: style.colorPrimary3_40,
    '& b': { color: style.colorPrimary3 }

  },
  leftColumn: {
    alignItems: 'center',
    position: 'relative',
  },
  rightColumn: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  cellTitle: {
    textAlign: 'left',
    alignContent: 'center',
  },
  cell: {
    display: 'flex',
    position: 'relative',
    height: '217px',
    overflow: 'hidden',
    alignItems: 'center',
    // border: 'solid 1px'
  },
  cellText: {
    fontSize: '1rem',
    textAlign: 'justify',
    display: 'flex',
    alignItems: 'center',
    '& gr': {
      color: style.colorPrimary3_60
    },
    '& a': {
      color: style.colorPrimary2
    }
  }
})
const slideData = require('./dataSlide2.json')

const Card = ({ index = null }) => {
  const cls = useStyles()

  if (index == null || index > slideData.cards?.length - 1)
    return


  return <Box className={cls.card}>
    <Box className={cls.cardRow}><b>{slideData.cards[index].title}</b></Box>
    <Box className={cls.cardRow}>ставка <b>{slideData.cards[index].percent}%</b> год.</Box>
    <Box className={cls.cardRow}>выдержка <b>{slideData.cards[index].maturity}</b> {slideData.cards[index].maturityUnit}</Box>
  </Box>
}

const Slide2 = ({ step = 0 }) => {
  const cls = useStyles()

  const chartColor = [
    { id: 0, color: 'rgb(50,72,77)', isActive: true },
    { id: 1, color: 'rgb(139, 191, 70)', isActive: true },
    { id: 2, color: 'rgb(192, 223, 150)', isActive: true },
    { id: 3, color: 'rgb(50,72,77)', isActive: true },
    { id: 4, color: 'rgb(139, 191, 70)', isActive: true },
    { id: 5, color: 'rgb(192, 223, 150)', isActive: true },
  ]

  const serieTemplate = {
    name: null,
    id: null,
    color: null,
    data: null,
    animation: false,
    // animation: { duration: 2000, easing: 'easeInOutElastic' },
    showInLegend: false,
    type: 'line',
    // step: 'left',
    stickyTracking: false,
    pointPadding: 0,
    xAxis: 0,
    yAxis: 0,
    lineWidth: 2,
    marker: {
      enabled: false,
    },
    zIndex: 60,
    visible: true,
  }

  const chartTemplate = {
    exporting: {
      enabled: false,
    },
    chart: {
      marginLeft: 40, // Keep all charts left aligned
      padding: 0,
      marginBottom: 30,
      marginTop: 30,
      marginRight: 40,
      plotBorderWidth: 0,
      plotBorderColor: plotBorderColor,
      events: {
      },
    },

    title: {
      text: ''
    },

    credits: {
      enabled: false
    },

    plotOptions: {
      labels: {
        useHTML: true
      },
      series: {
        pointPlacement: 'on',
        events: {
          legendItemClick: function () {
            return false; // Prevent the default legendItemClick behavior
          }
        },
        marker: { enabled: false, },
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
          normal: { enabled: false },
          select: { enabled: false }
        },
      },
    },
    legend: { enabled: false },
    xAxis: [{
      type: 'datetime',
      visible: true,
      crosshair: false,
      // offset:-100,
      gridLineWidth: 1,
      gridLineColor: axisGridLineColor,
      lineWidth: 1,
      lineColor: axisLineColor,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          lineHeight: '0.7rem',
          fontSize: '0.7rem',
          color: axisLabelsColor,
        },
        y: 17,
        formatter: function () {
          if (this.value == null)
            return ''
          const date = new Date(this.value)
          const y = date.getFullYear()
          const m = date?.getMonth()
          const d = date?.getDate()
          if (m == 0 && d == 1 && y % 5 == 0) {
            return y
          }

          return null
        },
      },
      tickPositioner: function (min, max) {
        const startYear = new Date(min).getFullYear() + 1
        const endYear = new Date(max).getFullYear()
        var positions = Array.from({ length: endYear - startYear + 1 }, (x, i) => new Date(min).setFullYear(startYear + i, 0, 1))
        return positions;
      },
      tickWidth: 0,
      minPadding: 0,
      maxPadding: 0,
    }],
    yAxis: [{
      crosshair: false,
      // gridLineColor: axisGridLineColor,
      gridLineWidth: 1,
      lineWidth: 1,
      lineColor: axisLineColor,
      tickInterval: 5,
      alignTicks: true,
      title: {
        text: null,
        x: 5,
        style: {
          fontSize: '0.8rem',
          color: axisTitleColor,
        }
      },
      labels: {
        x: -5,
        style: {
          lineHeight: '0.7rem',
          fontSize: '0.7rem',
          color: axisLabelsColor,
        }
      },
      tickWidth: 0,
    }],

    tooltip: {
      enabled: false,
    },

    series: null
  }

  const [chartOptions1, setChartOptions1] = useState(null)
  const [chartOptions2, setChartOptions2] = useState(null)
  const [chartOptions3, setChartOptions3] = useState(null)
  const [chartOptions4, setChartOptions4] = useState(null)
  const [chartOptions5, setChartOptions5] = useState(null)
  const [chartOptions6, setChartOptions6] = useState(null)

  useLayoutEffect(() => {
    //   {
    //     "date": "2024-08-01",
    //     "incentive1": "-4.60",
    //     "incentive2": "-4.60",
    //     "incentive3": "-13.60",
    //     "cpr1": "12.89",
    //     "cpr2": "9.50",
    //     "cpr3": "7.63"
    // },
    // const categories = slideData.data.map(x => {
    //   return new Date(x.date).getTime()
    // })
    // const newxAxis = chartTemplate.xAxis
    // newxAxis[0].categories = categories
    const data1 = []
    const data2 = []
    const data3 = []

    const data4 = []
    const data5 = []
    const data6 = []
    slideData.data.forEach(x => {
      const date = new Date(x.date).getTime()
      data1.push([date, x.incentive1])
      data2.push([date, x.incentive2])
      data3.push([date, x.incentive3])
      data4.push([date, x.cpr1])
      data5.push([date, x.cpr2])
      data6.push([date, x.cpr3])
    })
    const yAxis = { ...chartTemplate.yAxis[0], title: { ...chartTemplate.yAxis[0].title }, min: -15, max: 5, tickInterval: 5 }
    yAxis.title.text = t("convention2.incentiveAxisTitle")

    // const xAxis = { ...chartTemplate.xAxis[0], lineWidth: 0, labels: { enabled: false } }

    const yAxis2 = { ...chartTemplate.yAxis[0], title: { ...chartTemplate.yAxis[0].title }, min: 0, max: 30, tickInterval: 10 }
    yAxis2.title.text = t("convention2.cprAxisTitle")

    // const xAxis2 = { ...chartTemplate.xAxis[0], lineWidth: 0, labels: { enabled: false } }

    const newChart1 = {
      ...chartTemplate,
      yAxis: [yAxis],
      // xAxis: [xAxis],
      series: [{ ...serieTemplate, id: 'incentive1', name: 'incentive1', color: chartColor[0].color, data: (step == 0 || step == 1 || step == 2) ? data1 : null }]
    }

    const newChart4 = {
      ...chartTemplate,
      yAxis: [yAxis2],
      // xAxis: [xAxis2],
      series: [{ ...serieTemplate, id: 'cpr1', name: 'cpr1', color: chartColor[0].color, data: (step == 0 || step == 1 || step == 2) ? data4 : null }]
    }

    const newChart2 = {
      ...chartTemplate,
      yAxis: [yAxis],
      // xAxis: [xAxis],
      series: [{ ...serieTemplate, id: 'incentive2', name: 'incentive2', color: chartColor[1].color, data: (step == 1 || step == 2) ? data2 : null }]
    }

    const newChart5 = {
      ...chartTemplate,
      yAxis: [yAxis2],
      // xAxis: [xAxis2],
      series: [{ ...serieTemplate, id: 'cpr2', name: 'cpr2', color: chartColor[1].color, data: (step == 1 || step == 2) ? data5 : null }]
    }

    const newChart3 = {
      ...chartTemplate,
      yAxis: [yAxis],
      series: [{ ...serieTemplate, id: 'incentive3', name: 'incentive3', color: chartColor[2].color, data: (step == 2) ? data3 : null }]
    }

    const newChart6 = {
      ...chartTemplate,
      yAxis: [yAxis2],
      series: [{ ...serieTemplate, id: 'cpr3', name: 'cpr3', color: chartColor[2].color, data: (step == 2) ? data6 : null }]
    }

    setChartOptions1(newChart1)
    setChartOptions4(newChart4)

    setChartOptions2(newChart2)
    setChartOptions5(newChart5)

    setChartOptions3(newChart3)
    setChartOptions6(newChart6)
  }, [step])

  // useLayoutEffect(() => chart1Ref?.current?.chart.redraw(), [chartOptions1])

  return (<>
    <Grid container>
      <Grid item className={cls.leftColumn} xs={12}>
        <Box className={cls.slideHeader}>
          На основе траектории среднемесячной рыночной ставки рефинансирования ипотеки и модели S-кривых для <b><u>каждого</u></b> кредита в ипотечном покрытии моделируется траектория темпа досрочных погашений CPR:
        </Box>
        {/* <Grid container>
        <Grid item className={cls.cellTitle} xs={1.5}></Grid>
        <Grid item className={cls.cellTitle} xs={3.60}>{t("convention2.incentiveCardsTitle")}</Grid>
        <Grid item className={cls.cellTitle} xs={3.60}>{t("convention2.cprCardsTitle")}</Grid>
        <Grid item className={cls.cellTitle} xs={3.3}></Grid>
      </Grid> */}

        <Grid container>
          <Grid item className={cls.cell} xs={1.5}>
            {(step == 0 || step == 1 || step == 2) && <Card index={0} />}
          </Grid>
          <Grid item className={cls.cell} xs={3.60}>
            {chartOptions1?.series && chartOptions1?.series[0]?.data?.length &&
              <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
                options={chartOptions1 ? chartOptions1 : {}}
              />
            }
          </Grid>
          <Grid item className={cls.cell} xs={3.60}>
            {chartOptions4?.series && chartOptions4?.series[0]?.data?.length &&
              <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
                options={chartOptions4 ? chartOptions4 : {}}
              />
            }
          </Grid>
          <Grid item className={cls.cellText} xs={3.3}>
            {(step == 0 || step == 1 || step == 2) && <div dangerouslySetInnerHTML={{ __html: slideData.text[0] }} />}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={cls.cell} xs={1.5}>
            {(step == 1 || step == 2) && <Card index={1} />}
          </Grid>
          <Grid item className={cls.cell} xs={3.60}>
            {chartOptions2?.series && chartOptions2?.series[0]?.data?.length &&
              <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
                options={chartOptions2 ? chartOptions2 : {}}
              />
            }
          </Grid>
          <Grid item className={cls.cell} xs={3.60}>
            {chartOptions5?.series && chartOptions5?.series[0]?.data?.length &&
              <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
                options={chartOptions5 ? chartOptions5 : {}}
              />
            }
          </Grid>
          <Grid item className={cls.cellText} xs={3.3}>
            {(step == 1 || step == 2) && <div dangerouslySetInnerHTML={{ __html: slideData.text[1] }} />}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={cls.cell} xs={1.5}>
            {(step == 2) && <Card index={2} />}
          </Grid>
          <Grid item className={cls.cell} xs={3.60}>
            {chartOptions3?.series && chartOptions3?.series[0]?.data?.length &&
              <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
                options={chartOptions3 ? chartOptions3 : {}}
              />
            }
          </Grid>
          <Grid item className={cls.cell} xs={3.60}>
            {chartOptions6?.series && chartOptions6?.series[0]?.data?.length &&
              <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
                options={chartOptions6 ? chartOptions6 : {}}
              />
            }
          </Grid>
          <Grid item className={cls.cellText} xs={3.3}>
            {(step == 2) && <div dangerouslySetInnerHTML={{ __html: slideData.text[2] }} />}
          </Grid>
        </Grid>

      </Grid>
      {/* <Grid item
        className={cls.rightColumn}
        xs={3}>
        {slideData.text[step]}
      </Grid> */}
    </Grid >

    {/* <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
      options={chartOptions ? chartOptions : {}}
    /> */}


  </>
  )
}

export default Slide2