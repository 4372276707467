import React, { useEffect, useState } from 'react'
import style from '@Styles/domrf-theme.module.scss'
import { Box, FormLabel, InputAdornment, Switch, TextField, alpha } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { actionCreators as actionBonds } from '@Base/store/BondsStore'
import { actionCreators as actionDomRf } from '@Base/store/DomRfStore'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import useFilter from '../hooks/useFilter'
import { t } from 'i18next'
import { CheckBox, CheckBoxOutlineBlank, Clear } from '@mui/icons-material'
import useLocalStorage from '@Base/components/hook/useLocalStorage'
import CustomBox from '@Base/components/UI/CustomBox'

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        position: 'absolute',
        left: 0, top: 0, right: '0.5rem', bottom: 0,
    },
    row: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        cursor: 'pointer',
        padding: '4px 0',
        alignItems: 'center',
        '&>*:not(:first-child)': {
            marginLeft: '5px',
        },
        '&:hover': {
            backgroundColor: alpha(style.colorPrimary3, 0.2)
        }
    },
    bondName: {
        flexGrow: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    bondIsin: {
        color: style.colorPrimary3_60,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingRight: '5px'
    },
    virtualScrollablePanel: {
        flexGrow: 1,
        // overflow: 'auto',
        '& *': { userSelect: 'none', },
        '& .Mui-selected': {
            // backgroundColor: `#e4f1d5 !important`,
            backgroundColor: `#e4f1d500 !important`,
        },
        position: 'absolute', left: '4px', right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        padding: 0,
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '6px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            // '&::-webkit-scrollbar-track': {
            //     backgroundColor: '#00000008',
            // },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px',
        },
    },
    searchBlock: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        // backgroundColor: alpha(style.colorPrimary3, 0.1),
        // borderRadius: '6px',
        padding: '4px 8px',
    },
    // searchText: {
    //     lineHeight: '2rem',
    //     userSelect: 'none',
    // },
    filterCkecked: {

    },
    filterInput: {
        // marginLeft: '0.5rem',
    },
    dataBlock: {
        // marginTop: '0.5rem',
        position: 'relative',
        flexGrow: 1
    },
    bondCheckbox: {
        paddingLeft: '5px',
        display: 'inline-flex',
    },
    ckeckboxIcon: {
        color: style.colorPrimary3_60
    },
    ckeckboxIconDisabled: {
        opacity: 0.5,
        cursor: 'default',
    },
    inputEndAdornment: {
        cursor: 'pointer',
    },
    inputEndAdornmentIcon: {
        width: '20px',
        height: '20px',
    }
}))

const CompareBondList = () => {
    const cls = useStyles()
    const dispatch = useDispatch()
    const {
        count, items: bonds,
        ...otherStore
    } = useSelector((state) => state.bonds)
    const [showOnlySelected, setShowOnlySelected] = useState(false)
    const [textFilter, setTextFilter] = useState('')
    const [filterParams, setFilterParams] = useState([
        { field: 'name', value: textFilter },
        { field: 'isin', value: textFilter },])
    const [selectedBonds, setSelectedBonds] = useLocalStorage(new Array([null, null, null, null, null, null, null]), 'quotes/selectedBonds')
    const [filterSelected, setFilterSelected] = useState([])

    useEffect(() => {
        const newFilterSelected = [...selectedBonds?.map(bondId => { return { field: 'id', value: bondId } })]

        if (!showOnlySelected)
            setFilterSelected(newFilterSelected)

        dispatch(actionDomRf.setData({ selectedBonds: selectedBonds }))
    }, [selectedBonds, showOnlySelected])

    useEffect(() => {
        const newFilterParams = [...filterParams?.map(filter => { return { ...filter, value: textFilter } })]
        setFilterParams(newFilterParams)
    }, [textFilter])


    const filtredBonds = useFilter(bonds, showOnlySelected ? filterSelected : filterParams)

    // console.log('otherStore', otherStore)
    // console.log('filtredBonds', bonds, filtredBonds)
    useEffect(() => {
        dispatch(actionBonds.getBonds())
    }, [])

    const customBondNameComparator = (a = null, b = null) => {

        const a_digs = a?.match(/([0-9]+)/g)?.join().padStart(10, '0').slice(-5)
        const a_ = a?.toUpperCase()?.replace(/([0-9]+)/g, a_digs ? a_digs : '')
        const b_digs = b?.match(/([0-9]+)/g)?.join().padStart(10, '0').slice(-5)
        const b_ = b?.toUpperCase()?.replace(/([0-9]+)/g, b_digs ? b_digs : '')

        // console.log('(a_ > b_) ? 1 : (a_ < b_) ? -1 : 0', (a_ > b_) ? 1 : (a_ < b_) ? -1 : 0)
        return (a_ > b_) ? 1 : (a_ < b_) ? -1 : 0
    }

    const handleBondClick = (bondId) => {
        const newSelectedBond = [...selectedBonds]

        const bondIndex = newSelectedBond?.findIndex(id => id == bondId)
        if (bondIndex == -1) {
            const freeIndex = newSelectedBond?.findIndex(id => id == null)
            if (freeIndex != -1) {
                newSelectedBond[freeIndex] = bondId
            } else
                newSelectedBond.push(bondId)
        } else {
            newSelectedBond[bondIndex] = null
        }

        // const index = selectedBonds.indexOf(bondId)
        // if (index == -1) {
        //     newSelectedBond.push(bondId)
        // }
        // else {
        //     newSelectedBond.splice(index, 1)
        // }
        setSelectedBonds(newSelectedBond)
    }
    const checkDisableConditions = () => {
        // console.log('selectedBonds', selectedBonds)
        if (selectedBonds?.filter(id => id != null)?.length >= 7)
            return true

        return false
    }

    return (
        <Box className={cls.root}>
            <CustomBox
                label={t('quotes.filters')}
            >
                <Box
                    className={cls.searchBlock}>
                    <Box className={cls.filterCkecked}>
                        {t('quotes.onlySelected')}
                        <Switch
                            color='secondary'
                            size='small'
                            checked={showOnlySelected}
                            onChange={() => setShowOnlySelected(!showOnlySelected)}
                        />
                    </Box>
                    {!showOnlySelected &&
                        <TextField
                            size='small'
                            fullWidth
                            className={cls.filterInput}
                            inputProps={{ style: { textAlign: 'right' } }}
                            value={textFilter}
                            label={t('quotes.search')}
                            onChange={(e) => setTextFilter(e.target.value)}
                            InputProps={
                                {
                                    endAdornment: showOnlySelected ? null : <InputAdornment
                                        onClick={() => setTextFilter('')} className={cls.inputEndAdornment}
                                    >
                                        <Clear className={cls.inputEndAdornmentIcon} />
                                    </InputAdornment>
                                }
                            }
                        />}
                </Box>
            </CustomBox>
            <Box className={cls.dataBlock}>
                <Box className={cls.virtualScrollablePanel}>
                    {[...filtredBonds] //для правильной работы SORT 
                        ?.sort((a, b) => customBondNameComparator(a.name, b.name))
                        ?.map(bond => {
                            const isSelected = selectedBonds?.some(bondId => bondId == bond.id)
                            return <Box key={`row_bond_${bond?.id}`}
                            className={[cls.row, checkDisableConditions() && !isSelected ? cls.ckeckboxIconDisabled : ''].join(' ')}
                            onClick={() => (!checkDisableConditions() || isSelected) && handleBondClick(bond.id)}
                            >
                                <Box className={cls.bondCheckbox} >
                                    {selectedBonds?.some(bondId => bondId == bond.id)
                                        ? <CheckBox className={cls.ckeckboxIcon} />
                                        : <CheckBoxOutlineBlank className={cls.ckeckboxIcon} />}
                                </Box>
                                <Box className={cls.bondName}>{bond.name}</Box>
                                <Box className={cls.bondIsin}>{bond.isin}</Box>
                            </Box>
                        })}
                </Box>
            </Box>
        </Box >
    )
}

export default CompareBondList