import { hexToRgbA, roundDown, roundUp } from '@Base/Utilities'
import style from '@Styles/domrf-theme.module.scss';
import Localization from '@Base/components/Localization'
import { Box, alpha, debounce } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { t } from 'i18next'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react'
// import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { useSelector, useDispatch } from 'react-redux'
import useMobileDetect from '@Base/components/hook/useMobileDetect'
import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import SpinnerBars from '@Base/components/shared/SpinnerBars';
import { useNewDispatch, useNewSelector } from '@Base/index';
import { getSCurveGraphWithPool } from '@Base/newStore/conventionSlice';
import { axisGridLineColor, axisLabelsColor, axisLineColor, axisTitleColor, plotBorderColor } from '@Base/components/constants/charts';

const useStyles = makeStyles({
  grayed: {
    filter: 'grayscale(1)',
    opacity: 0.5,
  },
  decreaseFontSize: {
    fontSize: '0.8em',
  },
  root: {
    flexGrow: 1,
    position: 'relative',
    boxSizing: 'border-box',
  },
  rootData: {
    position: 'absolute',
    top: 0, left: 0, bottom: 0, right: 0,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: '0.5rem',
    alignItems: 'center',
    '&>*': { width: '100%' },
  },
  buttonsRoot: {
    overflow: 'visible',
    minHeight: '23px',
    height: '23px',
    // width: '150px',
    // marginBottom: '55px',
  },
  buttonsBox: {
    margin: 'auto',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsContaner: {
    display: 'flex',
    flexDirection: 'row',
    // margin: 'auto',
    textAlign: 'center',
    '&:has($buttonBox:is(.active):hover)': {
      '& $buttonBox:not(:hover) $button': {
        filter: 'grayscale(1)',
        opacity: 0.5,
        outline: `solid 1px ${style.colorPrimary3}`,
        backgroundColor: `${alpha(style.colorPrimary3, 0.5)} !important`,
        '&>*': {
          color: `white !important`,
        },
      }
    }
  },

  disabled: {
    '& $button': {
      filter: 'grayscale(1)',
      opacity: 0.5,
      outline: `solid 1px ${style.colorPrimary3}`,
      backgroundColor: `${alpha(style.colorPrimary3, 0.5)} !important`,
      '&>*': {
        color: `white !important`,
      },
      cursor: 'auto !important'
    }
  },
  buttonBox: {
    padding: '0px 4px',
    fontSize: '0.9rem',
  },
  button: {
    '&>*': {
      filter: 'grayscale(1) brightness(0.8) invert(1) contrast(50)'
    },
    fontFamily: 'DINPro',
    borderRadius: '0.2rem',
    overflow: 'hidden',
    padding: '0.2rem',
    minWidth: '120px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    fontWeight: 100,
    cursor: 'pointer',
  },
  chartRoot: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  functionBlock: {
    height: '1.5rem',
    // marginLeft: '40px',
    marginTop: '0.3rem',
    fontSize: '1.3rem',
    textAlign: 'center',
    '& sub': {
      fontSize: '0.8rem'
    }
    // zIndex: 999,
    // position: 'absolute',
    // top: '20px',
    // margin: '0 auto',
  },
  functionText: {
    lineHeight: '1.3rem',
  },
  '& sub': {
    fontSize: '0.7rem',
  }
})

const DetailedSCurveChartV2 = forwardRef(({ showDebt = false, setReadyForExport }, ref) => {

  const sCurvesColor = useRef(null)


  // console.log('showDebt', showDebt)
  const cls = useStyles();
  const device = useMobileDetect();
  const sCurveChartRef = useRef();
  const [bondResults, setBondResults] = useState(null)
  const [bettas, setBettas] = useState({})
  // const [hoveredLoanButton, setHoveredLoanButton] = useState(null)
  // const {
  //   selectedBond = null,
  //   calculationParameters = null,
  //   poolStatistics = null,
  //   sCurvesGraphLoading = false, sCurvesGraph = null,
  //   ...otherStore 
  // } = useSelector((state) => state.convention)

  const {
    selectedBond = null,
    calculationParameters = null,
    poolStatistics = null,
    sCurvesGraphLoading = false, sCurvesGraph = null,
    pricingParameters = null,
    ...otherStore } = useNewSelector((state) => state.newConvention)
  const dispatch = useDispatch()
  const newDispatch = useNewDispatch()

  console.log('sCurvesGraph', sCurvesGraph)
  useLayoutEffect(() => {
    if (sCurvesGraph == null /*|| selectedBond?.isin == null*/) {
      sCurvesColor.current = null
      return
    }

    if (sCurvesGraph?.poolData?.length == 1)
      sCurvesColor.current = [{ loanAgeId: 0, key: 'больше 0 лет', color: 'rgb(61, 66, 89)', valColor: 'rgb(241,241,241)', isActive: true }]
    else
      sCurvesColor.current = [
        { loanAgeId: 0, key: 'меньше 1 года', color: 'rgb(61, 66, 89)', valColor: 'rgb(124,152,180)', isActive: true },
        { loanAgeId: 1, key: 'от 1 до 2 лет', color: 'rgb(101, 97, 112)', valColor: 'rgb(180,192,203)', isActive: true },
        { loanAgeId: 2, key: 'от 2 до 3 лет', color: 'rgb(135, 122, 131)', valColor: 'rgb(207,212,214)', isActive: true },
        { loanAgeId: 3, key: 'от 3 до 4 лет', color: 'rgb(168, 148, 151)', valColor: 'rgb(234,231,225)', isActive: true },
        { loanAgeId: 4, key: 'от 4 до 5 лет', color: 'rgb(197, 170, 167)', valColor: 'rgb(245,239,230)', isActive: true },
        { loanAgeId: 5, key: 'от 5 до 6 лет', color: 'rgb(216, 179, 171)', valColor: 'rgb(245,239,230)', isActive: true },
        { loanAgeId: 6, key: 'от 6 до 7 лет', color: 'rgb(223, 174, 161)', valColor: 'rgb(245,239,230)', isActive: true },
        { loanAgeId: 7, key: 'от 7 до 8 лет', color: 'rgb(229, 169, 152)', valColor: 'rgb(245,239,230)', isActive: true },
        { loanAgeId: 8, key: 'от 8 до 9 лет', color: 'rgb(238, 164, 140)', valColor: 'rgb(245,239,230)', isActive: true },
        { loanAgeId: 9, key: 'больше 9 лет', color: 'rgb(245, 159, 130)', valColor: 'rgb(245,239,230)', isActive: true },
      ]

  }, [sCurvesGraph, selectedBond?.isin])

  useEffect(() => {

    // actionConvention.getSCurveGraphWithPool(
    //   selectedBond?.isin,
    //   calculationParameters?.calculationSCurvesReportDate,
    //   poolStatistics?.reportDate,
    //   poolStatistics?.currentRefinancingRate,
    //   null,
    //   null,
    //   0.1)(dispatch)
    console.log('pricingParameters', pricingParameters)
    console.log('poolStatistics', poolStatistics)
    newDispatch(getSCurveGraphWithPool(
      {
        bondId: pricingParameters?.bondID,
        scurveDate: calculationParameters?.calculationSCurvesReportDate,
        poolReportDate: poolStatistics?.reportDate,
        refinancingRate: poolStatistics?.currentRefinancingRate,
        minIncentive: null,
        maxIncentive: null,
        step: 0.1
      }))
  }, [calculationParameters?.calculationSCurvesReportDate])

  // console.log('sCurvesGraph', sCurvesGraph)
  // console.log('otherStore', otherStore)
  useImperativeHandle(ref, () => {
    // console.log('renew ImpHandle', chartData?.steps)
    return {
      exportChart: () => {
        if (sCurveChartRef.current) {
          // console.log('export=')
          sCurveChartRef.current?.chart?.exportChart()
        }
      }
    }
  })
  // console.log('sCurveGraph', sCurveGraph)

  // const LEFT_LIMMIT = -14
  // const RIGHT_LIMMIT = 8
  // const STEP = 0.1

  // const calculateSeriesData = (bettas) => {
  //   const row = new Array()
  //   for (let s = LEFT_LIMMIT; s < RIGHT_LIMMIT; s += STEP) {
  //     const f = bettas[0] + bettas[1] * Math.atan(bettas[2] + bettas[3] * s) + bettas[4] * Math.atan(bettas[5] + bettas[6] * s)
  //     row.push(Math.round(f * 10000) / 100)
  //   }
  //   return row
  // }

  const [series, setSeries] = useState([])

  useLayoutEffect(() => {
    if (sCurvesColor.current == null || sCurvesGraph?.data == null) {
      setSeries([])
      return
    }
    // console.log('update series processing')

    let cpr_series = []
    let emp_series = []
    let debts_series = []
    let distr_series = []
    const seriesCount = sCurvesGraph?.data?.length
    sCurvesGraph?.data?.toReversed()?.forEach(item => {
      // const parameters = {}
      // for (const [key, value] of Object.entries(item?.parameters)) {
      //   console.log('key, value', key, value)
      //   // if (key != 'reportDate')
      //   parameters[key] = Localization.FormatNumber(value, 2, 2)
      // }

      cpr_series.push(
        {
          name: 'cpr',
          id: `sCurve_${item?.loanAgeID}`,
          loanAgeId: item?.loanAgeID,
          type: 'line',
          pointPlacement: 'on',
          xAxis: 0,
          yAxis: 0,
          lineWidth: 2,
          color: getSerieColor('cpr', item?.loanAgeID),
          states: {
            hover: { enabled: false, },
          },
          marker: {
            enabled: false,
          },
          zIndex: 300,
          data: item?.cpr,
          visible: true,
        })

      emp_series.push(
        {
          name: 'empiricalCPR',
          id: `empiricalCurve_${item?.loanAgeID}`,
          loanAgeId: item?.loanAgeID,
          type: 'scatter',
          pointPlacement: 'on',
          lineWidth: 0,
          xAxis: 0,
          yAxis: 0,
          // color: alpha(sCurvesColor[item?.loanAgeID]?.color, 0.8),
          states: {
            hover: { enabled: false, },
          },
          visible: false,
          marker: {
            radius: 3,
            fillColor: alpha(getSerieColor('empiricalCRP', item?.loanAgeID), 0.5),
            lineColor: alpha(getSerieColor('empiricalCRP', item?.loanAgeID), 0.8),
            //   enabled: false,
          },
          zIndex: 100,
          data: item?.empiricalCPR
        },
      )
      debts_series.push(
        {
          name: 'debt',
          id: `debtBars_${item?.loanAgeID}`,
          loanAgeId: item?.loanAgeID,
          type: "column",
          pointPlacement: 'on',
          // pointPlacement: 'between',
          // stacking: 'normal',
          // reversedStacks: false,
          xAxis: 0,
          yAxis: 1,
          grouping: false,
          pointPadding: 0.0, // Space between individual columns within a group
          groupPadding: 0.0, // Space between different groups of columns
          color: getSerieColor('debt', item?.loanAgeID),
          borderColor: alpha(style.colorPrimary3, 0.1),
          borderWidth: seriesCount > 1 ? 0 : 1,
          data: item?.debt,
          visible: showDebt,
          // showInLegend: !showDebt,
          zIndex: 1,// 100 - item?.loanAgeID,
          stack: 'debt',
        }
      )


    })//?.flat()
    // console.log('poolDebtDistribution', poolDebtDistribution)
    sCurvesGraph?.poolData?.toReversed()?.forEach(item => {
      // for (const [key, value] of Object.entries(item?.parameters)) {
      //   item.parameters[key] = Localization.FormatNumber(value, 2, 2)
      // }

      distr_series.push(
        {
          name: 'distribution',
          id: `pool_loanAge_${item?.loanAgeID}`,
          loanAgeId: item?.loanAgeID,
          type: "column",
          pointPlacement: 'on',
          // pointPlacement: 'between',
          // stacking: 'normal',
          // reversedStacks: false,
          xAxis: 0,
          yAxis: 1,
          grouping: false,
          pointPadding: 0.0, // Space between individual columns within a group
          groupPadding: 0.0, // Space between different groups of columns
          color: getSerieColor('distribution', item?.loanAgeID),
          borderColor: alpha(style.colorPrimary3, 0.1),
          borderWidth: seriesCount > 1 ? 0 : 1,
          data: item?.ood,
          visible: !showDebt,
          // showInLegend: !showDebt,
          zIndex: 1,// 100 - item?.loanAgeID,
          stack: 'distr',
        }
      )
    })//?.flat()

    const startLoanAgeId = (showDebt ?
      debts_series : distr_series).findLast(i => i.visible)?.loanAgeId
    // : distr_series.fingIndexLast(i => i.loanAgeId == item?.loanAgeId  && i.visible)

    if (sCurvesColor.current?.length > 1) {
      sCurvesColor.current?.forEach(item => {
        // console.log('distr_series.filter(i=>i.loanAgeId == item?.loanAgeID)[0]?.visible', distr_series.filter(i => i.loanAgeId == item?.loanAgeId)[0]?.visible ?? false, item?.loanAgeId)
        item.isActive = item.loanAgeId >= startLoanAgeId ? true : false
        // item.isActive = showDebt ?
        // debts_series.filter(i => i.loanAgeId == item?.loanAgeId)[0]?.visible ?? false
        // : distr_series.filter(i => i.loanAgeId == item?.loanAgeId)[0]?.visible ?? false
      })

      cpr_series?.forEach(item => {
        item.visible = sCurvesColor.current.filter(i => i.loanAgeId == item?.loanAgeId)[0]?.isActive
      })

      // sCurvesColor.current = sCurvesColor
    }
    // console.log('cpr_series', cpr_series)
    // console.log('emp_series', emp_series)
    // console.log('debts_series', debts_series)
    // console.log('distr_series', distr_series)
    setSeries([...cpr_series, ...emp_series, ...debts_series, ...distr_series])

  }, [selectedBond?.isin, sCurvesGraph, showDebt, sCurvesColor.current])

  const getPointValue = useCallback((point) => {

    // console.log('point', point?.series?.userOptions?.loanAgeId)
    switch (point?.series?.userOptions?.name) {
      case 'cpr':
        // console.log('sCurvesColor[point?.series?.userOptions?.loanAgeId]', sCurvesColor.current, point?.series?.userOptions?.loanAgeId)
        return ({ name: `${t('convention2.GraphCPRTooltipPart0')} ${sCurvesColor.current[point?.series?.userOptions?.loanAgeId]?.key}, ${t('percentInYear')}`, value: point.y, fraction: 1, sortOrder: point?.series?.userOptions?.loanAgeId })
        break
    }

    // return ({ name: 'SERIES', value: point?.series?.userOptions?.id })
  }, [sCurvesColor.current])
  // console.log('otherStore', otherStore)
  const [sCurveChartOptions, setsCurveChartOptions] = useState({
    //График Стимул к рефинансированию

    exporting: {
      enabled: false,
      filename: ``,
      scale: 2,
      chartOptions: {
        chart: {
          width: 1200,
          height: 665,
          marginLeft: 50, // Keep all charts left aligned
          marginRight: 50,
          padding: 0,
          plotBorderWidth: 0,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false
            }
          }
        },
        xAxis: {
          title: {
            enabled: true
            //     style: {
            //       fontSize: '8px' // Размер шрифта значений оси X при экспорте
          }
        },
        //   labels: {
        //     style: {
        //       fontSize: '8px' // Размер шрифта значений оси X при экспорте
        //     }
        //   }
        // },
        // yAxis: [{
        //   title: {
        //     style: {
        //       fontSize: '8px' // Размер шрифта значений оси X при экспорте
        //     }
        //   },
        //   labels: {
        //     style: {
        //       fontSize: '8px' // Размер шрифта значений оси X при экспорте
        //     }
        //   }
        // },
        // {
        //   title: {
        //     style: {
        //       fontSize: '8px' // Размер шрифта значений оси X при экспорте
        //     }
        //   },
        //   labels: {
        //     style: {
        //       fontSize: '8px' // Размер шрифта значений оси X при экспорте
        //     }
        //   }
        // }],
        // legend: {
        //   align: 'center',
        //   verticalAlign: 'bottom',
        //   itemDistance: 40,
        //   itemStyle: {
        //     fontSize: '7px',
        //     overflow: 'hidden',
        //   },
        //   floating: false
        // }
      },
    },
    legend: {
      enabled: false,
      margin: 0,
      y: 10,
      itemStyle: {
        cursor: 'default',
        lineHeight: '0.8rem',
        fontSize: '0.8rem',
        // color: style.colorPrimary3// Задать цвет легенды
      },
      itemHoverStyle: {
        // color: style.colorPrimary3
      },
      itemHiddenStyle: {
        opacity: 0,
      },
    },
    chart: {
      animation: false,
      marginLeft: 40, // Keep all charts left aligned
      marginRight: 50,
      // height: 300,
      //plotAreaHeight: 290,
      padding: 0,
      // plotBorderWidth:10,
      marginBottom: 45,
      // spacingBottom: 13,
      plotBorderWidth: 0,
      plotBorderColor: plotBorderColor,
      events: {
        // render: (e) => {
        //   if (e.target?.callback) {
        //     e.target?.callback(e.target)

        //   }
        //   // setTimeout(onResize, 0);
        // },
        load: function () {
          // var chart = this
          // chart.exportChart = function () {
          //   chart.series[3].setVisible(false) // Hide the 3d series before exporting
          //   Highcharts.Chart.prototype.exportChart.call(this)
          //   chart.series[3].setVisible(true) // Show the scatter series after exporting
          // };
        }
      },
    },

    // dataLabels: {
    //     enabled: true,
    //     formatter: function () {
    //         return '<span style="color: ' + this.color + '">' + this.point.label + '</span>';
    //     },
    // },

    title: {
      text: ''
    },

    credits: {
      enabled: false
    },

    plotOptions: {
      series: {
        animation: false,
        states: {
          inactive: {
            opacity: 0,
            enabled: false,
          },
          hover: {
            halo: {
              size: 0
            }
          }
        },
        marker: {
          symbol: 'circle', // Set the marker symbol to 'circle'
          radius: 2,
        },
        events: {
          legendItemClick: function () {
            return false; // Prevent the default legendItemClick behavior
          }
        }
      },
      column: {
        borderRadius: 0,
        stacking: 'normal',
        // reversedStacks: false,
        states: {
          inactive: {
            enabled: false,
          },
          hover: {
            enabled: false,
          }
        },
      },
      scatter: {
        color: style.colorPrimary2, // Set the color for all points
        // enableMouseTracking: true,
        zIndex: 100,
        marker: {
          radius: 6,
          lineWidth: 1,
          lineColor: style.colorPrimary3,
          symbol: 'circle' // Set the shape for all points
        },
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            enabled: false,
            //     halo: {
            //         size: 0
            //     }
          }
        },
      },
    },

    xAxis: [{
      crosshair: {
        color: alpha(style.colorPrimary3, 0.1)
      },
      // crosshair: true,
      // startOnTick: true,
      // endOnTick: true,
      minPixelPadding: 0,
      minPointOffset: 0,

      gridLineColor: axisGridLineColor,
      gridLineWidth: 1,
      gridLineDashStyle: 'solid',
      tickInterval: 10,
      startOnTick: true,
      endOnTick: true,
      minPadding: 0,
      maxPadding: 0,
      tickWidth: 0,
      // gridLineWidth: 1,
      // lineWidth: 1,
      // lineColor: style.colorPrimary1,
      title: {
        // enabled: false,
        text: t('convention2.GraphIncentiveToRefinance'),
        offset: 19,
        style: {
          fontSize: '1rem',
          color: axisTitleColor,
        }
      },
      labels: {
        rotation: 0,
        // step: 10,
        y: 17,
        allowOverlap: true,
        style: {
          lineHeight: '0.8rem',
          fontSize: '0.8rem',
          overflow: 'visible',
          textOverflow: 'visible',
          color: axisLabelsColor,
        }
      },
      lineWidth: 1,
      lineColor: axisLineColor,
      categories: [],
    },
      // {
      //   tickWidth: 0,
      //   minPadding: 0,
      //   maxPadding: 0,
      //   visible: false
      // }
    ],

    yAxis: [{
      crosshair: false,
      // startOnTick: true,
      // endOnTick: true,
      gridLineColor: axisGridLineColor,
      gridLineWidth: 1,
      lineWidth: 1,
      lineColor: axisLineColor,
      min: 0,
      max: 45,
      // tickInterval: 15,
      tickAmount: 10,
      alignTicks: true,
      title: {
        text: t('Convention2_CPRPrc'),
        // y: -85,
        x: 5,
        style: {
          fontSize: '1rem',
          color: axisTitleColor,
        }
      },
      labels: {
        x: -5,
        style: {
          lineHeight: '0.8rem',
          fontSize: '0.8rem',
          color: axisLabelsColor,
        }
      },
      // offset: 100, //for hide
      tickWidth: 0,
    },
    {
      // tickWidth: 0,
      min: 0,
      max: 900.0,
      offset: 0,
      opposite: true,
      lineWidth: 1,
      lineColor: axisLineColor,
      gridLineWidth: 0,
      tickAmount: 10,
      alignTicks: true,
      title: {
        fontSize: '1rem',
        text: '',
        offset: 30,
        style: {
          fontSize: '1rem',
          color: axisTitleColor,
        }
      },
      labels: {
        x: 5,
        style: {
          lineHeight: '0.8rem',
          fontSize: '0.8rem',
          color: axisLabelsColor,
        }
      },
      visible: true,
    }
    ],

    tooltip: {
      animation: false,
      shape: "square",
      positioner: function (labelWidth, labelHeight, point) {
        return {
          x: this.chart.plotLeft + 7,//this.chart.plotWidth - labelWidth - 3,
          y: this.chart.plotTop + 5//this.chart.plotHeight - labelHeight - 3
        };
      },
      formatter: function () {
        // console.log('this', this.points)
        if (this?.points == null || !this?.points?.length) return

        var points = [{ name: t('convention2.RateDifferencePrc'), value: this.x, fraction: 1 },]

        this.points?.forEach(point => {
          const val = getPointValue(point)
          if (val) points.push(val)
        })

        return points.sort((a, b) => a?.sortOrder < b?.sortOrder ? -1 : a?.sortOrder > b?.sortOrder ? 1 : 0)
          .filter((item, index) => {
            if (index == 0 || (index != 0 && points[index - 1]?.sortOrder != item?.sortOrder))
              return item
          })
          .reduce(function (s, point) {
            return s + `<div style='display:flex;flex-direction:row;justify-content:space-between;font-size:0.9rem'>
                  <div>${point.name}</div>
                  <div style='margin-left:1rem;min-width:2rem;text-align:right;font-weight:bold;'>${Localization.FormatNumber(point.value, point?.fraction ?? 0, point?.fraction ?? 0)}</div>
                  </div>`
          }, '')

        // return '<table style="min-width:200px;max-width:600px">' +
        //   points.reduce(function (s, point) {
        //     return s + '<tr><td style="padding:0">' + t(point.name) + ': </td>' +
        //       '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(point.value, point?.fraction ?? 0, point?.fraction ?? 0) + '</b></td></tr>'
        //   }, '') +
        //   '</table>';
      },
      hideDelay: 0,
      shared: true,
      useHTML: true
    },

    series: []
  })

  // console.log('series', series)
  // useLayoutEffect(() => {
  //   actionConvention.clearValue('calculationParameters')(dispatch)
  // }, [])

  useLayoutEffect(() => {
    // console.log('update Chart')
    updateChartData()
  }, [/*sCurvesGraph, showDebt*/ series])

  const restoreColors = () => {
    // console.log('mouseLeave')
    // setHoveredLoanButton(null)
    const seriesCount = sCurvesGraph?.data?.length
    const newSeries = [...series]
    newSeries.forEach((item, index) => {
      // newSeries[index].color = sCurvesColor[index].color
      if (item?.name == 'debt') {
        item.borderWidth = seriesCount > 1 ? 0 : 1
        item.color = alpha(getSerieColor(item?.name, item.loanAgeId), 1)
        item.visible = showDebt ? true : false
      }

      if (item?.name == 'distribution') {
        item.borderWidth = seriesCount > 1 ? 0 : 1
        item.color = alpha(getSerieColor(item?.name, item.loanAgeId), 1)
        item.visible = !showDebt ? true : false
      }

      if (item?.name == 'empiricalCPR') {
        item.visible = false
      }

      if (item?.name == 'cpr') {
        item.zIndex = 300
        item.lineWidth = 2
        item.color = alpha(getSerieColor(item?.name, item.loanAgeId), 1)
      }

      // if (item?.name != 'empiricalCPR') {
      //   item.color = alpha(getSerieColor(item?.name, item.loanAgeId), 1)
      //   if (item?.name == 'debt')
      //     item.borderWidth = 0
      //   if (item?.name == 'cpr') {
      //     item.zIndex = 300
      //     item.lineWidth = 2
      //   }
      // }
      // else
      //   item.visible = false
    })
    var newChart = { ...sCurveChartOptions, series: newSeries }
    setsCurveChartOptions(newChart)
    setBettas({})
  }

  const highlightSerie = useCallback(debounce((loanAgeId) => {
    // setHoveredLoanButton(loanAgeId)
    const newBettas = {}
    for (const [key, value] of Object.entries(sCurvesGraph?.data[loanAgeId]?.parameters)) {
      newBettas[key] = Localization.FormatNumber(value, 2, 2)
    }
    setBettas(newBettas)
    // console.log('loanAgeId', loanAgeId)
    const newSeries = [...series]
    const grey = alpha(style.colorPrimary3, 0.13)
    const lightgrey = alpha(style.colorPrimary3, 0)
    const seriesCount = sCurvesGraph?.data?.length
    newSeries.forEach(item => {
      if (item?.name == 'empiricalCPR') {
        if (item.loanAgeId == loanAgeId)
          item.visible = true
        else
          item.visible = false
      }

      if (item?.name == 'debt' || item?.name == 'distribution') {
        if (item.loanAgeId != loanAgeId) {
          item.borderWidth = 1
          item.color = alpha(getSerieColor(item?.name, item.loanAgeId), 0.1)
        }
        else
          item.color = alpha(getSerieColor(item?.name, item.loanAgeId), 1)
      }

      if (item?.name == 'cpr') {
        if (item.loanAgeId == loanAgeId) {
          item.color = alpha(getSerieColor(item?.name, item.loanAgeId), 1)
          item.zIndex = 400
          item.lineWidth = 3
        }
        else {
          item.zIndex = 300
          item.lineWidth = 2
          item.color = grey
        }
      }

      // else {
      //   if (item.loanAgeId != loanAgeId) {
      //     item.color = grey
      //     if (item?.name == 'debt') {
      //       item.borderWidth = 1
      //       item.color = lightgrey
      //     }
      //     else {
      //       item.color = grey
      //       if (item?.name == 'cpr') {
      //         item.zIndex = 300
      //         item.lineWidth = 2
      //       }
      //     }
      //     // if (item?.name != 'debt')
      //     //   item.color = grey
      //     // else {
      //     //   item.color = alpha(getSerieColor(item?.name, item.loanAgeId), 0.15)
      //     //   item.borderWidth = 1
      //     // }
      //   }
      //   else {
      //     item.color = alpha(getSerieColor(item?.name, item.loanAgeId), 1)
      //     if (item?.name == 'cpr') {
      //       item.zIndex = 400
      //       item.lineWidth = 3
      //     }
      //   }
      // }

    })
    var newChart = { ...sCurveChartOptions, series: newSeries }
    // console.log('newChart', newChart)
    setsCurveChartOptions(newChart)

  }, 10)
    , [series])


  const updateChartData = () => {

    // if (!sCurveGraph?.hasOwnProperty('sCurvesEmpiricalCPR') && !Boolean(calculationParameters?.calculationSCurvesReportDate != null)) {
    //   setReadyForExport(false)
    //   return
    // }

    if (sCurveChartRef.current?.chart == null) {
      setReadyForExport(false)
      return
    }

    var newChart = { ...sCurveChartOptions, series: [...series] }

    newChart.xAxis[0].categories = sCurvesGraph?.incentive ?? []
    // console.log('series', series)
    // newChart.xAxis[0].categories = new Array()
    // for (let s = LEFT_LIMMIT; s < RIGHT_LIMMIT; s += STEP) {
    //   newChart.xAxis[0].categories.push(Math.round(s * 10) / 10)
    // }

    // console.log('newChart', newChart)
    // // console.log('sCurveGraph', sCurveGraph)
    // newChart.xAxis[0].categories = sCurveGraph.sCurvesIncentive
    // newChart.series[0].data = [...sCurveGraph.sCurvesEmpiricalCPR]
    // newChart.series[1].data = [...sCurveGraph.sCurvesCPR]

    // newChart.series[2].data = [...sCurveGraph.sCurvesDebt]
    // newChart.series[2].visible = showDebt
    // newChart.series[2].showInLegend = showDebt

    // newChart.series[3].visible = showDebt //msbPosition    
    // newChart.series[3].showInLegend = false //msbPosition    

    // newChart.series[4].data = [...sCurveGraph.sCurvesEmpiricalPartialCPR]
    // newChart.series[4].visible = showDebt
    // newChart.series[4].showInLegend = showDebt

    if (!showDebt) {
      let maxOOD = 0
      // if (sCurveGraph?.oodWithGov != null && sCurveGraph?.oodWithoutGov != null) {
      //   const ood = [...sCurveGraph.oodWithGov].map((val, index) => val ?? 0 + sCurveGraph.oodWithoutGov[index] ?? 0)
      //   maxOOD = Math.max.apply(null, ood)

      // }
      newChart.yAxis[1].max = maxOOD >= 70 ? 120 : maxOOD >= 30 ? 80 : 40
      newChart.yAxis[1].title.text = t('convention2.oodYAxis')
    }
    else {
      const tickInterval = roundUp(1050 / 7, 5)
      // newChart.yAxis[1].tickInterval = tickInterval
      // newChart.yAxis[1].max = tickInterval * 7
      newChart.yAxis[1].max = 800
      newChart.yAxis[1].title.text = t('convention2.debtYAxis')
    }

    // // console.log('newChart.yAxis[1].max', newChart.yAxis[1].max)

    // newChart.series[5].data = [...sCurveGraph.oodWithGov]
    // const hasGov = sCurveGraph.oodWithGov?.some(x => x != null)
    // newChart.series[5].visible = !showDebt && hasGov
    // newChart.series[5].showInLegend = !showDebt && hasGov

    // newChart.series[6].data = [...sCurveGraph.oodWithoutGov]
    // const hasWithoutGov = sCurveGraph.oodWithoutGov?.some(x => x != null)
    // newChart.series[6].visible = !showDebt && hasWithoutGov
    // newChart.series[6].showInLegend = !showDebt && hasWithoutGov


    // // console.log('calculationParameters', calculationParameters)
    // const default_left = roundUp(Math.min.apply(null, calculationParameters?.calculationSCurve?.sCurvesIncentive), 0.1)
    // const default_right = roundDown(Math.max.apply(null, calculationParameters?.calculationSCurve?.sCurvesIncentive), 0.1)
    // const actual_left = default_left//Math.min(default_left, Math.floor(sCurveGraph?.mbsPosition?.calculationIncentive) - 1.0)
    // const actual_right = default_right//Math.max(default_right, Math.ceil(sCurveGraph?.mbsPosition?.calculationIncentive) + 1.0)

    // const minIndex = newChart.xAxis[0].categories?.findLastIndex(v => v == actual_left) + 1 // 0
    // const maxIndex = newChart.xAxis[0].categories?.findIndex(v => v == actual_right)// sCurveGraph?.sCurvesIncentive?.length - 1

    // // if (calculationParameters?.calculationSCurveParameters?.sCurveTheta0 != null || calculationParameters?.calculationSCurveParameters?.sCurveTheta1 != null) {
    // if (minIndex != null || maxIndex != null) {

    //   newChart.series.push({ ...newChart.series[0], data: [...newChart.series[0].data], id: 'sCurve_1', dashStyle: 'Dash', lineWidth: 1, showInLegend: false })
    //   const seriesIndex0 = newChart.series?.length - 1

    //   newChart.series.push({ ...newChart.series[1], data: [...newChart.series[1].data], id: 'sapprox_1', dashStyle: 'Dash', lineWidth: 1, showInLegend: false })
    //   const seriesIndex1 = newChart.series?.length - 1

    //   newChart.series.push({ ...newChart.series[2], data: [...newChart.series[2].data], id: 'debtBars_1', color: alpha(style.colorPrimary3, 0.0), showInLegend: false, borderWidth: 1, borderColor: alpha(style.colorPrimary3, 0.3), })
    //   const seriesIndex2 = newChart.series?.length - 1

    //   newChart.series.push({ ...newChart.series[4], data: [...newChart.series[4].data], id: 'sCurvesEmpiricalPartialCPR_1', dashStyle: 'Dash', lineWidth: 1, showInLegend: false })
    //   const seriesIndex3 = newChart.series?.length - 1

    //   newChart.xAxis[0].categories?.forEach((value, index) => {
    //     if (index < minIndex || index > maxIndex) {
    //       newChart.series[0].data[index] = null
    //       newChart.series[1].data[index] = null
    //       newChart.series[4].data[index] = null
    //     }

    //     if (index > minIndex && index < maxIndex) {
    //       newChart.series[seriesIndex0].data[index] = null
    //       newChart.series[seriesIndex1].data[index] = null
    //       newChart.series[seriesIndex3].data[index] = null
    //     }

    //     if (index < minIndex || index >= maxIndex)
    //       newChart.series[2].data[index] = null
    //     else
    //       newChart.series[seriesIndex2].data[index] = null
    //   })


    //   newChart.xAxis[0].plotLines = []
    //   const coeff = 1 / newChart.xAxis[0].categories?.length
    //   // console.log('coeff', coeff)
    //   // if (calculationParameters?.calculationSCurveParameters?.sCurveTheta0 != null)
    //   newChart.xAxis[0].plotLines.push({
    //     value: minIndex, //newChart.xAxis[0].categories?.findIndex((value) => value == calculationParameters.calculationSCurveParameters.sCurveTheta0),
    //     width: 1,
    //     color: style.colorPrimary1_40,
    //     dashStyle: 'Dash',
    //     zIndex: 1,
    //   })

    //   //if (calculationParameters?.calculationSCurveParameters?.sCurveTheta1 != null)
    //   newChart.xAxis[0].plotLines.push({
    //     value: maxIndex, //newChart.xAxis[0].categories?.findLastIndex((value) => value == calculationParameters.calculationSCurveParameters.sCurveTheta1),
    //     width: 1,
    //     color: style.colorPrimary1_40,
    //     dashStyle: 'Dash',
    //     zIndex: 1,
    //   })

    //   newChart.xAxis[0].plotBands = []
    //   newChart.xAxis[0].plotBands.push({
    //     from: -Infinity,
    //     to: minIndex,
    //     color: alpha(style.colorPrimary3, 0.03) // Серый цвет фона при x < 1
    //   })
    //   newChart.xAxis[0].plotBands.push({
    //     from: maxIndex,
    //     to: Infinity,
    //     color: alpha(style.colorPrimary3, 0.03) // Серый цвет фона при x > 10
    //   })
    // }

    // if (calculationParameters?.calculationSCurveParameters != null) {

    //   // console.log('newChart', newChart)
    //   // newChart.series[0].zones = [{
    //   //   value: calculationParameters?.calculationSCurveParameters.sCurveTheta0,
    //   //   dashStyle: 'Solid' // Сплошная линия до значения 2
    //   // }, {
    //   //   value: calculationParameters?.calculationSCurveParameters.sCurveTheta1,
    //   //   dashStyle: 'Dot' // Штриховая линия между значениями 2 и 5
    //   // }, {
    //   //   dashStyle: 'Solid' // Сплошная линия после значения 5
    //   // }]
    // }

    // // const min = Math.min.apply(null, sCurveGraph?.sCurvesIncentive)
    // // const max = Math.max.apply(null, sCurveGraph?.sCurvesIncentive)

    // var chart = sCurveChartRef.current.chart;
    // var xAxis = chart.xAxis[0];
    // const xMin = 0
    // const xLen = sCurveGraph?.sCurvesIncentive?.length - 1

    // newChart.xAxis[0] = { ...newChart.xAxis[0], min: xMin, max: xLen }
    // const w = xAxis.width / (sCurveGraph.sCurvesIncentive.length - 1)
    // newChart.yAxis[0].offset = -sCurveGraph.sCurvesIncentive.indexOf(0) * w

    let minEmp = 0 //roundDown(Math.min.apply(null, sCurveGraph.sCurvesCPR), 5) - 5
    const maxEmp = 45 // roundUp(Math.max.apply(null, sCurveGraph.sCurvesCPR), 5) + 5

    // minEmp = minEmp > 0 ? minEmp : 0
    newChart.yAxis[0].min = minEmp
    newChart.yAxis[0].max = maxEmp

    // let tickPositions = []
    // for (let i = minEmp; i <= maxEmp; i = i + 5)
    //   tickPositions.push(i)
    // newChart.yAxis[0].tickPositions = tickPositions

    // if (sCurveGraph?.mbsPosition != null) {
    //   newChart.series[3].data = [{ x: newChart.xAxis[0]?.categories?.indexOf(sCurveGraph?.mbsPosition?.calculationIncentive), y: sCurveGraph?.mbsPosition?.calculationSCurveCPR }]
    // }


    // newChart.exporting.chartOptions.yAxis = newChart.yAxis
    // // S-кривая на ___ и ипотечное покрытие ISIN на ___ (reportDate)
    // // S-кривая на ___ (сейчас так и есть)
    const exportFilename = showDebt ? `${t('convention2.SCurvesChartName')} ${Localization.FormatDateStringLocale(calculationParameters?.calculationSCurvesReportDate)}`
      : `${t('convention2.SCurvesChartName')} ${Localization.FormatDateStringLocale(calculationParameters?.calculationSCurvesReportDate)} (ипотечное покрытие ${selectedBond?.isin} на ${Localization.FormatDateStringLocale(poolStatistics.reportDate)})`
    // // console.log('exportFilename', exportFilename)
    newChart.exporting.filename = exportFilename + '.png'

    setReadyForExport(true)
    setsCurveChartOptions(newChart)
  }

  const onResize = () => {
    if (sCurveChartRef.current != null) {
      sCurveChartRef.current.chart.reflow();
    }
  }

  const getSerieColor = useCallback((serieName, loanAgeId) => {
    try {
      let index = 0
      // console.log('sCurvesColor.current?.length', sCurvesColor.current?.length)
      if (sCurvesColor.current?.length > loanAgeId)
        index = loanAgeId

      switch (serieName) {
        case 'cpr':
          return sCurvesColor.current[index]?.color

        case 'debt':
          return sCurvesColor.current[index]?.valColor

        case 'distribution':
          return sCurvesColor.current[index]?.valColor

        default:
          return sCurvesColor.current[index]?.color
      }
    } catch (error) {

    }
  }, [sCurvesColor])




  const hoverHandler = (e, item) => {
    e.stopPropagation()
    highlightSerie(item.loanAgeId)
  }

  if (calculationParameters == null) return <></>

  // console.log('sCurvesColor', sCurvesColor)
  // console.log('sCurveGraph', sCurveGraph)
  // console.log('sCurveChartRef--------------------------', sCurveChartRef)
  // console.log('loadingChartData || loadingPoolDebtDistribution', loadingChartData, loadingPoolDebtDistribution)
  return (
    <>
      {Boolean(!calculationParameters?.calculationSCurvesReportDate) ?
        <Box className={cls.fullPanelAlert}>
          {selectedBond?.isin == 'RU000A100DQ4' ?
            <div dangerouslySetInnerHTML={{ __html: t('Convention2_sCurveChartDQ4Alert') }} />
            :
            <div dangerouslySetInnerHTML={{ __html: t('Convention2_sCurveChartNotDQ4Alert') }} />
          }
        </Box>
        :
        <Box className={cls.root}>
          <Box className={cls.rootData}>
            {(sCurvesGraphLoading || series?.length == 0) && <SpinnerBars withBackground />}
            <Box style={{ textAlign: 'center', fontSize: '1.2rem', }} dangerouslySetInnerHTML={{ __html: t('convention2.sCurvesTopText') }} />
            <Box className={cls.functionBlock}>
              <Box className={cls.functionText}>
                CPR(𝑠,ℎ) = {bettas?.beta0 ?? <>𝛽<sub><i>h</i>,0</sub></>} + {bettas?.beta1 ?? <>𝛽<sub><i>h</i>,1</sub></>} ∙ arctg({bettas?.beta2 ?? <>𝛽<sub><i>h</i>,2</sub></>} + {bettas?.beta3 ?? <>𝛽<sub><i>h</i>,3</sub></>} ∙ 𝑠) + {bettas?.beta4 ?? <>𝛽<sub><i>h</i>,4</sub></>} ∙ arctg({bettas?.beta5 ?? <>𝛽<sub><i>h</i>,5</sub></>} + {bettas?.beta6 ?? <>𝛽<sub><i>h</i>,6</sub></>} ∙ 𝑠)
              </Box>
            </Box>
            {/* <Box>CPR<sub><i>𝑡</i></sub>(𝑠) — <span className={cls.decreaseFontSize}>ожидаемый СPR кредита при стимуле к рефинансированию s на году жизни t, % год.</span></Box> */}
            <Box className={cls.chartRoot}>
              <HighchartsReact
                ref={sCurveChartRef}
                highcharts={Highcharts}
                containerProps={{ style: { /*height: "100%", width: "100%"*/ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
                options={sCurveChartOptions ? sCurveChartOptions : {}}
                callback={(chart) => {
                  // chart?.reflow()
                }}
              />
            </Box>
            <Box className={cls.buttonsRoot}>
              {sCurvesColor?.current != null &&
                <Box className={cls.buttonsBox}>
                  <Box className={cls.buttonBox}>Выдержка</Box>
                  <Box className={cls.buttonsContaner} onMouseLeave={restoreColors}>
                    {// console.log('item.isActive', item.isActive)
                      sCurvesColor.current
                        ?.filter(item => item?.isActive != false)
                        ?.map((item, index) => {
                          return <Box key={`loanAgeButton_${item?.loanAgeId}`} className={[cls.buttonBox, item?.isActive ? 'active' : cls.disabled].join(' ')} onMouseEnter={item?.isActive ? (e) => hoverHandler(e, item) : null}>
                            <Box className={[cls.button].join(' ')} style={{ backgroundColor: item.color }} >
                              <Box style={{ color: item.color }}>
                                {item.key}
                              </Box>
                            </Box>
                          </Box>
                        })}
                  </Box>
                </Box>
              }
            </Box>
          </Box>
        </Box >
      }
    </>
  )
})

export default DetailedSCurveChartV2