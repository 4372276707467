import React, { useEffect, useLayoutEffect, useState } from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import { Box, Button, Grid, IconButton, Stack, Theme, alpha } from '@mui/material'
import style from '@Styles/domrf-theme.module.scss'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import UniversalInputField, { InputDataType } from '@Base/framework/components/Form/UniversalInputField'
import { Edit, Delete } from '@mui/icons-material'
import Localization from '@Base/components/Localization'
import { actionCreators as actionBonds } from '@Base/store/BondsStore'
import { useDispatch } from 'react-redux'
import { useUpdatedFields } from '@Base/components/hook/useUpdatedFields'
import { ValidatorForm } from '@Base/framework/components/Form'
import { getClockNumberUtilityClass } from '@mui/x-date-pickers/ClockPicker/clockNumberClasses'
import { BondStatus, CouponType, CouponTypeStr } from '@Base/components/Definitions'
import { humanReadableBondStatus } from '@Base/Utilities'
import ConfirmationForm from '@Base/components/shared/ConfirmationForm'
import Globals from "@Base/Globals";
import { Permissions } from "@Base/components/Definitions";


const useStyles = makeStyles((theme: Theme) => createStyles({
    buttonsBlock: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        '&>*:not(:first-child)': {
            marginLeft: '0.5rem',
        }
    },
    blockContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        marginBottom: '1.2rem',
    },
    blockTitle: {
        // letterSpacing: '0.1rem',
        // padding: '3px 0.5rem',
        // marginTop: '3px',
        textTransform: 'uppercase',
        // backgroundColor: style.colorPrimary3_40,
        // color: '#FFF'//`${style.colorPrimary1_80} !important`,
        borderBottom: `solid 1px ${style.colorPrimary3_60}`,
        color: style.colorPrimary3_60,
        // borderLeft: `solid 3px ${style.colorPrimary2_40}`,
        // color: style.colorPrimary2,
        // borderBottom: `solid 2px ${style.colorPrimary2}`,
        // fontWeight: 600,
        // marginTop: '1.1rem',
        // marginBottom: 0,
        textAlign: 'left',
    },
    column: {
        padding: '4px',
    },
    row: {
        // borderRight: `solid 1px ${style.colorPrimary2}`,
        // borderLeft: `solid 1px ${style.colorPrimary2_40}`,
        padding: '0 0.5rem 0 0.5rem',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '2rem !important'
    },
    rowName: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        // maxWidth: '200px',
        flexGrow: 1,
    },
    rowInputBlock: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        paddingLeft: '0.5rem',
        alignItems: 'baseline',
    },
    rowInput: {
        flexGrow: 1,
        textAlign: 'right',
        width: '135px',
        // maxWidth: '160px',
        '& input': {
            textAlign: 'right',
            paddingRight: '5px',
        }
    },
    rowUnit: {
        width: '2.5rem !important',
        maxWidth: '2.5rem',
        overflow: 'hidden',
        marginLeft: '0.5rem',
        color: style.colorPrimary3_40,
        fontSize: '0.8rem !important',
        alignSelf: 'end',
        marginBottom: '5px',
    },
    rootContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        position: 'absolute', left: '4px', right: '4px', top: 0, bottom: 0,
    },
    container: {
        // borderWidth: '0px',
        // borderLeftWidth: '1px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#dfdfe1', //style.colorPrimary3,
        borderTopRightRadius: '0.5rem',
        borderTopLeftRadius: '0.5rem',
        overflow: 'hidden',
        position: 'absolute', left: 0, right: 0, top: '10px', bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '0px'
    },
    header: {
        height: '2.2rem',
        maxHeight: '2.2rem',
        padding: '0 0.5rem',
        marginBottom: '10px',
        // backgroundColor: alpha(style.colorPrimary2, 0.1),
        // borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        fontSize: '1.6rem',
        // lineHeight: '2rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap'
    },
    headerTitle: {
        display: 'flex',
        flexDirection: 'row',
        color: style.colorPrimary1_60,
        '&>*:not(:first-child)': {
            marginLeft: '0.5rem',
        },
        '& .bondName': {
            fontSize: '1.2rem',
            color: style.colorPrimary3
        }
    },
    body: {
        flexGrow: 1,
        position: 'relative',
        '& *': {
            fontSize: '1rem',
        }
    },
    scrollablePanel: {
        position: 'absolute', left: '4px', right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {

            width: '4px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
}))


const BondData = () => {
    const classes = useStyles()
    const { selectedBond: bondData = null, isBondCanBeRemoved = false, ...otherStore } = useSelector((state: any) => state.bonds)
    console.log('selectedBond', bondData)
    // console.log('bondData', bondData)
    // console.log('otherStore', otherStore)
    // const [isEdit, setIsEdit] = useState(false)
    const [selectedBond, setSelectedBond] = useState(null)
    const { data: updatedFields, add: addUpdatedFields, remove: removeUpdatedFields, reset: resetUpdatedFields } = useUpdatedFields()
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [isAdmin] = useState(Globals.hasAccess(Globals.Administrator_Role));

    // console.log('selectedBond', selectedBond)
    useLayoutEffect(() => {
        if (bondData != null) {
            setSelectedBond({ ...bondData, ...updatedFields })
        }
        else {
            setSelectedBond(null);
        }

        if ((bondData?.id !== selectedBond?.id) || (bondData?.isEdit !== selectedBond?.isEdit)) {
            resetUpdatedFields()
        }


    }, [bondData, updatedFields])


    const dispatch = useDispatch()

    const editBondHandler = (state: Boolean) => {
        actionBonds.updateBondData({ isEdit: state })(dispatch)
    }

    const removeBondHandler = () => {
        setConfirmationDialog(true);
    }

    // console.log('selectedBond', selectedBond)
    const valueGetter = (row) => {
        try {
            return selectedBond[row?.name] ?? ''

        } catch (error) {
            console.error(error.message)
        }
    }

    const getCouponTypes = () => {
        try {
            return [
                // { key: CouponType.TYPE_UNKNOWN, value: "" },
                { key: CouponType.TYPE_FIXED, value: t("bonds2.CouponTypeFixed") },
                { key: CouponType.TYPE_FLOAT, value: t("bonds2.CouponTypeFloating") },
                { key: CouponType.TYPE_KEYRATE_AND_PREMIUM, value: t("bonds2.CouponTypeStrKeyrateAndPremium") },
            ]
        } catch (error) {
            console.error(error.message)
        }
    }

    const getCouponPercentage = () => {
        try {
            // console.log('selectedBond.couponType', selectedBond.couponType)
            return selectedBond.couponType == CouponType.TYPE_FLOAT ? '' : selectedBond?.couponPercentage
        } catch (error) {
            console.error(error.message)
        }
    }

    const getSwapType = () => {
        return ([
            // { key: null, value: t('Undefined') },
            { key: 1, value: t('MonthlyPayments') },
            { key: 2, value: t('NettsPerCouponPeriod') },
        ])
    }

    const getCouponPeriods = () => {
        try {
            return ([
                // { key: null, value: t('Undefined') },
                { key: 1, value: '1' },
                { key: 3, value: '3' },
                { key: 6, value: '6' }
            ])

        } catch (error) {
            console.error(error.message)
        }
    }

    const getDate = (row) => {
        try {
            return Localization.FormatDateStringISO(selectedBond[row?.name]) ?? ''

        } catch (error) {
            console.error(error.message)
        }
    }

    const getBondStatus = () => {
        try {
            const selector = []
            for (const [key, value] of humanReadableBondStatus) {
                selector.push({ key: key, value: t(value) })
            }
            return selector

        } catch (error) {
            console.error(error.message)
        }
    }

    const getOriginator = (row) => {
        try {
            // console.log('selectedBond', selectedBond)
            return selectedBond?.originator.name
        } catch (error) {
            console.error(error.message)
        }
    }

    const checkCouponTypeNotFloat = () => {
        try {
            if (selectedBond?.couponType == CouponType.TYPE_FLOAT)
                return false

        } catch (error) {
            console.error(error.maessage)
        }

        return true
    }

    const checkManAccFloating = () => {
        try {
            if (selectedBond?.manAccFloating == false)
                return true

        } catch (error) {
            console.error(error.maessage)
        }

        return false
    }


    const getCouponPercentageAlias = () => {
        try {
            if (selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM)
                return 'CouponPercentageKS_bonds2'

        } catch (error) {
            console.error(error.maessage)
        }

        return 'CouponPercentage_bonds2'

    }

    const getLockPlacementCoupon_alias = () => {
        try {
            if (selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM)
                return 'LockPlacementCouponKS_bonds2'

        } catch (error) {
            console.error(error.maessage)
        }

        return 'LockPlacementCoupon_bonds2'

    }

    const getUsePlacementCoupon_alias = () => {
        try {
            if (selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM)
                return 'usePlacementCouponKS_bonds2'

        } catch (error) {
            console.error(error.maessage)
        }

        return 'usePlacementCoupon_bonds2'

    }

    const structure = [
        {
            id: '0', blocks: [
                {
                    block: 'BondIdentificators_bonds2', alias: 'BondIdentificators_bonds2', fields: [
                        // { name: 'id', alias:t('') },
                        { name: 'bondStatus', alias: 'BondStatus_bonds2', width: 175, dataSource: getBondStatus(), dataType: InputDataType.ENUM },
                        // { name: 'originatorId', alias: 'OriginatorID_bonds2', readOnly: true, valueGetter: getOriginator },
                        { name: 'name', alias: 'Name_bonds2', width: 175 },
                        { name: 'isin', alias: 'ISIN_bonds2', width: 175 },
                        { name: 'ticker', alias: 'Ticker_bonds2', width: 175 },
                        { name: 'regNumber', alias: 'RegNumber_bonds2', width: 175 },
                    ]
                },
                {
                    block: 'Dates_bonds2', alias: 'Dates_bonds2', fields: [
                        { name: 'inclusionDate', alias: 'InclusionDate_bonds2', valueGetter: getDate, dataType: InputDataType.DATE },
                        { name: 'fixationDate', alias: 'FixationDate_bonds2', valueGetter: getDate, dataType: InputDataType.DATE },
                        { name: 'actDeliveryDate', alias: 'ActDeliveryDate_bonds2', valueGetter: getDate, dataType: InputDataType.DATE },
                        { name: 'issueDate', alias: 'IssueDate_bonds2', valueGetter: getDate, dataType: InputDataType.DATE },
                        { name: 'secondPeriodStartDate', alias: 'SecondPeriodStartDate_bonds2', valueGetter: getDate, dataType: InputDataType.DATE },
                        { name: 'firstCouponDate', alias: 'FirstCouponDate_bonds2', valueGetter: getDate, dataType: InputDataType.DATE },
                        { name: 'maturityDate', alias: 'MaturityDate_bonds2', valueGetter: getDate, dataType: InputDataType.DATE },
                        { name: 'actualRedemptionDate', alias: 'ActualRedemptionDate_bonds2', valueGetter: getDate, dataType: InputDataType.DATE },
                        { name: 'correctReportsStartDate', alias: 'CorrectReportsStartDate_bonds2', valueGetter: getDate, dataType: InputDataType.DATE },
                    ]
                },
                {
                    block: 'BondParameters_bonds2', alias: 'BondParameters_bonds2', fields: [
                        { name: 'couponType', alias: 'CouponType_bonds2', dataSource: getCouponTypes(), dataType: InputDataType.ENUM },
                        { name: 'couponPeriod', alias: 'CouponPeriod_bonds2', dataSource: getCouponPeriods(), dataType: InputDataType.ENUM, unit: 'months' },
                        { name: 'startIssueAmount', alias: 'StartIssueAmount_bonds2', dataType: InputDataType.FLOAT, unit: 'rubSign' },
                        { name: 'nominal', alias: 'Nominal_bonds2', dataType: InputDataType.FLOAT, unit: 'rubSign', disabled: true },
                        { name: 'couponPercentage', alias: getCouponPercentageAlias(), /*valueGetter: getCouponPercentage,*/ hide: !checkCouponTypeNotFloat(), dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'swapORIG', alias: 'SwapORIG_bonds2', hide: !checkCouponTypeNotFloat(), dataType: InputDataType.BOOLEAN },
                        { name: 'swapType', alias: 'SwapType_bonds2', hide: !checkCouponTypeNotFloat(), dataSource: getSwapType(), dataType: InputDataType.ENUM },
                        { name: 'cleanUpCall', alias: 'CleanUpCall_bonds2', dataType: InputDataType.FLOAT, unit: 'percent' },
                    ]
                },
                {
                    block: 'TarifExpenses_bonds2', alias: 'TarifExpenses_bonds2', fields: [
                        { name: 'guaranteeRate', alias: 'GuaranteeRate_bonds2', dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'marginRate', alias: 'bonds2.MarginRate', dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'loanServRate', alias: 'LoanServRate_bonds2', dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'loanServMonthly', alias: 'LoanServMonthly_bonds2', dataType: InputDataType.BOOLEAN },
                        { name: 'resLoanServRate', alias: 'ResLoanServRate_bonds2', dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'specDepRate', alias: 'SpecDepRate_bonds2', dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'specDepMin', alias: 'SpecDepMin_bonds2', dataType: InputDataType.FLOAT, unit: 'rubPerMonth' },
                        { name: 'specDepFixed', alias: 'SpecDepFixed_bonds2', dataType: InputDataType.FLOAT, unit: 'rubPerMonth' },
                        { name: 'insuranceRate', alias: 'InsuranceRate_bonds2', dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                    ]
                },
                {
                    block: 'bonds2.Reinvestment', alias: 'bonds2.ReinvestmentBlock', hide: !checkCouponTypeNotFloat(), fields: [
                        { name: 'reinvestment', alias: 'Reinvestment', hide: !checkCouponTypeNotFloat(), dataType: InputDataType.BOOLEAN },
                        { name: 'deductionRUONIA', alias: 'DeductionRUONIA', hide: !Boolean(selectedBond?.reinvestment) /*!checkCouponTypeNotFloat()*/, dataType: InputDataType.FLOAT, unit: "percentInYear" }
                    ]
                },
            ]
        },
        {
            id: '1', blocks: [
                {
                    block: 'OneTimeExpenses_bonds2', alias: 'OneTimeExpenses_bonds2', fields: [
                        { name: 'comissionMOEXFirst', alias: 'ComissionMOEXFirst_bonds2', dataType: InputDataType.FLOAT, unit: 'rubSign' },
                        { name: 'comissionNSDFirst', alias: 'ComissionNSDFirst_bonds2', dataType: InputDataType.FLOAT, unit: 'rubSign' },
                        { name: 'settlementAgentFirst', alias: 'SettlementAgentFirst_bonds2', dataType: InputDataType.FLOAT, unit: 'rubSign' },
                        { name: 'underwriterFirst', alias: 'UnderwriterFirst_bonds2', dataType: InputDataType.FLOAT, unit: 'rubSign' },
                    ]
                },
                {
                    block: 'FixedExpenses_bonds2', alias: 'FixedExpenses_bonds2', fields: [

                        { name: 'manAccFloating', alias: 'ManAccFloating_bonds2', dataType: InputDataType.BOOLEAN },
                        { name: 'manAccCoef', alias: 'ManAccCoef_bonds2', hide: checkManAccFloating(), dataType: InputDataType.FLOAT },
                        { name: 'manAccFixedSum', alias: 'ManAccFixedSum_bonds2', hide: !checkManAccFloating(), dataType: InputDataType.FLOAT, unit: 'rubSign' },
                        { name: 'settlementAgentYearly', alias: 'SettlementAgentYearly_bonds2', dataType: InputDataType.FLOAT, unit: 'rubPerYear' },
                        { name: 'comissionMOEXYearly', alias: 'ComissionMOEXYearly_bonds2', dataType: InputDataType.FLOAT, unit: 'rubPerYear' },
                        { name: 'comissionNSDQuarter', alias: 'ComissionNSDQuarter_bonds2', dataType: InputDataType.FLOAT, unit: 'rubPerQuartal' },
                        { name: 'marketMakerMonth', alias: 'MarketMakerMonth_bonds2', dataType: InputDataType.FLOAT, unit: 'rubPerMonth' },
                        { name: 'bankAccountPaymentsMonth', alias: 'BankAccountPaymentsMonth_bonds2', dataType: InputDataType.FLOAT, unit: 'rubPerMonth' },
                        { name: 'bankAccountIssuerAndAgentMonth', alias: 'BankAccountIssuerAndAgentMonth_bonds2', dataType: InputDataType.FLOAT, unit: 'rubPerMonth' },
                    ]
                },

                {
                    block: 'PoolStatistic_bonds2', alias: 'PoolStatistic_bonds2', fields: [
                        { name: 'inclusionDebtDate', alias: 'InclusionDebtDate_bonds2', valueGetter: getDate, dataType: InputDataType.DATE },
                        { name: 'fixationWAC', alias: 'FixationWAC_bonds2', dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'fixationWALA', alias: 'FixationWALA_bonds2', dataType: InputDataType.FLOAT, unit: 'yearSign' },
                        { name: 'fixationWAM', alias: 'FixationWAM_bonds2', dataType: InputDataType.FLOAT, unit: 'months' },
                        { name: 'fixationAdjustedWAM', alias: 'FixationAdjustedWAM_bonds2', dataType: InputDataType.FLOAT, unit: 'months' },
                    ]
                },
                {
                    block: 'TransferActData_bonds2', alias: 'TransferActData_bonds2', fields: [
                        { name: 'actDeliveryDebt', alias: 'ActDeliveryDebt_bonds2', dataType: InputDataType.FLOAT, unit: 'rubSign' },
                        { name: 'actDeliveryYield', alias: 'ActDeliveryYield_bonds2', dataType: InputDataType.FLOAT, unit: 'rubSign' },
                        { name: 'actDeliveryPremium', alias: 'ActDeliveryPremium_bonds2', dataType: InputDataType.FLOAT, unit: 'rubSign' },
                    ]
                },
                {
                    block: 'ConventionParameters_bonds2', alias: 'ConventionParameters_bonds2', fields: [
                        // { name: 'placementCPR', alias: 'PlacementCPR_bonds2', dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'placementCDR', alias: 'PlacementCDR_bonds2', dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        // { name: 'lockPlacementPrice', alias: 'LockPlacementPrice_bonds2', width: 100, dataType: InputDataType.BOOLEAN },
                        { name: 'lockPlacementCoupon', alias: getLockPlacementCoupon_alias(), width: 100, dataType: InputDataType.BOOLEAN },

                        // { name: 'usePlacementCoupon', alias: getUsePlacementCoupon_alias(), width: 100, dataType: InputDataType.BOOLEAN },

                        // { name: 'defaultPlacementPrice', alias: 'DefaultPlacementPrice_bonds2', dataType: InputDataType.FLOAT, unit: 'percent' },
                        { name: 'firstCouponExpensesIssueDoc', alias: 'FirstCouponExpensesIssueDoc_bonds2', /*hide: checkCouponTypeNotFloat(),*/ dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'otherCouponsExpensesIssueDoc', alias: 'OtherCouponsExpensesIssueDoc_bonds2', /*hide: checkCouponTypeNotFloat(),*/ dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'specDepRateIssueDoc', alias: 'SpecDepRateIssueDoc_bonds2', /*hide: checkCouponTypeNotFloat(),*/ dataType: InputDataType.FLOAT, unit: 'percentInYear' },
                        { name: 'specDepMinMonthIssueDoc', alias: 'SpecDepMinMonthIssueDoc_bonds2', /*hide: checkCouponTypeNotFloat(),*/ dataType: InputDataType.FLOAT, unit: 'rubPerMonth' },
                        { name: 'specDepCompensationMonthIssueDoc', alias: 'SpecDepCompensationMonthIssueDoc_bonds2', /*hide: checkCouponTypeNotFloat(),*/ dataType: InputDataType.FLOAT, unit: 'rubPerMonth' },
                        { name: 'manAccQuartFixIssueDoc', alias: 'bonds2.ManAccQuartFixIssueDoc', /*hide: checkCouponTypeNotFloat(),*/ dataType: InputDataType.FLOAT, unit: 'rubPerQuartal' },
                        { name: 'manAccQuartRateIssueDoc', alias: 'bonds2.ManAccQuartRateIssueDoc', /*hide: checkCouponTypeNotFloat(),*/ dataType: InputDataType.FLOAT, unit: 'percent' },
                        { name: 'paymentAgentYearIssueDoc', alias: 'bonds2.PaymentAgentYearIssueDoc', /*hide: checkCouponTypeNotFloat(),*/ dataType: InputDataType.FLOAT, unit: 'rubPerYear' },

                        { name: 'partialPaymentAllowedAnytime', alias: 'PartialPaymentAllowedAnytime_bonds2', /*hide: checkCouponTypeNotFloat(),*/ dataType: InputDataType.BOOLEAN },
                        { name: 'returnAccruedSubsidy', alias: 'ReturnAccruedSubsidy_bonds2', /*hide: checkCouponTypeNotFloat(),*/ dataType: InputDataType.BOOLEAN },
                    ]
                },
                //oldFields
                // { name: 'createdDate', alias: 'CreatedDate' },
                // { name: 'bloombergTicker', alias: 'BloombergTicker' },
                // { name: 'shortName', alias: 'ShortName' },
                // { name: 'description', alias: 'Description' },
                // { name: 'originatorName', alias: 'OriginatorName' },
                // { name: 'nonRecurringExpenses', alias: 'NonRecurringExpenses' },
                // { name: 'constantExpenses', alias: 'ConstantExpenses' },
                // { name: 'productName', alias: 'ProductName' },
                // { name: 'productId', alias: 'ProductId' },
                // { name: 'isShared', alias: 'IsShared' },
                // { name: 'isValid', alias: 'IsValid' },
                // { name: 'isRegular', alias: 'IsRegular' },
                // { name: 'swapMA', alias: 'SwapMA' },
                // { name: 'isDomRF', alias: 'IsDomRF' },
                // { name: 'usePlacementCoupon', alias: 'UsePlacementCoupon' },
                // { name: 'useProduct', alias: 'UseProduct' },
            ]
        }
    ]

    const handleValueChange = (name, value) => {

        try {
            // console.log('name, value', name, value)
            if (bondData[name] != value) {
                addUpdatedFields({ [name]: value })
            }
            else {
                removeUpdatedFields(name)
            }
        }

        catch (error) {
            console.error(error.message)
        }

    }

    const cancelEditHandler = () => {
        resetUpdatedFields()
        editBondHandler(false)
    }

    const saveEditHandler = () => {
        // console.log('updatedFields', updatedFields)
        if (updatedFields) {
            const values = { id: selectedBond.id }
            Object.keys(updatedFields).forEach((key) => values[key] = { value: updatedFields[key] })
            // console.log('values', values)
            actionBonds.updateBond(values)(dispatch)
        }
    }

    const removeBond = () => {
        if (selectedBond?.id == null)
            return;

        actionBonds.removeBond(selectedBond.id)(dispatch)
    }

    return (
        <>
            <ConfirmationForm
                header={t("Warning")}
                content={t("AreYouShureToDeleteBond")}
                onAccept={() => {
                    cancelEditHandler();
                    setConfirmationDialog(false);
                    removeBond();
                }}
                onDecline={() => setConfirmationDialog(false)}
                acceptButton={t("Yes")}
                declineButton={t("No")}
                isOpen={confirmationDialog}
            />

            <Box className={classes.rootContainer}>
                <Box className={classes.container}>
                    <Box className={classes.header}>
                        <Box className={classes.headerTitle}>
                            {/* <Box>{t("Bond")}</Box> */}
                            <Box>{selectedBond?.name}</Box>
                            {/* {selectedBond?.name ? <Box className={'bondName'}>{selectedBond?.name}</Box> : ''} */}
                        </Box>
                        {Boolean(selectedBond) &&
                            <Box>
                                {selectedBond?.isEdit ?
                                    <Box className={classes.buttonsBlock}>
                                        <Button
                                            size="small"
                                            color='secondary'
                                            variant='contained'
                                            onClick={saveEditHandler}
                                        >
                                            {t("Save")}
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={cancelEditHandler}>
                                            {t("Cancel")}
                                        </Button>
                                    </Box>
                                    :
                                    <Box className={classes.buttonsBlock}>
                                        <IconButton size="small" onClick={() => editBondHandler(true)}><Edit /></IconButton>
                                        {
                                            (isAdmin && isBondCanBeRemoved) ? <IconButton size="small" onClick={() => removeBondHandler()}><Delete /></IconButton> : null
                                        }
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>
                    <Box className={classes.body}>
                        <Box className={classes.scrollablePanel}>
                            <Box>
                                {Boolean(selectedBond) && //<ShowData />
                                    <>
                                        <Grid container>
                                            <ValidatorForm
                                            // onSubmit  = {()=>{}}
                                            // instantValidate
                                            //  onError 
                                            //  debounceTime
                                            //    validators
                                            // onFieldChanged
                                            //  onFormStateChanged = {()=>{}}
                                            >
                                                {structure?.map(column => {
                                                    if (!column?.hide) return <Grid key={`column_${column.id}`}
                                                        xs={structure?.length ? 12 / structure?.length : ''}
                                                        item
                                                        className={classes.column}
                                                    >
                                                        {column?.blocks.map(block => {
                                                            if (!block?.hide)
                                                                return <Box
                                                                    key={`block_${block.alias}`}
                                                                    className={classes.blockContainer}
                                                                >
                                                                    <Box className={classes.blockTitle}>{t(block.alias)}</Box>
                                                                    {block.fields
                                                                        ?.filter(row => !row?.hide)
                                                                        ?.map(row => {
                                                                            if (row?.name == null)
                                                                                return

                                                                            return <Box
                                                                                key={`row_${row.name}`}
                                                                                className={classes.row}
                                                                            >
                                                                                <Box className={classes.rowName}>{t(row.alias)}</Box>
                                                                                <Box className={classes.rowInputBlock}>
                                                                                    {/* {console.log('row?.width', row?.width)} */}
                                                                                    <Box
                                                                                        className={classes.rowInput}
                                                                                        sx={row?.width != null ? { width: `${row.width}px !important` } : {}}
                                                                                    >
                                                                                        <UniversalInputField
                                                                                            needConfirm
                                                                                            dataType={row?.dataType ? row?.dataType : InputDataType.STRING}
                                                                                            name={row.name}
                                                                                            disabled={row?.disabled === true}
                                                                                            readOnly={!selectedBond?.isEdit || !!row?.readOnly}
                                                                                            value={row?.valueGetter ? row.valueGetter(row) : valueGetter(row)}
                                                                                            fullWidth={true}
                                                                                            // fieldProps={row.fieldProps}
                                                                                            handleValueChange={(name, value) => handleValueChange(name, value)}
                                                                                            //onBlur={() => onFieldLeft()}
                                                                                            //onKeyUp={(event) => handleKeyPress(event)}
                                                                                            // inputProps={row?.customProps}
                                                                                            dataSource={row?.dataSource}
                                                                                            {...row.validators}
                                                                                            sx={{
                                                                                                '& .Mui-disabled:before': { borderBottomStyle: 'solid !important', borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                                                '& .MuiInput-root:hover:not(.Mui-disabled):before': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                                                '& .MuiInput-underline:before': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                                                '& .Mui-readOnly': !row?.readOnly ? { color: 'initial !important', '& *': { '-webkit-text-fill-color': 'inherit !important' }, } : {},
                                                                                                '& .Mui-disabled': !!row?.disabled ? { color: `${style.colorPrimary3_60} !important`, } : {},
                                                                                                '& .Mui-readOnly:after': { border: 'none' },
                                                                                                '& .Mui-readOnly:before': { border: 'none !important' },
                                                                                                '& .Mui-focused:after': Boolean(selectedBond?.isEdit) && !Boolean(row?.readOnly) ? { border: `solid 2px ${alpha(style.colorPrimary2, 1)} !important`, top: '1px' } : { border: 'none' },
                                                                                                '& .Mui-error:after': { border: `none` },
                                                                                                '& svg': { fontSize: '20px' },
                                                                                                '& .MuiSwitch-track': { opacity: '0.5 !important' }, // отключаем прозрачность на disabled
                                                                                            }}
                                                                                            isChanged={Object.hasOwn(updatedFields, row.name)}
                                                                                            disableCloseOnSelect={false}
                                                                                            hideValidationIcon={true}
                                                                                        />
                                                                                    </Box>
                                                                                    <Box className={classes.rowUnit}>
                                                                                        {row?.unit ? t(row?.unit) : ''}
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        })}
                                                                </Box>
                                                        }
                                                        )}
                                                    </Grid>
                                                }
                                                )}
                                            </ValidatorForm>
                                        </Grid>
                                    </>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default BondData