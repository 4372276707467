import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import { Box, Stack } from '@mui/system'
import { t } from 'i18next'
import { Accordion, AccordionDetails, AccordionSummary, alpha, Button, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'
// import { useGridApiRef } from "@mui/x-data-grid";
import {
    DataGridPro,
    useGridApiContext,
    useGridSelector,
    gridFilteredDescendantCountLookupSelector,
    GridExpandMoreIcon,
    GridGroupNode,
} from '@mui/x-data-grid-pro'

import Localization from '@Base/components/Localization';
import { AddCircleOutline, CheckBoxOutlineBlank, CheckBoxOutlined, CheckOutlined, ChevronRight, ClearOutlined, CopyAll, ExpandLess, ExpandMore, RemoveCircleOutline } from '@mui/icons-material'
import { CouponType, CouponTypeStr, PoolProcessingStatus, ePoolNodeType } from '@Base/components/Definitions'
import CustomTooltip from '@Base/components/shared/CustomTooltip'
import useLocalStorage from '@Base/components/hook/useLocalStorage'
import { copyTextToClipboard } from '@Base/Utilities'
import { actionCreators as actionAlert } from '@Base/store/AlertStore'
import Alert from './Alert'
import clsx from 'clsx'

const useStyles = makeStyles({
    poolDate: {
        color: style.colorPrimary3_60,
    },
    poolDebt: {
        color: style.colorPrimary1,
        flexGrow: 1,
        textAlign: 'right',
    },
    poolWAC: {
        color: style.colorPrimary2_60,
    },
    iconButton: {
        alignSelf: 'stretch',
        marginLeft: '0.5rem',
        width: '2rem'
    },
    alertLayout: {
        pointerEvents: 'none',
        position: 'absolute', left: 0, right: 0, top: '70px', bottom: 0,
        zIndex: '9999',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
    },
    blockTitleRow: {
        cursor: 'pointer',
        flexGrow: 1,
        display: 'flex',
        alignSelf: 'stretch'
    },
    copyIcon: {
        width: '19px',
        height: '19px',
    },
    columnHeadersInner: {
        '& *:first-child': {
            border: 'none'
        },
        '& *:last-child': {
            border: 'none'
        }
    },
    addButton: {
        padding: '3px',
        backgroundColor: `${alpha(style.colorPrimary3_120, 0.1)} !important`,
        boxShadow: `0px 0px 0px 1px ${style.colorPrimary2} !important`,
        color: `${style.colorPrimary2} !important`,
        fontWeight: '200 !important',
        fontFamily: 'DINPro',
        '& .MuiTouchRipple-root': {
            color: `${style.colorPrimary3} !important`,
        }
    },
    disabledButton: {
        backgroundColor: `${alpha(style.colorPrimary3_120, 0.1)} !important`,
        boxShadow: `0px 0px 0px 1px ${style.colorPrimary3_120} !important`,
        color: `${style.colorPrimary3_120} !important`,
        fontWeight: '200 !important',
        fontFamily: 'DINPro',
    },
    pinnedColumnHeaders: {
        boxShadow: 'none'
    },
    pinnedColumns: {
        boxShadow: 'none'
    },
    scrollablePanel: {
        border: 'none',
        // position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {

            width: '4px',
            height: '9px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    paper: {
        border: 'none',
    },
    dataBlock: {
        maxWidth: '100% !important',
        overflow: 'auto',
        width: 'min-content',
        border: 'none',
        '& .MuiDataGrid-scrollbar': {
            '&::-webkit-scrollbar': {
                backgroundColor: '#FFF',
                width: '4px',
            },
            // '&::-webkit-scrollbar:disabled':{
            //     opacity:0,
            // },

            '&::-webkit-scrollbar-track': {
                boxShadow: 'unset',
                borderRadius: '3px',
                backgroundColor: '#00000000',
                transition: 'all 0.3s ease'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#0000',
                borderRadius: '3px',
                height: '4px'
            },
        },
        '&:hover .MuiDataGrid-scrollbar': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
                borderRadius: '3px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
                borderRadius: '3px',
            }
        },
        '& *': {
            userSelect: 'initial',
            boxSizing: 'border-box',
            outline: 'none !important',
            // overflow: 'visible',
        },
        '& .expandBlock': {
            '& *': { border: 'none !important' },
            backgroundColor: `${alpha(style.colorPrimary1, 0.1)}`,
            borderTop: `solid 1px ${alpha(style.colorPrimary3, 1)}`,
            borderBottom: `solid 1px ${alpha(style.colorPrimary3, 1)}`,
            color: style.colorPrimary3,
            alignItems: 'center',
            textTransform: 'uppercase',
            fontSize: '1.3rem'
        },
        '& .global': {
            '& *': { border: 'none !important' },
            fontWeight: 'bolder',
            backgroundColor: `${alpha(style.colorPrimary2, 0.1)}`,
            textTransform: 'uppercase',
            fontSize: '0.9rem',
        },
        '& .paramsBlock': {
            '& *': { border: 'none !important' },
            backgroundColor: `${alpha(style.colorPrimary2, 0.1)}`,
            textTransform: 'uppercase',
            fontSize: '1rem',
        },
        '& .paramsSubBlock': {
            '& *': { border: 'none !important' },
            // boxShadow: `inset 0px 0px 0 1px ${alpha(style.colorPrimary3, 0.3)}`,
            // borderSpacing: '-1px',
            // borderBottom:`solid 1px ${alpha(style.colorPrimary3, 0.3)}`,
            fontSize: '1rem',
            color: `${alpha(style.colorPrimary3, 1)}`,
            textTransform: 'uppercase',
            boxShadow: `0px 1px ${alpha(style.colorPrimary3, 0.7)}`,
        },
        '& .paramsData': {
            '& *': { border: 'none !important' },
            fontSize: '1rem',
            // '& .name': {
            //     paddingLeft: '3rem',
            //     position: 'relative',
            //     '&:before': {
            //         content: '""',
            //         position: 'absolute',
            //         left: '2rem', top: 0, bottom: 0,
            //         borderLeft: `1px solid ${alpha(style.colorPrimary3, 0.3)}`,
            //     }
            // },
            // color: `${alpha(style.colorPrimary3, 0.6)}`,
            // fontSize: '0.9rem',
        },
        '& .main': {
            '& *': { border: 'none !important' },
            backgroundColor: `${alpha(style.colorPrimary3, 0.07)}`,
            textTransform: 'initial',
            fontSize: '1rem',
        },
        '& .sub': {
            '& *': { border: 'none !important' },
            boxShadow: `inset 0px 0px 0 1px ${alpha(style.colorPrimary3, 0.3)}`,
            borderSpacing: '-1px',
            textTransform: 'uppercase',
            fontSize: '0.9rem',
        },
        '& .sub1': {
            '& *': {
                border: 'none !important',
            },

            '& .name': {
                paddingLeft: '3.6rem',
                position: 'relative',
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    left: '2.4rem', top: 0, bottom: 0,
                    borderLeft: `1px solid ${alpha(style.colorPrimary3, 0.3)}`,
                }
            },
            color: `${alpha(style.colorPrimary3, 0.6)}`,
            fontSize: '0.9rem',
        },
    },
    rowData: {
        padding: '0 0.6rem',
        '&>.name': {
            width: '320px !important',
            alignSelf: 'center',
            padding: '0.4rem 0',
        }
    },
    cellData: {
        justifyContent: 'end !important',
        outline: 'none !important'
    },
    unitData: {
        width: '50px',
        color: `${alpha(style.colorPrimary3, 0.6)}`,
        fontSize: '0.7rem',
        whiteSpace: 'nowrap',

    },
    headerTitle: {
        lineHeight: 'initial',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: '1px', right: '1px',
        '& .title': {
            fontWeight: 500,
            fontSize: '1.2rem',
        },
        '& .type': {
            textTransform: 'uppercase',
            color: `${style.colorPrimary3_40}`
        },
        '&:hover $removeButtonBlock': {
            display: 'block',
        },
    },
    removeButtonBlock: {
        display: 'none',
        position: 'absolute',
        backgroundColor: alpha(style.colorPrimary3, 0.1),
        '&:hover': { backgroundColor: alpha(style.colorPrimary3, 0.3) }
    },
    removeButton: {
        color: `${alpha(style.colorPrimary3, 0.4)}`,//'rgb(210,40,40)',
        width: '40px',
        height: '40px',
        // borderRadius:'50%',
        // border:0,
        '&:hover': {
            color: alpha(style.colorError, 0.5),
            // backgroundColor: alpha(style.colorPrimary3, 0.5)
        }
    },
    popOverRoot: {
        marginTop: '3px',
        // pointerEvents: "none"
        // maxHeight: '50%'
    },
    menuAccordion: {
        padding: 0,
        margin: 0,
        width: '100%',
    },
    menuAccordionDetails: {
        mardinLeft: '0.5rem',
        // padding: 0,
    },
    menuAccordionContent: {
        margin: 0,
    },
    menuAccordionSummary: {
        minHeight: '2rem !important',
        margin: 0,
        padding: '0 0.5rem',
        '& .Mui-expanded': {
            margin: '0px 0',

        },
    },
    dealSummaryExpanded: {
        margin: 0,
        minHeight: '1rem',
        backgroundColor: alpha(style.colorPrimary3, 0.15),
    },
    nodeSummaryExpanded: {
        margin: 0,
        minHeight: '1rem',
        // backgroundColor: style.colorPrimary3_20,
    },
    menuItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        width: '100%',
        padding: 0,
        '& .MuiPaper-root': {
            borderRadius: 0,
            margin: 0,
        },
        // '& *:not(:first-child)': {
        //     marginLeft: '0.5rem',
        // },
        '& $menuItem:hover': {
            backgroundColor: alpha(style.colorPrimary2, 0.15),
            // '&:before': {
            //     content: '"►"',
            //     position: 'absolute',
            //     left: 0,
            // },
        },
    },
    menuItem: {
        position: 'relative',
        flexDirection: 'row',
        padding: '0 0.5rem',
        // paddingLeft: '10px',
        '&>*:not(:first-child)': {
            marginLeft: '9px',
        },
    },
    dealItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        width: '100%',
        '& *:not(:first-child)': {
            marginLeft: '0.5rem',
            // color: `${style.colorPrimary3_40}`,
            // '&::before': {
            //     content: '"/"',
            //     marginRight: '0.5rem',
            //     // color:`${style.colorPrimary3_40}`,
            // }
        },

    },
    dealItemName: {
        // minWidth:'120px',
        // color: style.colorPrimary3,
        marginRight: '2rem',
        color: '#000',
    },
    dealItemInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexWrap: 'nowrap',
    },
    dealDebt: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        color: '#000',
    },
    dealDebtUnit: {
        color: style.colorPrimary3_40,
        marginLeft: '0.4rem',
    },
    dealCouponType: {
        color: 'rgba(0,183,50,0.5)', //эstyle.colorPrimary2,
        // fontWeight: '500',
        width: '50px !important',
        textAlign: 'right',
    },
    tabContainerNoData: {
        // position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1rem',
        flexGrow: 1,
    },
    labelColor: {
        color: style.colorPrimary3_120,
        userSelect: 'none',
    },
});

function CustomGridTreeDataGroupingCell(props) {
    const { id, row, field, rowNode } = props
    const apiRef = useGridApiContext()
    const [collapse, setCollapse] = useLocalStorage(false, rowNode.type == 'group' ? `Securitization/Comparation/RowCollapse_${row?.hierarchy?.join('')}` : null)
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    )

    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick = (event) => {
        if (rowNode.type !== 'group') {
            return;
        }
        setCollapse(rowNode.childrenExpanded)
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)

        event.stopPropagation();
    };

    return (
        <Box sx={{ ml: rowNode.depth * 4, }}>
            {/* <div> */}
            {filteredDescendantCount > 0 ?
                <IconButton size="small" onClick={handleClick} tabIndex={-1}>
                    {rowNode.childrenExpanded ? <ExpandMore /> : <ChevronRight />}
                </IconButton>
                : <span />
            }
            {/* </div> */}
        </Box>
    );
}

const NoRowOverlay = () => {
    const classes = useStyles()
    return <Box className={classes.tabContainerNoData}>
        {/* <h4 className={classes.labelColor}>
            {t("AddPoolToComparation")}
        </h4> */}
    </Box>
}

const NoResultsOverlay = () => {
    const classes = useStyles()
    return <Box className={classes.tabContainerNoData}>
        {/* <h4 className={classes.labelColor}>
            {t("NoResultToShow")}
        </h4> */}
    </Box>
}

const LoadingOverlay = () => {
    const classes = useStyles()
    return <Box className={classes.tabContainerNoData}>
        {/* <h4 className={classes.labelColor}>
            {t("Loading")}
        </h4> */}
    </Box>
}



function ComparePools({ /*poolList = null*/ }) {
    const nameColumnWidth = 280
    const dataColumnWidth = 140
    const unitColumnWidth = 60
    const classes = useStyles()

    const { deals,
        poolsToCompareData = null,
        originators = null,
        pool, poolCompareArray: poolList = [],
        ...otherStore
    } = useSelector((state: any) => state.securitization)
    const { alerts = [] } = useSelector((state: any) => state.alerts)

    // console.log('poolsToCompareData', poolsToCompareData)
    // console.log('originators', originators)
    // console.log('otherStore', otherStore)
    const [comparationData, setComparationData] = useState(null)
    const [dealList, setDealList] = useState([])
    const [loading, setLoading] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null)
    const [dealAnchorEl, setDealAnchorEl] = useState(null)
    const [nodeAnchorEl, setNodeAnchorEl] = useState(null)
    const [rows, setRows] = useState([])
    // const apiRef = useGridApiRef()
    const dispatch = useDispatch()

    const [poolCompareArray, setPoolCompareArray] = useLocalStorage([], 'Securitization/PoolCompareArray')

    // useLayoutEffect(() => {
    //     if (poolList?.length && poolsToCompareData?.pools?.length && poolList?.length != poolsToCompareData?.pools?.length) {
    //         const newList = [...poolsToCompareData?.pools]?.filter(pool => poolList.includes(pool.poolID)).map(x => x.poolID)

    //         setPoolCompareArray(newList)
    //     }
    // }, [poolsToCompareData])

    const handleCopyData = (row) => {
        // console.log('event', row)
        const header = ['']
        let source = []
        switch (row?.name) {
            case 'PoolStatistic':
                // console.log('PoolStatisticStructure', PoolStatisticStructure)
                source = PoolStatisticStructure
                break
            case 'PricingParameters':
                // console.log('PricingParametersStructure', PricingParametersStructure)
                source = PricingParametersStructure
                break
            case 'IterationGroup':
                // console.log('poolsToCompareData?.structure?.rows', poolsToCompareData?.structure?.rows)
                source = poolsToCompareData?.structure?.rows
                break
            default:
                // console.log('data.structure.rows', data?.structure?.rows)
                break
        }

        let val = ''
        let textToCopy = ''
        const dataSource = poolCompareArray.map(poolId => poolsToCompareData?.pools?.filter(pool => pool?.poolID == poolId)[0])
        //console.log('dataSource', dataSource)
        /*
        CHECKME!!! to check
        КБД в точке дюрации
        G-спред
        Требуемая доходность
        Требуемая ставка купона
        etc was hidden at clipboard copy, but visible at page
        */
        source?.filter(x => /*x.show && */x?.name != null)?.map(x => {
            console.log('x', x)
            // if (poolsToCompareData?.pools?.some(pool => (x?.getValue ? x.getValue(x, pool) : getValue(x, pool)) != null)) {
            if (dataSource?.some(pool => getValue(x, pool) != '')) {
                textToCopy += `${t(x?.alias)}\t`

                dataSource
                    ?.map(pool => {
                        header.push(pool?.dealName)


                        if (x?.getValue == customGetBoolean) {
                            val = (pool[x.name] ?? false) ? t("Yes") : t("No") //Masha wants y/n
                        }
                        else
                            val = getValue(x, pool)

                        if (typeof (val) == 'object') {

                            val = val?.props?.children
                        }
                        // console.log('val', val, x)

                        textToCopy += `${val ?? ''}\t`
                    })
                textToCopy += `${x.unit ?? ''}\r\n`
                // textToCopy += `\r\n`
            }
        })
        if (textToCopy != '') {
            const data = '\t' + dataSource?.map(pool => pool?.dealName)?.join('\t') + '\r\n' + textToCopy
            copyTextToClipboard(data)
            // actionAlert.addAlert({ component: Alert, key:{`comparePoolAlert_${Date.now()}`}, id:{`comparePoolAlert_${Date.now()}`}, text:{t('securitization.dataCopySuccefully')}, color:{style.colorPrimary1}, timeout:{4000} /> })(dispatch)
            // actionAlert.addAlert(t('securitization.dataCopySuccefully'), style.colorPrimary1)(dispatch)
            actionAlert.addAlert({ text: t('securitization.dataCopySuccefully'), color: style.colorPrimary1 })(dispatch)
        }
    }

    const NameRowCell = (params) => {
        const { id, row, field, rowNode } = params
        const apiRef = useGridApiContext()
        const [collapse, setCollapse] = useLocalStorage(false, rowNode.type == 'group' ? `Securitization/Comparation/RowCollapse_${row?.hierarchy?.join('')}` : null)
        const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector)
        let content
        // const collapse = localStorage.getItem("myCat");
        const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

        const handleClick = (event) => {
            if (rowNode.type !== 'group') {
                return;
            }
            // console.log('row?.hierarchy', row?.hierarchy)

            setCollapse(rowNode.childrenExpanded)
            // const v = localStorage.getItem(`Securitization/Comparation/RowCollapse_${row?.hierarchy?.join('')}`)
            // console.log('v', v)
            // localStorage.setItem(`Securitization/Comparation/RowCollapse_${row?.hierarchy?.join('')}`, !v )
            apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)

            event.stopPropagation();
        }

        if (rowNode.type == 'group') {
            return <>
                {
                    filteredDescendantCount > 0 ? (
                        <Box onClick={(e) => { handleClick(e) }} className={classes.blockTitleRow}>
                            {`${t(params?.row?.alias)} ` || ''}
                            <IconButton size='small'
                                className={classes.iconButton}
                                onClickCapture={(e) => { e.preventDefault(); e.stopPropagation(); handleCopyData(row) }}
                            >
                                <Tooltip
                                    title={t('securitization.copyData')}
                                >
                                    <CopyAll className={classes.copyIcon} />
                                </Tooltip>
                            </IconButton>
                        </Box>
                    ) : t(params?.row?.alias)
                }
            </>
        }

        if (params?.row?.customTooltip != null && typeof (params?.row?.customTooltip) == 'function')
            content = params?.row?.customTooltip(params?.row)

        return <>
            <CustomTooltip
                content={content ? content : (params?.row?.tooltip ? t(params?.row?.tooltip) : null)}
                hideIcon
            >
                {`${t(params?.row?.alias)} ` || ''}
            </CustomTooltip>
        </>
    }

    const customGetPremiumCouponType = (row, deal) => {
        try {
            if (deal.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM)
                return deal[row.name]
        }
        catch {
        }

        return null
    }

    const customGetNotPremiumCouponType = (row, deal) => {
        try {
            if (deal.couponType == CouponType.TYPE_FIXED)
                return deal[row.name]
        }
        catch {
        }

        return null
    }

    const customGetCouponType = (row, deal) => {
        try {
            switch (deal.couponType) {
                case CouponType.TYPE_FIXED:
                    return t("securitization.CouponTypeFixedShort")

                case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                    return t("securitization.CouponTypeStrKeyrateAndPremiumShort")

                case CouponType.TYPE_FLOAT:
                    return t("securitization.CouponTypeFloatingShort")

                default:
                    return null
            }
        }
        catch {
        }

        return null
    }
    const customGetDate = (row, deal) => {
        try {
            return Localization.FormatDateStringLocale(deal[row.name])
        }
        catch {
        }

        return null
    }
    const customGetBoolean = (row, deal) => {
        try {
            switch (deal[row.name]) {
                case true:
                    return (<CheckBoxOutlined style={{ width: '1.2rem', height: '1.2rem' }} />)

                default:
                    return (<CheckBoxOutlineBlank style={{ width: '1.2rem', height: '1.2rem' }} />)
            }
        }
        catch {
        }

        return null
    }

    const customGetRuonia = (row, pool) => {
        try {
            var res = pool[row.name];
            if (res != null) {
                return res;
            }

            if (pool.isKeyrateRequired)
                return t('Securitization_ExpectedRUONIA_KaP');
        }
        catch {
        }

        return null
    }

    const customGetPerYear = (row, deal) => {
        try {
            return deal[row.name] / 12.0 || '-'
        }
        catch {
        }

        return null
    }

    const customGetPoolParam = (row, deal) => {
        try {
            if (row?.invertSign)
                return -1 * deal[row.name] || <Box color={`${style.colorPrimary3_40} `}>-</Box>
            else
                return deal[row.name] || <Box color={`${style.colorPrimary3_40} `}>-</Box>
        }
        catch {
        }

        return null
    }

    const getNodeType = (row, data) => {
        try {
            // console.log('data', data, row)
            switch (data[row.name]) {
                case ePoolNodeType.TYPE_POOL_AT_REGISTRATION_DATE:
                    return t('Node_rip').toUpperCase()

                case ePoolNodeType.TYPE_POOL_AT_TRANSFER_DATE:
                    return t('Node_transfer').toUpperCase()

                case ePoolNodeType.TYPE_POOL_PLAIN:
                default:
                    return t('Node_lld').toUpperCase()
            }
        }
        catch {
        }

        return null
    }


    const getBlnsVal = (row, deal) => {
        try {
            return deal[row.name] / 1000000000.0
        }
        catch {
        }

        return null
    }

    const getMlnsVal = (row, deal) => {
        try {
            return deal[row.name] / 1000000.0
        }
        catch {
        }

        return null
    }

    const getPoolDistribution = (row, deal) => {
        try {
            return deal[row.name]?.filter(distribution => parseInt(distribution.product) == row.value)[0]?.percents
        }
        catch {
        }

        return null
    }

    const isProgramsDistributionPresent = () => {
        try {
            return poolsToCompareData?.pools?.some(x => x?.preferentialMortgageDistribution?.some(distribution => distribution.preferentialType != null))
        }
        catch {
        }

        return false
    }

    const getProgramsDistribution = (row, deal) => {
        try {
            return deal[row.name]?.filter(distribution => parseInt(distribution.preferentialType) == row.value)[0]?.percents
        }
        catch {
        }

        return null
    }

    /*
        public int DealID { get; set; }
        public string DealName { get; set; }
        public int? IterationID { get; set; }
        public int PoolID { get; set; }

        public ColumnDefinition[] Columns { get; set; }
        public decimal?[] CalculationWAC { get; set; }
        public decimal?[] LoanServiceWithoutVAT { get; set; }


        public decimal?[] PoolEffects { get; set; }
        public decimal?[] YieldShortfallEffect { get; set; }
        public decimal?[] WACMovementEffect { get; set; }
        public decimal?[] MortgageHolidaysEffect { get; set; }


        public decimal?[] GuaranteeTotal { get; set; }
        public decimal?[] GuaranteeAccrualEffect { get; set; }


        public decimal?[] SecuritizationExpenses { get; set; }
        public decimal?[] ResLoanServiceWithVAT { get; set; }
        public decimal?[] SpecDepTotalExpenses { get; set; }
        public decimal?[] LoanServiceVAT { get; set; }
        public decimal?[] MoscowExchangeAndNSD { get; set; }
        public decimal?[] OtherFixedExpenses { get; set; }


        public decimal?[] ReinvestmentEffect { get; set; }
        public decimal?[] CompensationsEffect { get; set; }
        public decimal?[] FloatingRate { get; set; }
        public decimal?[] CouponAccrualEffect { get; set; }
        public decimal?[] MaximumCouponRate { get; set; }


        public decimal?[] RateToIncrementConvertion { get; set; }
        public decimal?[] KeyRateDeduction { get; set; }
        public decimal?[] KeyRateAccrual { get; set; }
        public decimal?[] CompensationDelay { get; set; }


        public decimal?[] MaximumCouponIncrement { get; set; }
        public decimal?[] DurationMBS { get; set; }

        public decimal?[] RateDifference { get; set; }
        public decimal?[] ZCYCValue { get; set; }
        public decimal?[] Gspread { get; set; }
        public decimal?[] RequiredYTM { get; set; }
        public decimal?[] RequiredRate { get; set; }
        public decimal?[] IncrementDifference { get; set; }
        
        public decimal?[] MBSPrice { get; set; }
        public decimal?[] SwapMAIssue { get; set; }
        public decimal?[] SwapMAFirstCoupon { get; set; }
        public decimal?[] SwapOrigIssue { get; set; }
        public decimal?[] SwapOrigFirstCoupon { get; set; }
        public decimal?[] SwapExpenses { get; set; }
        public decimal?[] SwapExpensesFirstCoupon { get; set; }
        public decimal?[] SwapExpensesGuarantee { get; set; }
        public decimal?[] SwapExpensesExchangeAndNSD { get; set; }
        public decimal?[] ReinvestmentCompensationEffect { get; set; }
        public decimal?[] KeyRateWA { get; set; }
        public decimal?[] ActualMBSRateOrIncrement { get; set; }

        public decimal?[] LoanServiceRateClean { get; set; }
        public decimal?[] LoanServiceRateEffect { get; set; }

        ///deal fields
        public decimal? ExpectedIssueAmount { get; set; }
        public decimal? ExpectedDeliveryPrice { get; set; }
        public decimal? ExpectedDeliveryDebt { get; set; }
        public decimal? ExpectedDeliveryYield { get; set; }
        public decimal? ExpectedDeliveryPremium { get; set; }
        public decimal? PoolPrice { get; set; }

        //pool fields
        public DateTime CreatedDate { get; set; }
           
        public double? StartMainDebt { get; set; }

        public double? PreferenceKeyRateDeduction { get; set; }
        public double? FamilyKeyRateDeduction { get; set; }
        public double? PreferenceFraction { get; set; }
        public double? FamilyFraction { get; set; }

        public int? UserID { get; set; }

        //public double? GuaranteeRate { get; set; }
        //public double? InsuranceRate { get; set; }
        
        public double? PoolShare { get; set; }

        public int? PoolExpectedLoss { get; set; }
        public int? OPEX { get; set; }
        public int? PoolCapitalMargin { get; set; }
        public int? PoolPrepaymentOption { get; set; }
        public int? PoolBankMargin { get; set; }
    */

    const PoolStatisticStructure = [
        , { show: true, name: "poolDate", alias: "ActualDate", getValue: customGetDate, tooltip: "ActualDate_Info", frame: "paramsData", fractionPart: null, type: null, unit: null }
        , { show: true, name: "nodeType", alias: "NodeType", getValue: getNodeType, tooltip: "NodeType_tooltip", frame: "paramsData" }//дата пула
        , { show: true, name: "mortgagesCount", alias: "MortgagesCountShort", tooltip: "MortgagesCount_Info", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: null }
        , { show: true, name: "currentMainDebt", alias: "CurrentMainDebt", getValue: getBlnsVal, tooltip: "CurrentMainDebt_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("BillionsRubSign") }
        , { show: true, name: "averageMainDebt", alias: "AverageMainDebt", getValue: getMlnsVal, tooltip: "AverageMainDebt_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("MillionsRubSign") }
        , { show: true, frame: "separator" }
        , { show: true, name: "initialWAC", alias: "InitialWAC", getValue: customGetPoolParam, tooltip: "InitialWAC_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }
        , { show: true, name: "minimalWAC", alias: "MinimalWAC", getValue: customGetPoolParam, tooltip: "MinimalWAC_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }
        , { show: true, name: "currentWAC", alias: "CurrentWAC", getValue: customGetPoolParam, tooltip: "CurrentWAC_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }
        , { show: true, name: "lifetimeWAC", alias: "LifetimeWAC", getValue: customGetPoolParam, tooltip: "LifetimeWAC_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }
        , { show: true, frame: "separator" }
        , { show: true, name: "wala", alias: "WALAPlainShort", tooltip: "WALA_Info", frame: "paramsData", fractionPart: 1, type: 'numeric', unit: t("yearSign") }
        , { show: true, name: "wam", alias: "WAM", getValue: customGetPerYear, tooltip: "WAM_Info_Full", frame: "paramsData", fractionPart: 1, type: 'numeric', unit: t("yearSign") }
        , { show: true, name: "adjustedWAM", alias: "AdjustedWAMShort", getValue: customGetPerYear, tooltip: "AdjustedWAM_Info", frame: "paramsData", fractionPart: 1, type: 'numeric', unit: t("yearSign") }
        , { show: true, frame: "separator" }
        , { show: true, name: "governProgramsFraction", alias: "GovernProgramsFraction", getValue: customGetPoolParam, tooltip: "GovernProgramsFraction_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percent") }
        , { show: true, name: "governProgramsKeyRateDeduction", alias: "GovernProgramsKeyRateDeduction", getValue: customGetPoolParam, tooltip: "GovernProgramsKeyRateDeduction_Info", frame: "paramsData", invertSign: true, fractionPart: 2, type: 'numeric', unit: t("percent") }
        , { show: isProgramsDistributionPresent(), frame: "separator" }
        , { show: true, name: "preferentialMortgageDistribution", value: 1, alias: "Preferential", getValue: getProgramsDistribution, frame: "paramsData", type: 'numeric', fractionPart: 1, unit: t("percent") }
        , { show: true, name: "preferentialMortgageDistribution", value: 2, alias: "Family", getValue: getProgramsDistribution, frame: "paramsData", type: 'numeric', fractionPart: 1, unit: t("percent") }
        , { show: true, name: "preferentialMortgageDistribution", value: 3, alias: "FamilyDFO", getValue: getProgramsDistribution, frame: "paramsData", type: 'numeric', fractionPart: 1, unit: t("percent") }
        , { show: true, name: "preferentialMortgageDistribution", value: 4, alias: "DFO", getValue: getProgramsDistribution, frame: "paramsData", type: 'numeric', fractionPart: 1, unit: t("percent") }
        , { show: true, name: "preferentialMortgageDistribution", value: 5, alias: "IT", getValue: getProgramsDistribution, frame: "paramsData", type: 'numeric', fractionPart: 1, unit: t("percent") }        // , { show: true, name: "preferenceFraction", alias: "PreferenceFraction", getValue: customGetPoolParam, tooltip: "PreferenceFraction_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percent") }
        // , { show: true, name: "familyFraction", alias: "FamilyFraction", getValue: customGetPoolParam, tooltip: "FamilyFraction_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percent") }
        // , { show: true, name: "preferenceKeyRateDeduction", alias: "PreferenceKeyRateDeduction", getValue: customGetPoolParam, tooltip: "PreferenceKeyRateDeduction_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }
        // , { show: true, name: "familyKeyRateDeduction", alias: "FamilyKeyRateDeduction", getValue: customGetPoolParam, tooltip: "FamilyKeyRateDeduction_Info", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }
        , { show: true, frame: "separator" }
        , { show: true, name: "startLTV", alias: "StartLTV_short", getValue: customGetPoolParam, tooltip: "StartLTV_Info", frame: "paramsData", fractionPart: 1, type: 'numeric', unit: t("percent") }
        , { show: true, name: "currentLTV", alias: "CurrentLTV_short", getValue: customGetPoolParam, tooltip: "CurrentLTV_Info", frame: "paramsData", fractionPart: 1, type: 'numeric', unit: t("percent") }
        , { show: true, name: "startPTI", alias: "StartPTI_short", getValue: customGetPoolParam, tooltip: "StartPTI_Info", frame: "paramsData", fractionPart: 1, type: 'numeric', unit: t("percent") }
        , { show: true, name: "currentPTI", alias: "CurrentPTI_short", getValue: customGetPoolParam, tooltip: "CurrentPTI_Info", frame: "paramsData", fractionPart: 1, type: 'numeric', unit: t("percent") }
        , { show: true, frame: "separator" }
        , { show: true, name: "productDistribution", value: 1, alias: "PrimaryRealty", getValue: getPoolDistribution, frame: "paramsData", type: 'numeric', fractionPart: 1, unit: t("percent") }
        , { show: true, name: "productDistribution", value: 2, alias: "SecondaryRealty", getValue: getPoolDistribution, frame: "paramsData", type: 'numeric', fractionPart: 1, unit: t("percent") }
        , { show: true, name: "productDistribution", value: 3, alias: "Refinancing", getValue: getPoolDistribution, frame: "paramsData", type: 'numeric', fractionPart: 1, unit: t("percent") }
        , { show: true, name: "productDistribution", value: 4, alias: "Other", getValue: getPoolDistribution, frame: "paramsData", type: 'numeric', fractionPart: 1, unit: t("percent") }
    ]

    // const myTooltip = (row) => {
    //using at ROW: customTooltip: myTooltip,
    // }

    const PricingParametersStructure = [

        , { show: true, name: "DealDatesPanel", alias: "Dates", frame: "paramsSubBlock" }
        //duplicates!
        , { show: true, name: "poolDate", alias: "PoolSlaceDate", getValue: customGetDate, tooltip: "PoolDate_tooltip", frame: "paramsData" }//дата пула
        , { show: true, name: "inclusionDate", alias: "InclusionDate", getValue: customGetDate, tooltip: "InclusionDate_tooltip", frame: "paramsData" }//Дата включения в ИП
        , { show: true, name: "fixationDate", alias: "FixationDate", getValue: customGetDate, tooltip: "FixationDate_tooltip", frame: "paramsData" }//Дата подписания решения
        , { show: true, name: "actDeliveryDate", alias: "ActDeliveryDate", getValue: customGetDate, tooltip: "ActDeliveryDate_tooltip", frame: "paramsData" }//Дата передачи закладных
        , { show: true, name: "issueDate", alias: "IssueDate", getValue: customGetDate, tooltip: "IssueDate_tooltip", frame: "paramsData" }//Дата размещения ИЦБ
        , { show: true, name: "secondPeriodStartDate", alias: "SecondPeriodStartDate", getValue: customGetDate, tooltip: "SecondPeriodStartDate_tooltip", frame: "paramsData" }//Дата начала второго расч. периода
        , { show: true, name: "firstCouponDate", alias: "FirstCouponDate", getValue: customGetDate, tooltip: "FirstCouponDate_tooltip", frame: "paramsData" }//Дата выплаты первого купона
        , { show: true, name: "maturityDate", alias: "LegalMaturityDate", getValue: customGetDate, tooltip: "LegalMaturityDate_tooltip", frame: "paramsData" }//Срок до погашения (WA), мес

        , { show: true, name: "BondParametersPanel", alias: "BondParameters", frame: "paramsSubBlock" } //BondParametersPanel
        , { show: true, name: "couponType", alias: "CouponType", tooltip: "CouponType_tooltip", getValue: customGetCouponType, frame: "paramsData" } //dataType: InputDataType.ENUM, dataSource: couponTypes 
        , { show: true, name: `realCouponRate`, alias: "PremiumValue", tooltip: "RealCouponRate2_tooltip", getValue: customGetPremiumCouponType, frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("percentInYear") }
        , { show: true, name: `realCouponRate`, alias: "CouponRate", getValue: customGetNotPremiumCouponType, tooltip: "RealCouponRate1_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("percentInYear") }
        , { show: true, name: "swapORIG", alias: "SwapORIG", getValue: customGetBoolean, frame: "paramsData" }
        , { show: true, name: "couponPeriod", alias: "CouponPeriod", tooltip: "CouponPeriod_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("months") }
        , { show: true, name: "cleanUpCall", alias: "CleanUpCall", tooltip: "CleanUpCall_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("percent") }

        , { show: true, name: "BondTariffExpensesPanel", alias: "TariffExpenses", frame: "paramsSubBlock" } //BondTariffExpensesPanel
        , { show: true, name: "guaranteeRate", alias: "GuaranteeRate", tooltip: "GuaranteeRate1_tooltip", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") } //GuaranteeRate	Тариф поручительства, % год.
        , { show: true, name: "loanServRate", alias: "LoanServiceRate", tooltip: "LoanServRate_tooltip", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }//LoanServRate	Тариф основного сервиса с НДС, % год.
        , { show: true, name: "loanServMonthly", alias: "LoanServMonthly", getValue: customGetBoolean, tooltip: "LoanServMonthly_tooltip", frame: "paramsData" }//LoanServMonthly	Тариф основного сервиса начисляется ежемесячно ataType: InputDataType.BOOLEAN },
        , { show: true, name: "resLoanServRate", alias: "ResLoanServRate", tooltip: "ResLoanServRate_tooltip", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }//ResLoanServRate	Тариф резервного сервиса с НДС, % год.
        , { show: true, name: "specDepRate", alias: "SpecDepRate", tooltip: "SpecDepRate_tooltip", frame: "paramsData", /*fractionPart: 2,*/ type: 'numeric', unit: t("percentInYear") }//SpecDepRate	Тариф спец. депозитария, % год.
        , { show: true, name: "specDepMin", alias: "SpecDepMin", tooltip: "SpecDepMin_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("rubPerMonth") }//SpecDepMin	Мин.вознаграждение спец.депозитария, руб./ мес.
        , { show: true, name: "specDepFixed", alias: "SpecDepFixed", tooltip: "SpecDepFixed_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("rubPerMonth") }//SpecDepFixed	Возмещаемые расходы спец. депозитарию, руб./мес.
        , { show: true, name: "insuranceRate", alias: "InsuranceRate", tooltip: "InsuranceRate_tooltip1", frame: "paramsData", /*fractionPart: 2,*/ type: 'numeric', unit: t("percentInYear") }//InsuranceRate	Страховой тариф, % год.

        , { show: true, name: "BondOncedExpensesPanel", alias: "BondOncedExpenses", frame: "paramsSubBlock" } //BondTariffExpensesPanel
        , { show: true, name: "comissionMOEXFirst", alias: "ComissionMOEXFirst", tooltip: "ComissionMOEXFirst_tooltip", frame: "paramsData", fractionPart: null, type: 'numeric', unit: t("rubSign") }//ComissionMOEXFirst	Московская биржа, руб. единовременно
        , { show: true, name: "comissionNSDFirst", alias: "ComissionNSDFirst", tooltip: "ComissionNSDFirst_tooltip", frame: "paramsData", fractionPart: null, type: 'numeric', unit: t("rubSign") }//ComissionNSDFirst	НРД, руб. единовременно
        , { show: true, name: "settlementAgentFirst", alias: "SettlementAgentFirst", tooltip: "SettlementAgentFirst_tooltip", frame: "paramsData", fractionPart: null, type: 'numeric', unit: t("rubSign") }//SettlementAgentFirst	Расчетный агент, руб. единовременно
        , { show: true, name: "underwriterFirst", alias: "UnderwriterFirst", tooltip: "UnderwriterFirst_tooltip", frame: "paramsData", fractionPart: null, type: 'numeric', unit: t("rubSign") }//UnderwriterFirst	Андеррайтер эмитента, руб. единовременно

        , { show: true, name: "BondConstantExpensesPanel", alias: "ConstantExpenses", frame: "paramsSubBlock" } //BondConstantExpensesPanel
        , { show: true, name: "manAccFloating", alias: "CalculatedAccFloating", getValue: customGetBoolean, tooltip: "ManAccFloating_tooltip", frame: "paramsData" }//ManAccFloating	Вознаграждение УО и БО определяется коэффициентом
        , { show: true, name: "manAccCoef", alias: "ManAccCoef", tooltip: "ManAccCoef_tooltip", frame: "paramsData", fractionPart: null, type: 'numeric', unit: null }//ManAccCoef	Коэффициент вознаграждения УО и БО
        , { show: true, name: "manAccFixedSum", alias: "ManAccFixedSum", tooltip: "ManAccCManAccFixedSum_tooltipoef_tooltip", frame: "paramsData", fractionPart: null, type: 'numeric', unit: t("rubPerQuartal") }//ManAccFixedSum	Фиксированная сумма вознаграждения, руб./квартал
        , { show: true, name: "settlementAgentYearly", alias: "SettlementAgentYearly", tooltip: "SettlementAgentYearly_tooltip", frame: "paramsData", fractionPart: null, type: 'numeric', unit: t("rubPerYear") }//SettlementAgentYearly	Расчетный агент, руб. ежегодно
        , { show: true, name: "comissionMOEXYearly", alias: "ComissionMOEXYearly", tooltip: "ComissionMOEXYearly_tooltip", frame: "paramsData", fractionPart: null, type: 'numeric', unit: t("rubPerYear") }//ComissionMOEXYearly	Московская биржа, руб./год
        , { show: true, name: "comissionNSDQuarter", alias: "ComissionNSDQuarter", tooltip: "ComissionNSDQuarter_tooltip", frame: "paramsData", fractionPart: null, type: 'numeric', unit: t("rubPerQuartal") }//ComissionNSDQuarter	НРД, руб./квартал
        , { show: true, name: "marketMakerMonth", alias: "MarketMakerMonth", tooltip: "MarketMakerMonth_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("rubPerMonth") }//MarketMakerMonth	Маркет-мейкер, руб./мес.
        , { show: true, name: "bankAccountPaymentsMonth", alias: "BankAccountPaymentsMonth", tooltip: "BankAccountPaymentsMonth_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("rubPerMonth") }//BankAccountPaymentsMonth	Банк счета сбора платежей, руб./мес.
        , { show: true, name: "bankAccountIssuerAndAgentMonth", alias: "BankAccountIssuerAndAgentMonth", tooltip: "BankAccountIssuerAndAgentMonth_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("rubPerMonth") }//BankAccountIssuerAndAgentMonth	Банк счета эмитента и счета ипотечного покрытия, руб./мес.

        , { show: true, name: "ActOfTransferDataPanel", alias: "AnotherPerametrs", frame: "paramsSubBlock" }//ActOfTransferDataPanel
        , { show: true, name: "deliveryDayYieldToOriginator", alias: "DeliveryDayYieldToOriginator", getValue: customGetBoolean, tooltip: "DeliveryDayYieldToOriginator_tooltip", frame: "paramsData" }
        , { show: true, name: "partialPaymentAllowedAnytime", alias: "PartialPaymentAllowedAnytime", getValue: customGetBoolean, tooltip: "PartialPaymentAllowedAnytime_tooltip", frame: "paramsData" }

        , { show: true, name: "MarketParametersPanel", alias: "MarketParameters", frame: "paramsSubBlock" }
        , { show: true, name: "zcycDate", alias: "ZCYCDate", getValue: customGetDate, tooltip: "PoolDate_tooltip", frame: "paramsData" }
        , { show: true, name: "zSpread", alias: "RequiredZSpread", tooltip: "RequiredZSpread_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("BPS") }
        , { show: true, name: "requiredIncrement", alias: "RequiredIncrement", tooltip: "RequiredIncrement_tooltip1", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }

        , { show: true, name: "CashflowParametersPanel", alias: "CashflowParameters", frame: "paramsSubBlock" }
        , { show: true, name: "cpr", alias: "ExpectedCPR", tooltip: "ExpectedCPR_tooltip", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }
        , { show: true, name: "cdr", alias: "ExpectedCDR", tooltip: "ExpectedCDR_tooltip", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }
        , { show: true, name: "useMinimalWAC", alias: "UseMinimalWAC", getValue: customGetBoolean, tooltip: "UseMinimalWAC_tooltip", frame: "paramsData" }
        , { show: true, name: "compensationLagDays", alias: "CompensationLagDays", tooltip: "CompensationLagDays_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("days") }

        , { show: true, name: "MortgageHolidaysPanel", alias: "MortgageHolidays", frame: "paramsSubBlock" }
        , { show: true, name: "holidayMonthsStart", alias: "HolidayMonthsStart", tooltip: "HolidayMonthsStart_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("months") }
        , { show: true, name: "holidayDurationMonths", alias: "HolidayDurationMonths", tooltip: "HolidayDurationMonths_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("months") }
        , { show: true, name: "holidaysFraction", alias: "HolidaysFraction", tooltip: "HolidaysFraction_tooltip", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percent") }

        , { show: true, name: "ChargeOnBalancesPanel", alias: "ChargeOnBalances", frame: "paramsSubBlock" }

        , { show: true, name: "reinvestment", alias: "Reinvestment", getValue: customGetBoolean, tooltip: "Reinvestment_tooltip", frame: "paramsData" }
        , { show: true, name: "expectedRUONIA", alias: "ExpectedRUONIA", getValue: customGetRuonia, tooltip: "ExpectedRUONIA_tooltip", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }
        , { show: true, name: "deductionRUONIA", alias: "DeductionRUONIA", tooltip: "DeductionRUONIA_tooltip", frame: "paramsData", fractionPart: 2, type: 'numeric', unit: t("percentInYear") }
        , { show: true, name: "stopAccrualDays", alias: "StopAccrualDays", tooltip: "StopAccrualDays_tooltip", frame: "paramsData", fractionPart: 0, type: 'numeric', unit: t("days") }
    ]


    useLayoutEffect(() => {
        // console.log('poolList****************************************', poolList)
        if (poolList?.length) {
            actionSecuritization.getPoolsToCompare(poolList)(dispatch)
            setLoading(true)
        }
        else {
            setComparationData(null)
            setLoading(false)
        }
    }, [poolList])

    useLayoutEffect(() => {
        if (poolCompareArray?.length != poolList?.length) {
            actionSecuritization.setPoolCompareArray(poolCompareArray)(dispatch)
        }
    }, [poolCompareArray])


    useLayoutEffect(() => {
        // console.log('poolsToCompareData !!!!!!!!!!!!!!!!!!!!!!!!!!!!', poolsToCompareData)
        if (poolsToCompareData?.pools != null) {
            let data = { ...poolsToCompareData ? poolsToCompareData : {} }
            data.structure = { ...poolsToCompareData?.structure ? poolsToCompareData?.structure : {} }
            data.structure.rows = [...poolsToCompareData?.structure?.rows ? poolsToCompareData?.structure?.rows : []]

            let poolData = false
            let iterationData = false
            let parametrsData = false


            if (PoolStatisticStructure?.filter(row => poolsToCompareData?.pools?.some(pool => Boolean(getValue(row, pool))))?.length)// проверка наличия данных в PoolStatisticStructure
                poolData = true

            if (data.structure.rows?.filter(row => poolsToCompareData?.pools?.some(pool => Boolean(getValue(row, pool))))?.length)// проверка наличия данных в dealComparationData
                iterationData = true

            if (PricingParametersStructure?.filter(row => poolsToCompareData?.pools?.some(pool => Boolean(getValue(row, pool))))?.length)// проверка наличия данных в PricingParametersStructure
                parametrsData = true

            data.structure.rows = [
                poolData ? { hierarchy: ['Pool'], show: true, name: "PoolStatistic", alias: "PoolStatistic", frame: "expandBlock" } : null,
                ...(poolData ? PoolStatisticStructure.map((x, index) => { if (x.show == true) return { hierarchy: ['Pool', `${x.name}_iteration_${index} `], ...x } }) : []),

                iterationData ? { hierarchy: ['Iteration'], show: true, name: "IterationGroup", alias: "PricingTabResultsTitile", frame: "expandBlock" } : null,
                ...(iterationData ? data.structure.rows.map((x, index) => { /*if(x.show)*/ return { hierarchy: ['Iteration', `${x.name}_iteration_${index} `], ...x } }) : []),

                parametrsData ? { hierarchy: ['Parametrs'], show: true, name: "PricingParameters", alias: "PricingParameters", frame: "expandBlock" } : null,
                ...(parametrsData ? PricingParametersStructure.map((x, index) => { if (x.show == true) return { hierarchy: ['Parametrs', `${x.name}_iteration_${index} `], ...x } }) : [])
            ]
            // console.log('data!!!', data)

            setComparationData(data)
            setLoading(false)
        }
    }, [poolsToCompareData])


    const removePool = (id) => {
        // console.log('id', id)
        setPoolCompareArray(poolList.filter(x => x != id))
        //actionSecuritization.setPoolCompareArray(poolList.filter(x => x != id))(dispatch)
    }

    const cellParams = {
        sortable: false,
        filterable: false,
        disableReorder: true,
        hideable: false,
        resizable: false,
    }

    const slotColumnCommonFields = {
        minWidth: dataColumnWidth,
        // cellClassName: (params) => 'name',
        colSpan: (value, row, column, apiRef) => {
            if (row.frame == 'global' || row.frame == "expandBlock") return comparationData?.pools?.length + 2
        },
    };

    const getValue = (row, pool) => {
        let item = row.name //.charAt(0).toLowerCase() + row.name.toString().slice(1) 
        // console.log('row', row)
        let data = null
        let value = null

        if (row.getValue != null && typeof (row.getValue) == 'function')
            data = row.getValue(row, pool)
        else
            data = pool[item] != null ? pool[item] : null

        if (Array.isArray(data)) {
            const index = pool?.columns.findIndex(item => item?.highlight === true)
            if (index == -1)
                value = data[0]
            else
                value = data[index]
        }
        else
            value = data

        //  console.log('value', value, data, deal?.columns)


        if (row?.type == 'numeric') {
            // console.log('row', row)
            return Localization.FormatNumberString(value, row?.fractionPart, row?.fractionPart)
        }
        else
            return value
    }

    const getTitle = (data) => {
        if (data?.columns == null)
            return '';

        if (data?.couponType == CouponType.TYPE_FLOAT)
            return ''

        const index = data?.columns.findIndex(item => item?.highlight === true && item?.alias != null)
        // console.log('deal.name, index', deal.dealName, index)
        if (index == -1)
            return t(data?.columns[0].alias)

        return t(data?.columns[index].alias)
    }
    const openAddDeal = (e) => {
        if (e?.currentTarget)
            setAnchorEl(e.currentTarget)
    }

    const originatorsList = originators
        ?.filter(originator =>
            deals?.items
                ?.some(item => item?.deal?.originatorID == originator?.id && item.deal?.poolNodes?.some(node => node?.pools?.length > 0 && !node?.pools?.some(pool => poolList?.includes(pool?.id))))
        )
        ?.sort((a, b) => a?.shortName < b?.shortName ? -1 : a?.shortName > b?.shortName ? 1 : 0)

    let columns = [{
        field: "name",
        width: nameColumnWidth,
        cellClassName: 'name',
        renderCell: NameRowCell,
        // field: "addDeal",
        headerName: "",
        renderHeader: (params) => {
            return (
                <Stack className={classes.headerTitle}>
                    <Button
                        variant="contained"
                        fullWidth
                        // color='primary'
                        disabled={originatorsList?.length == 0} size='small' onClick={(e) => openAddDeal(e)}
                        classes={{ disabled: classes.disabledButton, root: classes.addButton }}
                    >
                        {t("AddPoolToCompare")}
                    </Button>
                    {/* <IconButton disabled={originatorsList.length == 0} size='small' onClick={(e) => openAddDeal(e)}>
                        <AddCircleOutline htmlColor={originatorsList.length ? style.colorPrimary2 : style.colorPrimary3_20} sx={{ width: '34px', height: '34px' }} fontSize="large" />
                    </IconButton> */}

                </Stack>
            )
        },
        ...slotColumnCommonFields,
        ...cellParams
    }]

    // if (comparationData?.pools != null)
    // columns = columns.concat(comparationData?.pools?.map((pool, index) => {
    //     console.log('pool', pool)
    //     return {
    //         field: index.toString(),
    //         minWidth: dataColumnWidth,
    //         // valueGetter: (params) => {
    //         //     return `${ getValue(params.row, deal) || '' } `
    //         // },
    //         renderCell: (params) => {
    //             return <>
    //                 {getValue(params.row, pool)}
    //             </>
    //         },
    //         cellClassName: classes.cellData,
    //         renderHeader: (params) => {
    //             return (<Stack className={classes.headerTitle}>
    //                 <Box className='title'>{pool.dealName}</Box>
    //                 <Box className='type'>{getTitle(pool) || ''}</Box>
    //                 {/* {(selectedDeal.deal.id != deal.dealID) && */}

    //                 <IconButton className={classes.removeButtonBlock} size='small' onClick={() => removePool(pool.poolID)}>
    //                     {/* <RemoveCircleOutline className={classes.removeButton} /> */}
    //                     <ClearOutlined className={classes.removeButton} />
    //                 </IconButton>

    //                 {/* } */}
    //             </Stack>
    //             )
    //         },
    //         ...cellParams
    //     }
    // })
    if (poolList.length)
        columns = columns.concat(poolList?.map((poolID, index) => {
            const poolArr = comparationData?.pools.filter(pool => pool.poolID == poolID)
            const pool = poolArr?.length ? poolArr[0] : null

            return {
                field: index.toString(),
                minWidth: dataColumnWidth,
                // valueGetter: (params) => {
                //     return `${ getValue(params.row, deal) || '' } `
                // },
                renderCell: (params) => {
                    return <>
                        {pool ? getValue(params.row, pool) : ''}
                    </>
                },
                cellClassName: classes.cellData,
                renderHeader: (params) => {
                    return (
                        <Stack className={classes.headerTitle}>
                            <Box className='title'>{pool?.dealName ?? `${t('pool')} `}</Box>
                            <Box className='type'>{pool ? getTitle(pool) : `${poolID} `}</Box>

                            <IconButton className={classes.removeButtonBlock} size='small' onClick={() => removePool(poolID)}>
                                <ClearOutlined className={classes.removeButton} />
                            </IconButton>
                        </Stack>
                    )
                },
                ...cellParams
            }
        })
            // .concat({
            //     field: "addDeal",
            //     headerName: "",
            //     sortable: false,
            //     renderHeader: (params) => {
            //         return (<Stack className={classes.headerTitle}>
            //             <Box className='addButton'>
            //                 <IconButton size='small' onClick={(e) => openAddDeal(e)}>
            //                     <AddCircleOutline />
            //                 </IconButton>
            //             </Box>
            //         </Stack>
            //         )
            //     },
            // })
            .concat({
                field: "unit",
                headerName: "",
                width: unitColumnWidth,
                cellClassName: classes.unitData,
                // valueGetter: (params) => {
                //     if (!params?.row?.unit)
                //         return ''
                //     return `${ params?.row?.unit } `
                // },
                renderCell: (params) => {
                    if (!params?.row?.unit || params?.row?.frame == 'global')
                        return ''
                    return <>{`${params?.row?.unit} `}</>
                },
                ...cellParams
            })
        )
    // console.log('comparationData?.structure?.rows', comparationData?.structure?.rows)
    useEffect(() => {
        if (comparationData != null)
            setRows(comparationData?.structure?.rows
                ?.filter(x => x != null)
                ?.map((row, index) => { return { id: index, ...row } })
                ?.filter(row => {
                    return (row?.frame == 'separator'
                        || row?.frame == 'expandBlock'
                        || row?.frame == 'paramsSubBlock'
                        || row?.frame == 'paramsBlock'
                        || row?.frame == 'global'
                        || comparationData?.pools.some(pool => Boolean(getValue(row, pool))) //убираем строки без значений
                    )
                })
                // console.log('rows', rows)
            )
        else setRows([])
    }, [comparationData])

    const GetCouponType = (type) => {
        switch (type) {
            case CouponType.TYPE_FIXED:
                return t("securitization.CouponTypeFixedShort")
                break;
            case CouponType.TYPE_FLOAT:
                return t("securitization.CouponTypeFloatingShort")
                break;
            case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                return t("securitization.CouponTypeStrKeyrateAndPremiumShort")
                break;
            default:
                return null
        }
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }
    // console.log('columns', columns)
    // console.log('deals', deals)

    const addDeal = (id) => {
        setAnchorEl(null)
        // setDealList([...dealList, id])
    }

    const getTreeDataPath = (row) => row.hierarchy;

    const groupingColDef = {
        headerName: '',
        width: 20,
        ...cellParams,
        // renderHeader: ()=><Box sx={{borderRight:'none'}}>1</Box>,
        renderCell: (params) => { return <CustomGridTreeDataGroupingCell {...params} /> },
    };

    // console.log('deals', deals)
    const openOriginator = (id) => {
        if (dealAnchorEl == id)
            setDealAnchorEl(null)
        else
            setDealAnchorEl(id)
    }

    const openDeal = (id) => {
        if (nodeAnchorEl == id)
            setNodeAnchorEl(null)
        else
            setNodeAnchorEl(id)
    }

    const addToCompare = (poolId) => {
        setPoolCompareArray([...poolCompareArray, poolId])
    }


    const isGroupExpandedByDefault = (node: GridGroupNode) => {
        const expanded = localStorage.getItem(`Securitization/Comparation/RowCollapse_${node.groupingKey}`)

        return expanded == "true" ? false : true  // data recieved from localstorage is STRING
    }


    return (
        <>

            {/* <Box className={classes.alertLayout}>
                {
                    alerts.map(alert => <Alert key={`alert_${alert.id}`} id={alert.id} text={alert.message} color={alert.color} timeout={2000} />)
                }
            </Box> */}
            <Menu
                open={Boolean(anchorEl) && Boolean(originatorsList.length)}
                onClose={handleMenuClose}
                anchorEl={anchorEl}
                // position="bottol-left"
                autoFocus={false}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    // onMouseEnter: handleHover,
                    // onMouseLeave: handleCloseHover,
                    style: { pointerEvents: "auto", padding: 0, margin: 0 }
                }}
                PopoverClasses={{
                    root: classes.popOverRoot
                }}
            >
                {
                    originatorsList
                        ?.map(originator => {
                            return (
                                <MenuItem component="div" className={classes.menuItemContainer} key={`originator - compareMenu - ${originator.id} `}
                                    disableRipple>
                                    <Accordion
                                        classes={{ root: classes.menuAccordion, expanded: classes.dealSummaryExpanded }}
                                        expanded={dealAnchorEl === originator.id}
                                        onChange={() => openOriginator(originator?.id)}
                                    >
                                        <AccordionSummary
                                            classes={{ root: classes.menuAccordionSummary, content: classes.menuAccordionContent }}
                                            expandIcon={<GridExpandMoreIcon />} >
                                            {originator.shortName}
                                        </AccordionSummary>

                                        <AccordionDetails
                                            classes={{ root: classes.menuAccordionDetails }}
                                        >
                                            {/* {console.log('deals?.items', deals?.items)} */}
                                            {deals?.items?.filter(item =>
                                                item?.deal?.originatorID == originator.id
                                                && item?.deal?.poolNodes?.length > 0
                                                && item?.deal?.poolNodes?.some(node => node?.pools?.length > 0 && node?.pools[0].status == PoolProcessingStatus.STATUS_SUCCESS && !node?.pools?.some(pool => poolList?.includes(pool?.id)))
                                            ).map(data => {
                                                return <Accordion
                                                    key={`compareDeal_${data?.deal?.id} `}
                                                    classes={{ root: classes.menuAccordion, expanded: classes.nodeSummaryExpanded }}
                                                    expanded={nodeAnchorEl === data?.deal?.id}
                                                    onChange={() => openDeal(data?.deal?.id)}>
                                                    <AccordionSummary
                                                        classes={{ root: classes.menuAccordionSummary, content: classes.menuAccordionContent }}
                                                        expandIcon={<GridExpandMoreIcon />}>
                                                        {data.deal.name}
                                                    </AccordionSummary>
                                                    <AccordionDetails
                                                        classes={{ root: classes.menuAccordionDetails }}
                                                    >
                                                        {/* {console.log(data?.deal?.name, 'nodes', data?.deal?.poolNodes)} */}
                                                        {data?.deal?.poolNodes
                                                            ?.filter(node => node?.pools?.length > 0 && node?.pools[0].status == PoolProcessingStatus.STATUS_SUCCESS && !node.pools?.some(pool => poolList?.includes(pool?.id)))
                                                            ?.map(node => {
                                                                return <Stack
                                                                    key={`compareNode_${node.id} `}
                                                                    className={classes.menuItem}
                                                                    onClick={() => addToCompare(node?.pools[0].id)}
                                                                >
                                                                    <Box>{getNodeType({ name: 'type' }, node)} </Box>
                                                                    <Box className={classes.poolDate}>{Localization.FormatDateStringLocale(node?.pools[0]?.poolDate)}</Box>
                                                                    <Box className={classes.poolDebt}>{Localization.FormatNumber(node?.pools[0]?.currentMainDebt / 1000000000.0, 1, 1)} {t("Billions")}</Box>
                                                                    <Box className={classes.poolWAC}>{Localization.FormatNumber(node?.pools[0]?.currentWAC)} {t("percent")}</Box>
                                                                </Stack>
                                                            })}
                                                    </AccordionDetails>
                                                </Accordion>
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                </MenuItem>
                            )
                        })
                }
            </Menu>
            <DataGridPro
                autoPageSize={true}
                classes={{
                    root: classes.dataBlock,
                    virtualScroller: classes.scrollablePanel,
                    columnHeadersInner: classes.columnHeadersInner,
                    // pinnedColumnHeaders: classes.pinnedColumnHeaders,
                    // pinnedColumns: classes.pinnedColumns,
                }}
                // className={classes.dataBlock}
                // loading={rows?.length === 0}
                loading={loading && rows?.length == 0}
                slots={{
                    noRowsOverlay: NoRowOverlay,
                    noResultsOverlay: NoResultsOverlay,
                    loadingOverlay: LoadingOverlay,
                }}
                treeData={true}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
                // defaultGroupingExpansionDepth={-1}
                isGroupExpandedByDefault={isGroupExpandedByDefault}

                rows={rows}
                columns={columns}
                getRowClassName={(params) => params?.row?.frame}
                getRowSpacing={(params) => {/*if(params?.model?.frame == 'separator') console.log('params', params);*/ return { top: (params?.model?.frame == 'sub1' || params?.model?.frame == 'paramsData' || params?.model?.frame == 'separator') ? 0 : 8, bottom: 0 } }}
                rowSpacingType='margin'
                initialState={{
                    // pinnedColumns: {
                    //     left: ['name'],
                    //     right: ['unit']
                    // },
                    // pagination: {
                    //     paginationModel: {
                    //         pageSize: 5,
                    //     },
                    // },
                }}
                // pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick={true}
                disableColumnSelector
                disableColumnFilter
                disableColumnMenu
                showCellVerticalBorder={false}
                showColumnVerticalBorder={true}
                density='compact'
                columnHeaderHeight={75}
                // rowHeight={'45'}
                getRowHeight={(params) => {
                    switch (params.model.frame) {
                        case 'separator':
                            return 3
                            break;

                        default:
                            return 31.2
                            break;
                    }
                }}
                hideFooterPagination={true}
                hideFooter={true}
                scrollbarSize={4}
                // apiRef={apiRef}
                // pageSizeOptions={rows?.length ? [rows?.length] : [300]}
                paginationModel={{ page: 0, pageSize: rows?.length ? rows?.length : 300 }}
            />
            {/* } */}
        </>
    )
}

export default ComparePools