import React, { useEffect, useMemo, useState } from 'react'
import { Paper, Box, Typography, ClickAwayListener, Tooltip, IconButton, FormControlLabel, Checkbox, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Theme } from '@mui/material'
import { CopyAll, FileCopyOutlined, HelpOutline } from '@mui/icons-material'
import style from '@Styles/domrf-theme.module.scss'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { createStyles, makeStyles } from '@mui/styles'
import { CouponType, FlowTypes } from '@Base/components/Definitions'
import Localization from '@Base/components/Localization'
import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { useDispatch } from 'react-redux'
import { FLOW_TYPES } from '@babel/types'
import CustomTooltip, { getTooltip } from '@Base/components/shared/CustomTooltip'
import SpinnerBars from '@Base/components/shared/SpinnerBars'
import { copyTextToClipboard } from '@Base/Utilities'
import { actionCreators as actionAlert } from '@Base/store/AlertStore'
import { useNewDispatch, useNewSelector } from '@Base/index'
import { setConventionData } from '@Base/newStore/conventionSlice'

const useStyles = makeStyles((theme: Theme) => createStyles({
    headerCheckbox: {
        margin: 0,
        fontSize: '0.8rem !important',
        letterSpacing: '1.5px !important',
        lineHeight: '2rem',
    },
    coverLayout: {
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        zIndex: '+100',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',

    },
    customCellData: {
        padding: '0.1rem 21px',
        minWidth: '60px',
    },
    root: {
        position: 'absolute',
        // left: '0.2rem', top: 0, bottom: 0, right: '0.2rem',
        left: 0, top: 0, bottom: 0, right: 0,
        // maxHeight:'400px',
        // marginRight: '0.2rem',
        // marginLeft: '0.2rem',
        // marginTop: 0,
        // height: '400px',
        // position: 'relative',
        '& $panelHeader *': {
            fontSize: '1rem'
        },
        '& *': {
            fontSize: '1rem',
            userSelect: 'none',
        }
    },
    rootPaper: {
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'hidden',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 .5rem',
        justifyContent: 'space-between',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80
    },
    panelData: {
        margin: '0.5rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        wlexWrap: 'nopwrap',
    },
    knownTableRow: {
        '& td': {
            color: style.colorPrimary2_80
        }
    },
    historyTableRow: {
        '& td': {
            color: style.colorPrimary3_60
        }
    },
    columnTitle: {
        padding: '0.5rem 0',
        fontSize: '13px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'top',
    },
    columnTitleUnit: {
        fontSize: '0.9rem',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    }
}))

const FlowsPanel = () => {
    const cls = useStyles()
    // const {
    //     selectedBond = null,
    //     mbsCashflowTable: flows = null,
    //     showHistoryFlows = false,
    //     loading = false,
    //     ...otherStore } = useSelector((state: any) => state.convention)
    const [flows, setFlows] = useState(null)

    const {
        selectedBond = null,
        mbsCashflowTable /*: flows*/ = null,
        showHistoryFlows = false,
        loading = false,
        ...otherStore } = useNewSelector(state => state.newConvention)

    useEffect(() => {
        /* преобразхование из объекта в массив объектов */
        if (mbsCashflowTable != null) {
            const newFlows = new Array()
            Object.entries(mbsCashflowTable).forEach(([key, val], p_index) => {
                if (Array.isArray(val)) {
                    val.forEach((v, index) => {
                        newFlows[index] = { ...newFlows[index], [key]: v }
                    })
                }
            })
            setFlows(newFlows)
        }
    }, [mbsCashflowTable])

    const dispatch = useDispatch()
    const newDispatch = useNewDispatch()
    const columns = [
        { name: 'couponDate', title: 'Convention2_CouponDates', align: 'left' },
        { name: 'bondPrincipalStartPeriod', title: 'Convention2_BondPrincipalStartPeriod', align: 'right', fractSize: 2 },
        { name: 'bondAmortization', title: 'Convention2_BondAmortization', align: 'right', fractSize: 2 },
        { name: 'bondCouponPayments', title: 'Convention2_coupon2', align: 'right', fractSize: 2 },
        // { name: 'bondCouponPayments', title: selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM ? 'Convention2_coupon1' : 'Convention2_coupon2', align: 'right', fractSize: 2 },
        { name: 'bondCouponPaymentsPercents', title: 'Convention2_coupon2_percents', align: 'right', fractSize: 2 }
        // { name: 'bondCouponPaymentsPercents', title: selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM ? 'Convention2_coupon1_percents' : 'Convention2_coupon2_percents', align: 'right', fractSize: 2 }
    ]

    const getRowClass = (type) => {
        switch (type) {
            case FlowTypes.MODEL:
                return '';
            case FlowTypes.HISTORY:
                return cls.knownTableRow;
            case FlowTypes.USED_HISTORY:
                return cls.historyTableRow;

        }
        return {}
    }

    const handleHistoryCheckChange = () => {
        // actionConvention.setData({ showHistoryFlows: !showHistoryFlows })(dispatch)
        newDispatch(setConventionData({ showHistoryFlows: !showHistoryFlows }))
    }
    // console.log('otherStore', otherStore)
    let prevFlow

    const onSaveGraphFlowsButtonClick = (event) => {

        if (!selectedBond == null)
            return;

        var textToCopy = columns.map((item) => t(item.title)).join('\t') + '\r\n';
        textToCopy += flows
            ?.filter((row) => showHistoryFlows ? true : row?.cashflowType != FlowTypes.USED_HISTORY)
            .map(row => columns.map(col => valueGetter(row, col) + '\t').join(''))
            .join('\r\n')

        copyTextToClipboard(textToCopy)
        actionAlert.addAlert({ text: t('Convention2_CopyPlotAlert'), color: style.colorPrimary1 })(dispatch)
    }

    const valueGetter = (row = null, col = null) => {
        try {
            if (row == null || col == null)
                return

            const value = row[col.name];
            return typeof value === 'number' ?
                Localization.FormatNumberString(value, col?.fractSize, col?.fractSize)
                : (typeof value === 'string' ?
                    (Date(value) !== 'Invalid date' ? Localization.FormatDateLocale(new Date(value)) : value)
                    : value
                )
        } catch (error) {

        }
    }

    if (flows == null) return <></>

    return (
        <Box className={cls.root}>
            <Box className={cls.rootPaper}>
                {loading && <Box className={cls.coverLayout} />}
                <Box className={cls.panelHeader}>
                    <Box className={cls.panelHeaderTitle} >

                        <CustomTooltip
                            // hideIcon
                            sx={{ '& .MuiButtonBase-root': { marginLeft: '4px', }, '& svg': { color: style.colorPrimary3_40, width: '20px', height: '20px', } }}
                            right
                            content={`<div><b style="color: ${style.colorPrimary3_120} !important; font-size: 1.5rem; line-height: 0;margin-right: 0.5rem;">■</b>${t('Convention2_GridColorsInformation_Gray')}</div>\
                        <div style="padding-top: 0.5rem;"><b style="color: ${style.colorPrimary2} !important; font-size: 1.5rem; line-height: 0;margin-right: 0.5rem;">■</b>${t('Convention2_GridColorsInformation_Green')}</div>\
                        <div style="padding-top: 0.5rem;"><b style="color: ${style.colorPrimary1} !important; font-size: 1.5rem; line-height: 0;margin-right: 0.5rem;">■</b>${t('Convention2_GridColorsInformation_Black')}</div>\
                        ${getTooltip({ tooltip: 'Convention2_GridColorsInformation_Link' })}
                        `}
                        >
                            {/* <IconButton onClick={openTooltipClick}> */}
                            {/* <HelpOutline sx={{ width: 18, height: 18, }} /> */}
                            {/* </IconButton> */}
                            {
                                t('Convention2_BondsCasheFlowPanelTitle')
                            }

                        </CustomTooltip>
                    </Box>

                    <Box className={cls.panelIconsBox}>
                        {Boolean(flows?.some((row) => row?.cashflowType == FlowTypes.USED_HISTORY)) &&
                            <Box sx={{ '& *': { color: style.colorPrimary3 } }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id='showHistoryFlows'
                                            name='showHistoryFlows'
                                            size='small'
                                            checked={showHistoryFlows}// && !historyFlowsDisabled}
                                            onChange={handleHistoryCheckChange}
                                            sx={{ p: 0, lineHeight: '2rem', height: '2rem', width: '2rem', overflow: 'hidden' }}
                                        />
                                    }
                                    classes={{ label: cls.headerCheckbox }}
                                    sx={{ marginRight: '0.5rem' }}
                                    label={t('ShowHistory')}
                                />
                            </Box>
                        }
                        {/* кнопка Copy */}
                        <Tooltip
                            disableFocusListener
                            disableInteractive
                            //interactive
                            title={t('Convention2_CopyPlotResult')}>
                            <span> { /*A disabled element does not fire events. Tooltip needs to listen to the child element's events to display the title. */}
                                <IconButton size="small" disabled={selectedBond == null} onClick={onSaveGraphFlowsButtonClick}>
                                    <CopyAll className={cls.icon} />
                                </IconButton>
                            </span>
                        </Tooltip>

                        {/* <Box component='span' sx={{ pr: 1 }}>
                        <Tooltip
                            disableFocusListener
                            //interactive
                            title={t('CopyCashFlowResult')}>
                            <span> 
                                <IconButton sx={{ width: 30, height: 30 }} disabled={bondResults == null} onClick={onSaveFlowsButtonClick}>
                                    <FileCopyOutlined />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box> */}
                    </Box>
                </Box>
                {/* {toolbarRoot({}, state)} */}
                <TableContainer sx={{ m: 0, p: 0, flexGrow: 1, overflow: 'hidden auto' }} className={'modernScrollbar'}>
                    {/* height:'100%', */}
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                {columns?.map((column, index) => (
                                    <TableCell
                                        key={`flow-header-${index}`}
                                        className={cls.columnTitle}
                                        width={'20%'}
                                    >
                                        {t(column.title)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {console.log('flows', flows)} */}
                            {flows
                                ?.filter((row) => showHistoryFlows ? true : row?.cashflowType != FlowTypes.USED_HISTORY)
                                ?.map((row, index) => {
                                    let showDivider = false
                                    if (prevFlow && prevFlow != row.cashflowType && prevFlow === FlowTypes.USED_HISTORY) showDivider = true
                                    prevFlow = row.cashflowType
                                    return (
                                        <TableRow /*hover*/ role='checkbox' tabIndex={-1} key={`flow-row-${index}`}
                                            className={getRowClass(row.cashflowType)}
                                            sx={{ position: 'relative', '&:hover': { bgcolor: style.colorPrimary2_20 } }}
                                        >
                                            {columns.map((column, idx) => {
                                                const value = row[column.name];
                                                return (
                                                    <TableCell key={`flow-cell-${index}-${idx}`} align={column.align?.toString()}
                                                        className={cls.customCellData}
                                                    >
                                                        {showDivider ? <Box sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, borderTop: `dashed 1px ${style.colorPrimary3_40}` }}></Box> : <></>}

                                                        {valueGetter(row, column)}
                                                        {/* {typeof value === 'number' ?
                                                            Localization.FormatNumber(value, column?.fractSize, column?.fractSize)
                                                            : (typeof value === 'string' ?
                                                                (Date(value) !== 'Invalid date' ? Localization.FormatDateLocale(new Date(value)) : value)
                                                                : value
                                                            )
                                                        } */}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box >
    )
}

export default FlowsPanel