import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import AddBox from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';

import clsx from 'clsx';
import style from '@Styles/domrf-theme.module.scss';

import { debounce } from 'throttle-debounce';
import SearchPanelInput from '@Components/shared/SearchPanelInput';
import MailingEditorPage from './MailingEditorPage';

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from 'react-i18next'


import { createStyles, makeStyles } from '@mui/styles'
import { Paper, Stack, alpha, Box, Container, Tab, Tabs, Theme, Button, Typography, InputAdornment, Tooltip } from '@mui/material'
import ConfirmationForm from "@Components/shared/ConfirmationForm";

import { actionCreators as administrationStore } from "@Store/AdministrationStore";
import { actionCreators as actionTopNavbar } from '@Store/TopNavbarStore'

import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridActionsCellItem,
    GridColumnGroupingModel,
    GridComparatorFn,
    GridValueFormatterParams
} from '@mui/x-data-grid-premium';

import { ruRU, enUS } from '@mui/x-data-grid/locales';

import TextField from '@mui/material/TextField';


const useStyles = makeStyles((theme: Theme) => createStyles({

    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        flex: '1 1 auto',
        height: 0,
        width: '100%',
        minWidth: '100%'
    },
    searchField: {
        marginLeft: 'auto'
    },
}));

const MailingPage = () => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    //const [startPageIndex, setStartPageIndex] = useState(0); //CHECKME!!! add to user settings
    //const [pageSize, setPageSize] = useState(50); //CHECKME!!! add to user settings
    const [searchValue, setSearchValue] = useState('');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50
    });

    const [locale, setLocale] = useState(ruRU);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [editedRow, setEditedRow] = useState(null);

    const rowRef = useRef(null);

    const {
        data,
        createdGroups,
        updatedGroups,
        isLoading = false
    } = useSelector((state: any) => state.administration)

    useEffect(() => {

        actionTopNavbar.setTitle(t('MailGroups'))(dispatch)
        return () => {
            actionTopNavbar.setTitle("")(dispatch)
            searchDebounced.cancel();
        }
    }, [])

    useEffect(() => {
        searchDebounced(searchValue);
    }, [searchValue, paginationModel]);

    useEffect(() => {

        if (createdGroups == null || createdGroups.length == 0)
            return;

        var newItems = [...items, ...createdGroups];
        setItems(newItems);
        setCount(count + createdGroups.length);

    }, [createdGroups]);

    useEffect(() => {

        if (updatedGroups == null || updatedGroups.length == 0)
            return;

        var newItems = [...items];

        updatedGroups.forEach(x => {
            var index = newItems.findIndex(item => item.id == x.id);
            if (index == -1) {
                throw `Updated element with id = ${x.id} not found`;
            }

            newItems[index] = x;
        });

        
        setItems(newItems);

    }, [updatedGroups]);

    useEffect(() => {
        if (i18n.language == 'ru') {
            setLocale(ruRU);
        }
        else {
            setLocale(enUS);
        }

    }, [i18n]);

    useEffect(() => {

        setItems(data?.items ?? []);
        setCount(data?.count ?? 0);

    }, [data]);

    const handleEdit = (params: any) => {
        rowRef.current = params;
        setEditedRow({ ...params.row }); //copy of row

        setShowEditPopup(true);
    }

    const handleDelete = (params: any) => {
        rowRef.current = params;
        setShowConfirmDialog(true);
    }

    const applyRecordRemove = () => {
        setShowConfirmDialog(false);

        //CHECKME!!! add remove result and bind table update
        administrationStore.removeGroups([rowRef.current.id])(dispatch);

        setItems(items.filter(row => row.id != rowRef.current.id));
        setCount(count - 1);

        rowRef.current = null;
    }

    const declineRecordRemove = () => {
        setShowConfirmDialog(false);
        rowRef.current = null;
    }

    const handleRowChange = (name, value) => {

        setEditedRow({ ...editedRow, [name]: value });
    }

    const applyRowChanges = (row, updatedFields) => {

        if (row.id == null) {
            //add row
            administrationStore.createGroups([row])(dispatch);
        }
        else {
            //update row

            var updated = [];
            var item = { id: row.id };
            Object.entries(updatedFields).forEach(([key, value]) => {
                item[key] = { Value: value };
            });
            updated.push(item);

            administrationStore.updateGroups(updated)(dispatch);

            /*
            var index = items.findIndex(x => x.id == row.id);
            if (index != -1) {
                var current = items[index];

                Object.entries(updatedFields).forEach(([key, value]) => {
                    current[key] = value;
                });
            }
            */
        }

        setShowEditPopup(false);
        rowRef.current = null;
        setEditedRow(null);
    }

    const cancelRowChanges = (test) => {
        setShowEditPopup(false);
        rowRef.current = null;
        setEditedRow(null);
    }

    const addNewRecord = () => {
        var dummy = {};
        rowRef.current = dummy;
        setEditedRow(dummy);

        setShowEditPopup(true);
    }

    const columns: GridColDef[] = [

        {
            field: 'actions',
            type: 'actions',
            width: 60,
            getActions: (params) => [
                <GridActionsCellItem icon={
                    <EditIcon />
                } label={'Редактировать'} onClick={() => handleEdit(params)} />,
                <GridActionsCellItem icon={
                    <DeleteIcon />
                } label={'Удалить запись'} onClick={() => handleDelete(params)} />
            ],
            renderHeader: () => (
                <Tooltip
                    disableFocusListener
                    title={t('Add')}
                    aria-label={t('Add')}>
                    <IconButton onClick={addNewRecord}>
                        <AddBox />
                    </IconButton>
                </Tooltip>

            ),
        },

        { field: 'isActive', headerName: 'Активно', description: "Пользователь или группа участвуют в рассылке", type: 'boolean', width: 100 },
        { field: 'isExcludeFromList', headerName: "Исключить из рассылки", description: "Исключить пользователя из группы рассылки", type: 'boolean', width: 200 },
        { field: 'name', headerName: "Группа", description: "Название группы рассылки", width: 300 },
        { field: 'receiver', headerName: "Пользователь", description: "Получатель рассылки, зарегистрированный пользователь", width: 250 },
        { field: 'adNewslettersFilter', headerName: "Фильтр группы Active Directory", description: "Фильтр Active Directory, определяющий корпоративную группу рассылки", width: 390 },
        { field: 'outerUserEmail', headerName: "Внешний Email", description: "Если пользователья не зарегистрирован в системе, то его можно подключить к рассылке, указав в этом поле email", width: 250 },
        { field: 'monday', headerName: "Понедельник", description: "", type: 'boolean', width: 120 },
        { field: 'tuesday', headerName: "Вторник", description: "", type: 'boolean', width: 100 },
        { field: 'wednesday', headerName: "Среда", description: "", type: 'boolean', width: 100 },
        { field: 'thursday', headerName: "Четверг", description: "", type: 'boolean', width: 100 },
        { field: 'friday', headerName: "Пятница", description: "", type: 'boolean', width: 100 },
        { field: 'saturday', headerName: "Суббота", description: "", type: 'boolean', width: 100 },
        { field: 'sunday', headerName: "Воскресенье", description: "", type: 'boolean', width: 120 },
    ];

    const columnGrouping: GridColumnGroupingModel = [
        {
            groupId: 'receiver',
            headerClassName: 'bandHeaderCell',
            headerName: 'Получатель',
            children: [
                { field: 'outerUserEmail' },
                { field: 'receiver' },
                { field: 'adNewslettersFilter' }
            ]
        },
        {
            groupId: 'runs',
            headerName: 'Запуски',
            headerClassName: 'bandHeaderCell',
            children: [
                { field: 'monday' },
                { field: 'tuesday' },
                { field: 'wednesday' },
                { field: 'thursday' },
                { field: 'friday' },
                { field: 'saturday' },
                { field: 'sunday' }
            ]
        }
    ];

    const updateDataSource = (searchValue) => {

        var filterItem = null;

        if (searchValue != '') {

            if (paginationModel.page != 0) {
                setPaginationModel({ ...paginationModel, page: 0 });
                return;
            }

            filterItem = [{
                Operator: "or",
                Values: [
                    {
                        Name: "Name",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "Receiver",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "ADNewslettersFilter",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "OuterUserEmail",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    }
                ]
            }];
        }

        var filter = {
            Filters: filterItem,
            ItemIndex: paginationModel.page * paginationModel.pageSize,
            PageSize: paginationModel.pageSize
        };

        administrationStore.getMailingList(filter)(dispatch);
    }

    const searchDebounced = useCallback(debounce(500, updateDataSource), []);


    return (
        <Container className={classes.container}>
            {
                showEditPopup &&
                <MailingEditorPage
                    row={editedRow}
                    onChange={handleRowChange}
                    onApplyChanges={applyRowChanges}
                    onCancelChanges={cancelRowChanges}
                    open={showEditPopup}
                />
            }
            <ConfirmationForm
                isOpen={showConfirmDialog}
                header='Удаление'
                content='Вы хотите удалить запись?'
                onAccept={applyRecordRemove}
                onDecline={declineRecordRemove}
            />

            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingX: '1rem', paddingY: '0.5rem' }}>
                <SearchPanelInput className={classes.searchField} value={searchValue} onValueChange={setSearchValue} placeHolder={t('Search')} />
            </Stack>

            <DataGridPremium
                sx={{
                    ["& .bandHeaderCell .MuiDataGrid-columnHeaderTitleContainer"]: {
                        justifyContent: 'center'
                    }
                }}
                experimentalFeatures={{ columnGrouping: true }}
                disableColumnMenu
                loading={isLoading}
                columnHeaderHeight={30}
                rowHeight={25}
                rows={items}
                columns={columns}
                columnGroupingModel={columnGrouping}
                localeText={locale.components.MuiDataGrid.defaultProps.localeText}

                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                rowCount={count}
                paginationMode="server"
                pagination
                /*
                initialState={{
                    pagination: {
                        paginationModel: {
                            page: startPageIndex,
                            pageSize: pageSize
                        }
                    },
                }}
                */
                pageSizeOptions={[30, 50, 100]}
            />
        </Container >
    )
}

export default MailingPage;