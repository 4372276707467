import { Navigate } from "react-router-dom";
import * as React from "react";
import Globals from "@Base/Globals";
// import { Instruments } from '@Components/Instruments';

import AuthorizedLayout from '@Layouts/AuthorizedLayout';
import AnonymousLayout from "@Layouts/AnonymousLayout";

// const getRedirectUrl = () => {
//     if ((Globals.user.startupUrl || "") == "")
//         return null;

//     var arr = Globals.user.startupUrl.split('/');
//     var current = Instruments().find((element) => element.id == arr[0]);
//     if (Globals.hasPermissionAccess(current.permissionRequired)) {

//         current = current.actions.find((element) => element.id == arr[1])

//         if (current && current.url != null) {
//             return current.url;
//         }
//     }
// };


function AuthorizedRoute(props) {

    const {
        isUseAnonymous,
        path,
        history,
        component: Component,

        area,
        extraProps,
        permissionRequired,
        childElements,
        hideActions = false,
    } = props;
    if (path === "/")
        return <Navigate to="/instruments" />;

    if (isUseAnonymous) {
        return (
            <AnonymousLayout area={area} history={history} url={path} showHamburger={childElements > 1 && !hideActions}>
                <Component {...extraProps} />
            </AnonymousLayout>
        );
    }

    if (permissionRequired != null) {
        if (!Globals.hasPermissionAccess(permissionRequired)) {
            return <Navigate to="/accessDenied" />;
        }
    }

    return (
        <AuthorizedLayout area={area} history={history} url={path} showHamburger={childElements > 1 && !hideActions}>
            <Component {...extraProps} />
        </AuthorizedLayout>
    );
}

export default AuthorizedRoute;