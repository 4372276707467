import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import { ValidatorForm } from "@Framework/components/Form";
import style from '@Styles/domrf-theme.module.scss';
import { actionCreators } from '@Store/SecuritizationStore';
import BondPlacementPanel from '../panels/BondPlacementPanel';
import { debounce } from 'throttle-debounce';
import { alpha, Box, Paper, Tooltip } from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import ConfirmationForm from '@Base/components/shared/ConfirmationForm';
import { eDealStatus, ePoolNodeType, efDealValidationState } from '@Base/components/Definitions';
import CreateBondForm from '../components/CreateBondForm';
import { LoadingButton } from '@mui/lab';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import { actionCreators as actionAlert } from '@Base/store/AlertStore'
import Alert from '../components/Alert';

const useStyles = makeStyles((theme: Theme) => createStyles({
    removeButton: {
        width: '36px',
        marginRight: '0.5rem',
        minWidth: '36px',
        color: alpha(style.colorError, 0.5),
        '&:hover': {
            color: alpha(style.colorError, 1),
        }
    },
    Button: {
        fontSize: '1.2rem',
    },
    disabledButton: {
        backgroundColor: `${alpha(style.colorPrimary3_120, 0.1)} !important`,
        boxShadow: `0px 0px 0px 1px ${style.colorPrimary3_120} !important`,
        color: `${style.colorPrimary3_120} !important`,
        fontWeight: '200 !important',
        fontFamily: 'DINPro',
    },
    rootContainer: {
        position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '472px',
        margin: '0 auto',
    },
    controlsContainer: {
        flexShrink: 1,
        padding: '0.5rem',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        '&::before': {
            content: '""',
            position: 'absolute',
            left: '0.5rem', right: '0.5rem', top: 0, height: '1px',
            backgroundColor: `${style.colorPrimary3_40}`,
        }
    },
    dataContainer: {
        position: 'relative',
        flexGrow: 1
    },
    error: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0, right: 0, bottom: '6px', top: '6px',
            backgroundColor: '#FF000012',
        }
    },
    edited: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0, right: 0, bottom: '6px', top: '6px',
            backgroundColor: alpha(style.colorPrimary2, 0.1)
        }
    },
    errorMessage: {
        margin: '0 0.5rem',
        color: style.colorError,
    },
    globalRoot: {
        '& *': {
            userSelect: 'none',
        },
    },
    tabHeaderButton: {
        padding: '2px 8px'
    },
    paramsTab: {
        // height: '100%',

        '&>*': {
            color: style.colorPrimary3_120,
            padding: '0px 10px 0px 10px',
            fontSize: '1rem',
            letterSpacing: 'initial',
            // lineHeight: '1.2rem',
            // textTransform: 'uppercase',

            '&:hover': {
                color: style.colorPrimary3
            },
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 6, bottom: 6, right: 0,
            width: '2px',
            borderRight: `solid 1px ${style.colorPrimary3_120}`,
        }
        // 
        // cursor: 'pointer'
    },
    activeTab: {
        '& *': {
            color: style.colorPrimary1,
            fontWeight: 'bolder'
        }
    },
    scrollablePanel: {
        position: 'absolute', left: '8px', right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {

            width: '4px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    dealsContainer: {
        display: 'flex',
        minHeight: '200px',
        padding: '15px',
        background: `repeating-linear-gradient(45deg, ${style.colorPrimary3_5}, ${style.colorPrimary3_5} 10px, white 10px, white 20px)`
    },
    deals: {
        display: 'flex',
        overflowX: 'auto',
        width: '100%',
        //paddingTop: '15px',
        //paddingBottom: '15px',
        marginRight: '15px'
    },
    dealCard: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '280px',
        marginRight: '15px'
    },
    addDealButton: {
        alignSelf: 'center'
        //height: '200px',
        //width: '200px'
    },
    itemActionArea: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    itemContent: {
        display: 'flex',
        flex: '1 1 auto',
        height: '100%'
    },
    buttonContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardSelect: {
        border: '1px solid ' + style.colorPrimary1
    },
    layoutGrid: {
        '& .react-grid-item': {
            padding: '0.2rem'
        }
    },
    infoPanel: {
        display: 'flex',
        justifyContent: 'end',
    },
    panel: {
        //overflow: 'auto'
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    hideButton: {
        position: 'absolute',
        cursor: 'pointer',
        fontSize: '20px',
        top: '0',
        right: '5px',
        zIndex: '1000'
    },
    hidden: {
        display: 'none'
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: '1rem',
        flexGrow: 1,
    },
    tabContainerNoData: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1rem',
        flexGrow: 1,
    },
    labelColor: {
        color: style.colorPrimary3_120,
        userSelect: 'none',
    },
    colContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    paper: {
        padding: '0.5rem 0',
        flex: '1 1 auto'
    },
    fullHeightPaper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0.5rem 0',
        flex: '1 1 auto'
    },
    parametersPanel: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up('lg')]: {
            height: '100%'
        },
        [theme.breakpoints.down('lg')]: {
            height: '400px'
        }
    },
    header: {
        marginLeft: '0.5rem',
        height: '30px', minHeight: '30px', maxHeight: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflow: 'hidden',
    },
    headerButtonsBlock: {
        marginLeft: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    headerTabsBlock: {
        display: 'flex',
        flexDirection: 'row',
    }
}));

const DealReleaseParametersTab = ({
    deal,
    iteration,
    // originators,
    // products,
    // onEntitiesUpdated,
    editPermission
}) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [bondID, setBondID] = useState(deal?.deal?.bondID)
    const updatedFields = useRef({ deal: {}/*, iteration: {}*/ });
    const { isIterationError, isDealError, updatedDeal, permissions, isPoolTransferProcessing = false, ...otherStore } = useSelector((state: any) => state.securitization)
    // console.log('deal?.deal', deal?.deal)
    const { validationState: dealValidationState = null } = (deal?.deal || {});
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [releaseParametersIsValid, setReleaseParametersIsValid] = useState(false)
    const [showBondCreatingDialog, setShowBondCreatingDialog] = useState(false)

    useLayoutEffect(() => {
        if (bondID == null && deal?.deal?.bondID != null) {
            setBondID(deal?.deal?.bondID)
            actionAlert.addAlert({ text: t('securitization.bondWasCreated'), color: style.colorPrimary1, timeout: 6000 })(dispatch)
            // actionAlert.addAlert(t('securitization.bondWasCreated'), style.colorPrimary1, 6000)(dispatch)
        }
    }, [deal?.deal?.bondID, deal?.deal?.status])

    useLayoutEffect(() => {
        setReleaseParametersIsValid(false)

        //clear fields
        updatedFields.current = { deal: {} /*, iteration: {}*/ };
    }, [deal])

    const removeDeal = () => {
        if (deal?.deal?.id)
            actionSecuritization.archiveDeal(deal?.deal?.id)(dispatch)
        setShowRemoveConfirmation(false)
    }
    // console.log('releaseParametersIsValid', releaseParametersIsValid)

    const readyForRelease = (parametersIsValid) => {
        //  console.log('parametersIsValid', parametersIsValid)
        if (parametersIsValid)
            setReleaseParametersIsValid(true)
        else
            setReleaseParametersIsValid(false)
    }

    const showBondCreatingDialogEvent = () => {
        setShowBondCreatingDialog(true)
    }

    const transferPoolToPortfolio = () => {
        actionSecuritization.convertDealRIPToProtfolio(deal?.deal?.id)(dispatch)
    }

    // console.log('deal', deal?.deal )
    //     console.log(' !releaseParametersIsValid || Boolean(dealValidationState | efDealValidationState.STATE_VALID) || deal?.deal?.bondID != null',
    //         !releaseParametersIsValid,
    //         Boolean(dealValidationState | efDealValidationState.STATE_VALID),

    //         deal?.deal?.bondID != null)
    // {console.log('------------------------------------------------------', deal?.deal?.poolNodes?.some(x=>x.type == ePoolNodeType.TYPE_POOL_AT_REGISTRATION_DATE))}

    return (
        deal != null ? (
            <>
                <CreateBondForm
                    isOpen={showBondCreatingDialog}
                    dealId={deal?.deal?.id}
                    onClose={() => setShowBondCreatingDialog(false)}
                />
                <ConfirmationForm
                    header={t("RemoveDeal")}
                    content={t("AreYouSureToRemoveDeal")}
                    onAccept={removeDeal}
                    onDecline={() => setShowRemoveConfirmation(false)}
                    acceptButton={t("Remove")}
                    declineButton={t("Cancel")}
                    isOpen={showRemoveConfirmation}
                />
                <Box className={classes.rootContainer}>
                    <Box className={classes.dataContainer}>
                        <Box className={classes.scrollablePanel} >
                            <BondPlacementPanel
                                // data={panelsData}
                                isEdit={editPermission}
                                readyForRelease={readyForRelease}
                            // onFieldUpdate={onUpdateField}
                            //onFieldLeft={saveEdit}
                            />
                        </Box>
                    </Box>
                    {(permissions?.isAdmin) &&
                        <Box className={classes.controlsContainer}>
                            <Button
                                // disabled={deal?.deal?.status == eDealStatus.STATUS_FINISHED}
                                variant="outlined"
                                // fullWidth
                                color="error"
                                onClick={() => setShowRemoveConfirmation(true)}
                                classes={{ root: classes.removeButton, disabled: classes.disabledButton }}
                            >
                                <DeleteOutlineOutlined />
                            </Button>
                            {console.log('--------------releaseParametersIsValid',
                                !releaseParametersIsValid,
                                dealValidationState,
                                Boolean(dealValidationState | efDealValidationState.STATE_VALID),
                                deal?.deal?.bondID != null,
                                !deal?.deal?.poolNodes?.some(x => x.type == ePoolNodeType.TYPE_POOL_AT_REGISTRATION_DATE))}
                            <Button
                                sx={{ marginRight: '0.5rem' }}
                                disabled={
                                    !releaseParametersIsValid
                                    || Boolean(dealValidationState | efDealValidationState.STATE_VALID)
                                    // || deal?.deal?.status != eDealStatus.STATUS_NORMAL
                                    || deal?.deal?.bondID != null
                                    || !deal?.deal?.poolNodes?.some(x=>x.type == ePoolNodeType.TYPE_POOL_AT_REGISTRATION_DATE) //проверка на наличие РИПа
                                }
                                variant="contained"
                                fullWidth
                                color='secondary'
                                onClick={showBondCreatingDialogEvent}
                                classes={{ /*root: classes.Button,*/ disabled: classes.disabledButton }}
                            >
                                {deal?.deal?.bondID ? t("BondAlreadyCreated") : t("releaseDealButton")}
                            </Button>
                            <Tooltip title={!!deal?.isPortfolioCreating ? t("TransferPoolToProtfolioIsInProgress") : t("TransferPoolToProtfolio")}>
                                <LoadingButton
                                    disabled={deal?.deal?.bondID == null || !!deal?.isPortfolioCreating}
                                    loading={isPoolTransferProcessing}
                                    variant="outlined"
                                    //fullWidth
                                    color='primary'
                                    onClick={transferPoolToPortfolio}
                                    classes={{ /*root: classes.Button,*/ disabled: classes.disabledButton }}
                                >
                                    <ShortcutIcon />
                                </LoadingButton>
                            </Tooltip>
                        </Box>
                    }
                </Box >
            </>
        ) : (
            <div >
                <h4>{t('SelectDeal')}</h4>
            </div >
        )
    )
}

export default DealReleaseParametersTab