import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux"
import Globals from "@Base/Globals"
import { makeStyles } from '@mui/styles'
import { useTranslation } from "react-i18next"
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_exporting_data from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official'

import style from '@Styles/domrf-theme.module.scss'
import Localization from '@Components/Localization'


import { actionCreators, reducer } from '@Store/SecuritizationStore';
import { alpha } from '@mui/system'
import { setHighchartsLocalization } from '../../../../Utilities'
import { Box, debounce, IconButton, Slider, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import SpinnerBars from '../../../../components/shared/SpinnerBars'
import CustomTooltip from '../../../../components/shared/CustomTooltip'
import { format, isValid, parseISO } from 'date-fns'
import { i18nLocales } from '../../../../components/Localization'
import { ru, enUS } from 'date-fns/locale'
import { SaveOutlined } from '@mui/icons-material'

const useStyles = makeStyles({
    saveButton: {
        position: 'absolute',
        top: '5px',
        right: '60px',
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.2rem',
        color: style.colorPrimary3_40
    },
    markActive: {
        boxShadow: `0px 0px 0px 8px ${alpha(style.colorPrimary2, 0.5)} !important`
    },
    mark: {
        backgroundColor: 'transparent',
    },
    sliderRoot: {
        width: '240px',
        minWidth: '240px',
        maxWidth: '240px',
        paddingTop: 0,
        paddingBottom: 0,
        zIndex: +1,

    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },

    controlsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        //alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: '60px',
        top: '7px',
    },

    controlBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'max-content',
        flexWrap: 'nowrap',
        // width: '30%'
    },
    spinnerContainer: {
        position: 'relative',
        width: '100%',
        height: '100%'
    }
});


const MaturityTab = ({ poolId }) => {

    const classes = useStyles()
    const chart = useRef()
    const xFormat = useRef(null)
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const { chartsData, deal = null, selectedNode = null, ...otherStore } = useSelector((state: any) => state.securitization)
    const { nodeWithChangesLoad = false, MaturityRateLoad = false } = useSelector((state: any) => state.loader);
    const [chartData, setChartData] = useState(null)
    const [chartLoading, setChartLoading] = useState(false);
    const [step, setStep] = useState(null)
    const [steps, setSteps] = useState([1, 3, 12])
    const [lods, setLods] = useState([])
    const [lod, setLod] = useState(null);
    const [binCount, setBinCount] = useState(0)
    // console.log('lod', lod)

    const onSaveChartAsImageButtonClick = (event) => {
        if (chart.current)
            chart.current.chart.exportChart({ width: 3840, height: 1920 });
    }

    const chartTitle = `${deal?.deal?.name}, ${t("MaturityTab")}, пул от ${selectedNode?.node?.pools?.length ? Localization.FormatDateStringLocale(selectedNode.node.pools[0]?.poolDate) : ''}`

    const [chartOptions, setChartOptions] = useState({
        sensitivityChartOptions: {
            customData: () => this,
        },
        poolChartOptions: {
            customData: () => { return { step: step, lod: lod } },
        },
        exporting: {
            enabled: false,
            filename: chartTitle + '.jpg',
            sourceWidth: 1280,
            sourceHeight: 520,
            chartOptions: {
                title: {
                    text: chartTitle,
                    // align: 'center',
                    style: { display: "block", "color": "#2F444E", "fontSize": "20px", "fontWeight": "bold", 'float': 'left' },
                },
                chart: {
                    // style: { "fontSize": "5px" },
                    marginBottom: 65,
                    // events: {
                    // load: function () {
                    //     var renderer = this.renderer;
                    //     console.log('this', this, renderer)
                    //         renderer.path(['M', 30, 385, 'L', 570, 385, 'Z']).attr({
                    //             stroke: 'black',
                    //             'stroke-width': 1
                    //         }).add();

                    //         renderer.text('Some text...', 30, 400).add();
                    // }
                    // }
                },
                xAxis: {
                    labels: { style: { "color": "#2F444E", "fontSize": "18px", } },
                    title: { style: { "color": "#2F444E", "fontSize": "20px", } }
                },
                yAxis: [{
                    labels: { style: { "color": "#2F444E", "fontSize": "18px", } },
                    title: { style: { "color": "#2F444E", "fontSize": "20px", } }
                },
                {
                    labels: { style: { "color": "#2F444E", "fontSize": "18px", } },
                    title: { style: { "color": "#2F444E", "fontSize": "20px", } }
                }],
                legend: {
                    verticalAlign: 'bottom',
                    align: 'center',
                    y: 20,
                    x: 0,
                    itemDistance: 40,
                    itemStyle: {
                        fontSize: '18px',
                        overflow: 'hidden',
                    },
                },
            }
        },
        chart: {
            animation: false,
            // marginBottom:25,
            // height: 300,
            marginTop: 40,
            // marginBottom: 20,
            marginLeft: 60,
            marginRight: 65,
            //events: {
                //load: () => setChartLoading(false)
            //}
        },
        plotOptions: {
            series: {
                /*
                dataLabels: {
                    enabled: true,
                    color: '#2F444E',
                    format: '{y:.3f}'
                },
                */
                pointPadding: 0,
                groupPadding: 0.1,

            },

        },

        title: {
            text: t("MaturityRate"),
            style: { display: "none", "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold", 'float': 'left' },
            align: 'left',
            verticalAlign: 'top',
            x: 50,
        },

        xAxis: {
            crosshair: {
                color: alpha(style.colorPrimary2, 0.1)
            },
            categories: null,
            labels: {
                enabled: true,
                formatter: null,
                rotation: 0,
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none'
                }
            },

            title: {
                enabled: false
            },

            gridLineWidth: 0,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: style.colorPrimary1,
            tickmarkPlacement: 'between',

            plotLines: [
                // {
                //     color: style.colorPrimary2,
                //     dashStyle: 'dash',
                //     width: 2,
                //     value: -0.2,
                //     zIndex: 2,
                //     label: {
                //         rotation: 0,
                //         textAlign: 'right',
                //         x: -5,
                //         y: 15,
                //         style: {
                //             color: style.colorPrimary1,
                //             fontWeight: 'bolder',
                //             fontSize: '0.9rem'
                //         },
                //     }
                // }
            ]
        },

        yAxis: [{
            labels: {
                enabled: true,
            },
            stackLabels: {
                enabled: true
            },
            title: {
                text: t("CurrentDebtShare"),
                // margin: 4,
                style: { color: style.colorPrimary3_40 }
            },
            tickPositions: null,
            gridLineWidth: 1,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: style.colorPrimary1,
            gridLineColor: style.colorPrimary3_5,
        }, {
            labels: {
                enabled: true,
            },
            stackLabels: {
                enabled: true
            },
            title: {
                text: t("WACPercents"),
                style: { color: style.colorPrimary3_40 }
            },
            opposite: true,
            tickPositions: null,

            gridLineWidth: 1,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: style.colorPrimary1,
            gridLineColor: style.colorPrimary3_5,
            plotLines: [
                {
                    color: style.colorPrimary2,
                    dashStyle: 'dash',
                    width: 2,
                    value: -0.2,
                    zIndex: 2,
                    label: {
                        rotation: 0,
                    }
                }]
        }],

        legend: {
            floating: true,
            enabled: true,
            // layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -75,
            itemMarginTop: -4,
            itemMarginBottom: 0
        },

        tooltip: {
            shape: "square",
            positioner: function (labelWidth, labelHeight, point) {
                return {
                    // x: this.chart.plotWidth - labelWidth - 15 + this.chart.marginRight,
                    // y: 45
                    x: this.chart.plotLeft + 10,
                    y: this.chart.plotTop + 10
                };
            },
            formatter: function () {
                if (this.points != null) {
                    let locale = Localization.code == "ru" ? ru : enUS
                    let date = format(new Date(this.x), xFormat.current, { locale: locale })
                    //console.log('this.point', this.points[0].point)
                    return '<table style="min-width:180px;max-width:180px"><tr><td>' + t("MaturityPeriod") + ':</td>' +
                        '<td style="text-align:end"><b>' + (this.points[0].point.x == 0 ? '<' : '') + date + '</b></td>' +
                        '</tr>' +
                        this.points.reduce(function (s, point) {
                            return s + '<tr><td>' + point.series.name + ': </td>' +
                                '<td  style="text-align:end">' + Localization.FormatNumber(point.y, 1, 2) + '</td></tr>'
                        }, '') +
                        '</td > ' +
                        '</tr>' +
                        '</table>'
                }
            },
            // headerFormat: '<table>'
            //     + '<tr><td>' + t("MaturityRate") + ':</td>' +
            //     '<td style="text-align:end"><b>{point.key:.2f}</b></td>' +
            //     '</tr>',
            // pointFormat: '<tr><td style="padding:0">{series.name}: </td>' +
            //     '<td style="padding-left:0.5rem;text-align:end"><b>{point.y: .1f}</b></td></tr>',
            // footerFormat: '</table>',
            shared: true,
            useHTML: true,
            hideDelay: 20
        },

        credits: {
            enabled: false
        },

        series: [{
            type: 'column',
            name: t('CurrentDebt_short'),
            color: alpha(style.colorPrimary3, 0.1),
            borderColor: alpha(style.colorPrimary3, 0.5),
            yAxis: 0,
            data: null,
            animation: false,
            column: {
                zones: {
                    fillColor: style.colorPrimary2_20
                }
            },
            visible: true,
        }, {
            type: 'line',
            name: t('WACPercents_short'),
            color: style.colorPrimary1,
            yAxis: 1,
            data: null,
            marker: {
                fillColor: '#FFFFFF',
                lineWidth: 2,
                lineColor: null
            },
            animation: false,
            visible: true,
        }]
    })

    HC_exporting(Highcharts)
    HC_exporting_data(Highcharts)
    setHighchartsLocalization(Highcharts, t)

    useEffect(() => {
        setStep(steps[1])
        setChartData(null)
        if (window) {
            window.addEventListener('resize', debounce(onResize, 300))
            onResize();
        }

        return () => {
            window.removeEventListener('resize', debounce(onResize, 300))
        }
    }, [])

    useLayoutEffect(() => {
        xFormat.current = step == 12 ? 'yyyy' : step == 3 ? 'qкв.yy' : 'LLL yyyy'
        setBinCount(step == 12 ? 10 : step == 3 ? 20 : 24)
        actionCreators.getPoolData(poolId, 'MaturityRate', step)(dispatch)
        //setChartLoading(true)
    }, [step, poolId])

    useEffect(() => {
        if (chartsData != null && chartsData.poolID == poolId) {
            // console.log('chartsData', chartsData?.data[0]?.x1)
            let arr = chartsData?.data[0]?.x1
            const cnt = arr?.length
            // console.log('cnt - binCount ', cnt, binCount )

            if (cnt != null) {
                setLods(
                    arr?.slice(0, cnt - binCount >= 0 ? cnt - binCount + 1 : 0)
                        .map((x, index) => {
                            return {
                                value: cnt - binCount - index
                            }
                        }).reverse()
                        .slice(0) //убираем ноль
                )
            }

            setChartData(chartsData);
        }
        // else {
        //     setChartData(null)
        //     setChartLoading(false)
        // }
    }, [chartsData]);

    useLayoutEffect(() => {
        if (lods?.length)
            setLod(0) //lods.length - 1)
        //  console.log('lods', lods)
    }, [lods])


    useEffect(() => {
        if (chart.current != null)
            chart.current.chart.reflow();
    }, [chartOptions])

    useEffect(() => {
        //if (operationLoading == false) {
        setChartLoading(nodeWithChangesLoad || MaturityRateLoad);
        //}
    }, [nodeWithChangesLoad || MaturityRateLoad])

    useLayoutEffect(() => {
        updateChart()
    }, [chartData])

    useLayoutEffect(() => {
        // console.log('lod', lod)
        updateChart()
    }, [lod])

    const updateChart = () => {
        // console.log('chartData*', chartData)
        if (chartData == null || chartData?.chartName?.toLowerCase() != "maturityrate" || lod < 0 || chartsData.poolID != poolId) {
            // setChartLoading(false)
            return;
        }

        // console.log('lod**', lod)
        const lineStyle = {
            color: style.colorPrimary3_5,
            dashStyle: 'solid',
            width: 1,
            zIndex: 2,
            label: {
                rotation: 0
            }
        }
        //make chart
        var opts = { ...chartOptions };

        var data = { ...chartData.data[0], charts : [...chartData.data[0].charts] }

        // console.log('chartData.data', chartData.data)
        let sliceSize = lods[lod]?.value //длина среза слева

        // let currentDebt = 0;
        // console.log('data.charts[0]', data.charts[0])
        if (sliceSize > 0 && data?.charts[0]?.length > sliceSize + 1 && data?.charts[0]?.length == data?.charts[1]?.length) {
            // currentDebt = data?.charts[0]?.reduce((x, s) => { return x != null ? x + s : s }, 0)
            const sliceDebt = data?.charts[0]?.slice(0, sliceSize + 1)?.reduce((x, s) => { return x != null ? x + s : s }, 0)
            let sliceWAC = data?.charts[1]?.slice(0, sliceSize + 1)?.map((x, index) => {
                if (x == null || data?.charts[0][index] == null) return null
                return x * data.charts[0][index]
            }).reduce((x, s) => { return x != null ? x + s : s }, 0) / sliceDebt

            data.charts[0] = [sliceDebt, ...data?.charts[0]?.slice(sliceSize + 1)]
            data.charts[1] = [sliceWAC, ...data?.charts[1]?.slice(sliceSize + 1)]
            data.x1 = data?.x1?.slice(sliceSize)
            // console.log('controlTotalDebt', data?.charts[0]?.reduce((x, s) => { return x != null ? x + s : s }, 0))
        }
        // console.log('data.charts[0]', data.charts[0])
        opts.xAxis.plotLines = Array()
        let locale = Localization.code == "ru" ? ru : enUS
        opts.xAxis.categories = data.x1?.map((x, index) => {
            if (x == '') return ""
            let date = parseISO(x)
            switch (step) {
                /*draw x ticks START*/
                case 12:
                    if (index != 0) opts.xAxis.plotLines.push({ ...lineStyle, value: index - 0.5 })
                    break
                case 3:
                    if (format(date, 'Q') == '1' && (index != 0)) opts.xAxis.plotLines.push({ ...lineStyle, value: index - 0.5 })
                    break
                default:
                    if (format(date, 'M') == '1' && (index != 0)) opts.xAxis.plotLines.push({ ...lineStyle, value: index - 0.5 })
                    break
                /*draw x ticks END*/
            }
            return date
        })
        // if (sliceSize > 0) opts.xAxis.categories[0] = `<${opts.xAxis.categories[0]}`
        let firstVal = false
        opts.xAxis.labels.formatter = function () {
            // console.log('this.value', this.value)
            let date = new Date(this.value)
            let d = ''
            let xAxisSize = opts.xAxis.categories?.length
            let leftSlice = (xAxisSize > 100 ? 9 : (xAxisSize > 60 ? 6 : (xAxisSize > 30 ? 3 : 2)))

            if (!isValid(date))
                return ''
            switch (step) {
                /*draw x ticks START*/
                case 12:
                    // if (this.pos > leftSlice)
                        d = format(date, xFormat.current, { locale: locale })
                    break
                case 3:
                    if (format(date, 'Q') == '1' && this.pos > leftSlice)
                        d = format(date, xFormat.current, { locale: locale })
                    break
                default:
                    if (format(date, 'M') == '1' && this.pos > leftSlice)
                        d = format(date, xFormat.current, { locale: locale })
                    break
                /*draw x ticks END*/
            }
            // if (!firstVal && d != '') {
            //     d = `< ${d}`
            //     firstVal = true
            // }
            // console.log('lod', lod)
            // console.log('d', d, xFormat.current, locale)
            if (this.pos == 0) {
                d = `${lod != 0 && lod != null ? '<' : ''}${format(date, xFormat.current, { locale: locale })}`
            }
            return d
        }
        // console.log('opts.xAxis.categories', opts.xAxis.categories)
        // console.log('lod+5', lod / 5)
        // opts.xAxis.labels.rotation = step == 1 ? 90 : 0

        let y2format10 = false, y2format100 = false
        data.y2.some(y => {
            if (y * 10 % 1) {
                //сотые
                y2format100 = true
                return true
            }

            if (!y2format10 && y % 1) {
                //десятые
                y2format10 = true
            }
        }
        )
        opts.yAxis[1].labels.format = y2format100 ? '{value:.2f}' : (y2format10 ? '{value:.1f}' : '{value:.0f}')

        let y1format10 = false, y1format100 = false
        data.y1.some(y => {
            if (y * 10 % 1) {
                //сотые
                y1format100 = true
                return true
            }

            if (!y1format10 && y % 1) {
                //десятые
                y1format10 = true
            }
        }
        )
        opts.yAxis[0].labels.format = y1format100 ? '{value:.2f}' : (y1format10 ? '{value:.1f}' : '{value:.0f}')

        opts.yAxis[0].tickPositions = data.y1
        opts.yAxis[1].tickPositions = data.y2

        opts.series[0].data = data.charts[0]
        opts.series[1].data = data.charts[1].map((item, index) => [data.x1[index], item])
        // if (step == steps[0]) opts.series[1].data = []
        opts.series[1].visible = (step == steps[0] ? false : true)

        // console.log(opts.series[1])

        // opts.yAxis[1].plotLines[0].value = chartData.currentWAC
        // opts.yAxis[1].plotLines[0].label.text = `${t('WAC')}: ${Localization.FormatNumber(chartData.currentWAC)}`

        // opts.xAxis.plotLines[0].value = chartData.pti / step - 0.5
        // console.log('chartData.CurrentLTV', chartData.CurrentLTV, step)
        // opts.xAxis.plotLines[0].label.text = `${t('CurrentPTI_short')}: ${Localization.FormatNumber(chartData.pti, 1, 1)}`


        setChartOptions(opts)
        // setChartLoading(false)
    }

    const onResize = () => {
        if (chart.current != null) {
            chart.current.chart.reflow();
            // console.log('resize')
        }
    }

    const handleStep = (event: React.MouseEvent<HTMLElement>, newValue: number | null) => {
        if (newValue != null)
            setStep(newValue)
    }

    const handleLODTrack = (event: Event, value: number | Array<number>, activeThumb: number) => {
        // console.log('value', value)
        setLod(value);
    }
    // костыль
    // if ( chartLoading && !(!chartData || chartData?.chartName?.toLowerCase() != "maturityrate" || chartOptions?.series == null || chartOptions?.series?.length == 0))
    //     setChartLoading(false)

    return (
        <Stack sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <Stack sx={{ flex: 1, overflow: 'hidden' }}>
                {chartLoading ? <SpinnerBars withBackground /> : <></>}
                {(chartLoading || !chartData || chartData?.chartName.toLowerCase() != "maturityrate" || chartOptions?.series == null || chartOptions.series?.length == 0) ?
                    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', color: style.colorPrimary3_60 }}>
                        {/* {(chartLoading == false && ((chartData == null && chartOptions?.series?.data == null) || chartData?.chartName.toLowerCase() == "maturityrate")) && <Box className={classes.info}>{t("DataLoadingFail")}</Box>} */}
                    </Box> :
                    <>

                        <HighchartsReact
                            ref={chart}
                            highcharts={Highcharts}
                            options={chartOptions}
                            containerProps={{ style: { height: "100%", width: "100%" } }}
                            callback={() => {
                                setTimeout(() => {
                                    onResize()
                                }, 1)
                            }
                            }
                        />
                        <Tooltip title={t("SaveAsAttachment")} disableInteractive>
                            <IconButton size="small" className={classes.saveButton} disabled={false} onClick={onSaveChartAsImageButtonClick}>
                                <SaveOutlined />
                            </IconButton>
                        </Tooltip>
                        <Stack className={classes.controlsContainer} sx={{ mb: 1 }}>
                            <Box className={classes.controlBox}>
                                {/* <CustomTooltip id={`chart_step_${poolId}`} hideIcon sx={{ mr: 1 }} content={'?????'}>
                                    {t('Step')}
                                </CustomTooltip> */}
                                <ToggleButtonGroup
                                    value={step}
                                    exclusive
                                    onChange={handleStep}
                                    aria-label="step"
                                >
                                    {
                                        steps?.map(x => {
                                            return (
                                                <ToggleButton
                                                    size='small'
                                                    sx={{ pt: 0, pb: 0 }}
                                                    key={`step_${x}`} value={x}>
                                                    {Localization.FormatNumber(x, 0, 0)}
                                                </ToggleButton>
                                            )
                                        })
                                    }
                                </ToggleButtonGroup>
                            </Box>
                            {lods?.length > 1 &&
                                <Box className={classes.controlBox} sx={{ ml: 2 }}>
                                    {/* <CustomTooltip id={`chart_detailing_${poolId}`} hideIcon sx={{ mr: 2 }} content={t('LOD_Info')}>
                            {t('LOD')}
                        </CustomTooltip> */}
                                    {/* {console.log('lod*', lod)} */}
                                    <Slider
                                        aria-label={`LOD_steps_${poolId}`}
                                        size='small'
                                        value={lod}
                                        track={false}
                                        defaultValue={lods.length}
                                        marks={lods}
                                        max={lods[lods.length - 1].value}
                                        min={lods[0].value}
                                        // track="inverted"
                                        //step={1}
                                        //valueLabelDisplay="auto"
                                        onChange={handleLODTrack}
                                        //onChangeCommitted={handleLODChange}
                                        classes={{
                                            root: classes.sliderRoot,
                                            mark: classes.mark,
                                            active: classes.markActive,
                                        }}
                                    />
                                </Box>
                            }
                        </Stack>
                    </>
                }
            </Stack>

        </Stack >
    )
}

export default MaturityTab;