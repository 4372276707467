import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import AddBox from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import LinearProgress from '@mui/material/LinearProgress';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';


import clsx from 'clsx';
import style from '@Styles/domrf-theme.module.scss';

import { debounce } from 'throttle-debounce';
import SearchPanelInput from '@Components/shared/SearchPanelInput';
import OriginatorEditorPage from './OriginatorEditorPage';

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from 'react-i18next'


import { createStyles, makeStyles } from '@mui/styles'
import { Paper, Stack, alpha, Box, Container, Tab, Tabs, Theme, Button, Typography, InputAdornment, Tooltip } from '@mui/material'
import ConfirmationForm from "@Components/shared/ConfirmationForm";

import { actionCreators as calculatorStore } from "@Store/CalculatorStore";
import { actionCreators as actionTopNavbar } from '@Store/TopNavbarStore'

import Localization from '@Components/Localization';
import { ProcessingStatus } from '@Components/Definitions';
import MessagesListDialog from '@Components/shared/MessagesListDialog';
import { MessageType, EntityType } from '@Components/Definitions';
import CustomTooltip from '@Components/shared/CustomTooltip';

import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridActionsCellItem,
    GridColumnGroupingModel,
    GridComparatorFn,
    GridValueFormatterParams,
    GridRenderCellParams,
    GridPaginationModel,
    GridCallbackDetails,
    GridSortModel
} from '@mui/x-data-grid-premium';
import { ruRU, enUS } from '@mui/x-data-grid/locales';

const useStyles = makeStyles((theme: Theme) => createStyles({

    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        flex: '1 1 auto',
        height: 0,
        width: '100%',
        minWidth: '100%'
    },
    searchField: {
        marginLeft: 'auto'
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    progress: {
        width: '100%'
    },
    warningIcon: {
        color: style.colorWarning
    },
    statusButton: {
        padding: '0'
    }
}));

const OriginatorsPage = () => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    //const [startPageIndex, setStartPageIndex] = useState(0); //CHECKME!!! add to user settings
    //const [pageSize, setPageSize] = useState(50); //CHECKME!!! add to user settings
    const [searchValue, setSearchValue] = useState('');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50
    });

    const [locale, setLocale] = useState(ruRU);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [editPopupTitle, setEditPopupTitle] = useState(null);
    const [editedRow, setEditedRow] = useState(null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'name',
            sort: 'asc'
        }
    ]);

    const rowRef = useRef(null);

    const {
        originators,
        newOriginator,
        updatedOriginator,
    } = useSelector((state: any) => state.calculator)

    const {
        originatorsLoad
    } = useSelector((state: any) => state.loader)

    useEffect(() => {

        actionTopNavbar.setTitle(t('Originators'))(dispatch)
        return () => {
            actionTopNavbar.setTitle("")(dispatch)
            searchDebounced.cancel();
        }
    }, [])

    useEffect(() => {
        searchDebounced(searchValue);
    }, [searchValue, paginationModel, sortModel]);

    useEffect(() => {

        if (newOriginator == null)
            return;

        var newItems = [...items, newOriginator];
        setItems(newItems);
        setCount(count + 1);

    }, [newOriginator]);

    useEffect(() => {

        if (updatedOriginator == null)
            return;

        var newItems = [...items];

        var index = newItems.findIndex(item => item.id == updatedOriginator.id);
        if (index == -1) {
            throw `Updated element with id = ${updatedOriginator.id} not found`;
        }

        newItems[index] = updatedOriginator;

        setItems(newItems);

    }, [updatedOriginator]);

    useEffect(() => {
        if (i18n.language == 'ru') {
            setLocale(ruRU);
        }
        else {
            setLocale(enUS);
        }

    }, [i18n]);

    useEffect(() => {

        setItems(originators?.items ?? []);
        setCount(originators?.count ?? 0);

    }, [originators]);

    const handleEdit = (params: any) => {
        rowRef.current = params;

        var row = {
            Id: params.row.id,
            createdDate: params.row.createdDate,
            name: params.row.name,
            description: params.row.description,
            productId: params.row.productId,
            shortName: params.row.shortName,
            nameEN: params.row.nameEN,
            isShared: params.row.isShared,
            isVisibleAtInternet: params.row.isVisibleAtInternet,
        }
        setEditedRow(row);

        setEditPopupTitle(t('Edit'));

        setShowEditPopup(true);
    }

    const handleDelete = (params: any) => {
        rowRef.current = params;
        setShowConfirmDialog(true);
    }

    const applyRecordRemove = () => {
        setShowConfirmDialog(false);

        //CHECKME!!! add remove result and bind table update
        calculatorStore.removePortfolio([rowRef.current.id])(dispatch);

        setItems(items.filter(row => row.id != rowRef.current.id));
        setCount(count - 1);

        rowRef.current = null;
    }

    const declineRecordRemove = () => {
        setShowConfirmDialog(false);
        rowRef.current = null;
    }

    const handleRowChange = (name, value) => {

        setEditedRow({ ...editedRow, [name]: value });
    }

    const applyRowChanges = (row, updatedFields) => {

        if (row.Id == null) {
            //add row
            var formData = new FormData();
            for (var propertyName in row) {

                formData.append(propertyName, row[propertyName] instanceof Date ? Localization.FormatDateISO(row[propertyName]) : row[propertyName]);
            }

            //result = await CalculatorService.createPortfolio(formData);

            calculatorStore.createOriginator(formData)(dispatch);

            //administrationStore.createGroups([row])(dispatch);
        }
        else {
            //update row

            //var updated = [];
            var item = { id: row.Id };
            Object.entries(updatedFields).forEach(([key, value]) => {
                item[key] = { Value: value };
            });
            //updated.push(item);

            calculatorStore.updateOriginator(item)(dispatch);

            /*
            var index = items.findIndex(x => x.id == row.id);
            if (index != -1) {
                var current = items[index];

                Object.entries(updatedFields).forEach(([key, value]) => {
                    current[key] = value;
                });
            }
            */
        }

        setShowEditPopup(false);
        rowRef.current = null;
        setEditedRow(null);
    }

    const cancelRowChanges = (test) => {
        setShowEditPopup(false);
        rowRef.current = null;
        setEditedRow(null);
    }

    const addNewRecord = () => {
        var dummy = { createdDate: new Date() };
        rowRef.current = dummy;

        setEditedRow(dummy);
        setEditPopupTitle(t('NewOriginator'));
        setShowEditPopup(true);
    }

    const columns: GridColDef[] = [

        {
            field: 'actions',
            type: 'actions',
            width: 60,
            getActions: (params) => [
                <GridActionsCellItem icon={
                    <EditIcon />
                } label={'Редактировать'} onClick={() => handleEdit(params)} />
                /*
                <GridActionsCellItem
                    icon={
                        <DeleteIcon />
                    } label={'Удалить запись'} onClick={() => handleDelete(params)} />
                    */
            ],
            renderHeader: () => (
                <Tooltip
                    disableFocusListener
                    title={t('Add')}
                    aria-label={t('Add')}>
                    <IconButton onClick={addNewRecord}>
                        <AddBox />
                    </IconButton>
                </Tooltip>

            ),
        },


        { field: 'id', headerName: t('ID'), description: t('originators.id') },
        {
            field: 'createdDate', headerName: t('CreatedDate'), description: t('originators.createdDate'), width: 120,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return '';
                }
                return Localization.FormatDateStringLocale(params.value);
            }
        },
        /*
        {
            field: 'actualDate', headerName: t('ActualDate'), description: t('OriginatorActualDate_Info'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return '';
                }
                return Localization.FormatDateStringLocale(params.value);
            }
        },
        {
            field: 'originatorId', headerName: t('Originator'), description: t('OriginatorOriginator_Info'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                var row = params.api.getRow(params.id)

                return row.originatorName;
            }
        },
        {
            field: 'bondId', headerName: t('Bond'), description: t('OriginatorBond_Info'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                var row = params.api.getRow(params.id)

                return row.bondName;
            }
        },
        */
        { field: 'name', headerName: t('Name'), description: t('originators.name'), width: 300 },
        { field: 'description', headerName: t('Description'), description: t('originators.description'), width: 200 },
        { field: 'shortName', headerName: t('ShortName'), description: t('originators.shortName'), width: 150 },
        { field: 'nameEN', headerName: t('EnglishName'), description: t('originators.englishName'), width: 200 },
        {
            field: 'productId', headerName: t('Product'), description: t('originators.product'), width: 200,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                var row = params.api.getRow(params.id)

                return row.productName;
            }
        },
        { field: 'isShared', headerName: t('Shared'), description: t('originators.shared'), type: 'boolean', width: 100 },
        { field: 'isVisibleAtInternet', headerName: t('VisibleAtInternet'), description: t('originators.visibleAtInternet'), type: 'boolean', width: 150 }
    ];

    const updateDataSource = (searchValue) => {

        var filterItem = null;
        if (searchValue != '') {

            if (paginationModel.page != 0) {
                setPaginationModel({ ...paginationModel, page: 0 });
                return;
            }

            filterItem = [{
                Operator: "or",
                Values: [
                    {
                        Name: "name",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "description",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    }
                ]
            }];
        }

        var sortItem = sortModel.map((item, index) => {
            return {
                Name: item.field,
                IsAscending: item.sort == 'asc',
                SortOrder: index
            };
        });

        var filter = {
            Filters: filterItem,
            Sorters: sortItem,
            ItemIndex: paginationModel.page * paginationModel.pageSize,
            PageSize: paginationModel.pageSize
        };

        calculatorStore.getOriginators(filter)(dispatch);
    }

    const onPaginationModelChange = (model: GridPaginationModel, details: GridCallbackDetails) => {

        setPaginationModel(model);
        searchDebounced(searchValue);
    }

    const searchDebounced = useCallback(debounce(500, updateDataSource), [paginationModel, sortModel]);


    return (
        <Container className={classes.container}>
            <OriginatorEditorPage
                row={editedRow}
                onChange={handleRowChange}
                onApplyChanges={applyRowChanges}
                onCancelChanges={cancelRowChanges}
                title={editPopupTitle}
                open={showEditPopup}
            />

            <ConfirmationForm
                isOpen={showConfirmDialog}
                header='Удаление'
                content='Вы хотите удалить запись?'
                onAccept={applyRecordRemove}
                onDecline={declineRecordRemove}
            />

            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingX: '1rem', paddingY: '0.5rem' }}>
                <SearchPanelInput className={classes.searchField} value={searchValue} onValueChange={setSearchValue} placeHolder={t('Search')} />
            </Stack>
            {/*console.log("portfoliosLoad => " + portfoliosLoad)*/}
            <DataGridPremium
                sx={{
                    ["& .bandHeaderCell .MuiDataGrid-columnHeaderTitleContainer"]: {
                        justifyContent: 'center'
                    }
                }}
                //experimentalFeatures={{ columnGrouping: true }}
                disableColumnMenu
                loading={originatorsLoad}
                columnHeaderHeight={30}
                rowHeight={25}
                rows={items}
                columns={columns}
                //columnGroupingModel={columnGrouping}
                localeText={locale.components.MuiDataGrid.defaultProps.localeText}

                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={onPaginationModelChange}
                rowCount={count}
                pagination
                pageSizeOptions={[30, 50, 100]}

                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            />
        </Container >
    )
}

export default OriginatorsPage;