import Localization from '@Base/components/Localization'
import { Close, PendingActionsOutlined, SaveOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, Tooltip, alpha } from '@mui/material'
import KeyrateDialog from '../components/KeyrateDialog'
import style from '@Styles/domrf-theme.module.scss'
import { makeStyles, styled } from '@mui/styles'
import { t } from 'i18next'
import { useLayoutEffect, useRef, useState } from 'react'
// import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import UniversalInputField, { InputDataType } from '@Base/framework/components/Form/UniversalInputField'
import { setConventionData } from '@Base/newStore/conventionSlice'
import { useNewDispatch, useNewSelector } from '@Base/index'

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: "DINPro-Bold",
    fontWeight: "700",
    // letterSpacing: '2px',
    // fontSize: '1.1rem !important',
    letterSpacing: '1.5px',
    fontSize: '0.8rem !important',
    '& *': {
        fontSize: '0.8rem !important',
    },
    // color: style.colorPrimary3,
    padding: '0 0.5rem 0 9px',
    marginRight: '0.5rem',
    backgroundColor: 'rgba(183, 217, 139, 0.23)',
    borderColor: 'rgb(61,149,109)',
    color: 'rgb(61,149,109)',
    '&:hover': {
        borderColor: 'rgb(61,149,109) !important',
        backgroundColor: 'rgba(183, 217, 139, 0.4) !important',
        // borderColor: `${style.colorPrimary2} !important`,
        // backgroundColor: 'rgba(183, 217, 139, 0.23) !important',
        // color: `rgb(0,176,80) !important`,
    }
}))

const useStyles = makeStyles({
    mr05: {
        marginRight: '0.5rem',
    },
    switchLabel: {
        margin: 0,
        textTransform: 'uppercase',
        fontSize: '0.8rem !important',
        letterSpacing: '1.5px !important',
        lineHeight: '2rem',
        // marginTop: '3px',
        marginBottom: '1px',
        fontWeight: 'bold',
    },
    disabled: {
        color: `${style.colorPrimary3_40} !important`
    },
    switchContainer: {
        fontFamily: "DINPro-Bold",
        fontWeight: "700",
        letterSpacing: '1.5px',
        fontSize: '0.8rem !important',
        border: `solid 1px ${style.colorPrimary3_40}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '23px',
        borderRadius: '4px',
        marginRight: '0.5rem',
        padding: '0 0.5rem',
        cursor: 'pointer'
    },

    switchRoot: {
        marginRight: '3px',
        marginBottom: '2px',
        zoom: '0.96',
        // height: '15px !important',
        // '& .MuiButtonBase-root': {
        //     height: '20px !important'
        // },
        '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
            color: 'rgb(245,245,245)',
            border: `solid 1px rgb(189,189,189)`,

        },
        '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
            color: 'rgb(245,245,245)',
            border: `solid 1px rgb(189,189,189)`,
        },
        '& .MuiSwitch-switchBase.Mui-focusVisible': {
            color: `${alpha(style.colorPrimary3, 1)}`,
            // padding:'5px',
            // paddingRight:0,
            // paddingBottom:0,
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            backgroundColor: 'rgb(255,255,255)',
            border: `solid 1px rgb(189,189,189)`,
            // opacity:'0.5 !important'
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            color: 'rgb(245,245,245)',
            border: `solid 1px rgb(189,189,189)`,
        },
    },
    rootModal: {
        position: 'relative',
        width: '1450px',
        height: '830px',
        marginRight: '4px',
        outline: 'none',
        '& $rootPaper': {
            backgroundColor: '#FFF',
        }
    },
    rootPaper: {
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'hidden',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 0',
        justifyContent: 'space-between',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
        marginLeft: '0.5rem',
    },
    panelHeaderButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // '&>*:not(:first-child)': {
        //     marginLeft: '0.5rem',
        // }
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80
    },
})


const KeyratePanel = ({ readyForExport = false, hidePanel = () => null }) => {
    const cls = useStyles()
    // const { storedEditMode = null, ...otherStore } = useSelector((state) => state.convention)
    const { storedEditMode, ...otherStore } = useNewSelector(state => state.newConvention)

    // console.log('otherStore ************************************', storedEditMode, otherStore)
    // const dispatch = useDispatch()
    const newDispatch = useNewDispatch()
    const dialogDataRef = useRef()
    const [editMode, setEditMode] = useState(storedEditMode ?? false)
    // const [localKeyrateWasChanged, setLocalkeyrateWasChanged] = useState(keyrateWasChanged??false)

    useLayoutEffect(() => {
        // actionConvention.setData({ storedEditMode: editMode })(dispatch)
        newDispatch(setConventionData({ storedEditMode: editMode }))
    }, [editMode])

    // useLayoutEffect(() => {
    //     setLocalkeyrateWasChanged(keyrateWasChanged)
    // }, [keyrateWasChanged])

    return <Box className={cls.rootModal}>
        <Box className={cls.rootPaper}>
            <Box className={cls.panelHeader}>
                <Box className={cls.panelHeaderTitle}>
                    {`${t('convention2.keyrateModeling')}`}
                </Box>
                <Box className={cls.panelHeaderButtons}>
                    <StyledButton
                        variant="outlined"
                        size="small"
                        onClick={() => dialogDataRef.current?.chartHelper()}
                        className={cls.resetButton}
                        disabled={Boolean(dialogDataRef.current?.chartHelper == null || editMode)}
                    >
                        {t('convention2.seriesDescription')}
                    </StyledButton>
                    <span className={cls.switchContainer} onClick={() => setEditMode(!editMode)}>
                        <Box className={[cls.switchLabel, editMode && cls.disabled].join(' ')}>
                            {t('convention2.viewMode')}
                        </Box>
                        <UniversalInputField
                            classes={{ root: cls.switchRoot }}
                            dataType={InputDataType.BOOLEAN}
                            value={editMode}
                            handleValueChange={(name, value) => { } /*seteditMode(!value)*/}
                        />
                        <Box className={[cls.switchLabel, !editMode && cls.disabled,/* cls.mr05*/].join(' ')}>
                            {t('convention2.modelingMode')}
                        </Box>
                    </span>
                    <IconButton size="small" onClick={hidePanel}>
                        <Close className={cls.icon} />
                    </IconButton>
                </Box>
            </Box>
            <KeyrateDialog
                ref={dialogDataRef}
                hidePanel={hidePanel}
                editMode={editMode}
            // setEditMode={setEditMode}
            // keyrateWasChanged={localKeyrateWasChanged}
            />
        </Box>
    </Box>
}

export default KeyratePanel