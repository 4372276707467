import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BondStatus, MortgageType, PoolProcessingStatus, ProductsType } from '@Components/Definitions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faBox } from '@fortawesome/free-solid-svg-icons'
import { isValid as isValidDateFns } from 'date-fns';
import { t } from 'i18next';

export const humanReadableBondStatus = new Map([
    [BondStatus.STATUS_NOT_ISSUED, "BondNotIssued"],
    [BondStatus.STATUS_PLACEMENT, "BondPlacement"],
    [BondStatus.STATUS_ISSUED, "BondIssued"],
    [BondStatus.STATUS_REDEEMED, "BondRedeemed"],
])

export const humanReadableMortgageProgramms = new Map([
    [MortgageType.UNKNOWN, "Unknown"],
    [MortgageType.PREFERENTIAL, "Preferential"],
    [MortgageType.FAMILY, "Family"],
    [MortgageType.FAMILYDFO, "FamilyDFO"],
    [MortgageType.DFO, "DFO"],
    [MortgageType.IT, "IT"],
    [MortgageType.OTHER, "Other"],
])

export const humanReadableProducts = new Map([
    [ProductsType.UNKNOWN, "Unknown"],
    [ProductsType.PRIMARY, "PrimaryRealty"],
    [ProductsType.SECONDARY, "SecondaryRealty"],
    [ProductsType.REFINANCING, "Refinancing"],
    [ProductsType.OTHER, "Other"],
])

export const humanReadableStatuses = new Map([
    [PoolProcessingStatus.STATUS_UNKNOWN, "Unknown"],
    [PoolProcessingStatus.STATUS_LOADED, "Loaded"],
    [PoolProcessingStatus.STATUS_PROCESSING, "Processing"],
    [PoolProcessingStatus.STATUS_UPLOADING_AT_DB, "UploadingAtDB"],
    [PoolProcessingStatus.STATUS_UPLOADED_AT_DB, "UploadedAtDB"],
    [PoolProcessingStatus.STATUS_SENDING, "Sending"],
    [PoolProcessingStatus.STATUS_SENT, "Sent"],
    [PoolProcessingStatus.STATUS_VALIDATION_FAILED, "ValidationFailed"],
    [PoolProcessingStatus.STATUS_ATTENTION_REQUIRED, "UserAttentionRequired"],
    [PoolProcessingStatus.STATUS_SUCCESS, "Success"],
    [PoolProcessingStatus.STATUS_FAILED, "Failed"],
    [PoolProcessingStatus.STATUS_ARCHIVED, "Archived"]
]);

export function fileDownloadPage(path, target = '_self') {
    window.open(`/instruments/downloader?l=${bytesToBase64(path)}`, target)
}

export function base64ToBytes(base64) {
    try {
        return window.atob(base64);
    }
    catch (error) {
        console.error(error)
    }
}

export function bytesToBase64(bytes) {
    // console.log('bytes', bytes)
    // const binString = String.fromCodePoint([...bytes]);
    try {
        return window.btoa(bytes);
    }
    catch (error) {
        console.error(error)
    }
}

export function roundUp(value, cutOff = 1) {
    // console.log('UP value -> Math.floor(value / cutOff) * cutOff;', value,  Math.ceil(value / cutOff) * cutOff)
    return Math.ceil(value / cutOff) * cutOff;

}

export function roundDown(value, cutOff = 1) {
    // console.log('DOWN value -> Math.floor(value / cutOff) * cutOff;', value,  Math.floor(value / cutOff) * cutOff)
    return Math.floor(value / cutOff) * cutOff;
}

export function clone(object) {
    return JSON.parse(JSON.stringify(object));
}

/**
 * Is server prerendering by Node.js.
 * There can't be any DOM: window, document, etc.
 */
//CHEKME!!! remove
export function isNode() {
    return typeof process === 'object' && process.versions && !!process.versions.node;
}

export function isObjectEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function EmptyForm(form) {
    var inputs = Array.from(form.querySelectorAll("input, select, textarea"));
    inputs.forEach(x => {
        var inputType = x.getAttribute("type");
        if (inputType === "checkbox" || inputType === "radio") {
            x.checked = false;
            //x.dispatchEvent(new Event("change"));
        } else {
            //x.parentElement.querySelectorAll("label").forEach(y => y.classList.remove("active"));
            x.value = "";
            x.defaultValue = "";
            //x.dispatchEvent(new Event("change"));
        }
    });
}

export function setHighchartsLocalization(highcharts, t) {

    highcharts.setOptions({
        lang: {
            months: [
                t('January'),
                t('February'),
                t('March'),
                t('April'),
                t('May'),
                t('June'),
                t('July'),
                t('August'),
                t('September'),
                t('October'),
                t('November'),
                t('December')
            ],

            shortMonths: [
                t('January_Short'),
                t('February_Short'),
                t('March_Short'),
                t('April_Short'),
                t('May_Short'),
                t('June_Short'),
                t('July_Short'),
                t('August_Short'),
                t('September_Short'),
                t('October_Short'),
                t('November_Short'),
                t('December_Short')
            ],

            weekdays: [
                t('Monday'),
                t('Tuesday'),
                t('Wednesday'),
                t('Thursday'),
                t('Friday'),
                t('Saturday'),
                t('Sunday')
            ],

            contextButtonTitle: t('contextButtonTitle'),
            downloadJPEG: t('downloadJPEG'),
            downloadPDF: t('downloadPDF'),
            downloadPNG: t('downloadPNG'),
            downloadSVG: t('downloadSVG'),
            downloadCSV: t('downloadCSV'),
            downloadXLS: t('downloadXLS'),
            drillUpText: t('drillUpText') + ' { series.name }',

            loading: t('Loading'),
            noData: t('noData'),
            printChart: t('printChart'),
            resetZoom: t('resetZoom'),
            resetZoomTitle: t('resetZoomTitle'),
            viewData: t('viewData')
        },
        chart: {
            style: {
                fontFamily: 'inherit'
            }
        }
    });
}

export function getTableLocalization(t) {

    return {
        tableMessages: {
            noData: t('NoData')
        },

        editColumnMessages: {
            addCommand: t('NewRecord'),
            editCommand: t('Edit'),
            deleteCommand: t('Remove'),
            commitCommand: t('Save'),
            cancelCommand: t('Cancel')
        },
        groupingPanelMessages: {
            groupByColumn: t('DragColumnToGroup')
        },
        filterRowMessages: {
            filterPlaceholder: t('Filter'),
        },
        pagingPanelMessages: {
            showAll: t('All'),
            rowsPerPage: t('RowsPerPage'),
            info: t('GateInfo')
        },
        columnChooserMessages: {
            showColumnChooser: t('SelectColumns')
        },
        searchPanelMessages: {
            searchPlaceholder: t('Search'),
            tooltip: t('SearchByValue')
        }
    }
}

export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

// function copyVarToClipboard(text) {
//     var dummy = document.createElement("textarea");
//     // to avoid breaking orgain page when copying more words
//     // cant copy when adding below this code
//     // dummy.style.display = 'none'
//     document.body.appendChild(dummy);
//     //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
//     dummy.value = text;
//     dummy.select();
//     document.execCommand("copy");
//     document.body.removeChild(dummy);
// }

function fallbackCopyTextToClipboard(text, element) {

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    var textArea = document.createElement("textarea");

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = 'transparent';
    textArea.style.zIndex = 10001;

    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
    //if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
    //}
    // navigator.clipboard.writeText(text).then(function () {
    //     console.log('Async: Copying to clipboard was successful!');
    // }, function (err) {
    //     console.error('Async: Could not copy text: ', err);
    // });
}

export function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

export function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export function getChartsData(chartOptions) {

    var dates = chartOptions.series.map(s => s.data.map(y => y.x)).flat().filter((item, index, arr) => arr.indexOf(item) === index).sort();

    var matrix = [...Array(Math.max(...chartOptions.series.map(s => s.data.length)))].map(x => Array(chartOptions.series.length + 1));

    dates.forEach((item, rowIndex) => {

        matrix[rowIndex][0] = item;

        chartOptions.series.forEach((s, colIndex) => {
            var iIndex = s.data.findIndex(i => i.x === item);
            if (iIndex === -1)
                return;

            matrix[rowIndex][colIndex + 1] = s.data[iIndex].y;
        });
    });

    return matrix;
}
export function downloadFile(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function downloadData(url) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.click();
    document.body.removeChild(a);
};

export function toUnixDate(date) {
    return date.getTime() - (date.getTimezoneOffset() * 60 * 1000);
}

export function toUnixDateGMT(date) {
    return date.getTime();
}

//percentage not in %! it's from 0 to 1
export function makeGradient(minColor, maxColor, percentage, alpha) {

    var minC = minColor.match(/^#((?:[A-Fa-f0-9]{3}){1,2})$/);
    if (minC == null)
        throw new Error('Bad minColor');

    var maxC = maxColor.match(/^#((?:[A-Fa-f0-9]{3}){1,2})$/);
    if (maxC == null)
        throw new Error('Bad maxColor');

    var minInt = parseInt(minC[1], 16);
    var minR = (minInt >> 16) & 255;
    var minG = (minInt >> 8) & 255;
    var minB = minInt & 255;

    var maxInt = parseInt(maxC[1], 16);
    var maxR = (maxInt >> 16) & 255;
    var maxG = (maxInt >> 8) & 255;
    var maxB = maxInt & 255;

    var rR = Math.min(255, Math.round(minR + (maxR - minR) * percentage));
    var rG = Math.min(255, Math.round(minG + (maxG - minG) * percentage));
    var rB = Math.min(255, Math.round(minB + (maxB - minB) * percentage));

    if (alpha) {
        return `rgba('${rR},${rG},${rB},${alpha}')`;
    }
    else {
        return "#" + toPaddedHexString(rR, 2) + toPaddedHexString(rG, 2) + toPaddedHexString(rB, 2);
    }
}

export function toPaddedHexString(num, len) {
    var str = num.toString(16);
    return '0'.repeat(len - str.length) + str;
}

export function hexToRgbA(hex, alpha) {
    // console.log('hex', hex)
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
}

export function upperFirstChar(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function TabPanel(props) {
    const { children, value, index, boxProps, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box {...boxProps}>{children}</Box>}
        </Typography>
    );
}

export function TabPanelWithState(props) {
    const { children, value, index, boxProps, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, }} {...boxProps}>{children}</Box>
            )}
        </Typography>
    );
}

export function getDate(date) {

    if (date == null || date === '')
        return null;

    var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    var unixDate = newDate.getTime() - (newDate.getTimezoneOffset() * 60 * 1000);
    return new Date(unixDate);
}

export function getDateUTC(date) {

    if (date == null || date === '')
        return null;

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}

export function getDateTimeGMT(date) {

    if (date == null || date === '')
        return date;
    if (!isValidDateFns(date))
        return date;

    var unixDate = date.getTime() - (date.getTimezoneOffset() * 60 * 1000);
    return new Date(unixDate);
}

export function getTime(date) {

    if (date == null || date === '')
        return null;

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0);
}

export function isPermissionGranted(permissionId, permissions) {

    //first, get byte where permission lays
    var halfByteIndex = (permissionId >> 2);

    if (permissions == null || permissions.length === 0 || permissions.length <= halfByteIndex)
        return false;


    var bitIndex = (permissionId - (halfByteIndex << 2));
    //if (bitIndex != 0)
    //byteIndex++;

    var value = parseInt(permissions[halfByteIndex], 16);

    return (value & (1 << bitIndex)) !== 0;
}

export function setPermissionState(permissionId, permissions, isGrant) {

    var permissionOut = permissions || '';
    //first, get byte where permission lays
    var halfByteIndex = (permissionId >> 2);

    if (permissions == null || permissions.length === 0 || permissions.length <= halfByteIndex) {
        var addition = halfByteIndex - (permissions?.length ?? 0) + 1;
        if (addition !== 0) {
            permissionOut = permissionOut + '0'.repeat(addition);
        }

        if (!isGrant)
            return permissionOut;
    }

    var bitIndex = (permissionId - (halfByteIndex << 2));

    var value = parseInt(permissionOut[halfByteIndex], 16);

    if (isGrant)
        value = value | (1 << bitIndex);
    else
        value = value & ~(1 << bitIndex);

    permissionOut = permissionOut.substring(0, halfByteIndex) + value.toString(16) + permissionOut.substr(halfByteIndex + 1, permissionOut.length - halfByteIndex - 1);

    return permissionOut;
}

export function mergePermissions(permissions: string[]): string {

    if (permissions == null || permissions.length === 0)
        return '';

    //first, pad all permissions
    var maxLength = Math.max(...permissions.map(x => x.length));
    var innerPermissions = permissions.map(item => item + '0'.repeat(maxLength - item.length));

    var result = innerPermissions[0];

    for (var i = 1; i < innerPermissions.length; i++) {
        result = joinPermissions(result, innerPermissions[i]);
    }

    return result;
}

//lenghts must be equal!
function joinPermissions(first: string, second: string): string {

    const chunkSize = 8; //8 hexidecimal chars = 32bit integer
    var currentIndex = 0;
    var result = '';

    while (currentIndex < first.length) {
        var leftChunk = first.substring(currentIndex, currentIndex + chunkSize);
        var rightChunk = second.substring(currentIndex, currentIndex + chunkSize);

        var leftValue = parseInt(leftChunk, 16);
        var rightValue = parseInt(rightChunk, 16);

        result += (leftValue | rightValue).toString(16);

        currentIndex += chunkSize;
    }

    //pad
    if (result.length < first.length)
        result = '0'.repeat(first.length - result.length) + result;

    return result;
}

export function valueOrDefault(value = null, defaultValue) {
    return value === null ? defaultValue : value;
}

export const getNameAbbreviation = (name = null) => {
    if (name == null)
        return t('Unknown');

    let source = name?.split(/\s/g)
    let output = []

    source.forEach((el, index) => { if (index != 0) output.push(source[index][0]) })
    output.push(` ${source[0]}`)
    return output.join('.')
}

const isNumber = (val) => {
    return !!val && isFinite(val)
}
const isDate = (val) => {
    const date = Date.parse(val)
    // console.log(val, 'ch_date', date)
    return isValidDateFns(date)
}

export const getType = (value) => {

    if (value === null)
        return 'nullObject';

    if (value === undefined)
        return 'undefined';

    if (typeof (value) === 'boolean')
        return 'boolean'

    if (isNumber(value?.toString()))
        return 'number';

    if (isDate(value?.toString()))
        return 'date';

    return 'string';
}

export const formDateFromParts = (displayPart: string[], datePart: string[], datePartDivider: string, placeholder: string): string => {
    const parts = {
        d: { key: 'day', default: '01' },
        M: { key: 'month', default: '01' },
        y: { key: 'year', default: '0001' }
    };

    if (displayPart == null)
        return datePart.join(datePartDivider);

    if (displayPart?.length != datePart?.length)
        throw "Actual date value does not fits to it partial view";

    let phParts = placeholder.split(datePartDivider);
    return phParts
        .map((part, index) => {

            var element = parts[part[0]];
            if (element == null)
                throw "unknown part " + part[0];

            var partIndex = displayPart.findIndex(x => x == element.key);
            if (partIndex == -1)
                return element.default;

            return datePart[partIndex];

        })
        .join(datePartDivider);
}

export const formDatePattern = (displayPart: string[], datePartDivider: string, placeholder: string, mask: string): { phParts: string[], msParts: string[] } => {


    let phParts = placeholder?.split(datePartDivider);
    let msParts = mask != null ? mask.split(datePartDivider) : null;

    if (displayPart == null || displayPart.length == 0)
        return { phParts, msParts };

    if (msParts != null && phParts.length != msParts.length)
        throw "Mask and placeholder are different!";

    const baseViews = ['day', 'month', 'year'];

    baseViews.forEach(view => {

        var index = displayPart.indexOf(view);
        if (index != -1)
            return;

        switch (view) {
            case 'day':
                {
                    var partIndex = phParts.findIndex(x => x.indexOf('d') != -1);

                    if (partIndex != -1) {
                        phParts.splice(partIndex, 1);
                        if (msParts != null)
                            msParts.splice(partIndex, 1);
                    }
                }
                break;
            case 'month':
                {
                    var partIndex = phParts.findIndex(x => x.indexOf('M') != -1);

                    if (partIndex != -1) {
                        phParts.splice(partIndex, 1);
                        if (msParts != null)
                            msParts.splice(partIndex, 1);
                    }

                }
                break;
            case 'year':
                {
                    var partIndex = phParts.findIndex(x => x.indexOf('y') != -1);

                    if (partIndex != -1) {
                        phParts.splice(partIndex, 1);
                        if (msParts != null)
                            msParts.splice(partIndex, 1);
                    }
                }
                break;
            default:
                throw "Unknown date part: " + view;
        }
    });


    return {
        phParts, msParts
    };
}