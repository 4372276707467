import { useNewDispatch, useNewSelector } from '@Base/index'
import { getSCurveGraph } from '@Base/newStore/conventionSlice'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { alpha } from '@mui/system'
import { axisGridLineColor, axisLabelsColor, axisLineColor, axisTitleColor, plotBorderColor } from '@Base/components/constants/charts'
import { t } from 'i18next'
import style from '@Styles/style-main.scss'
import { Box } from '@mui/material'
import Localization from '@Base/components/Localization'

const ChartSCurves = forwardRef(({ min, max }, ref) => {
    const chartRef = useRef()
    ref = chartRef?.current
    const dispatch = useNewDispatch()
    const { sCurvesGraph, sCurvesGraphLoading, poolInfo } = useNewSelector(state => state.newConvention)

    const sCurvesColor = [
        { loanAgeId: 0, key: 'меньше 1 года', color: 'rgb(50,72,77)', valColor: 'rgb(124,152,180)', isActive: true },
        { loanAgeId: 1, key: 'от 1 до 2 лет', color: 'rgb(70,101,72)', valColor: 'rgb(180,192,203)', isActive: true },
        { loanAgeId: 2, key: 'от 2 до 3 лет', color: 'rgb(88,126,67)', valColor: 'rgb(207,212,214)', isActive: true },
        { loanAgeId: 3, key: 'от 3 до 4 лет', color: 'rgb(106,152,62)', valColor: 'rgb(234,231,225)', isActive: true },
        { loanAgeId: 4, key: 'от 4 до 5 лет', color: 'rgb(125,179,57)', valColor: 'rgb(245,239,230)', isActive: true },
        { loanAgeId: 5, key: 'от 5 до 6 лет', color: 'rgb(139,191,70)', valColor: 'rgb(245,239,230)', isActive: true },
        { loanAgeId: 6, key: 'от 6 до 7 лет', color: 'rgb(152,199,90)', valColor: 'rgb(245,239,230)', isActive: true },
        { loanAgeId: 7, key: 'от 7 до 8 лет', color: 'rgb(164,207,108)', valColor: 'rgb(245,239,230)', isActive: true },
        { loanAgeId: 8, key: 'от 8 до 9 лет', color: 'rgb(177,215,128)', valColor: 'rgb(245,239,230)', isActive: true },
        { loanAgeId: 9, key: 'больше 9 лет', color: 'rgb(192,223,150)', valColor: 'rgb(245,239,230)', isActive: true },
    ]

    useEffect(() => {
        dispatch(getSCurveGraph({ minInc: min, maxInc: max }))
    }, [])


    const [sCurvesChartOptions, setsCurvesChartOptions] = useState({
        exporting: {
            enabled: false,
        },
        chart: {
            animation: false,
            marginLeft: 10, // Keep all charts left aligned
            height: 400,
            marginBottom: 80,
            spacingBottom: 13,
            plotBorderWidth: 0,
            plotBorderColor: plotBorderColor,
            events: {
                load: function () {

                }
            }
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                animation: false,
                // animation: { defer: 500 },
                states: {
                    inactive: {
                        opacity: 0,
                        enabled: false,
                    },
                    hover: {
                        halo: {
                            size: 0
                        }
                    }
                },
                marker: {
                    symbol: 'circle', // Set the marker symbol to 'circle'
                    radius: 2,
                },
                events: {
                    legendItemClick: function () {
                        return false; // Prevent the default legendItemClick behavior
                    }
                }
            },
        },

        xAxis: [{
            crosshair: {
              color: alpha(style.colorPrimary3, 0.5)
            },
            minPixelPadding: 0,
            minPointOffset: 0,
            gridLineColor: axisGridLineColor,
            gridLineWidth: 1,
            gridLineDashStyle: 'solid',
            tickInterval: 10,
            startOnTick: true,
            endOnTick: true,
            minPadding: 0,
            maxPadding: 0,
            tickWidth: 0,
            // min: min,
            // max: max,
            title: {
                text: t('convention2.GraphIncentiveToRefinance'),
                // offset: 19,
                style: {
                    fontSize: '0.8rem',
                    color: axisTitleColor,
                }
            },
            labels: {
                rotation: 0,
                y: 17,
                allowOverlap: true,
                style: {
                    lineHeight: '11px',
                    fontSize: '11px',
                    overflow: 'visible',
                    textOverflow: 'visible',
                    color: axisLabelsColor,
                }
            },
            lineWidth: 1,
            lineColor: axisLineColor,
            // categories: [],
        },
        ],

        yAxis: [{
            crosshair: false,
            gridLineColor: axisGridLineColor,
            gridLineWidth: 1,
            lineWidth: 1,
            lineColor: axisLineColor,
            min: 0,
            max: 45,
            tickAmount: 10,
            alignTicks: true,
            title: {
                text: t('convention2.demoYAxisCPR'),
                // x: 5,
                y: -70,
                style: {
                    fontSize: '0.8rem',
                    color: axisTitleColor,
                }
            },
            labels: {
                // x: -5,
                style: {
                    lineHeight: '11px',
                    fontSize: '11px',
                    color: axisLabelsColor,
                }
            },
            tickWidth: 0,
        }],

        tooltip: {
            animation: false,
            shape: "square",
            positioner: function (labelWidth, labelHeight, point) {
              return {
                x: this.chart.plotLeft + 7,//this.chart.plotWidth - labelWidth - 3,
                y: this.chart.plotTop + 5//this.chart.plotHeight - labelHeight - 3
              };
            },
            formatter: function () {
              // console.log('this', this.points)
              if (this?.points == null || !this?.points?.length) return
      
              var points = [{ name: t('convention2.RateDifferencePrc'), value: this.x, fraction: 1 },]
      
              this.points?.forEach(point => {
                const val = getPointValue(point)
                if (val) points.push(val)
              })
      
              return points.sort((a, b) => a?.sortOrder < b?.sortOrder ? -1 : a?.sortOrder > b?.sortOrder ? 1 : 0)
                .filter((item, index) => {
                  if (index == 0 || (index != 0 && points[index - 1]?.sortOrder != item?.sortOrder))
                    return item
                })
                .reduce(function (s, point) {
                  return s + `<div style='display:flex;flex-direction:row;justify-content:space-between;font-size:0.9rem'>
                        <div>${point.name}</div>
                        <div style='margin-left:1rem;min-width:2rem;text-align:right;font-weight:bold;'>${Localization.FormatNumber(point.value, point?.fraction ?? 0, point?.fraction ?? 0)}</div>
                        </div>`
                }, '')
      
              // return '<table style="min-width:200px;max-width:600px">' +
              //   points.reduce(function (s, point) {
              //     return s + '<tr><td style="padding:0">' + t(point.name) + ': </td>' +
              //       '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(point.value, point?.fraction ?? 0, point?.fraction ?? 0) + '</b></td></tr>'
              //   }, '') +
              //   '</table>';
            },
            hideDelay: 0,
            shared: true,
            useHTML: true
          },
        series: []
    })

    const getPointValue = useCallback((point) => {

        // console.log('point', point?.series?.userOptions?.loanAgeId)
        switch (point?.series?.userOptions?.name) {
          case 'cpr':
            // console.log('sCurvesColor[point?.series?.userOptions?.loanAgeId]', sCurvesColor.current, point?.series?.userOptions?.loanAgeId)
            return ({ name: `${t('convention2.GraphCPRTooltipPart0')} ${sCurvesColor[point?.series?.userOptions?.loanAgeId]?.key}, ${t('percentInYear')}`, value: point.y, fraction: 1, sortOrder: point?.series?.userOptions?.loanAgeId })
            break
        }
    
        // return ({ name: 'SERIES', value: point?.series?.userOptions?.id })
      }, [sCurvesColor.current])

    useEffect(() => {
        const cpr_series = []
        const newChart = { ...sCurvesChartOptions }
        // newChart.xAxis[0].categories = sCurvesGraph?.incentive ?? []

        // console.log('sCurvesData', sCurvesData)
        sCurvesGraph?.data?.toReversed()?.forEach((item, index) => {
            cpr_series.push(
                {
                    animation: false,
                    // animation: { duration: 600, defer: 300 * index,},
                    name: 'cpr',
                    id: `sCurve_${item?.loanAgeID}`,
                    loanAgeId: item?.loanAgeID,
                    type: 'line',
                    pointPlacement: 'on',
                    xAxis: 0,
                    yAxis: 0,
                    lineWidth: 2,
                    color: sCurvesColor[item?.loanAgeID].color,
                    states: {
                        hover: { enabled: false, },
                    },
                    marker: {
                        enabled: false,
                    },
                    zIndex: 300,
                    data: item?.cpr?.map((x, index) => ([sCurvesGraph?.incentive[index], x])),
                    visible: true,
                    showInLegend: false,
                })

        })
        newChart.xAxis[0].tickPositions = sCurvesGraph?.incentive?.filter((x, index) => index % 10 == 0)
        setsCurvesChartOptions({ ...newChart, series: cpr_series })
    }, [sCurvesGraph])

    const onResize = useCallback(() => {
        if (sCurvesChartOptions?.series) {
            const xAxis = chartRef?.current?.chart.xAxis[0]
            const yAxis = chartRef?.current?.chart.yAxis[0]
            const ml = chartRef?.current?.chart?.plotLeft ?? 0
            if (xAxis && yAxis) {
                // xAxis.update({ min: min, max: max })
                const offset = xAxis.toPixels(0)
                if (!isNaN(offset)) {
                    // const newChart = sCurvesChartOptions
                    yAxis.update({ offset: -offset + ml })
                }
                chartRef?.current?.chart.redraw()

            }
        }
    }, [sCurvesChartOptions?.series])

    useEffect(() => {
        onResize()
    }, [sCurvesChartOptions])

    useLayoutEffect(() => {
        if (window)
            window.addEventListener('resize', onResize)

        return () => {
            if (window) {
                window.removeEventListener('resize', onResize)
            }
        }
    }, [])
    // console.log('sCurvesGraph, sCurvesGraphLoading', sCurvesGraph, sCurvesGraphLoading)
    return (
        <Box sx={{ minHeight: '400px', position: 'relative' }}>
            <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                containerProps={{ style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, } }}
                options={sCurvesChartOptions ? sCurvesChartOptions : {}}
                callback={() => {
                    // console.log('ref?.current?.chart.xAxis[0]', chartRef.current?.chart.xAxis[0].toPixels(0, 0))
                    // newChart.yAxis[0].offset = -chartRef?.current?.chart.xAxis[0].toPixels(0, 0) //+ this.sCurveChart?.chart?.plotLeft ?? 0 
                }}
            />
        </Box>
    )
})

export default ChartSCurves