import { createStyles, makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    header: {
        /* -webkit-transition: box-sha.dow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, */
        /* transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, */
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
        /* display: -webkit-box, */
        /* display: -webkit-flex, */
        /* display: -ms-flexbox, */
        display: 'flex',
        flexDirection: 'column',
        /* -webkit-flex-direction: column, */
        /* -ms-flex-direction: column, */
        width: '100%',
        height: '64px',
        /* box-sizing: border-box, */
        /* -webkit-flex-shrink: 0, */
        /* -ms-flex-negative: 0, */
        /* flex-shrink: 0, */
        /* position: fixed, */
        /* z-index: 1100, */
        /* top: 0, */
        /* left: auto, */
        /* right: 0, */
        backgroundColor: '#2F444E',
        color: '#fff',
    },

    title: {
        width: '1520px',
        margin: 'auto',
        height: '64px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
    },

    logo: {
        width: '40px',
        height: '40px',
        position: 'relative',
    },
    alert: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        lineHeight: '1.5rem',
        fontFamily: 'DINPro-Regular, "Helvetica Neue", Arial, sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        textTransform: 'uppercase',
        color: '#c6c7c9',
    }
}))

const reload = () => {
    document.location.reload()
}
const TechSupportPage = () => {
    const cls = useStyles()

    return <div className={cls.root}>
        <div className={cls.alert}>
            <div style={{ width: '96px', height: '96px', marginRight: '1rem' }}>
                <svg viewBox="0 0 24 24" data-testid="SettingsOutlinedIcon" tabIndex="-1" title="SettingsOutlined">
                    <path fill="#E6E7E9"
                        d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4m0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2">
                    </path>
                </svg>
            </div>
            <div>
                Проводятся технические работы<br />
                Попробуйте <a onClick={reload} style={{ cursor: 'pointer', color: 'rgb(0,176,80)', textDecoration: 'underline' }}>обновить</a> через несколько минут<div style={{ marginBottom: '4px' }} />
                <span style={{ fontSize: '12px' }}><a href='mailto:calculator.service@domrf.ru' style={{ color: 'rgb(0,176,80)', textDecoration: 'underline' }}>обратиться в техподдержку</a></span>
            </div>
        </div>
    </div>

}

export default TechSupportPage